import React from 'react';

import { Icon, Link } from '../../components';
import { useTimer } from '../../hooks/useTimer';
import './Notification.scss';
import { NotificationProps, NotificationTypes } from './Notification.types';

const getNotificationTypeIcon = (type) => {
  if (type === NotificationTypes.alert || type === NotificationTypes.warning) {
    return Icon.AlertCircleIcon;
  } else if (type === NotificationTypes.success) {
    return Icon.ThumbsUpIcon;
  }
  return null;
};

export const Notification = ({ notification, onClose }: NotificationProps) => {
  useTimer(onClose, notification.duration);

  return (
    <div className="notification-card">
      <div className="notification-card__content">
        <NotificationHeader>
          <NotificationIcon type={notification.type} />
          <NotificationMessage
            code={notification.code}
            link={notification.link}
          >
            {notification.message}
          </NotificationMessage>
          <NotificationCloseButton onClick={onClose} />
        </NotificationHeader>
        <NotificationDetails text={notification.details} />
      </div>
    </div>
  );
};

export const NotificationHeader = ({ children }) => {
  return <div className="notification-card__header">{children}</div>;
};

export const NotificationMessage = ({ children, code, link }) => {
  return (
    <div className="notification-card__message">
      {link ? <Link to={link}>{children}</Link> : children}{' '}
      {!!code && <span className="notification-card__message">({code})</span>}
    </div>
  );
};

export const NotificationIcon = ({ type }) => {
  const TypeIcon = getNotificationTypeIcon(type);
  if (!TypeIcon) return null;
  return (
    <div className={`notification-card__icon notification-card__icon--${type}`}>
      <TypeIcon />
    </div>
  );
};

export const NotificationCloseButton = ({ onClick }) => {
  return (
    <div className="notification-card__action-icon" onClick={onClick}>
      <Icon.CloseIcon />
    </div>
  );
};

export const NotificationDetails = ({ text }) => {
  if (!text) return null;
  return <div className="notification-card__details">{text}</div>;
};

export const NotificationWithLoadingState = ({ children }) => {
  return (
    <NotificationHeader>
      <div className="notification-card__message notification-card__message--loading">
        {children}
      </div>
    </NotificationHeader>
  );
};
