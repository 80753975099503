import { SelectOption } from './Select.types';

export * from './MultiSelect';
export * from './AsyncMultiSelect';
export * from './SingleSelect';
export * from './AsyncSingleSelect';

type BaseResource = {
  id: string;
  name?: string;
};

function baseGetLabel<T extends BaseResource>(resource: T) {
  return resource.name;
}

export function getSelectOptions<T extends BaseResource>(
  resources: T[],
// @ts-ignore
  getLabel: (r: T) => string = baseGetLabel
): SelectOption[] {
  return resources.map((resource: T) => ({
    label: getLabel(resource),
    value: resource.id,
    entity: resource,
  }));
}
