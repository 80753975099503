import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { RegistrationRouterContract } from './registration.router-contract.sdk';

export const getRegistrationClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'registrationStore';

  const tsRest = buildTsRestInstance<typeof RegistrationRouterContract>(
    apiInstance,
    RegistrationRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance<{
    listRegistrations: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof RegistrationRouterContract.listRegistrations.query>
        >
      >,
      z.infer<
        (typeof RegistrationRouterContract.listRegistrations.responses)['200']
      >
    >;
    register: RtkMutation<
      RtkFullParams<z.infer<typeof RegistrationRouterContract.register.body>>,
      z.infer<(typeof RegistrationRouterContract.register.responses)['200']>
    >;
    confirmRegistrationEmail: RtkMutation<
      RtkFullParams<
        z.infer<typeof RegistrationRouterContract.confirmRegistrationEmail.body>
      >,
      z.infer<
        (typeof RegistrationRouterContract.confirmRegistrationEmail.responses)['200']
      >
    >;
    markRegistrationsInReview: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof RegistrationRouterContract.markRegistrationsInReview.body
        >
      >,
      z.infer<
        (typeof RegistrationRouterContract.markRegistrationsInReview.responses)['200']
      >
    >;
    declineRegistrations: RtkMutation<
      RtkFullParams<
        z.infer<typeof RegistrationRouterContract.declineRegistrations.body>
      >,
      z.infer<
        (typeof RegistrationRouterContract.declineRegistrations.responses)['200']
      >
    >;
    blockRegistrations: RtkMutation<
      RtkFullParams<
        z.infer<typeof RegistrationRouterContract.declineRegistrations.body>
      >,
      z.infer<
        (typeof RegistrationRouterContract.declineRegistrations.responses)['200']
      >
    >;
    completeRegistrations: RtkMutation<
      RtkFullParams<
        z.infer<typeof RegistrationRouterContract.completeRegistrations.body>
      >,
      z.infer<
        (typeof RegistrationRouterContract.completeRegistrations.responses)['200']
      >
    >;
    getRegistrationPublicData: RtkQuery<
      RtkFullParams<
        z.infer<
          typeof RegistrationRouterContract.getRegistrationPublicData.pathParams
        >
      >,
      z.infer<
        (typeof RegistrationRouterContract.getRegistrationPublicData.responses)['200']
      >
    >;
  }>(tsRest, RegistrationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
