import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AssessmentFormQuestionType } from '@nl-lms/common/feature/types';
import { Survey } from '@nl-lms/sdk/backend';
import {
  AssessmentQuestion,
  Button,
  Card,
  Separator,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import SurveyLayout from '../../layouts/SurveyLayout/SurveyLayout';
import './SurveyPlayer.scss';

const combineConditions = (prevMatch, currentMatch, combinator) => {
  if (combinator === 'and') {
    return prevMatch && currentMatch;
  }
  return prevMatch || currentMatch;
};

type SurveyPlayerProps = {
  surveyForm: Survey;
  onSubmitSurveyAnswers?: (
    id: string,
    answers: { questionId: string; value: string[] }[],
    timeSpent: number,
  ) => void;
  onChangeSurveyAnswers?: (
    answers: { questionId: string; value: string[] }[],
    readyForSubmit: boolean,
    timeSpent: number,
  ) => void;
  onPersistSurveyAnswers?: (
    id: string,
    timeSpent: number,
    answers?: { questionId: string; value: string[] }[],
  ) => void;
  submitBtnLabel?: string;
  name?: string;
  description?: string;
  isSubmitLoading?: boolean;
  initialAnswers?: Record<string, string[]>;
  disabled?: boolean;
  showHeader?: boolean;
};

export const SurveyPlayer = ({
  surveyForm,
  // @ts-ignore
  onSubmitSurveyAnswers = null,
  // @ts-ignore
  onChangeSurveyAnswers = null,
  // @ts-ignore
  onPersistSurveyAnswers = null,
  submitBtnLabel = 'Submit',
  name = '',
  description = '',
  isSubmitLoading = false,
  initialAnswers = {},
  disabled = false,
  showHeader = true,
}: SurveyPlayerProps) => {
  const [userAnswers, setUserAnswers] =
    useState<Record<string, string[]>>(initialAnswers);
  const [trackedTime, setTrackedTime] = useState({
    previousVisibilityEventStartTime: new Date(),
    focusTotalTime: 0,
    blurTotalTime: 0,
  });

  const timeSpent = useMemo(
    () => (surveyForm?.timeSpent || 0) * 1000,
    [surveyForm],
  );

  const onChange = useCallback(async () => {
    if (onChangeSurveyAnswers) {
      await onChangeSurveyAnswers(
        parseAnswers(surveyForm, userAnswers),
        isReadyForSubmit,
        computeTotalTime(timeSpent, trackedTime),
      );
    }

    if (onPersistSurveyAnswers) {
      await onPersistSurveyAnswers(
        surveyForm?.id,
        computeTotalTime(timeSpent, trackedTime),
        parseAnswers(surveyForm, userAnswers),
      );
    }
  }, [
    userAnswers,
    timeSpent,
    trackedTime,
    onPersistSurveyAnswers,
    onChangeSurveyAnswers,
  ]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      const blurTime =
        trackedTime.blurTotalTime +
        (new Date().getTime() -
          trackedTime.previousVisibilityEventStartTime.getTime());
      setTrackedTime({
        ...trackedTime,
        previousVisibilityEventStartTime: new Date(),
        blurTotalTime: blurTime,
      });
    } else {
      const focusTime =
        trackedTime.focusTotalTime +
        (new Date().getTime() -
          trackedTime.previousVisibilityEventStartTime.getTime());
      setTrackedTime({
        ...trackedTime,
        previousVisibilityEventStartTime: new Date(),
        focusTotalTime: focusTime,
      });
    }
  }, [trackedTime]);

  const isReadyForSubmit = useMemo(() => {
    const questionIds = surveyForm?.questions
      ?.filter((question) => {
        if (!question.condition || !question.condition.length) return true;
        return checkIfQuestionMatchesCondition(question, userAnswers);
      })
      ?.filter((q) => q.required)
      ?.map((question) => question?.questionId);
    return !questionIds?.some(
      (qId) =>
        !userAnswers[qId] ||
        !userAnswers[qId].length ||
        userAnswers[qId].includes(''),
    );
  }, [surveyForm?.questions, userAnswers]);

  const onChangeAnswer = useCallback(
    (e) => {
      if (!e?.target?.name) return;
      const answer =
        typeof e.target.value === 'string' ? [e.target.value] : e.target.value;
      const questionId = e.target.name;
      const newAnswers = {
        ...userAnswers,
        [questionId]: answer,
      };
      setUserAnswers(newAnswers);
    },
    [
      userAnswers,
      surveyForm,
      onChangeSurveyAnswers,
      isReadyForSubmit,
      timeSpent,
      trackedTime,
    ],
  );

  const onSubmitForm = useCallback(() => {
    if (!onSubmitSurveyAnswers) return;
    onSubmitSurveyAnswers(
      surveyForm?.id,
      parseAnswers(surveyForm, userAnswers),
      computeTotalTime(timeSpent, trackedTime),
    );
  }, [surveyForm, userAnswers, timeSpent, trackedTime]);

  const orderedQuestions = useMemo(() => {
    const questions = [...(surveyForm?.questions || [])]; // clone so we don't mutate original when sorting

    // make rating first question if exists
    const ratingQuestionIndex = questions.findIndex(
      (q) => q.type == AssessmentFormQuestionType.Rating,
    );
    if (ratingQuestionIndex > -1)
      questions[ratingQuestionIndex] = {
        ...questions[ratingQuestionIndex],
        order: -1,
      };

    return questions
      .sort((a, b) => a.order - b.order)
      .filter((question) => {
        if (!question.condition || !question.condition.length) return true;
        return checkIfQuestionMatchesCondition(question, userAnswers);
      });
  }, [surveyForm?.questions, userAnswers]);

  useEffect(() => {
    onChange();
  }, [userAnswers, trackedTime]);

  useEffect(() => {
    window.addEventListener('beforeunload', onChange, true);
    return () => window.removeEventListener('beforeunload', onChange, true);
  }, [onChange]);

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange, true);
    return () => {
      window.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        true,
      );
    };
  }, [handleVisibilityChange]);

  return (
    <>
      {showHeader ? (
        <SurveyLayout.Header>
          <SurveyLayout.HeaderLogo>
            <img src={C.LOGO_DETAILS.variation.small} />
          </SurveyLayout.HeaderLogo>
          <SurveyLayout.HeaderDetails>
            <Typography.h1>{name || surveyForm?.name}</Typography.h1>
            <Typography.h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: description || surveyForm?.description,
                }}
              />
            </Typography.h2>
          </SurveyLayout.HeaderDetails>
        </SurveyLayout.Header>
      ) : null}

      <SurveyLayout.Content>
        <Card className="survey-player" paddingType="medium">
          {orderedQuestions?.map((question, index) => (
            <div key={`${question?.questionId}-${index}`}>
              <AssessmentQuestion
                type={question?.type}
                question={question.title}
                description={question?.description}
                inputProps={{
                  onChange: onChangeAnswer,
                  options: question?.options.map((q) => ({
                    value: q.title,
                    label: q.title,
                  })),
                  name: question?.questionId,
                  controlled: true,
                  disabled,
                }}
                value={userAnswers[question?.questionId]}
                index={index}
                required={question.required}
              />
              {index < orderedQuestions.length - 1 && <Separator />}
            </div>
          ))}
          {!!onSubmitSurveyAnswers && (
            <div className="survey-player__submit-action">
              <Separator />
              <Button
                onClick={onSubmitForm}
                label={submitBtnLabel}
                isLoading={isSubmitLoading}
                disabled={disabled || !isReadyForSubmit}
              />
            </div>
          )}
        </Card>
      </SurveyLayout.Content>
    </>
  );
};

const computeTotalTime = (initialTime, currentTime) =>
  (initialTime || 0) +
  currentTime.focusTotalTime +
  (new Date().getTime() -
    currentTime.previousVisibilityEventStartTime.getTime()) +
  currentTime?.blurTotalTime;

const parseAnswers = (
  surveyForm: Survey,
  userAnswers: Record<string, string[]>,
): { questionId: string; value: string[] }[] => {
  if (!surveyForm.questions) return [];
  return surveyForm.questions.map((question) => {
    const { questionId } = question;
    let userAnswer = userAnswers[questionId] || [''];
    const isConditional = question.condition && question.condition.length;
    if (
      isConditional &&
      !checkIfQuestionMatchesCondition(question, userAnswers) &&
      userAnswer.length
    ) {
      userAnswer = [];
    }
    return {
      questionId,
      value: userAnswer,
    };
  });
};

const checkIfQuestionMatchesCondition = (
  question: Required<Survey>['questions'][number],
  userAnswers: Record<string, string[]>,
) => {
  let currentCombinator = 'and';
  let matchesCondition;
  question?.condition?.forEach((condition, index) => {
    if ('combinator' in condition) {
      currentCombinator = condition.combinator;
      return;
    }
    const userAnswer = userAnswers[condition.questionId];
    let matchesUserAnswer = false;
    if (!userAnswer && !condition.answer?.length) matchesUserAnswer = true;
    if (userAnswer?.length && !condition.answer?.length)
      matchesUserAnswer = false;
    if (userAnswer && condition.answer?.length) {
      const matchedAnswers = condition.answer.filter((answer) =>
        userAnswer.includes(answer),
      );
      matchesUserAnswer = matchedAnswers.length === condition.answer.length;
    }
    if (index === 0) {
      matchesCondition = matchesUserAnswer;
    } else {
      matchesCondition = combineConditions(
        matchesCondition,
        matchesUserAnswer,
        currentCombinator,
      );
    }
  });
  return matchesCondition;
};
