import store from 'store';

import { getElearningClients } from '@nl-lms/feature/elearning/sdk';

import { apiBaseUrl } from './api';

const { raw: elearningApiClient, rtk: elearningRtkClient } =
  getElearningClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export { elearningApiClient };

export const elearningApi = elearningRtkClient;
elearningApi.enhanceEndpoints<'ElearningCourse' | 'ContentFile'>({
  endpoints: {
    getElearningCourse: {
      providesTags: (result, error, arg) => [
        { type: 'ElearningCourse', id: arg.id },
      ],
    },
    removeElearningCourses: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ElearningCourse', id: 'LIST' }];
      },
    },
    updateElearningCourse: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ElearningCourse', id: 'LIST' }];
      },
    },
    createElearningCourse: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ElearningCourse', id: 'LIST' }];
      },
    },
    listElearningCourses: {
      providesTags: (result, error, arg) => [
        { type: 'ElearningCourse', id: 'LIST' },
      ],
    },
    listContentFiles: {
      providesTags: (result, error, arg) => [
        { type: 'ContentFile', id: 'LIST' },
      ],
    },
    createContentFile: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ContentFile', id: 'LIST' }];
      },
    },
    validateContentFile: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'ContentFile', id: 'LIST' },
          { type: 'ContentFile', id: arg.id },
          { type: 'ElearningCourse', id: arg.id },
        ];
      },
    },
    removeContentFiles: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ContentFile', id: 'LIST' }];
      },
    },
  },
});

type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type ElearningApiMutationNames = FilterEndingWith<
  keyof typeof elearningApi,
  'Mutation'
>;
