import React from 'react';

import { Typography } from '../Typography/Typography';

import './Spinner.scss';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

export const Spinner = ({
  className = '',
  withOverlay = false,
  small = false,
  large = false,
  light = false,
  message = '',
  ...props
}) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('loading-spinner', props);
  const circleClassNameProps = useClassNameProps(
    'loading-spinner__circle',
    !small && !large ? 'loading-spinner__circle--medium' : null,
    small && !large ? 'loading-spinner__circle--small' : null,
    !small && large ? 'loading-spinner__circle--large' : null,
    light ? 'loading-spinner__circle--light' : null
  );

  return (
    <div {...classNameProps} {...commonProps}>
      <div className="loading-spinner__wrapper">
        {withOverlay && <div className="loading-spinner__overlay" />}
        <div {...circleClassNameProps} />
        {message && (
          <Typography.h2
            className={`loading-spinner__message ${
              light ? 'loading-spinner__message--light' : ''
            }`}
          >
            {message}
          </Typography.h2>
        )}
      </div>
    </div>
  );
};
