export const LearningAssignmentRouterPrefix = '/learning-assignment';
export const LearningAssignmentInstanceNotificationAudienceName =
  'learning-assignment-instance-audience';

// TODO: View instead of list
export const LearningAssignmentScope = {
  listLearningAssignments: { resource: 'learning_assignment', action: 'list' },
  createLearningAssignments: {
    resource: 'learning_assignment',
    action: 'create',
  },
  deleteLearningAssignments: {
    resource: 'learning_assignment',
    action: 'delete',
  },
  updateLearningAssignments: {
    resource: 'learning_assignment',
    action: 'update',
  },
  listLearningAssignmentInstances: {
    resource: 'learning_assignment_instance',
    action: 'list',
  },
  deleteLearningAssignmentInstances: {
    resource: 'learning_assignment_instance',
    action: 'delete',
  },
  createLearningAssignmentInstances: {
    resource: 'learning_assignment_instance',
    action: 'create',
  },
  updateLearningAssignmentInstances: {
    resource: 'learning_assignment_instance',
    action: 'update',
  },
  convertLearningAssignmentInstances: {
    resource: 'learning_assignment_instance',
    action: 'convert',
  },
  listLearningAssignmentPlannedInstances: {
    resource: 'learning_assignment_planned_instance',
    action: 'list',
  },
  deleteLearningAssignmentPlannedInstances: {
    resource: 'learning_assignment_planned_instance',
    action: 'delete',
  },
  createLearningAssignmentPlannedInstances: {
    resource: 'learning_assignment_planned_instance',
    action: 'create',
  },
  updateLearningAssignmentPlannedInstances: {
    resource: 'learning_assignment_planned_instance',
    action: 'update',
  },
} as const;

export const LearningAssignmentDomainEventNames = {
  LearnerJoinedAudience: 'learning-assignment.learner-joined-audience',
  LearningAssignmentInstanceCompleted: 'learning-assignment-instance.completed',
  LearningAssignmentInstanceStarted: 'learning-assignment-instance.started',
  LearningAssignmentInstanceCreated: 'learning-assignment-instance.created',
  LearningAssignmentInstanceRemoved: 'learning-assignment-instance.removed',
  LearningAssignmentInstanceFailed: 'learning-assignment-instance.failed',

  // Without rule triggers ATM
  // LearningAssignmentInstanceUpdated: 'learning-assignment-instance.updated',
  LearnerLeftAudience: 'learning-assignment.learner-left-audience',
  // LearningAssignmentUpdated: 'learning-assignment.updated',
  LearningAssignmentRemoved: 'learning-assignment.removed',
} as const;

export const LearningAssignmentAutomation = {
  entityName: 'learning-assignment',
  actions: {
    createInstance: 'create-instance',
  },
};

// TODO: This should be moved in a general way of handling different notification audiences and properties for notifications
export const LearningAssignmentInitialNotificationTemplateName =
  'LearningAssignmentInitialNotificationTemplate';
export const LearningAssignmentReminderNotificationTemplateName =
  'LearningAssignmentReminderNotificationTemplate';
export const LearningAssignmentNotificationMentionOptions = [
  {
    id: '{{learner.firstName}}',
    label: '{{learner.firstName}}',
  },
  { id: '{{learner.lastName}}', label: '{{learner.lastName}}' },
  { id: '{{learner.email}}', label: '{{learner.email}}' },
  {
    id: '{{learningAssignment.name}}',
    label: '{{learningAssignment.name}}',
  },
  {
    id: '{{learningAssignment.contentName}}',
    label: '{{learningAssignment.contentName}}',
  },
  {
    id: '{{learningAssignment.dueDate}}',
    label: '{{learningAssignment.dueDate}}',
  },
  {
    id: '{{portalUrl}}',
    label: '{{portalUrl}}',
  },
];
