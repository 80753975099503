import i18n from 'i18next';

export const translateDurationInHours = (minutes) => {
  if (minutes) {
    if (minutes < 60) {
      return `${minutes} ${i18n.t('learner:common.time.minutes')}`;
    } else if (minutes % 60) {
      return `${(minutes / 60).toFixed(1)} ${
        minutes / 60 > 1
          ? i18n.t('learner:common.time.hours')
          : i18n.t('learner:common.time.hour')
      }`;
    } else
      return `${minutes / 60} ${
        minutes / 60 > 1
          ? i18n.t('learner:common.time.hours')
          : i18n.t('learner:common.time.hour')
      }`;
  } else return i18n.t('learner:common.time.noduration');
};

export const translateDurationInDays = (days) => {
  if (days) {
    if (days > 1) return `${days} ${i18n.t('learner:common.time.days')}`;
    else if (days === 1) return `${days} ${i18n.t('learner:common.time.day')}`;
  }
  return i18n.t('learner:common.time.noduration');
};

type TranslateParam = {
  days?: number;
  totalMinutes?: number;
};

export const translateDuration = ({
  days = undefined,
  totalMinutes = undefined,
}: TranslateParam = {}) => {
  if (days && days > 1) {
    return translateDurationInDays(days);
  } else if (days && days === 1 && totalMinutes) {
    return translateDurationInHours(totalMinutes);
  }

  return i18n.t('learner:common.time.noduration');
};
