import { InternalAxiosRequestConfig } from 'axios';

import { ApiClientOptions } from '../types';

export const setAuthTokenInterceptor =
  (options: ApiClientOptions) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const originalRequest = config;

    const accessToken = options.tokenGetter();
    if (!accessToken) return originalRequest;

    if (originalRequest.headers) {
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
    }

    return originalRequest;
  };
