import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatConstantString } from '@nl-lms/ui/utils';
import { C } from '@nl-lms/ui/constants';
import { Button, FormField, Input, SingleSelect } from '@nl-lms/ui/components';
import * as _ from 'lodash';
import { ScheduleBlock } from './types';

type ScheduleBlockAddInputProps = {
  onChange?: (ScheduleBlock) => void;
  onSubmit: (ScheduleBlock) => void;
  value?: ScheduleBlock;
  initialValue?: ScheduleBlock;
  disabled?: boolean;
};

const scheduleBlockTypes = _.map(C.SCHEDULE_BLOCK, (label, value) => ({
  label: formatConstantString(label),
  value,
}));

export const ScheduleBlockAddInput = ({
  onChange,
  onSubmit,
  value,
  disabled = false,
  initialValue,
}: ScheduleBlockAddInputProps) => {
  const [type, setType] = useState<{ label: string; value: string }>(() => {
    const val = initialValue || value || { type: 1, duration: 0 };
    return {
      label: formatConstantString(C.SCHEDULE_BLOCK[val.type]),
      value: val.type + '',
    };
  });
  const [duration, setDuration] = useState<string>(() => {
    const val = initialValue || value || { type: 1, duration: 0 };
    return val.duration + '';
  });

  const updateOnChange = useCallback(() => {
    if (disabled) return;

    if (onChange) {
      onChange({ type: type.value, duration: parseFloat(duration) });
    }
  }, [type, duration, onChange, disabled]);

  const onChangeType = useCallback(
    (e) => {
      if (disabled) return;

      setType({ label: formatConstantString(C.SCHEDULE_BLOCK[e]), value: e });
      updateOnChange();
    },
    [updateOnChange]
  );

  const onChangeDuration = useCallback(
    (e) => {
      if (disabled) return;

      setDuration(e.target.value);
      updateOnChange();
    },
    [updateOnChange]
  );

  useEffect(() => {
    if (disabled) return;
    if (!value) return;

    if (value.type) {
      setType({
        label: formatConstantString(C.SCHEDULE_BLOCK[value.type]),
        value: value.type + '',
      });
    }

    if (value.duration) setDuration(value.duration + '');
  }, [value]);

  const onClickSubmit = useCallback(() => {
    if (disabled) return;

    onSubmit({ type: parseInt(type.value), duration: parseFloat(duration) });
    setDuration('');
    setType({ value: '1', label: formatConstantString(C.SCHEDULE_BLOCK[1]) });
  }, [type, duration]);

  const className = useMemo(() => {
    const base = 'dsti-day__block';
    if (disabled) return `${base} dsti-day__block--disabled`;
    return base;
  }, [disabled]);
  return (
    <div className={className}>
      <FormField
        label="Block Schedule Type"
        className="dsti-day__block-component"
      >
        <SingleSelect
          name="block-schedule-type"
          onChange={onChangeType}
          selectedItem={type}
          options={scheduleBlockTypes}
          disabled={disabled}
        />
      </FormField>
      <FormField label="Block Duration" className="dsti-day__block-component">
        <Input
          name="block-schedule-duration"
          onChange={onChangeDuration}
          value={duration}
          placeholder="Duration in hours"
          type="number"
          disabled={disabled}
        />
      </FormField>
      <div className="dsti-day__block-component">
        <Button label="Add block" onClick={onClickSubmit} disabled={disabled} />
      </div>
    </div>
  );
};
