import { useMemo } from 'react';

import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { C } from '@nl-lms/ui/constants';

const baseFilters = [
  {
    id: 'learner-mandatory-learning',
    value: {
      field: { label: 'mandatory', field: 'mandatory' },
      operator: {
        label: 'Equals',
        value: QueryOperator.Equals,
        type: QueryFilterOperatorType.Unary,
      },
      value: {
        label: 'ismandatory',
        value: true,
      },
    },
    combinator: QueryFilterCombinator.And,
  },
  {
    id: 'learner-learning-status',
    value: {
      field: { label: 'status', field: 'status' },
      operator: {
        label: 'Excludes',
        value: QueryOperator.Excludes,
        type: QueryFilterOperatorType.Unary,
      },
      value: {
        label: 'learningStatusValue',
        value: ['CANCELED'],
      },
    },
    combinator: QueryFilterCombinator.And,
  },
];

export const useLearnerMandatoryLearningBaseFilters = () => {
  return useMemo<AppQueryFilter>(
    () => ({
      id: 'learner-active-learning-filters',
      value: [
        // only include elearning assignments and exclude canceled items
        ...baseFilters,
        {
          id: 'learner-active-learning-type',
          value: {
            field: { label: 'learningType', field: 'learningType' },
            operator: {
              label: 'Includes',
              value: QueryOperator.Includes,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: 'learningTypeValue',
              value: [
                C.I_LEARNING_ACTIVITY_TYPES.ELEARNING_SESSION,
                C.I_LEARNING_ACTIVITY_TYPES.LEARNING_PATH_INSTANCE,
              ],
            },
          },
        },
      ],
    }),
    []
  );
};
