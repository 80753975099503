export const overrideConsole = () => {
  // @ts-ignore
  if (process.env.NODE_ENV === 'development') return;
  console._log = console.log.bind(console);
  console._error = console.error.bind(console);
  console._warn = console.warn.bind(console);
  console._debug = console.debug.bind(console);
  console.logs = [];
  const updateLogTrace = (
    logArgs: any[],
    level: typeof console.logs[0]['level']
  ) => {
    try {
      const currentDate = new Date();
      console.logs.push({
        timestamp: currentDate.toISOString(),
        content: logArgs,
        level,
      });
      if (console.logs.length > 50) {
        console.logs.splice(0, console.logs.length - 50);
      }
      const currentLogs = [...console.logs];
      currentLogs.some((logEntry) => {
        const logDate = new Date(logEntry.timestamp);
        const diffInMs = currentDate.getTime() - logDate.getTime();
        // Discard entries older than 5 minutes
        if (diffInMs > 300000) {
          console.logs = currentLogs.filter(
            (l) =>
              l.timestamp !== logEntry.timestamp && l.level !== logEntry.level
          );
          return false;
        }
        return true;
      });
    } catch (e) {
      console.error(e);
    }
  };
  console.log = function () {
    // updateLogTrace(Array.from(arguments), 'log');
    console._log.apply(console, arguments);
  };
  console.error = function () {
    updateLogTrace(Array.from(arguments), 'error');
    console._error.apply(console, arguments);
  };
  console.debug = function () {
    updateLogTrace(Array.from(arguments), 'debug');
    console._debug.apply(console, arguments);
  };
  console.warn = function () {
    updateLogTrace(Array.from(arguments), 'warn');
    console._warn.apply(console, arguments);
  };
};
