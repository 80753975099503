import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { ContentLibraryItemRouterPrefix } from './content-library-item.constants.sdk';
import {
  ContentLibraryItemIdParamSchema,
  ContentLibraryItemSchema,
  CreateContentLibraryItemSchema,
  UpdateContentLibraryItemSchema,
} from './content-library-item.schema.sdk';

const contract = initContract();

// the description field is for indicating if it's a mutation or a query

export const ContentLibraryItemRouterContract = contract.router({
  listContentLibraryItems: {
    method: 'GET',
    path: `${ContentLibraryItemRouterPrefix}/`,
    responses: ContractPaginatedResponse(ContentLibraryItemSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  removeContentLibraryItems: {
    method: 'DELETE',
    path: `${ContentLibraryItemRouterPrefix}/`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  createContentLibraryItem: {
    method: 'POST',
    path: `${ContentLibraryItemRouterPrefix}/`,
    responses: ContractStandardResponse(ContentLibraryItemSchema),
    body: CreateContentLibraryItemSchema,
  },
  updateContentLibraryItem: {
    method: 'POST',
    path: `${ContentLibraryItemRouterPrefix}/:contentLibraryItemId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateContentLibraryItemSchema,
    pathParams: ContentLibraryItemIdParamSchema,
  },
  getContentLibraryItem: {
    method: 'GET',
    path: `${ContentLibraryItemRouterPrefix}/:contentLibraryItemId`,
    responses: ContractStandardResponse(ContentLibraryItemSchema),
    pathParams: ContentLibraryItemIdParamSchema,
  },
});
