import * as z from 'zod';

import { Competency, CompetencySchema } from '@nl-lms/common/feature/types';

import { ContentLibraryItem, ContentLibraryItemType } from '../sdk';

export const ContentLibraryItemSchema: z.ZodType<
  ContentLibraryItem & {
    competencies: Omit<Competency, 'createdAt' | 'updatedAt'>[];
  }
> = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  url: z.string(),
  type: z.nativeEnum(ContentLibraryItemType),
  createdAt: z.date(),
  updatedAt: z.date(),
  competencies: z.array(
    CompetencySchema.omit({ createdAt: true, updatedAt: true }),
  ),
});

export const CreateContentLibraryItemSchema: z.ZodType<
  Omit<ContentLibraryItem, 'id' | 'createdAt' | 'updatedAt'>
> = z.object({
  name: z.string(),
  url: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  competencyIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  type: z.nativeEnum(ContentLibraryItemType),
});
export const UpdateContentLibraryItemSchema: z.ZodType<
  Partial<Omit<ContentLibraryItem, 'id' | 'createdAt' | 'updatedAt'>>
> = z.object({
  name: z.string(),
  url: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  competencyIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  type: z.nativeEnum(ContentLibraryItemType),
});

export const ContentLibraryItemIdParamSchema = z.object({
  contentLibraryItemId: z.string(),
});
