import React, { useCallback, useMemo } from 'react';

import { MultiSelect } from '@nl-lms/ui/components';
import { getSelectOptions } from '@nl-lms/ui/utils';

import { adminApi } from '../../../_common/services/api';

const buildTagLabel = (tag) => {
  if (tag.scope) return `${tag.scope}:${tag.title}`;
  return tag.title;
};

const defaultProps = {
  initialSelectedItems: [],
  selectedItems: null,
};

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

const { useListTagsForSelectQuery, useCreateTagMutation } = adminApi;
export const AdminTagSelect = (props) => {
  const {
    onChange,
    name,
    returnEntireItemOnChange,
    initialSelectedItems,
    selectedItems,
  } = props;

  const [createTag, { isLoading: isCreateLoading }] = useCreateTagMutation();
  const {
    data,
    refetch,
    isLoading: isFetchLoading,
    // @ts-ignore
  } = useListTagsForSelectQuery(null);
  const options = useMemo(() => {
    if (!data) return [];
    return getSelectOptions(data, buildTagLabel);
  }, [data]);

  const onCreate = useCallback(async (inputValue) => {
    const splitInput = inputValue.split(':');
    const scope = splitInput.length > 1 ? splitInput[0] : '';
    const title = splitInput.length > 1 ? splitInput[1] : splitInput[0];
    const result = await createTag({ title, scope });
    if ('data' in result) {
      refetch();
      //@ts-ignore
      return { value: result.data.id, label: inputValue };
    }
    return false;
  }, []);

  return (
    <MultiSelect
      name={name}
      placeholder="Search or create new tag"
      options={options}
      onChange={onChange}
      isLoading={isCreateLoading || isFetchLoading}
      isCreatable
      returnEntireItemOnChange={returnEntireItemOnChange}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={initialSelectedItems}
      onCreateOption={onCreate}
    />
  );
};

AdminTagSelect.defaultProps = defaultProps;
