import React from 'react';
import { _ } from '@nl-lms/vendor';

import { C } from '@nl-lms/ui/constants';

import { SingleSelect } from '@nl-lms/ui/components';

export const AdminCostResourceTypeSelect = ({
  onChange,
  name = 'admin-cost-resource-type-select',
  initialValue = '',
  placeholder = 'Select resource type',
  hasError = false
}) => {
  const options = _.map(C.COST_RESOURCE_TYPES, (rt) => ({
    value: rt,
    label: rt,
  }));
  return (
    <SingleSelect
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      initialSelectedItem={initialValue}
      name={name}
      hasError={hasError}
    />
  );
};
