import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Competency } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';

type Props = {
  competency?: Partial<Competency>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  scope: yup.string(),
});

export const AdminCompetencyEditFormSideModal = ({
  competency = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...competency,
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateCompetencyMutation',
    updateHookName: 'useUpdateCompetencyMutation',
    entityId: competency.id,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {competency.id ? 'Edit Competency' : 'Create New Competency'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Competency Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Title"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="Scope" errorMessage={errors?.scope?.message}>
              <Input
                required
                {...register('scope')}
                hasError={!!errors?.scope?.message}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={competency.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
