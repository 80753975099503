import React from 'react';
import { _ } from '@nl-lms/vendor';

import { C } from '@nl-lms/ui/constants';

import { SingleSelect } from '@nl-lms/ui/components';

export const AdminLiveCourseLanguageSelect = ({
  onChange,
  name = 'learning-type-select',
  isClearable = false,
  initialValue = null,
  value = null,
  hasError = false
}) => (
  <SingleSelect
    onChange={onChange}
    options={_.map(C.LANGUAGES, (label, value) => ({
      value: parseInt(value, 10),
      label,
    }))}
    // @ts-ignore
    selectedItem={parseSelectedItem(value)}
    placeholder="Select Language"
    isClearable={isClearable}
// @ts-ignore
    initialSelectedItem={initialValue ? parseInt(initialValue) : null}
    name={name}
    hasError={hasError}
  />
);

const parseSelectedItem = (value) => {
  if (value) return parseInt(value);
  return null;
};
