import React from 'react';

export const QrIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M2 9C1.4 9 1 8.6 1 8V4c0-1.7 1.3-3 3-3h4c.6 0 1 .4 1 1S8.6 3 8 3H4C3.4 3 3 3.4 3 4v4C3 8.6 2.6 9 2 9zM8 23H4c-1.7 0-3-1.3-3-3v-4c0-.6.4-1 1-1s1 .4 1 1v4c0 .6.4 1 1 1h4c.6 0 1 .4 1 1S8.6 23 8 23zM22 9c-.6 0-1-.4-1-1V4c0-.6-.4-1-1-1h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c1.7 0 3 1.3 3 3v4C23 8.6 22.6 9 22 9zM20 23h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1-.4 1-1v-4c0-.6.4-1 1-1s1 .4 1 1v4C23 21.7 21.7 23 20 23zM22 14H2c-.6 0-1-.4-1-1s.4-1 1-1h20c.6 0 1 .4 1 1S22.6 14 22 14z"
    ></path>
    <path
      fill="currentColor"
      d="M10 11H6c-.6 0-1-.4-1-1V6c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v4C11 10.6 10.6 11 10 11zM7 9h2V7H7V9zM18 19h-2c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v2C19 18.6 18.6 19 18 19zM14 11c-.6 0-1-.4-1-1V6c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V7h-2v3C15 10.6 14.6 11 14 11zM12 19H9c-.6 0-1-.4-1-1v-1H6c-.6 0-1-.4-1-1s.4-1 1-1h6c.6 0 1 .4 1 1v2C13 18.6 12.6 19 12 19z"
    ></path>
    {/* <path
      fill="currentColor"
      d="M21.75,4.5V8a.75.75,0,0,1-1.5,0V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5ZM3,8.75A.75.75,0,0,0,3.75,8V4.5c0-.589.161-.75.75-.75H8a.75.75,0,0,0,0-1.5H4.5A2.067,2.067,0,0,0,2.25,4.5V8A.75.75,0,0,0,3,8.75Zm5,11.5H4.5c-.589,0-.75-.161-.75-.75V16a.75.75,0,0,0-1.5,0v3.5A2.067,2.067,0,0,0,4.5,21.75H8a.75.75,0,0,0,0-1.5Zm13-5a.75.75,0,0,0-.75.75v3.5c0,.589-.161.75-.75.75H16a.75.75,0,0,0,0,1.5h3.5a2.067,2.067,0,0,0,2.25-2.25V16A.75.75,0,0,0,21,15.25ZM6.25,8.88V9a.75.75,0,0,0,1.5,0V8.88c0-.845.285-1.13,1.13-1.13h6.24c.845,0,1.13.285,1.13,1.13V9a.75.75,0,0,0,1.5,0V8.88a2.394,2.394,0,0,0-2.63-2.63H8.88A2.394,2.394,0,0,0,6.25,8.88Zm8.87,8.87H8.88a2.394,2.394,0,0,1-2.63-2.63V12.75H5a.75.75,0,0,1,0-1.5H19a.75.75,0,0,1,0,1.5H17.75v2.37A2.394,2.394,0,0,1,15.12,17.75Zm1.13-2.63V12.75H7.75v2.37c0,.845.285,1.13,1.13,1.13h6.24C15.965,16.25,16.25,15.965,16.25,15.12Z"
    ></path> */}
  </svg>
);
