import { useAbility } from '@casl/react';
import React, { useCallback, useMemo, useState } from 'react';

import { LearningProgram } from '@nl-lms/common/feature/types';
import { FieldType } from '@nl-lms/common/shared';
import {
  LearningAssignmentLearningContentType,
  LearningAssignmentScope,
} from '@nl-lms/feature/learning-assignments/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  TableWithFullPagination,
  Typography,
  TypographyProps,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
} from '@nl-lms/ui/modules';
import { formatConstantString, formatTimeDelta } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { AbilityContext } from '../../../Can';
import { useTableAction } from '../../../_common/hooks';
import {
  learningAssignmentsApi,
  learningProgramsApi,
} from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import { mapAndLoadConstantsForSelect } from '../../_common/utils/fetchEntitiesForSelectMethods';
import { LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal } from '../learning-assignment/LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal';
import { LearningAssignmentEditSideModal } from '../learning-assignment/LearningAssignmentEditSideModal';

const {
  useListLearningAssignmentsQuery,
  useUpdateLearningAssignmentsMutation,
} = learningAssignmentsApi;

const { useUpdateLearningProgramMutation } = learningProgramsApi;

const { useRemoveLearningProgramAssignmentsMutation } = learningProgramsApi;

export const LearningProgramAssignmentsListTable = ({
  learningProgram,
}: {
  learningProgram: LearningProgram;
}) => {
  const { sorting, query, onChangeSorting, filters } = useListViewContext();
  const [assignmentsOrder, setAssignmentsOrder] = useState(() => {
    return learningProgram.settings.assignmentsOrder || [];
  });
  const { data: listLearningAssignments, isLoading } =
    useListLearningAssignmentsQuery({
      query: { query: transformTsRestQuery(query) },
    });

  const parsedAssignments = useMemo(() => {
    if (!listLearningAssignments) return [];
    const assignments = listLearningAssignments.rows;
    if (!assignmentsOrder.length) return assignments;
    const assignmentsById = _.indexBy(assignments, 'id');
    return assignmentsOrder.map((assignmentId) => {
      return assignmentsById[assignmentId];
    });
  }, [listLearningAssignments, assignmentsOrder]);
  const [removeAssignments] = useRemoveLearningProgramAssignmentsMutation();
  const [updateLearningAssignments] = useUpdateLearningAssignmentsMutation();
  const [updateLearningProgram] = useUpdateLearningProgramMutation();
  const showAssignmentEditModal = useShowModal(LearningAssignmentEditSideModal);
  const onEditRow = useCallback((assignment) => {
    showAssignmentEditModal({ assignment, showMandatory: false });
  }, []);
  const onRemoveRow = useTableAction(removeAssignments, {
    withRowCountProp: true,
    confirmationMessage: 'Are you sure that you want to remove this row? ',
    alertMessage: (res: { error?: { message?: string } }) =>
      res?.error?.message ?? 'Unable to remove assignments',
  });

  const rowActions = [
    ...getActionAbility(
      LearningAssignmentScope.updateLearningAssignments.action,
      LearningAssignmentScope.updateLearningAssignments.resource,
      {
        name: 'Edit',
        handler: onEditRow,
        Icon: Icon.EditIcon,
      }
    ),
    ...getActionAbility(
      LearningAssignmentScope.deleteLearningAssignments.action,
      LearningAssignmentScope.deleteLearningAssignments.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
        Icon: Icon.DeleteIcon,
      }
    ),
  ];

  const onDragRow = useCallback(
    async (from: number, to: number) => {
      const assignmentIds = parsedAssignments.map((la) => la.id);
      const previousOrder = [...assignmentIds];
      const [movedRow] = assignmentIds.splice(from, 1);
      assignmentIds.splice(to, 0, movedRow);
      setAssignmentsOrder(assignmentIds);
      const result = await updateLearningProgram({
        id: learningProgram.id,
        settings: {
          ...learningProgram.settings,
          assignmentsOrder: assignmentIds,
        },
      });
      if ('data' in result) {
        return;
      }
      setAssignmentsOrder(previousOrder);
    },
    [learningProgram, assignmentsOrder, parsedAssignments]
  );
  const setAsVisibleAfterDueDate = useCallback((selection) => {
    return updateLearningAssignments({
      ...selection,
      showAfterDueDate: true,
    });
  }, []);
  const setAsNotVisibleAfterDueDate = useCallback((selection) => {
    return updateLearningAssignments({
      ...selection,
      showAfterDueDate: false,
    });
  }, []);
  const onSetShowAfterDueDate = useTableAction(setAsVisibleAfterDueDate, {
    withRowCountProp: true,
    baseFilters: query.filters,
    confirmationMessage:
      'Are you sure that you want to set the selected rows as visible after due date?',
  });
  const onSetNotShowAfterDueDate = useTableAction(setAsNotVisibleAfterDueDate, {
    withRowCountProp: true,
    baseFilters: query.filters,
    confirmationMessage:
      'Are you sure that you want to set the selected rows as not visible after due date?',
  });

  const showModal = useShowModal(
    LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal
  );
  const onClickUpdateDueDate = useTableAction(
    (selection) => showModal({ selection }),
    {
      baseFilters: query.filters,
      withRowCountProp: true,
      showConfirmation: false,
      showNotification: false,
    }
  );

  const tableActions = [
    ...getActionAbility(
      LearningAssignmentScope.updateLearningAssignments.action,
      LearningAssignmentScope.updateLearningAssignments.resource,
      {
        name: 'Update',
        options: [
          {
            name: 'Change Due Date',
            handler: onClickUpdateDueDate,
          },
          {
            name: 'Set As Visible After Due Date',
            handler: onSetShowAfterDueDate,
          },
          {
            name: 'Set As Not Visible After Due Date',
            handler: onSetNotShowAfterDueDate,
          },
        ],
      }
    ),
    ...getActionAbility(
      LearningAssignmentScope.deleteLearningAssignments.action,
      LearningAssignmentScope.deleteLearningAssignments.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
      }
    ),
  ];

  const [columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Learning Item',
      accessor: 'item',
      Cell: ({ row }) => (
        <Link
          to={routes.admin.manage.assignments.item.path.full(row.original.id)}
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row }) =>
        formatConstantString(
          LearningAssignmentLearningContentType[row?.original?.content?.type]
        ),
    },
    // {
    //   Header: 'Required',
    //   accessor: 'mandatory',
    //   Cell: ({ row }) => (row.original.mandatory ? 'Yes' : 'No'),
    // },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      Cell: ({ row }) => {
        if (row.original.dueDateDelta) {
          return `${formatTimeDelta(row.original.dueDateDelta)}`;
        }

        return <PrettyDate value={row.original.dueDate} withTime />;
      },
    },
  ]);

  return (
    <>
      <TableWithFullPagination
        columns={columns}
        data={parsedAssignments}
        isLoading={isLoading}
        sorting={sorting}
        tableActions={tableActions}
        onDrop={onDragRow}
        rowActions={rowActions}
        onChangeSorting={onChangeSorting}
        onChangeColumns={onChangeColumns}
        // ExpanderComponent={LearningProgramAssignmentRulesListTable}
      >
        <NoDataPlaceholder
          iconName={filters ? 'FileIcon' : 'FileTextIcon'}
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.default
          }
          title={
            filters
              ? 'There are no assignments that match your search'
              : 'There are no assignments created'
          }
          // @ts-ignore
          subtitle={filters ? 'You can try changing the active filters' : null}
        />
      </TableWithFullPagination>
    </>
  );
};

export const LearningProgramAssignmentsListTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();
  const ability = useAbility(AbilityContext);
  return (
    <FilterBar
      id="learning-assignment-view"
      fields={[
        {
          name: 'name',
          label: 'Name',
          type: FieldType.string,
        },
        {
          name: 'type',
          label: 'Type',
          type: FieldType.select,
          loadOptions: mapAndLoadConstantsForSelect({
            ...(ability.can('list', 'elearning_course')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.ELEARNING]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.ELEARNING
                    ],
                }
              : {}),
            ...(ability.can('list', 'assessment_form')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.ASSESSMENT]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.ASSESSMENT
                    ],
                }
              : {}),
            ...(ability.can('list', 'learning_path')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.LEARNING_PATH]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.LEARNING_PATH
                    ],
                }
              : {}),
            ...(ability.can('list', 'live_course')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.LIVE_COURSE]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.LIVE_COURSE
                    ],
                }
              : {}),
            ...(ability.can('list', 'survey')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.SURVEY]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.SURVEY
                    ],
                }
              : {}),
            ...(ability.can('list', 'checklist')
              ? {
                  [C.I_LEARNING_ASSIGNMENT_TYPE.CHECKLIST]:
                    C.LEARNING_ASSIGNMENT_TYPE[
                      C.I_LEARNING_ASSIGNMENT_TYPE.CHECKLIST
                    ],
                }
              : {}),
          }),
        },
        {
          name: 'mandatory',
          label: 'Mandatory',
          type: FieldType.boolean,
        },
        {
          name: 'show_after_due_date',
          label: 'Show After Due Date',
          type: FieldType.boolean,
        },
        {
          name: 'due_date',
          label: 'Due Date',
          type: FieldType.datetime,
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const LearningProgramAssignmentsListTableLabel = ({
  variant = 'h3',
}: {
  variant?: TypographyProps['variant'];
}) => {
  const { query } = useListViewContext();
  const { data, isLoading } = useListLearningAssignmentsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  if (isLoading) {
    return <Typography variant={variant}>Fetching Rows</Typography>;
  }

  const actualCount = data?.count || 0;
  return (
    <Typography variant={variant}>
      {actualCount || 0} Learning Assignment
      {actualCount > 1 ? 's' : ''}
    </Typography>
  );
};

LearningProgramAssignmentsListTable.Label =
  LearningProgramAssignmentsListTableLabel;

LearningProgramAssignmentsListTable.Filters =
  LearningProgramAssignmentsListTableFilters;
