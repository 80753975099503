import { useCombobox, useMultipleSelection } from 'downshift';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { _ } from '@nl-lms/vendor';

import { useClassNameProps, useTestProps } from '../../hooks';
import { BadgeTypes, LightBadge } from '../Badge/Badge';
import { BadgeGroup } from '../BadgeGroup/BadgeGroup';
import { ArrowDownIcon, ArrowUpIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './DownshiftSelect.scss';
import { BaseSelectProps, SelectOption } from './Select.types';
import { filterMultiselectItems } from './lib';

export type AsyncMultiSelectProps = TidComponent<
  BaseSelectProps & {
    onChange: (items: Array<any>, name: string) => void;
    loadOptions: (inputValue: string) => Promise<SelectOption[]>;
    initialSelectedItems?: SelectOption[];
    children?: React.ReactNode;
    returnEntireItemOnChange?: boolean;
  }
>;

export const AsyncMultiSelect = (props: AsyncMultiSelectProps) => {
  const {
    onChange,
    onCreateOption,
    name,
    loadOptions,
    placeholder = 'Select or type value to search',
    isCreatable = false,
    isLoading = false,
    returnEntireItemOnChange = false,
    initialSelectedItems = [],
    hasError = false,
    disabled = false,
    children = null,
    initialHighlightedIndex = 0,
  } = props;
  const commonProps = useTestProps(props);
  const wrapperClassNameProps = useClassNameProps(
    'downshift-select__input-wrapper',
    hasError ? 'downshift-select__input-wrapper--has-error' : null,
    disabled ? 'downshift-select__input-wrapper--disabled' : null
  );
  const [inputValue, setInputValue] = useState('');
  const onSelectedItemsChange: any = useCallback(
    ({ selectedItems }) => {
      if (returnEntireItemOnChange) {
        onChange(selectedItems, name);
      } else {
        onChange(
          selectedItems.map((item) => item.value),
          name
        );
      }
    },
    [onChange, returnEntireItemOnChange, name]
  );

  const existingInitialSelectedItems = useMemo(() => {
    return initialSelectedItems.filter((e) => typeof e !== 'string');
  }, [initialSelectedItems]);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: existingInitialSelectedItems,
    onSelectedItemsChange,
  });

  const { isLoadOptionsLoading, debouncedSetInputAndLoadOptions, options } =
    useAsyncSelect({ loadOptions });
  const filteredItems = filterMultiselectItems(
    inputValue,
    options,
    selectedItems,
    isCreatable
  );

  const updateWithInitialSelectedItemsAfterFetch = useCallback(() => {
    const valueInitialSelectedItems = initialSelectedItems.filter(
      (e) => typeof e === 'string'
    );
    options.forEach((option) => {
      if (
        valueInitialSelectedItems.includes(option.value) &&
        !selectedItems.includes(option)
      ) {
        addSelectedItem(option);
      }
    });
  }, [initialSelectedItems, options]);

  useEffect(() => {
    updateWithInitialSelectedItemsAfterFetch();
  }, [initialSelectedItems, options]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    toggleMenu,
    closeMenu,
    openMenu,
  } = useCombobox({
    inputValue,
    items: filteredItems,
    initialHighlightedIndex,
    defaultHighlightedIndex: 0,
    onStateChange: ({
      inputValue: downshiftStateInputValue,
      type,
      selectedItem,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          // @ts-ignore
          setInputValue(downshiftStateInputValue);
          debouncedSetInputAndLoadOptions(downshiftStateInputValue);
          if (!isOpen) openMenu();
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue('');
          if (selectedItem as SelectOption) {
            addSelectedItem(selectedItem as SelectOption);
            selectItem(null);
          }
          closeMenu();
          break;
        default:
          break;
      }
    },
  });

  const createOptionHandler = useCallback(async () => {
    // @ts-ignore
    const result = await onCreateOption(inputValue);
    if (result) {
      addSelectedItem(result);
      setInputValue('');
      closeMenu();
    }
  }, [inputValue, closeMenu, onCreateOption, addSelectedItem]);

  return (
    <div className="downshift-select" {...commonProps}>
      <div {...wrapperClassNameProps} {...getComboboxProps({ disabled })}>
        <div className="downshift-select__input-and-selected-items">
          <div className="downshift-select__selected-items">
            <BadgeGroup
              name="multiSelectItems"
              type={BadgeTypes.LIGHT}
              reorderTags={false}
            >
              {selectedItems.map((item: SelectOption, index) => (
                <LightBadge
                  label={item.label}
                  key={`selected-item-${index}`}
                  onClose={() => removeSelectedItem(item)}
                  {...getSelectedItemProps({ selectedItem: item, index })}
                />
              ))}
            </BadgeGroup>
          </div>
          <input
            {...getInputProps({
              placeholder,
              disabled,
              ...getDropdownProps({ preventKeyAction: isOpen }),
              onClick: toggleMenu,
            })}
            name={name}
            className={`downshift-select__input ${
              disabled ? 'downshift-select__input--disabled' : ''
            }`}
          />
        </div>
        <div className="downshift-select__actions">
          <div className="downshift-select__input-icons">
            {isLoading || isLoadOptionsLoading ? (
              <div className="downshift-select__spinner" />
            ) : null}
            {children}
            <div
              className={`downshift-select__input-icon ${
                disabled ? 'downshift-select__input-icon--disabled' : ''
              }`}
              {...getToggleButtonProps({ disabled })}
              aria-label="toggle menu"
            >
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        </div>
      </div>
      <div
        {...getMenuProps()}
        className="downshift-select__options-menu-wrapper"
      >
        {isOpen ? (
          <ul className="downshift-select__options-menu">
            {filteredItems.map((item, index) => (
              <li
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                className={`downshift-select__option ${
                  highlightedIndex === index
                    ? 'downshift-select__option--active'
                    : ''
                }`}
              >
                {item.Component ? (
                  <item.Component label={item.label} entity={item.entity} />
                ) : (
                  item.label
                )}
              </li>
            ))}

            {filteredItems.length === 0 && isCreatable ? (
              <li
                key="creat-option"
                className={`downshift-select__option downshift-select__option--active`}
                onClick={createOptionHandler}
              >
                {`Create ${inputValue}`}
              </li>
            ) : null}

            {filteredItems.length === 0 && !isCreatable ? (
              <li
                key="no-options"
                className="downshift-select__option downshift-select__option--none-available"
              >
                No options available
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

const useAsyncSelect = ({ loadOptions }) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoadOptionsLoading, setIsLoadOptionsLoading] = useState(false);
  const loadOptionsAndUpdateState = useCallback(
    async (inputValue) => {
      setIsLoadOptionsLoading(true);
      let options = [];
      try {
        const res = await loadOptions(inputValue);
        options = res;
        setOptions(res);
      } catch (e) {
      } finally {
        setIsLoadOptionsLoading(false);
      }
      return options;
    },
    [setIsLoadOptionsLoading, setOptions]
  );
  const debouncedSetInputAndLoadOptions = useCallback(
    _.debounce((inputValue) => loadOptionsAndUpdateState(inputValue), 200),
    [loadOptionsAndUpdateState, setOptions]
  );

  useEffect(() => {
    loadOptionsAndUpdateState('');
  }, [loadOptionsAndUpdateState]);

  return {
    debouncedSetInputAndLoadOptions,
    isLoadOptionsLoading,
    options,
    loadOptionsAndUpdateState,
  };
};
