import { z } from 'zod';

export enum RegistrationStatus {
  // Initial status before a learner confirms by email
  emailUnconfirmed = 1,
  // The learner has confirmed by email and the admin needs to decide what to do next
  waitingAction,
  // Intermediary status. It exists because:
  // - the admin might need to do additional actions (accept payments, etc)
  // - the learner needs to be informed about this state
  inReview,
  // Admin has decided that the learner is not allowed to register
  // Is accompanied by a decline reason that is set by the admin
  declined,
  // Final status - This state cannot be changed
  // All the checks have passed
  // and the learner is added to the learning instace/event
  completed,
  // Final Status - This state cannot be changed
  // This email is not allowed to register on any type of learning content
  blocked,
}

export const RegistrationPayloadSchema = z.object({
  answers: z.array(
    z.object({
      questionId: z.string(),
      value: z.array(z.string()),
    }),
  ),
  details: z.record(z.string(), z.string()),
});

export const RegistrationSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  status: z.nativeEnum(RegistrationStatus),
  payload: RegistrationPayloadSchema,
  surveyFormId: z.string(),
  surveyFormInstanceId: z.string().nullable(),
  trainingSessionId: z.string().nullable(),
  assessmentFormId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  learnerId: z.string().nullable(),
  trainingSessionLearnerId: z.string().nullable(),
  assessmentFormInstanceId: z.string().nullable(),
  elearningSessionId: z.string().nullable(),
  declineReason: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Registration = z.infer<typeof RegistrationSchema>;
