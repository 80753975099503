import { AppQuery } from '@nl-lms/common/shared';

import { ApiService } from '../lib';
import { IApiService } from './types';

export class ElearningSession
  extends ApiService
  implements IApiService<'elearningSession'>
{
  public paths: any;
  public serviceBase: string = `/elearning_session`;

  constructor(props) {
    super(props);
    this.paths = {
      list: () => this.serviceBase,
      create: () => this.serviceBase,
      remove: (id) => `${this.serviceBase}/${id}`,
      updateResult: (id) => `${this.serviceBase}/${id}/result`,
      reset: (id) => `${this.serviceBase}/${id}/reset`,
      listAllLearners: () => `/list/learner`,
    };
  }

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), { params: { query } });

  create = (data) =>
    this.api.post(this.paths.create(), {
      learner_id: data.learnerId,
      elearning_course_id: data.elearningCourseId,
      score: data.score,
      status: data.status,
      started_on: data.startedOn,
      finished_on: data.finishedOn,
      time_spent: data.timeSpent,
    });

  remove = (id) => this.api.delete(this.paths.remove(id));

  updateResult = (payload) =>
    this.api.post(this.paths.updateResult(payload.id), {
      score: payload.score,
      status: payload.status,
      started_on: payload.startedOn,
      finished_on: payload.finishedOn,
      time_spent: payload.timeSpent,
    });

  reset = (id) => this.api.post(this.paths.reset(id));

  listAllLearners = () => this.api.get(this.paths.listAllLearners());
}
