import React from 'react';

export const PlayerIconSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1H2C1.44772 1 1 1.51167 1 2.14286V7.85714C1 8.48833 1.44772 9 2 9H10C10.5523 9 11 8.48833 11 7.85714V2.14286C11 1.51167 10.5523 1 10 1Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4 12H8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M6 9V11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 3.5V6.5L7.5 5L5.5 3.5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinejoin="bevel"
    />
  </svg>
);
