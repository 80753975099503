import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../Box/Box';
import { StarIcon } from '../Icon';
import { Typography } from '../Typography/Typography';
import './Rating.scss';

type RatingProps = {
  total: number;
  rating: number;
  reviewCount?: number;
  iconEmpty?: ReactNode;
  iconFilled?: ReactNode;
  iconHalf?: ReactNode;
};

export const Rating = ({
  total = 5,
  rating,
  reviewCount,
  iconEmpty = <StarIcon type="outline" />,
  iconFilled = <StarIcon type="filled" />,
  iconHalf = <StarIcon type="half" />,
}: RatingProps) => {
  const totalArr = new Array(total).fill(0);
  const textStyle = { color: '#7a7f82', fontSize: '13px' };

  const { t } = useTranslation('learner');

  const getIconType = (index) => {
    if (index < rating && rating < index + 1) {
      if (rating < index + 0.5) return iconEmpty;
      else return iconHalf;
    } else if (index < rating) return iconFilled;
    else return iconEmpty;
  };

  return (
    <Box
      flex={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        flex={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 's',
        }}
      >
        {rating <= total && !!rating ? (
          <Box
            flex={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {totalArr.map((i, index) => (
              <span
                className={`rating__item ${
                  index >= rating ? 'rating__item--empty' : ''
                }`}
              >
                {getIconType(index)}
              </span>
            ))}
          </Box>
        ) : (
          <Typography.p style={textStyle}>
            {rating > total
              ? t('common.rating.invalidData')
              : t('common.rating.noRating')}
          </Typography.p>
        )}
        <Typography.p style={textStyle}>
          {rating} / {total}
        </Typography.p>
      </Box>
      {reviewCount && (
        <div className="rating__reviews">
          <Typography.p style={textStyle}>
            {`(${reviewCount}`}{' '}
            {reviewCount > 1
              ? `${t('common.rating.reviews')})`
              : `${t('common.rating.review')})`}
          </Typography.p>
        </div>
      )}
    </Box>
  );
};
