import React, { forwardRef, useState, useEffect, useCallback } from 'react';

import { ToggleInput } from '../ToggleInput/ToggleInput';
import { Typography } from '../Typography/Typography';

import './SwitchInput.scss';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { TidComponent } from '../index.types';

type SwitchInputValue = string | number;

type SwitchInputOption<T extends SwitchInputValue> = {
  label: string;
  value: T;
};

type Props<T extends SwitchInputValue> = TidComponent<{
  name: string;
  options: [SwitchInputOption<T>, SwitchInputOption<T>];
  className?: string;
  initialActiveOption?: T;
  activeOption?: T;
  onChange: (value: T) => void;
  disabled?: boolean;
}>;

export const SwitchInput = forwardRef<HTMLInputElement, Props<any>>(
  (props, ref) => {
    const {
      name,
      activeOption: _activeOption = undefined,
      disabled = false,
      options,
      initialActiveOption = null,
      onChange,
    } = props;

    const commonProps = useTestProps(props);
    const classNameProps = useClassNameProps('switch-input', props);

    const [activeOption, setActiveOption] = useState(() => {
      if (!initialActiveOption) return options[0].value;
      const active = options.find((o) => o.value === initialActiveOption);
      if (!active) {
        console.error(
          `Invalid value for the initial active option ${initialActiveOption}`
        );
        return options[0].value;
      }
      return active.value;
    });
    useEffect(() => {
      if (typeof _activeOption !== 'undefined') {
        setActiveOption(_activeOption);
      }
    }, [_activeOption]);

    const onChangeToggleInput = useCallback(
      (checked) => {
        if (checked) {
          setActiveOption(options[1].value);
          return onChange(options[1].value);
        }
        setActiveOption(options[0].value);
        return onChange(options[0].value);
      },
      [onChange]
    );
    return (
      <div {...classNameProps} {...commonProps}>
        <Typography.h5
          className={`switch-input__option-label ${
            options[0].value === activeOption
              ? 'switch-input__option-label--active'
              : ''
          }`}
        >
          {options[0].label}
        </Typography.h5>
        <ToggleInput
          name={name}
          onChange={onChangeToggleInput}
          mode="switch"
          ref={ref}
          defaultChecked={options[1].value === initialActiveOption}
          disabled={disabled}
        />
        <Typography.h5
          className={`switch-input__option-label ${
            options[1].value === activeOption
              ? 'switch-input__option-label--active'
              : ''
          }`}
        >
          {options[1].label}
        </Typography.h5>
      </div>
    );
  }
);
