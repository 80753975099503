import { matchSorter } from 'match-sorter';
import { SelectOption } from './Select.types';

export function filterMultiselectItems(
  filter,
  options,
  selectedItems,
  isCreatable
) {
  const selectedItemsLabels = selectedItems.map((item) => item.label);
  const remainingOptions = options.filter(
    (o) => !selectedItemsLabels.includes(o.label)
  );
  const items = filter
    ? matchSorter(remainingOptions, filter, {
        keys: ['label'],
      })
    : remainingOptions;
  if (!items.length && isCreatable) {
    return [
      {
        value: 'create',
        label: `Create ${filter}`,
      },
    ];
  }
  return items;
}

export const parseInitialSelectedItems = (initialSelectedItems, options) =>
  initialSelectedItems.reduce((acc, item) => {
    if (typeof item === 'string' || typeof item === 'number') {
      const option = options.find((o) => o.value === item);
      if (option) return [...acc, option];
      return acc;
    }
    if (!item) return acc;
    return [...acc, item];
  }, []);
