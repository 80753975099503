import {
  LearningAssignment,
  LearningAssignmentLearningContentType,
} from '@nl-lms/common/feature/types';

export function getLearningAssignmentLearningContentType(
  learningAssignment: LearningAssignment
) {
  if (learningAssignment.elearningCourseId)
    return LearningAssignmentLearningContentType.elearning;
  if (learningAssignment.learningPathId)
    return LearningAssignmentLearningContentType['learning-path'];
  if (learningAssignment.assessmentFormId)
    return LearningAssignmentLearningContentType.assessment;
  if (learningAssignment.surveyFormId)
    return LearningAssignmentLearningContentType.survey;
  if (learningAssignment.checklistId)
    return LearningAssignmentLearningContentType.checklist;
  return LearningAssignmentLearningContentType['live-learning'];
}
