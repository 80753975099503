import { z } from 'zod';

export const ErrorResponseSchema = z.object({
  name: z.string(),
  message: z.string(),
  code: z.number(),
});

export const ContractStandardResponse = <T>(Schema: T) => ({
  200: Schema,
  400: ErrorResponseSchema,
});

export const RequestQuery = <T>(Schema: T) => ({
  200: Schema,
  400: ErrorResponseSchema,
});

export const ContractPaginatedResponse = <T>(ResourceSchema: z.ZodType<T>) => {
  return {
    200: z.object({
      rows: z.array(ResourceSchema),
      count: z.number(),
    }),
    400: ErrorResponseSchema,
  };
};
export const BooleanResponseSchema = z.boolean();
export const DeleteResourcesResponseSchema = z.union([
  z.boolean(),
  z.object({ jobId: z.union([z.string(), z.number()]) }),
]);
export const EmptyObjectSchema = z.object({});
