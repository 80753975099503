import { FieldType } from '@nl-lms/common/shared';
import {
  LearningAssignmentAutomation,
  LearningAssignmentDomainEventNames,
} from '@nl-lms/feature/learning-assignments/sdk';
import { C } from '@nl-lms/ui/constants';

import {
  fetchAndMapAssignments,
  fetchAndMapLearnerGroups,
  fetchAndMapLearners,
  mapAndLoadValArrayForSelect,
} from '../../../_common/utils/fetchEntitiesForSelectMethods';
import { AssignationRuleEvent, AssignationRuleEventEntityType } from './types';

export const AssignationRuleEventEntityTypeToLabel: Record<
  AssignationRuleEventEntityType,
  string
> = {
  assignmentId: 'Assignment',
  learnerId: 'Learner',
  learnerGroupId: 'Learner Group',
};

export const DefaultReferenceEntities = {
  assignmentId: {
    value: 'current-assignment',
    label: 'Same Assignment',
  },
  learnerId: {
    value: 'any-learner',
    label: 'Any Learner',
  },
};

export const AssignationRuleEventPayloadToOptions = {
  assignmentId: {
    name: 'assignmentId',
    label: 'Assignment',
    type: FieldType.select,
    loadOptions: fetchAndMapAssignments,
  },
  learnerId: {
    name: 'learnerId',
    label: 'Audience Learner',
    type: FieldType.select,
    loadOptions: fetchAndMapLearners,
  },
  learnerGroupId: {
    name: 'learnerGroupId',
    label: 'Audience Group',
    type: FieldType.select,
    loadOptions: fetchAndMapLearnerGroups,
  },
};

export const AssignationRuleMatchFieldNamesToTypes = {
  status: FieldType.select,
  scheduleDate: FieldType.date,
  dueDate: FieldType.date,
  firstName: FieldType.string,
  lastName: FieldType.string,
  email: FieldType.string,
  isManager: FieldType.boolean,
  isActive: FieldType.boolean,
  learnerGroupIds: FieldType.jsonArraySelect,
  learnerGroupId: FieldType.jsonArraySelect,
};

export const AssignationRuleMatchFilterFieldNames = {
  ASSIGNMENT_STATUS: {
    name: 'status',
    label: 'Assignment Status',
    type: AssignationRuleMatchFieldNamesToTypes['status'],
    loadOptions: mapAndLoadValArrayForSelect(
      C.LEARNING_ASSIGNMENT_INSTANCE_STATUS
    ),
  },
  ASSIGNMENT_START_DATE: {
    name: 'startDate',
    label: 'Assignment Start Date',
    type: AssignationRuleMatchFieldNamesToTypes['startDate'],
  },
  ASSIGNMENT_DUE_DATE: {
    name: 'dueDate',
    label: 'Assignment Due Date',
    type: AssignationRuleMatchFieldNamesToTypes['dueDate'],
  },
  ASSIGNMENT_AUDIENCE: {
    name: 'learnerGroupIds',
    label: 'Assignment Audience',
    type: AssignationRuleMatchFieldNamesToTypes['learnerGroupIds'],
    loadOptions: fetchAndMapLearnerGroups,
  },
  LEARNER_FIRST_NAME: {
    name: 'firstName',
    label: 'Learner First Name',
    type: AssignationRuleMatchFieldNamesToTypes['firstName'],
  },
  LEARNER_LAST_NAME: {
    name: 'lastName',
    label: 'Learner Last Name',
    type: AssignationRuleMatchFieldNamesToTypes['lastName'],
  },
  LEARNER_EMAIL: {
    name: 'email',
    label: 'Learner Email',
    type: AssignationRuleMatchFieldNamesToTypes['email'],
  },
  LEARNER_IS_MANAGER: {
    name: 'isManager',
    label: 'Learner Is Manager',
    type: AssignationRuleMatchFieldNamesToTypes['isManager'],
  },
  LEARNER_LEARNER_GROUP: {
    name: 'learnerGroupId',
    label: 'Learner Learner Group',
    type: AssignationRuleMatchFieldNamesToTypes['learnerGroupId'],
    loadOptions: fetchAndMapLearnerGroups,
  },
  LEARNER_IS_ACTIVE: {
    name: 'isActive',
    label: 'Learner Is Active',
    type: AssignationRuleMatchFieldNamesToTypes['isActive'],
  },
};

export const AssignationRuleEventPayload: AssignationRuleEvent[] = [
  {
    name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceCreated,
    fieldLabel: 'Learning Assignment Instance is Created',
    label: 'Instance is Created',
    type: FieldType.select,
    payload: [
      {
        ...AssignationRuleEventPayloadToOptions['assignmentId'],
        mandatory: false,
      },
      // {
      //   ...AssignationRuleEventPayloadToOptions['learnerId'],
      //   mandatory: false,
      // },
    ],
    mandatoryFilterFields: false,
    filterFields: [
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_STATUS,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
      AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
      AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
      AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
    ],
  },
  // {
  //   name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceUpdated,
  //   label: 'Instance Is Updated',
  //   fieldLabel: 'Learning Assignment Instance is Updated',
  //   type: FieldType.select,
  //   payload: [
  //     {
  //       ...AssignationRuleEventPayloadToOptions['assignmentId'],
  //       mandatory: false,
  //     },
  //     // {
  //     //   ...AssignationRuleEventPayloadToOptions['learnerId'],
  //     //   mandatory: false,
  //     // },
  //   ],
  //   mandatoryFilterFields: true,
  //   filterFields: [
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_STATUS,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
  //   ],
  // },
  // {
  //   name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceRemoved,
  //   label: 'Instance Is Removed',
  //   fieldLabel: 'Learning Assignment Instance is Removed',
  //   type: FieldType.select,
  //   payload: [
  //     {
  //       ...AssignationRuleEventPayloadToOptions['assignmentId'],
  //       mandatory: false,
  //     },
  //     // {
  //     //   ...AssignationRuleEventPayloadToOptions['learnerId'],
  //     //   mandatory: false,
  //     // },
  //   ],
  //   mandatoryFilterFields: false,
  //   filterFields: [
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_STATUS,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
  //   ],
  // },
  {
    name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceStarted,
    label: 'Instance Is Started',
    fieldLabel: 'Learning Assignment Instance is Started',
    type: FieldType.select,
    payload: [
      {
        ...AssignationRuleEventPayloadToOptions['assignmentId'],
        mandatory: false,
      },
      // {
      //   ...AssignationRuleEventPayloadToOptions['learnerId'],
      //   mandatory: false,
      // },
    ],
    mandatoryFilterFields: false,
    filterFields: [
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
      AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
      AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
      AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
    ],
  },
  {
    name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
    label: 'Instance Is Completed',
    fieldLabel: 'Learning Assignment Instance is Completed',
    type: FieldType.select,
    payload: [
      {
        ...AssignationRuleEventPayloadToOptions['assignmentId'],
        mandatory: false,
      },
      // {
      //   ...AssignationRuleEventPayloadToOptions['learnerId'],
      //   mandatory: false,
      // },
    ],
    mandatoryFilterFields: false,
    filterFields: [
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
      AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
      AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
      AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
    ],
  },
  {
    name: LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
    label: 'Instance Is Failed',
    fieldLabel: 'Learning Assignment Instance is Failed',
    type: FieldType.select,
    payload: [
      {
        ...AssignationRuleEventPayloadToOptions['assignmentId'],
        mandatory: false,
      },
      // {
      //   ...AssignationRuleEventPayloadToOptions['learnerId'],
      //   mandatory: false,
      // },
    ],
    mandatoryFilterFields: false,
    filterFields: [
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_START_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
      AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
      AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
      AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
    ],
  },
  {
    name: LearningAssignmentDomainEventNames.LearnerJoinedAudience,
    label: 'Audience joined by Learner',
    fieldLabel: 'Learner joined Assignment Audience',
    type: FieldType.select,
    payload: [
      {
        ...AssignationRuleEventPayloadToOptions['assignmentId'],
        mandatory: false,
      },
      // {
      //   ...AssignationRuleEventPayloadToOptions['learnerId'],
      //   mandatory: false,
      // },
    ],
    mandatoryFilterFields: false,
    filterFields: [
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
      AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
      AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
      AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
      AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
      AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
    ],
  },
  // {
  //   name: LearningAssignmentDomainEventNames.LearnerLeftAudience,
  //   label: 'Audience left by Learner',
  //   fieldLabel: 'Learner left Assignment Audience',
  //   type: FieldType.select,
  //   payload: [
  //     {
  //       ...AssignationRuleEventPayloadToOptions['assignmentId'],
  //       mandatory: false,
  //     },
  //     // {
  //     //   ...AssignationRuleEventPayloadToOptions['learnerId'],
  //     //   mandatory: false,
  //     // },
  //   ],
  //   mandatoryFilterFields: false,
  //   filterFields: [
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_EMAIL,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_FIRST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LAST_NAME,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_ACTIVE,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_IS_MANAGER,
  //     AssignationRuleMatchFilterFieldNames.LEARNER_LEARNER_GROUP,
  //   ],
  // },
  // {
  //   name: LearningAssignmentDomainEventNames.LearningAssignmentUpdated,
  //   label: 'Is Updated',
  //   fieldLabel: 'Learning Assignment is Updated',
  //   type: FieldType.select,
  //   payload: [
  //     {
  //       ...AssignationRuleEventPayloadToOptions['assignmentId'],
  //       mandatory: false,
  //     },
  //   ],
  //   mandatoryFilterFields: true,
  //   filterFields: [
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
  //   ],
  // },
  // {
  //   name: LearningAssignmentDomainEventNames.LearningAssignmentRemoved,
  //   label: 'Is Removed',
  //   fieldLabel: 'Learning Assignment is Removed',
  //   type: FieldType.select,
  //   payload: [
  //     {
  //       ...AssignationRuleEventPayloadToOptions['assignmentId'],
  //       mandatory: true,
  //     },
  //   ],
  //   mandatoryFilterFields: false,
  //   filterFields: [
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_DUE_DATE,
  //     AssignationRuleMatchFilterFieldNames.ASSIGNMENT_AUDIENCE,
  //   ],
  // },
];

const AssignationRuleStartDateOffsetConditionDayOptions = {
  day: {
    anyDay: { label: 'Any Days', value: 'anyDay', children: [] },
    weekdays: { label: 'Weekdays', value: 'weekdays', children: [] },
    customDay: {
      label: 'Custom Days',
      value: 'customDay',
      children: [
        {
          value: 'M',
          label: 'M',
        },
        {
          value: 'TU',
          label: 'TU',
        },
        {
          value: 'W',
          label: 'W',
        },
        {
          value: 'TH',
          label: 'TH',
        },
        {
          value: 'F',
          label: 'F',
        },
        {
          value: 'SA',
          label: 'SA',
        },
        {
          value: 'SU',
          label: 'SU',
        },
      ],
    },
  },
};

const AssignationRuleStartDateOffsetConditionWeekOptions = {
  week: {
    anyWeek: { label: 'Any Week', value: 'anyWeek', children: [] },
    firstWeek: { label: 'First Week', value: 'firstWeek', children: [] },
    lastWeek: { label: 'Last Week', value: 'lastWeek', children: [] },
    customWeek: {
      label: 'Custom Weeks',
      value: 'customWeek',
      children: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
      ],
    },
  },
};

const AssignationRuleStartDateOffsetConditionMonthOptions = {
  month: {
    anyMonth: { label: 'Any Month', value: 'anyMonth', children: [] },
    firstMonth: { label: 'First Month', value: 'firstMonth', children: [] },
    lastMonth: { label: 'Last Month', value: 'lastMonth', children: [] },
    customMonth: {
      label: 'Custom Months',
      value: 'customMonth',
      children: [
        {
          value: 'Jan',
          label: 'Jan',
        },
        {
          value: 'Feb',
          label: 'Feb',
        },
        {
          value: 'Mar',
          label: 'Mar',
        },
        {
          value: 'Apr',
          label: 'Apr',
        },
        {
          value: 'May',
          label: 'May',
        },
        {
          value: 'Jun',
          label: 'Jun',
        },
        {
          value: 'Jul',
          label: 'Jul',
        },
        {
          value: 'Aug',
          label: 'Aug',
        },
        {
          value: 'Sep',
          label: 'Sep',
        },
        {
          value: 'Oct',
          label: 'Oct',
        },
        {
          value: 'Nov',
          label: 'Nov',
        },
        {
          value: 'Dec',
          label: 'Dec',
        },
      ],
    },
  },
};

export const AssignationRuleStartDateOffsetConditionOptions: Record<
  'weeks' | 'months' | 'years',
  | Record<
      'day',
      Record<
        'anyDay' | 'weekdays' | 'customDay',
        Record<'label' | 'children' | 'value', string | any[]>
      >
    >
  | Record<
      'week',
      Record<
        'anyWeek' | 'firstWeek' | 'lastWeek' | 'customWeek',
        Record<'label' | 'children' | 'value', string | any[]>
      >
    >
  | Record<
      'month',
      Record<
        'anyMonth' | 'firstMonth' | 'lastMonth' | 'customMonth',
        Record<'label' | 'children' | 'value', string | any[]>
      >
    >
> = {
  weeks: {
    ...AssignationRuleStartDateOffsetConditionDayOptions,
  },
  months: {
    ...AssignationRuleStartDateOffsetConditionDayOptions,
    ...AssignationRuleStartDateOffsetConditionWeekOptions,
  },
  years: {
    ...AssignationRuleStartDateOffsetConditionDayOptions,
    ...AssignationRuleStartDateOffsetConditionWeekOptions,
    ...AssignationRuleStartDateOffsetConditionMonthOptions,
  },
};

export enum AssignationRuleTemplateTypes {
  autoAssign = 'auto-assign',
  autoAssignFixed = 'auto-assign-fixed',
  recurrence = 'recurrence',
  recurrenceFailed = 'recurrence-failed',
  assignStarted = 'assign-started',
  assignCompleted = 'assign-completed',
  assignFailed = 'assign-failed',
  assignCreated = 'assign-created',
}

export const AssignationRuleTemplates = [
  {
    label: 'Auto-assign (learner joins audience)',
    value: AssignationRuleTemplateTypes.autoAssign,
    payload: {
      selectTriggerTimes: true,
      selectStartDate: true,
      selectReference: false,
      selectLearnerGroup: false,
      name: 'Auto-Assign',
      startDate: null,
      conditionsEquation: {
        value: {
          eventName: LearningAssignmentDomainEventNames.LearnerJoinedAudience,
          // learner: { id: 'any-learner', label: 'Any Learner' },
          // learnerId: 'any-learner',
          reference: {
            id: DefaultReferenceEntities.assignmentId.value,
            label: DefaultReferenceEntities.assignmentId.label,
          },
          referenceId: DefaultReferenceEntities.assignmentId.value,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    label: 'Assign on recurrence',
    value: AssignationRuleTemplateTypes.recurrence,
    payload: {
      selectTriggerTimes: true,
      selectStartDate: true,
      selectReference: false,
      selectLearnerGroup: false,
      name: 'Recurrence',
      startDate: null,
      conditionsEquation: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
          // learner: { id: 'any-learner', label: 'Any Learner' },
          reference: {
            id: DefaultReferenceEntities.assignmentId.value,
            label: DefaultReferenceEntities.assignmentId.label,
          },
          // learnerId: 'any-learner',
          referenceId: DefaultReferenceEntities.assignmentId.value,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    label: 'Reassign on failure',
    value: AssignationRuleTemplateTypes.recurrenceFailed,
    payload: {
      selectTriggerTimes: true,
      selectStartDate: true,
      selectReference: false,
      selectLearnerGroup: false,
      name: 'Reassign On Failure',
      startDate: null,
      conditionsEquation: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
          // learner: { id: 'any-learner', label: 'Any Learner' },
          reference: {
            id: DefaultReferenceEntities.assignmentId.value,
            label: DefaultReferenceEntities.assignmentId.label,
          },
          // learnerId: 'any-learner',
          referenceId: DefaultReferenceEntities.assignmentId.value,
          match: null,
        },
        combinator: null,
      },
    },
  },
  // Comment this for now. If they want to connect assignments they should use
  // programs
  // {
  //   label: 'Other assignment is started',
  //   value: AssignationRuleTemplateTypes.assignStarted,
  //   payload: {
  //     selectReference: true,
  //     selectRelativeTime: true,
  //     selectFixedTime: false,
  //     selectTriggerTimes: false,
  //     selectLearnerGroup: false,
  //     name: 'Assignment Started',
  //     startDate: {
  //       type: 'relative',
  //       options: {},
  //       value: null,
  //     },
  //     settings: { triggerNTimes: '1' },
  //     conditionsEquation: {
  //       value: {
  //         eventName:
  //           LearningAssignmentDomainEventNames.LearningAssignmentInstanceStarted,
  //         learner: { id: 'any-learner', label: 'Any Learner' },
  //         reference: {},
  //         match: null,
  //       },
  //       combinator: null,
  //     },
  //   },
  // },
  // {
  //   label: 'Other assignment is completed',
  //   value: AssignationRuleTemplateTypes.assignCompleted,
  //   payload: {
  //     selectReference: true,
  //     selectRelativeTime: true,
  //     selectFixedTime: false,
  //     selectTriggerTimes: false,
  //     selectLearnerGroup: false,
  //     name: 'Assignment Completed',
  //     startDate: {
  //       type: 'relative',
  //       options: {},
  //       value: null,
  //     },
  //     settings: { triggerNTimes: '1' },
  //     conditionsEquation: {
  //       value: {
  //         eventName:
  //           LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
  //         learner: { id: 'any-learner', label: 'Any Learner' },
  //         reference: {},
  //         match: null,
  //       },
  //       combinator: null,
  //     },
  //   },
  // },
  // {
  //   label: 'Other assignment is created',
  //   value: AssignationRuleTemplateTypes.assignCreated,
  //   payload: {
  //     selectReference: true,
  //     selectRelativeTime: true,
  //     selectFixedTime: false,
  //     selectTriggerTimes: false,
  //     selectLearnerGroup: false,
  //     name: 'Assignment Created',
  //     startDate: {
  //       type: 'relative',
  //       options: {},
  //       value: null,
  //     },
  //     settings: { triggerNTimes: '1' },
  //     conditionsEquation: {
  //       value: {
  //         eventName:
  //           LearningAssignmentDomainEventNames.LearningAssignmentInstanceCreated,
  //         learner: { id: 'any-learner', label: 'Any Learner' },
  //         reference: {},
  //         match: null,
  //       },
  //       combinator: null,
  //     },
  //   },
  // },
  // {
  //   label: 'Other assignment is failed',
  //   value: AssignationRuleTemplateTypes.assignFailed,
  //   payload: {
  //     selectReference: true,
  //     selectRelativeTime: true,
  //     selectFixedTime: false,
  //     selectTriggerTimes: false,
  //     selectLearnerGroup: false,
  //     name: 'Assignment Failed',
  //     startDate: {
  //       type: 'relative',
  //       options: {},
  //       value: null,
  //     },
  //     settings: { triggerNTimes: '1' },
  //     conditionsEquation: {
  //       value: {
  //         eventName:
  //           LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
  //         learner: { id: 'any-learner', label: 'Any Learner' },
  //         reference: {},
  //         match: null,
  //       },
  //       combinator: null,
  //     },
  //   },
  // },
];

export const AssignmentRuleMatchOperatorToPayloadOperator = {
  Equals: 'equals',
  NEqual: 'notEquals',
  Contains: 'contains',
  DoesNotContain: 'doesNotContain',
  JsonArrayIncludes: 'jsonArrayIncludes',
  JsonArrayDoesNotInclude: 'jsonArrayDoesNotInclude',
  Includes: 'includes',
  Excludes: 'doesNotInclude',
  GreaterThan: 'greaterThan',
  GreaterThanEqual: 'greaterThanOrEqual',
  LowerThanEqual: 'lowerThanOrEqual',
  LowerThan: 'lowerThan',
  IsTrue: 'isTrue',
  IsFalse: 'isFalse',
};

export const AssignmentRuleMatchPayloadOperatorToLabel = {
  equals: 'is equal to',
  notEquals: 'is not equal to',
  greaterThan: 'is greater than',
  greaterThanOrEqual: 'is greater or equal than',
  lowerThan: 'is lower than',
  lowerThanOrEqual: 'is lower or equal than',
  contains: 'contains',
  doesNotContain: 'does not contain',
  isTrue: 'is true',
  isFalse: 'is false',
  includes: 'includes',
  doesNotInclude: 'excludes',
  jsonArrayIncludes: 'includes',
  jsonArrayDoesNotInclude: 'excludes',
};
