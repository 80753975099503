import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import store from 'store';

import { _ } from '@nl-lms/vendor';
import { useHotkey, useRoutingAction } from '@nl-lms/ui/hooks';
import { getModifierKey } from '@nl-lms/ui/utils';
import {
  SelectInput,
  SelectInputContainer,
  SelectLoadingSpinner,
  SelectMenu,
  SelectOption,
  SelectProvider,
} from '../../components/Select/Select';
import * as Icon from '../../components/Icon';

import './GlobalSearchBar.scss';

type SearchResult<Entity> = {
  id: string;
  path: string;
  label: string;
  typeLabel: string;
  entity: Entity;
  Component?: React.FunctionComponent<{ entity: Entity }>;
};

interface GlobalSearchBarProps<Entity> {
  name: string;
  searchResults: SearchResult<Entity>[];
  search: (searchTerm: string) => Promise<void> | void;
  isLoading?: boolean;
  hasError?: boolean;
  className?: string;
  placeholder?: string;
}

export type SearchHistoryEntry = {
  id: string;
  path: string;
  type: string;
  label: string;
};

interface SearchHistory {
  accessedResults: SearchHistoryEntry[];
  accessedPages: SearchHistoryEntry[];
}

export const GlobalSearchBarContext = createContext<{
  searchHistory: SearchHistory;
  addAccessedResult: (searchResult: SearchHistoryEntry) => void;
  addAccessedPage: (searchHistory: SearchHistoryEntry) => void;
// @ts-ignore
}>(null);

export function GlobalSearchBar<Entity>({
  searchResults,
  name,
  search,
  isLoading = false,
  hasError = false,
  className = '',
  placeholder = 'Search for anything',
}: GlobalSearchBarProps<Entity>) {
  return (
    <GlobalSearchBarProvider name={name}>
      <GlobalSearchBarInput
        name={name}
        searchResults={searchResults}
        search={search}
        isLoading={isLoading}
        hasError={hasError}
        className={className}
        placeholder={placeholder}
      />
    </GlobalSearchBarProvider>
  );
}

export const GlobalSearchBarProvider = ({ children, name }) => {
  const [searchHistory, setSearchHistory] = useState<SearchHistory>(() => {
    return hydrateSearchHistory(name);
  });
  const updateSearchHistory = useCallback(
    (
      result: { id: string; label: string; path: string; type: string },
      type: keyof SearchHistory
    ) => {
      const historyEntries = searchHistory[type];
      const existentIndex = historyEntries.findIndex((r) => r.id === result.id);
      let newHistoryEntries = [...historyEntries];
      if (existentIndex !== -1) {
        newHistoryEntries[existentIndex] = historyEntries[0];
        newHistoryEntries[0] = result;
      } else {
        newHistoryEntries = [result, ...newHistoryEntries];
      }
      const newSearchHistory = {
        ...searchHistory,
        [type]: newHistoryEntries.slice(0, 5),
      };
      setSearchHistory(newSearchHistory);
      persistSearchHistory(name, newSearchHistory);
    },
    [name, searchHistory]
  );

  const addAccessedPage = useCallback(
    (accessedPage: SearchHistoryEntry) => {
      updateSearchHistory(accessedPage, 'accessedPages');
    },
    [updateSearchHistory]
  );
  const addAccessedResult = useCallback(
    (accessedResult: SearchHistoryEntry) => {
      updateSearchHistory(accessedResult, 'accessedResults');
    },
    [updateSearchHistory]
  );

  return (
    <GlobalSearchBarContext.Provider
      value={{ searchHistory, addAccessedPage, addAccessedResult }}
    >
      {children}
    </GlobalSearchBarContext.Provider>
  );
};

export function GlobalSearchBarInput<Entity>({
  searchResults,
  name,
  search,
  isLoading,
  hasError,
  className,
  placeholder,
}: GlobalSearchBarProps<Entity>) {
  const { searchHistory, addAccessedResult } = useContext(
    GlobalSearchBarContext
  );
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const [searchWasCalled, setSearchWasCalled] = useState(false);
  const focusInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  useHotkey(`${getModifierKey()}+k`, focusInput);
// @ts-ignore
  const options = useMemo<SelectOption<Entity>[]>(() => {
    if (!searchResults.length) {
      if (isLoading || (inputValue && !searchWasCalled))
        return [GlobalSearchBarLoadingOption];
      if (inputValue) return [GlobalSearchBarNoResultsOption];
      if (inputValue) return [GlobalSearchBarNoResultsOption];
      if (hasError) return [GlobalSearchBarErrorOption];
      if (
        searchHistory.accessedResults.length ||
        searchHistory.accessedPages.length
      ) {
        return [
          ...searchHistory.accessedResults.map((s) => ({
            value: `search-result-${s.id}`,
            label: `${s.label} • ${s.type?.toUpperCase()}`,
            groupName: 'Recent Searches',
            entity: { id: s.id, path: s.path },
          })),
          ...searchHistory.accessedPages.map((s) => ({
            value: `accessed-pages-${s.id}`,
            label: `${s.label} • ${s.type?.toUpperCase()}`,
            groupName: 'Recent Pages',
            entity: { id: s.id, path: s.path },
          })),
        ] as any as SelectOption<Entity>[];
      }
      return [GlobalSearchBarNoInputOption];
    }
    return searchResults.map((s) => {
      return {
        label: s.label,
        value: s.id,
        groupName: s.typeLabel,
        Component: s.Component || null,
        entity: s.entity,
      };
    });
  }, [
    searchResults,
    isLoading,
    inputValue,
    hasError,
    searchHistory,
    searchWasCalled,
  ]);

  const goToRoute = useRoutingAction();
  const onChangeInputValue = useCallback((inputValue) => {
    setInputValue(inputValue);
    setSearchWasCalled(false);
    debounceSearch(inputValue);
  }, []);
  const onChange = useCallback(
    (selectedOption) => {
      const selectedSearchResult = searchResults.find(
        (s) => s.id === selectedOption.value
      );
      onChangeInputValue('');
      if (!selectedSearchResult) {
        if (selectedOption?.entity?.path) {
          goToRoute(selectedOption?.entity?.path);
        }
        return;
      }
      // @ts-ignore
      if ('id' in selectedSearchResult.entity) {
        addAccessedResult({
          ...selectedSearchResult,
          type: selectedSearchResult.typeLabel,
        });
      }
// @ts-ignore
      goToRoute(selectedSearchResult.path);
    },
    [searchResults, addAccessedResult]
  );
  const onSearch = useCallback((inputValue) => {
    setSearchWasCalled(true);
    search(inputValue);
  }, []);
  const debounceSearch = useCallback(_.debounce(onSearch, 300), []);

  return (
    <SelectProvider
      options={options}
      name={name}
      isLoading={isLoading}
// @ts-ignore
      ref={inputRef}
      onChange={onChange}
      inputValue={inputValue}
      className={`global-search-bar ${className}`}
      onChangeInputValue={onChangeInputValue}
      placeholder={placeholder}
      filterOptions={false}
    >
      <SelectInputContainer>
        <div className="select__input-icon">
          <Icon.SearchIcon />
        </div>
        <SelectInput />
        <SelectLoadingSpinner />
      </SelectInputContainer>
      <SelectMenu />
    </SelectProvider>
  );
}

export const useMarkGlobalSearchBarPageVisit = () => {
  const { addAccessedPage } = useContext(GlobalSearchBarContext);

  return addAccessedPage;
};

export const persistSearchHistory = (
  name: string,
  searchHistory: SearchHistory
) => {
  const storageKey = `GLOBAL_SEARCH_${name}`;
  store.set(storageKey, JSON.stringify(searchHistory));
};

export const hydrateSearchHistory = (name: string): SearchHistory => {
  const storageKey = `GLOBAL_SEARCH_${name}`;
  const stringSearchHistory = store.get(storageKey);
  const defaultSearchHistory: SearchHistory = {
    accessedPages: [],
    accessedResults: [],
  };
  if (stringSearchHistory) {
    try {
      return JSON.parse(stringSearchHistory);
    } catch (e) {
      console.error('Invalid local store global search history');
      console.error(e);
    }
  }
  return defaultSearchHistory;
};

export const GlobalSearchBarViewMoreOptionComponent: React.FunctionComponent<{
  entity: { path: string; label: string };
  isActive: boolean;
}> = ({ entity, isActive }) => {
  return (
    <span
      className={`global-search-bar__link-option ${
        isActive ? 'global-search-bar__link-option--active' : ''
      }`}
    >
      {entity.label || 'View More Results'}
    </span>
  );
};

export const GlobalSearchBarLoadingOptionComponent = () => {
  return (
    <span className="global-search-bar__loading-option">Loading Results</span>
  );
};

const GlobalSearchBarLoadingOption: SelectOption<any> = {
  value: 'Loading',
  label: 'Loading',
  disabled: true,
  Component: GlobalSearchBarLoadingOptionComponent,
};

export const GlobalSearchBarNoResultsOptionComponent = () => {
  return (
    <span>No Results Available. You can try to change your search term</span>
  );
};

const GlobalSearchBarNoResultsOption: SelectOption<any> = {
  value: 'No Results',
  label: 'No Results',
  disabled: true,
  Component: GlobalSearchBarNoResultsOptionComponent,
};

export const GlobalSearchBarNoInputOptionComponent = () => {
  return <span>Start by typing a search term</span>;
};

const GlobalSearchBarNoInputOption: SelectOption<any> = {
  value: 'No Input',
  label: 'No Input',
  disabled: true,
  Component: GlobalSearchBarNoInputOptionComponent,
};

export const GlobalSearchBarErrorOptionComponent = () => {
  return (
    <span>
      We encountered an error while performing your search. You can try to
      change your search term.
    </span>
  );
};

const GlobalSearchBarErrorOption: SelectOption<any> = {
  value: 'Error',
  label: 'Error',
  disabled: true,
  Component: GlobalSearchBarErrorOptionComponent,
};
