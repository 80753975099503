import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import { ApiQuerySchema } from '@nl-lms/common/shared';

import { RegistrationRouterPrefix } from './registration.constants.sdk';
import {
  GetReigstrationPublicDataSchema,
  ListRegistrationSchema,
  RegisterPayloadSchema,
} from './registration.schema.sdk';

const contract = initContract();

export const RegistrationRouterContract = contract.router({
  register: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/register/:surveyFormLearningUnitId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: RegisterPayloadSchema,
    pathParams: z.object({ surveyFormLearningUnitId: z.string() }),
  },
  confirmRegistrationEmail: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/confirm-email`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      token: z.string(),
    }),
  },
  markRegistrationsInReview: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/mark-in-review`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      registrationIds: z.array(z.string()),
    }),
  },
  declineRegistrations: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/decline`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      registrationIds: z.array(z.string()),
      reason: z.string(),
    }),
  },
  blockRegistrations: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/block`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      registrationIds: z.array(z.string()),
    }),
  },
  completeRegistrations: {
    method: 'POST',
    path: `${RegistrationRouterPrefix}/complete`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      registrationIds: z.array(z.string()),
    }),
  },
  listRegistrations: {
    method: 'GET',
    path: `${RegistrationRouterPrefix}/`,
    responses: ContractPaginatedResponse(ListRegistrationSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  getRegistrationPublicData: {
    method: 'GET',
    path: `${RegistrationRouterPrefix}/register/:surveyFormLearningUnitId`,
    pathParams: z.object({ surveyFormLearningUnitId: z.string() }),
    responses: ContractStandardResponse(GetReigstrationPublicDataSchema),
  },
});
