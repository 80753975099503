import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  AppQuery,
  QueryFilter,
  QueryOperator,
  transformQuery,
} from '@nl-lms/common/shared';
import { AssessmentFormQuestionType } from '@nl-lms/feature/assessment/sdk';
import { AssessmentQuestion } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Separator,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { formatConstantString, getMessageFromError } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import { adminApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminAssessmentAddQuestionOptionsInput } from './AdminAssessmentAddQuestionOptionsInput/AdminAssessmentAddQuestionOptionsInput';

type Props = {
  question?: Partial<AssessmentQuestion>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  tagIds: yup.array(),
  description: yup.string(),
  type: yup
    .number()
    .oneOf(
      Object.values(AssessmentFormQuestionType).filter(
        (v) => typeof v === 'number'
      ) as number[]
    )
    .required(),
  options: yup.array().of(
    yup.object().shape({
      title: yup.string().required(),
      correct: yup.boolean(),
    })
  ),
});

const AssessmentQuestionOptionDefaults = {
  [AssessmentFormQuestionType.Dropdown]: [],
  [AssessmentFormQuestionType.MultipleChoice]: [],
  [AssessmentFormQuestionType.SingleChoice]: [],
  [AssessmentFormQuestionType.Nps]: Array.from(new Array(10).keys()).map(
    (v) => ({
      title: `${v + 1}`,
      correct: false,
    })
  ),
  [AssessmentFormQuestionType.Range]: Array.from(new Array(10).keys()).map(
    (v) => ({
      title: `${v + 1}`,
      correct: false,
    })
  ),
  [AssessmentFormQuestionType.Rating]: Array.from(new Array(5).keys()).map(
    (v) => ({
      title: `${v + 1}`,
      correct: false,
    })
  ),
};

export type AdminAssessmentQuestionEditFormSideModalReturnType = {
  success: boolean;
  payload: AssessmentQuestion;
};
const { useLazyListSurveysByConditionedQuestionQuery } = adminApi;

export const AdminAssessmentQuestionEditFormSideModal = ({
  question = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      title: question?.title || '',
      tagIds: question?.tagIds || [],
      description: question?.description || '',
      type: question?.type || AssessmentFormQuestionType.FreeText,
      options: question?.options || [],
    },
  });
  const { hide } =
    useModal<AdminAssessmentQuestionEditFormSideModalReturnType>();

  const [listSurveysByConditionedQuestion, { data: affectedSurveyForms }] =
    useLazyListSurveysByConditionedQuestionQuery();
  const listSurveysByConditionedQuestionAction = useAction(
    listSurveysByConditionedQuestion
  );

  const { addWarningNotification } = useNotifications();
  const onSubmitSuccess = useCallback(
    (result) => {
      if (affectedSurveyForms?.length) {
        const filters = new QueryFilter();
        affectedSurveyForms.forEach((surveyForm) => {
          filters.or({
            field: 'id',
            operator: QueryOperator.Equals,
            value: surveyForm.id,
          });
        });
        const query: AppQuery = {
          filters: filters.appQueryFilter,
          pagination: { limit: 10, offset: 0 },
        };
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('query', JSON.stringify(transformQuery(query)));
        const surveyLinkWithQuery = `${routes.admin.catalogue.surveys.path.full()}?${urlSearchParams.toString()}`;
        addWarningNotification({
          message: `View affected survey forms`,
          details: `The question that you have edited is used as a condition in ${affectedSurveyForms.length} form(s). Click the notification to view and edit the affected survey forms`,
          link: surveyLinkWithQuery,
        });
      }
      hide(result);
    },
    [affectedSurveyForms]
  );
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateAssessmentQuestionMutation',
    updateHookName: 'useUpdateAssessmentQuestionMutation',
    entityId: question.id,
    onSuccess: onSubmitSuccess,
  });
  const questionType = watch('type');
  const questionOptions = watch('options');

  useEffect(() => {
    register('options');

    if (question?.id) listSurveysByConditionedQuestionAction(question.id);
  }, []);

  const renderQuestionOptionsInput = useCallback(() => {
    if (!questionType) return null;
    if (questionType == AssessmentFormQuestionType.FreeText) return null;
    if (questionType == AssessmentFormQuestionType.Date) return null;

    const initialOptions = questionOptions?.length
      ? questionOptions
      : AssessmentQuestionOptionDefaults[questionType];

    return (
      <FormField
        label="Options"
        required
        errorMessage={errors?.options?.message}
      >
        <Controller
          name="options"
          control={control}
          render={({ field }) => (
            <AdminAssessmentAddQuestionOptionsInput
              onChange={field.onChange}
              name="options"
              isMultiSelect={
                AssessmentFormQuestionType.MultipleChoice == questionType
              }
              type={questionType}
              initialOptions={initialOptions}
            />
          )}
        />
      </FormField>
    );
  }, [questionType]);

  const onSubmitClick = (onSubmit) => {
    if (!getValues('description')) {
      setValue('description', '');
    }
    handleSubmit(onSubmit)();
  };

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {question.id ? 'Edit Question' : 'Create New Question'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        {affectedSurveyForms?.length ? (
          <>
            <Typography.p>
              This question is used as a condition in{' '}
              {affectedSurveyForms.length} survey form(s). If you edit either
              the question type or the options you will be directed, after
              submit, to the affected survey forms.
            </Typography.p>
            <Separator />
          </>
        ) : null}
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Type"
              required
              errorMessage={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    name={field.name}
                    onChange={field.onChange}
                    initialSelectedItem={questionType}
                    options={Object.values(AssessmentFormQuestionType)
                      .filter((v) => typeof v === 'number')
                      .map((qType) => ({
                        value: qType,
                        label: formatConstantString(
                          AssessmentFormQuestionType[qType]
                        ),
                      }))}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>{renderQuestionOptionsInput()}</Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Tags">
              <Controller
                name="tagIds"
                control={control}
                render={({ field }) => (
                  <AdminTagSelect
                    onChange={field.onChange}
                    selectedItems={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Description">
              <Controller
                name="description"
                control={control}
                render={({ field }) => <TextEditorWithFileUpload {...field} />}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={question.id ? 'Save' : 'Create'}
          onClick={() => onSubmitClick(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
