import React from 'react';

import { Card, Typography } from '@nl-lms/ui/components';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi } from '../../../_common/services/api';

export const AdminAnalyticsFillRateCard = ({ filters }) => {
  const { useGetSessionFillRateQuery } = adminApi;
  const { isLoading, data, error } = useGetSessionFillRateQuery(filters);
  useQueryErrorHandler({ error });

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Fill Rate</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <Card.Body>
          {data && data?.percentage ? (
            <Typography.h1>{data.percentage}%</Typography.h1>
          ) : (
            <Typography.h2>No data available</Typography.h2>
          )}
        </Card.Body>
      </Card.Content>
    </Card>
  );
};
