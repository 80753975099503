export const SurveyRouterPrefix = '/survey';

export const SurveyScope = {
  update: { resource: 'survey_form', action: 'update' },
  delete: { resource: 'survey_form', action: 'delete' },
  view: { resource: 'survey_form', action: 'view' },
  create: { resource: 'survey_form', action: 'create' },

  viewInstance: { resource: 'survey_form_instance', action: 'view' },
  updateInstance: { resource: 'survey_form_instance', action: 'update' },
  deleteInstance: { resource: 'survey_form_instance', action: 'delete' },
  createInstance: { resource: 'survey_form_instance', action: 'create' },
  portal: {
    viewSurveyForm: {
      resource: 'portal_survey_form',
      action: 'view',
    },
    viewSurveyFormInstance: {
      resource: 'portal_survey_form_instance',
      action: 'view',
    },
  },
} as const;
