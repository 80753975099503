import React, { useCallback, useMemo, useState } from 'react';

import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import {
  Box,
  Button,
  FormField,
  Modal,
  Separator,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getSessionLearnerStatusOptions } from '@nl-lms/ui/utils';

import { useAction } from '../../../../_common/hooks/useAction';
import { adminApi } from '../../../../_common/services/api';
import { AdminLiveSessionSelect } from '../AdminLiveSessionSelect';
import './AdminLiveSessionLearnerCopyModal.scss';

type AdminLiveSessionLearnerCopyModalProps = {
  list: string[] | AppQueryFilter;
};

const liveSessionFilters = {
  value: {
    field: {
      label: 'status',
      field: 'status',
    },
    operator: {
      label: 'Includes',
      value: QueryOperator.Includes,
      type: QueryFilterOperatorType.Unary,
    },
    value: {
      label: 'status',
      value: [
        C.I_SESSION_STATUSES.DRAFT,
        C.I_SESSION_STATUSES.PROCESSING,
        C.I_SESSION_STATUSES.READY,
      ],
    },
  },
  combinator: QueryFilterCombinator?.And,
};
const { useCopyLiveSessionLearnersMutation } = adminApi;

export const AdminLiveSessionLearnerCopyModal = ({
  list,
}: AdminLiveSessionLearnerCopyModalProps) => {
  const { hide } = useModal();
  const [sessionIds, setSessionIds] = useState([]);
  const [status, setStatus] = useState(C.I_LEARNER_STATUS.NOT_INVITED);
  const [copyLiveSessionLearners, { isLoading, error }] =
    useCopyLiveSessionLearnersMutation();
  const copyLiveSessionLearnersAction = useAction(copyLiveSessionLearners, {
    successMessage: 'The live session learners have been copied successfully',
  });

  const statusOptions = useMemo(() => getSessionLearnerStatusOptions(), []);
  const onSubmit = useCallback(async () => {
    const payload = { sessionIds, status, list };
    const result = await copyLiveSessionLearnersAction(payload);
    if (result && 'data' in result) {
      hide({ success: true });
    }

    return result;
  }, [list, sessionIds, status]);

  return (
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Copy Learners To Another Session</Modal.Title>
      </Modal.Header>
      <Box>
        <Typography.p>
          The learner(s) will be added to the selected sessions with the
          specified status.
        </Typography.p>
        <Separator />
        <FormField label="Session Names">
          <AdminLiveSessionSelect
            name="sessionNames"
            onChange={setSessionIds}
            filters={liveSessionFilters}
          />
        </FormField>
        <FormField label="Learner Status">
          <SingleSelect
            name="learnerStatus"
            options={statusOptions}
            onChange={setStatus}
            initialSelectedItem={C.I_LEARNER_STATUS.NOT_INVITED}
          />
        </FormField>
      </Box>
      <Modal.Actions>
        <Button
          label="Submit"
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={!sessionIds.length}
        />
      </Modal.Actions>
    </Modal.Content>
  );
};
