import EnTranslations from './en.json';
import RoTranslations from './ro.json';

const en = EnTranslations;
const ro = RoTranslations;

export const NamespaceTranslations = {
  en,
  ro,
};
