import React from 'react';

export const AddIcon = React.forwardRef<
  SVGSVGElement,
  { strokeWidth?: '1' | '2' }
>(({ strokeWidth = '2' }, ref) => (
  <svg
    ref={ref}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={strokeWidth}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
));
