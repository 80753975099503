import React, { useCallback, useMemo } from 'react';

import { MultiSelect } from '@nl-lms/ui/components';
import { getSelectOptions } from '@nl-lms/ui/utils';

import { adminApi } from '../../../_common/services/api';

const buildLabel = (item) => {
  if (item.scope) return `${item.scope}:${item.title}`;
  return item.title;
};

const defaultProps = {
  initialSelectedItems: [],
  selectedItems: null,
};

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

const { useListCompetenciesForSelectQuery, useCreateCompetencyMutation } =
  adminApi;

export const AdminCompetencySelect = (props) => {
  const {
    onChange,
    name,
    returnEntireItemOnChange,
    initialSelectedItems,
    selectedItems,
  } = props;
  const {
    data,
    isLoading: isFetchLoading,
    refetch,
    // @ts-ignore
  } = useListCompetenciesForSelectQuery(null);
  const options = useMemo(() => {
    if (!data) return [];
    return getSelectOptions(data, buildLabel);
  }, [data]);

  const [createCompetency, { isLoading: isCreateLoading }] =
    useCreateCompetencyMutation();

  const onCreate = useCallback(
    async (inputValue) => {
      const splitInput = inputValue.split(':');
      const scope = splitInput.length > 1 ? splitInput[0] : '';
      const title = splitInput.length > 1 ? splitInput[1] : splitInput[0];
      const result = await createCompetency({ title, scope });
      if ('data' in result) {
        refetch();
        //@ts-ignore
        return { value: result.data.id, label: inputValue };
      }
      return false;
    },
    [createCompetency]
  );

  return (
    <MultiSelect
      name={name}
      placeholder="Search or create new competency"
      options={options}
      onChange={onChange}
      isLoading={isCreateLoading || isFetchLoading}
      isCreatable
      returnEntireItemOnChange={returnEntireItemOnChange}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={initialSelectedItems}
      onCreateOption={onCreate}
    />
  );
};

AdminCompetencySelect.defaultProps = defaultProps;
