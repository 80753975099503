import { yupResolver } from '@hookform/resolvers/yup';
import { Slot } from '@radix-ui/react-slot';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import * as yup from 'yup';

import { AppQueryFilter } from '@nl-lms/common/shared';
import { Widget } from '@nl-lms/feature/dashboard/sdk';
import {
  ReportDataColumn,
  ReportTemplateName,
  ReportsScope,
} from '@nl-lms/feature/reports/sdk';
import { PaginatedReportQuery } from '@nl-lms/feature/reports/sdk';
import {
  Box,
  Button,
  FormField,
  Icon,
  Input,
  SideModal,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';

import { Can } from '../../../Can';
import { reportsApi } from '../../../_common/services/api';
import { AdminAnalyticsReportColumnsInput } from '../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsReportColumnsInput';
import { AdminAnalyticsReportFormFilterBar } from '../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsReportFormFilterBar';
import { AdminAnalyticsReportTemplateSelect } from '../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsReportTemplatesSelect';
import { AdminAnalyticsReportPreviewSideModalTrigger } from '../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsUpsertReportSideModal';
import { AdminDashboardContext } from './AdminDashboardContext';

type AdminDashboardAddReportWidgetSideModalProps = {
  widget?: Widget<PaginatedReportQuery>;
};

const REPORT_WIDGET_MAX_ROWS = 1000;

const Schema = yup.object().shape({
  label: yup.string().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  columns: yup.array(),
  filters: yup.object().nullable(),
  notification: yup.object(),
});

export const AdminDashboardAddReportWidgetSideModal = (
  props: AdminDashboardAddReportWidgetSideModalProps
) => {
  const { hide } = useModal();
  const isEditing = useMemo(() => {
    return 'widget' in props;
  }, []);
  const { onAddWidget, onUpdateWidget } = useContext(AdminDashboardContext);
  const [returnsToManyRows, setReturnsToManyRows] = useState(false);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);
  const initialWidget = useMemo(() => {
    return {
      label: props?.widget?.label || '',
      columns: props?.widget?.payload?.columns || [],
      filters: props?.widget?.payload?.query?.filters,
      name: props?.widget?.payload?.name || '',
      description: props?.widget?.payload?.description || '',
      notification: props.widget?.payload?.notification,
    };
  }, [isEditing]);
  const dispatch = useDispatch();
  const validateReportData = useCallback(async (widget) => {
    const resultPromise = dispatch(
      reportsApi.endpoints.getReportData.initiate(
        widget.payload
      ) as unknown as AnyAction
    );
    const result = await resultPromise;
    if (result.status === 'fulfilled') {
      resultPromise.unsubscribe();
      return result.data?.count <= REPORT_WIDGET_MAX_ROWS;
    }
    return false;
  }, []);

  const onSubmit = useCallback(
    async (entity) => {
      const oldWidgetLabel = props?.widget?.label ? props.widget.label : '';
      const position = props?.widget?.position
        ? props.widget.position
        : { x: 0, y: 0, h: 12, w: 12 };
      const parsedWidget = {
        label: entity.label,
        type: 'report',
        position,
        payload: {
          ...entity,
          query: {
            filters: entity.filters,
            pagination: { offset: 0, limit: 10 },
          },
          withHistoricalLearnerData: false,
        },
      } as Widget;
      const isValid = await validateReportData(parsedWidget);
      setReturnsToManyRows(!isValid);
      if (!isValid) {
        return;
      }
      if (isEditing) {
        onUpdateWidget(oldWidgetLabel, parsedWidget);
      } else {
        onAddWidget(parsedWidget);
      }
      hide();
    },
    [isEditing]
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    // todo check this
    // @ts-ignore
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    // @ts-ignore
    defaultValues: initialWidget,
  });

  const onChangeReportTemplate = useCallback((selectedOption) => {
    setSelectedReportTemplate(selectedOption?.report);
    if (!selectedOption?.report) return;
    const originalReport = selectedOption.report;
    setValue('name', originalReport.name);
    setValue('description', originalReport.description);
    setValue('label', originalReport.label);
    setValue('columns', originalReport.columns);
    setValue('filters', originalReport.filters);
    setValue('notification', originalReport.notification);
  }, []);

  // @ts-ignore
  const columns = watch('columns') as ReportDataColumn[];
  const name = watch('name');
  const filters = watch('filters');
  const availableColumnsForEdit = columns.filter(
    (col) => !(col.options && 'alwaysHide' in col.options)
  );
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Add Report Widget</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.p type="muted">
            Start by selecting an report template to specify what columns will
            be available
          </Typography.p>
        </Box>
        {isEditing ? null : (
          <Box flex={{ flexDirection: 'row', gap: 'm' }}>
            <FormField
              label="Report Template"
              errorMessage={errors?.name?.message}
            >
              <AdminAnalyticsReportTemplateSelect
                onChange={onChangeReportTemplate}
                isClearable
              />
            </FormField>
          </Box>
        )}
        <FormField errorMessage={errors.label?.message} label="Label">
          <Input
            hasError={!!errors.label?.message}
            // @ts-ignore
            name="label"
            {...register('label')}
          />
        </FormField>
        <FormField label="Filters">
          <Controller
            name="filters"
            control={control}
            render={({ field }) => (
              <AdminAnalyticsReportFormFilterBar
                onChange={field.onChange}
                key={`filter-bar-${name}`}
                value={field.value}
                columns={columns}
              />
            )}
          />
        </FormField>
        <FormField label="Columns">
          <Controller
            name="columns"
            control={control}
            render={({ field }) => (
              <AdminAnalyticsReportColumnsInput
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </FormField>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {returnsToManyRows
            ? `You report returns to many rows. Update your filters to satisfy the limit of ${REPORT_WIDGET_MAX_ROWS} rows.`
            : ''}
        </SideModal.Error>
        <AdminAnalyticsReportPreviewSideModalTrigger
          name={name as ReportTemplateName}
          columns={columns}
          filters={filters as AppQueryFilter}
          withHistoricalLearnerData={false}
        >
          <Button
            disabled={!name || !availableColumnsForEdit?.length}
            label="Preview Data"
            icon={<Icon.TableIcon />}
            regular
          />
        </AdminAnalyticsReportPreviewSideModalTrigger>
        <Button label="Submit" onClick={handleSubmit(onSubmit)} />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

export const AdminDashboardAddReportWidgetSideModalTrigger = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const showModal = useShowModal(AdminDashboardAddReportWidgetSideModal);

  return (
    <Can
      do={ReportsScope.updateTemplate.action}
      on={ReportsScope.updateTemplate.resource}
    >
      <Slot onClick={() => showModal({})}>{children}</Slot>
    </Can>
  );
};

export const useShowAdminDashboardAddReportWidgetSideModal = () => {
  const showModal = useShowModal(AdminDashboardAddReportWidgetSideModal);

  return useCallback(
    (props: AdminDashboardAddReportWidgetSideModalProps) => showModal(props),
    []
  );
};
