import { createApi } from '@reduxjs/toolkit/query/react';

import { AppQuery } from '@nl-lms/common/shared';
import {
  Assessment,
  AssessmentInstance,
  AssessmentInstanceAttempt,
  ChecklistInstance,
  Competency,
  ElearningSession,
  IndividualLearningInstance,
  LearnerChecklist,
  LearnerChecklistInstance,
  LearnerElearningCourse,
  LearnerElearningCourseListItem,
  LearnerLearning,
  LearnerLearningAssignmentStatus,
  LearnerLearningByDay,
  LearnerLearningByType,
  LearnerLearningPath,
  LearnerLearningPathListItem,
  LearnerLiveCourse,
  LearnerPortalSearchResult,
  LearnerTimeSpentLearning,
  LearningPathInstance,
  ListResponse,
  LiveCourse,
  LiveSession,
  Survey,
  SurveyInstance,
} from '@nl-lms/sdk/backend';

import { axiosBaseQuery } from './axiosBaseQuery';
import { getTagsFunctions } from './getTagsFunctions';
import { liveLearningApi } from './liveLearningApi';

const LearnerPortalTagTypes = [
  'LearnerHistoryExport',
  'LearnerLearning',
  'LearnerElearningCourse',
  'LearnerLearningPath',
  'LearnerAssessment',
  'LearnerElearningSession',
  'LearnerLearningPathInstance',
  'LearnerLiveSessions',
  'Search',
  'LearnerLearningAssignmentStatus',
  'LearnerCompetencies',
  'LearnerTimeSpentLearning',
  'LearnerLearningByDay',
  'LearnerLearningByType',
  'LearnerLiveCourse',
  'LearnerAssessmentInstance',
  'LearnerAssessmentInstanceAttempt',
  'LearnerIndividualLearning',
  'LearnerSurveyInstance',
  'LearnerSurvey',
  'LearnerChecklist',
  'LearnerChecklistInstance',
] as const;

const { getProvidesTagsFunction, getInvalidatesTagsFunction } =
  getTagsFunctions<(typeof LearnerPortalTagTypes)[number]>();

export const learnerApi = createApi({
  reducerPath: 'learnerApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: LearnerPortalTagTypes,
  endpoints: (builder) => ({
    listUpcomingLiveSessions: builder.query<
      ListResponse<LiveSession>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listUpcomingSessions',
        params: query,
      }),
      keepUnusedDataFor: 1,
      providesTags: getProvidesTagsFunction('LearnerLiveSessions'),
    }),
    listLearnerLearning: builder.query<
      ListResponse<Omit<LearnerLearning, 'tags' | 'learner_groups'>>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listLearning',
        params: query,
      }),
      keepUnusedDataFor: 1,
      providesTags: getProvidesTagsFunction('LearnerLearning'),
    }),
    listLearnerCompetencies: builder.query<Competency[], { learnerId: string }>(
      {
        query: (query) => ({
          service: 'learnerApp',
          method: 'listCompetencies',
          params: query,
        }),
        providesTags: getProvidesTagsFunction('LearnerCompetencies'),
      }
    ),
    listElearningCourses: builder.query<
      ListResponse<LearnerElearningCourseListItem>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listElearningCourse',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerElearningCourse'),
    }),
    listLearningPaths: builder.query<
      ListResponse<LearnerLearningPathListItem>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listLearningPath',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningPath'),
    }),
    listLiveCourses: builder.query<
      ListResponse<LiveCourse>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listCourse',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningPath'),
    }),
    listAssessments: builder.query<
      ListResponse<Assessment>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listAssessmentActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerAssessment'),
    }),
    listElearningSessions: builder.query<
      ListResponse<ElearningSession>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listElearningActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerElearningSession'),
    }),
    listIndividualInstances: builder.query<
      ListResponse<IndividualLearningInstance>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listIndividualActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerIndividualLearning'),
    }),
    listLearningPathInstances: builder.query<
      ListResponse<LearningPathInstance>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listLearningPathActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningPathInstance'),
    }),
    listChecklistInstances: builder.query<
      ListResponse<ChecklistInstance>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listChecklistActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerChecklistInstance'),
    }),
    getChecklistWithInstance: builder.query<
      LearnerChecklist,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getChecklistWithInstance',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerChecklist'),
    }),
    getChecklistInstance: builder.query<
      LearnerChecklistInstance,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getChecklistInstance',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerChecklistInstance'),
    }),
    startChecklistInstance: builder.mutation<
      boolean,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'startChecklistInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerChecklistInstance'),
    }),
    saveChecklistInstance: builder.mutation<
      boolean,
      { id: string; learnerId: string; items: any }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'persistChecklistInstanceItems',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerChecklistInstance'),
    }),
    persistChecklistInstanceItems: builder.mutation<
      boolean,
      { id: string; learnerId: string; items: any; timeSpent: number }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'persistChecklistInstanceItems',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerChecklistInstance'),
    }),
    endChecklistInstance: builder.mutation<
      boolean,
      { id: string; learnerId: string; items: any; timeSpent: number }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'endChecklistInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerChecklistInstance'),
    }),

    trainingSessionRegisterWithToken: builder.mutation<
      any,
      { token: string; answers: any }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'trainingSessionRegisterWithToken',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerLiveSessions'),
    }),
    submitFeedbackSurveyForm: builder.mutation<
      any,
      {
        learnerId: string;
        surveyFormId: string;
        learningUnitId: string;
        answers: any;
        timeSpent: number;
      }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'submitFeedbackSurveyForm',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurvey'),
    }),

    listLiveSessions: builder.query<
      ListResponse<LiveSession>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listLiveTrainingActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLiveSessions'),
    }),
    getLiveSession: builder.query<any, { id: string; learnerId: string }>({
      query: (query) => ({
        service: 'learnerApp',
        method: 'getTrainingSession',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLiveSessions'),
    }),
    listLiveSessionTimeline: builder.query<
      ListResponse<LiveSession>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listOpenSessions',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerLiveSessions'),
    }),
    getTrainingSessionRegistrationForm: builder.query<
      { surveyForm: Survey; trainingSession: LiveSession },
      string
    >({
      query: (tk) => ({
        service: 'learnerApp',
        method: 'getTrainingSessionRegistrationForm',
        params: tk,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurvey'),
    }),
    trainingSessionRegister: builder.mutation<any, any>({
      query: (params) => ({
        service: 'learnerApp',
        method: 'trainingSessionRegister',
        params: params,
      }),
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            liveLearningApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LiveCourse',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: getInvalidatesTagsFunction('LearnerLiveSessions'),
    }),
    trainingSessionCancelRegistration: builder.mutation<any, any>({
      query: (params) => ({
        service: 'learnerApp',
        method: 'trainingSessionCancelRegistration',
        params: params,
      }),
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            liveLearningApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LiveCourse',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: getInvalidatesTagsFunction('LearnerLiveSessions'),
    }),
    getSurveyFormForRegistration: builder.query<
      Survey,
      { id: string; learnerId: string }
    >({
      query: (tk) => ({
        service: 'learnerApp',
        method: 'getSurveyFormForRegistration',
        params: tk,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurvey'),
    }),
    getFeedbackSurveyForm: builder.query<
      Survey,
      {
        learnerId: string;
        surveyFormId: string;
        learningUnitId: string;
      }
    >({
      query: (tk) => ({
        service: 'learnerApp',
        method: 'getFeedbackSurveyForm',
        params: tk,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurvey'),
    }),

    // Profile stats
    learningAssignmentsGroupedByStatus: builder.query<
      LearnerLearningAssignmentStatus[],
      { learnerId: string }
    >({
      query: (learnerId) => ({
        service: 'learnerApp',
        method: 'getLearningAssignmentsGroupedByStatus',
        params: learnerId,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningAssignmentStatus'),
    }),
    learningProgress: builder.query<
      { status: string; type: string; value: number }[],
      { learnerId: string }
    >({
      query: (learnerId) => ({
        service: 'learnerApp',
        method: 'getLearningProgress',
        params: learnerId,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningAssignmentStatus'),
    }),
    learningTimeSpent: builder.query<
      LearnerTimeSpentLearning[],
      { learnerId: string }
    >({
      query: (learnerId) => ({
        service: 'learnerApp',
        method: 'getTimeSpentLearning',
        params: learnerId,
      }),
      providesTags: getProvidesTagsFunction('LearnerTimeSpentLearning'),
    }),
    learningGroupedByDay: builder.query<
      LearnerLearningByDay[],
      { learnerId: string }
    >({
      query: (learnerId) => ({
        service: 'learnerApp',
        method: 'getLearningGroupedByDay',
        params: learnerId,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningByDay'),
    }),
    learningGroupedByType: builder.query<
      LearnerLearningByType[],
      { learnerId: string }
    >({
      query: (learnerId) => ({
        service: 'learnerApp',
        method: 'getLearningGroupedByType',
        params: learnerId,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningByType'),
    }),
    learnerLiveCourse: builder.query<
      LearnerLiveCourse,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getCourse',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerLiveCourse'),
    }),
    requestLiveCourse: builder.mutation<
      boolean,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'requestCourse',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerLiveCourse'),
    }),
    createIndividualLearning: builder.mutation<
      boolean,
      { data: any; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'createIndividualLearning',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerIndividualLearning'),
    }),
    removeRequestLiveCourse: builder.mutation<
      boolean,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'removeRequest',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerLiveCourse'),
    }),
    learnerElearningCourse: builder.query<
      LearnerElearningCourse,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getElearningCourse',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerElearningCourse'),
    }),
    learnerElearningSession: builder.query<
      ElearningSession,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getElearningSession',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerElearningSession'),
    }),
    startOrResumeElearningSession: builder.mutation<boolean, { token: string }>(
      {
        query: (params) => ({
          service: 'learnerApp',
          method: 'startOrResumeElearningSession',
          params: params,
        }),
        invalidatesTags: getInvalidatesTagsFunction('LearnerElearningCourse', [
          'LearnerElearningSession',
        ]),
      }
    ),
    stopElearningSession: builder.mutation<
      boolean,
      { token: string; blurTotalTime: number; focusTotalTime: number }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'stopElearningSession',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerElearningCourse', [
        'LearnerElearningSession',
      ]),
    }),
    learnerLearningPath: builder.query<
      LearnerLearningPath,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getLearningPath',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerLearningPath'),
    }),
    joinLearningPath: builder.mutation<
      boolean,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'joinLearningPath',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerLearningPath'),
    }),
    learnerAssessmentForm: builder.query<
      {
        assessmentForm: Assessment;
        assessmentFormInstances?: AssessmentInstance[];
      },
      {
        id: string;
        learnerId: string;
        data: {
          learningPathItemId: string;
          learningPathInstanceId: string;
        };
      }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getAssessmentForm',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerAssessment'),
    }),
    learnerAssessmentInstance: builder.query<
      AssessmentInstance,
      { assessmentInstanceId: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getAssessmentInstance',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerAssessmentInstance'),
    }),
    learnerAssessmentInstanceAttempt: builder.query<
      AssessmentInstanceAttempt,
      { assessmentInstanceId: string; learnerId: string; rnd: any } // this is in order to invalidate cache on every call
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getAssessmentAttempt',
        params: { ...params },
      }),
      providesTags: getProvidesTagsFunction('LearnerAssessmentInstanceAttempt'),
    }),
    startAssessmentAttempt: builder.mutation<
      boolean,
      { assessmentInstanceId: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'startAssessmentAttempt',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction(
        'LearnerAssessmentInstanceAttempt'
      ),
    }),
    endAssessmentAttempt: builder.mutation<
      boolean,
      {
        assessmentInstanceId: string;
        learnerId: string;
        answers: any;
        timeSpent: number;
      }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'endAssessmentAttempt',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction(
        'LearnerAssessmentInstanceAttempt'
      ),
    }),

    // survey view
    getSurveyFormWithInstance: builder.query<
      { surveyForm: Survey; surveyFormInstances: SurveyInstance[] },
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getSurveyFormWithInstance',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurvey'),
    }),

    // Survey player
    listSurveyInstances: builder.query<
      ListResponse<SurveyInstance>,
      { query: AppQuery; learnerId: string }
    >({
      query: (query) => ({
        service: 'learnerApp',
        method: 'listSurveyActivity',
        params: query,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurveyInstance'),
    }),
    getSurveyInstance: builder.query<
      SurveyInstance,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getSurveyFormInstance',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurveyInstance'),
    }),
    getTrainingSessionFeedbackForm: builder.query<
      { surveryForm: Survey },
      { token: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'getTrainingSessionFeedbackForm',
        params: params,
      }),
      providesTags: getProvidesTagsFunction('LearnerSurveyInstance'),
    }),
    createSurveyInstance: builder.mutation<
      SurveyInstance,
      { surveyFormId: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'createSurveyFormInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurveyInstance'),
    }),
    startSurveyInstance: builder.mutation<
      boolean,
      { id: string; learnerId: string }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'startSurveyFormInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurveyInstance'),
    }),
    endSurveyInstance: builder.mutation<
      boolean,
      { id: string; learnerId: string; answers: any; timeSpent: number }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'endSurveyFormInstance',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurveyInstance'),
    }),
    persistSurveyInstanceData: builder.mutation<
      boolean,
      {
        instanceId?: string;
        learnerId: string;
        timeSpent: number;
        surveyFormId: string;
        liveSessionId?: string;
      }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'persistSurveyFormInstanceData',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurveyInstance'),
    }),
    submitTrainingSessionFeedbackForm: builder.mutation<
      boolean,
      {
        token: string;
        answers: any;
        timeSpent: number;
      }
    >({
      query: (params) => ({
        service: 'learnerApp',
        method: 'submitTrainingSessionFeedbackForm',
        params: params,
      }),
      invalidatesTags: getInvalidatesTagsFunction('LearnerSurveyInstance'),
    }),

    // Search
    search: builder.query<
      LearnerPortalSearchResult[],
      { learnerId: string; searchTerm: string }
    >({
      query: (searchTerm) => ({
        service: 'learnerApp',
        method: 'search',
        params: searchTerm,
      }),
      providesTags: ['Search'],
    }),

    exportLearningHistory: builder.query<boolean, { learnerId: string }>({
      query: (searchTerm) => ({
        service: 'learnerApp',
        method: 'exportLearningHistory',
        params: searchTerm,
      }),
      providesTags: getProvidesTagsFunction('LearnerHistoryExport'),
    }),

    // Generator entry point
  }),
});
