import store from 'store';

import { getClients } from '@nl-lms/feature/notifications/sdk';

import { apiBaseUrl } from './api';

const { raw: notificationsApiClient, rtk: notificationsRtkClient } = getClients(
  {
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  }
);

const notificationsApi = notificationsRtkClient;
notificationsApi.enhanceEndpoints<'Notification' | 'NotificationTemplate'>({
  endpoints: {
    listNotifications: { providesTags: [{ type: 'Notification', id: 'LIST' }] },
    listNotificationTemplates: {
      providesTags: [{ type: 'NotificationTemplate', id: 'LIST' }],
    },
    sendNotifications: {
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    },
    scheduleNotifications: {
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    },
    removeNotifications: {
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    },
    rescheduleNotifications: {
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    },
  },
});

export { notificationsApi, notificationsApiClient };
