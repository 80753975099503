import clsx from 'clsx';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { Box } from '../Box/Box';
import { ArrowDownIcon, CheckIcon } from '../Icon';
import { ReactPortal } from '../ReactPortal/ReactPortal';
import { Separator } from '../Separator/Separator';
import { TidComponent } from '../index.types';
import './FloatingMenu.scss';

type Ref = HTMLDivElement;
type MenuProps = TidComponent<{
  handleClose: (ev: any) => void;
  parentRef: React.RefObject<HTMLDivElement>;
  align: string;
  className?: string;
  topOffset: number;
  renderMenuItem: any;
  menuItems: (
    | boolean
    | TidComponent<{ handler: any; name: string | ReactNode }>
    | string
  )[];
}>;

export const Menu = React.forwardRef<Ref, MenuProps>(
  (
    {
      handleClose,
      parentRef,
      align,
      className = '',
      topOffset,
      menuItems,
      renderMenuItem,
      ...props
    },
    ref
  ) => {
    const commonProps = useTestProps(props);

    const [isMenuInScreen, setIsMenuInScreen] = useState(true);
    const [refLoaded, setRefLoaded] = useState(false);

    const [position, setPosition] = useState({
      top: undefined,
      left: undefined,
      right: undefined,
    });

    const getPosition = useCallback(() => {
      const isRightAligned = align === 'right';

      // @ts-ignore
      const { offsetWidth } = parentRef.current;
      // @ts-ignore
      const initialPosition = parentRef.current.getBoundingClientRect();
      const pageWidth = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );

      // @ts-ignore
      const menuPosition = ref.current.getBoundingClientRect();

      let right;
      let left;
      let top;
      if (!isMenuInScreen) {
        top =
          initialPosition.bottom -
          initialPosition.height -
          topOffset -
          menuPosition.height;
      } else {
        top = initialPosition.bottom + topOffset;
      }

      if (isRightAligned)
        right = pageWidth - initialPosition.left - offsetWidth;
      else left = initialPosition.left;

      return { top, left, right };
    }, [parentRef, align, topOffset, ref, refLoaded, isMenuInScreen]);

    const updatePosition = useCallback(() => {
      const _position = getPosition();
      setPosition(_position);
    }, [setPosition, getPosition]);

    useEffect(() => {
      document.addEventListener('mousedown', handleClose);
      const mainLayoutContent = document.querySelector('.main-layout__content');
      if (mainLayoutContent) {
        mainLayoutContent.addEventListener('scroll', updatePosition);
      }

      updatePosition();

      return function cleanup() {
        document.removeEventListener('mousedown', handleClose);
        const layoutContent = document.querySelector('.main-layout__content');
        if (layoutContent)
          layoutContent.removeEventListener('scroll', updatePosition);
      };
    }, [updatePosition, handleClose, updatePosition]);

    useEffect(() => {
      setTimeout(() => {
        // wait for ref to bind after first render

        // sometimes it might not :-? probably triggers after closing the modal
        // @ts-ignore
        if (!ref.current) return;

        // @ts-ignore
        const menuPosition = ref.current.getBoundingClientRect();

        // set here to prevent flickering when scrolling
        setIsMenuInScreen(
          menuPosition.height + menuPosition.top < document.body.clientHeight
        );
        setRefLoaded(true);
      }, 0);
    }, []);

    return (
      <div
        className={`new-floating-menu__menu ${className}`}
        // wait for ref to bind and then show the menu - prevent visible position change
        style={{ ...position, opacity: refLoaded ? 1 : 0 }}
        ref={ref}
        {...commonProps}
      >
        {menuItems.map((item, index) => {
          if (typeof item === 'boolean') {
            return (
              <Separator
                key={index}
                margin={5}
                // @ts-ignore
                className={'new-floating-menu__separator'}
              />
            );
          } else if (typeof item === 'string') {
            return (
              <div className="new-floating-menu__section-label" key={index}>
                {item}
              </div>
            );
          }

          return renderMenuItem ? (
            renderMenuItem({ ...item, index })
          ) : (
            <MenuItem
              key={index}
              handler={item.handler} // because of react portal https://stackoverflow.com/questions/62910943/react-portal-event-bubbling-in-the-wrong-direction
              tid={item.tid}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </div>
    );
  }
);

const MenuItem = ({ children, handler, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <div
      className="new-floating-menu__menu-item"
      onMouseDown={handler} // because of react portal https://stackoverflow.com/questions/62910943/react-portal-event-bubbling-in-the-wrong-direction
      {...commonProps}
    >
      {children}
    </div>
  );
};

type FloatingMenuContextType = {
  isToggled: boolean;
};

const FloatingMenuContext = createContext({} as FloatingMenuContextType);

export type FloatingMenuProps = TidComponent<{
  align?: 'right' | 'left';
  alwaysToggled?: boolean;
  className?: string;
  menuClassName?: string;
  disabled?: boolean;
  items: (
    | {
        handler: (item: any) => void;
        name: string | ReactNode;
        Component?: React.FC<{ name: string }>;
      }
    | {
        handler: (item: any) => void;
        name: string | ReactNode;
        Component?: React.FC<{ name: string }>;
        sectionLabel?: string;
      }[]
  )[];
  children?: any;
  renderMenuItem?: any;
  topOffset?: number;
  usePortal?: boolean;
}>;

export const FloatingMenu = ({
  align = 'right',
  alwaysToggled,
  disabled,
  items = [],
  menuClassName = '',
  children,
  renderMenuItem,
  topOffset = 5,
  usePortal = true,
  ...props
}: FloatingMenuProps) => {
  const commonProps = useTestProps(props);
  const [toggled, setToggled] = useState(!!alwaysToggled);

  const ref = useRef<HTMLDivElement>();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setToggled(false);
      }
    },
    [alwaysToggled, setToggled, ref, menuRef]
  );

  const handleToggle = useCallback(
    (e) => {
      if (alwaysToggled) {
        setToggled(true);
      } else {
        setToggled(!toggled);
      }
      e.stopPropagation();
    },
    [setToggled, toggled, alwaysToggled]
  );

  const classNameProps = useClassNameProps(
    'new-floating-menu',
    props,
    disabled || !items.length ? 'new-floating-menu--disabled' : null
  );

  const getMenuItem = useCallback(
    ({ handler, name }) => {
      const _handler = () => {
        handler();

        if (alwaysToggled) return;
        setToggled(false);
      };

      return { name, handler: _handler };
    },
    [alwaysToggled, setToggled]
  );

  const menuItems = useMemo(() => {
    const _items = [];

    items.forEach((item, index) => {
      if (Array.isArray(item)) {
        if (index > 0) {
          // @ts-ignore
          _items.push(true);
        }
        const sectionLabel = item[0].sectionLabel;
        if (sectionLabel) {
          // @ts-ignore
          _items.push(sectionLabel);
        }
        item.forEach((_item) => {
          // @ts-ignore
          _items.push(getMenuItem(_item));
        });
      } else {
        // @ts-ignore
        _items.push(getMenuItem(item));
      }
    });

    return _items;
  }, [items, getMenuItem]);

  const renderMenu = () => (
    <Menu
      handleClose={handleClose}
      // @ts-ignore
      parentRef={ref}
      ref={menuRef}
      className={menuClassName}
      align={align}
      topOffset={topOffset}
      menuItems={menuItems}
      renderMenuItem={renderMenuItem}
    />
  );

  return (
    // @ts-ignore
    <FloatingMenuContext.Provider value={{ isToggled: toggled }}>
      {/* @ts-ignore */}
      <div ref={ref} {...classNameProps} {...commonProps}>
        <div className="new-floating-menu__content" onClick={handleToggle}>
          {!!children && children}
        </div>

        {toggled && items.length ? (
          <>
            {usePortal ? (
              <ReactPortal>{renderMenu()}</ReactPortal>
            ) : (
              renderMenu()
            )}
          </>
        ) : null}
      </div>
    </FloatingMenuContext.Provider>
  );
};

const FloatingMenuCheckedOption = ({
  label,
  isChecked,
  ...props
}: TidComponent<{
  label: string;
  isChecked: boolean;
}>) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Box
      flex={{ flexDirection: 'row', justifyContent: 'space-between' }}
      {...commonProps}
    >
      <div>{label}</div>
      <div className="new-floating-menu__menu-item-icon ">
        {isChecked ? <CheckIcon /> : null}
      </div>
    </Box>
  );
};

const FloatingMenuArrow = () => {
  const { isToggled } = useContext(FloatingMenuContext);

  return (
    <span
      className={clsx(
        'new-floating-menu__arrow',
        isToggled && 'new-floating-menu__arrow--toggled'
      )}
      data-state={isToggled ? 'open' : 'closed'}
    >
      <ArrowDownIcon />
    </span>
  );
};

FloatingMenu.Arrow = FloatingMenuArrow;

FloatingMenu.CheckedOption = FloatingMenuCheckedOption;
