import { ApiService, snakelize } from '../lib';
import { AdminSearchResult, IApiService } from './types';

export class Common extends ApiService implements IApiService<'common'> {
  public paths: any;
  public serviceBase: string = `/common`;

  constructor(props) {
    super(props);
    this.paths = {
      getSearch: () => `${this.serviceBase}/search`,
      getSettings: () => `${this.serviceBase}/settings`,
      uploadFile: (fileType) => `${this.serviceBase}/upload/${fileType}`,
      sendSupportEmail: () => `${this.serviceBase}/support`,
      getSignedPutUrl: () => `${this.serviceBase}/signed_put_url`,
      getSignedPutUrls: () => `${this.serviceBase}/signed_put_urls`,
      completeMultipartUpload: () =>
        `${this.serviceBase}/complete_multipart_upload`,
    };
  }

  search = (term) => {
    if (!term || !term.trim()) return Promise.resolve([]);
    return this.api.get<null, AdminSearchResult[]>(this.paths.getSearch(), {
      params: { term },
    });
  };

  getSettings = () => this.api.get(this.paths.getSettings());

  getSignedPutUrl = (fileName, type) =>
    this.api.post<
      null,
      {
        fileUrl: string;
        putSignedUrl: string;
      }
    >(this.paths.getSignedPutUrl(), snakelize({ fileName, type }));
  getSignedPutUrls = (fileName, type, partsCount) =>
    this.api.post<
      null,
      {
        relativePath: string;
        uploadId: string;
        signedUrls: string;
        fileUrl: string;
      }
    >(this.paths.getSignedPutUrls(), snakelize({ fileName, type, partsCount }));

  completeMultipartUpload = (fileKey, uploadedParts, uploadId) =>
    this.api.post(this.paths.completeMultipartUpload(), {
      file_key: fileKey,
      uploaded_parts: uploadedParts,
      upload_id: uploadId,
    });

  uploadFile = (fileType, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post<null, { fileUrl: string; relativePath: string }>(
      this.paths.uploadFile(fileType),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  sendSupportEmail = ({ subject, message, metadata }) => {
    return this.api.post<null, null>(this.paths.sendSupportEmail(), {
      subject,
      message,
      metadata,
    });
  };
}
