import React, { CSSProperties, useRef } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { Tooltip } from '../Tooltip/Tooltip';
import { TidComponent } from '../index.types';
import './Button.scss';

const getButtonClassName = ({
  regular,
  ghost,
  alert,
  success,
  warn,
}: {
  regular?: boolean;
  ghost?: boolean;
  alert?: boolean;
  success?: boolean;
  warn?: boolean;
  small?: boolean;
  round?: boolean;
}) => {
  let baseClass = 'new_button__primary';
  if (regular) baseClass = 'new_button__regular';
  if (ghost) baseClass = 'new_button__ghost';

  if (alert) return `${baseClass} ${baseClass}--alert`;
  if (warn) return `${baseClass} ${baseClass}--warn`;
  if (success) return `${baseClass} ${baseClass}--success`;

  return `${baseClass} ${baseClass}--default`;
};

type ButtonWithIconAsProp = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon: React.FC;
  size?: 's' | 'xs';
};
type ButtonWithIconAsChild = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  label: string;
  size?: 's' | 'xs';
};
type ComponentProps = ButtonWithIconAsChild | ButtonWithIconAsProp;

export const IconButton = React.forwardRef<HTMLButtonElement, ComponentProps>(
  (props, ref) => {
    const { size = '', className = '', ...rest } = props;

    if ('Icon' in props) {
      const { Icon } = props;
      return (
        <button
          ref={ref}
          className={`icon-button icon-button--${size} ${className}`}
          {...rest}
        >
          <Icon />
        </button>
      );
    }

    const { children, label } = props;
    return (
      <Tooltip asChild title={label}>
        <button
          ref={ref}
          className={`icon-button icon-button--${size} ${className}`}
          {...rest}
        >
          {children}
        </button>
      </Tooltip>
    );
  }
);

IconButton.displayName = 'IconButton';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  TidComponent<{
    regular?: boolean;
    ghost?: boolean;
    alert?: boolean;
    success?: boolean;
    warn?: boolean;
    isLoading?: boolean;
    label: string;
    round?: boolean;
    small?: boolean;
    icon?: any;
    iconPosition?: 'left' | 'right';
    style?: CSSProperties;
    className?: string;
    responsive?: boolean;
    readOnly?: boolean;
    name?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  }>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (props, forwardedRef) => {
    const {
      regular = false,
      ghost = false,
      alert = false,
      success = false,
      warn = false,
      isLoading = false,
      label = '',
      small = false,
      round = false,
      disabled = false,
      icon = null,
      iconPosition = 'right',
      className = '',
      responsive = false,
      readOnly = false,
      name,
      onClick,
      style = {},
      ...rest
    } = props;

    const localRef = useRef();
    const buttonRef = forwardedRef || localRef;

    const commonProps = useTestProps(props);

    const buttonClassNameProps = useClassNameProps(
      'new_button',
      getButtonClassName(props),
      props,
      responsive ? 'responsive' : '',
      small ? 'new_button__small' : '',
      round ? 'new_button__round' : ''
    );

    const buttonContentClassNameProps = useClassNameProps(
      'new_button__content'
    );

    const buttonSpinnerClassNameProps = useClassNameProps(
      'new_button__spinner',
      regular || ghost ? 'new_button__spinner--dark' : null
    );

    const buttonWidth =
      // @ts-ignore
      buttonRef && buttonRef.current ? buttonRef.current.offsetWidth : 'auto';

    return (
      <button
        // @ts-ignore
        onClick={onClick ? onClick : null}
        name={name}
        style={isLoading ? { width: buttonWidth, ...style } : style}
        // @ts-ignore
        ref={buttonRef}
        disabled={disabled || isLoading}
        {...buttonClassNameProps}
        {...commonProps}
        {...rest}
      >
        <div {...buttonContentClassNameProps}>
          {isLoading ? (
            <div {...buttonSpinnerClassNameProps} />
          ) : (
            <>
              {icon && iconPosition === 'left' ? (
                <div className={`new_button__icon`}>{icon}</div>
              ) : null}
              {label ? <div className="new_button__label">{label}</div> : null}
              {icon && iconPosition === 'right' ? (
                <div className={`new_button__icon`}>{icon}</div>
              ) : null}
            </>
          )}
        </div>
      </button>
    );
  }
);
