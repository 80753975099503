import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  AssignmentArgs,
  ElearningCourse as ElearningCourseType,
  IApiService,
} from './types';

export class ElearningCourse
  extends ApiService
  implements IApiService<'elearningCourse'>
{
  public paths: any;
  public serviceBase: string = `/resource/course/elearning`;

  constructor(props) {
    super(props);
    this.paths = {
      remove: (id) => `${this.serviceBase}/${id}`,
      listForSelect: () => `/list/elearning_course`,
    };
  }

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };

  listForSelect = (input, includeDeleted = false) =>
    this.api.get<string, ElearningCourseType[]>(this.paths.listForSelect(), {
      params: { query: { name: input }, include_deleted: !!includeDeleted },
    });
}
