import store from 'store';

import { getLearningProgramClients } from '@nl-lms/feature/learning-programs/sdk';
import { automationApi } from '@nl-lms/web/_common/services/api/automationApi';

import { apiBaseUrl } from './api';
import { learningAssignmentsApi } from './learningAssignmentsApi';

const { raw: learningProgramsApiClient, rtk: learningProgramsRtkClient } =
  getLearningProgramClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export const tsRestClient = learningProgramsApiClient;
export { learningProgramsApiClient };

export const learningProgramsApi = learningProgramsRtkClient;
learningProgramsApi.enhanceEndpoints<
  'LearningProgram' | 'LearningProgramInstance' | 'LearningProgramRule'
>({
  endpoints: {
    listLearningPrograms: {
      providesTags: [{ type: 'LearningProgram', id: 'LIST' }],
    },
    listLearningProgramInstances: {
      providesTags: [{ type: 'LearningProgramInstance', id: 'LIST' }],
    },
    getLearningProgramInstance: {
      providesTags: (result, error, arg) => [
        { type: 'LearningProgramInstance', id: arg.id },
      ],
    },
    getLearningProgram: {
      providesTags: (result, error, arg) => [
        { type: 'LearningProgram', id: arg.id },
      ],
    },
    createLearningProgram: {
      invalidatesTags: (result, error, arg) => {
        return [
          // @ts-ignore
          { type: 'LearningProgram', id: result.id },
          { type: 'LearningProgram', id: 'LIST' },
        ];
      },
    },
    updateLearningProgram: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgram', id: 'LIST' },
        ];
      },
    },
    removeLearningPrograms: {
      invalidatesTags: [
        { type: 'LearningProgram', id: 'LIST' },
        { type: 'LearningProgramInstance', id: 'LIST' },
      ],
    },
    removeLearningProgramInstances: {
      invalidatesTags: [
        { type: 'LearningProgram', id: 'LIST' },
        { type: 'LearningProgramInstance', id: 'LIST' },
      ],
    },
    removeLearningProgramAssignments: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignment',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: [
        { type: 'LearningProgram', id: 'LIST' },
        { type: 'LearningProgramInstance', id: 'LIST' },
      ],
    },
    addLearningProgramAssignments: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignment',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: [
        { type: 'LearningProgram', id: 'LIST' },
        { type: 'LearningProgramInstance', id: 'LIST' },
      ],
    },
    resolveLearningProgramInstances: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstance',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgram', id: 'LIST' },
          { type: 'LearningProgramInstance', id: 'LIST' },
        ];
      },
    },
    addLearningProgramInstances: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstance',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgram', id: 'LIST' },
          { type: 'LearningProgramInstance', id: 'LIST' },
        ];
      },
    },
    scheduleLearningProgramAssignmentInstances: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstance',
                id: 'LIST',
              },
              {
                // @ts-expect-error
                type: 'LearningAssignmentPlannedInstance',
                id: 'LIST',
              },
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstanceAndPlannedInstance',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgramInstance', id: 'LIST' },
        ];
      },
    },
    addLearningProgramAssignmentInstances: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstance',
                id: 'LIST',
              },
              {
                // @ts-expect-error
                type: 'LearningAssignmentInstanceAndPlannedInstance',
                id: 'LIST',
              },
              {
                // @ts-expect-error
                type: 'LearningAssignmentPlannedInstance',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgramInstance', id: 'LIST' },
        ];
      },
    },
    listLearningProgramRules: {
      providesTags: (result, error, arg) => [
        { type: 'LearningProgram', id: arg.id },
        { type: 'LearningProgramRule', id: 'LIST' },
      ],
    },
    addLearningProgramRules: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            learningAssignmentsApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'LearningAssignment',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgram', id: 'LIST' },
          { type: 'LearningProgramInstance', id: 'LIST' },
        ];
      },
    },
    upsertAutoEnrollRule: {
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            automationApi.util.invalidateTags([
              {
                // @ts-expect-error
                type: 'Rule',
                id: 'LIST',
              },
            ])
          );
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningProgram', id: arg.id },
          { type: 'LearningProgram', id: 'LIST' },
        ];
      },
    },
  },
});

type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type LearningProgramApiMutationNames = FilterEndingWith<
  keyof typeof learningProgramsApi,
  'Mutation'
>;
