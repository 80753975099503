import React, { useCallback, useState } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { Button } from '../Button/Button';
import { TidComponent } from '../index.types';
import './ToggleButtonGroup.scss';

type ToggleButtonGroupOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

type ToggleButtonGroupProps = TidComponent<{
  options: ToggleButtonGroupOption[];
  className?: string;
  initialSelectedOption?: ToggleButtonGroupOption | string | number;
  onChange: any;
  name: string;
  isLoading?: boolean;
  small?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}>;

export const ToggleButtonGroup: React.FunctionComponent<
  ToggleButtonGroupProps
> = (props) => {
  const {
    options,
    className = '',
    initialSelectedOption,
    onChange,
    name,
    disabled = false,
    isLoading = false,
    readOnly = false,
    ...buttonProps
  } = props;
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('toggle-button-group', props);

  const [selectedOption, setSelectedOption] = useState(() => {
    if (
      typeof initialSelectedOption === 'string' ||
      typeof initialSelectedOption === 'number'
    ) {
      return options.find((o) => o.value === initialSelectedOption);
    }
    return initialSelectedOption;
  });

  const onClickOption = useCallback(
    (value) => {
      const newlySelectedOption = options.find((o) => o.value === value);
      if (newlySelectedOption) {
        setSelectedOption(newlySelectedOption);
        onChange(value, name);
      }
    },
    [onChange, setSelectedOption, options, name]
  );

  return (
    <div {...classNameProps} {...commonProps}>
      {options.map((option, index) => (
        <Button
          {...buttonProps}
          key={`${name}-${option.value}-${index}`}
          name={option.value.toString()}
          regular={selectedOption?.value !== option.value}
          onClick={() => onClickOption(option.value)}
          label={option.label}
          disabled={disabled || option.disabled}
          isLoading={selectedOption?.value === option.value && isLoading}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};
