import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AssessmentFormQuestionType } from '@nl-lms/common/feature/types';
import {
  AssessmentQuestion,
  Survey,
  SurveyQuestionCondition,
} from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  Draggable,
  FormField,
  FormWizard,
  Icon,
  IconButton,
  Input,
  Link,
  Modal,
  MultiSelect,
  Separator,
  SideModal,
  SingleSelect,
  SwitchInput,
  Tooltip,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { SurveyPlayer } from '../../../_common/modules/SurveyPlayer/SurveyPlayer';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import {
  AdminAssessmentQuestionEditFormSideModal,
  AdminAssessmentQuestionEditFormSideModalReturnType,
} from '../AdminAssessment/AdminAssessmentQuestionEditFormSideModal';
import {
  AdminAssessmentQuestionImportModal,
  AdminAssessmentQuestionImportModalReturnType,
} from '../AdminAssessment/AdminAssessmentQuestionImportModal/AdminAssessmentQuestionImportModal';
import { AdminAssessmentQuestionSingleSelect } from '../AdminAssessment/AdminAssessmentQuestionSelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';

type Props = {
  survey?: Survey;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  thumbnail: yup.string(),
  tagIds: yup.array(),
  questions: yup.array().required().min(1),
  canStartWithoutAssignment: yup.boolean().required(),
});

const getDefaultValues = (survey?: Survey) => {
  if (survey) {
    return {
      ..._.omit(survey, 'id'),
      questions: _.sortBy(survey.questions, (q) => q.order),
    };
  }
  return {
    name: '',
    description: '',
    thumbnail: '',
    tagIds: [],
    questions: [],
    canStartWithoutAssignment: false,
  };
};

export const AdminSurveyEditFormSideModal = ({ survey }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: getDefaultValues(survey),
  });
  const isEditing = useMemo(() => !!survey, [survey]);
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateSurveyMutation',
    createHookName: 'useCreateSurveyMutation',
    entityId: survey?.id,
  });
  return (
    <SideModal.Content className="admin-edit-form-side-modal">
      <FormWizard.Provider
        steps={['questions', 'details']}
        withContainer={false}
      >
        <SideModal.Header>
          <SideModal.Title>
            {isEditing ? 'Edit Survey' : 'Create New Survey'}
          </SideModal.Title>
        </SideModal.Header>
        <SideModal.Body>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
          <Box margin={{ bottom: 'm' }}>
            <FormWizard.Tabs />
          </Box>
          <FormWizard.Step name="details">
            <Box>
              <Box>
                <FormField label="Tags">
                  <Controller
                    name="tagIds"
                    control={control}
                    render={({ field }) => (
                      <AdminTagSelect {...field} selectedItems={field.value} />
                    )}
                  />
                </FormField>
              </Box>
            </Box>
            <Box>
              <FormField
                label="Description"
                errorMessage={errors?.description?.message}
              >
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextEditorWithFileUpload {...field} />
                  )}
                />
              </FormField>
            </Box>
            <Box>
              <Box>
                <FormField
                  label="Thumbnail"
                  errorMessage={errors?.thumbnail?.message}
                  required
                >
                  <Controller
                    name="thumbnail"
                    control={control}
                    render={({ field }) => (
                      <ImageUploadInput
                        {...field}
                        initialS3Url={
                          survey?.thumbnail || getValues('thumbnail')
                        }
                      />
                    )}
                  />
                </FormField>
              </Box>
            </Box>

            <Box>
              <Box>
                <FormField label="Player Settings">
                  <>
                    <Checkbox
                      {...register('canStartWithoutAssignment')}
                      className="admin-learning-assignment-modal__edit-checkbox"
                      label="Can Start Without Assignment"
                    />
                  </>
                </FormField>
              </Box>
            </Box>
          </FormWizard.Step>
          <FormWizard.Step name="questions">
            <Controller
              name="questions"
              control={control}
              render={({ field }) => (
                <AdminSurveyEditFormQuestionsInput
                  {...field}
                  error={errors?.questions?.message}
                />
              )}
            />
          </FormWizard.Step>
        </SideModal.Body>
        <SideModal.Actions>
          {/*// @ts-ignore */}
          <SideModal.Error error={error} />
          <Tooltip
            offsetToChildren={10}
            title="See how the final form will look like for a learner"
          >
            <AdminSurveyPlayerPreviewModal
              surveyForm={{
                name: watch('name'),
                questions: watch('questions'),
                description: watch('description'),
              }}
            >
              <Button label="Preview" regular />
            </AdminSurveyPlayerPreviewModal>
          </Tooltip>
          <Button
            label={isEditing ? 'Save' : 'Create'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </SideModal.Actions>
      </FormWizard.Provider>
    </SideModal.Content>
  );
};

export const AdminSurveyEditFormQuestionsInput = ({
  onChange,
  value = [],
  error,
}: {
  onChange: (q: any) => void;
  value?: Survey['questions'];
  error?: string;
}) => {
  const questions = value;
  const [selectedQuestion, setSelectedQuestion] =
    // @ts-ignore
    useState<AssessmentQuestion>(null);
  const [clearSelection, setClearSelection] = useState(false);

  const onChangeQuestion = useCallback((item) => {
    if (!item) return;
    setSelectedQuestion(item.question);
  }, []);

  const { addWarningNotification } = useNotifications();

  const showImportQuestionsModal =
    useShowModal<AdminAssessmentQuestionImportModalReturnType>(
      AdminAssessmentQuestionImportModal
    );
  const showCreateQuestionModal =
    useShowModal<AdminAssessmentQuestionEditFormSideModalReturnType>(
      AdminAssessmentQuestionEditFormSideModal
    );

  const onClickCreateQuestion = useCallback(async () => {
    const result = await showCreateQuestionModal();
    if (result?.success) {
      onChange([
        ...questions,
        {
          ...result.payload,
          questionId: result.payload.id,
          order: questions.length + 1,
          required: true,
        },
      ]);
    }
  }, [questions]);

  const onClickImportQuestions = useCallback(async () => {
    const result = await showImportQuestionsModal();
    if (result?.success) {
      onChange([
        ...questions,
        ...result.payload.map((q, index) => ({
          ...q,
          questionId: q.id,
          order: questions.length + index + 1,
          required: true,
        })),
      ]);
    }
  }, [questions]);

  const onAddQuestion = useCallback(() => {
    if (!selectedQuestion) return;

    if (questions.map((q) => q.questionId).includes(selectedQuestion.id)) {
      addWarningNotification(`You can't add the same question twice.`);
      return;
    }

    if (
      questions.find((q) => q.type === AssessmentFormQuestionType.Rating) &&
      selectedQuestion.type === AssessmentFormQuestionType.Rating
    ) {
      addWarningNotification(
        'You can only have one rating question in a survey'
      );
      return;
    }

    onChange([
      ...questions,
      {
        ...selectedQuestion,
        questionId: selectedQuestion.id,
        required: true,
        order: questions.length,
      },
    ]);
    setClearSelection(true);
    // @ts-ignore
    setSelectedQuestion(null);
  }, [selectedQuestion, questions]);

  const onToggleRequired = useCallback(
    (questionIndex) => {
      const q = questions[questionIndex];
      if (!q) return;
      const clone = _.clone(questions);
      clone[questionIndex] = { ...q, required: !q.required };
      onChange(clone);
    },
    [questions]
  );

  const onMoveQuestion = useCallback(
    (from: number, to: number) => {
      const newQuestions = [...questions];
      const [movedQuestion] = newQuestions.splice(from, 1);
      newQuestions.splice(to, 0, movedQuestion);
      onChange(
        newQuestions.map((q, index) => ({
          ...q,
          order: index + 1,
        }))
      );
    },
    [questions]
  );

  const onRemoveQuestion = useCallback(
    (qIndex) => {
      const q = questions[qIndex];
      if (!q) return;
      const clone = _.clone(questions);
      clone.splice(qIndex, 1);
      onChange(clone.map((q, index) => ({ ...q, order: index })));
    },
    [questions]
  );

  const onUpdateCondition = useCallback(
    (payload) => {
      const { questionId, condition } = payload;
      onChange(
        questions.map((q) => {
          if (q.questionId === questionId) {
            return {
              ...q,
              condition,
            };
          }
          return q;
        })
      );
    },
    [questions]
  );

  useEffect(() => {
    if (selectedQuestion) {
      setClearSelection(false);
    }
  }, [selectedQuestion]);

  return (
    <>
      <Box margin={{ bottom: 's' }}>
        <Typography.p>
          You can choose from existing questions or either{' '}
          <Link onClick={onClickCreateQuestion}>create</Link> or{' '}
          <Link onClick={onClickImportQuestions}>import</Link> import new ones.
        </Typography.p>
      </Box>
      <Box>
        <Box>
          <FormField errorMessage={error}>
            <Box
              flex={{
                flexDirection: 'row',
                gap: 's',
                alignItems: 'flex-start',
              }}
            >
              <AdminAssessmentQuestionSingleSelect
                name="survey-question-select"
                onChange={onChangeQuestion}
                returnEntireItemOnChange
                isClearable
                clearSelection={clearSelection}
              />
              <Button
                label="Add"
                regular
                onClick={onAddQuestion}
                disabled={!selectedQuestion}
              />
            </Box>
            <Separator marginBottom={20} marginTop={20} />
            <Box flex={{ flexDirection: 'column' }}>
              {questions.map((question, index) => (
                <AdminSurveyEditFormQuestionRow
                  questions={questions}
                  question={question}
                  index={index}
                  onMoveQuestion={onMoveQuestion}
                  onUpdateQuestionCondition={onUpdateCondition}
                  onRemoveQuestion={onRemoveQuestion}
                  onToggleRequired={onToggleRequired}
                  key={question.questionId}
                />
              ))}
            </Box>
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const AdminSurveyEditFormQuestionRow = ({
  questions,
  question,
  index,
  onUpdateQuestionCondition,
  onRemoveQuestion,
  onMoveQuestion,
  onToggleRequired,
}) => {
  return (
    <>
      <Box
        padding={{
          top: 's',
          bottom: 's',
          right: 'xs',
          left: 'xs',
        }}
        flex={{
          flexDirection: 'column',
        }}
        asChild
      >
        <Draggable
          type="input"
          index={index}
          onDrop={onMoveQuestion}
          className="admin-edit-form-side-modal__question-row"
        >
          <Box
            flex={{
              flexDirection: 'row',
              gap: 'xs',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ width: '100%' }}>
              <Typography.h3>
                {index + 1}. {question.title}
              </Typography.h3>
            </div>
            <Draggable.HoverActions>
              <Draggable.DragButton />
            </Draggable.HoverActions>
          </Box>
          <Box
            key={`question-${question.name}-${index}`}
            flex={{
              flexDirection: 'row',
              gap: 'm',
              alignItems: 'center',
            }}
          >
            <Box flex={{ flexDirection: 'row' }}>
              <Typography.p type="muted">
                {formatConstantString(
                  AssessmentFormQuestionType[question.type]
                )}
                {' • '}
                <Tooltip
                  title={
                    question.required ? 'Set as optional' : 'Set as required'
                  }
                  asChild
                >
                  <span>
                    <Link onClick={() => onToggleRequired(index)}>
                      {question.required ? 'Required' : 'Optional'}
                    </Link>
                  </span>
                </Tooltip>
                {' • '}
                <EditSurveyQuestionConditionModal
                  otherQuestions={questions.filter(
                    (q) => q.questionId !== questions[index].questionId
                  )}
                  onSubmit={onUpdateQuestionCondition}
                  activeQuestion={questions[index]}
                />
                {' • '}
                <Link onClick={() => onRemoveQuestion(index)}>Remove</Link>
              </Typography.p>
            </Box>
          </Box>
        </Draggable>
      </Box>
    </>
  );
};

const EditSurveyQuestionConditionModal = ({
  onSubmit,
  otherQuestions,
  activeQuestion,
}) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>
        <Tooltip
          title={activeQuestion.condition ? 'Edit Condition' : 'Add Condition'}
          asChild
        >
          <Link>{activeQuestion.condition ? 'Conditional' : 'Visible'}</Link>
        </Tooltip>
      </Modal.Trigger>
      <Modal.Content scrollable>
        <EditSurveyQuestionConditionModalContent
          otherQuestions={otherQuestions}
          activeQuestion={activeQuestion}
          onSubmit={onSubmit}
        />
      </Modal.Content>
    </Modal.Provider>
  );
};

const EditSurveyQuestionConditionModalContent = ({
  activeQuestion,
  otherQuestions,
  onSubmit: _onSubmit,
}) => {
  const [conditions, setConditions] = useState<SurveyQuestionCondition>(
    activeQuestion.condition || []
  );
  const { hide } = useModal();

  const isValidCondition = useMemo(() => {
    return !(!conditions || !conditions.length);
  }, [conditions]);
  const onSubmit = useCallback(() => {
    if (!isValidCondition) return;
    _onSubmit({ condition: conditions, questionId: activeQuestion.questionId });
    hide();
  }, [isValidCondition, conditions, activeQuestion]);
  const onRemove = useCallback(() => {
    _onSubmit(null);
    hide();
  }, []);
  const onChangeConditionAnswer = useCallback(
    (answer, itemIndex) => {
      const newConditions = _.cloneDeep(conditions);
      if ('answer' in newConditions[itemIndex]) {
        // @ts-ignore
        newConditions[itemIndex].answer = answer;
        setConditions(newConditions);
      }
    },
    [conditions]
  );
  const onToggleCombinator = useCallback(
    (combinatorIndex) => {
      const combinator = conditions[combinatorIndex];
      if (!('combinator' in combinator)) return;
      const newConditions = [...conditions];
      newConditions[combinatorIndex] = {
        combinator: combinator.combinator === 'and' ? 'or' : 'and',
      };
      setConditions(newConditions);
    },
    [conditions]
  );
  const onSelectReferenceQuestion = useCallback(
    (value) => {
      if (!value) return;
      if (!conditions.length) {
        setConditions([
          {
            questionId: value,
            answer: [],
          },
        ]);
      } else {
        setConditions([
          ...conditions,
          { combinator: 'and' },
          {
            questionId: value,
            answer: [],
          },
        ]);
      }
    },
    [conditions]
  );
  const onRemoveCondition = useCallback(
    (itemIndex) => {
      const item = conditions[itemIndex];
      if (!item || 'combinator' in item) return null;
      const indexesToRemove = [itemIndex];
      if (itemIndex === 0 && conditions.length > 1) {
        indexesToRemove.push(itemIndex + 1);
      }
      if (itemIndex !== 0) {
        indexesToRemove.push(itemIndex - 1);
      }
      setConditions(
        conditions.filter((c, index) => !indexesToRemove.includes(index))
      );
    },
    [conditions]
  );

  return (
    <>
      <Modal.Header>
        {activeQuestion.condition ? 'Edit Condition' : 'Add Condition'}
      </Modal.Header>{' '}
      {conditions.length ? (
        <Box margin={{ bottom: 's' }}>
          <Typography.p type="muted">
            Select other questions if you want to extend your condition.
          </Typography.p>
        </Box>
      ) : null}
      <SingleSelect
        name="reference-question-select"
        // @ts-ignore
        selectedItem={null}
        onChange={onSelectReferenceQuestion}
        options={otherQuestions.map((q) => ({
          value: q.questionId,
          label: q.title,
        }))}
      />
      <Box flex={{ flexDirection: 'column', gap: 'm' }}>
        {conditions.length ? (
          <Box margin={{ top: 's', bottom: 's' }}>
            <Typography.h4>Question will be visible if:</Typography.h4>
          </Box>
        ) : (
          <Box margin={{ top: 's' }}>
            <Typography.p type="muted">
              Select the question that should be used as reference.
            </Typography.p>
          </Box>
        )}
        {conditions.map((condition, index) => {
          if ('combinator' in condition) {
            return (
              <SwitchInput
                name="test"
                options={[
                  { value: 'and', label: 'and' },
                  { value: 'or', label: 'or' },
                ]}
                activeOption={condition.combinator}
                onChange={() => onToggleCombinator(index)}
              />
            );
          }
          const referenceQuestion = otherQuestions.find(
            (q) => q.questionId === condition.questionId
          );
          if (!referenceQuestion) return null;
          return (
            <Box flex={{ flexDirection: 'column', gap: 's' }}>
              <Box flex={{ flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                  <Typography.p>{referenceQuestion.title}</Typography.p>
                </div>
                <IconButton
                  label="Remove"
                  onClick={() => onRemoveCondition(index)}
                  size="xs"
                >
                  <Icon.DeleteIcon />
                </IconButton>
              </Box>
              <Typography.p type="muted">
                has the following answer:
              </Typography.p>
              <ConditionAnswerInput
                currentAnswer={condition.answer}
                question={referenceQuestion}
                onChange={(value) => onChangeConditionAnswer(value, index)}
              />
            </Box>
          );
        })}
      </Box>
      <Modal.Actions>
        {activeQuestion.condition ? (
          <Button regular label="Remove" onClick={onRemove} />
        ) : null}
        <Button
          disabled={!isValidCondition}
          onClick={onSubmit}
          label={activeQuestion.condition ? 'Update' : 'Add'}
        />
      </Modal.Actions>
    </>
  );
};

const ConditionAnswerInput = ({ question, currentAnswer, onChange }) => {
  const onSelectOption = useCallback(
    (answer) => {
      let selectedAnswer;
      if (answer?.target) {
        selectedAnswer = [answer.target.value];
      } else if (Array.isArray(answer)) {
        selectedAnswer = answer;
      } else {
        selectedAnswer = [answer];
      }
      onChange(selectedAnswer);
    },
    [onChange]
  );

  if (
    [
      AssessmentFormQuestionType.Nps,
      AssessmentFormQuestionType.Range,
      AssessmentFormQuestionType.Dropdown,
      AssessmentFormQuestionType.SingleChoice,
    ].includes(question.type)
  ) {
    return (
      <SingleSelect
        name="answer"
        onChange={onSelectOption}
        selectedItem={currentAnswer[0]}
        options={question.options.map((o) => ({
          value: o.title,
          label: o.title,
        }))}
      />
    );
  }

  if (AssessmentFormQuestionType.MultipleChoice === question.type) {
    return (
      <MultiSelect
        name="answer"
        onChange={onSelectOption}
        selectedItems={currentAnswer.map((c) => ({ value: c, label: c }))}
        options={question.options.map((o) => ({
          value: o.title,
          label: o.title,
        }))}
      />
    );
  }
  return (
    <Input name="answer" value={currentAnswer[0]} onChange={onSelectOption} />
  );
};

const AdminSurveyPlayerPreviewModal = ({ children, surveyForm }) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>{children}</Modal.Trigger>
      <SideModal.Content>
        <SideModal.Header>
          <SideModal.Title>Form Preview</SideModal.Title>
        </SideModal.Header>
        <SideModal.Body>
          <SurveyPlayer surveyForm={surveyForm} submitBtnLabel="Submit" />
        </SideModal.Body>
      </SideModal.Content>
    </Modal.Provider>
  );
};
