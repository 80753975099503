import { z } from 'zod';

import {
  CompetencySchema,
  LiveCourseSchema,
  LiveCourseType,
  LiveSessionExtendedSchema,
  LiveSessionLearnerSchema,
} from '@nl-lms/common/feature/types';

export const LearningPortalLiveSessionSchema = LiveSessionExtendedSchema.extend(
  {
    learner: LiveSessionLearnerSchema.nullish(),
    remainingSeats: z.number(),
    enableWaitingList: z.boolean(),
  }
);

export const GetLiveCourseSchema = LiveCourseSchema.omit({
  externalId: true,
  userId: true,
  trainingSessionApproverIds: true,
  targetLearnerGroupIds: true,
}).extend({
  isRegistered: z.boolean(),
  competencies: z.array(CompetencySchema),
  upcomingLiveSessions: z.array(LearningPortalLiveSessionSchema),
  liveSessionActivity: z.array(
    LiveSessionLearnerSchema.extend({
      name: z.string(),
      startDate: z.date().nullable(),
      type: z.nativeEnum(LiveCourseType),
    })
  ),
});

export const ListLiveSessionsSchema = LearningPortalLiveSessionSchema.extend({
  courseActivity: z.array(LiveSessionLearnerSchema),
});

export const LiveCourseWithLiveSessionsSchema = LiveCourseSchema.omit({
  trainingSessionApproverIds: true,
  targetLearnerGroupIds: true,
}).extend({
  liveSessions: z.array(LearningPortalLiveSessionSchema),
});
