import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { AutomationRouterContract } from './automation.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'automationStore';

  const tsRest = buildTsRestInstance<typeof AutomationRouterContract>(
    apiInstance,
    AutomationRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listActions: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<z.infer<typeof AutomationRouterContract.listActions.query>>
      >,
      z.infer<(typeof AutomationRouterContract.listActions.responses)['200']>
    >;
    listRules: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<z.infer<typeof AutomationRouterContract.listRules.query>>
      >,
      z.infer<(typeof AutomationRouterContract.listRules.responses)['200']>
    >;
    scheduleActions: RtkMutation<
      RtkFullParams<
        z.infer<typeof AutomationRouterContract.scheduleActions.body>
      >,
      z.infer<
        (typeof AutomationRouterContract.scheduleActions.responses)['200']
      >
    >;
    removeActions: RtkMutation<
      RtkFullParams<
        z.infer<typeof AutomationRouterContract.removeActions.body>
      >,
      z.infer<(typeof AutomationRouterContract.removeActions.responses)['200']>
    >;
    toggleRules: RtkMutation<
      RtkFullParams<z.infer<typeof AutomationRouterContract.toggleRules.body>>,
      z.infer<(typeof AutomationRouterContract.toggleRules.responses)['200']>
    >;
  }>(tsRest, AutomationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
