import { LayoutDirection } from 'libs/ui/src/lib/components/FlowGraph/useGraphElements';
import { toUpper } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  Position,
  getBezierPath,
  useReactFlow,
} from 'reactflow';

import { Rule, RuleCondition } from '@nl-lms/common/feature/types';
import {
  Badge,
  BadgeTypes,
  Box,
  ContextMenu,
  FlowGraphEdgeType,
  FlowGraphEdgeTypes,
  Icon,
  IconButton,
  Typography,
  useConfirmationModal,
} from '@nl-lms/ui/components';

import {
  RuleComponent,
  getRuleAssignationDate,
  getRuleTriggerNTimesLabel,
} from '../learning-assignment-rule/RuleComponent';
import { ProgramRuleTemplates, ProgramRulesTemplateTypes } from './constants';
import { ProgramRule, RULE_NODE, START_NODE } from './types';

type LearningProgramRulesComponentGraphConnectorEdgeProps = {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  source: string;
  target: string;
  markerEnd?: string;
  sourcePosition: Position;
  targetPosition: Position;
  data: {
    type: FlowGraphEdgeType;
    selfIndex: number;
    index: number;
    color: string;
    name: string;
    parentData: {
      index: number;
      id: string;
    };
    onDeleteEdge?: ({
      ruleIndex,
      conditionIndex,
      source,
      target,
    }: {
      source?: string;
      target?: string;
      ruleIndex: number;
      conditionIndex?: number;
    }) => void;
    onClickEdge?: (index: number, rule: ProgramRule) => void;
    layout: LayoutDirection;
    isHighlighted?: (nodeId: string) => boolean;
    condition?: {
      value?: RuleCondition;
      combinator?: 'and' | 'or' | null;
    };
  } & Partial<Rule>;
};

export const LearningProgramRulesComponentGraphConnectorEdge = (
  props: LearningProgramRulesComponentGraphConnectorEdgeProps
) => {
  const [isClicked, setIsClicked] = useState(false);
  const ruleEdgeContainerRef = useRef<HTMLDivElement>(null);
  const ruleCombinator = useMemo(
    () => props?.data?.condition?.combinator,
    [props?.data]
  );
  const globalOnClickListener = useCallback((e) => {
    if (
      ruleEdgeContainerRef.current &&
      !ruleEdgeContainerRef.current.contains(e.target)
    ) {
      setIsClicked(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', globalOnClickListener);
    return () => {
      document.removeEventListener('click', globalOnClickListener);
    };
  }, []);

  const [customEdgePath, customXCoord, customYCoord] = getBezierPath(props);

  const [selfXCoord, selfYCoord] = useMemo(
    () => [(props?.targetX - props?.sourceX) * 0.6, 50],
    [props]
  );

  const selfEdgePath = useMemo(
    () =>
      `M ${props?.sourceX - 5} ${props?.targetY} A ${
        (props?.sourceX - props?.targetX) * 0.6
      } ${props?.data?.selfIndex * 35 + 75} 0 1 0 ${props?.targetX + 2} ${
        props?.targetY
      }`,
    [props, selfXCoord, selfYCoord]
  );

  const edgeComponentStyle = useMemo(() => {
    let transform;
    if (props?.data?.type === FlowGraphEdgeTypes.Connector) {
      transform = `translate(-50%, -50%) translate(${customXCoord}px,${customYCoord}px)`;
    } else if (props?.data?.type === FlowGraphEdgeTypes.Self) {
      const posX = props?.sourceX - 300;
      const posY =
        props?.sourceY - 140 - props?.data?.selfIndex * selfYCoord * 1.1;
      transform = `translate(${posX}px,${posY}px)`;
    }
    return transform ? { transform } : {};
  }, [props, customXCoord, customYCoord]);

  const edgeDetailStyle = useMemo(() => {
    let transform;
    if (props?.data?.type === FlowGraphEdgeTypes.Connector) {
      transform = `translate(-50%, 100%) translate(${customXCoord}px,${customYCoord}px)`;
    } else if (props?.data?.type === FlowGraphEdgeTypes.Self) {
      const posX = props?.sourceX - 100;
      const posY =
        props?.sourceY - 140 - props?.data?.selfIndex * selfYCoord * 1.1;
      transform = `translate(${posX}px,${posY}px)`;
    }
    return transform ? { transform } : {};
  }, [props, customXCoord, customYCoord]);

  const { setEdges } = useReactFlow();

  const showRemoveStartEdgeConfirmationModal = useConfirmationModal({
    message:
      'Are you sure that you want to perform this action? Keep in mind that removing starting points also removes its ending points',
  });
  const onRemoveEdge = useCallback(
    async (e) => {
      e?.stopPropagation();
      if (props?.source === START_NODE) {
        const confirmationResult = await showRemoveStartEdgeConfirmationModal();
        if (!confirmationResult.confirmed) return false;
      }
      setEdges((edges) => edges.filter((edge) => edge.id !== props?.id));
      props?.data?.onDeleteEdge?.({
        source: props?.source,
        target: props?.target,
        ruleIndex: props?.data?.parentData?.index,
      });
    },
    [setEdges, props]
  );

  const isEdit = useMemo(() => !!props?.data?.onDeleteEdge, [props]);

  const onEditEdge = useCallback(() => {
    props?.data?.onClickEdge?.(
      props?.data?.parentData?.index,
      props?.data as any as ProgramRule
    );
  }, [props]);

  const edgeContextMenuOptions = [
    {
      name: 'Edit Rule',
      handler: onEditEdge,
    },
    {
      name: 'Remove Rule',
      handler: onRemoveEdge,
    },
  ];

  const isDisabled = useMemo(
    () => !props?.data?.isHighlighted?.(props?.target),
    [props]
  );

  const startDateDetails = useMemo(() => {
    let startDate;
    if (props?.data?.scheduleDate && props?.data?.scheduleDate?.value) {
      startDate = (
        <Typography.p>
          {getRuleAssignationDate(props?.data?.scheduleDate)}
        </Typography.p>
      );
    }

    return startDate ? <>{startDate}</> : null;
  }, [props?.data]);

  const settingsDetails = useMemo(() => {
    let triggerTimes = props?.data?.settings?.triggerNTimes as any;
    if (!triggerTimes || triggerTimes === '1' || triggerTimes === 1) {
      return null;
    }
    if (isNaN(triggerTimes)) triggerTimes = parseInt(triggerTimes);

    return (
      <Typography.p>{getRuleTriggerNTimesLabel(triggerTimes)}</Typography.p>
    );
  }, [props?.data]);

  return (
    <>
      <BaseEdge
        path={
          props?.data?.type === FlowGraphEdgeTypes.Connector
            ? customEdgePath
            : selfEdgePath
        }
        markerEnd={props?.markerEnd}
        style={{
          stroke: isDisabled ? '#e5e5e7' : props?.data?.color,
          strokeWidth: 2,
          opacity: isDisabled ? 0.25 : 1,
          transition: 'opacity 250ms ease-in-out',
        }}
      />
      <EdgeLabelRenderer>
        <ContextMenu items={edgeContextMenuOptions} disabled={!isEdit}>
          {(startDateDetails || settingsDetails) && (
            <div
              style={{
                border: `2px solid ${
                  isDisabled ? '#e5e5e7' : props?.data?.color
                }`,
                ...edgeDetailStyle,
              }}
              className={`learning-program-graph__edge-connection__detail ${
                isDisabled
                  ? 'learning-program-graph__edge-connection__detail--faded'
                  : ''
              }`}
            >
              {startDateDetails}
              {settingsDetails}
            </div>
          )}
          <div
            ref={ruleEdgeContainerRef}
            // @ts-ignore
            style={{
              border: `2px solid ${
                isDisabled ? '#e5e5e7' : props?.data?.color
              }`,
              ...edgeComponentStyle,
            }}
            onClick={isEdit ? onEditEdge : () => setIsClicked(true)}
            className={`learning-program-graph__edge-connection ${
              isDisabled ? 'learning-program-graph__edge-connection--faded' : ''
            }`}
          >
            <Box
              className="learning-program-graph__edge-header"
              flex={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Icon.GitIconSmall />
              </Box>
              {ruleCombinator && (
                <Badge type={BadgeTypes.LIGHT} label={ruleCombinator} />
              )}
              <Box flex={{ flexDirection: 'row' }}>
                <Typography.h4 className="learning-program-graph__edge-header__text">
                  {props?.data?.name}
                </Typography.h4>
              </Box>
            </Box>
            {isClicked ? (
              <div
                className="learning-program-graph__edge-tooltip"
                style={{
                  height: 'fit-content',
                }}
              >
                <Box className="learning-program-graph__edge-body">
                  {/* @ts-ignore */}
                  <RuleComponent rule={props?.data} />
                </Box>
              </div>
            ) : null}
          </div>
        </ContextMenu>
      </EdgeLabelRenderer>
    </>
  );
};

export const LearningProgramRulesComponentGraphSelfEdge = (props) => (
  <LearningProgramRulesComponentGraphConnectorEdge {...props} type="self" />
);

export const LearningProgramRulesComponentGraphEndEdge = (props) => {
  const [customEdgePath, customXCoord, customYCoord] = getBezierPath(props);

  const { setEdges } = useReactFlow();

  const onRemoveEdge = useCallback(
    async (e) => {
      e?.stopPropagation();
      setEdges((edges) => edges.filter((edge) => edge.id !== props?.id));

      props?.data?.onDeleteEdge?.({
        source: props?.source,
        target: props?.target,
        ruleIndex: props?.data?.parentData?.index,
        conditionIndex: props?.data?.index,
      });
    },
    [setEdges, props]
  );

  const onEditEdge = useCallback(() => {
    props?.data?.onClickEdge?.(
      props?.data?.parentData?.index,
      props?.data as ProgramRule
    );
  }, []);

  const edgeContextMenuOptions = [
    {
      name: 'Edit Rule',
      handler: onEditEdge,
    },
    {
      name: 'Remove Rule',
      handler: onRemoveEdge,
    },
  ];

  const isEdit = useMemo(() => !!props?.data?.onDeleteNode, [props]);

  const isDisabled = useMemo(
    () => !props?.data?.isHighlighted?.(props?.target),
    [props]
  );

  const ruleLabel = useMemo(
    () =>
      ProgramRuleTemplates.find(
        (template) =>
          template.value ===
          (props?.data?.type === FlowGraphEdgeTypes.End.Completed
            ? ProgramRulesTemplateTypes.assignCompleted
            : ProgramRulesTemplateTypes.assignFailed)
      )?.payload?.name || '',
    [props]
  );

  return (
    <>
      <BaseEdge
        id={props?.id}
        path={customEdgePath}
        style={{
          stroke: isDisabled ? '#e5e5e7' : props?.data?.color,
          strokeWidth: 2,
          opacity: isDisabled ? 0.25 : 1,
          transition: 'opacity 250ms ease-in-out',
        }}
      />
      <EdgeLabelRenderer>
        <ContextMenu items={edgeContextMenuOptions} disabled={!isEdit}>
          <div
            // @ts-ignore
            style={{
              borderRadius: '4px',
              borderColor: isDisabled ? '#e5e5e7' : `${props?.data?.color}`,
              cursor: 'pointer',
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${customXCoord}px,${customYCoord}px)`,
            }}
            className={`learning-program-graph__edge-connection ${
              isDisabled ? 'learning-program-graph__edge-connection--faded' : ''
            }`}
          >
            <Box
              className="learning-program-graph__edge-header"
              flex={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Icon.GitIconSmall />
              </Box>
              <Box flex={{ flexDirection: 'row' }}>
                <Typography.h4 className="learning-program-graph__edge-header__text">
                  {ruleLabel}
                </Typography.h4>
              </Box>
            </Box>
          </div>
        </ContextMenu>
      </EdgeLabelRenderer>
    </>
  );
};

export const LearningProgramRulesComponentGraphDefaultEdge = (props) => {
  const [customEdgePath, customXCoord, customYCoord] = getBezierPath(props);

  const isDisabled = useMemo(() => {
    const nodeId =
      props.source.includes(RULE_NODE) || props.source === START_NODE
        ? props.target
        : props.source;
    return !props?.data?.isHighlighted?.(nodeId);
  }, [props]);

  return (
    <>
      <BaseEdge
        id={props?.id}
        path={customEdgePath}
        markerEnd={props?.markerEnd}
        style={{
          stroke: isDisabled ? '#e5e5e7' : props?.data?.color,
          strokeWidth: 2,
          opacity: isDisabled ? 0.25 : 1,
          transition: 'opacity 250ms ease-in-out',
        }}
      />
      {props.source === START_NODE ? null : (
        <EdgeLabelRenderer>
          {props?.data?.condition?.combinator || props.source === START_NODE ? (
            <div
              // @ts-ignore
              style={{
                borderRadius: '4px',
                borderColor: 'grey',
                cursor: 'pointer',
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${customXCoord}px,${customYCoord}px)`,
              }}
              className={`learning-program-graph__edge-connection ${
                isDisabled
                  ? 'learning-program-graph__edge-connection--faded'
                  : ''
              }`}
            >
              <Box
                className="learning-program-graph__edge-header"
                flex={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.h4 className="learning-program-graph__edge-header__text">
                  {toUpper(
                    props.source === START_NODE
                      ? ''
                      : props?.data?.condition?.combinator
                  )}
                </Typography.h4>
              </Box>
            </div>
          ) : null}
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export const GraphEdge = {
  Self: LearningProgramRulesComponentGraphSelfEdge,
  End: LearningProgramRulesComponentGraphEndEdge,
  Default: LearningProgramRulesComponentGraphDefaultEdge,
  Connector: LearningProgramRulesComponentGraphConnectorEdge,
};
