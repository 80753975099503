import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { IApiService } from './types';

export type EmailTemplateSeparatorSection = {
  type: 'separator';
  name: string;
};
export type EmailTemplateBodyTextSection = {
  type: 'body_text';
  name: string;
};

export type EmailTemplateTitleSection = {
  type: 'title';
  name: string;
};

export type EmailTemplateFooterSection = {
  type: 'footer_text';
  name: string;
};

type EmailTemplateButton = {
  name: string;
  href: string;
  type: 'default' | 'confirm' | 'decline';
};

export type EmailTemplateButtonsSection = {
  type: 'buttons';
  name: string;
  content: EmailTemplateButton[];
};

export type EmailTemplateTableSection = {
  type: 'table';
  name: string;
  content: [string, string];
};

export type BaseEmailTemplateSection =
  | EmailTemplateSeparatorSection
  | EmailTemplateBodyTextSection
  | EmailTemplateFooterSection
  | EmailTemplateButtonsSection
  | EmailTemplateTableSection
  | EmailTemplateTitleSection;

export type EmailTemplateConditionSection = {
  type: 'condition';
  name: string;
  content: BaseEmailTemplateSection[];
};

export type EmailTemplateRepeaterSection = {
  type: 'repeater';
  name: string;
  content: BaseEmailTemplateSection[];
};

export type EmailTemplateLanguage = 'en' | 'ro';
export type EmailTemplateTranslations = Record<
  EmailTemplateLanguage,
  { string: string }
>;

export type EmailTemplateSection =
  | BaseEmailTemplateSection
  | EmailTemplateRepeaterSection
  | EmailTemplateConditionSection;
export type EmailTemplateSectionType = EmailTemplateSection['type'];

export interface EmailTemplate {
  id: string;
  name: string;
  label: string;
  description: string;
  sections: EmailTemplateSection[];
  translations: EmailTemplateTranslations;
  enabled: boolean;
  reply_to: string;
}

const decodeHtml = (html) => {
  const element = document.createElement('textarea');
  element.innerHTML = html;
  return element.value;
};

export class Email extends ApiService implements IApiService<'email'> {
  public paths: any;
  public serviceBase: string = '/email';

  constructor(props) {
    super(props);
    this.paths = {
      fetchAllTemplates: () => `${this.serviceBase}/templates`,
      getEmailTemplatePreview: () => `${this.serviceBase}/templates/preview`,
      createEmailTemplate: () => `${this.serviceBase}/templates`,
      editEmailTemplate: (id) => `${this.serviceBase}/templates/${id}`,
      deleteEmailTemplate: (id) => `${this.serviceBase}/templates/${id}`,
      sendEmail: (resourceName, actionType) =>
        `${this.serviceBase}/${resourceName}/${actionType}`,
      sendEmailWithTokenAuth: (resourceName, actionType) =>
        `/external/email/${resourceName}/${actionType}`,
      sendTrainerEmailToLiveSessionAdmin: () =>
        `/external/session/attendance/message`,
      bookTrainer: () => `${this.serviceBase}/trainer/inclass_session_booking`,
      bookRoom: () => `${this.serviceBase}/room/inclass_session_booking`,
      previewBookTrainer: () =>
        `${this.serviceBase}/trainer/inclass_session_booking`,
      previewBookRoom: () => `${this.serviceBase}/room/inclass_session_booking`,
      list: () => `${this.serviceBase}/`,
      render: () => `${this.serviceBase}/render`,
    };
  }

  fetchAllTemplates = async () => {
    const templates = await this.api.get<null, EmailTemplate[]>(
      this.paths.fetchAllTemplates()
    );
    return templates.map((v) => ({
      ...v,
      translations: Object.keys(v.translations).reduce((acc, lang) => {
        const parsedTranslations = Object.keys(v.translations[lang]).reduce(
          (langObject, key) => ({
            ...langObject,
            [key]: decodeHtml(v.translations[lang][key]),
          }),
          {}
        );
        return { ...acc, [lang]: parsedTranslations };
      }, {}),
    }));
  };

  getEmailTemplatePreview = ({ templateData, language }) =>
    this.api.post<null, string>(this.paths.getEmailTemplatePreview(), {
      template_data: snakelize(templateData),
      language,
    });

  createEmailTemplate = (templateData) =>
    this.api.post(this.paths.createEmailTemplate(), {
      template_data: snakelize(templateData),
    });

  editEmailTemplate = (templateData) =>
    this.api.patch(
      this.paths.editEmailTemplate(templateData.id),
      snakelize(templateData)
    );

  deleteEmailTemplate = (id) =>
    this.api.delete(this.paths.deleteEmailTemplate(id));

  sendEmail = (props) => {
    const {
      options = {},
      resourceName,
      actionType,
      payload = {},
      getPreview = false,
      index = 0,
    } = props;

    let data;
    const headers = {};

    if (payload.mergeFile) {
      data = new FormData();
      const snakelizedPayload = snakelize(payload);
      data.append('file', payload.mergeFile, payload.mergeFile.name);
      Object.keys(snakelizedPayload).forEach((key) => {
        if (key === 'mergeFile') return;
        data.append(key, JSON.stringify(snakelizedPayload[key]));
      });
      data.append(
        'options',
        JSON.stringify({
          ...snakelize(options),
          get_preview: getPreview,
          index,
        })
      );

      headers['Content-Type'] = 'multipart/form-data';
    } else {
      data = {
        ...snakelize(payload),
        options: { ...snakelize(options), get_preview: getPreview, index },
      };
      headers['Content-Type'] = 'application/json';

    }

    return this.api.post<
      null,
      string | { emailPreview: string; recipientsCount: number }
    >(this.paths.sendEmail(resourceName, actionType), data, { headers });
  };

  render = (props) => {
    return this.api.patch<null, string>(this.paths.render(), {
      ...snakelize(props),
    });
  };

  sendEmailWithTokenAuth = (props) => {
    const {
      options = {},
      token,
      resourceName,
      actionType,
      payload = {},
      getPreview = false,
    } = props;

    return this.api.post<
      null,
      string | { emailPreview: string; recipientsCount: number }
    >(this.paths.sendEmailWithTokenAuth(resourceName, actionType), {
      ...snakelize(payload),
      token,
      options: { ...snakelize(options), get_preview: getPreview },
    });
  };

  sendTrainerEmailToLiveSessionAdmin = ({ token, subject, message }) => {
    return this.api.post(this.paths.sendTrainerEmailToLiveSessionAdmin(), {
      token,
      subject,
      message,
    });
  };

  bookTrainer = (id) =>
    this.api.post(this.paths.bookTrainer(), {
      session_id: id,
    });

  bookRoom = (id) =>
    this.api.post(this.paths.bookRoom(), {
      session_id: id,
    });

  previewBookTrainer = (id) =>
    this.api.post(this.paths.previewBookTrainer(), {
      session_id: id,
      options: { get_preview: true },
    });

  previewBookRoom = (id) =>
    this.api.post(this.paths.previewBookRoom(), {
      session_id: id,
      options: { get_preview: true },
    });

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), {
      params: { query },
    });
}
