import { useAbility } from '@casl/react';
import React, { useMemo } from 'react';

import { FieldType } from '@nl-lms/common/shared';
import { Learner } from '@nl-lms/sdk/backend';
import {
  Button,
  ErrorPlaceholder,
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  TableAction,
  TableRowAction,
  TableSelectionMode,
  TableWithFullPagination,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { AbilityContext } from '../../../Can';
import { useShowEntityEditSideModalForm } from '../../../_common/hooks';
import {
  adminApi,
  useGetResourceForTableRowAction,
} from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import {
  fetchAndMapLearnerGroups,
  fetchAndMapLearners,
} from '../../_common/utils/fetchEntitiesForSelectMethods';
import { getLearnerPropertiesFilterFields } from '../../_common/utils/getLearnerPropertiesFilterFields';
import { AdminLearnerEditFormSideModal } from './AdminLearnerEditSideModalForm';

const { useListLearnersQuery, useRemoveLearnersMutation } = adminApi;

export const AdminLearnerListTable = ({
  tableActions: _tableActions = undefined,
  rowActions: _rowActions = undefined,
  selectionMode = 'checkbox',
}: {
  rowActions?: TableRowAction<Learner>[];
  tableActions?: TableAction<Learner>[];
  selectionMode?: TableSelectionMode;
}) => {
  const { sorting, onChangePagination, onChangeSorting, filters, query } =
    useListViewContext();
  const { data, error, refetch, isLoading } = useListLearnersQuery(query);
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  const [columns, onChangeColumns] = useListViewTableColumns(
    getAdminLearnerListTableColumns()
  );
  const [removeLearners] = useRemoveLearnersMutation();
  const { get, prefetch } = useGetResourceForTableRowAction('getLearner');
  const onEditRow = useShowEntityEditSideModalForm({
    ModalComponent: AdminLearnerEditFormSideModal,
    entityName: 'learner',
    buildInitialEntity: get,
  });
  const onRemoveRow = useAction((row) => removeLearners([row.id]), {
    showConfirmation: true,
    confirmationMessage:
      'Are you sure you want to remove the selected learners?',
  });
  const onClickCreateLearner = useShowModal(AdminLearnerEditFormSideModal);

  const baseRowActions = [
    ...getActionAbility('edit', 'learner', {
      name: 'Edit',
      handler: onEditRow,
      onMouseEnter: prefetch,
      Icon: Icon.EditIcon,
    }),
    ...getActionAbility('delete', 'learner', {
      name: 'Delete',
      handler: onRemoveRow,
      Icon: Icon.DeleteIcon,
    }),
  ];
  const rowActions = useMemo(() => {
    if (_rowActions !== undefined) return _rowActions;
    return baseRowActions;
  }, [_rowActions]);

  const tableActions = useMemo(() => {
    if (_tableActions !== undefined) return _tableActions;
    return [];
  }, [_tableActions]);
  const ability = useAbility(AbilityContext);
  return (
    <TableWithFullPagination
      columns={columns}
      onChangeColumns={onChangeColumns}
      data={rows}
      isLoading={isLoading}
      onChangeSorting={onChangeSorting}
      onChangePagination={onChangePagination}
      rowActions={rowActions}
      tableActions={tableActions}
      sorting={sorting}
      pagination={pagination}
      selectionMode={selectionMode}
    >
      {error ? (
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          <ErrorPlaceholder.Title />
          <ErrorPlaceholder.Description>
            Something went wrong during the fetching process. Try to refresh the
            table data or if the problem persists please get in contact with us
            using the app help channel
          </ErrorPlaceholder.Description>
          <Button label="Reload" onClick={refetch} />
        </ErrorPlaceholder>
      ) : (
        <NoDataPlaceholder
          iconName={filters ? 'UserIcon' : 'UserPlusIcon'}
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.success
          }
          title={
            filters
              ? 'There are no learners that match your search'
              : 'There are no learners created'
          }
          subtitle={
            filters
              ? 'You can try changing the active filters'
              : 'You can start adding new learners right now'
          }
          // @ts-ignore
          actionName={filters ? null : 'create learner'}
          actionHandler={onClickCreateLearner}
          allowedAction={ability.can('create', 'learner')}
        />
      )}
    </TableWithFullPagination>
  );
};

const getAdminLearnerListTableColumns = () => [
  {
    Header: 'Name',
    accessor: 'firstName',
    sortField: 'learnerName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link to={routes.admin.learners.item.path.full(row?.original.id)}>
              {row.original.firstName} {row.original.lastName}
            </Link>
          ) : (
            <span>
              {row.original.firstName} {row.original.lastName}
            </span>
          )}
        </Sensitive>
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortField: 'email',
    Cell: ({ row }) => <Sensitive>{row.original.email}</Sensitive>,
  },
  {
    Header: 'Internal ID',
    accessor: 'customerInternalId',
    sortField: 'customerInternalId',
    Cell: ({ row }) => <Sensitive>{row.original.customerInternalId}</Sensitive>,
  },
  {
    Header: 'Is Manager',
    accessor: 'isManager',
    sortField: 'isManager',
    Cell: ({ row }) => (row.original.isManager ? 'Yes' : 'No'),
  },
  {
    Header: 'Is Active',
    accessor: 'isActive',
    sortField: 'isActive',
    Cell: ({ row }) => (!row.original.deletedAt ? 'Yes' : 'No'),
  },
  ...C.LEARNER_PROPERTIES.map((prop) => ({
    Header: prop.fieldName,
    accessor: `details.${prop.name}`,
    sortField: `sort.details.${prop.name}`,
    Cell: ({ row }) => {
      let value = row?.original?.details
        ? row?.original?.details[prop.name]
        : '';
      if (typeof value === 'boolean') value = value ? 'Yes' : 'No';
      if (!value) value = '';
      return <Sensitive>{value}</Sensitive>;
    },
  })),
  {
    Header: 'Created At',
    Cell: ({ row }) => <PrettyDate value={row.original.createdAt} withTime />,
    sortField: 'createdAt',
    accessor: 'createdAt',
  },
  {
    Header: 'Updated At',
    Cell: ({ row }) => <PrettyDate value={row.original.updatedAt} withTime />,
    sortField: 'updatedAt',
    accessor: 'updatedAt',
  },
  {
    Header: 'Deleted At',
    Cell: ({ row }) => <PrettyDate value={row.original.deletedAt} withTime />,
    sortField: 'deletedAt',
    accessor: 'deletedAt',
  },
];

export const AdminLearnerListTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();

  return (
    <FilterBar
      id="learner-list-table"
      fields={[
        {
          name: 'first_name',
          label: 'First Name',
          type: FieldType.string,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'last_name',
          label: 'Last Name',
          type: FieldType.string,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'email',
          label: 'Email',
          type: FieldType.string,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'is_manager',
          label: 'Is Manager',
          type: FieldType.boolean,
        },
        {
          name: 'customer_internal_id',
          label: 'Internal Id',
          type: FieldType.string,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'manager_id',
          label: 'Learner Manager',
          type: FieldType.select,
          loadOptions: fetchAndMapLearners,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'learner_group_id',
          label: 'Learner Group',
          type: FieldType.select,
          loadOptions: fetchAndMapLearnerGroups,
        },
        {
          name: 'is_active',
          label: 'Active',
          type: FieldType.boolean,
        },
        {
          name: 'deleted_at',
          label: 'Deleted At',
          type: FieldType.date,
        },
        {
          name: 'updated_at',
          label: 'Updated At',
          type: FieldType.date,
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
        {
          name: 'search',
          label: 'Search',
          type: FieldType.string,
        },
        ...getLearnerPropertiesFilterFields(),
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};
