import React, { createContext } from 'react';

import { Tracker } from './tracker';

// @ts-ignore
export const TrackingContext = createContext<{ tracker: Tracker }>(undefined);

export const TrackingProvider = ({ tracker, children }) => {
  return (
    <TrackingContext.Provider value={{ tracker }}>
      {children}
    </TrackingContext.Provider>
  );
};
