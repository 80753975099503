import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Badge,
  BadgeGroup,
  BadgeTypes,
  Icon,
  InlineDotList,
  PrettyDate,
  StatusTag,
  TableWithoutRowSelectionMenu,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useGetUrlQuery } from '@nl-lms/ui/hooks';

import { CardIconCaption, LearningBanner } from '../../../_common/components';
import { useQueryErrorHandler } from '../../../_common/hooks';
import { authStore } from '../../../_common/modules/Auth/auth';
import {
  contentLibraryItemsApi,
  learnerApi,
  useApi,
} from '../../../_common/services/api';
import { ElearningPlayerButton } from '../../_common/components/ElearningPlayerButton';
import LearnerAppMainLayout from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import {
  CardBodySectionAlignment,
  LearnerLearningUnitPageLayout,
} from '../../_common/components/LearnerLearningUnitPageLayout/LearnerLearningUnitPageLayout';
import { LearnerLibraryListCard } from '../../_common/components/LearnerLibraryListCard/LearnerLibraryListCard';
import { learnerLibraryByCompetenciesFilter } from '../../modules/LearnerLibraryList/LearnerLibraryFilters';

const selectedSessionsStatusMap = {
  [C.I_ELEARNING_SESSION_STATUSES.NOT_STARTED]: 7,
  [C.I_ELEARNING_SESSION_STATUSES.IN_PROGRESS]: 6,
  [C.I_ELEARNING_SESSION_STATUSES.COMPLETED_FAILED]: 5,
  [C.I_ELEARNING_SESSION_STATUSES.FAILED]: 4,
};

const { useLearnerElearningCourseQuery } = learnerApi;
const { useLazyListContentLibraryItemsQuery } = contentLibraryItemsApi;

export const LearnerElearningCourseViewPage = () => {
  const [selectedSession, setSelectedSession] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [assignedActiveSessions, setAssignedActiveSessions] = useState([]);
  const [regularSessions, setRegularSessions] = useState([]);
  const [regularInProgressSessions, setRegularInProgressSessions] = useState(
    [],
  );

  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const query = useGetUrlQuery() || {};

  useEffect(() => {
    setSelectedSession(null);
    setInitialDataLoaded(false);
    setAssignedActiveSessions([]);
    setRegularSessions([]);
    setRegularInProgressSessions([]);
  }, [id]);

  const { t } = useTranslation('learner');

  const onSelectRow = useCallback(
    (row) => {
      const hasInProgressSessions = !!regularInProgressSessions.length;
      if (typeof row === 'boolean') {
        if (row) return;
        else setSelectedSession(null);
        // @ts-ignore
      } else if (row?.id === selectedSession?.id && !hasInProgressSessions) {
        setSelectedSession(null);
      } else if (row?.passedDueDate) {
        setSelectedSession(null);
      } else {
        setSelectedSession(row);
      }
    },
    [selectedSession, regularInProgressSessions],
  );

  const fetchArguments = useMemo(
    () => ({
      id,
      learnerId: authStore.learnerId,
    }),
    [id],
  );

  const { data, isLoading, error, isError } = useLearnerElearningCourseQuery(
    // @ts-ignore
    fetchArguments,
    {
      refetchOnFocus: true,
    },
  );
  useQueryErrorHandler({ error });

  const getSession = useCallback(
    async (sessionId) => {
      const { data: session } = await api.learnerApp.getElearningSession({
        // @ts-ignore
        learnerId: authStore.learnerId,
        id: sessionId,
      });
      setSelectedSession(session);
    },
    [api, setSelectedSession, fetchArguments],
  );

  const [listContentLibraryItems, { data: contentLibraryItemsData }] =
    useLazyListContentLibraryItemsQuery();

  useEffect(() => {
    if (!data?.elearningSessions) return;

    let _activeSessions = [];
    const _regularSessions = [];
    (data?.elearningSessions || []).forEach((l) => {
      if (
        l.learningAssignmentInstanceId &&
        l.status !== C.I_ELEARNING_SESSION_STATUSES.COMPLETED &&
        l.status !== C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED &&
        l.status !== C.I_ELEARNING_SESSION_STATUSES.CANCELED &&
        !l.passedDueDate
      ) {
        // @ts-ignore
        _activeSessions.push(l);
      } else {
        // @ts-ignore
        _regularSessions.push(l);
      }
    });

    // @ts-ignore
    _activeSessions = _.sortBy(
      _activeSessions.map((es) => {
        return {
          // @ts-ignore
          ...es,
          // @ts-ignore
          i: selectedSessionsStatusMap[es.status],
          // @ts-ignore
          m: es.mandatory ? 1 : 0,
        };
      }),
      ['m', 'i', 'due_date'],
      ['desc', 'desc', 'desc'],
    );

    const _regularInProgressSessions = _regularSessions
      // @ts-ignore
      .filter((s) => s.status === C.I_ELEARNING_SESSION_STATUSES.IN_PROGRESS)
      .sort(
        (a, b) =>
          // @ts-ignore
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    setAssignedActiveSessions(_activeSessions);
    setRegularSessions(_regularSessions);
    setRegularInProgressSessions(_regularInProgressSessions);

    let elearningSession;
    if (query.instanceId) {
      elearningSession = (data?.elearningSessions || []).find(
        (a) => a.id === query.instanceId,
      );
      if (!elearningSession) {
        getSession(query.instanceId);
      }
    } else if (_activeSessions && _activeSessions.length) {
      elearningSession = _activeSessions[0];
    } else if (
      _regularInProgressSessions &&
      _regularInProgressSessions.length
    ) {
      elearningSession = _regularInProgressSessions[0];
    }

    if (!initialDataLoaded) setInitialDataLoaded(true);
    if (elearningSession) setSelectedSession(elearningSession);

    // Fetch content library items based on competencies
    if (data?.elearningCourse?.competencyIds?.length) {
      listContentLibraryItems({
        query: {
          query: transformTsRestQuery({
            filters: learnerLibraryByCompetenciesFilter(
              data.elearningCourse.competencyIds,
            ),
            pagination: {
              disabled: true,
            },
          }),
        },
      });
    }
  }, [data]);

  const activeAssignedSelectedSessions = useMemo(() => {
    // @ts-ignore
    if (!selectedSession?.id) {
      return assignedActiveSessions && assignedActiveSessions[0]
        ? [assignedActiveSessions[0]]
        : [];
    } else {
      // @ts-ignore
      const s = assignedActiveSessions.find((s) => selectedSession.id === s.id);
      return s ? [s] : [];
    }
  }, [assignedActiveSessions, selectedSession]);

  const regularSelectedSessions = useMemo(() => {
    // @ts-ignore
    if (selectedSession?.id) {
      // @ts-ignore
      const s = regularSessions.find((s) => selectedSession.id === s.id);
      return s ? [s] : [];
    }

    if (assignedActiveSessions && assignedActiveSessions.length) {
      return [];
    }

    const [latestInProgressSession] = regularInProgressSessions;
    return latestInProgressSession ? [latestInProgressSession] : [];
  }, [
    assignedActiveSessions,
    assignedActiveSessions,
    regularSessions,
    regularInProgressSessions,
    selectedSession,
  ]);

  const canSelectRegularSession = useMemo(() => {
    if (!assignedActiveSessions || !assignedActiveSessions.length) return true;
  }, [assignedActiveSessions]);

  const typeCaption = useMemo(() => {
    if (!data?.elearningCourse) {
      return t('common.learningunit.elearning');
    } else {
      if (
        data?.elearningCourse?.contentFileVersion !==
          C.I_CONTENT_FILE_VERSIONS.SCORM2004 &&
        data?.elearningCourse?.contentFileVersion !==
          C.I_CONTENT_FILE_VERSIONS.SCORM12 &&
        // @ts-ignore
        C.CONTENT_FILE_VERSIONS[data?.elearningCourse?.contentFileVersion]
      ) {
        return C.CONTENT_FILE_VERSIONS[
          // @ts-ignore
          data?.elearningCourse?.contentFileVersion
        ];
      } else {
        return t('common.learningunit.elearning');
      }
    }
  }, [data]);

  return (
    <LearnerAppMainLayout>
      <LearnerLearningUnitPageLayout
        isLoading={!initialDataLoaded && isLoading}
      >
        <LearnerLearningUnitPageLayout.HeroImage
          imageUrl={`"${data?.elearningCourse?.thumbnail}"`}
        />

        {selectedSession &&
          ![
            C.I_ELEARNING_SESSION_STATUSES.COMPLETED,
            C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED,
            // @ts-ignore
          ].includes(selectedSession.status) && (
            <LearningBanner
              // @ts-ignore
              dueDate={selectedSession.dueDate}
              // @ts-ignore
              mandatory={selectedSession.mandatory}
              className={''}
            />
          )}

        <LearnerLearningUnitPageLayout.CardHeaderTitle>
          {data?.elearningCourse?.name}
        </LearnerLearningUnitPageLayout.CardHeaderTitle>

        <LearnerLearningUnitPageLayout.CardHeaderItem>
          <InlineDotList>
            <CardIconCaption
              icon={<Icon.MonitorIconSmall />}
              caption={typeCaption}
            />
          </InlineDotList>
        </LearnerLearningUnitPageLayout.CardHeaderItem>

        <LearnerLearningUnitPageLayout.CardBodySection>
          <LearnerLearningUnitPageLayout.CardBodySectionDescription>
            <div
              dangerouslySetInnerHTML={{
                // @ts-ignore
                __html: data?.elearningCourse?.description,
              }}
            ></div>
          </LearnerLearningUnitPageLayout.CardBodySectionDescription>
        </LearnerLearningUnitPageLayout.CardBodySection>

        <LearnerLearningUnitPageLayout.CardBodySection
          // @ts-ignore
          title={t('learningunit.competencies')}
        >
          {data?.elearningCourse?.competencies &&
          data?.elearningCourse?.competencies.length ? (
            <BadgeGroup type={BadgeTypes.LIGHT}>
              {data?.elearningCourse?.competencies.map((tag, index) => (
                <Badge
                  key={index}
                  label={tag.scope ? `${tag.scope}:${tag.title}` : tag.title}
                  type={BadgeTypes.LIGHT}
                />
              ))}
            </BadgeGroup>
          ) : null}
        </LearnerLearningUnitPageLayout.CardBodySection>

        <LearnerLearningUnitPageLayout.CardHistory>
          <>
            {data?.elearningSessions?.length ? (
              <>
                {!!assignedActiveSessions.length && (
                  <LearnerLearningUnitPageLayout.CardBodySection
                    // @ts-ignore
                    title={t('learningunit.activeassignments')}
                    align={CardBodySectionAlignment.LEFT}
                  >
                    <Typography.p>
                      {t('learningunit.activeassignmentsdescription')}
                    </Typography.p>
                    <br />
                    <TableWithoutRowSelectionMenu
                      columns={[
                        {
                          Header: t('common.table.startedon'),
                          accessor: 'startedAt',
                          Cell: ({ row }) => (
                            <PrettyDate
                              value={
                                row.original.startedAt || row.original.createdAt
                              }
                              withTime
                            />
                          ),
                        },
                        {
                          Header: t('common.table.status'),
                          accessor: 'status',
                          Cell: ({ row }) => (
                            <StatusTag
                              status={
                                C.ELEARNING_SESSION_STATUSES[
                                  row.original.status
                                ]
                              }
                            />
                          ),
                        },
                        {
                          Header: t('common.table.score'),
                          accessor: 'score',
                          Cell: ({ row }) =>
                            typeof row.original.score !== 'undefined' &&
                            row.original.score !== null
                              ? `${row.original.score}%`
                              : '-',
                        },
                        {
                          Header: t('common.table.mandatory'),
                          accessor: 'mandatory',
                          Cell: ({ row }) =>
                            row.original.mandatory
                              ? t('common.statustag.yes')
                              : t('common.statustag.no'),
                        },
                        {
                          Header: t('common.table.duedate'),
                          accessor: 'dueDate',
                          Cell: ({ row }) => (
                            <PrettyDate value={row.original.dueDate} />
                          ),
                        },
                      ]}
                      selectedRows={activeAssignedSelectedSessions}
                      isLoading={!initialDataLoaded && isLoading}
                      selectionMode={'none'}
                      data={assignedActiveSessions}
                    />
                  </LearnerLearningUnitPageLayout.CardBodySection>
                )}

                <LearnerLearningUnitPageLayout.CardBodySection
                  // @ts-ignore
                  title={t('learningunit.history')}
                  align={CardBodySectionAlignment.LEFT}
                >
                  <Typography.p>
                    {t('learningunit.historydescription', {
                      unit: t('learningunit.elearningcourse'),
                    })}
                  </Typography.p>
                  <br />
                  <TableWithoutRowSelectionMenu
                    columns={[
                      {
                        Header: t('common.table.startedon'),
                        accessor: 'startedAt',
                        Cell: ({ row }) => (
                          <PrettyDate
                            value={
                              row.original.startedAt || row.original.createdAt
                            }
                            withTime
                          />
                        ),
                      },
                      {
                        Header: t('common.table.status'),
                        accessor: 'status',
                        Cell: ({ row }) => (
                          <StatusTag
                            status={
                              C.ELEARNING_SESSION_STATUSES[row.original.status]
                            }
                          />
                        ),
                      },
                      {
                        Header: t('common.table.score'),
                        accessor: 'score',
                        Cell: ({ row }) =>
                          typeof row.original.score !== 'undefined' &&
                          row.original.score !== null
                            ? `${row.original.score}%`
                            : '-',
                      },
                      {
                        Header: t('common.table.mandatory'),
                        accessor: 'mandatory',
                        Cell: ({ row }) =>
                          row.original.mandatory
                            ? t('common.statustag.yes')
                            : t('common.statustag.no'),
                      },
                      {
                        Header: t('common.table.duedate'),
                        accessor: 'dueDate',
                        Cell: ({ row }) => (
                          <PrettyDate value={row.original.dueDate} />
                        ),
                      },
                    ]}
                    selectedRows={regularSelectedSessions}
                    isLoading={!initialDataLoaded && isLoading}
                    selectionMode={canSelectRegularSession ? 'radio' : 'none'}
                    // @ts-ignore
                    onSelectRow={canSelectRegularSession ? onSelectRow : null}
                    data={regularSessions}
                  />
                </LearnerLearningUnitPageLayout.CardBodySection>
              </>
            ) : null}
            {data && !data?.elearningCourse?.deletedAt ? (
              <LearnerLearningUnitPageLayout.CardBodySection>
                <LearnerLearningUnitPageLayout.CardActions>
                  <ElearningPlayerButton
                    elearningSession={selectedSession}
                    elearningCourse={data?.elearningCourse}
                  />
                </LearnerLearningUnitPageLayout.CardActions>
              </LearnerLearningUnitPageLayout.CardBodySection>
            ) : null}
          </>
        </LearnerLearningUnitPageLayout.CardHistory>
        {contentLibraryItemsData?.rows?.length && (
          <LearnerLearningUnitPageLayout.Section>
            <LearnerLearningUnitPageLayout.SectionTitle
              title={t('learningunit.recommendedLibraryContent')}
            />
            <LearnerLearningUnitPageLayout.SectionSubTitle
              text={`${contentLibraryItemsData?.rows.length} ${
                contentLibraryItemsData?.rows.length === 1
                  ? t('learningunit.libraryItem')
                  : t('learningunit.libraryItems')
              }`}
            />

            <LearnerLearningUnitPageLayout.SectionCarousel columns={4}>
              {contentLibraryItemsData?.rows.map((item, index) => (
                <LearnerLibraryListCard {...item} key={item.id} />
              ))}
            </LearnerLearningUnitPageLayout.SectionCarousel>
          </LearnerLearningUnitPageLayout.Section>
        )}
      </LearnerLearningUnitPageLayout>
    </LearnerAppMainLayout>
  );
};
