export const AssessmentRouterPrefix = '/assessment';

export const AssessmentScope = {
  portal: {
    viewAssessmentForm: {
      resource: 'portal_assessment_form',
      action: 'view',
    },
    viewAssessmentFormInstance: {
      resource: 'portal_assessment_form_instance',
      action: 'view',
    },
  },
} as const;
