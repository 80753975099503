import React, { Fragment } from 'react';

import './InlineDotList.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type InlineDotListProps = TidComponent<{
  className?: string;
  children: React.ReactNode;
}>;

export const InlineDotList = ({ children, ...props }: InlineDotListProps) => {
  const classNameProps = useClassNameProps('inline-dot-list', props);
  const commonProps = useTestProps(props);

  const parsedChildren = (
    Array.isArray(children) ? children : [children]
  ).filter((child) => child);

  return (
    <div {...classNameProps} {...commonProps}>
      {parsedChildren.map((child, index) => (
        <Fragment key={index}>
          {index !== 0 ? (
            <span className="inline-dot-list__dot">
              {' '}
              <svg
                width="3"
                height="3"
                viewBox="0 0 3 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.266 2.558V0.721H0.572V2.558H2.266Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          ) : (
            ''
          )}
          <span className="inline-dot-list__item">{child}</span>
        </Fragment>
      ))}
    </div>
  );
};
