import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { AutomationRouterPrefix } from './automation.contants.sdk';
import {
  ListActionsSchema,
  ListRuleSchema,
  ScheduleActionsPayloadSchema,
} from './automation.schema.sdk';

const contract = initContract();

export const AutomationRouterContract = contract.router({
  listActions: {
    method: 'GET',
    path: `${AutomationRouterPrefix}/action`,
    responses: ContractPaginatedResponse(ListActionsSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  scheduleActions: {
    method: 'POST',
    path: `${AutomationRouterPrefix}/action/schedule`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: ScheduleActionsPayloadSchema,
  },
  removeActions: {
    method: 'DELETE',
    path: `${AutomationRouterPrefix}/action`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  toggleRules: {
    method: 'PATCH',
    path: `${AutomationRouterPrefix}/rule/toggle`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  listRules: {
    method: 'GET',
    path: `${AutomationRouterPrefix}/rule`,
    responses: ContractPaginatedResponse(ListRuleSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
});
