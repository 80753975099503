import { _ } from '@nl-lms/vendor';

const snakeCaseTransform = (obj) => {
  let result = obj;
  if (_.isArray(obj)) {
    result = _.map(obj, (v) => snakeCaseTransform(v));
  } else if (_.isObject(obj)) {
    result = _.transform(
      // @ts-ignore
      obj,
      (res, val, key) => {
        // @ts-ignore
        res[_.snakeCase(key)] = snakeCaseTransform(val);
      },
      {}
    );
  }
  return result;
};

export const snakelize = (obj, deep = true) => {
  if (!deep) return _.mapKeys(obj, (val, key) => _.snakeCase(key));
  return snakeCaseTransform(obj);
};
