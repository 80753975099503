import { InternalAxiosRequestConfig } from 'axios';

import { transformQuery } from '@nl-lms/common/shared';

import { ApiClientOptions } from '../types';

export const queryTransformerInterceptor =
  (options: ApiClientOptions) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.params && config.params.query) {
      config.params.query = JSON.stringify({
        ...config.params.query,
        ...transformQuery(config.params.query),
      });
    }

    if (
      config.data &&
      config.data.query &&
      Object.keys(config.data || {}).length === 1
    ) {
      config.data.query = {
        ...config.data.query,
        ...transformQuery(config.data.query),
      };
    }

    return config;
  };
