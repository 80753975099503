import React from 'react';

import { Button, Card, Typography, useShowModal } from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';

import { authStore } from '../../../../_common/modules/Auth/auth';
import { routes } from '../../../../lib/routes';
import { AdminAssessmentEditFormSideModal } from '../../AdminAssessment/AdminAssessmentEditFormSideModal/AdminAssessmentEditFormSideModal';
import { LearningAssignmentCreateSideModal } from '../../learning-assignment/LearningAssignmentCreateSideModal';
import './AdminLearningAssignmentCard.scss';
import evaluatorImgSrc from './assessmentassignment.png';
import managerImgSrc from './learningassignment.png';

export const AdminLearningAssignmentCard = () => {
  const onClickAddLearningAssignment = useShowModal(
    LearningAssignmentCreateSideModal
  );
  const onClickCreateAssessment = useShowModal(
    AdminAssessmentEditFormSideModal
  );
  const goToLearningCatalogue = useRoutingAction({
    route: routes.admin.catalogue.path.full(),
  });
  const { hasManagerRole, hasEvaluatorRole } = authStore;
  const getCardElements = () => {
    if (hasManagerRole) {
      return {
        title: 'Learning',
        header: 'Assign learning to your team',
        primaryButtonText: 'assign learning',
        primaryButtonAction: onClickAddLearningAssignment,
        secondaryButtonText: 'view learning catalogue',
        secondaryButtonAction: goToLearningCatalogue,
        imageSrc: managerImgSrc,
      };
    } else if (hasEvaluatorRole) {
      return {
        title: 'Assessments',
        header: 'Assign assessments to learners',
        primaryButtonText: 'assign assessments',
        primaryButtonAction: onClickAddLearningAssignment,
        secondaryButtonText: 'create new assessment',
        secondaryButtonAction: onClickCreateAssessment,
        imageSrc: evaluatorImgSrc,
      };
    }
    return {};
  };
  const cardElements = getCardElements();
  return (
    <Card className="admin-learning-assignment-card">
      <Card.Header withSeparator={false}>
        <div className="admin-learning-assignment-card__title">
          <Typography.h3>{cardElements.title || ''}</Typography.h3>
        </div>
        <div className="admin-learning-assignment-card__subtitle">
          <Typography.h2>{cardElements.header || ''}</Typography.h2>
        </div>
      </Card.Header>
      <div className="admin-learning-assignment-card__actions">
        <Button
          label={cardElements.primaryButtonText || ''}
          regular
          className="admin-learning-assignment-card__action"
          // @ts-ignore
          onClick={cardElements.primaryButtonAction || null}
        />
        <Button
          label={cardElements.secondaryButtonText || ''}
          className="admin-learning-assignment-card__action"
          // @ts-ignore
          onClick={cardElements.secondaryButtonAction || null}
        />
      </div>
      <div className="admin-learning-assignment-card__image">
        <img src={cardElements.imageSrc || '/'} />
      </div>
    </Card>
  );
};
