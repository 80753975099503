import {
  EntityLoadCalendarDayEventWithGridPosition,
  EntityLoadCalendarEventGroup,
} from './EntityLoadCalendar.types';

type EntityLoadCalendarEventGroupWithGridPosition = {
  title: string;
  entityObject: any;
  entityUrl: string;
  events: EntityLoadCalendarDayEventWithGridPosition[];
};

export const formatDate = (date: Date) => date?.toLocaleDateString('en-us');
export const formatDateToHour = (date: Date) => {
  return date?.getHours() + Math.round(date?.getMinutes() / 31);
};

export const parseGroupEvents = (
  groups: EntityLoadCalendarEventGroup[],
  dates: Date[],
  hours: number[],
  hourStep: number
): EntityLoadCalendarEventGroupWithGridPosition[] => {
  const formattedDates = dates.map((d) => formatDate(d));
  if (dates.length < 2) return [];
  // const startDate = dates[0];
  // const endDate = dates[dates.length - 1];
  // @ts-ignore
  const groupedEventsWithGridPosition: EntityLoadCalendarEventGroupWithGridPosition[] =
    groups.map((group) => {
      let parsedGroupEvents = group?.events
        ?.map((ev) => {
          const startDateString = formatDate(new Date(ev.startDate));
          const endDateString = formatDate(new Date(ev.endDate));

          let dayStartIndex = formattedDates.indexOf(startDateString);
          let dayEndIndex = formattedDates.indexOf(endDateString);

          // let eventStartDate = ev.startDate;
          // let eventEndDate = ev.endDate;

          const startDateHourString = formatDateToHour(new Date(ev.startDate));
          const endDateHourString = formatDateToHour(new Date(ev.endDate));

          let startHourOffset = null;
          let endHourOffset = null;

          hours.map((hour, index) => {
            let hourStepCount = hourStep - 1;
            while (hourStepCount >= 0) {
              if (hour + hourStepCount === startDateHourString) {
                // @ts-ignore
                startHourOffset = index * hourStep + hourStepCount;
              }
              if (hour + hourStepCount === endDateHourString) {
                // @ts-ignore
                endHourOffset = index * hourStep + hourStepCount;
              }
              hourStepCount -= 1;
            }
          });

          if (dayStartIndex === -1 && dayEndIndex > -1) {
            dayStartIndex = 0;
            // eventStartDate = startDate;
            // @ts-ignore
            startHourOffset = 0;
          }
          if (dayStartIndex > -1 && dayEndIndex === -1) {
            dayEndIndex = formattedDates.length - 1;
            // eventEndDate = endDate;
            // @ts-ignore
            endHourOffset = hours?.length * hourStep;
          }
          if (dayStartIndex === -1 || dayEndIndex === -1) return null;
          if (dayStartIndex > dayEndIndex) return null;

          if (!startHourOffset) {
            if (startDateHourString > hours[hours?.length - 1]) {
              return null;
            } else if (startDateHourString < hours[0]) {
              // @ts-ignore
              startHourOffset = 0;
            }
          }

          if (!endHourOffset) {
            if (endDateHourString < hours[0]) {
              return null;
            } else if (endDateHourString > hours[hours?.length - 1]) {
              // @ts-ignore
              endHourOffset = hours?.length * hourStep;
            }
          }

          const dayStartIndexPos =
            // @ts-ignore
            dayStartIndex * hours?.length * hourStep + startHourOffset;
          const dayEndIndexPos =
            // @ts-ignore
            dayEndIndex * hours?.length * hourStep + endHourOffset;

          const parsedEvent = {
            ...ev,
            indexStart: dayStartIndexPos,
            indexEnd: dayEndIndexPos,
            // startDate: eventStartDate,
            // endDate: eventEndDate,
          };

          return parsedEvent;
        })
        .filter((ev) => !!ev);
      return {
        ...group,
        events: parsedGroupEvents,
      };
    });
  return groupedEventsWithGridPosition;
};
