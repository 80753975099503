import { useAbility } from '@casl/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ApiQueryFilter,
  AppQueryFilter,
  FieldMetadata,
  FieldType,
  QueryFilter,
} from '@nl-lms/common/shared';
import {
  LearningAssignmentInitialNotificationTemplateName,
  LearningAssignmentInstanceNotificationAudienceName,
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
} from '@nl-lms/feature/learning-assignments/sdk';
import { SampleReceiversQuery } from '@nl-lms/feature/notifications/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  FloatingMenu,
  Link,
  Modal,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableColumn,
  TableWithFullPagination,
  Typography,
  TypographyProps,
  useShowModal,
} from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { AbilityContext } from '../../../Can';
import { useTableAction } from '../../../_common/hooks';
import { authStore } from '../../../_common/modules/Auth/auth';
import { learningAssignmentsApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import {
  fetchAndMapAssessmentForms,
  fetchAndMapAssignments,
  fetchAndMapAvailableElearningCourses,
  fetchAndMapLearnerGroups,
  fetchAndMapLearners,
  fetchAndMapLearningPaths,
  mapAndLoadEnumsForSelect,
} from '../../_common/utils/fetchEntitiesForSelectMethods';
import { useTriggerNotificationSendSideModal } from '../notifications/NotificationSendModal';
import { NotificationTemplateContext } from '../notifications/NotificationTemplateProvider';
import { LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal } from './LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal';

const {
  useListLearningAssignmentInstancesQuery,
  useCancelLearningAssignmentInstancesMutation,
  useRemoveLearningAssignmentInstancesMutation,
  useResetLearningAssignmentInstancesMutation,
  useUpdateLearningAssignmentInstancesMutation,
} = learningAssignmentsApi;

export const LearningAssignmentListInstancesTable = ({
  availableColumns = [
    'learner.name',
    'content.name',
    'content.type',
    'status',
    'mandatory',
    'createdAt',
    'dueDate',
  ],
}: {
  availableColumns?: (
    | 'learner.name'
    | 'learner.email'
    | 'learningAssignment.name'
    | 'content.name'
    | 'content.type'
    | 'status'
    | 'mandatory'
    | 'createdAt'
    | 'dueDate'
    | 'updatedAt'
  )[];
}) => {
  // TODO: Add ability
  const ability = useAbility(AbilityContext);

  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined,
  );
  const { data, isLoading } = useListLearningAssignmentInstancesQuery(
    {
      query: { query: transformTsRestQuery(query) },
    },
    {
      pollingInterval,
    },
  );
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  const [resetInstances] = useResetLearningAssignmentInstancesMutation();
  const [removeInstances] = useRemoveLearningAssignmentInstancesMutation();
  const [cancelInstances] = useCancelLearningAssignmentInstancesMutation();
  const [updateLearningAssignmentInstances] =
    useUpdateLearningAssignmentInstancesMutation();
  const showModal = useShowModal(
    LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal,
  );
  useEffect(() => {
    if (
      data &&
      data.rows.some(
        (row) => row.status === LearningAssignmentInstanceStatus.CREATING,
      )
    ) {
      setPollingInterval(2000);
    } else {
      setPollingInterval(undefined);
    }
  }, [data]);
  const [columns, onChangeColumns] = useListViewTableColumns(
    AdminLearningAssignmentLearningSessionListTableColumns,
    availableColumns,
  );

  const setAsMandatory = useCallback((selection) => {
    return updateLearningAssignmentInstances({
      ...selection,
      mandatory: true,
    });
  }, []);
  const setAsNotMandatory = useCallback((selection) => {
    return updateLearningAssignmentInstances({
      ...selection,
      mandatory: false,
    });
  }, []);
  const { templatesByName } = useContext(NotificationTemplateContext);
  const showSendNotificationModal = useTriggerNotificationSendSideModal();
  const onSendNotificationModal = useCallback(
    async ({ list }: { list: string[] | ApiQueryFilter }) => {
      const receivers: SampleReceiversQuery = {
        name: LearningAssignmentInstanceNotificationAudienceName,
        filters: list,
      };

      await showSendNotificationModal(
        receivers,
        _.cloneDeep(
          templatesByName[LearningAssignmentInitialNotificationTemplateName]
            .content,
        ),
        MentionOptions,
      );
    },
    [],
  );
  const onClickSendNotification = useTableAction(onSendNotificationModal, {
    withRowCountProp: true,
    baseFilters: query.filters,
    showConfirmation: false,
    showNotification: false,
  });
  const onSetAsMandatory = useTableAction(setAsMandatory, {
    withRowCountProp: true,
    baseFilters: query.filters,
    confirmationMessage:
      'Are you sure that you want to set the selected rows as mandatory?',
  });
  const onSetAsNotMandatory = useTableAction(setAsNotMandatory, {
    withRowCountProp: true,
    baseFilters: query.filters,
    confirmationMessage:
      'Are you sure that you want to set the selected rows as not mandatory?',
  });
  const onResetInstances = useTableAction(resetInstances, {
    withRowCountProp: true,
    confirmationMessage:
      'Are you sure that you want to reset the selected instances. This will create new learning sessions and link them to the selected assignment instances. The currently linked learning sessions will not be deleted and the assignment instances statuses will change to Not Started or Not Registered, depending on the content type.',
    baseFilters: query.filters,
  });
  const onCancelInstances = useTableAction(cancelInstances, {
    withRowCountProp: true,
    confirmationMessage:
      'The status of the selected instances will be set to Canceled. Are you sure that you want to perform this action?',
    baseFilters: query.filters,
  });
  const removeWithContent = useCallback(
    async (params) => {
      return removeInstances({ ...params, removeContent: true });
    },
    [query],
  );
  const removeWithoutContent = useCallback(
    async (params) => {
      return removeInstances({ ...params, removeContent: false });
    },
    [query],
  );
  const onRemoveRowWithContent = useTableAction(removeWithContent, {
    withRowCountProp: true,
    baseFilters: query.filters,
    successMessage: getRemoveRowsSuccessMessage,
    confirmationMessage:
      'Are you sure that you want to remove the selected instances. This will remove the selected instances and the learning sessions (elearning sessions, learning paths, assessment form instances).',
  });
  const onRemoveRowWithoutContent = useTableAction(removeWithoutContent, {
    withRowCountProp: true,
    baseFilters: query.filters,
    successMessage: getRemoveRowsSuccessMessage,
    confirmationMessage:
      'Are you sure that you want to remove the selected instances. This will remove the selected instances but the actual learning sessions will be remain in the application.',
  });
  const onClickUpdateDueDate = useTableAction(
    (selection) => showModal({ editAssignment: false, selection }),
    {
      baseFilters: query.filters,
      withRowCountProp: true,
      showConfirmation: false,
      showNotification: false,
    },
  );

  return (
    <>
      <TableWithFullPagination
        data={rows}
        isLoading={isLoading}
        columns={columns}
        sorting={sorting}
        tableActions={[
          {
            name: 'Update',
            options: [
              {
                name: 'Change Due Date',
                handler: onClickUpdateDueDate,
              },
              { name: 'Set As Mandatory', handler: onSetAsMandatory },
              { name: 'Set As Not Mandatory', handler: onSetAsNotMandatory },
            ],
          },
          {
            name: 'Notify',
            handler: onClickSendNotification,
          },
          { name: 'Cancel', handler: onCancelInstances },
          { name: 'Reset', handler: onResetInstances },
          {
            name: 'Delete',
            options: [
              {
                name: 'With Learning Sessions',
                handler: onRemoveRowWithContent,
              },
              {
                name: 'Delete Without Learning Sessions',
                handler: onRemoveRowWithoutContent,
              },
            ],
          },
        ]}
        pagination={pagination}
        selectionMode="checkbox"
        onChangePagination={onChangePagination}
        onChangeSorting={onChangeSorting}
        onChangeColumns={onChangeColumns}
      >
        <NoDataPlaceholder.Container>
          <NoDataPlaceholder.Icon
            name={filters ? 'FileIcon' : 'FileTextIcon'}
            size="large"
            color={
              filters
                ? NoDataPlaceholderColor.warning
                : NoDataPlaceholderColor.default
            }
          />
          <NoDataPlaceholder.Title>
            {filters
              ? 'There are no learning sessions that match your search'
              : 'There are no learning sessions created'}
          </NoDataPlaceholder.Title>
          <NoDataPlaceholder.Subtitle>
            {filters
              ? 'You can try changing the active filters'
              : 'You can assign learning sessions from your existing audience by pressing the button below. '}
          </NoDataPlaceholder.Subtitle>
        </NoDataPlaceholder.Container>
      </TableWithFullPagination>
    </>
  );
};

const getRemoveRowsSuccessMessage = (result) => {
  if (result?.data?.jobId) {
    return 'You are deleting a large amount of data. The rows were scheduled for removal and the process will take a couple of minutes to finalize.';
  }
  return 'Rows were removed successfully';
};
export const AdminLearningAssignmentInstanceListTableFilters = ({
  fieldNames = [
    'learner_id',
    'learning_assignment_id',
    'name',
    'status',
    'start_date',
    'email',
    'mandatory',
    'due_date',
    'depth',
    'type',
  ],
  showGroupByOptions = false,
}: {
  fieldNames?: (typeof AdminLearningAssignmentInstanceListTableFiltersFields)[number]['name'][];
  showGroupByOptions?: boolean;
}) => {
  const { filters, onChangeFilters } = useListViewContext();

  const fields = useMemo(
    () =>
      AdminLearningAssignmentInstanceListTableFiltersFields.filter((f) =>
        fieldNames.includes(f.name),
      ) as FieldMetadata[],
    [fieldNames],
  );

  return (
    <FilterBar
      id="learning-assignment-view"
      fields={fields.filter((f) => {
        if (f.name === 'depth') return authStore.isOnlyManager;
        return true;
      })}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const AdminLearningAssignmentInstanceListTableFiltersFields = [
  {
    name: 'learner_id',
    label: 'Learner',
    type: FieldType.select,
    loadOptions: fetchAndMapLearners,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'email',
    label: 'Learner Email',
    type: FieldType.string,
  },

  {
    name: 'depth',
    label: 'Learner Level',
    type: FieldType.number,
  },
  {
    name: 'learning_assignment_id',
    label: 'Assignment',
    type: FieldType.select,
    loadOptions: fetchAndMapAssignments,
  },
  {
    name: 'name',
    label: 'Assignment Name',
    type: FieldType.string,
  },
  {
    name: 'learning_item_id',
    label: 'Elearning Course',
    type: FieldType.select,
    loadOptions: fetchAndMapAvailableElearningCourses,
  },
  {
    name: 'learning_item_id',
    label: 'Assessment Form',
    type: FieldType.select,
    loadOptions: fetchAndMapAssessmentForms,
  },
  {
    name: 'learning_item_id',
    label: 'Learning Path',
    type: FieldType.select,
    loadOptions: fetchAndMapLearningPaths,
  },
  {
    name: 'type',
    label: 'Type',
    type: FieldType.select,
    loadOptions: mapAndLoadEnumsForSelect(
      LearningAssignmentLearningContentType,
    ),
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.select,
    loadOptions: mapAndLoadEnumsForSelect(LearningAssignmentInstanceStatus),
  },
  {
    name: 'mandatory',
    label: 'Mandatory',
    type: FieldType.boolean,
  },
  {
    name: 'start_date',
    label: 'Start Date',
    type: FieldType.datetime,
  },
  {
    name: 'due_date',
    label: 'Due Date',
    type: FieldType.datetime,
  },
  {
    name: 'learner_group_ids',
    label: 'Learner Group',
    type: FieldType.select,
    loadOptions: fetchAndMapLearnerGroups,
  },
];

const AdminLearningAssignmentInstanceListTableLabel = ({
  variant = 'h3',
}: {
  variant?: TypographyProps['variant'];
}) => {
  const { query } = useListViewContext();
  const { data, isLoading } = useListLearningAssignmentInstancesQuery({
    query: { query: transformTsRestQuery(query) },
  });

  if (isLoading || !data) {
    return <Typography variant={variant}>Fetching Rows</Typography>;
  }

  return (
    <Typography variant={variant}>
      {data?.count || 0} Learning Assignment Instance
      {data?.count > 1 ? 's' : ''}
    </Typography>
  );
};

LearningAssignmentListInstancesTable.Label =
  AdminLearningAssignmentInstanceListTableLabel;
LearningAssignmentListInstancesTable.Filters =
  AdminLearningAssignmentInstanceListTableFilters;

const AdminLearningAssignmentLearningSessionListTableColumns: TableColumn[] = [
  {
    Header: 'Learner',
    accessor: 'learner.name',
    sortField: 'learnerName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      const goToRoute = useRoutingAction();
      const items = [
        {
          name: 'Go To Learner View',
          handler: () =>
            goToRoute(
              // @ts-ignore
              routes.admin.learners.item.path.full(row.original.learnerId),
            ),
        },
      ];
      if (row.original.learningItemInstanceParentId) {
        items.push({
          name: 'Go To Live Session',
          handler: () =>
            goToRoute(
              // @ts-ignore
              routes.admin.manage.liveSessions.item.path.full(
                row.original.learningItemInstanceParentId,
              ),
            ),
        });
      }
      return (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <FloatingMenu align="left" items={items}>
              <a>
                {row.original.learner.firstName} {row.original.learner.lastName}
              </a>
            </FloatingMenu>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      );
    },
  },
  {
    Header: 'Assignment',
    accessor: 'learningAssignment.name',
    sortField: 'name',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return ability.can('view', 'assignment_page') ? (
        <Link
          to={routes.admin.manage.assignments.item.path.full(
            row.original.learningAssignmentId,
          )}
        >
          {row.original.learningAssignment.name}
        </Link>
      ) : (
        <span>{row.original.learningAssignment.name}</span>
      );
    },
  },
  {
    Header: 'Content',
    accessor: 'content.name',
    sortField: 'contentName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      let contentUrl = '';
      switch (row.original.type) {
        case LearningAssignmentLearningContentType.elearning:
          contentUrl = routes.admin.catalogue.elearningCourses.item.path.full(
            row.original.learningItemId,
          );
          break;
        case LearningAssignmentLearningContentType.assessment:
          contentUrl = routes.admin.catalogue.assessments.item.path.full(
            row.original.learningItemId,
          );
          break;
        case LearningAssignmentLearningContentType['live-learning']:
          contentUrl = routes.admin.catalogue.liveCourses.item.path.full(
            row.original.learningItemId,
          );
          break;
        case LearningAssignmentLearningContentType.checklist:
          contentUrl = routes.admin.catalogue.checklists.item.path.full(
            row.original.learningItemId,
          );
          break;
        case LearningAssignmentLearningContentType.survey:
          contentUrl = routes.admin.catalogue.surveys.item.path.full(
            row.original.learningItemId,
          );
          break;
        default:
          break;
      }

      if (!contentUrl) {
        return row.original.learningItemName;
      }
      return <Link to={contentUrl}>{row.original.learningItemName}</Link>;
    },
  },
  {
    Header: 'Type',
    accessor: 'content.type',
    sortField: 'type',
    Cell: ({ row }) =>
      formatConstantString(
        LearningAssignmentLearningContentType[row.original.type],
      ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortField: 'status',
    Cell: ({ row }) => {
      return (
        <StatusTag
          status={LearningAssignmentInstanceStatus[row.original.status]}
        />
      );
    },
  },
  {
    Header: 'Mandatory',
    accessor: 'mandatory',
    sortField: 'mandatory',
    Cell: ({ row }) => (row.original.mandatory ? 'Yes' : 'No'),
  },
  {
    Header: 'Assigned On',
    accessor: 'createdAt',
    sortField: 'createdAt',
    Cell: ({ row }) => <PrettyDate value={row.original.createdAt} withTime />,
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    sortField: 'dueDate',
    Cell: ({ row }) => <PrettyDate value={row.original.dueDate} withTime />,
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    sortField: 'updatedAt',
    Cell: ({ row }) => <PrettyDate value={row.original.updatedAt} withTime />,
  },
];

const MentionOptions = [
  {
    id: '{{learner.firstName}}',
    label: '{{learner.firstName}}',
  },
  { id: '{{learner.lastName}}', label: '{{learner.lastName}}' },
  { id: '{{learner.email}}', label: '{{learner.email}}' },
  {
    id: '{{learningAssignment.name}}',
    label: '{{learningAssignment.name}}',
  },
  {
    id: '{{learningAssignment.contentName}}',
    label: '{{learningAssignment.contentName}}',
  },
  {
    id: '{{learningAssignment.dueDate}}',
    label: '{{learningAssignment.dueDate}}',
  },
  {
    id: '{{portalUrl}}',
    label: '{{portalUrl}}',
  },
];
