import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LiveSessionStatus } from '@nl-lms/common/feature/types';
import { Button, Typography, useShowModal } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useGetUrlQuery, useRoutingAction } from '@nl-lms/ui/hooks';
import { useNotifications } from '@nl-lms/ui/modules';
import { PrettyDistanceType, prettyDistance } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import {
  LearnerSurveyModal,
  LearnerSurveyModalReturnType,
} from '../../modules/LearnerSurveyModal/LearnerSurveyModal';
import { LearnerLiveSessionCancelRegistrationModal } from '../../pages/LearnerLiveSessionViewPage/LearnerLiveSessionCancelRegistrationModal';

type LiveTrainingRegisterButton = {
  session: {
    id: string;
    name: string;
    status: LiveSessionStatus;
    startDate: Date | null;
    remainingSeats: number;
    enableWaitingList: boolean;
  };
  isLoading?: boolean;
  disabled?: boolean;
  onSuccess?: () => void;
  onShowRegistrationForm?: () => void;
  learnerStatus?: number;
  className?: string;
  registrationForm: any;
  pageRegisterButtonRef?: any;
  courseId: string;
  buttonStyle?: 'regular' | 'primary';
};

const { useLazyLearnerLiveCourseQuery, useTrainingSessionRegisterMutation } =
  learnerApi;
export const LiveTrainingRegisterButton = (
  props: LiveTrainingRegisterButton
) => {
  const { t } = useTranslation('learner');
  const {
    session,
    isLoading: _isLoading = false,
    disabled = false,
    className = '',
    onSuccess = undefined,
    learnerStatus = undefined,
    onShowRegistrationForm = undefined,
    registrationForm,
    courseId,
    buttonStyle = 'regular',
  } = props;
  const dispatch = useDispatch();
  const [registrationResult, setRegistrationResult] = useState('');
  const { addAlertNotification, addSuccessNotification } = useNotifications();

  const [
    trainingSessionRegister,
    { isLoading: isRegisterLoading, isSuccess: hasRegisteredToSession },
  ] = useTrainingSessionRegisterMutation();
  const trainingSessionRegisterAction = useAction(trainingSessionRegister);

  const [getCourse, { isLoading: isGetCourseLoading }] =
    useLazyLearnerLiveCourseQuery();
  const getCourseAction = useAction(getCourse);

  const isLoading = isGetCourseLoading || isRegisterLoading;
  const courseFetchArguments = useMemo(
    () => ({
      id: courseId,
      learnerId: authStore.learnerId!,
    }),
    [courseId]
  );

  const query = useGetUrlQuery() || {};
  const data = {
    learningPathItemId: query.learningPathItemId,
    learningPathInstanceId: query.learningPathInstanceId,
  };

  const showCancelRegistrationModal = useShowModal(
    LearnerLiveSessionCancelRegistrationModal
  );

  const goToSessionViewPage = useRoutingAction({
    route: routes.portal.liveSession.item.path.full(session.id),
  });
  const onRegister = useCallback(
    async (data) => {
      if (!learnerStatus) {
        const result = await trainingSessionRegisterAction({
          id: session.id,
          learnerId: authStore.learnerId,
          data,
        });

        if (result?.data?.name) {
          setRegistrationResult(result?.data?.name);
          addSuccessNotification(result?.data?.message);

          if (onSuccess) onSuccess();
        }

        if (result?.error && result?.error.message) {
          addAlertNotification({
            // @ts-ignore
            message: result.error.message,
          });
        }
      } else {
        // @ts-ignore
        await showCancelRegistrationModal({
          id: session.id,
          learnerId: authStore.learnerId,
        });
        if (onSuccess) onSuccess();
      }
    },
    [
      dispatch,
      trainingSessionRegisterAction,
      session.id,
      learnerStatus,
      setRegistrationResult,
      onSuccess,
    ]
  );

  const showSurveyModal =
    useShowModal<LearnerSurveyModalReturnType>(LearnerSurveyModal);

  const onClickRegister = useCallback(async () => {
    if (!learnerStatus) {
      const result = await getCourseAction(courseFetchArguments);
      const previousActivity = (result?.data?.activity || [])
        .map((a) =>
          [
            C.I_LEARNER_STATUS.CANCELLED,
            C.I_LEARNER_STATUS.ABSENT,
            C.I_LEARNER_STATUS.DECLINED,
            C.I_LEARNER_STATUS.APPROVAL_DECLINED,
          ].includes(a.status || 0)
            ? null
            : a
        )
        .filter((a) => !!a);

      if (result?.data?.course?.canRegisterToMultipleSessions) {
        // if (previousActivity.length) {
        //   const confirmation = await showConfirmationModal();
        //   if (!confirmation.confirmed) return;
        // }
      } else {
        if (previousActivity.length) {
          addAlertNotification({
            // @ts-ignore
            message:
              'Inregistrarea nu este permisa daca ati mai participat anterior la acest curs.',
          });
          return;
        }
      }
    }

    if (registrationForm && !learnerStatus) {
      if (!registrationForm?.surveyFormId || !authStore.learnerId) return;
      if (onShowRegistrationForm) onShowRegistrationForm();
      const res = await showSurveyModal({
        id: registrationForm?.surveyFormId,
        learnerId: authStore.learnerId,
      });

      if (res?.success) {
        onRegister({
          ...data,
          ...res.payload,
        });
      }
    } else {
      onRegister(data);
    }
  }, [learnerStatus, registrationForm]);

  const buttonProps = useMemo(() => {
    const _props = {
      label: '',
      alert: false,
      success: false,
      regular: false,
      warn: false,
      disabled: true,
      className,
      isLoading: isLoading,
    };

    const isPast = session?.startDate
      ? prettyDistance(session?.startDate).type === PrettyDistanceType.Past
      : true;
    const isReady = session.status === C.I_SESSION_STATUSES.READY;

    const hasAvailableSeats =
      session?.remainingSeats > 0 || session?.enableWaitingList;

    if (learnerStatus === C.I_LEARNER_STATUS.APPROVAL) {
      _props.warn = true;
      _props.disabled = true;
      _props.label = t('learningunit.waitingapproval');
    } else if (learnerStatus === C.I_LEARNER_STATUS.APPROVAL_DECLINED) {
      _props.alert = true;
      _props.disabled = true;
      _props.label = t('learningunit.declinedapproval');
    } else if (learnerStatus === C.I_LEARNER_STATUS.WAITING_LIST) {
      _props.warn = true;
      _props.disabled = true;
      _props.label = t('learningunit.waitinglisted');
    } else if (learnerStatus === C.I_LEARNER_STATUS.CANCELLED) {
      _props.alert = true;
      _props.disabled = true;
      _props.label = t('learningunit.alreadycancelled');
    } else if (
      learnerStatus === C.I_LEARNER_STATUS.ATTENDED ||
      learnerStatus === C.I_LEARNER_STATUS.GO_SHOW
    ) {
      _props.success = true;
      _props.disabled = true;
      _props.label = t('learningunit.attended');
    } else if (learnerStatus === C.I_LEARNER_STATUS.ABSENT) {
      _props.alert = true;
      _props.disabled = true;
      _props.label = t('learningunit.absent');
    } else if (learnerStatus) {
      _props.alert = true;
      _props.disabled = false;
      _props.label = t('learningunit.cancelregistration');
    } else if (!isReady || !hasAvailableSeats) {
      _props.regular = true;
      _props.disabled = true;
      _props.label = t('learningunit.registrationclosed');
    } else if (isPast) {
      _props.regular = true;
      _props.disabled = true;
      _props.label = t('learningunit.sessionpast');
    } else {
      _props.regular = buttonStyle === 'regular';
      _props.disabled = false;
      _props.label = t('learningunit.register');
    }

    if (
      _props.disabled ||
      disabled ||
      _isLoading ||
      isLoading ||
      hasRegisteredToSession
    ) {
      _props.disabled = true;
    }

    if (isLoading) {
      _props.label = '';
    }

    return _props;
  }, [
    isLoading,
    _isLoading,
    className,
    hasRegisteredToSession,
    registrationResult,
    learnerStatus,
    session,
  ]);

  const registrationResultMessage = useMemo(() => {
    if (!registrationResult) return '';
    switch (registrationResult) {
      case 'REGISTERED':
        return t('learningunit.registrationcomplete');
      case 'WAITING_APPROVAL':
        return t('learningunit.approvalsent');
      case 'APPROVAL_DECLINED':
        return t('learningunit.approvaldeclined');
      case 'WAITING_LIST':
      case 'ADDED_TO_WAITING_LIST':
        return t('learningunit.waitinglist');
      default:
        return t('learningunit.unableregister');
    }
  }, [registrationResult]);

  if (hasRegisteredToSession) {
    return <Typography.h3>{registrationResultMessage}</Typography.h3>;
  }

  return <Button {...buttonProps} onClick={onClickRegister} />;
};
