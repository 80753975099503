import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { LearningProgramAutomation } from '@nl-lms/feature/learning-programs/sdk';
import {
  Badge,
  BadgeTypes,
  Box,
  Link,
  PrettyDate,
  StatusTag,
  Typography,
} from '@nl-lms/ui/components';
import { formatTimeDelta, prettyDate } from '@nl-lms/ui/utils';

import { learningAssignmentsApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import { ProgramRule } from '../learning-program-rule-graph/types';
import { isProgramRule } from '../learning-program-rule-graph/utils';
import './LearningAssignmentRulesComponent.scss';
import {
  AssignationRuleEventPayload,
  AssignationRuleMatchFieldNamesToTypes,
  AssignationRuleMatchFilterFieldNames,
  AssignmentRuleMatchPayloadOperatorToLabel,
} from './utils/constants';
import { AssignationRule } from './utils/types';

const { useGetLearningAssignmentQuery } = learningAssignmentsApi;

export const RuleComponent = ({
  rule,
  showSubjectAssignment = true,
}: {
  rule: AssignationRule | ProgramRule;
  showSubjectAssignment?: boolean;
}) => {
  const ruleEntityId =
    // @ts-ignore
    rule?.entityId || rule?.subject?.id || rule?.learningAssignmentId;

  let ruleEntityName =
    // @ts-ignore
    rule?.entity?.name || rule?.subject?.name;

  const { data: entityAssignment } = useGetLearningAssignmentQuery({
    id: ruleEntityId,
  });
  ruleEntityName = isProgramRule(rule)
    ? 'Learning Program'
    : entityAssignment?.name;

  // @ts-ignore
  const ruleStartDate = rule?.startDate || rule?.scheduleDate;
  const ruleConditions = parseConditionMatchToOptions(rule);
  const recurrence = rule?.settings?.triggerNTimes;

  return (
    <Box flex={{ flexDirection: 'row' }}>
      <Typography.h4 className="admin-assignation-rule-component__container-h4">
        {showSubjectAssignment ? (
          <Link className="admin-assignation-rule-component__highlighted-text">
            {ruleEntityName}{' '}
          </Link>
        ) : null}
        <span className="admin-assignation-rule-component__normal-text">
          will be assigned
        </span>
        {getRuleAssignationDate(ruleStartDate)}
        <span className="admin-assignation-rule-component__normal-text">
          {getRuleTriggerNTimesLabel(recurrence)}
        </span>
        {getRuleConditions(ruleConditions)}
      </Typography.h4>
    </Box>
  );
};

export const parseEntityLabel = (label) => {
  if (_.isEmpty(label)) return 'No label';
  if (label.includes('·')) {
    return label?.split('·')?.[0];
  }

  return label;
};

export const getLabelForRuleMatch = (match) => {
  if (match?.field && match?.operator && match?.value) {
    const fieldLabelKey =
      Object.keys(AssignationRuleMatchFilterFieldNames)?.find(
        (key) => AssignationRuleMatchFilterFieldNames[key]?.name === match.field
      ) || '';
    const fieldLabel =
      AssignationRuleMatchFilterFieldNames[fieldLabelKey]?.label;

    const operator = AssignmentRuleMatchPayloadOperatorToLabel[match?.operator];

    let value = <></>;

    switch (AssignationRuleMatchFieldNamesToTypes?.[match?.field]) {
      case 'date':
        value = <PrettyDate value={match?.value} />;
        break;
      case 'datetime':
        value = <PrettyDate value={match?.value} withTime />;
        break;
      case 'select':
        if (match.field === 'status') {
          if (Array.isArray(match?.value)) {
            match?.value?.map((val) => {
              value = (
                <>
                  {value} <StatusTag status={val} />
                </>
              );
            });
          } else value = <StatusTag status={match?.value} />;
        } else {
          if (match.valueOptions) {
            const foundRes = match?.valueOptions?.filter((item) =>
              match?.value?.includes(item?.value)
            );

            value = <>{foundRes?.map((f) => f?.label)?.join(',')}</>;
          } else {
            value = <>{match?.value?.join(',')}</>;
          }
        }
        break;
      default:
        value = <>{match?.value}</>;
        break;
    }

    return (
      <>
        AND <span>{fieldLabel} </span>
        <span>{operator} </span>
        <span>{value}</span>
      </>
    );
  }
  return <></>;
};

const getRuleConditions = (conditions) => {
  if (!conditions?.length || conditions?.every((cond) => _.isEmpty(cond)))
    return <></>;

  if (conditions.length === 1) {
    return <>{getLabelForCondition(conditions[0])}</>;
  }

  let text;
  conditions.forEach((cond, index) => {
    if (cond && !_.isEmpty(cond)) {
      if (index > 0) {
        text = (
          <>
            {text} {getLabelForCondition(cond)}
          </>
        );
      } else {
        text = <>{getLabelForCondition(cond)}</>;
      }
    }
  });

  return text;
};

const getLabelForCondition = ({ value: condValue, combinator }) => {
  let conditionRuleText;
  const conditionEventIndex = condValue?.eventName
    ? AssignationRuleEventPayload?.findIndex(
        (ev) => ev?.name === condValue?.eventName
      )
    : -1;

  if (conditionEventIndex > -1) {
    conditionRuleText = <> IF{conditionRuleText}</>;
    if (combinator) {
      conditionRuleText = <Badge type={BadgeTypes.LIGHT} label={combinator} />;
    }

    const refId = condValue?.referenceEntityId || condValue?.referenceId;

    if (refId) {
      const { data: referenceAssignment } = useGetLearningAssignmentQuery({
        id: refId,
      });
      const refName = referenceAssignment?.name || condValue?.reference?.label;
      conditionRuleText = (
        <>
          {conditionRuleText}{' '}
          <Link
            to={routes.admin.manage.assignments.item.path.full(refId)}
            className="admin-assignation-rule-component__highlighted-text"
          >
            {parseEntityLabel(refName)}
          </Link>
        </>
      );
    }
    conditionRuleText = (
      <span>
        {conditionRuleText}{' '}
        {AssignationRuleEventPayload[conditionEventIndex]?.label}
      </span>
    );
  }

  if (!_.isEmpty(condValue?.match)) {
    conditionRuleText = (
      <>
        {conditionRuleText} {getLabelForRuleMatch(condValue?.match)}
      </>
    );
  }

  return conditionRuleText;
};

export const parseConditionMatchToOptions = (rule) =>
  rule?.conditions?.length
    ? rule?.conditions?.map((cond, condIndex) => {
        const combinator =
          cond?.combinator ||
          rule?.conditionsEquation?.value?.[condIndex]?.combinator;
        // if (cond?.value?.match?.fieldLoadOptions) {
        //   const valueOptions = await cond?.value?.match.fieldLoadOptions('');

        //   return {
        //     value: {
        //       ...cond?.value,
        //       match: {
        //         ...cond?.value?.match,
        //         fieldValueOptions: valueOptions,
        //       },
        //     },
        //     combinator,
        //   };
        // }
        return {
          value: cond?.value,
          combinator,
        };
      })
    : [];

export const getRuleAssignationDate = (startDate) => {
  const dateType = startDate?.type;
  const dateValue = startDate?.value
    ? dateType === 'relative'
      ? formatTimeDelta(startDate?.value, '')
      : prettyDate(startDate?.value, { withTime: true })
    : null;
  const hasOptions =
    startDate?.type === 'relative' &&
    startDate?.options &&
    !_.isEmpty(startDate?.options);

  const hour = hasOptions ? startDate?.options?.hour : null;

  const skipWeekends = hasOptions && startDate?.options?.skipWeekends;
  const customDay =
    hasOptions && startDate?.options?.dayOfWeek
      ? startDate?.options?.dayOfWeek
      : null;

  const firstWeek = hasOptions && startDate?.options?.firstWeek;
  const lastWeek = hasOptions && startDate?.options?.lastWeek;
  const customWeek =
    hasOptions && startDate?.options?.weekOfMonth
      ? startDate?.options?.weekOfMonth
      : null;

  const firstMonth = hasOptions && startDate?.options?.firstMonth;
  const lastMonth = hasOptions && startDate?.options?.lastMonth;
  const customMonth =
    hasOptions && startDate?.options?.monthOfYear
      ? startDate?.options?.monthOfYear
      : null;

  const dateConditions = () => {
    let conditions = '';

    if (hour) {
      conditions = ` at ${hour}`;
    }

    if (skipWeekends) {
      conditions = `${conditions} weekdays only`;
    } else if (customDay) {
      conditions = `${conditions} on ${customDay}`;
    }

    if (firstWeek) {
      conditions = `${conditions} first week of the month`;
    } else if (lastWeek) {
      conditions = `${conditions} last week of the month`;
    } else if (customWeek) {
      conditions = `${conditions} on week ${customWeek} of the month`;
    }

    if (firstMonth) {
      conditions = `${conditions} first month of the year`;
    } else if (lastMonth) {
      conditions = `${conditions} last month of the year`;
    } else if (customMonth) {
      conditions = `${conditions} in ${customMonth}`;
    }

    return conditions !== '' ? <>{conditions}</> : null;
  };

  const connector = () => {
    if (dateType === 'fixed') {
      return <> ON</>;
    } else {
      if (!dateValue || dateValue === 'right away') {
        return <></>;
      }
      return <> AFTER</>;
    }
  };

  return (
    <>
      {connector()} {dateValue ?? ''}
      {dateConditions()}
    </>
  );
};

export const getRuleTriggerNTimesLabel = (
  triggerNTimes?: number | undefined
) => {
  if (!triggerNTimes || [1, '1'].includes(triggerNTimes)) return '';

  return ` ${triggerNTimes} times `;
};
