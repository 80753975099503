import React from 'react';

export const StarIcon = ({ type }: { type: 'filled' | 'outline' | 'half' }) => {
  switch (type) {
    case 'filled':
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.10013 11.3L6.70013 14.6L5.70013 19.2C5.60013 19.8 5.80013 20.4 6.30013 20.7C6.50013 20.9 6.80013 21 7.10013 21C7.30013 21 7.50013 21 7.70013 20.9C7.70013 20.9 7.80013 20.9 7.80013 20.8L11.9001 18.5L16.0001 20.8C16.0001 20.8 16.1001 20.8 16.1001 20.9C16.6001 21.1 17.2001 21.1 17.6001 20.8C18.1001 20.5 18.3001 19.9 18.2001 19.3L17.2001 14.7C17.6001 14.4 18.2001 13.8 18.8001 13.2L20.7001 11.5L20.8001 11.4C21.2001 11 21.3001 10.4 21.1001 9.9C20.9001 9.4 20.5001 9 19.9001 8.9H19.8001L15.1001 8.4L13.2001 4.1C13.2001 4.1 13.2001 4 13.1001 4C13.0001 3.3 12.5001 3 12.0001 3C11.5001 3 11.0001 3.3 10.7001 3.8C10.7001 3.8 10.7001 3.9 10.6001 3.9L8.70013 8.2L4.00013 8.7H3.90013C3.40013 8.8 2.90013 9.2 2.70013 9.7C2.60013 10.3 2.70013 10.9 3.10013 11.3Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'outline':
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8546 20.9659C16.6306 20.9659 16.4116 20.9159 16.2086 20.8199L16.1046 20.7689L11.9976 18.4259L7.89056 20.7699L7.78456 20.8229C7.29656 21.0509 6.69956 20.9969 6.26356 20.6799C5.79456 20.3399 5.56256 19.7469 5.67756 19.1709L6.63456 14.5289L5.03256 13.0719L3.13756 11.3469L3.05956 11.2649C2.68456 10.8689 2.55056 10.2949 2.71956 9.77294C2.89256 9.24894 3.33956 8.86094 3.87956 8.76394L3.98156 8.74594L8.68256 8.22494L10.6286 3.91494L10.6886 3.80494C10.9506 3.33194 11.4526 3.03394 11.9976 3.03394C12.5406 3.03394 13.0416 3.33194 13.3066 3.80394L13.3666 3.91594L15.3146 8.22794L20.0156 8.74894L20.1196 8.76594C20.6586 8.86594 21.1056 9.25194 21.2776 9.77794C21.4476 10.2989 21.3126 10.8759 20.9376 11.2719L20.8596 11.3499L17.3616 14.5339L18.3186 19.1659C18.4316 19.7529 18.2006 20.3439 17.7286 20.6849C17.4766 20.8669 17.1726 20.9659 16.8546 20.9659ZM8.70556 14.4019C8.66656 14.5839 8.23956 16.6479 7.86056 18.4839L11.5036 16.4069C11.8106 16.2319 12.1876 16.2319 12.4936 16.4069L16.1366 18.4819L15.2876 14.3779C15.2166 14.0319 15.3326 13.6729 15.5956 13.4359L18.6956 10.6139L14.5276 10.1529C14.1766 10.1139 13.8736 9.89294 13.7266 9.56894L11.9986 5.74794L10.2726 9.56894C10.1266 9.89094 9.82256 10.1119 9.47156 10.1529L5.30356 10.6139L8.40356 13.4359C8.67556 13.6819 8.78756 14.0529 8.70556 14.4019Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'half':
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.10013 11.3L6.70013 14.6L5.70013 19.2C5.60013 19.8 5.80013 20.4 6.30013 20.7C6.50013 20.9 6.80013 21 7.10013 21C7.30013 21 7.50013 21 7.70013 20.9C7.70013 20.9 7.80013 20.9 7.80013 20.8L11.9001 18.5L16.0001 20.8C16.0001 20.8 16.1001 20.8 16.1001 20.9C16.6001 21.1 17.2001 21.1 17.6001 20.8C18.1001 20.5 18.3001 19.9 18.2001 19.3L17.2001 14.7C17.6001 14.4 18.2001 13.8 18.8001 13.2L20.7001 11.5L20.8001 11.4C21.2001 11 21.3001 10.4 21.1001 9.9C20.9001 9.4 20.5001 9 19.9001 8.9H19.8001L15.1001 8.4L13.2001 4.1C13.2001 4.1 13.2001 4 13.1001 4C13.0001 3.3 12.5001 3 12.0001 3C11.5001 3 11.0001 3.3 10.7001 3.8C10.7001 3.8 10.7001 3.9 10.6001 3.9L8.70013 8.2L4.00013 8.7H3.90013C3.40013 8.8 2.90013 9.2 2.70013 9.7C2.60013 10.3 2.70013 10.9 3.10013 11.3ZM12.0001 16.3V5.8L13.7001 9.6C13.8001 9.9 14.2001 10.1 14.5001 10.2L18.7001 10.7L15.6001 13.5C15.3001 13.7 15.2001 14.1 15.3001 14.5C15.3001 14.7 15.8001 16.7 16.1001 18.6L12.5001 16.5C12.3001 16.3 12.2001 16.3 12.0001 16.3Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8546 20.9659C16.6306 20.9659 16.4116 20.9159 16.2086 20.8199L16.1046 20.7689L11.9976 18.4259L7.89056 20.7699L7.78456 20.8229C7.29656 21.0509 6.69956 20.9969 6.26356 20.6799C5.79456 20.3399 5.56256 19.7469 5.67756 19.1709L6.63456 14.5289L5.03256 13.0719L3.13756 11.3469L3.05956 11.2649C2.68456 10.8689 2.55056 10.2949 2.71956 9.77294C2.89256 9.24894 3.33956 8.86094 3.87956 8.76394L3.98156 8.74594L8.68256 8.22494L10.6286 3.91494L10.6886 3.80494C10.9506 3.33194 11.4526 3.03394 11.9976 3.03394C12.5406 3.03394 13.0416 3.33194 13.3066 3.80394L13.3666 3.91594L15.3146 8.22794L20.0156 8.74894L20.1196 8.76594C20.6586 8.86594 21.1056 9.25194 21.2776 9.77794C21.4476 10.2989 21.3126 10.8759 20.9376 11.2719L20.8596 11.3499L17.3616 14.5339L18.3186 19.1659C18.4316 19.7529 18.2006 20.3439 17.7286 20.6849C17.4766 20.8669 17.1726 20.9659 16.8546 20.9659ZM8.70556 14.4019C8.66656 14.5839 8.23956 16.6479 7.86056 18.4839L11.5036 16.4069C11.8106 16.2319 12.1876 16.2319 12.4936 16.4069L16.1366 18.4819L15.2876 14.3779C15.2166 14.0319 15.3326 13.6729 15.5956 13.4359L18.6956 10.6139L14.5276 10.1529C14.1766 10.1139 13.8736 9.89294 13.7266 9.56894L11.9986 5.74794L10.2726 9.56894C10.1266 9.89094 9.82256 10.1119 9.47156 10.1529L5.30356 10.6139L8.40356 13.4359C8.67556 13.6819 8.78756 14.0529 8.70556 14.4019Z"
            fill="currentColor"
          />
        </svg>
      );
  }
};
