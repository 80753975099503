import store from 'store';

import { getClients } from '@nl-lms/feature/content-library/sdk';

import { apiBaseUrl } from './api';

const {
  raw: contentLibraryItemsApiClient,
  rtk: contentLibraryItemsRtkApiClient,
} = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export { contentLibraryItemsApiClient };

export const contentLibraryItemsApi = contentLibraryItemsRtkApiClient;
contentLibraryItemsApi.enhanceEndpoints<'ContentLibraryItem'>({
  endpoints: {
    listContentLibraryItems: {
      providesTags: [{ type: 'ContentLibraryItem', id: 'LIST' }],
    },
    getContentLibraryItem: {
      providesTags: [{ type: 'ContentLibraryItem', id: 'LIST' }],
    },
    createContentLibraryItem: {
      invalidatesTags: [{ type: 'ContentLibraryItem', id: 'LIST' }],
    },
    updateContentLibraryItem: {
      invalidatesTags: [{ type: 'ContentLibraryItem', id: 'LIST' }],
    },
    removeContentLibraryItems: {
      invalidatesTags: [{ type: 'ContentLibraryItem', id: 'LIST' }],
    },
  },
});

export type ContentLibraryItemsApiEndpointNames =
  keyof typeof contentLibraryItemsApi.endpoints;
type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type ContentLibraryItemsApiMutationNames = FilterEndingWith<
  keyof typeof contentLibraryItemsApi,
  'Mutation'
>;
