import React from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './Banner.scss';

type BannerProps = TidComponent<{
  text: string;
  description?: string;
  action?: React.ReactElement | React.ReactNode;
  alert?: boolean;
  warn?: boolean;
  regular?: boolean;
  success?: boolean;
  icon?: any;
  className?: string;
}>;

const getBannerClassName = (
  {
    alert,
    success,
    warn,
  }: {
    regular?: boolean;
    alert?: boolean;
    success?: boolean;
    warn?: boolean;
  },
  baseClass = 'banner'
) => {
  if (alert) return `${baseClass}--alert`;
  if (warn) return `${baseClass}--warn`;
  if (success) return `${baseClass}--success`;

  return `${baseClass}--regular`;
};

export const Banner = ({
  text,
  description = '',
  action = null,
  icon = null,
  ...props
}: BannerProps) => {
  const classNameProps = useClassNameProps(
    'banner',
    getBannerClassName(props),
    props
  );
  const commonProps = useTestProps(props);

  return (
    <div {...classNameProps} {...commonProps}>
      {icon ? <div className={`banner-icon`}>{icon}</div> : null}
      <div className="banner-content">
        <div className="banner-text">{text}</div>
        {description && <div className="banner-description">{description}</div>}
        {action && <div className="banner-action">{action}</div>}
      </div>
    </div>
  );
};
