import { AxiosResponse } from 'axios';

import { _ } from '@nl-lms/vendor';

import { ApiClientOptions } from '../types';

// response should be already transformed by the success handler interceptor
export const camelizeTransformInterceptor =
  (options: ApiClientOptions) => (data: AxiosResponse) => {
    if (typeof window !== 'undefined' && data instanceof Blob) return data;

    if (typeof data === 'string') {
      try {
        return _.camelize(JSON.parse(data));
      } catch (e) {
        throw new Error('Could not serialize request');
      }
    } else {
      return _.camelize(data);
    }
  };
