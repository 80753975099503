import React, { useCallback, useEffect, useState } from 'react';
import { SingleSelect, Button } from '@nl-lms/ui/components';
import { useApi } from '../../../../../_common/services/api';
import './AdminLearningPathLearningUnitsSection.scss';
import { C } from '@nl-lms/ui/constants';

type Props = {
  onSubmit: (item: any) => void;
  hasError: boolean;
};

enum LearningUnitType {
  liveCourse = 'In Class',
  virtualCourse = 'Virtual In Class',
  conference = 'Conference',
  virtualConference = 'Virtual Conference',
  eLearning = 'Elearning',
  assessment = 'Assessment',
}

const getCourseType = (type) => {
  switch (type) {
    case C.I_LEARNING_TYPES.IN_CLASS:
      return LearningUnitType.liveCourse;
    case C.I_LEARNING_TYPES.CONFERENCE:
      return LearningUnitType.conference;
    case C.I_LEARNING_TYPES.VIRTUAL_CONFERENCE:
      return LearningUnitType.virtualConference;
    case C.I_LEARNING_TYPES.VIRTUAL_CLASS:
      return LearningUnitType.virtualCourse;
    default:
      return '';
  }
};

export const AdminLearningPathLearningUnitSelect = (props: Props) => {
  const { onSubmit, hasError } = props;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [shouldClearSelection, setShouldClearSelection] = useState(false);

  const api = useApi();

  const onLoadOptions = useCallback(async (input) => {
    const courseRes = await api.course.listForSelect(input);
    const elearningRes = await api.elearningCourse.listForSelect(input, false);
    const assessmentRes = await api.assessment.listForSelect(input);
    const courses = courseRes.map((course) => ({
      label: `${course.name} - ${getCourseType(course.type)}`,
      value: course.id,
      unit: course,
      unitType: getCourseType(course.type),
    }));
    const elearning = elearningRes.map((course) => ({
      label: `${course.name} - ${LearningUnitType.eLearning}`,
      value: course.id,
      unit: course,
      unitType: LearningUnitType.eLearning,
    }));
    const assessments = assessmentRes.map((assessment) => ({
      label: `${assessment.name} - ${LearningUnitType.assessment}`,
      value: assessment.id,
      unit: assessment,
      unitType: LearningUnitType.assessment,
    }));
// @ts-ignore
    setOptions([...courses, ...elearning, ...assessments]);
  }, []);

  useEffect(() => {
    onLoadOptions('');
  }, []);

  return (
    <div className="learning-units-section__select-container">
      <SingleSelect
        name="learningUnits"
        onChange={(value) => setSelected(value)}
        placeholder="Search for a module..."
        options={options}
        shouldClearSelection={shouldClearSelection}
        returnEntireItemOnChange={true}
        hasError={hasError}
      />
      <Button
        success
        label="Add unit"
        onClick={() => {
          onSubmit(selected);
// @ts-ignore
          setSelected(null);
          setShouldClearSelection(true);
          setTimeout(() => setShouldClearSelection(false), 0);
        }}
      />
    </div>
  );
};
