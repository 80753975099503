import React from 'react';

export const BellIcon = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7C16 3.68629 13.3137 1 10 1C6.68629 1 4 3.68629 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7"
      stroke="#565C5F"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M11.73 19.9998C11.3722 20.6166 10.7131 20.9963 10 20.9963C9.28695 20.9963 8.62782 20.6166 8.27002 19.9998"
      stroke="#565C5F"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
