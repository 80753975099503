import { z } from 'zod';

export const CompetencySchema = z.object({
  id: z.string(),
  title: z.string(),
  scope: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Competency = z.infer<typeof CompetencySchema>;

export const ResourceCompetencySchema = z.object({
  id: z.string(),
  competencyId: z.string(),
  learnerId: z.string(),
  elearningCourseId: z.string(),
  courseId: z.string(),
  assessmentFormId: z.string(),
  learningPathId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ResourceCompetency = z.infer<typeof ResourceCompetencySchema>;
