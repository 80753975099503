import store from 'store';

import { getClients } from '@nl-lms/feature/live-learning/sdk';

import { apiBaseUrl } from './api';

const { raw: liveLearningApiClient, rtk: liveLearningRtkApiClient } =
  getClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export { liveLearningApiClient };

export const liveLearningApi = liveLearningRtkApiClient;
liveLearningApi.enhanceEndpoints<'LiveSession' | 'LiveCourse'>({
  endpoints: {
    getLiveCourse: { providesTags: [{ type: 'LiveCourse', id: 'LIST' }] },
  },
});
