import React, { useEffect } from 'react';

import { NotificationsContainer, useTracker } from '@nl-lms/ui/modules';

import './SingleActionLayout.scss';

export const SingleActionLayout = ({ children, className = '' }) => {
  const tracker = useTracker();

  useEffect(() => {
    tracker.stopRecording();
  }, []);

  return (
    <div className={`nifty-app-v3 single-action-layout ${className}`}>
      <div className="single-action-layout__content">{children}</div>
      <div className="notifications-container">
        <NotificationsContainer />
      </div>
    </div>
  );
};
