import {
  AppQueryFilter,
  FieldType,
  FilterOperator,
} from '@nl-lms/common/shared';
import { combineFilters } from '@nl-lms/common/shared';

export function buildFiltersFromRowSelection<Entity>(args: {
  selectedRows: Entity[];
  allRowsAreSelected: boolean;
  excludedRows: Entity[];
  filters?: AppQueryFilter;
  field?: string;
}): AppQueryFilter {
  const {
    selectedRows,
    excludedRows,
    allRowsAreSelected,
    filters = null,
    field = 'id',
  } = args;
  // @ts-ignore
  let idFilter: AppQueryFilter = null;
  if (allRowsAreSelected && excludedRows.length) {
    idFilter = {
      id: '',
      value: [
        {
          id: '',
          value: {
            field: { label: field, field: field, type: FieldType.string },
            value: {
              // @ts-ignore
              value: excludedRows.map((v) => v.id),
              label: 'Ids',
            },
            operator: FilterOperator.Excludes,
          },
        },
      ],
    };
  }
  if (!allRowsAreSelected && selectedRows.length) {
    idFilter = {
      id: '',
      value: [
        {
          id: '',
          value: {
            field: { label: field, field: field, type: FieldType.string },
            value: {
              // @ts-ignore
              value: excludedRows.map((v) => v.id),
              label: 'Ids',
            },
            operator: FilterOperator.Includes,
          },
        },
      ],
    };
  }

  // @ts-ignore
  return combineFilters(filters, idFilter);
}
