import { z } from 'zod';

export enum LiveCourseType {
  IN_CLASS = 1,
  CONFERENCE = 2,
  VIRTUAL_CLASS = 3,
  VIRTUAL_CONFERENCE = 4,
}

export enum ScheduleTemplateBlockType {
  CONTENT_DELIVERY = 1,
  BREAK,
  DAY_BREAK,
}

export const LiveCourseScheduleSchema = z.array(
  z.object({
    // start_date: z.preprocess(
    //   (val) => (typeof val === 'string' ? new Date(val) : null),
    //   z.date().nullable()
    // ),
    end_date: z.string().nullable(),
    start_date: z.string().nullable(),
    schedule: z.array(
      z.object({
        type: z.nativeEnum(ScheduleTemplateBlockType),
        duration: z.number(),
      })
    ),
  })
);

export const LiveCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  language: z.number(),
  courseMaterial: z.string().nullable(),
  minPax: z.number(),
  maxPax: z.number(),
  userId: z.string(),
  externalId: z.string().nullable(),
  scheduleTemplate: LiveCourseScheduleSchema,
  registrationType: z.number(),
  description: z.string().nullable(),
  thumbnail: z.string(),
  prework: z.string().nullable(),
  managerLearnerAttendedNotificationMessage: z.string().nullable(),
  requirements: z.array(z.string()),
  trainingSessionApprovalType: z.number(),
  trainingSessionApproverIds: z.array(z.string()),
  type: z.nativeEnum(LiveCourseType),
  tagIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  vendorId: z.string().nullable(),
  canRegisterToMultipleSessions: z.boolean(),
  rating: z.number().nullable(),
  ratingCount: z.number().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  duration: z.object({
    days: z.number(),
    totalMinutes: z.number(),
    contentMinutes: z.number(),
    breakMinutes: z.number(),
  }),
});

export const LearnerLiveCourseSchema = LiveCourseSchema.extend({
  activity: z.array(z.unknown()),
  futureSessions: z.array(z.unknown()),
});

export type LiveCourse = z.infer<typeof LiveCourseSchema>;

export type LiveCourseScheduleTemplate = z.infer<
  typeof LiveCourseScheduleSchema
>;
