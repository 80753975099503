import { useAbility } from '@casl/react';
import React, { useCallback, useMemo } from 'react';

import { FieldType, formatSecondsToStandard } from '@nl-lms/common/shared';
import {
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  Table,
  TableWithFullPagination,
  useConfirmationModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { AbilityContext } from '../../../Can';
import { useQueryErrorHandler, useTableAction } from '../../../_common/hooks';
import { adminApi, useApi } from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import {
  fetchAndMapLearners,
  mapAndLoadConstantsForSelect,
} from '../../_common/utils/fetchEntitiesForSelectMethods';
import { AdminAssessmentAttemptEvaluationSideModal } from './AdminAssessmentAttemptEvaluationSideModal';

const {
  useRemoveAssessmentInstancesMutation,
  useListAssessmentInstanceAttemptsQuery,
  useListAssessmentInstancesByAssessmentIdQuery,
  useRemoveAssessmentInstanceAttemptMutation,
} = adminApi;

export const AdminAssessmentFormInstanceTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();

  return (
    <FilterBar
      id="assessment-form-instance-view"
      fields={[
        {
          name: 'learner_id',
          label: 'Learner',
          type: FieldType.select,
          loadOptions: fetchAndMapLearners,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'attempts_count',
          label: 'Attempts',
          type: FieldType.number,
        },
        {
          name: 'status',
          label: 'Status',
          type: FieldType.select,
          loadOptions: mapAndLoadConstantsForSelect(
            C.ASSESSMENT_INSTANCE_STATUSES
          ),
        },
        {
          name: 'score',
          label: 'Score',
          type: FieldType.number,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

export const AdminAssessmentFormInstanceListTable = ({ assessmentId }) => {
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();

  const arg = useMemo(
    () => ({ query, id: assessmentId }),
    [query, assessmentId]
  );
  const { data, isLoading } =
    useListAssessmentInstancesByAssessmentIdQuery(arg);
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  const [removeInstances] = useRemoveAssessmentInstancesMutation();
  const onRemoveInstances = useTableAction(removeInstances);
  const [columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Learner',
      accessor: 'learnerName',
      sortField: 'learnerName',
      Cell: ({ row }) => (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      ),
    },
    {
      Header: 'Attempts',
      accessor: 'attemptsCount',
      sortField: 'attemptsCount',
      Cell: ({ row }) => row.original.attemptsCount,
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortField: 'status',
      Cell: ({ row }) => (
        <StatusTag
          status={C.ASSESSMENT_INSTANCE_STATUSES[row.original.status]}
        />
      ),
    },
    {
      Header: 'Score',
      accessor: 'score',
      sortField: 'score',
      Cell: ({ row }) =>
        Number.isInteger(row.original.score) ? row.original.score : '-',
    },
  ]);
  const ability = useAbility(AbilityContext);

  return (
    <>
      <TableWithFullPagination
        data={rows}
        columns={columns}
        isLoading={isLoading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        onChangeColumns={onChangeColumns}
        tableActions={[
          {
            name: 'Delete',
            handler: onRemoveInstances,
          },
        ]}
        ExpanderComponent={AdminAssessmentViewInstanceAttemptsTable}
        sorting={sorting}
        onChangeSorting={onChangeSorting}
      >
        <NoDataPlaceholder
          iconName="MonitorIcon"
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.default
          }
          title={
            filters
              ? 'There are no assessments that match your search'
              : 'There are no assessments created'
          }
          // @ts-ignore
          subtitle={filters ? 'You can try changing the active filters' : null}
        />
      </TableWithFullPagination>
    </>
  );
};

export const AdminAssessmentFormInstanceActivityTableFilters = () => {
  const api = useApi();
  const { filters, onChangeFilters } = useListViewContext();
  const fetchAndMapAssessments = useCallback(
    async (inputValue) => {
      const res = await api.assessment.listForSelect(inputValue);
      return res.map((c) => ({
        value: c.id,
        label: c.name,
      }));
    },
    [api]
  );

  return (
    <FilterBar
      id="assessment-form-instance-view"
      fields={[
        {
          name: 'learner_id',
          label: 'Learner',
          type: FieldType.select,
          loadOptions: fetchAndMapLearners,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'assessment_form_id',
          label: 'Assessment',
          type: FieldType.select,
          loadOptions: fetchAndMapAssessments,
        },
        {
          name: 'status',
          label: 'Status',
          type: FieldType.select,
          loadOptions: mapAndLoadConstantsForSelect(
            C.ASSESSMENT_INSTANCE_STATUSES
          ),
        },
        {
          name: 'attempts_count',
          label: 'Attempts',
          type: FieldType.number,
        },
        {
          name: 'score',
          label: 'Score',
          type: FieldType.number,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

export const AdminAssessmentFormInstanceActivityTable = () => {
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();
  const { useListAssessmentInstancesQuery } = adminApi;
  const { data, isLoading } = useListAssessmentInstancesQuery(query);
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  const ability = useAbility(AbilityContext);
  const [_columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Learner',
      accessor: 'learnerName',
      sortField: 'learnerName',
      Cell: ({ row }) => (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      ),
    },
    {
      Header: 'Assessment',
      accessor: 'assessmentForm.name',
      sortField: 'assessmentFormName',
      Cell: ({ row }) =>
        ability.can('view', 'assessment_instance_page') ? (
          <Link
            to={routes.admin.catalogue.assessments.item.path.full(
              row.original.assessmentForm.id
            )}
          >
            {row.original.assessmentForm.name}
          </Link>
        ) : (
          row.original.assessmentForm.name
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortField: 'status',
      Cell: ({ row }) => (
        <StatusTag
          status={C.ASSESSMENT_INSTANCE_STATUSES[row.original.status]}
        />
      ),
    },
    {
      Header: 'Attempts',
      accessor: 'attemptsCount',
      sortField: 'attemptsCount',
      Cell: ({ row }) =>
        `${row.original.attemptsCount}/${row.original.maxAttemptsCount}`,
    },
    {
      Header: 'Score',
      accessor: 'score',
      sortField: 'score',
      Cell: ({ row }) =>
        Number.isInteger(row.original.score) ? row.original.score : '-',
    },
    {
      Header: 'Passing Score',
      accessor: 'assessmentForm.minScore',
      sortField: 'assessmentFormMinScore',
      Cell: ({ row }) =>
        Number.isInteger(row.original.assessmentForm.minScore)
          ? row.original.assessmentForm.minScore
          : '-',
    },
  ]);

  return (
    <TableWithFullPagination
      columns={_columns}
      data={rows}
      isLoading={isLoading}
      onChangePagination={onChangePagination}
      pagination={pagination}
      ExpanderComponent={AdminAssessmentViewInstanceAttemptsTable}
      sorting={sorting}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
    >
      <NoDataPlaceholder
        iconName="MonitorIcon"
        color={
          filters
            ? NoDataPlaceholderColor.warning
            : NoDataPlaceholderColor.default
        }
        title={
          filters
            ? 'There are no assessments that match your search'
            : 'There are no assessments created'
        }
        // @ts-ignore
        subtitle={filters ? 'You can try changing the active filters' : null}
      />
    </TableWithFullPagination>
  );
};

export const AdminAssessmentViewInstanceAttemptsTable = ({ row: instance }) => {
  const fetchArguments = useMemo(() => ({ id: instance.id }), [instance]);
  const { data, isLoading, error } =
    useListAssessmentInstanceAttemptsQuery(fetchArguments);
  useQueryErrorHandler({ error });

  const [removeInstanceAttempt] = useRemoveAssessmentInstanceAttemptMutation();
  const removeInstanceAttemptAction = useAction(removeInstanceAttempt, {
    successMessage: 'The assessment attempt has been removed successfully',
    confirmationMessage:
      'You are about to delete an assessment instance attempt',
    showConfirmation: true,
  });

  const onRemoveAttempt = useCallback(
    async (row) => {
      const res = await removeInstanceAttemptAction({
        instanceId: instance.id,
        attemptId: row.id,
      });

      console.log(res);
    },
    [removeInstanceAttemptAction]
  );

  const rowActions = getActionAbility('remove_attempt', 'assessment_instance', {
    name: 'Remove Attempt',
    handler: onRemoveAttempt,
    Icon: Icon.DeleteIcon,
  });

  const showEvaluationSideModal = useShowModal(
    AdminAssessmentAttemptEvaluationSideModal
  );

  const onClickRow = useCallback(
    async (row) => {
      if (row.status === C.I_ASSESSMENT_INSTANCE_ATTEMPT_STATUSES.IN_PROGRESS)
        return;

      // @ts-ignore
      await showEvaluationSideModal({
        attemptId: row.id,
        instanceId: instance.id,
      });
    },
    [instance]
  );

  const attempts = useMemo(
    () =>
      (data || []).map((row) => ({
        ...row,
        isScoredAttempt:
          instance.scoredAssessmentFormInstanceAttemptId === row.id,
      })),
    [data, instance]
  );

  const ability = useAbility(AbilityContext);

  return (
    <Table
      data={attempts}
      isLoading={isLoading}
      rowActions={rowActions}
      // @ts-ignore
      onClickRow={
        ability.can('view_evaluation', 'assessment_instance')
          ? onClickRow
          : null
      }
      columns={[
        {
          Header: 'Started On',
          accessor: 'startedOn',
          Cell: ({ row }) => (
            <PrettyDate value={row.original.startedOn} withTime />
          ),
        },
        {
          Header: 'Finished On',
          accessor: 'finishedOn',
          Cell: ({ row }) => (
            <PrettyDate value={row.original.finishedOn} withTime />
          ),
        },
        {
          Header: 'Time Spent',
          accessor: 'timeSpent',
          Cell: ({ row }) => formatSecondsToStandard(row.original.timeSpent),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => (
            <StatusTag
              status={
                C.ASSESSMENT_INSTANCE_ATTEMPT_STATUSES[row.original.status]
              }
            />
          ),
        },
        {
          Header: 'Score',
          accessor: 'score',
          Cell: ({ row }) => (row.original.score ? row.original.score : '-'),
        },
        {
          Header: 'Scored Attempt',
          accessor: 'isScoredAttempt',
          Cell: ({ row }) => (
            <StatusTag status={row.original.isScoredAttempt ? 'Yes' : 'No'} />
          ),
        },
      ]}
    />
  );
};
