import React, { useCallback, useState } from 'react';

import { NotificationMessageBlock } from '@nl-lms/feature/notifications/sdk';
import {
  Button,
  FormField,
  SideModal,
  TextEditor,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';

import { NotificationEmailMessageInput } from '../notifications';
import { parseProsemirrorMentions } from './utils';

type NotificationTemplateEditSideModalFormProps =
  | {
      message: NotificationMessageBlock[];
      mentionsOptions: { id: string; label: string }[];
    }
  | {};

export const NotificationTemplateEditSideModal = (
  props: NotificationTemplateEditSideModalFormProps
) => {
  const [message, setMessage] = useState<NotificationMessageBlock[]>(
    'message' in props ? props.message : []
  );
  const [subject, setSubject] = useState<NotificationMessageBlock>(() => {
    const block =
      'message' in props
        ? (props.message.find(
            (b) => b.type === 'title'
          ) as unknown as NotificationMessageBlock)
        : { type: 'title', value: '' };
    return {
      ...block,
      value: parseProsemirrorMentions(block.value as string),
    };
  });
  const mentionsOptions =
    'mentionsOptions' in props ? props.mentionsOptions : [];
  const { hide } = useModal();
  const onSubmit = useCallback(() => {
    return hide({
      message: [...message.filter((block) => block.type !== 'title'), subject],
    });
  }, [message, subject]);

  const onChangeSubject = useCallback(
    (htmlValue, markdownValue) => {
      setSubject({ ...subject, value: markdownValue });
    },
    [setSubject, subject]
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Edit Email Template</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <FormField label="Subject">
          <TextEditor.Provider
            mentionOptions={mentionsOptions}
            onChange={onChangeSubject}
            value={subject.value as string}
          >
            <TextEditor.Input className="notification-subject-input" />
            <TextEditor.MentionsMenu />
          </TextEditor.Provider>
        </FormField>
        <FormField label="Message">
          <NotificationEmailMessageInput
            message={message}
            mentionOptions={mentionsOptions}
            onChange={setMessage}
          />
        </FormField>
      </SideModal.Body>
      <SideModal.Actions>
        <Button onClick={onSubmit} label="Submit" />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

export const useEditNotificationTemplateSideModal = () => {
  return useShowModal<
    { message: NotificationMessageBlock[] },
    NotificationTemplateEditSideModalFormProps
  >(NotificationTemplateEditSideModal);
};
