import { AppQuery, AppQueryFilter } from '@nl-lms/common/shared';

import { ApiService } from '../lib';
import { IApiService } from './types';

export class ActivityLog
  extends ApiService
  implements IApiService<'activityLog'>
{
  public paths: any;
  public serviceBase: string = `/resource/activity_log`;

  constructor(props) {
    super(props);

    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => this.serviceBase,
      listGrouped: () => `/list/activity_log/grouped`,
      listIndividual: () => '/list/activity_log',
      listSessionActivityLogs: (id) => `/session/${id}/activity_log`,
      create: () => this.serviceBase,
      update: (id) => `${this.serviceBase}/${id}`,
      batchUpdate: () => this.serviceBase,
      remove: (id) => `${this.serviceBase}/${id}`,
    };
  }

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery = {}) =>
    this.api.get(this.paths.list(), {
      params: { query },
    });

  listGrouped = (query: AppQuery | AppQueryFilter = {}) =>
    this.api.get(this.paths.listGrouped(), {
      params: { query },
    });

  listIndividual = (query: AppQuery = {}) =>
    this.api.get(this.paths.listIndividual(), {
      params: { query },
    });

  listSessionActivityLogs = ({ id }) =>
    this.api.get(this.paths.listSessionActivityLogs(id));

  create = (entity) => this.api.post(this.paths.create(), entity);

  update = (entity) => this.api.patch(this.paths.update(entity.id), entity);

  batchUpdate = (entities) =>
    this.api.patch(this.paths.batchUpdate(), { data: entities });

  remove = (entity: any) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };
}
