import { LearningAssignmentRule } from '@nl-lms/common/feature/types';
import {
  Box,
  FormField,
  NoDataPlaceholder,
  Typography,
} from '@nl-lms/ui/components';

import { LearningAssignmentRulesList } from './LearningAssignmentRulesList';
import { AssignationRule } from './utils/types';

type LearningAssignmentRulesListProps = {
  rules: LearningAssignmentRule[] | [];
  onDeleteRule?: (ruleId: string | number) => void;
};

export const LearningAssignmentRulesCreatePreview = ({
  rules,
  onDeleteRule,
}: LearningAssignmentRulesListProps) => {
  return (
    <FormField.Root>
      <Box flex={{ flexDirection: 'column' }}>
        <FormField.Label spacing="s">
          <Typography.h3>Rules that will be created</Typography.h3>
        </FormField.Label>
        {rules?.length > 0 ? (
          <LearningAssignmentRulesList
            rules={rules as AssignationRule[]}
            onDeleteRule={onDeleteRule}
          />
        ) : (
          <NoDataPlaceholder.Container>
            <NoDataPlaceholder.Icon
              name="ListIcon"
              size="small"
              color="default"
            />
            <NoDataPlaceholder.Title size="small">
              The created assignation rules will appear in this list
            </NoDataPlaceholder.Title>
          </NoDataPlaceholder.Container>
        )}
        <div></div>
      </Box>
    </FormField.Root>
  );
};
