import { useEffect } from 'react';

import { C } from '../constants';

export const setPageName = (name) => {
  let title = 'Nifty Learning';
  if (name) {
    title = name;
  }

  document.title = `[${C.NAME}] ${title}`;
};

export const usePageName = (name) => {
  useEffect(() => {
    setPageName(name);
  }, [name]);
};
