import React, { useMemo } from 'react';

import { AsyncSingleSelect, MultiSelect } from '@nl-lms/ui/components';
import { getSelectOptions } from '@nl-lms/ui/utils';

import { adminApi } from '../../../_common/services/api';
import { fetchAndMapSurveys } from '../../_common/utils/fetchEntitiesForSelectMethods';

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

const { useListSurveysForSelectQuery } = adminApi;

const buildLabel = (item) => {
  return item.name;
};

const parseOptions = (res) =>
  res.map((survey) => ({
    value: survey.id,
    item: survey,
    label: survey.name,
  }));

const parseSelectedItem = (selectedItem, options) => {
  if (!selectedItem) return null;
  if (typeof selectedItem === 'string') {
    return options.find((o) => selectedItem === o.value);
  } else return selectedItem;
};

export const AdminSurveySingleSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItem,
    hasError,
    returnEntireItemOnChange,
    disabled,
    selectedItem,
  } = props;
  // @ts-ignore
  const { data, isLoading } = useListSurveysForSelectQuery(null);
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data);
  }, [data]);

  // @ts-ignore
  const selectedValue = parseSelectedItem(selectedItem, options) || null;
  // @ts-ignore
  const initialSelectedValue =
    parseSelectedItem(initialSelectedItem, options) || null;

  const handleOnChangeInputValue = (value) => {
    if (!value) {
      onChange(null, '');
    }
  };

  return (
    <AsyncSingleSelect
      name={name}
      placeholder="Select survey form"
      onChange={onChange}
      onChangeInputValue={handleOnChangeInputValue}
      initialSelectedItem={initialSelectedValue}
      selectedItem={selectedValue}
      returnEntireItemOnChange={returnEntireItemOnChange}
      isClearable
      disabled={disabled}
      hasError={hasError}
      loadOptions={fetchAndMapSurveys}
      isLoading={isLoading}
    />
  );
};

export const AdminSurveyMultiSelect = (props) => {
  const { onChange, name, initialSelectedItems, selectedItems } = props;
  const { data, isLoading } = useListSurveysForSelectQuery('');
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data);
  }, [data]);

  return (
    <MultiSelect
      name={name}
      placeholder="Search surveys"
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={parseSelectedItems(initialSelectedItems, options)}
    />
  );
};

AdminSurveyMultiSelect.defaultProps = {
  initialSelectedItems: [],
  selectedItems: null,
};

AdminSurveySingleSelect.defaultProps = {
  initialSelectedItem: null,
  selectedItem: null,
};
