import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { LearningAssignmentRouterContract } from './learning-assignment.router-contract.sdk';

export const getLearningAssignmentClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'learningAssignmentStore';

  const tsRest = buildTsRestInstance<typeof LearningAssignmentRouterContract>(
    apiInstance,
    LearningAssignmentRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listLearningAssignments: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningAssignmentRouterContract.listLearningAssignments.query
          >
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.listLearningAssignments.responses)['200']
      >
    >;
    createLearningAssignment: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.createLearningAssignment.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.createLearningAssignment.responses)['200']
      >
    >;
    updateLearningAssignment: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignment.body
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignment.pathParams
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.updateLearningAssignment.responses)['200']
      >
    >;
    updateLearningAssignments: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignments.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.updateLearningAssignments.responses)['200']
      >
    >;
    removeLearningAssignments: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignments.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignments.responses)['200']
      >
    >;
    getLearningAssignment: RtkQuery<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.getLearningAssignment.pathParams
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.getLearningAssignment.responses)['200']
      >
    >;
    updateLearningAssignmentNotifications: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignmentNotifications.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignmentNotifications.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.updateLearningAssignmentNotifications.responses)['200']
      >
    >;
    addLearningAssignmentAudience: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentAudience.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentAudience.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.addLearningAssignmentAudience.responses)['200']
      >
    >;
    removeLearningAssignmentAudience: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentAudience.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentAudience.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignmentAudience.responses)['200']
      >
    >;
    listLearningAssignmentRules: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningAssignmentRouterContract.listLearningAssignmentRules.query
          >
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.listLearningAssignmentRules.responses)['200']
      >
    >;
    addLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.addLearningAssignmentRule.responses)['200']
      >
    >;
    addLearningAssignmentRules: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentRules.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.addLearningAssignmentRules.responses)['200']
      >
    >;
    removeLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignmentRule.responses)['200']
      >
    >;
    removeLearningAssignmentRules: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentRules.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignmentRules.responses)['200']
      >
    >;
    enableLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.enableLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.enableLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.enableLearningAssignmentRule.responses)['200']
      >
    >;
    disableLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.disableLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.disableLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.disableLearningAssignmentRule.responses)['200']
      >
    >;
    applyLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.applyLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.applyLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.applyLearningAssignmentRule.responses)['200']
      >
    >;
    updateLearningAssignmentRule: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignmentRule.pathParams
        >,
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignmentRule.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.updateLearningAssignmentRule.responses)['200']
      >
    >;
    listLearningAssignmentInstances: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningAssignmentRouterContract.listLearningAssignmentInstances.query
          >
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.listLearningAssignmentInstances.responses)['200']
      >
    >;
    addLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.addLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.addLearningAssignmentInstances.responses)['200']
      >
    >;

    updateLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.updateLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.updateLearningAssignmentInstances.responses)['200']
      >
    >;

    removeLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignmentInstances.responses)['200']
      >
    >;

    resetLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.resetLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.resetLearningAssignmentInstances.responses)['200']
      >
    >;

    cancelLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.cancelLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.cancelLearningAssignmentInstances.responses)['200']
      >
    >;

    listLearningAssignmentPlannedInstances: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningAssignmentRouterContract.listLearningAssignmentPlannedInstances.query
          >
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.listLearningAssignmentPlannedInstances.responses)['200']
      >
    >;
    scheduleLearningAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.scheduleLearningAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.scheduleLearningAssignmentInstances.responses)['200']
      >
    >;
    removeLearningAssignmentPlannedInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.removeLearningAssignmentPlannedInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.removeLearningAssignmentPlannedInstances.responses)['200']
      >
    >;
    rescheduleLearningAssignmentPlannedInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.rescheduleLearningAssignmentPlannedInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.rescheduleLearningAssignmentPlannedInstances.responses)['200']
      >
    >;
    forceResolveLearningAssignmentPlannedInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningAssignmentRouterContract.forceResolveLearningAssignmentPlannedInstances.body
        >
      >,
      z.infer<
        (typeof LearningAssignmentRouterContract.forceResolveLearningAssignmentPlannedInstances.responses)['200']
      >
    >;
  }>(tsRest, LearningAssignmentRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
