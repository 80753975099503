import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterOperator, QueryFilterCombinator } from '@nl-lms/common/shared';
import {
  Badge,
  BadgeGroup,
  BadgeTypes,
  Box,
  Button,
  Icon,
  Modal,
  SideModal,
  Table,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';

import { useApi } from '../../../../_common/services/api';
import { AdminAssessmentEditFormAddCategoryModal } from './AdminAssessmentEditFormAddCategoryModal';
import { AdminAssessmentEditFormAddQuestionModal } from './AdminAssessmentEditFormAddQuestionModal';
import './AdminAssessmentEditFormSideModal.scss';

const getFilterIdQuery = (qIds) => ({
  pagination: { disabled: true },
  filters: {
    id: '',
    value: [
      {
        id: '',
        value: {
          field: { field: 'id', label: '' },
          operator: FilterOperator.Includes,
          value: {
            label: '',
            value: qIds || [],
          },
        },
      },
    ],
  },
});

const getFilterTagIdsQuery = (tagIds) => ({
  id: '',
  value: [
    {
      id: '',
      value: {
        field: { field: 'tag_ids', label: '' },
        operator: FilterOperator.Contains,
        value: {
          label: '',
          value: tagIds || [],
        },
      },
    },
  ],
});

const buildFilterFromTagIds = (tagIds) => {
  const tagIdsFilter = tagIds.map((tagId) => {
    return {
      value: {
        field: 'tag_ids',
        value: [tagId],
        operator: FilterOperator.Contains.value,
      },
      combinator: QueryFilterCombinator.Or,
    };
  });
  tagIdsFilter[0] && delete tagIdsFilter[0].combinator;

  return {
    value: tagIdsFilter,
  };
};

const getQuestionIdsFromFormItems = (formItems) => {
  return formItems
    .map(({ assessmentQuestionId }) => assessmentQuestionId || undefined)
    .filter((e) => e);
};

const getFormItemsFromQuestionIds = (questionIds) => {
  return questionIds.map((qId) => ({
    type: C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.QUESTION,
    assessmentQuestionId: qId,
  }));
};

const getFormItemFromCategory = (filter) => {
  return {
    type: C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.FILTER,
    questionFilter: buildFilterFromTagIds(filter.tagIds),
    questionCount: filter.count,
    tagIds: filter.tagIds,
  };
};

export const AdminAssessmentEditFormQuestionsSection = ({
  initialItems,
  onChange,
  errors,
  getValues,
}) => {
  const api = useApi();
  const values = getValues();
  const [formItems, setFormItems] = useState(initialItems || []);
  const [removeRow, setRemoveRow] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [tags, setTags] = useState([]);

  const onAddSingleQuestion = useCallback(
    (questionIds) => {
      setFormItems([...formItems, ...getFormItemsFromQuestionIds(questionIds)]);
    },
    [formItems]
  );

  const onAddCategory = useCallback(
    (category) => {
      setFormItems([...formItems, getFormItemFromCategory(category)]);
    },
    [formItems]
  );

  const fetchTags = useCallback(async () => {
    const _tags = (await api.tag.listForSelect(
      'assessment_question'
    )) as any as [];
    setTags(_tags);
  }, []);

  const fetchTableRow = useCallback(
    async (formItem, questions) => {
      if (formItem.assessmentQuestionId) {
        const question = questions.find(
          (q) => q.id === formItem.assessmentQuestionId
        );
        if (!question) return;
        return { ...question, id: _.uniqueId() };
      } else {
        const tagIds = formItem?.questionFilter?.value
          ?.map((filter) => filter?.value?.value)
          .filter((r) => r)
          .flat()
          .filter((r) => r) as any[];

        let tagQuestionCount = 0;
        await Promise.all(
          tagIds.map(async (tag) => {
            const { count } = (await api.assessmentQuestion.list({
              pagination: { offset: 1, limit: 1 },
              filters: getFilterTagIdsQuery([tag]),
            })) as any;
            tagQuestionCount += count;
          })
        );

        // @ts-ignore
        let itemTags = tags.filter((tag) => tagIds.includes(tag.id));

        // @ts-ignore
        let title = `${itemTags.map((i) => i.title).join(', ')} (${
          formItem.questionCount
        }/${tagQuestionCount})`;

        return { id: _.uniqueId(), title, tags: itemTags };
      }
    },
    [tags]
  );

  const fetchTableRows = useCallback(async () => {
    const { rows: questions } = (await api.assessmentQuestion.list(
      getFilterIdQuery(getQuestionIdsFromFormItems(formItems))
    )) as any;

    const _tableRows = [];
    for await (const formItem of formItems) {
      let _tableRow = await fetchTableRow(formItem, questions);
      _tableRow = {
        ..._tableRow,
        assessmentQuestionId: formItem.assessmentQuestionId,
        type: formItem.type,
        questions: formItem.assessmentQuestionId ? 1 : formItem.questionCount,
      };
      // @ts-ignore
      _tableRows.push(_tableRow);
    }
    setTableRows(_tableRows);
  }, [formItems, fetchTableRow]);

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    fetchTableRows();
  }, [tags, formItems]);

  useEffect(() => {
    onChange && onChange(formItems);
  }, [formItems]);

  useEffect(() => {
    if (removeRow) {
      let updatedFormItems = [];
      // @ts-ignore
      if (removeRow.type === C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.QUESTION) {
        updatedFormItems = formItems.filter(
          (item) =>
            // @ts-ignore
            item?.assessmentQuestionId !== removeRow?.assessmentQuestionId
        );
      } else if (
        // @ts-ignore
        removeRow.type === C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.FILTER
      ) {
        const parsedRowItemTags = JSON.stringify(
          // @ts-ignore
          removeRow.tags.map((el) => el.id)
        );
        updatedFormItems = formItems.filter(
          (item) => JSON.stringify(item?.tagIds) !== parsedRowItemTags
        );
      }
      setFormItems(updatedFormItems);
    }
    setRemoveRow(null);
  }, [removeRow]);

  const totalQuestions = useMemo(
    // @ts-ignore
    () => tableRows.reduce((acc, item) => acc + item.questions, 0),
    [tableRows]
  );

  return (
    <>
      <Typography.h2>Questions ({totalQuestions})</Typography.h2>
      <Box>
        <Box>
          <Table
            data={tableRows}
            // @ts-ignore
            rowActions={
              !values.id
                ? [
                    {
                      name: 'Delete',
                      handler: setRemoveRow,
                      Icon: Icon.DeleteIcon,
                    },
                  ]
                : []
            }
            columns={[
              {
                Header: 'Type',
                accessor: 'type',
                sortField: 'type',
                Cell: ({ row }) =>
                  C.ASSESSMENT_FORM_QUESTION_ITEM_TYPES[row.original.type] ===
                  'FILTER'
                    ? 'CATEGORY'
                    : 'QUESTION',
              },
              {
                Header: 'Title',
                accessor: 'title',
                sortField: 'title',
                Cell: ({ row }) => row.original.title,
              },
              {
                Header: 'Questions',
                accessor: 'questions',
                sortField: 'questions',
                Cell: ({ row }) => row.original.questions,
              },
              {
                Header: 'Tags',
                accessor: 'tags',
                Cell: ({ row }) => (
                  <BadgeGroup
                    name="adminAssessmentEditFormTags"
                    type={BadgeTypes.LIGHT}
                  >
                    {row.original.tags.map((tag) => (
                      <Badge
                        label={
                          tag.scope ? `${tag.scope}:${tag.title}` : tag.title
                        }
                        type={BadgeTypes.LIGHT}
                      />
                    ))}
                  </BadgeGroup>
                ),
              },
            ]}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <div className="admin-assessment-edit-form__questions-section__buttons">
            <Modal.Provider>
              <Modal.Trigger>
                <Button regular label={'Add Category'} disabled={values.id} />
              </Modal.Trigger>
              <AdminAssessmentEditFormAddCategoryModal
                onSubmit={onAddCategory}
              />
            </Modal.Provider>

            <Modal.Provider>
              <Modal.Trigger>
                <Button regular label={'Add Question'} disabled={values.id} />
              </Modal.Trigger>
              <AdminAssessmentEditFormAddQuestionModal
                onSubmit={onAddSingleQuestion}
                excludedQuestionIds={getQuestionIdsFromFormItems(formItems)}
              />
            </Modal.Provider>
          </div>
        </Box>
      </Box>
      {errors?.items?.message && (
        <Box>
          <div className="admin-assessment-edit-form__questions-section__error">
            questions is a mandatory field for the assessment form
          </div>
        </Box>
      )}
    </>
  );
};
