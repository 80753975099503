import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { IApiMethods, IApiService, ListResponse, Venue } from './types';

export class Room
  extends ApiService
  implements IApiService<'venue'>, IApiMethods<'venue'>
{
  static serviceBase = '/resource/venue';
  paths = {
    get: (id) => `${Room.serviceBase}/${id}`,
    list: () => Room.serviceBase,
    create: () => Room.serviceBase,
    update: (id) => `${Room.serviceBase}/${id}`,
    bulkDelete: () => Room.serviceBase,
    remove: (id) => `${Room.serviceBase}/${id}`,
    listVendors: () => `/list/venue/vendors`,
  };

  get = (id) => this.api.get<null, Venue>(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get<null, ListResponse<Venue>>(this.paths.list(), {
      params: { query },
    });

  create = (entity) =>
    this.api.post<null, boolean>(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch<null, boolean>(
      this.paths.update(entity.id),
      snakelize(entity)
    );

  bulkDelete = (ids) =>
    this.api.delete<null, boolean>(this.paths.bulkDelete(), {
      data: { list: ids },
    });

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };

  listVendors = () => this.api.get(this.paths.listVendors());
}
