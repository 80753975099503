import React from 'react';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { TidComponent } from '../index.types';

import './Separator.scss';

type SeparatorProps = TidComponent<{
  margin?: number;
  marginBottom?: number;
  marginTop?: number;
  backgroundColor?: string;
  transparent?: boolean;
  className?: string;
}>;
export const Separator = ({
  margin = 30,
  marginBottom = undefined,
  marginTop = undefined,
  backgroundColor = '#0B0B0D',
  transparent = false,
  ...props
}: SeparatorProps) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('separator', props);
  return (
    <div
      {...classNameProps}
      {...commonProps}
      style={{
        background: transparent ? 'transparent' : backgroundColor,
        marginTop: `${marginTop === undefined ? margin : marginTop}px`,
        marginBottom: `${marginBottom === undefined ? margin : marginBottom}px`,
      }}
    />
  );
};
