import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FieldType,
  FieldTypeToOperatorsRecord,
  NumberFieldMetadata,
  OperatorToLabelRecord,
  QueryOperator,
} from '@nl-lms/common/shared';
import { _ } from '@nl-lms/vendor';

import {
  FilterBarOptionsComponentProps,
  NumberValueOption,
} from '../FilterBar.types';
import { getOptionClassName, getOptionId } from '../filterUtils';

export const FilterBarNumberFieldSuggestOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<NumberFieldMetadata>) => {
  const { t } = useTranslation('learner');

  // is not float
  if (!_.isNumeric(inputValue)) return null;

  const option: NumberValueOption = {
    id: getOptionId(
      `${QueryOperator.GreaterThan}-${parseFloat(inputValue)}`,
      field.name,
      'NumberValueOption'
    ),
    type: 'NumberValueOption',
    field,
    operator: QueryOperator.GreaterThan,
    value: parseFloat(inputValue),
    label: `${t(
      // @ts-ignore
      `common.filterbar.${
        OperatorToLabelRecord[QueryOperator.GreaterThan]
      }` as const
    )} "${inputValue}"`,
  };

  return (
    <>
      <span className="filter-bar__option-separator">{field.label}</span>
      <li
        className={getOptionClassName(option.id === activeOptionId)}
        {...getOptionProps(option, option.id)}
      >
        {option.label}
      </li>
    </>
  );
};

export const FilterBarNumberFieldOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<NumberFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const b = t('common.categories');
  const operatorOptions = useMemo<NumberValueOption[]>(() => {
    return _.isNumeric(inputValue)
      ? FieldTypeToOperatorsRecord[FieldType.number].map((operator) => ({
          id: getOptionId(
            `${operator}-${inputValue}`,
            field.name,
            'NumberValueOption'
          ),
          field,
          type: 'NumberValueOption',
          value: parseFloat(inputValue),
          label: `${t(
            // @ts-ignore
            `common.filterbar.${OperatorToLabelRecord[operator]}`
          )} "${inputValue}"`,
          operator: operator,
        }))
      : [];
  }, [inputValue, field]);

  return (
    <>
      <span className="filter-bar__option-separator">
        {t('common.filterbar.values')}
      </span>

      {_.isNumeric(inputValue) ? (
        operatorOptions.map((option) => (
          <li
            className={getOptionClassName(option.id === activeOptionId)}
            {...getOptionProps(option, option.id)}
            key={option.id}
          >
            {option.label}
          </li>
        ))
      ) : (
        <li className={getOptionClassName(false)}>
          {t('common.filterbar.invalidvalue')}
        </li>
      )}
    </>
  );
};
