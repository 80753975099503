import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';

import { useTracker } from '@nl-lms/ui/modules';

export const useHotkey = (key, cb) => {
  const track = useTracker();
  const wrappedCb = useCallback(
    (...props) => {
      track.event('hotkey_clicked', 'useHotkey', { hotkey: key });

      cb(...props);
      return false;
    },
    [cb]
  );
  useEffect(() => {
    hotkeys(key, wrappedCb);
    return () => hotkeys.unbind(key);
  }, [key, wrappedCb]);
};
