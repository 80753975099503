import * as z from 'zod';

import { CreateDashboardPayload, Dashboard } from './dashboard.types.sdk';

const WidgetPosition = z.object({
  x: z.number(),
  y: z.number(),
  w: z.number(),
  h: z.number(),
  minH: z.number().optional(),
  minW: z.number().optional(),
  maxW: z.number().optional(),
  maxH: z.number().optional(),
});

const WidgetSchema = z.object({
  label: z.string(),
  type: z.string(),
  position: WidgetPosition,
  payload: z.record(z.unknown()),
});

export const CreateDashboardSchema: z.ZodType<CreateDashboardPayload> =
  z.object({
    label: z.string(),
    widgets: z.array(WidgetSchema),
    targetLearnerGroupIds: z.array(z.string()),
  });

export const DashboardSchemaSdk: z.ZodType<
  Omit<Dashboard, 'creator'> & {
    creator: { id: string; firstName: string; lastName: string; email: string };
  }
> = z
  .object({
    id: z.string(),
    label: z.string(),
    widgets: z.array(WidgetSchema),
    userId: z.string(),
    creator: z.object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
    }),
    targetLearnerGroupIds: z.array(z.string()),
    createdAt: z.date(),
    updatedAt: z.date(),
  })
  .required();

export const ListDashboardsSchema = z.array(DashboardSchemaSdk);
