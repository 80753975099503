import { ApiFetcher } from '@ts-rest/core';
import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { isBrowser } from 'browser-or-node';
import LZString from 'lz-string';

import { ApiQuery, AppQuery, transformQuery } from '@nl-lms/common/shared';

import {
  queryTransform,
  setAuthTokenHandler,
  setSessionSpanIdHandler,
} from './interceptors';

export type ApiClientOptions = {
  errorHandler?: any;
  warningHandler?: any;
  tokenGetter: () => string;
};

// @ts-ignore
export const getTsRestAxiosInstance: (
  baseUrl: string,
  options: ApiClientOptions
) => ApiFetcher = (apiBaseUrl, options) => {
  const apiInstance = axios.create({
    baseURL: apiBaseUrl,
  });

  // const _errorHandler = errorHandler(options);
  apiInstance.defaults.headers.common['Content-Type'] = 'application/json';
  apiInstance.interceptors.request.use(
    // @ts-ignore
    setSessionSpanIdHandler
  );
  apiInstance.interceptors.request.use(
    // @ts-ignore
    setAuthTokenHandler(options.tokenGetter)
  );

  apiInstance.interceptors.request.use(
    // @ts-ignore
    setAuthTokenHandler(options.tokenGetter)
  );

  // @ts-ignore
  apiInstance.interceptors.request.use(queryTransform);

  return async ({ path, method, headers, body }) => {
    try {
      const result = await apiInstance.request({
        method,
        url: path,
        headers,
        data: body,
      });
      const parsedData = parseData(result);
      if (result.data.warnings && result.data.warnings.length) {
        options.warningHandler && options.warningHandler(result.data.warnings);

        if (isBrowser) {
          document.dispatchEvent(
            new CustomEvent('onWarning', { detail: result.data.warnings })
          );
        }
      }

      return { status: 200, body: parsedData, headers: result.headers };
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        const parsedError = parseError(error);
        return {
          status: error.response?.status as number,
          body: parsedError,
          // @ts-ignore
          headers: e.response.headers,
        };
      }
      throw e;
    }
  };
};

type ApiError = { message: string; name: string };
const parseError = (error: AxiosError) => {
  let parsedError: ApiError;
  if (error.response) {
    const responseData = error.response?.data as { error: ApiError };
    parsedError = responseData?.error || {
      message: 'An unknown error occurred. Please contact your administrator.',
      name: 'UNKNOWN_ERROR',
    };
  } else {
    parsedError = error;
  }
  return parsedError;
};

const parseData = (response: AxiosResponse) => {
  let parsedData = response.data;
  if (response?.data?.data || response?.data?.data === 0) {
    parsedData = response.data.data;
  }

  return parsedData;
};

export const transformTsRestQuery = (query: AppQuery) => {
  return LZString.compressToEncodedURIComponent(
    JSON.stringify(transformQuery(query))
  ) as unknown as ApiQuery;
};
