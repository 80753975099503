import React from 'react';

import './Illustration.scss';
import { useTestProps } from '@nl-lms/ui/hooks';
import { TidComponent } from '../index.types';

type IllustrationName =
  | 'briefcase'
  | 'broken-mug'
  | 'browser'
  | 'calculator'
  | 'gears'
  | 'light-bulb'
  | 'magnifier'
  | 'man-applauding'
  | 'man-browser-reload'
  | 'man-cup-idea'
  | 'man-facepalm-desk'
  | 'man-looking-at-phone-with-checkmark-bubble'
  | 'man-mug-chat-bubble'
  | 'man-thinking-charts'
  | 'man-thinking'
  | 'man-thumbs-up'
  | 'man-walking'
  | 'man-with-idea'
  | 'board-cut'
  | 'man-with-phone-and-bullet-list'
  | 'megafon'
  | 'notebook'
  | 'paper-cup'
  | 'paper-graph-chart'
  | 'paper-pie-chart'
  | 'paper-scribble'
  | 'pencil'
  | 'post-it'
  | 'present'
  | 'speech-balloon-bottom-right'
  | 'speech-balloon-bottom'
  | 'speech-balloon-top'
  | 'tablet'
  | 'woman-applauding-check-marks'
  | 'woman-browser'
  | 'woman-coffee-speech-bubble'
  | 'woman-facepalm'
  | 'woman-idea-man-facepalm'
  | 'woman-phone-man-waving'
  | 'woman-phone-speech-bubble'
  | 'woman-waving';

export const Illustration = (
  props: TidComponent<{
    name: IllustrationName;
    size?: 's' | 'm' | 'l' | 'xl';
    className?: string;
  }>
) => {
  const commonProps = useTestProps(props);
  const { name, size = 'm', className = '' } = props;
  return (
    <img
      alt={name}
      className={`illustration illustration--${size} ${className}`}
      src={`https://storage.niftylearning.app/common/illustrations/${name}.png`}
      {...commonProps}
    />
  );
};
