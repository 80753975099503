import { z } from 'zod';

import {
  AssessmentFormSchema,
  AssessmentQuestionSchema,
  ElearningCourseSchema,
  LearnerSchema,
  LiveSessionSchema,
  RegistrationPayloadSchema,
  RegistrationSchema,
  SurveyFormQuestionSchema,
  SurveyFormSchema,
} from '@nl-lms/common/feature/types';

export const RegisterPayloadSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  payload: RegistrationPayloadSchema,
});

export const ListRegistrationSchema = RegistrationSchema.extend({
  learner: LearnerSchema.nullable(),
  liveSession: LiveSessionSchema.nullable(),
  elearningCourse: ElearningCourseSchema.nullable(),
  assessmentForm: AssessmentFormSchema.nullable(),
  surveyForm: SurveyFormSchema.nullable(),
  payload: z.object({
    ...RegistrationPayloadSchema.shape,
    answers: z.array(
      z.object({
        ...RegistrationPayloadSchema.shape.answers.element.shape,
        question: AssessmentQuestionSchema,
      }),
    ),
  }),
});
export type ListRegistration = z.infer<typeof ListRegistrationSchema>;

export const GetReigstrationPublicDataSchema = z.object({
  surveyForm: SurveyFormSchema.extend({
    questions: z.array(
      SurveyFormQuestionSchema.extend({
        question: AssessmentQuestionSchema,
      }),
    ),
  }),
});

export type GetRegistrationPublicData = z.infer<
  typeof GetReigstrationPublicDataSchema
>;
