import React from 'react';
import { useTestProps } from '@nl-lms/ui/hooks';

export const Emoji = ({ label = '', symbol, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label}
      aria-hidden={label ? 'false' : 'true'}
      {...commonProps}
    >
      {symbol}
    </span>
  );
};
