import { ResponsivePie } from '@nivo/pie';
import React from 'react';

import { Card, NoDataPlaceholder } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi } from '../../../_common/services/api';

const parseTrainingProgress = (data) => {
  let parsedData = {};
  _.each(data, (row) => {
    parsedData[C.SESSION_STATUSES[row.status].toLowerCase()] = Number.parseInt(
      row.statusTotal,
      10
    );
  });

  return parsedData;
};

const COLORS_MAP = {
  processing: '#F5A623',
  canceled: '#D0021B',
  draft: '#E8EBEC',
  completed: '#00A470',
  ready: '#003952',
};

const getColor = (node) => COLORS_MAP[node.id];
const getData = (data) =>
  _.map(data, (statusCount, statusName) => ({
    id: statusName,
    label: _.startCase(statusName),
    value: statusCount,
  }));

export const AdminAnalyticsTrainingProgressCard = ({ filters }) => {
  const { useGetSessionActivityProgressQuery } = adminApi;
  const {
    isLoading,
    data: trainingProgressRes,
    error,
  } = useGetSessionActivityProgressQuery({});
  useQueryErrorHandler({ error });

  const trainingProgress = parseTrainingProgress(trainingProgressRes);

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Learning Activity Progress</Card.HeaderTitle>
      </Card.Header>
      <Card.Content style={{ height: '200px' }}>
        {Object.keys(trainingProgress).length ? (
          <ResponsivePie
            data={getData(trainingProgress)}
            innerRadius={0.3}
            padAngle={0.7}
            colors={getColor}
            borderWidth={0}
            cornerRadius={0}
            animate
            margin={{ left: 100 }}
            enableArcLinkLabels={false}
            legends={[
              {
                anchor: 'left',
                direction: 'column',
                translateY: 0,
                translateX: -100,
                itemWidth: 100,
                itemsSpacing: 16,
                itemHeight: 18,
                itemTextColor: '#0E1B21',
                symbolSize: 22,
                symbolShape: 'circle',
              },
            ]}
          />
        ) : (
          <NoDataPlaceholder
            iconName="PieChartIcon"
            title="There is no learning activity data available"
          />
        )}
      </Card.Content>
    </Card>
  );
};
