import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ReportDataColumnSchema,
  ReportQuerySchema,
  ReportSchema,
  ReportTemplateSchema,
} from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
  EmptyObjectSchema,
  SdkEndpointType,
} from '@nl-lms/common/sdk';
import {
  ApiQueryFilterSchema,
  ApiQuerySchema,
  AppQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import {
  ReportsRouterPrefix,
  StatisticsRouterPrefix,
} from './report.constants.sdk';
import {
  CreateReportTemplateSchema,
  GetLearnerAttendanceSchema,
  GetLearnerLearnedTimeSchema,
  GetLiveCourseNpsSchema,
  LearnerStatisticsParentFiltersSchema,
  ListLearnerStatisticsSchema,
  ListLiveCourseStatisticsSchema,
  ListReportTemplateSchema,
  LiveLearningStatisticsParentFiltersSchema,
  PaginatedReportQuerySchema,
  ReportIdParamSchema,
  ReportTemplateIdParamSchema,
  ReportTemplateNameSchema,
  ScheduleReportQuerySchema,
  UpdateReportQuerySchema,
} from './report.schema.sdk';

const contract = initContract();

export const ReportsRouterContract = contract.router({
  listReports: {
    method: 'GET',
    path: `${ReportsRouterPrefix}/`,
    responses: ContractPaginatedResponse(ReportSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  deleteReports: {
    method: 'DELETE',
    path: `${ReportsRouterPrefix}/`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  getReportData: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/`,
    responses: ContractPaginatedResponse(z.record(z.unknown())),
    body: PaginatedReportQuerySchema,
    description: SdkEndpointType.Query,
  },
  listReportTemplates: {
    method: 'GET',
    path: `${ReportsRouterPrefix}/template`,
    responses: ContractPaginatedResponse(ListReportTemplateSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  selectReportTemplates: {
    method: 'GET',
    path: `${ReportsRouterPrefix}/template/select`,
    responses: ContractPaginatedResponse(ReportTemplateSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createReportTemplate: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/template`,
    responses: ContractStandardResponse(ReportTemplateSchema),
    body: CreateReportTemplateSchema,
  },
  deleteReportTemplates: {
    method: 'DELETE',
    path: `${ReportsRouterPrefix}/template`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  updateReportTemplate: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/template/:reportTemplateId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: CreateReportTemplateSchema,
    pathParams: ReportTemplateIdParamSchema,
  },
  updateReportTemplateColumns: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/template/:reportTemplateId/columns`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({ columns: z.array(ReportDataColumnSchema) }),
    pathParams: ReportTemplateIdParamSchema,
  },
  generateReport: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/generate`,
    responses: ContractStandardResponse(ReportSchema),
    body: ReportQuerySchema,
  },
  generateAndDownloadReport: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/generate/download`,
    responses: ContractStandardResponse(z.object({ signedUrl: z.string() })),
    body: z.object({
      name: ReportTemplateNameSchema,
      query: AppQuerySchema,
    }),
  },
  scheduleReport: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/schedule`,
    responses: ContractStandardResponse(ReportSchema),
    body: ScheduleReportQuerySchema,
  },
  getReport: {
    method: 'GET',
    path: `${ReportsRouterPrefix}/:reportId`,
    responses: ContractStandardResponse(ReportSchema),
    pathParams: ReportIdParamSchema,
  },
  updateReport: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/:reportId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateReportQuerySchema,
    pathParams: ReportIdParamSchema,
  },
  downloadReport: {
    method: 'GET',
    path: `${ReportsRouterPrefix}/:reportId/download`,
    responses: ContractStandardResponse(z.object({ signedUrl: z.string() })),
    pathParams: ReportIdParamSchema,
  },
  buildReport: {
    method: 'POST',
    path: `${ReportsRouterPrefix}/:reportId/build`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: EmptyObjectSchema,
    pathParams: ReportIdParamSchema,
  },

  listLiveCourseStatistics: {
    method: 'GET',
    path: `${StatisticsRouterPrefix}/live-course/list`,
    responses: ContractPaginatedResponse(ListLiveCourseStatisticsSchema),
    query: z.object({
      query: ApiQuerySchema,
      parentFilters: LiveLearningStatisticsParentFiltersSchema,
    }),
  },
  getLiveCourseNps: {
    method: 'GET',
    path: `${StatisticsRouterPrefix}/live-course/nps`,
    responses: ContractStandardResponse(GetLiveCourseNpsSchema),
    query: z.object({
      query: ApiQuerySchema,
      parentFilters: LiveLearningStatisticsParentFiltersSchema,
    }),
  },

  listLearnerStatistics: {
    method: 'GET',
    path: `${StatisticsRouterPrefix}/learner/list`,
    responses: ContractPaginatedResponse(ListLearnerStatisticsSchema),
    query: z.object({
      query: ApiQuerySchema,
      parentFilters: LearnerStatisticsParentFiltersSchema,
    }),
  },
  getLearnerAttendance: {
    method: 'GET',
    path: `${StatisticsRouterPrefix}/learner/attendance`,
    responses: ContractStandardResponse(GetLearnerAttendanceSchema),
    query: z.object({
      query: ApiQuerySchema,
      parentFilters: LearnerStatisticsParentFiltersSchema,
    }),
  },
  getLearnerLearnedTime: {
    method: 'GET',
    path: `${StatisticsRouterPrefix}/learner/time`,
    responses: ContractStandardResponse(GetLearnerLearnedTimeSchema),
    query: z.object({
      query: ApiQuerySchema,
      parentFilters: LearnerStatisticsParentFiltersSchema,
    }),
  },
});
