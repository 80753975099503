import { useCallback, useEffect, useState, MutableRefObject } from 'react';

export const useElementSize = (
  elementRef: MutableRefObject<HTMLElement>,
  initialSize = { width: undefined, height: undefined }
) => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [elementSize, setElementSize] = useState<{
    width?: number;
    height?: number;
  }>(initialSize);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const onWindowResize = useCallback(() => {
    if (elementRef?.current) {
      const node = elementRef.current;
      if (node instanceof HTMLElement && node.offsetWidth) {
        setElementSize({ width: node.offsetWidth, height: node.offsetHeight });
      }
    }
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      onWindowResize();
    }
  }, [isInitialRender]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);
  return elementSize;
};
