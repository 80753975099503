import { FunctionComponent } from 'react';

export enum NotificationTypes {
  info = 'info',
  alert = 'alert',
  success = 'success',
  warning = 'warning',
}

export type ComponentNotificationModel = {
  type: NotificationTypes;
  id: string;
  timestamp: string;
  visible: boolean;
  duration: number;
  Component: FunctionComponent;
};

export type DefaultNotificationModel = {
  type: NotificationTypes;
  id: string;
  timestamp: string;
  visible: boolean;
  duration: number;
  code?: number;
  message: string;
  details?: string;
  link?: string;
};
export type NotificationModel = DefaultNotificationModel;

export type NotificationProps = {
  notification: NotificationModel;
  onClose: () => void;
};
