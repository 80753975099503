import { useEffect, useRef, useState } from 'react';

type MediaQuery =
  | `max-width: ${number}px`
  | `min-width: ${number}px`
  | `max-height: ${number}px`
  | `min-height: ${number}px`;

export function useMediaQuery(
  mediaQueryLiteral: MediaQuery,
  callbackFn?: () => void
): boolean {
  const mediaQueryRef = useRef<MediaQueryList>(
    window.matchMedia(`(${mediaQueryLiteral})`)
  );
  const [matches, setMatches] = useState(() => {
    return mediaQueryRef.current.matches;
  });

  useEffect(() => {
    const handleChange = (event: MediaQueryListEvent) => {
      if (callbackFn && event.matches) {
        callbackFn();
      }
      setMatches(event.matches);
    };

    if (callbackFn && mediaQueryRef.current.matches) {
      callbackFn();
    }
    mediaQueryRef.current.addEventListener('change', handleChange);
    return () => {
      mediaQueryRef.current.removeEventListener('change', handleChange);
    };
  }, []);

  return matches;
}
