import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { getStatusColor } from '../../utils/getStatusColor';
import { Badge, BadgeTypes } from '../Badge/Badge';
import { TidComponent } from '../index.types';

type StatusTagProps = TidComponent<{
  status?: string;
  onClick?: (e: any) => void;
  className?: string;
  style?: React.CSSProperties;
}>;

export const StatusTag = (props: StatusTagProps) => {
  const { className = '', status = '', onClick = null, style = {} } = props;

  const tagType = getStatusColor(status) as BadgeTypes;

  const commonProps = useTestProps(props, { passThrough: true });

  const { t } = useTranslation('learner');
  // @ts-ignore
  const parsedStatus = _.capitalize(t(`common.statustag.${_.toLower(status)}`));
  if (!status) return null;

  return (
    <Badge
      // @ts-ignore
      onClick={onClick}
      label={parsedStatus}
      type={tagType}
      style={style}
      className={className}
      {...commonProps}
    />
  );
};
