import {
  useActive,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useHelpers,
} from '@remirror/react';
import React, { useCallback, useState } from 'react';

import { Button } from '../Button/Button';
import { FormField } from '../FormField/FormField';
import * as Icon from '../Icon';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';

/**
 * Known issue:
 * After initial render type in a couple of letters.
 * Press cmd + a to select all text
 * Press the link icon and add a link
 * The link will cover all the text minus the last letter
 */
export const TextEditorEditLinkButton = () => {
  const chain = useChainedCommands();
  const { getTextBetween } = useHelpers();
  const [textToDisplay, setTextToDisplay] = useState('');
  const [href, setHref] = useState('');
  const { from, to } = useCurrentSelection();
  const active = useActive();
  const isActive = active.link();
  const attrs = useAttrs();
  const [error, setError] = useState('');

  const initFormState = useCallback(() => {
    const selection = getTextBetween(from, to);
    const activeLink = attrs.link()?.href as string;
    if (activeLink) {
      setHref(activeLink);
    } else if (selection && isValidUrl(selection)) {
      setHref(selection);
    }
    setTextToDisplay(selection);
  }, [from, to, attrs]);

  const onChangeInput = useCallback((e) => {
    const inputName = e.target.name;
    if (inputName === 'href') setHref(e.target.value);
    else if (inputName === 'textToDisplay') setTextToDisplay(e.target.value);
  }, []);
  const resetFormState = useCallback(() => {
    setTextToDisplay('');
    setHref('');
  }, []);
  const onSubmit = useCallback(
    (e) => {
      if (!textToDisplay || !href) return;
      if (!isValidUrl(href)) {
        setError('Invalid Link');
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else {
        setError('');
      }
      if (from !== to) {
        chain.insertText('', { from, to });
      }
      chain
        .insertText(textToDisplay, { from })
        .updateLink(
          { href, auto: false },
          { from, to: textToDisplay.length + from }
        )
        .focus()
        .run();
    },
    [textToDisplay, href, to, from]
  );

  const isSubmitDisabled = !href || !textToDisplay;
  return (
    <div
      className={`texteditor__toggle-button ${
        isActive ? 'texteditor__toggle-button--active' : ''
      }`}
      title="Edit Link"
    >
      <Modal.Provider onClose={resetFormState}>
        <Modal.Trigger>
          <button onClick={initFormState}>
            <Icon.LinkIcon />
          </button>
        </Modal.Trigger>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Edit Link</Modal.Title>
          </Modal.Header>
          <FormField label="Text to display">
            <Input
              name="textToDisplay"
              value={textToDisplay}
              onChange={onChangeInput}
            />
          </FormField>
          <FormField label="Link" errorMessage={error}>
            <Input
              name="href"
              value={href}
              onChange={onChangeInput}
              hasError={!!error}
            />
          </FormField>
          <Modal.Actions>
            <Modal.Close disabled={isSubmitDisabled}>
              <Button label="Submit" onClick={onSubmit} />
            </Modal.Close>
          </Modal.Actions>
        </Modal.Content>
      </Modal.Provider>
    </div>
  );
};

const isValidUrl = (url) => {
  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const TextEditorRemoveLinkButton = () => {
  const chain = useChainedCommands();
  const active = useActive(true);
  const { getCommandOptions } = useHelpers();
  const options = getCommandOptions('removeLink');
  const onClick = useCallback(() => {
    chain.removeLink().focus().run();
  }, []);
  // @ts-ignore
  const isActive = active[options.name] ? active[options.name]() : false;
  return (
    <button
      onClick={onClick}
      className={`texteditor__toggle-button ${
        isActive ? 'texteditor__toggle-button--active' : ''
      }`}
      title="Remove Link"
    >
      <Icon.LinkRemoveIcon />
    </button>
  );
};
