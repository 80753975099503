import { TagId } from './types';

export const getTagsFunctions = <T>() => ({
  getProvidesTagsFunction: (tagType: T, tagId: TagId = 'LIST') => {
    return (result, error, arg) => {
      // If argument is string (aka resource id) then we're dealing with a get by id API call
      if (typeof arg === 'string') return [{ type: tagType, id: arg }];
      return [{ type: tagType, id: tagId }];
    };
  },
  getInvalidatesTagsFunction:
    (
      tag: T,
      extraTags?:
        | T[]
        | ((
            result: unknown,
            error: unknown,
            arg: Record<string, unknown>
          ) => { type: T; id: string }[])
    ) =>
    (result, error, arg) => {
      if (error) return [];
      let baseTags = [];
      if (extraTags && Array.isArray(extraTags)) {
// @ts-ignore
        baseTags = extraTags
          .map((t) => [
            { type: t, id: 'LIST' },
            { type: t, id: 'LIST_FOR_SELECT' },
          ])
          .flat();
      }
      if (extraTags && !Array.isArray(extraTags)) {
// @ts-ignore
        baseTags = extraTags(result, error, arg);
      }
      // Edit Resource
      if (arg?.id) {
        return [
          ...baseTags,
          { type: tag, id: arg.id },
          { type: tag, id: 'LIST' },
          { type: tag, id: 'LIST_FOR_SELECT' },
        ];
      }

      // Bulk Delete Resource
      if (Array.isArray(arg) && typeof arg[0] === 'string') {
        return [
          ...arg.map((id) => ({
            id,
            type: tag,
          })),
          ...baseTags,
          { type: tag, id: 'LIST' },
          { type: tag, id: 'LIST_FOR_SELECT' },
        ];
      }

      if (typeof arg === 'string') {
// @ts-ignore
        baseTags.push({ type: tag, id: arg });
      }
      // Create Resource
      return [
        ...baseTags,
        { type: tag, id: 'LIST' },
        { type: tag, id: 'LIST_FOR_SELECT' },
      ];
    },
});
