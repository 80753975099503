import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';

import { RadioButton } from '../RadioButton/RadioButton';
import { TidComponent } from '../index.types';
import './RadioButtonGroup.scss';

type RadioButtonGroupOption = TidComponent<{
  value: string;
  label: string;
  disabled?: boolean;
  hasError?: boolean;
}>;

type RadioButtonGroupProps = TidComponent<{
  name: string;
  onChange?: any;
  value?: string;
  options: RadioButtonGroupOption[];
  readOnly?: boolean;
  controlled?: boolean;
  disabled?: boolean;
  orientation?: 'column' | 'row';
}>;

export const RadioButtonGroup: React.FunctionComponent<
  RadioButtonGroupProps
> = (props) => {
  const {
    name,
    onChange,
    options,
    readOnly = false,
    value = null,
    controlled = false,
    disabled = false,
    orientation = 'column',
  } = props;

  const commonProps = useTestProps(props);

  const [selectedOption, setSelectedOption] = useState<RadioButtonGroupOption>(
    // @ts-ignore
    value ? options.find((o) => o.value === value) : null
  );

  const _selectedOption = useMemo(() => {
    if (!controlled) return selectedOption;
    return value ? options.find((o) => o.value === value) : null;
  }, [value, controlled, selectedOption]);

  const handleChange = (option) => () => {
    setSelectedOption(option);
    if (onChange) {
      onChange({
        target: {
          value: option.value,
          name,
        },
      });
    }
  };

  return (
    <div
      className={`radio-button-group radio-button-group--${orientation}`}
      {...commonProps}
    >
      {options.map((option, i) => {
        return (
          <RadioButton
            key={i}
            name={option.value}
            label={option.label}
            value={option.value}
            hasError={option.hasError}
            checked={_selectedOption?.value === option.value}
            disabled={option.disabled || disabled}
            onClick={handleChange(option)}
            tid={option.tid}
            readOnly={readOnly}
          />
        );
      })}
    </div>
  );
};
