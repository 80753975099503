import React from 'react';

export const ArrowRightIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="square"
    strokeLinejoin="round"
    strokeWidth="2"
  >
    <polyline points="9 18 15 12 9 6" />
  </svg>
);
