import { C } from '@nl-lms/ui/constants';

export const isVirtualSession = (sessionType) =>
  [
    C.I_LEARNING_TYPES.VIRTUAL_CLASS,
    C.I_LEARNING_TYPES.VIRTUAL_CONFERENCE,
  ].includes(sessionType);

const isDifferent = (current, next) => {
  if (!current && !next) return false;
  return current !== next;
};

const hasScheduleTemplateChanged = (
  currentScheduleTemplate,
  nextScheduleTemplate
) => {
  if (currentScheduleTemplate.length !== nextScheduleTemplate.length)
    return true;
  return currentScheduleTemplate.some((templateItem, index) => {
    return (
      templateItem.startDate !== nextScheduleTemplate[index].startDate ||
      templateItem.endDate !== nextScheduleTemplate[index].endDate
    );
  });
};

export const willCalendarEventBeUpdated = (currentSession, nextSession) => {
  if (!nextSession.calendarEnabled) return false;
  return (
    isDifferent(currentSession.startDate, nextSession.startDate) ||
    isDifferent(currentSession.description, nextSession.description) ||
    isDifferent(currentSession.name, nextSession.name) ||
    isDifferent(currentSession.virtualLocation, nextSession.virtualLocation) ||
    isDifferent(currentSession.city, nextSession.city) ||
    isDifferent(currentSession.region, nextSession.region) ||
    isDifferent(currentSession.region, nextSession.region) ||
    hasScheduleTemplateChanged(
      currentSession.scheduleTemplate,
      nextSession.scheduleTemplate
    )
  );
};
