import React from 'react';

import './RangeButton.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type RangeButtonProps = TidComponent<{
  label: string;
  count: number;
  className?: string;
  disabled?: boolean;
  checked: boolean;
  defaultChecked?: boolean;
  hasError?: boolean;
  readOnly?: boolean;
  onClick: any;
  name: string;
  value;
}>;

export const RangeButton: React.FunctionComponent<RangeButtonProps> = (
  props
) => {
  const {
    label,
    disabled = false,
    checked,
    defaultChecked = false,
    hasError = false,
    readOnly = false,
    onClick,
    name,
    value,
    count,
  } = props;

  const commonProps = useTestProps(props);

  const isTextLabel = !parseInt(label);

  const classNameProps = useClassNameProps(
    'range-button',
    props,
    disabled ? 'range-button--disabled' : null,
    readOnly ? 'range-button--readonly' : null,
    checked ? 'range-button--checked' : null,
    hasError ? 'range-button--has-error' : null,
    isTextLabel ? 'range-button--label-text' : null
  );

  return (
    <div className="range-button__wrapper" {...commonProps}>
      <label {...classNameProps}>
        <input
          type="radio"
          className="range-button__input"
          disabled={disabled}
          defaultChecked={defaultChecked || checked}
          readOnly={readOnly}
          onClick={onClick}
          value={value}
          name={name}
        />
        {isTextLabel && <span className={'range-button__count'}>{count}</span>}
        {!isTextLabel && label && (
          <span className={'range-button__label range-button__label--number'}>
            {label}
          </span>
        )}
      </label>
      {isTextLabel && label && (
        <span className={'range-button__label range-button__label--text'}>
          {label}
        </span>
      )}
    </div>
  );
};
