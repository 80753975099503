import { useAbility } from '@casl/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FieldType } from '@nl-lms/common/shared';
import { ActionStatus, AutomationScope } from '@nl-lms/feature/automation/sdk';
import {
  LearningAssignmentAutomation,
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
  getLearningAssignmentLearningContentType,
} from '@nl-lms/feature/learning-assignments/sdk';
import {
  LearningProgramInstanceStatus,
  LearningProgramScope,
} from '@nl-lms/feature/learning-programs/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableWithFullPagination,
  Typography,
  TypographyProps,
} from '@nl-lms/ui/components';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import {
  fetchAndMapLearners,
  mapAndLoadEnumsForSelect,
} from '@nl-lms/web/adminApp/_common/utils/fetchEntitiesForSelectMethods';

import { AbilityContext } from '../../../Can';
import { useTableAction } from '../../../_common/hooks';
import {
  automationApi,
  learningProgramsApi,
} from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';

const { useListActionsQuery, useRemoveActionsMutation } = automationApi;

export const LearningProgramScheduledInstancesTable = () => {
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined
  );
  const { data, isLoading } = useListActionsQuery(
    {
      query: { query: transformTsRestQuery(query) },
    },
    {
      pollingInterval,
    }
  );

  const ability = useAbility(AbilityContext);

  const [rows, pagination] = useListViewTableData(data);
  const [removeActions] = useRemoveActionsMutation();

  const onRemoveRow = useTableAction(removeActions, {
    withRowCountProp: true,
    confirmationMessage:
      'Are you sure that you want to remove the selected rows?',
  });

  const tableActions = [
    ...getActionAbility(
      AutomationScope.deleteAction.action,
      AutomationScope.deleteAction.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
      }
    ),
  ];

  return (
    <TableWithFullPagination
      columns={[
        {
          Header: 'Name',
          accessor: 'learner.id',
          Cell: ({ row }) => (
            <Sensitive>
              {ability.can('view', 'learner_page') ? (
                <Link
                  to={routes.admin.learners.item.path.full(
                    row.original.learnerId
                  )}
                >
                  {row.original.learner.firstName}{' '}
                  {row.original.learner.lastName}
                </Link>
              ) : (
                <span>
                  {row.original.learner.firstName}{' '}
                  {row.original.learner.lastName}
                </span>
              )}
            </Sensitive>
          ),
        },
        {
          Header: 'Email',
          accessor: 'learner.email',
          Cell: ({ row }) => {
            return row.original.learner.email;
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          sortField: 'status',
          Cell: ({ row }) => {
            return <StatusTag status={ActionStatus[row.original.status]} />;
          },
        },
        {
          Header: 'Created On',
          accessor: 'createdAt',
          sortField: 'createdAt',
          Cell: ({ row }) => {
            return <PrettyDate value={row.original.createdAt} withTime />;
          },
        },
        {
          Header: 'Updated At',
          accessor: 'updatedAt',
          sortField: 'updatedAt',
          Cell: ({ row }) => (
            <PrettyDate value={row.original.updatedAt} withTime />
          ),
        },
      ]}
      data={rows}
      isLoading={isLoading}
      sorting={sorting}
      tableActions={tableActions}
      pagination={pagination}
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
    >
      <NoDataPlaceholder
        iconName={filters ? 'FileIcon' : 'FileTextIcon'}
        color={
          filters
            ? NoDataPlaceholderColor.warning
            : NoDataPlaceholderColor.default
        }
        title={
          filters
            ? 'There are no scheduled program instances that match your search'
            : 'There are no learning program instances that have been scheduled'
        }
        // @ts-ignore
        subtitle={filters ? 'You can try changing the filters' : null}
      />
    </TableWithFullPagination>
  );
};

export const LearningProgramScheduledInstancesListTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();
  const ability = useAbility(AbilityContext);
  return (
    <FilterBar
      id="learning-program-view"
      fields={[
        {
          name: 'learner_id',
          label: 'Learner',
          type: FieldType.select,
          loadOptions: fetchAndMapLearners,
          ValueComponent: ({ children }) => {
            return <Sensitive>{children}</Sensitive>;
          },
        },
        {
          name: 'status',
          label: 'Status',
          type: FieldType.select,
          loadOptions: mapAndLoadEnumsForSelect(ActionStatus),
        },
        {
          name: 'scheduled_on',
          label: 'Scheduled On',
          type: FieldType.datetime,
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

LearningProgramScheduledInstancesTable.Filters =
  LearningProgramScheduledInstancesListTableFilters;
