import jwtDecode from 'jwt-decode';
import { useCallback } from 'react';
import store from 'store';

import { parseLocationData } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import * as sentry from '../../../lib/sentry';
import { useAction } from '../../hooks/useAction';
import { adminApi, api, apiBaseUrl } from '../../services/api';
import { uniqueId } from '../../utils';

const { useResetPasswordMutation } = adminApi;
interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  learnerId?: string;
  id: string;
  roles: string[];
  scope: string[];
  competencies: { id: string; title: string; scope: string }[];
  settings: { i18nPortal: string; i18nAdmin: string };
  avatar?: string;
}

class AuthStore {
  protected listeners = [];

  get learnerId() {
    return this.user?.learnerId;
  }

  get competencies() {
    return this.user?.competencies || [];
  }

  get prevRouteBeforeLogout(): string {
    return store.get('prevRouteBeforeLogout');
  }

  set prevRouteBeforeLogout(route: string) {
    store.set('prevRouteBeforeLogout', route);
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  get hasAdminRole() {
    return this.user && this.user.roles.includes('admin');
  }

  get hasReportDownloadRole() {
    return (
      this.user &&
      (this.user.roles.includes('report-download') ||
        this.user.roles.includes('admin') ||
        this.user.roles.includes('evaluator') ||
        this.user.roles.includes('learner') ||
        this.user.roles.includes('manager') ||
        this.user.roles.includes('auditor'))
    );
  }

  get hasManagerRole() {
    return this.user && this.user.roles.includes('manager');
  }

  get hasEvaluatorRole() {
    return this.user && this.user.roles.includes('evaluator');
  }

  get hasAuditorRole() {
    return this.user && this.user.roles.includes('auditor');
  }

  get hasLearnerRole() {
    return (
      this.user &&
      this.user.scope.includes('learner.app') &&
      !!this.user.learnerId
    );
  }

  get isOnlyManager() {
    return this.hasManagerRole && !this.hasAdminRole;
  }

  get isOnlyEvaluator() {
    return this.hasEvaluatorRole && !this.hasAdminRole;
  }

  get isOnlyAdmin() {
    return this.hasAdminRole && !this.hasManagerRole && !this.hasEvaluatorRole;
  }

  get isAnythingButAdmin() {
    return this.hasManagerRole && this.hasEvaluatorRole && !this.hasAdminRole;
  }

  set accessToken(accessToken) {
    store.set('accessToken', accessToken);
  }

  get accessToken(): string {
    return store.get('accessToken');
  }

  set user(
    user: IUser & {
      impersonatedFirstName?: string;
      impersonatedLastName?: string;
    }
  ) {
    const oldUser = this.user;
    store.set('user', user);

    if (!_.isEqual(oldUser, user)) {
      this.listeners.forEach(({ variable, listener }) => {
        // @ts-ignore
        if (variable === 'user' && listener) listener(user, oldUser);
      });
    }
  }

  get user(): IUser & {
    impersonatedFirstName?: string;
    impersonatedLastName?: string;
  } {
    return store.get('user');
  }

  set activeApp(app) {
    const oldApp = this.activeApp;
    store.set('activeApp', app);

    if (!_.isEqual(oldApp, app)) {
      this.listeners.forEach(({ variable, listener }) => {
        // @ts-ignore
        if (variable === 'activeApp' && listener) listener(app, oldApp);
      });
    }
  }

  get activeApp(): 'portal' | 'admin' | 'auth' | 'public' | null {
    const app = store.get('activeApp');
    const parsedActiveApp = app ? app.toLowerCase() : null;
    if (parsedActiveApp === 'portal') return 'portal';
    else if (parsedActiveApp === 'admin') return 'admin';
    else if (parsedActiveApp === 'auth') return 'auth';
    else if (parsedActiveApp === 'public') return 'public';
    else return null;
  }

  onChange(variable, listener) {
    // @ts-ignore
    this.listeners.push({ variable, listener });
  }
}

export const authStore = new AuthStore();

type LoginResponsePayload = {
  user: IUser;
  token?: string;
};

export const setAuthData = (loginResponsePayload) => {
  sentry.setUser(loginResponsePayload.user);
  authStore.user = loginResponsePayload.user;
  authStore.accessToken = loginResponsePayload.token;
  authStore.activeApp = 'portal';
};

export const login = async (
  loginArgs: any,
  { withRedirect } = { withRedirect: true }
) => {
  // @ts-ignore
  const res = (await api.auth.access(
    loginArgs.email,
    loginArgs.password,
    loginArgs.code,
    loginArgs.tk
  )) as LoginResponsePayload;
  if (!res.token) throw new Error('Unable to login');

  setAuthData(res);

  handleTokenRefreshing();

  if (!withRedirect) return;

  const baseRoute = authStore.hasLearnerRole ? '/portal' : '/admin';
  const path = authStore.prevRouteBeforeLogout || baseRoute;

  window.location.href = path;

  // @ts-ignore
  authStore.prevRouteBeforeLogout = null;
};

export const register = async (data: any) => {
  // @ts-ignore
  const res = (await api.auth.register(data)) as any;
  return res;
};

const RegistrationSignals: Record<string, AbortController> = {};

export const registerConfirm = async (token: string) => {
  if (RegistrationSignals[token]) {
    RegistrationSignals[token].abort();
  }
  RegistrationSignals[token] = new AbortController();
  // @ts-ignore
  const res = (await api.auth.registerConfirm(
    token,
    RegistrationSignals[token].signal
  )) as any;

  delete RegistrationSignals[token];
  return res;
};

export const logout = async () => {
  const prevRouteBeforeLogout = window.location.href;
  const accessToken = authStore.accessToken;

  // @ts-ignore
  authStore.user = null;
  // @ts-ignore
  authStore.accessToken = null;
  authStore.prevRouteBeforeLogout = prevRouteBeforeLogout;

  window.location.href = `${apiBaseUrl()}${api.auth.paths.reject()}?access_token=${accessToken}`;
};

type DecodedToken = {
  exp: number;
  nbf: number;
};

let tokenCheckInterval = null;
export const handleTokenRefreshing = async () => {
  const refreshAndUpdateToken = async () => {
    try {
      const res = await api.auth.refresh();
      setAuthData(res);
      handleTokenRefreshing();
    } catch (e) {
      await logout();
    }
  };

  // @ts-ignore
  clearInterval(tokenCheckInterval);

  const tokenCheckIntervalInSeconds = 1;
  // @ts-ignore
  tokenCheckInterval = setInterval(() => {
    const accessToken = authStore.accessToken;
    if (!accessToken) return false;

    const nowTime = Date.now().valueOf() / 1000;
    const decodedAccessToken = jwtDecode(accessToken) as DecodedToken;

    let isTokenExpired = false;
    if (
      typeof decodedAccessToken.exp !== 'undefined' &&
      decodedAccessToken.exp < nowTime
    )
      isTokenExpired = true;
    if (
      typeof decodedAccessToken.nbf !== 'undefined' &&
      decodedAccessToken.nbf > nowTime
    )
      isTokenExpired = true;

    if (isTokenExpired) {
      return logout();
    }

    const timeBeforeRefreshInSeconds = 30;
    const timeUntilExpiryInSeconds = decodedAccessToken.exp - nowTime;
    const refreshTimeInSeconds =
      timeUntilExpiryInSeconds - timeBeforeRefreshInSeconds;
    if (refreshTimeInSeconds <= 0) return refreshAndUpdateToken();

    return true;
  }, tokenCheckIntervalInSeconds * 1000);
};

export const setSettings = (settings) => {
  const leanerProperties = _.map(settings.learnerExtraParameters, (row) => {
    const camelizedRow = {
      ...row,
      name: _.camelCase(row.name),
    };
    if (row.fields)
      camelizedRow.fields = _.map(row.fields, (field) => ({
        ...field,
        name: _.camelCase(field.name),
      }));

    return camelizedRow;
  });
  store.set('NAME', settings.name);
  store.set('LEARNER_PROPERTIES', leanerProperties);
  store.set('I18N', settings.i18n);
  store.set('CUSTOMER_ID', settings.id);
  store.set('LOCATION_DATA', parseLocationData(settings.locationData));
  store.set('LOGO_DETAILS', settings.logoDetails);
  store.set('VENUE_FEATURES', settings.venueFeatures);
  store.set('SSO', settings.sso);
  store.set('LEARNER_SYNC', settings.learnerSync);
  store.set('UPLOAD_STRATEGY', settings.uploadStrategy);
  store.set('CURRENCY', settings.currency);
  store.set('NOTIFICATIONS', settings.notifications);
  store.set('SENTRY_DSN', settings.sentryDsn);
  store.set('VERSION', settings.version);
  store.set('CONTENT_PLAYER_URL', settings.contentPlayerUrl);
  store.set('FEATURES', _.camelize(settings.features));
};

export const startSessionTrace = () => {
  if (!sessionStorage.getItem('spanId')) {
    sessionStorage.setItem('spanId', uniqueId());
  }
};

export const useChangePassword = () => {
  const [resetPassword] = useResetPasswordMutation();
  const resetAction = useAction(resetPassword, {
    confirmationMessage:
      'Are you sure that you want to change your password? This action will log you out of the current session and a change password email with custom steps will be sent to you.',
    confirmationTitle: 'Change Password',
    showConfirmation: true,
    alertMessage: 'Unable to change password',
  });
  return useCallback(async () => {
    const result = await resetAction(authStore.user.email);
    if (result?.data) logout();
  }, []);
};
