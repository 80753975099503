import React from 'react';

import { Card, Typography } from '@nl-lms/ui/components';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi } from '../../../_common/services/api';

export const AdminAnalyticsCancelledSessionsCard = ({ filters }) => {
  const { useGetCancelledSessionsQuery } = adminApi;
  const { isLoading, data, error } = useGetCancelledSessionsQuery(filters);
  useQueryErrorHandler({ error });

  const totalSessionsCount = parseInt(data?.total ?? '0');
  const cancelledSessionCount = parseInt(data?.statusTotal ?? '0');
  const percentage =
    totalSessionsCount > 0
      ? ((100 * cancelledSessionCount) / totalSessionsCount).toFixed(1)
      : 0;

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Cancelled Sessions</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <Card.Body>
          <Typography.h1>{percentage}%</Typography.h1>
        </Card.Body>
      </Card.Content>
    </Card>
  );
};
