import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import React from 'react';

import { Icon, Link } from '..';
import './Breadcrumbs.scss';

const BreadcrumbsRoot = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode;
  }
>(({ className, ...props }, ref) => (
  <nav className={clsx('breadcrumbs', className)} ref={ref} {...props} />
));
BreadcrumbsRoot.displayName = 'Breadcrumbs';

const BreadcrumbsList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<'ol'>
>(({ className, ...props }, ref) => (
  <ol ref={ref} className={clsx('breadcrumbs__list', className)} {...props} />
));
BreadcrumbsList.displayName = 'BreadcrumbsList';

const BreadcrumbsItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<'li'> & { link?: string; isActive?: boolean }
>(({ className, link, isActive, ...props }, ref) => {
  if (link) {
    return (
      <li
        ref={ref}
        data-is-active={isActive}
        className={clsx('breadcrumbs__item', className)}
        {...props}
      >
        <Link to={link}>{props.children}</Link>
      </li>
    );
  }
  return (
    <li
      ref={ref}
      data-is-active={isActive}
      className={clsx('breadcrumbs__item', className)}
      {...props}
    />
  );
});
BreadcrumbsItem.displayName = 'BreadcrumbsItem';

const BreadcrumbsSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<'li'>) => (
  <li className={clsx('breadcrumbs__separator', className)} {...props}>
    {children ?? <Icon.ArrowRightIcon />}
  </li>
);
BreadcrumbsSeparator.displayName = 'BreadcrumbsSeparator';

export const Breadcrumbs = Object.assign(BreadcrumbsRoot, {
  List: BreadcrumbsList,
  Item: BreadcrumbsItem,
  Separator: BreadcrumbsSeparator,
});
