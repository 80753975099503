import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Badge,
  BadgeTypes,
  Button,
  Card,
  Checkbox,
  Icon,
  Separator,
  ToggleButtonGroup,
} from '@nl-lms/ui/components';
import { ElearningCourse, LiveCourse, Assessment } from '@nl-lms/sdk/backend';

import './AdminLearningPathLearningUnitCard.scss';
import { C } from '@nl-lms/ui/constants';

type BaseLearningUnit = {
  mandatory: boolean;
  sequential: boolean;
  validationType: number;
  order: number;
  id?: string;
};

type LearningUnit = BaseLearningUnit & {
  assessmentForm?: Assessment;
  elearningCourse?: ElearningCourse;
  course?: LiveCourse;
};

type LearningUnitCardProps = {
  unit: LearningUnit;
  onChange: (unitId: string, prop?: string, isChecked?: boolean) => void;
  disabledSequential: boolean;
};

const parseDescription = (description) => {
  const el = document.createElement('div');
  el.innerHTML = description;
  return el.textContent || el.innerText;
};

const getUnitTypeMap = (key, type = null) => {
  switch (key) {
    case 'elearningCourse':
      return 'Elearning';
    case 'assessmentForm':
      return 'Assessment';
    case 'course':
      return _.startCase(
        // @ts-ignore
        C.LEARNING_TYPES[type].toLowerCase().replace('_', ' ')
      );
  }
};

export const LearningUnitCard = (props: LearningUnitCardProps) => {
  const { unit, onChange, disabledSequential } = props;
  let unitKey = useMemo(() => {
    if (unit.course) {
      return 'course';
    } else if (unit.elearningCourse) {
      return 'elearningCourse';
    } else if (unit.assessmentForm) {
      return 'assessmentForm';
    }
    return '';
  }, [unit]);

  const parsedUnit = useMemo(() => {
    let thumbnail = null;
    let duration = null;
    let scheduleTemplate = null;
    let prework = null;

    if (unit.course) {
      // @ts-ignore
      thumbnail = unit.course.thumbnail;
      scheduleTemplate = unit.course.scheduleTemplate;
      // @ts-ignore
      prework = unit.course.prework;
    } else if (unit.elearningCourse) {
      // @ts-ignore
      thumbnail = unit.elearningCourse.thumbnail;
    } else if (unit.assessmentForm) {
      // @ts-ignore
      thumbnail = unit.assessmentForm.thumbnail;
      // @ts-ignore
      duration = unit.assessmentForm.duration;
    }
    return {
      thumbnail,
      duration,
      scheduleTemplate,
      name: unit[unitKey].name,
      prework,
      description: unit[unitKey].description,
    };
  }, [unit, unitKey]);
  const [isMandatory, setIsMandatory] = useState(unit.mandatory);
  const [isSequential, setIsSequential] = useState(unit.sequential);
  const [validationType, setValidationType] = useState(unit.validationType);

  const onChangeMandatory = () => {
    onChange(unit[unitKey].id, 'mandatory', !isMandatory);
    setIsMandatory(!isMandatory);
  };

  const onChangeSequential = () => {
    onChange(unit[unitKey].id, 'sequential', !isSequential);
    setIsSequential(!isSequential);
  };

  const onChangeValidationType = (value) => {
    onChange(unit[unitKey].id, 'validationType', value);
    setValidationType(value);
  };

  const unitType =
    unitKey === 'course'
      ? // @ts-ignore
        getUnitTypeMap(unitKey, unit[unitKey]?.type)
      : getUnitTypeMap(unitKey);
  return (
    <Card className="learning-unit-card">
      {/*// @ts-ignore */}
      <img src={parsedUnit.thumbnail} className="learning-unit-card__image" />
      <Card.Header withSeparator={false}>
        <Card.HeaderTitle>
          {parsedUnit.name}
          <div className="learning-unit-card__subtitle">{unitType}</div>
        </Card.HeaderTitle>
        <Card.HeaderActions>
          <Button
            label=""
            small
            icon={<Icon.ArrowLeftIcon />}
            ghost
            className="learning-unit-card__icon-button"
            onClick={() => onChange(unit[unitKey].id, 'prev')}
          />
          <Button
            label=""
            small
            icon={<Icon.ArrowRightIcon />}
            ghost
            className="learning-unit-card__icon-button"
            onClick={() => onChange(unit[unitKey].id, 'next')}
          />
          <Button
            label=""
            small
            icon={<Icon.DeleteIcon />}
            ghost
            className="learning-unit-card__icon-button"
            onClick={() => onChange(unit[unitKey].id, 'remove')}
          />
        </Card.HeaderActions>
      </Card.Header>
      <Card.Content paddingType="medium">
        <div className="learning-unit-card__body">
          <Badge
            label={
              parsedUnit.prework ? 'Includes pre-work' : 'No prework needed'
            }
            type={BadgeTypes.LIGHT}
          />
          <div className="learning-unit-card__description">
            {parseDescription(parsedUnit.description)}
          </div>
        </div>
      </Card.Content>
      <Card.Actions>
        <Separator marginTop={0} marginBottom={0} />
        <div className="learning-unit-card__actions">
          <Checkbox
            name="mandatory"
            label="Mandatory"
            checked={isMandatory}
            onChange={onChangeMandatory}
          />
          <span className="learning-unit-card__actions-separator" />
          <Checkbox
            name="sequential"
            label="Sequential"
            checked={isSequential}
            onChange={onChangeSequential}
            disabled={disabledSequential}
          />
          <span className="learning-unit-card__actions-separator" />

          <ToggleButtonGroup
            options={[
              {
                label: 'Last',
                value: C.I_LEARNING_PATH_ITEM_VALIDATION_TYPE.LATEST_SCORE,
              },
              {
                label: 'Best',
                value: C.I_LEARNING_PATH_ITEM_VALIDATION_TYPE.HIGHEST_SCORE,
              },
            ]}
            initialSelectedOption={validationType}
            onChange={onChangeValidationType}
            name="validationType"
            small
            className="learning-unit-card__actions-result"
          />
        </div>
      </Card.Actions>
    </Card>
  );
};
