import { Slot } from '@radix-ui/react-slot';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { useCallback } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { ReactPortal } from '../ReactPortal/ReactPortal';
import { TidComponent } from '../index.types';
import './Tooltip.scss';

export enum TooltipAlignment {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOP_LEFT = 'topLeft',
}

const defaultOnClick = () => {
  return;
};

type TooltipProps = TidComponent<{
  title: string;
  description?: string;
  children: React.ReactNode;
  onMouseEnter?: () => void;
  name?: string;
  className?: string;
  align?: TooltipAlignment;
  offsetToChildren?: number;
  onClick?: () => void;
  asChild?: boolean;
  collisionBoundary?: Element;
  side?: 'top' | 'bottom' | 'left' | 'right';
  disabled?: boolean;
}>;

export const Tooltip = ({
  title,
  description = '',
  children,
  className = '',
  onMouseEnter: _onMouseEnter = undefined,
  offsetToChildren = 5,
  asChild = false,
  onClick = defaultOnClick,
  collisionBoundary = undefined,
  disabled = false,
  side = 'top',
  ...props
}: TooltipProps) => {
  const commonProps = useTestProps(props);
  const triggerClassNameProps = useClassNameProps(
    'tooltip-trigger',
    className,
    disabled ? 'tooltip-trigger--disabled' : null
  );

  const onMouseEnter = useCallback(() => {
    if (_onMouseEnter) _onMouseEnter();
  }, [_onMouseEnter]);

  const Component = asChild ? Slot : 'div';
  return (
    <TooltipPrimitive.Root delayDuration={500}>
      <TooltipPrimitive.Trigger asChild>
        <Component
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          {...commonProps}
          {...triggerClassNameProps}
        >
          {children}
        </Component>
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          collisionBoundary={collisionBoundary}
          collisionPadding={{ left: 10, right: 10, top: 10, bottom: 10 }}
          sideOffset={offsetToChildren}
          side={side}
          asChild
        >
          <div className="tooltip-content" {...commonProps}>
            <div className="tooltip-content__title tooltip-content__title">
              {title}
            </div>
            {description && (
              <div className="tooltip-content__description">{description}</div>
            )}
          </div>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

export const DialogTooltip = ({
  // position,
  type,
  children,
  // style,
  tooltipHeight = 200,
  containerRef,
}) => {
  let position = 'right';
  let style = {};
  if (containerRef && containerRef.current) {
    const { bottom, top, height, width, x, y } =
      containerRef.current.getBoundingClientRect();
    const { height: parentHeight } =
      // @ts-ignore
      containerRef.current.offsetParent.getBoundingClientRect();

    if (bottom + 100 > parentHeight) position = 'top';

    if (position === 'top') {
      style = {
        // @ts-ignore
        top: bottom - height - tooltipHeight - 20,
        left: x,
      };
    } else {
      style = {
        top: top + height + 20,
        left: x,
      };
    }
  }
  return (
    <ReactPortal>
      <div
        className={`
            dialog-tooltip-element
            dialog-tooltip-element--${position} dialog-tooltip-element--${type}`}
        style={{
          ...style,
          height: tooltipHeight,
        }}
      >
        <div className="dialog-tooltip-content">{children}</div>
      </div>
    </ReactPortal>
  );
};

Tooltip.top = (props: TooltipProps) => (
  <Tooltip {...props} align={TooltipAlignment.TOP} />
);
Tooltip.bottom = (props: TooltipProps) => (
  <Tooltip {...props} align={TooltipAlignment.TOP} />
);
Tooltip.left = (props: TooltipProps) => (
  <Tooltip {...props} align={TooltipAlignment.LEFT} />
);
Tooltip.right = (props: TooltipProps) => (
  <Tooltip {...props} align={TooltipAlignment.RIGHT} />
);
Tooltip.topLeft = (props: TooltipProps) => (
  <Tooltip {...props} align={TooltipAlignment.TOP_LEFT} />
);
Tooltip.provider = ({ children }) => (
  <TooltipPrimitive.Provider>{children}</TooltipPrimitive.Provider>
);
