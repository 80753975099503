import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';

import { PartialBy } from '@nl-lms/common/shared';

import './ScrollArea.scss';

const ScrollAreaRoot = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RadixScrollArea.Root>
>(({ className, children, ...rest }, ref) => {
  return (
    <RadixScrollArea.Root
      ref={ref}
      className={`scrollarea ${className}`}
      {...rest}
    >
      {children}
    </RadixScrollArea.Root>
  );
});

ScrollAreaRoot.displayName = 'ScrollArea';

const ScrollAreaViewport = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RadixScrollArea.Viewport>
>(({ className, children, ...rest }, ref) => {
  return (
    <RadixScrollArea.Viewport
      className={`scrollarea__viewport ${className}`}
      {...rest}
      ref={ref}
    >
      {children}
    </RadixScrollArea.Viewport>
  );
});

ScrollAreaViewport.displayName = 'ScrollAreaViewport';

const ScrollAreaThumb = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RadixScrollArea.Thumb>
>(({ className, ...rest }, ref) => {
  return (
    <RadixScrollArea.Thumb
      className={`scrollarea__thumb ${className}`}
      ref={ref}
      {...rest}
    />
  );
});

ScrollAreaThumb.displayName = 'ScrollAreaThumb';

const ScrollAreaScrollbar = React.forwardRef<
  HTMLDivElement,
  PartialBy<React.ComponentProps<typeof RadixScrollArea.Scrollbar>, 'children'>
>(({ className, children, ...rest }, ref) => {
  if (!children) {
    return (
      <RadixScrollArea.Scrollbar
        className={`scrollarea__scrollbar ${className}`}
        {...rest}
        ref={ref}
      >
        <RadixScrollArea.Thumb className="scrollarea__thumb" />
      </RadixScrollArea.Scrollbar>
    );
  }

  return (
    <RadixScrollArea.Scrollbar
      className={`scrollarea__scrollbar ${className}`}
      {...rest}
      ref={ref}
    >
      {children}
    </RadixScrollArea.Scrollbar>
  );
});

ScrollAreaScrollbar.displayName = 'ScrollAreaScrollbar';

export const ScrollArea = Object.assign(ScrollAreaRoot, {
  Scrollbar: ScrollAreaScrollbar,
  Thumb: ScrollAreaThumb,
  Viewport: ScrollAreaViewport,
});
