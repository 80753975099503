import React, { useCallback, useMemo, useState } from 'react';

import { useClassNameProps } from '../../hooks/useClassNameProps';
import { useTestProps } from '../../hooks/useTestProps';
import { RatingButton } from '../RatingButton/RatingButton';
import { TidComponent } from '../index.types';
import './RatingButtonGroup.scss';

type RatingButtonGroupOption = TidComponent<{
  value: string;
  label: string;
  disabled?: boolean;
  hasError?: boolean;
}>;

type RatingButtonGroupProps = TidComponent<{
  name: string;
  onChange?: any;
  value?: string;
  options: RatingButtonGroupOption[];
  readOnly?: boolean;
  controlled?: boolean;
  className?: string;
  disabled?: boolean;
}>;

export const RatingButtonGroup = (props: RatingButtonGroupProps) => {
  const {
    name,
    onChange,
    options,
    readOnly = false,
    value: rating = null,
    // controlled = false,
    disabled = false,
  } = props;

  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('rating-button-group', props);

  const [hoverOption, setHoverOption] = useState<
    (RatingButtonGroupOption & { parsedValue: number; index: number }) | null
  >();

  const [currentRating, setCurrentRating] = useState(
    rating ? (!isNaN(parseFloat(rating)) ? parseFloat(rating) : 0) : 0
  );

  const parsedOptions = useMemo(
    () =>
      options?.map((o, i) => ({
        ...o,
        parsedValue: !isNaN(parseFloat(o.value)) ? parseFloat(o.value) : i + 1,
        index: i,
      })),
    [options]
  );

  const getOptionIconType = useCallback(
    (option) => {
      let isChecked = false;

      isChecked = option.parsedValue <= currentRating;

      if (hoverOption) {
        if (option.parsedValue <= hoverOption?.parsedValue) {
          isChecked = true;
        } else {
          isChecked = false;
        }
      }
      return { isChecked };
    },
    [hoverOption, currentRating]
  );

  const onOptionClick = useCallback(
    (option) => {
      setCurrentRating(option.parsedValue);

      if (onChange) {
        onChange({
          target: {
            value: option.value,
            name,
          },
        });
      }
    },
    [onChange, name]
  );

  return (
    <div {...classNameProps} {...commonProps}>
      {parsedOptions?.map((option) => {
        const optionProps = getOptionIconType(option);
        return (
          <RatingButton
            key={option.index}
            name={option.value}
            value={option.value}
            checked={optionProps.isChecked}
            disabled={option.disabled || disabled}
            onClick={() => onOptionClick(option)}
            onMouseOver={() => setHoverOption(option)}
            onMouseLeave={() => setHoverOption(null)}
            readOnly={readOnly}
            tid={option.tid}
          />
        );
      })}
    </div>
  );
};
