import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { Icon, IconButton, Link } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  setPageName,
  useLocalStorageState,
  useMediaQuery,
  useOnClickOutside,
  useRoutingAction,
} from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import './NavigationMenu.scss';

type NavigationMenuContextType = {
  align: 'top' | 'center';
  size: 'small' | 'large';
  isHovered: boolean;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  setIsHovered: (isHovered: boolean) => void;
  setSize: (size: 'small' | 'large') => void;
};

const NavigationMenuContext = createContext<NavigationMenuContextType>(
  {} as NavigationMenuContextType
);

const NavigationMenuSizeStoreKey = 'navigation-menu-size';
const NavigationMenuProvider = ({
  children,
  align = 'top',
  name = '',
  defaultSize = 'large',
}: React.PropsWithChildren<{
  align?: 'top' | 'center';
  defaultSize?: 'small' | 'large';
  name?: string;
}>) => {
  const [size, setSize] = useLocalStorageState<'small' | 'large'>(
    `${NavigationMenuSizeStoreKey}-${name}`,
    defaultSize
  );
  const [isVisible, setIsVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useMediaQuery('max-width: 640px', () => setIsVisible(false));
  useMediaQuery('min-width: 640px', () => setIsVisible(true));

  return (
    <NavigationMenuContext.Provider
      value={{
        align,
        setIsHovered,
        size,
        isHovered,
        setSize,
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </NavigationMenuContext.Provider>
  );
};

const NavigationMenuRoot = ({ children }) => {
  const { setIsVisible, setIsHovered, size, isVisible, isHovered } = useContext(
    NavigationMenuContext
  );
  const debounceRef = useRef<ReturnType<typeof _.debounce> | null>(null);
  const onMouseEnter = useCallback(() => {
    if (debounceRef.current) {
      debounceRef.current.cancel();
      debounceRef.current = null;
    }
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    debounceRef.current = _.debounce(() => {
      setIsHovered(false);
    }, 200);
    debounceRef.current();
  }, []);
  const isMobile = useMediaQuery('max-width: 640px');
  const ref = useOnClickOutside({
    handler: () => {
      if (isMobile && isVisible) {
        setIsVisible(false);
      }
    },
  });
  return (
    <>
      <nav
        ref={ref}
        className="navigation-menu"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-is-visible={isVisible}
        data-is-hovered={isHovered}
        data-size={size}
      >
        {children}
      </nav>
      {size === 'small' && (
        <div className="navigation-menu__small-menu-placeholder" />
      )}
    </>
  );
};

type NavigationMenuListContextType = {
  hoveredElement: React.MutableRefObject<HTMLDivElement | null>;
};

const NavigationMenuListContext = createContext<NavigationMenuListContextType>(
  {} as NavigationMenuListContextType
);

const NavigationMenuList = ({
  children,
  className = '',
}: React.PropsWithChildren<{ className?: string }>) => {
  const hoveredElement = useRef<HTMLDivElement | null>(
    null
  ) as React.MutableRefObject<HTMLDivElement | null>;

  return (
    <NavigationMenuListContext.Provider value={{ hoveredElement }}>
      <ul className={`navigation-menu__list ${className}`}>{children}</ul>
    </NavigationMenuListContext.Provider>
  );
};

type NavigationMenuListItemContextType = {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  isHovered: boolean;
  ref: React.RefObject<HTMLDivElement>;
};

const NavigationMenuListItemContext =
  createContext<NavigationMenuListItemContextType>(
    {} as NavigationMenuListItemContextType
  );

const NavigationMenuListItem = (
  props: React.PropsWithChildren<{
    isActive: boolean;
    isVisible?: boolean;
    link?: string;
  }>
) => {
  const { children, isActive, isVisible = true, link } = props;
  const [isExpanded, setIsExpanded] = useState(isActive);
  const { hoveredElement } = useContext(NavigationMenuListContext);
  const { isHovered: isNavigationMenuHovered, size: navigationMenuSize } =
    useContext(NavigationMenuContext);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const debounceRef = useRef<any>(null);

  const goToRoute = useRoutingAction();
  const onMouseLeave = useCallback(() => {
    debounceRef.current = _.debounce(() => {
      setIsHovered(false);
    }, 200);
    debounceRef.current();
  }, []);

  const onMouseEnter = useCallback(() => {
    if (debounceRef.current) {
      debounceRef.current.cancel();
      debounceRef.current = null;
    }
    if (hoveredElement.current && hoveredElement.current !== ref.current) {
      hoveredElement.current.setAttribute('data-is-hovered', 'false');
    }

    hoveredElement.current = ref.current;
    setIsHovered(true);
  }, []);

  useEffect(() => {
    if (navigationMenuSize === 'small' && !isNavigationMenuHovered) {
      setIsExpanded(false);
    }
  }, [isNavigationMenuHovered, navigationMenuSize]);

  const onClick = useCallback(() => {
    goToRoute(link);
    let pageName = '';
    const labelElement = ref.current?.querySelector(
      '.navigation-menu__list-item-label'
    );
    if (labelElement) {
      // @ts-expect-error
      pageName = labelElement?.innerText;
    }
    if (pageName) setPageName(pageName);
  }, [link]);

  if (!isVisible) return null;
  if (link) {
    return (
      <NavigationMenuListItemContext.Provider
        value={{ ref, isExpanded, isHovered, setIsExpanded }}
      >
        <div
          ref={ref}
          className={`navigation-menu__list-item`}
          data-is-active={isActive}
          data-is-expanded={false}
          data-is-hovered={isHovered}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link className="navigation-menu__link" onClick={onClick}>
            <div className="navigation-menu__list-item-indicator" />
            {children}
          </Link>
        </div>
      </NavigationMenuListItemContext.Provider>
    );
  }

  return (
    <NavigationMenuListItemContext.Provider
      value={{ ref, isExpanded, isHovered, setIsExpanded }}
    >
      <div
        ref={ref}
        className={`navigation-menu__list-item`}
        data-is-active={isActive}
        data-is-expanded={isExpanded}
        data-is-hovered={isHovered}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="navigation-menu__list-item-indicator" />
        {children}
      </div>
    </NavigationMenuListItemContext.Provider>
  );
};

const NavigationMenuListItemContent = ({ children }) => {
  const { isExpanded, setIsExpanded } = useContext(
    NavigationMenuListItemContext
  );

  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div onClick={onClick} className={`navigation-menu__list-item-content`}>
      {children}
    </div>
  );
};

const NavigationMenuListItemIcon = ({ children }) => {
  return <div className="navigation-menu__list-item-icon">{children}</div>;
};

const NavigationMenuListItemLabel = ({ children }) => {
  return <div className="navigation-menu__list-item-label">{children}</div>;
};

const NavigationMenuListItemArrow = () => {
  const { isExpanded } = useContext(NavigationMenuListItemContext);
  return (
    <div
      data-is-expanded={isExpanded}
      className="navigation-menu__list-item-arrow"
    >
      <Icon.ArrowRightIcon />
    </div>
  );
};

const NavigationMenuItemSublist = ({ children }) => {
  const { ref, isExpanded } = useContext(NavigationMenuListItemContext);

  return (
    <ul className="navigation-menu__sublist" data-is-expanded={isExpanded}>
      {children}
      {ref.current
        ? createPortal(
            <NavigationMenuSublistHoverContent>
              {children}
            </NavigationMenuSublistHoverContent>,
            ref.current
          )
        : null}
    </ul>
  );
};

const NavigationMenuSublistHoverContent = ({ children }) => {
  const { isExpanded, ref } = useContext(NavigationMenuListItemContext);

  if (!ref.current) return null;
  const { top } = ref.current.getBoundingClientRect();
  return (
    <div
      className="navigation-menu__hover-content-container"
      data-element-type="hover-content"
      style={{ top: `${top}px`, left: `${210}px` }}
    >
      <ul
        data-is-expanded={isExpanded}
        className="navigation-menu__hover-sublist"
      >
        {children}
      </ul>
    </div>
  );
};

const NavigationMenuSublistItem = ({
  children,
  link,
  isVisible = true,
  isActive: _isActive,
}: React.PropsWithChildren<{
  link: string;
  isActive?: boolean;
  isVisible?: boolean;
}>) => {
  const { pathname } = useLocation();
  const ref = useRef<HTMLLIElement>(null);
  const goToRoute = useRoutingAction();
  const isActive = _isActive || pathname.startsWith(link);
  const onClick = useCallback(() => {
    goToRoute(link);
    let pageName = '';
    const labelElement = ref.current?.querySelector(
      '.navigation-menu__sublist-item-label'
    );
    if (labelElement) {
      // @ts-expect-error
      pageName = labelElement?.innerText;
    }
    if (pageName) setPageName(pageName);
  }, [children]);

  if (!isVisible) return null;
  return (
    <li
      data-is-active={isActive}
      className="navigation-menu__sublist-item"
      ref={ref}
      onClick={onClick}
    >
      <div className="navigation-menu__sublist-item-indicator" />
      {children}
    </li>
  );
};

const NavigationMenuSublistItemLabel = ({ children }) => {
  return <div className="navigation-menu__sublist-item-label">{children}</div>;
};

const NavigationMenuSeparator = () => {
  return <div className="navigation-menu__separator" />;
};

const NavigationMenuLabel = ({ children }) => {
  return <div className="navigation-menu__label">{children}</div>;
};

const NavigationMenuHeader = ({ children }) => {
  return <div className="navigation-menu__header">{children}</div>;
};

const NavigationMenuFooter = ({ children }) => {
  return <div className="navigation-menu__footer">{children}</div>;
};

const NavigationMenuHeaderLogo = ({ link }: { link: string }) => {
  return (
    <div className="navigation-menu__header-icon">
      <Link to={link}>
        <img src={C.LOGO_DETAILS.variation.small} />
      </Link>
    </div>
  );
};

type NavigationMenuHeaderSearchContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const NavigationMenuHeaderSearchContext =
  createContext<NavigationMenuHeaderSearchContextType>(
    {} as NavigationMenuHeaderSearchContextType
  );

const NavigationMenuHeaderSearchButton = ({ children }) => {
  const { size, setIsHovered, isHovered } = useContext(NavigationMenuContext);
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsOpen(true);
    if (size === 'small') {
      // if the menu collapses it will then expand
      // again when the user moves the mouse over the command menu
      setIsHovered(true);
    }
  }, []);
  return (
    <NavigationMenuHeaderSearchContext.Provider value={{ isOpen, setIsOpen }}>
      <button onClick={onClick} className="navigation-menu__search-button">
        <Icon.SearchIcon />
        Search
      </button>
      {children}
    </NavigationMenuHeaderSearchContext.Provider>
  );
};

const NavigationMenuToggleSizeButton = () => {
  const { setSize, size } = useContext(NavigationMenuContext);
  return (
    <IconButton
      label={size === 'small' ? 'Lock Menu' : 'Hide Menu'}
      onClick={() => setSize(size === 'small' ? 'large' : 'small')}
      className="navigation-menu__toggle-size-button"
    >
      <Icon.MenuLayoutIcon />
    </IconButton>
  );
};

const NavigationMenuToggleVisibilityButton = ({
  className = '',
}: {
  className?: string;
}) => {
  const { setIsVisible, isVisible, size } = useContext(NavigationMenuContext);
  const onClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [size, isVisible]);
  return (
    <IconButton
      label="View Menu"
      onClick={onClick}
      className={`navigation-menu__toggle-visiblity-button ${className}`}
    >
      <Icon.MenuLayoutIcon />
    </IconButton>
  );
};

export const NavigationMenu = Object.assign(NavigationMenuRoot, {
  Label: NavigationMenuLabel,
  Header: NavigationMenuHeader,
  List: NavigationMenuList,
  ListItem: NavigationMenuListItem,
  ListItemIcon: NavigationMenuListItemIcon,
  ListItemLabel: NavigationMenuListItemLabel,
  ListItemArrow: NavigationMenuListItemArrow,
  ListItemContent: NavigationMenuListItemContent,
  Sublist: NavigationMenuItemSublist,
  SublistItem: NavigationMenuSublistItem,
  SublistItemLabel: NavigationMenuSublistItemLabel,
  Separator: NavigationMenuSeparator,
  Footer: NavigationMenuFooter,
  ToggleSizeButton: NavigationMenuToggleSizeButton,
  ToggleVisibilityButton: NavigationMenuToggleVisibilityButton,
  HeaderLogo: NavigationMenuHeaderLogo,
  HeaderSearchButton: NavigationMenuHeaderSearchButton,
  Context: NavigationMenuContext,
  Provider: NavigationMenuProvider,
});
