import { useAbility } from '@casl/react';
import React, { useCallback, useMemo } from 'react';

import {
  Button,
  ErrorPlaceholder,
  Icon,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  Sensitive,
  TableWithFullPagination,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { _ } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { AbilityContext } from '../../../../Can';
import { useAction } from '../../../../_common/hooks/useAction';
import { adminApi } from '../../../../_common/services/api';

const { useListLiveSessionCostsQuery, useRemoveLiveSessionCostsMutation } =
  adminApi;

const displayCurrency = C.CURRENCIES[C.CURRENCY];
const parseCosts = (costs) =>
  _.map(_.values(costs), (cost) => {
    const costTypeResourceData = [cost.resourceType, cost.resourceName].filter(
      (e) => e
    );
    return {
      ...cost,
      resource_type: cost.resourceType,
      resource: cost.resourceName
        ? `${cost.resourceType} - ${cost.resourceName}`
        : cost.resourceType,
      costTypeWithResource: `${cost.costType.name}${
        costTypeResourceData.length
          ? ` (${costTypeResourceData.join(' - ')})`
          : ''
      }`,
      costUnitPriceWithUnit: `${cost.unitPrice} ${
        C.CURRENCIES[cost.currency]
      }/${cost.costType.unit}`,
      costTotalCost: `${cost.qty * cost.unitPrice} ${
        C.CURRENCIES[cost.currency]
      }`,
      costConvertedUnitPriceWithUnit: cost.convertedUnitPrice
        ? `${cost.convertedUnitPrice.toFixed(2)} ${displayCurrency}/${
            cost.costType.unit
          }`
        : '-',
      costConvertedTotalCost: cost.convertedUnitPrice
        ? `${cost.qty * cost.convertedUnitPrice.toFixed(2)} ${displayCurrency}`
        : '-',
    };
  });

export const AdminLiveSessionCostListTable = ({
  sessionId,
}: {
  sessionId: string;
}) => {
  const { sorting, onChangePagination, onChangeSorting, filters, query } =
    useListViewContext();

  const fetchArg = useMemo(() => ({ sessionId, query }), [sessionId, query]);

  const { data, error, refetch, isLoading } =
    useListLiveSessionCostsQuery(fetchArg);
  useQueryErrorHandler({ error });

  const [rows, pagination] = useListViewTableData(data);

  const [columns, onChangeColumns] = useListViewTableColumns(getColumns());

  const [removeLiveSessionCosts] = useRemoveLiveSessionCostsMutation();
  const removeLiveSessionCostsAction = useAction(removeLiveSessionCosts, {
    successMessage: 'The live session costs have been successfully removed',
    confirmationMessage:
      'You are about to delete the selected live session costs. Are you sure?',
    showConfirmation: true,
  });

  const onRemoveCost = useCallback(
    async (row) => {
      const ids = Array.isArray(row) ? row.map((row) => row.id) : [row.id];
      const result = await removeLiveSessionCostsAction({ ids, sessionId });
      if (result?.data) return true;
    },
    [sessionId]
  );

  const _data = useMemo(() => parseCosts(rows), [rows]);

  const ability = useAbility(AbilityContext);

  return (
    <TableWithFullPagination
      columns={columns}
      onChangeColumns={onChangeColumns}
      data={_data}
      isLoading={isLoading}
      onChangeSorting={onChangeSorting}
      onChangePagination={onChangePagination}
      sorting={sorting}
      pagination={pagination}
      rowActions={
        ability.can('delete', 'live_session_cost')
          ? [
              {
                name: 'Remove Cost',
                handler: onRemoveCost,
                Icon: Icon.DeleteIcon,
              },
            ]
          : []
      }
      tableActions={
        ability.can('delete', 'live_session_cost')
          ? [
              {
                name: 'Remove',
                handler: onRemoveCost,
              },
            ]
          : []
      }
    >
      {error ? (
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          <ErrorPlaceholder.Title />
          <ErrorPlaceholder.Description>
            Something went wrong during the fetching process. Try to refresh the
            table data or if the problem persists please get in contact with us
            using the app help channel
          </ErrorPlaceholder.Description>
          <Button label="Reload" onClick={refetch} />
        </ErrorPlaceholder>
      ) : (
        <NoDataPlaceholder
          iconName={filters ? 'DollarIcon' : 'DollarIcon'}
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.success
          }
          title={
            filters
              ? 'There are no costs that match your search'
              : 'There are no costs added'
          }
          subtitle={
            filters
              ? 'You can try changing the active filters'
              : 'You can start adding new costs right now'
          }
        />
      )}
    </TableWithFullPagination>
  );
};

const getColumns = () => [
  {
    Header: 'Cost Type',
    accessor: 'costTypeWithResource',
    sortField: 'name',
    Cell: ({ row }) => (
      <Sensitive>{row.original.costTypeWithResource}</Sensitive>
    ),
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    sortField: 'qty',
    width: 50,
    Cell: ({ row }) => row.original.qty,
  },
  {
    Header: 'Price/Unit',
    accessor: 'costUnitPriceWithUnit',
    sortField: 'unitPrice',
    Cell: ({ row }) => (
      <Sensitive>{row.original.costUnitPriceWithUnit}</Sensitive>
    ),
    width: 100,
  },
  {
    Header: 'Total',
    accessor: 'costTotalCost',
    sortField: 'total',
    Cell: ({ row }) => <Sensitive>{row.original.costTotalCost}</Sensitive>,
    width: 100,
  },
  {
    Header: `Price/Unit (${displayCurrency})`,
    accessor: 'costConvertedUnitPriceWithUnit',
    Cell: ({ row }) => (
      <Sensitive>{row.original.costConvertedUnitPriceWithUnit}</Sensitive>
    ),
    sortField: 'unitPrice',
    width: 150,
  },
  {
    Header: `Total (${displayCurrency})`,
    accessor: 'costConvertedTotalCost',
    Cell: ({ row }) => (
      <Sensitive>{row.original.costConvertedTotalCost}</Sensitive>
    ),
    sortField: 'total',
    width: 100,
  },
];
