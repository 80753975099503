// @ts-nocheck
import { MentionAtomNodeAttributes } from 'remirror/extensions';

import { isValue } from '@nl-lms/common/shared';
import { NotificationAudience } from '@nl-lms/feature/notifications/sdk';

export const receiverPropertiesToMentionOptions = (
  receiverProps: NotificationAudience['fields'],
  prefix = ''
): MentionAtomNodeAttributes[] => {
  const parsedPrefix = prefix ? `${prefix}.` : '';
  return Object.keys(receiverProps)
    .map((prop) => {
      const propValue = receiverProps[prop];
      if (typeof propValue === 'string')
        return {
          id: `{{${parsedPrefix}${prop}}}`,
          label: `{{${parsedPrefix}${prop}}}`,
        } as MentionAtomNodeAttributes;
      if (propValue.type === 'object')
        return receiverPropertiesToMentionOptions(
          propValue.properties,
          `${parsedPrefix}${prop}`
        );
      return null;
    })
    .flat()
    .filter(isValue);
};

export function parseProsemirrorMentions(value: string) {
  const regex = /{{(.*?)}}/g;
  return value.replace(regex, '<span data-mention-atom-id="$1">$&</span>');
}
