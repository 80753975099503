import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArraySelectFieldMetadata,
  FieldType,
  FieldTypeToOperatorsRecord,
  JsonArraySelectFieldMetadata,
  OperatorToLabelRecord,
  QueryOperator,
  SelectFieldMetadata,
} from '@nl-lms/common/shared';
import { Checkbox } from '@nl-lms/ui/components';

import {
  FilterBarFilteringState,
  FilterBarOptionsComponentProps,
  OperatorOption,
  SelectValueOption,
} from '../FilterBar.types';
import { filterOptions, getOptionClassName, getOptionId } from '../filterUtils';

export const FilterBarSelectFieldOptions = ({
  inputValue,
  filteringState,
  field,
  activeFilter,
  getOptionProps,
  activeOptionId,
}: FilterBarOptionsComponentProps<
  SelectFieldMetadata | JsonArraySelectFieldMetadata | ArraySelectFieldMetadata
>) => {
  const { t } = useTranslation('learner');

  const [asyncOptions, setAsyncOptions] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const fetchOptions = useCallback(async (searchTerm) => {
    setIsFetchLoading(true);
    try {
      // @ts-ignore
      const res = await field.loadOptions(searchTerm);
      // @ts-ignore
      setAsyncOptions(res);
    } catch (e) {
    } finally {
      setIsFetchLoading(false);
    }
  }, []);

  const debouncedFetchOptions = useMemo(
    () => _.debounce(fetchOptions, 300),
    []
  );

  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  let options = useMemo(() => {
    let operator = QueryOperator.Includes;
    if (field.type === FieldType.jsonArraySelect) {
      operator = QueryOperator.JsonArrayIncludes;
    } else if (field.type === FieldType.arraySelect) {
      operator = QueryOperator.Overlaps;
    }

    const _options = getOptions({
      options: asyncOptions,
      field,
      operator,
      activeFilter,
    });
    return filterOptions(inputValue, _options);
  }, [asyncOptions, field, activeFilter, inputValue]);

  const separatorLabel =
    filteringState === FilterBarFilteringState.NotEditing && inputValue
      ? field.label
      : t('common.filterbar.values');

  const operatorOptions = useMemo<OperatorOption[]>(() => {
    return FieldTypeToOperatorsRecord[field.type].map((operator) => ({
      id: getOptionId(operator, field.name, 'OperatorOption'),
      type: 'OperatorOption',
      value: operator,
      // @ts-ignore
      label: t(`common.filterbar.${OperatorToLabelRecord[operator]}`) as string,
      field,
    }));
  }, [field]);

  return (
    <>
      <span className="filter-bar__option-separator">{separatorLabel}</span>

      {isFetchLoading && !options.length ? (
        <li className={getOptionClassName(false, false)}>
          Fetching Options...
        </li>
      ) : (
        <>
          {options.length ? (
            options.map((option) => {
              const optionProps = getOptionProps(option, option.id);
              return (
                <li
                  className={getOptionClassName(
                    option.id === activeOptionId,
                    option.isChecked
                  )}
                  key={option.id}
                  {...optionProps}
                >
                  <Checkbox
                    name={`${option.label}-${option.value}`}
                    checked={option.isChecked}
                    onChange={(e) => optionProps.onClick(e, true)}
                    className="filter-bar__option-checkbox"
                  />
                  {option.Component ? (
                    <option.Component
                      label={option.label}
                      entity={option.entity}
                    />
                  ) : (
                    option.label
                  )}
                </li>
              );
            })
          ) : (
            <li className={getOptionClassName(false, false)}>
              No data available
            </li>
          )}

          {filteringState === FilterBarFilteringState.EditingFilter ? (
            <>
              <span className="filter-bar__option-separator">Operators</span>
              {operatorOptions.map((option) => (
                <li
                  key={option.id}
                  className={getOptionClassName(option.id === activeOptionId)}
                  {...getOptionProps(option, option.id)}
                >
                  {option.label}
                </li>
              ))}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export const FilterBarSelectFieldSuggestOptions = ({
  inputValue,
  field,
  activeFilter,
  getOptionProps,
  activeOptionId,
}: FilterBarOptionsComponentProps<
  SelectFieldMetadata | JsonArraySelectFieldMetadata | ArraySelectFieldMetadata
>) => {
  const [asyncOptions, setAsyncOptions] = useState([]);
  const fetchOptions = useCallback(async (searchTerm) => {
    try {
      // @ts-ignore
      const res = await field.loadOptions(searchTerm);
      // @ts-ignore
      setAsyncOptions(res);
    } catch (e) {}
  }, []);

  const debouncedFetchOptions = useMemo(
    () => _.debounce(fetchOptions, 300),
    []
  );

  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  let options = useMemo(() => {
    let operator = QueryOperator.Includes;
    if (field.type === FieldType.jsonArraySelect) {
      operator = QueryOperator.JsonArrayIncludes;
    } else if (field.type === FieldType.arraySelect) {
      operator = QueryOperator.Overlaps;
    }

    const _options = getOptions({
      options: asyncOptions,
      field,
      operator,
      activeFilter,
    });
    return filterOptions(inputValue, _options);
  }, [asyncOptions, field, activeFilter, inputValue]);

  if (!options.length) return null;

  return (
    <>
      <span className="filter-bar__option-separator">{field.label}</span>
      {options.slice(0, 3).map((option) => (
        <li
          className={getOptionClassName(
            option.id === activeOptionId,
            option.isChecked
          )}
          key={option.id}
          {...getOptionProps(option, option.id)}
        >
          {option.Component ? (
            <option.Component label={option.label} entity={option.entity} />
          ) : (
            option.label
          )}
        </li>
      ))}
    </>
  );
};

const getOptions = ({
  options,
  field,
  operator,
  activeFilter,
}): SelectValueOption[] =>
  options.map((v) => ({
    id: getOptionId(`${operator}-${v.value}`, field.name, 'SelectValueOption'),
    type: 'SelectValueOption',
    field,
    label: v.label,
    operator: operator,
    value: v.value,
    Component: v.Component,
    isChecked: Array.isArray(activeFilter?.value)
      ? // @ts-ignore
        activeFilter?.value?.includes(v.value)
      : false,
  }));
