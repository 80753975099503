import clsx from 'clsx';
import React, { MutableRefObject, useRef } from 'react';
import { useCallback, useContext, useMemo } from 'react';

import {
  EmailButtonsBlock,
  EmailTextBlock,
  NotificationMessageBlock,
} from '@nl-lms/feature/notifications/sdk';
import { Draggable, Icon, IconButton, TextEditor } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useHotkey, useOnClickOutside } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { useUploadFile } from '../../../../_common/hooks';
import { NotificationMessageContext } from '../NotificationMessageContext';
import { NotificationEmailMessageBlockToolbar } from './NotificationEmailMessageBlockToolbar';

const RecursionDepth = 7;
function matchMessageBlockTypeElements(e: MouseEvent, recursionLevel = 0) {
  const eventTarget = e.target as HTMLElement;
  if (
    eventTarget?.getAttribute('data-is-message-block') === 'true' ||
    eventTarget?.className ===
      'notification-email-message-input__active-section-toolbar'
  )
    return true;
  if (recursionLevel === RecursionDepth) return false;
  const parentElement = eventTarget?.parentElement;
  if (!parentElement) return false;
  return matchMessageBlockTypeElements(
    { target: parentElement } as unknown as MouseEvent,
    recursionLevel + 1
  );
}

export const NotificationEmailMessageBlock = ({
  block,
  index,
}: {
  block: NotificationMessageBlock;
  index: number;
  children?: React.ReactNode;
}) => {
  const {
    onSelectBlock,
    onAddBlock,
    onReorderBlocks,
    onRemoveBlock,
    message,
    activeBlockIndex,
  } = useContext(NotificationMessageContext);
  const isActive = activeBlockIndex === index;

  // Unfocus editor if you click on something else
  // const componentRef = useOnClickOutside({
  //   handler: () => onSelectBlock(null),
  //   matchFn: matchMessageBlockTypeElements,
  // });

  const onFocus = useCallback(
    (e) => {
      onSelectBlock(index);
      e.stopPropagation();
      return false;
    },
    [index]
  );
  const disabled = false;

  const onDuplicate = useCallback(() => {
    if (activeBlockIndex === null) return;
    onAddBlock(message[activeBlockIndex], activeBlockIndex);
  }, [message, activeBlockIndex]);

  const onDrop = useCallback(
    (from: number, to: number) => {
      if (activeBlockIndex === null) return;
      onReorderBlocks(from, to);
    },
    [message, activeBlockIndex]
  );

  const onRemove = useCallback(() => {
    if (activeBlockIndex === null) return;
    onRemoveBlock(activeBlockIndex);
  }, [activeBlockIndex, message]);

  const BlockInputComponent = useMemo(() => {
    return BlockInputComponentsRecord[block.type];
  }, [block]);

  const defaultBorderColor = useMemo(() => {
    const blockStyle = C.NOTIFICATIONS.theme.email[block.type] as Record<
      string,
      unknown
    >;
    const sectionStyle = blockStyle.section as Record<string, unknown>;

    return 'backgroundColor' in sectionStyle
      ? sectionStyle.backgroundColor
      : 'transparent';
  }, [block]);
  if (!BlockInputComponent) return null;

  return (
    <Draggable asChild type="message-block" onDrop={onDrop} index={index}>
      <div
        // ref={componentRef}
        // @ts-expect-error
        style={{ '--default-border-color': defaultBorderColor }}
        onClick={onFocus}
        className={clsx('notification-email-message-input__section', {
          'notification-email-message-input__section--disabled': disabled,
          'notification-email-message-input__section--active': isActive,
        })}
        data-is-message-block="true"
      >
        {isActive ? (
          <div className="notification-email-message-input__section-actions">
            <Draggable.DragButton />
            <IconButton label="Duplicate" onClick={onDuplicate}>
              <Icon.CopyIcon />
            </IconButton>
            <IconButton label="Remove" onClick={onRemove}>
              <Icon.DeleteIcon />
            </IconButton>
          </div>
        ) : null}
        <BlockInputComponent block={block} index={index} />
      </div>
    </Draggable>
  );
};

type BlockInputProps = {
  block: NotificationMessageBlock;
  index: number;
};

const TextBlockInput = ({ index, block }: BlockInputProps) => {
  const textBlock = block as EmailTextBlock;
  const [sectionStyle, textStyle] = useMemo(() => {
    let textBlockStyle = C.NOTIFICATIONS.theme.email.text as Record<
      string,
      unknown
    >;
    if (textBlock.attributes.type) {
      // @ts-expect-error,
      textBlockStyle = textBlockStyle.variations[
        textBlock.attributes.type
      ] as Record<string, unknown>;
    }
    const { section, ...rest } = textBlockStyle;
    return [_.camelize(section), _.camelize(rest)];
  }, [block]);

  return (
    <div
      style={sectionStyle}
      className="notification-email-message-input__section-container"
    >
      <MessageBlockTextEditorContext index={index} block={block}>
        <TextEditor.Input
          style={textStyle}
          className="notification-email-message-input__editor"
        />
        <TextEditor.MentionsMenu />
        <NotificationEmailMessageBlockToolbar block={block} index={index} />
      </MessageBlockTextEditorContext>
    </div>
  );
};

const SeparatorBlockInput = ({ block }: BlockInputProps) => {
  const [sectionStyle, elementStyle] = useMemo(() => {
    const blockStyle = C.NOTIFICATIONS.theme.email.separator as Record<
      string,
      unknown
    >;
    const { section, ...rest } = blockStyle;
    return [_.camelize(section), _.camelize(rest)];
  }, [block]);
  return (
    <div
      className="notification-email-message-input__section-container"
      style={sectionStyle}
    >
      <div style={{ ...elementStyle, borderBottom: 'none ' }} />
    </div>
  );
};

const ButtonsBlockInput = ({ block, index }: BlockInputProps) => {
  const buttonsBlock = block as EmailButtonsBlock;

  const buttonsSectionStyle = useMemo(() => {
    const buttonsTheme = C.NOTIFICATIONS.theme.email.buttons as {
      section: Record<string, unknown>;
    };
    return _.camelize(buttonsTheme.section);
  }, [block]);

  return (
    <div
      style={buttonsSectionStyle}
      className="notification-email-message-input__section-container notification-email-message-input__buttons"
    >
      {buttonsBlock.value.map((button, buttonIndex) => {
        return (
          <>
            <ButtonBlockInput
              buttonIndex={buttonIndex}
              button={button}
              index={index}
              block={block}
            />
            <NotificationEmailMessageBlockToolbar block={block} index={index} />
          </>
        );
      })}
    </div>
  );
};

const ButtonBlockInput = ({
  buttonIndex,
  block,
  button,
}: BlockInputProps & {
  button: EmailButtonsBlock['value'][number];
  buttonIndex: number;
}) => {
  const { onEditBlock, activeBlockIndex } = useContext(
    NotificationMessageContext
  );
  const buttonRef = useRef<HTMLDivElement>(null);

  const initialLabel = useRef<string>(button.label);
  const buttonStyle = useMemo(() => {
    const elementTheme = C.NOTIFICATIONS.theme.email.button as Record<
      string,
      unknown
    >;
    const { section, variations, ...elementStyle } = elementTheme;
    const typedVariations = variations as unknown as Record<
      string,
      React.CSSProperties
    >;
    const buttonStyle = button?.type
      ? typedVariations[button?.type]
      : elementStyle;
    return _.camelize(buttonStyle);
  }, [button]);

  const onChangeButtonLabel = useCallback(
    (keydownEvent) => {
      const blockValue = block.value as unknown as EmailButtonsBlock['value'];
      if (activeBlockIndex === null || !blockValue[buttonIndex]) return;
      onEditBlock(
        activeBlockIndex,
        `value[${buttonIndex}].label`,
        keydownEvent.target.innerText
      );
    },
    [block, activeBlockIndex, buttonIndex]
  );

  return (
    <div
      ref={buttonRef}
      style={buttonStyle}
      onInput={onChangeButtonLabel}
      className="notification-email-message-input__button"
      suppressContentEditableWarning={true}
      contentEditable
    >
      {initialLabel.current}
    </div>
  );
};

const HeaderBlockInput = ({ block }: BlockInputProps) => {
  const [sectionStyle, headerStyle, imgStyle] = useMemo(() => {
    const elementStyle = C.NOTIFICATIONS.theme.email.header as Record<
      string,
      unknown
    >;
    const { section, src, width, ...rest } = elementStyle;
    return [_.camelize(section), _.camelize(rest), { src, width }];
  }, [block]);
  return (
    <div style={{ ...sectionStyle, display: 'flex', justifyContent: 'center' }}>
      <div style={headerStyle}>
        <img
          alt=""
          src={imgStyle.src as string}
          width={imgStyle.width as string}
        />
      </div>
    </div>
  );
};

export const EmptyBlockInput = () => {
  return <div style={{ padding: '40px' }}></div>;
};

const BlockInputComponentsRecord: Record<string, React.FC<BlockInputProps>> = {
  text: TextBlockInput,
  separator: SeparatorBlockInput,
  buttons: ButtonsBlockInput,
  header: HeaderBlockInput,
  exmpty: EmptyBlockInput,
};

const MessageBlockTextEditorContext = ({
  index,
  block,
  children,
}: {
  index: number;
  block: NotificationMessageBlock;
  children: React.ReactNode;
}) => {
  const { mentionOptions, activeBlockIndex, onEditBlock } = useContext(
    NotificationMessageContext
  );
  const { onUpload } = useUploadFile({ type: 'text_editor_asset' });
  const onChange = useCallback(
    (htmlText: string) => {
      if (index !== activeBlockIndex) return;
      onEditBlock(activeBlockIndex, 'value', htmlText);
    },
    [activeBlockIndex, onEditBlock, block]
  );

  const sectionTextValue = block.value as string;
  // force rerender when dynamic data changes
  const renderKey = useMemo(
    () => mentionOptions.map((item) => item.id).join('-'),
    [mentionOptions]
  );
  return (
    <TextEditor.Provider
      onUploadFile={onUpload}
      value={sectionTextValue}
      onChange={onChange}
      key={renderKey}
      mentionOptions={mentionOptions}
    >
      {children}
    </TextEditor.Provider>
  );
};
