import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import {
  AssessmentQuestionType,
  AssessmentQuestionWithValidation,
  Box,
  Button,
  Separator,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';
import { _ } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';
import { adminApi } from '@nl-lms/web/_common/services/api';

const { useGetAssessmentAttemptByIdQuery, useScoreAssessmentAttemptMutation } =
  adminApi;

export const AdminAssessmentAttemptEvaluationSideModal = ({
  instanceId,
  attemptId,
}) => {
  const [manuallyScoredQuestions, setManuallyScoredQuestions] = useState([]);
  const fetchArguments = useMemo(
    () => ({ instanceId, attemptId }),
    [instanceId, attemptId]
  );

  const { data: attempt, error } =
    useGetAssessmentAttemptByIdQuery(fetchArguments);
  useQueryErrorHandler({ error });

  const [scoreAssessmentAttempt, { isLoading }] =
    useScoreAssessmentAttemptMutation();
  const scoreAssessmentAttemptAction = useAction(scoreAssessmentAttempt, {
    successMessage: 'The assessment attempt has been scored successfully',
  });

  const { hide } = useModal();

  useEffect(() => {
    if (!attempt) return;

    const instanceAttemptQuestionsByQuestionId = _.keyBy(
      attempt.assessmentFormInstanceAttemptQuestions,
      'assessmentQuestionId'
    );
    const _assessmentQuestions = attempt.assessmentQuestions || [];
    const _manuallyScoredQuestions = _assessmentQuestions.filter((q) => {
      return (
        (q.type === AssessmentQuestionType.FreeText ||
          q.type === AssessmentQuestionType.Date) &&
        (instanceAttemptQuestionsByQuestionId[q.id].isCorrect === null ||
          typeof instanceAttemptQuestionsByQuestionId[q.id].isCorrect ===
            'undefined')
      );
    });
    // @ts-ignore
    setManuallyScoredQuestions(_manuallyScoredQuestions);
  }, [attempt, setManuallyScoredQuestions]);

  const onChangeScoring = useCallback(
    (isCorrect, id) => {
      setManuallyScoredQuestions(
        // @ts-ignore
        manuallyScoredQuestions.map((q) =>
          // @ts-ignore
          q.id === id ? { ...q, isCorrect } : q
        )
      );
    },
    [manuallyScoredQuestions, setManuallyScoredQuestions]
  );

  const onSubmitScoring = useCallback(async () => {
    const answers = manuallyScoredQuestions.map((q) => ({
      // @ts-ignore
      assessmentQuestionId: q.id,
      // @ts-ignore
      isCorrect: q.isCorrect,
    }));

    const res = await scoreAssessmentAttemptAction({
      instanceId,
      attemptId,
      answers,
    });

    return hide({ success: !!res?.data });
  }, [manuallyScoredQuestions, scoreAssessmentAttempt, attemptId, instanceId]);

  const isScoringValid = !manuallyScoredQuestions.find(
    // @ts-ignore
    (q) => typeof q.isCorrect === 'undefined'
  );
  const assessmentQuestions = attempt?.assessmentQuestions || [];

  const printViewRef = useRef();
  const onPrintScoring = useReactToPrint({
    // @ts-ignore
    content: () => printViewRef.current,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Answers</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        {/*// @ts-ignore */}
        <div ref={printViewRef}>
          {assessmentQuestions.map((question, index) => (
            <Box key={index}>
              <Box>
                {index > 0 ? <Separator /> : null}
                <AssessmentQuestionWithValidation
                  type={question.type}
                  question={question.title}
                  index={index}
                  options={question.options.map((q) => ({
                    value: q.title,
                    label: q.title,
                    isCorrect: q.correct,
                  }))}
                  id={question.id}
                  learnerAnswer={
                    // @ts-ignore
                    attempt?.answers.find((a) => a.questionId === question.id)
                      ?.answer || ['']
                  }
                  allowManualScoring={manuallyScoredQuestions.find(
                    // @ts-ignore
                    (q) => q.id === question.id
                  )}
                  onChangeScoring={onChangeScoring}
                  isCorrect={
                    // @ts-ignore
                    attempt.assessmentFormInstanceAttemptQuestions.find(
                      ({ assessmentQuestionId }) =>
                        assessmentQuestionId === question.id
                    )?.isCorrect
                  }
                />
              </Box>
            </Box>
          ))}

          <Separator marginBottom={0} backgroundColor="transparent" />
        </div>
      </SideModal.Body>

      <SideModal.Actions>
        <Button
          regular
          disabled={!isScoringValid}
          label="Print responses"
          onClick={onPrintScoring}
        />
        <Button
          disabled={!isScoringValid}
          label="Submit Evaluation"
          onClick={onSubmitScoring}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
