import suggest from './time-suggest';

export type DateSuggest = {
  formatted: string;
  iso8601: string;
  date: Date;
  confidence: number;
  rule: string;
  tokens: Array<ParseToken>;
  timezoneConverted: any;
  in_future: boolean;
  delta: {
    minutes: number;
    hours: number;
    days: number;
    months: number;
    years: number;
  };
};

export type ParseToken = {
  optional?: boolean;
  type: string; // should be enum
  value: string; // should be enum
  parsed: number;
  guessed?: boolean;
};

type TimeObject = {
  hours: number;
  minutes: number;
  seconds: number;
};

export type Locale = string;
export type Interpreter = 'relative' | 'absolute' | 'subsequent';
export type SuggestOptions = {
  month_before_day?: boolean;
  default_time?: TimeObject;
  locale?: Locale;
  relative_to?: any;
  hide_suffixes?: boolean;
  allow_past_dates?: boolean;
  allow_someday?: boolean;
  default_suggestions?: Array<string>;
  hide_preposition?: boolean;
  results_count?: number;
  interpreters?: Interpreter[];
};

export type Suggestor = (input: string) => Array<DateSuggest>;

export function create(
  options: SuggestOptions
): (input: string) => Array<DateSuggest> {
  return (input) => suggest(input, options);
}
export const timeSuggest = suggest;
