import React from 'react';
import { useTranslation } from 'react-i18next';

import { SingleSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';

export const LearnerLiveSessionCancelRegistrationReasonSelect = ({
  onChange,
  name = 'cancelRegistrationReasons',
  initialValue = null,
}) => {
  const { t } = useTranslation('learner');

  return (
    <SingleSelect
      onChange={(val) => onChange(parseInt(val))}
      options={_.map(C.SESSION_CANCELLATION_REASONS, (label, value) => ({
        value,
        // @ts-ignore
        label: t(`learningunit.cancellationreason.${label}`) as string,
      }))}
      // @ts-ignore
      initialSelectedItem={initialValue}
      placeholder={t('learningunit.selectreason')}
      isClearable
      name={name}
    />
  );
};
