import React from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';

import { Button } from '../Button/Button';
import * as Icon from '../Icon';
import { IconName } from '../Icon/index.types';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './NoDataPlaceholder.scss';

export enum NoDataPlaceholderColor {
  default = 'default',
  success = 'success',
  alert = 'alert',
  warning = 'warning',
}

export enum IconSizes {
  small = 'small',
  large = 'large',
}

type NoDataPlaceholderProps = TidComponent<{
  iconName?: IconName;
  title: string;
  subtitle?: string;
  actionName?: string;
  actionHandler?: () => void;
  color?: NoDataPlaceholderColor;
  allowedAction?: boolean;
  iconSize?: IconSizes;
}>;

export const NoDataPlaceholder = (props: NoDataPlaceholderProps) => {
  const {
    iconName = '',
    iconSize = IconSizes.large,
    title,
    subtitle,
    actionName,
    actionHandler,
    color = NoDataPlaceholderColor.default,
    allowedAction = true,
  } = props;

  const commonProps = useTestProps(props);
  const IconComponent = iconName ? Icon[iconName] : null;

  return (
    <div className="no-data-placeholder" {...commonProps}>
      <div className="no-data-placeholder__wrapper">
        <div className="no-data-placeholder__icon-wrapper">
          {IconComponent && (
            <div
              className={`no-data-placeholder__icon no-data-placeholder__icon--${color} no-data-placeholder__icon--${iconSize}`}
            >
              <IconComponent />
            </div>
          )}
        </div>
        <Typography.h2 className="no-data-placeholder__title">
          {title}
        </Typography.h2>
        <Typography.h4 className="no-data-placeholder__subtitle" type="muted">
          {subtitle}
        </Typography.h4>
        {allowedAction && (
          <div className="no-data-placeholder__actions">
            {actionName && actionHandler ? (
              <Button label={actionName} onClick={actionHandler} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const NoDataPlaceholderContainer = ({ children, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <div className="no-data-placeholder" {...commonProps}>
      <div className="no-data-placeholder__wrapper">{children}</div>
    </div>
  );
};

const NoDataPlaceholderIcon = ({
  name,
  size = 'large',
  color = 'default',
  ...props
}: TidComponent<{
  name: IconName;
  size?: 'small' | 'large';
  color?: 'default' | 'success' | 'alert' | 'warning';
}>) => {
  const IconComponent = Icon[name];
  if (!IconComponent) return null;
  const commonProps = useTestProps(props);
  return (
    <div className="no-data-placeholder__icon-wrapper" {...commonProps}>
      <div
        className={`no-data-placeholder__icon no-data-placeholder__icon--${color} no-data-placeholder__icon--${size}`}
      >
        <IconComponent />
      </div>
    </div>
  );
};

const NoDataPlaceholderTitle = ({ children, size = 'normal', ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  if (size === 'small') {
    return (
      <Typography.h3 className="no-data-placeholder__title" {...commonProps}>
        {children}
      </Typography.h3>
    );
  }
  return (
    <Typography.h2 className="no-data-placeholder__title" {...commonProps}>
      {children}
    </Typography.h2>
  );
};

const NoDataPlaceholderSubtitle = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Typography.h4
      className="no-data-placeholder__subtitle"
      type="muted"
      {...commonProps}
    >
      {children}
    </Typography.h4>
  );
};

const NoDataPlaceholderActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="no-data-placeholder__actions" {...commonProps}>
      {children}
    </div>
  );
};

NoDataPlaceholder.Icon = NoDataPlaceholderIcon;
NoDataPlaceholder.Container = NoDataPlaceholderContainer;
NoDataPlaceholder.Title = NoDataPlaceholderTitle;
NoDataPlaceholder.Subtitle = NoDataPlaceholderSubtitle;
NoDataPlaceholder.Actions = NoDataPlaceholderActions;
