import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

export const ElearningPlayerButton = (props) => {
  const { elearningSession, elearningCourse, onClick, ...rest } = props;

  const { t } = useTranslation('learner');

  if (elearningCourse?.deletedAt) return null;

  const goToPlayer = useCallback(async (data) => {
    window.open(data.playerUrl);
    if (onClick) onClick(data);
    // Hackalert: We use this to force page reload after a learner starts a new session
    // This way we try to make sure that the view is updated with the new ongoing session
    // and that there's a lower risk of the learner starting a new instance
    // setTimeout(() => window.location.reload(), 1000);
  }, []);

  return (
    <>
      {!elearningSession && (
        <Button
          regular
          label={t('common.learningactivitycard.start')}
          icon={<Icon.PlayIcon />}
          onClick={() => goToPlayer(elearningCourse)}
          {...rest}
        />
      )}

      {elearningSession && (
        <>
          {elearningSession.status ===
            C.I_ELEARNING_SESSION_STATUSES.NOT_STARTED && (
            <Button
              label={t('common.learningactivitycard.start')}
              regular
              icon={<Icon.PlayIcon />}
              onClick={() => goToPlayer(elearningSession)}
              {...rest}
            />
          )}
          {(elearningSession.status ===
            C.I_ELEARNING_SESSION_STATUSES.COMPLETED ||
            elearningSession.status ===
              C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED) && (
            <Button
              label={t('common.learningactivitycard.completed')}
              success
              icon={<Icon.CheckIcon />}
              {...rest}
            />
          )}
          {elearningSession.status ===
            C.I_ELEARNING_SESSION_STATUSES.IN_PROGRESS && (
            <Button
              label={t('common.learningactivitycard.continue')}
              regular
              icon={<Icon.PlayIcon />}
              onClick={() => goToPlayer(elearningSession)}
              {...rest}
            />
          )}
          {(elearningSession.status === C.I_ELEARNING_SESSION_STATUSES.FAILED ||
            elearningSession.status ===
              C.I_ELEARNING_SESSION_STATUSES.COMPLETED_FAILED) && (
            <Button
              label={t('common.learningactivitycard.retake')}
              regular
              icon={<Icon.PlayIcon />}
              onClick={() => goToPlayer(elearningSession)}
              {...rest}
            />
          )}
        </>
      )}
    </>
  );
};
