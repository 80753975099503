import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';

export const isInitialized = () => {
  return !!Sentry.getCurrentHub().getClient();
};

let ActiveEventId = '';

export const init = (dsn, { history, user, customerId, version }) => {
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    environment: version !== 'stable' ? 'production' : 'staging',
    release: version,
    beforeSend: (event, hint) => {
// @ts-ignore
      ActiveEventId = event.event_id;
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
  Sentry.setTag('customer_id', customerId);
  Sentry.setTag('span_id', sessionStorage.getItem('spanId'));
  setUser(user);
};

export const setUser = (user) => {
  if (!user || !isInitialized()) return;
  return Sentry.setUser({ id: user.id });
};

export const getReduxEnhancer = () => {
  if (!isInitialized()) return;
  return Sentry.createReduxEnhancer({});
};

export const sendUserFeedback = ({ comments, email, name }) => {
  if (!comments || !isInitialized()) return;
  return axios.post(
    'https://sentry.io/api/0/projects/nifty-learning/nl-lms-frontend/user-feedback/',
    {
      event_id: ActiveEventId,
      comments,
      name,
      email,
    },
    {
      headers: {
        Authorization: `Bearer `,
      },
    }
  );
};
