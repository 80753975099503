import React, { useCallback, useEffect } from 'react';
import { useNotifications } from '@nl-lms/ui/modules';

export const GlobalListeners = ({ children }) => {
  const { addWarningNotification } = useNotifications();

  const handleOnWarning = useCallback((e) => {
    if (!e || !e.detail || !e.detail.length) return;

    e.detail.forEach((warning) => {
      if (!warning.message) return;

      addWarningNotification(warning);
    });
  }, []);

  useEffect(() => {
    document.addEventListener('onWarning', handleOnWarning, false);

    return () => {
      document.removeEventListener('onWarning', handleOnWarning);
    };
  }, []);

  return <>{children}</>;
};

export default GlobalListeners;
