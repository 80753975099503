import { BaseQueryFn } from '@reduxjs/toolkit/query/react';

import { ApiError } from '@nl-lms/common/sdk';

import { api } from './api';
import { ApiServiceMethodName, ApiServiceName } from './types';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      service: ApiServiceName;
      method: ApiServiceMethodName;
      params?: any;
    },
    unknown,
    ApiError
  > =>
  async ({ service, method, params }) => {
    try {
      const result = await api[service][method](params);
      return { data: result };
    } catch (err) {
      const apiError = err as ApiError;
      return {
        error: apiError,
      };
    }
  };
