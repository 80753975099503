import _ from 'lodash';

import {
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';

import { DefaultReferenceEntities } from './constants';
import { AssignationRule } from './types';

export const getOffsetTimeMeasure = (startDate: Record<string, number>) => {
  let validMeasure;
  Object.keys(startDate).map((measure) => {
    if (startDate[measure] > 0) {
      if (measure === 'days') {
        if (startDate[measure] >= 7) validMeasure = 'weeks';
        else validMeasure = 'days';
      } else validMeasure = measure;
    }
  });

  return validMeasure ?? 'none';
};

export const parseStartDateOptions = ({ time, days, weeks, months }) => {
  let options = {};
  if (time && time !== '') {
    options = {
      hour: time,
    };
  }

  if (days && !_.isEmpty(days)) {
    if (days?.value === 'weekdays') {
      options = {
        ...options,
        skipWeekends: true,
      };
    } else if (
      days?.value === 'customDay' &&
      days?.payload &&
      days?.payload !== ''
    ) {
      options = {
        ...options,
        dayOfWeek: days?.payload,
      };
    }
  }

  if (weeks && !_.isEmpty(weeks)) {
    if (weeks?.value === 'firstWeek') {
      options = {
        ...options,
        firstWeek: true,
      };
    } else if (weeks?.value === 'lastWeek') {
      options = {
        ...options,
        lastWeek: true,
      };
    } else if (
      weeks?.value === 'customWeek' &&
      weeks?.payload &&
      weeks?.payload !== ''
    ) {
      options = {
        ...options,
        weekOfMonth: weeks?.payload,
      };
    }
  }

  if (months && !_.isEmpty(months)) {
    if (months?.value === 'firstMonth') {
      options = {
        ...options,
        firstMonth: true,
      };
    } else if (months?.value === 'lastMonth') {
      options = {
        ...options,
        lastMonth: true,
      };
    } else if (
      months?.value === 'customMonth' &&
      months?.payload &&
      months?.payload !== ''
    ) {
      options = {
        ...options,
        monthOfYear: months?.payload,
      };
    }
  }

  return options;
};

export const parseFormDataConditions = (conditions, subject, entityName) =>
  conditions?.map((condition) => {
    let parsedConditionValue = {};
    const referenceAlias =
      entityName === 'learningAssignment' ? 'reference' : 'referenceEntity';
    const referenceIdAlias =
      entityName === 'learningAssignment' ? 'referenceId' : 'referenceEntityId';
    if (
      condition.value?.[referenceIdAlias] ===
      DefaultReferenceEntities.assignmentId.value
    ) {
      parsedConditionValue = {
        ...condition.value,
        [referenceIdAlias]: subject?.id,
        [referenceAlias]: subject,
      };
    } else {
      parsedConditionValue = {
        ...condition?.value,
      };
    }

    // if (
    //   !condition.value?.learnerId ||
    //   condition.value?.learnerId === 'any-learner'
    // ) {
    //   parsedConditionValue = _.omit(parsedConditionValue, [
    //     'learner',
    //     'learnerId',
    //   ]);
    // }

    // if (!condition.value?.learnerGroupId) {
    //   parsedConditionValue = _.omit(parsedConditionValue, [
    //     'learnerGroup',
    //     'learnerGroupId',
    //   ]);
    // }

    if (condition.value?.match && !_.isEmpty(condition.value?.match)) {
      parsedConditionValue = {
        ...parsedConditionValue,
        match: condition.value?.match,
      };
    } else {
      parsedConditionValue = {
        ...parsedConditionValue,
        match: null,
      };
    }

    return condition?.combinator
      ? {
          value: parsedConditionValue,
          combinator: condition?.combinator,
        }
      : { value: parsedConditionValue };
  });

const rulePayloadAssignmentConditions = (ruleData) => {
  if (ruleData?.conditions?.length) {
    const parsedConditions = parseFormDataConditions(
      ruleData?.conditions,
      ruleData?.subject,
      'learningAssignment'
    );
    if (ruleData?.conditions?.length === 1) {
      return parsedConditions[0]?.value;
    }

    return parsedConditions;
  }
  return {};
};

export const parseFormRulesToAssignmentsPayload = (ruleData) => ({
  name: ruleData?.name,
  learningAssignmentId: ruleData?.subject?.id,
  startDate: ruleData?.startDate?.value ? ruleData?.startDate : null,
  settings: {
    triggerNTimes: ruleData?.settings?.triggerNTimes
      ? parseInt(ruleData?.settings?.triggerNTimes)
      : null,
  },
  conditionsEquation: {
    value: rulePayloadAssignmentConditions(ruleData),
  },
});

export const parseAssignmentRulePayloadToAssignmentComponent = (
  rule,
  assignmentName = ''
) => {
  const subjectId =
    rule?.learningAssignmentId || rule?.subjectId || rule?.subject?.id;
  const subjectName =
    rule?.learningAssignment?.name || rule?.subject?.name || assignmentName;

  return {
    ...rule,
    subject: {
      id: subjectId,
      name: subjectName,
    },
    conditions: (rule?.conditions as AssignationRule['conditions'])?.map(
      (cond, index) => {
        if ('value' in cond) {
          return cond;
        }
        const combinator =
          cond?.combinator ||
          rule?.conditionsEquation?.[index]?.combinator ||
          null;
        return {
          value: cond,
          combinator,
        };
      }
    ),
    settings:
      rule?.settings && rule?.settings?.triggerNTimes
        ? { triggerNTimes: `${rule?.settings?.triggerNTimes}` }
        : rule?.settings,
  } as AssignationRule;
};

export const getRulesByIdFilterQuery = (ruleIds: string[]) => ({
  query: {
    query: transformTsRestQuery({
      filters: {
        id: 'ruleAssignmentIds',
        value: [
          {
            id: 'ruleAssignmentIdFilter',
            value: {
              field: {
                field: 'id',
                label: 'id',
              },
              operator: {
                type: QueryFilterOperatorType.Binary,
                value: QueryOperator.Includes,
              },
              value: {
                value: ruleIds,
                label: 'assignationRuleIds',
              },
            },
          },
          {
            id: 'ruleActiveFilter',
            value: {
              field: {
                field: 'active',
                label: 'active',
              },
              operator: {
                type: QueryFilterOperatorType.Unary,
                value: QueryOperator.IsTrue,
              },
              value: {
                value: true,
                label: 'isTrue',
              },
            },
            combinator: QueryFilterCombinator.And,
          },
        ],
      },
    }),
  },
});

export const getRulesByAssignmentIdFilterQuery = (
  assignmentIds: string[],
  onlyActive = true
) => ({
  query: {
    query: transformTsRestQuery({
      filters: {
        id: 'ruleAssignmentIds',
        value: [
          {
            id: 'ruleAssignmentIdFilter',
            value: {
              field: {
                field: 'learning_assignment_id',
                label: 'learning_assignment_id',
              },
              operator: {
                type: QueryFilterOperatorType.Binary,
                value: QueryOperator.Includes,
              },
              value: {
                value: assignmentIds,
                label: 'ruleAssignmentIds',
              },
            },
          },
          ...(onlyActive
            ? [
                {
                  id: 'ruleActiveFilter',
                  value: {
                    field: {
                      field: 'active',
                      label: 'active',
                    },
                    operator: {
                      type: QueryFilterOperatorType.Unary,
                      value: QueryOperator.IsTrue,
                    },
                    value: {
                      value: true,
                      label: 'isTrue',
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
              ]
            : []),
        ],
      },
    }),
  },
});

export const getAssignmentsByProgramIdFilterQuery = (programId: string) => ({
  query: {
    query: transformTsRestQuery({
      filters: {
        id: 'programAssignmentId',
        value: {
          field: { field: 'learning_program_id', label: 'learningProgramId' },
          operator: {
            type: QueryFilterOperatorType.Binary,
            value: QueryOperator.Includes,
          },
          value: {
            value: [programId],
            label: 'programId',
          },
        },
      },
    }),
  },
});
