import store from 'store';

import { getClients } from '@nl-lms/feature/certification/sdk';

import { apiBaseUrl } from './api';

const { raw: certificationApiClient, rtk: certificationRtkClient } = getClients(
  {
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  }
);

export { certificationApiClient };

export const certificationApi = certificationRtkClient;
certificationApi.enhanceEndpoints<'Certification' | 'CertificationInstance'>({
  endpoints: {
    listCertifications: {
      providesTags: [{ type: 'Certification', id: 'LIST' }],
    },
    createCertification: {
      invalidatesTags: [{ type: 'Certification', id: 'LIST' }],
    },
    updateCertification: {
      invalidatesTags: [{ type: 'Certification', id: 'LIST' }],
    },
    removeCertification: {
      invalidatesTags: [{ type: 'Certification', id: 'LIST' }],
    },
    getCertification: { providesTags: [{ type: 'Certification', id: 'LIST' }] },
    downloadCertificate: {
      providesTags: [{ type: 'CertificationInstance', id: 'LIST' }],
    },
  },
});
type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type CertificationApiMutationNames = FilterEndingWith<
  keyof typeof certificationApi,
  'Mutation'
>;
