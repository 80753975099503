import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Competency } from '@nl-lms/common/feature/types';
import {
  ContentLibraryItem,
  ContentLibraryItemType,
} from '@nl-lms/feature/content-library/sdk';
import { Button, Icon } from '@nl-lms/ui/components';

import { contentLibraryItemsApi } from '../../../../_common/services/api';
import { LearnerLearningTile } from '../../../modules/LearnerLearningTile/LearnerLearningTile';

type LearnerLibraryListCardProps = {
  id: string;
  description: string | null;
  thumbnail: string | null;
  name: string;
  type: ContentLibraryItemType;
  url: string;
  competencies: Omit<Competency, 'createdAt' | 'updatedAt'>[];
};

export const LearnerLibraryListCard = ({
  id,
  description,
  thumbnail,
  name,
  type,
  url,
  competencies,
}: LearnerLibraryListCardProps) => {
  const { t } = useTranslation('learner');

  const itemTypeInfo = useMemo(() => {
    let icon = <></>;
    let badgeType = 'default';
    let detail = '';
    if (type === ContentLibraryItemType.Article) {
      icon = <Icon.FileIcon />;
      badgeType = 'info';
      detail = t('learningunit.article');
    } else if (type === ContentLibraryItemType.Document) {
      icon = <Icon.FolderIcon />;
      badgeType = 'info';
      detail = t('learningunit.document');
    } else if (type === ContentLibraryItemType.Media) {
      icon = <Icon.ImageIcon />;
      badgeType = 'success';
      detail = t('learningunit.media');
    } else if (type === ContentLibraryItemType.Book) {
      icon = <Icon.BookIcon />;
      badgeType = 'alert';
      detail = t('learningunit.book');
    } else if (type === ContentLibraryItemType.WebPage) {
      icon = <Icon.MonitorIcon />;
      badgeType = 'warning';
      detail = t('learningunit.webpage');
    }
    return { icon, type: badgeType, detail };
  }, [type]);

  const onOpenClick = () => {
    window.open(url, '_blank', 'noopener');
  };

  const libraryItemAction = (type: ContentLibraryItemType) => {
    switch (type) {
      case ContentLibraryItemType.Article:
      case ContentLibraryItemType.Document:
        return t('common.learningactivitycard.read');
      case ContentLibraryItemType.Media:
        return t('common.learningactivitycard.view');
      case ContentLibraryItemType.Book:
        return t('common.learningactivitycard.borrow');
      case ContentLibraryItemType.WebPage:
        return t('common.learningactivitycard.access');
      default:
        return '';
    }
  };

  const actions = useMemo(
    () => [
      <Button
        regular
        onClick={onOpenClick}
        label={libraryItemAction(type)}
        small
      />,
    ],
    [type],
  );

  return (
    <LearnerLearningTile style={{ minWidth: 'auto' }}>
      <LearnerLearningTile.Thumbnail imageUrl={thumbnail} />
      <LearnerLearningTile.Title>{name}</LearnerLearningTile.Title>
      <LearnerLearningTile.TitleIcon type={itemTypeInfo.type}>
        {itemTypeInfo.icon}
      </LearnerLearningTile.TitleIcon>
      <LearnerLearningTile.Detail>
        {itemTypeInfo.detail}
      </LearnerLearningTile.Detail>
      <LearnerLearningTile.Description>
        {description}
      </LearnerLearningTile.Description>
      <LearnerLearningTile.Tags>
        {competencies?.length
          ? competencies?.map((tag) => tag.title)
          : [t('learningunit.nocompetencies')]}
      </LearnerLearningTile.Tags>
      {actions.map((action, index) => (
        <LearnerLearningTile.Action key={index}>
          {action}
        </LearnerLearningTile.Action>
      ))}
    </LearnerLearningTile>
  );
};
