import React from 'react';

import { useClassNameProps } from '../../hooks';
import './Sensitive.scss';

type Display = 'inline' | 'inline-block' | 'block' | 'none';
export const Sensitive = ({
  children,
  display,
  ...props
}: {
  children: React.ReactNode;
  display?: Display;
  className?: string;
}) => {
  const style: { display?: Display } = {};
  if (display) style.display = display;

  const classNameProps = useClassNameProps('sensitive', 'ph-no-capture', props);

  return (
    <span {...classNameProps} style={style}>
      {children}
    </span>
  );
};
