import { DomainEventPayload } from '@nl-lms/common/shared';

export const ElearningRouterPrefix = '/elearning';

export const ElearningScope = {
  viewElearningCourse: { resource: 'elearning_course', action: 'view' },
  createElearningCourse: {
    resource: 'elearning_course',
    action: 'create',
  },
  deleteElearningCourse: {
    resource: 'elearning_course',
    action: 'delete',
  },
  editElearningCourse: {
    resource: 'elearning_course',
    action: 'edit',
  },
  portal: {
    viewElearningCourse: {
      resource: 'portal_elearning_course',
      action: 'view',
    },
    viewElearningSession: {
      resource: 'portal_elearning_course',
      action: 'view',
    },
  },
  viewContentFile: {
    resource: 'content_file',
    action: 'view',
  },
  editContentFile: {
    resource: 'content_file',
    action: 'edit',
  },
  createContentFile: {
    resource: 'content_file',
    action: 'create',
  },
  deleteContentFile: {
    resource: 'content_file',
    action: 'delete',
  },
} as const;

export enum ElearningDomainEventNames {
  ElearningSessionStarted = 'elearning-session.started',
  ElearningSessionCompleted = 'elearning-session.completed',
  ElearningSessionFailed = 'elearning-session.failed',
}

interface BaseElearningSessionEventPayload extends DomainEventPayload {
  learnerId: string;
  elearningSessionId: string;
  learningAssignmentId: string;
  learningAssignmentInstanceId: string;
  learningProgramInstanceId: string | null;
}

export type ElearningSessionStartedEvent = BaseElearningSessionEventPayload;
export type ElearningSessionCompletedEvent = BaseElearningSessionEventPayload;
export type ElearningSessionFailedEvent = BaseElearningSessionEventPayload;

export enum ContentFileVersionRecord {
  SCORM12 = '1.2',
  SCORM2004 = '2004',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  SHAREPOINT = 'sharepoint',
  WEB = 'web',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  MP4 = 'mp4',
  WEBM = 'webm',
  OGG = 'ogg',
}
