import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryFilterCombinator } from '@nl-lms/common/shared';
import { Icon, Tooltip } from '@nl-lms/ui/components';

import { useListParserModal } from '../../../components/ListParserModal/ListParserModal';
import { useTracker } from '../../tracking';
import { FilterBarContext } from '../FilterBarContext/FilterBarContext';

export const FilterBarPasteListButton = () => {
  const { onAddFilters, pasteFromListFields, fields, onPasteFromList } =
    useContext(FilterBarContext);
  const showListParserModal = useListParserModal();
  const tracker = useTracker();
  const { t } = useTranslation('learner');
  const pasteableFields = fields.filter((f) =>
    pasteFromListFields.includes(f.name)
  );
  const onClickIcon = useCallback(async () => {
    const result = await showListParserModal({
      title: t('common.filterbar.pastevalues'),
      fieldOptions: pasteableFields.map((f) => ({
        value: f.name,
        label: f.label,
      })),
    });

    if (result && result.list.length) {
      const field = fields.find((f) => f.name === result.field);
      if (!field) return;
      const filters = await onPasteFromList(
        result.list,
        field,
        result.operator
      );
      onAddFilters(filters, QueryFilterCombinator.Or);
    }
  }, []);

  if (!pasteFromListFields.length) return null;
  return (
    <Tooltip
      title="Paste From A List"
      onClick={onClickIcon}
      className="filter-bar__input-icon"
    >
      <Icon.ClipboardIcon />
    </Tooltip>
  );
};
