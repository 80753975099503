import React from 'react';

export const PowerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18.365 5.63989C19.6234 6.89868 20.4803 8.50233 20.8273 10.2481C21.1743 11.9938 20.9959 13.8033 20.3146 15.4476C19.6334 17.092 18.4798 18.4974 16.9998 19.4862C15.5199 20.475 13.7799 21.0028 12 21.0028C10.2201 21.0028 8.48016 20.475 7.00018 19.4862C5.5202 18.4974 4.36664 17.092 3.68537 15.4476C3.00409 13.8033 2.82569 11.9938 3.17272 10.2481C3.51975 8.50233 4.37663 6.89868 5.635 5.63989"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M12.0049 2V10"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);
