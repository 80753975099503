import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

export const NavigationTabs = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  const { pathname } = useLocation();
  const multiPathSeparator = '--';

  const tabPaths = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (
          Array.isArray(child?.props?.path) &&
          child?.props?.path?.length > 0
        ) {
          let mergedPaths = '';
          child?.props?.path.map(
            (path) =>
              (mergedPaths = mergedPaths + `${path}${multiPathSeparator}`)
          );
          return mergedPaths;
        }
        return child?.props?.path;
      }),
    [children]
  );

  const tabVisibility = useMemo<boolean[]>(
    () =>
      React.Children.map(children, (child) => {
        return !child?.props?.hidden;
      }),
    [children]
  );

  const activeTabIndex = useMemo(() => {
    const tabIndex = tabPaths.findIndex((tp) => {
      if (tp.includes(multiPathSeparator)) {
        const pathValues = tp
          .split(multiPathSeparator)
          .filter((el) => el !== '');
        if (pathValues.length > 0) {
          return pathValues.some((pv) => pathname.includes(pv));
        }
      }
      return pathname.includes(tp);
    });
    const firstVisibleTabIndex = tabVisibility.findIndex((tv) => tv);

    if (tabIndex !== -1 && tabVisibility[tabIndex]) return tabIndex;
    return firstVisibleTabIndex;
  }, [pathname, tabPaths, tabVisibility]);

  return (
    <div className="new-tabs" {...commonProps}>
      <div className="new-tabs__list">
        {React.Children.map(children, (child, index) => {
          if (child) {
            if (!child?.props?.hidden) {
              return React.cloneElement(child, {
                isActive: index === activeTabIndex,
              });
            } else {
              return null;
            }
          }
          return child;
        })}
      </div>
    </div>
  );
};

export const NavigationTab = ({
  path,
  isActive = false,
  children,
  onClick = null,
  hidden = false,
  ...props
}) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'new-tabs__list-item',
    isActive ? 'new-tabs__list-item--selected' : null
  );
  const navigate = useNavigate();
  const goToRoute = useCallback(() => {
    if (Array.isArray(path) && path.length > 0) {
      // @ts-ignore
      if (onClick) onClick(path[0]);
      navigate(path[0]);
    } else {
      // @ts-ignore
      if (onClick) onClick(path);
      navigate(path);
    }
  }, []);
  return (
    <div {...classNameProps} {...commonProps} onClick={goToRoute}>
      {children}
    </div>
  );
};

NavigationTabs.Tab = NavigationTab;
