export const useTestProps = (
  testProps: { tid?: string },
  {
    defaultValue = '',
    passThrough = false,
  }: { defaultValue?: string; passThrough?: boolean } = {}
): { 'data-tid'?: string; tid?: string } => {
  let value = testProps.tid || defaultValue;
  if (!value) return {};

  if (passThrough) return { tid: value };
  else return { 'data-tid': value };

  return {};
};
