import { SingleSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

const AdminVendorTypeSelect = ({
  onChange,
  name = 'vendor-type-select',
  initialValue = null,
  isClearable = false,
  value = null,
  hasError = false,
}) => (
  <SingleSelect
    onChange={onChange}
    options={_.map(C.VENDOR_TYPES, (label, value) => ({
      value: parseInt(value),
      label: formatConstantString(label),
    }))}
    placeholder="Select Type"
    isClearable={isClearable}
    name={name}
    // @ts-ignore
    selectedItem={value}
    // @ts-ignore
    initialSelectedItem={initialValue}
    hasError={hasError}
  />
);

export default AdminVendorTypeSelect;
