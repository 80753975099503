import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@nl-lms/ui/components';

import { Modal, createModalContext, useModal } from '../Modal/Modal';

export const ConfirmationModalContext = createModalContext();

type ConfirmationModalProps = {
  message: string;
  title?: string;
  confirmLabel?: string;
  declineLabel?: string;
  confirmButtonType?: 'alert' | 'default';
};

type ConfirmationModalResult = { confirmed: boolean };

type ConfirmationModalContextType = {
  activeModal: ConfirmationModalProps | null;
  showModal: (
    modalProps: ConfirmationModalProps,
    resolveFn: (result: ConfirmationModalResult) => void
  ) => void;
  closeModal: (result: ConfirmationModalResult) => void;
};

const ConfirmationModelContext = React.createContext(
  {} as ConfirmationModalContextType
);

export const ConfirmationModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState<ConfirmationModalProps | null>(
    null
  );
  const resolveFnRef = useRef<
    ((result: ConfirmationModalResult) => void) | null
  >(null);
  const onClose = useCallback(() => {
    setActiveModal(null);
    resolveFnRef.current = null;
  }, []);
  const showModal = useCallback(
    (modalProps: ConfirmationModalProps, resolveFn) => {
      setActiveModal(modalProps);
      resolveFnRef.current = resolveFn;
    },
    []
  );
  const closeModal = useCallback((result: ConfirmationModalResult) => {
    setActiveModal(null);
    if (resolveFnRef.current) resolveFnRef.current(result);
  }, []);
  return (
    <ConfirmationModelContext.Provider
      value={{ activeModal, showModal, closeModal }}
    >
      {children}

      {activeModal ? (
        <Modal.Provider defaultOpened onClose={onClose}>
          <ConfirmationModal {...activeModal} />
        </Modal.Provider>
      ) : null}
    </ConfirmationModelContext.Provider>
  );
};

export const useConfirmationModal = (props: ConfirmationModalProps) => {
  const { showModal } = React.useContext(ConfirmationModelContext);
  return useCallback(() => {
    return new Promise<ConfirmationModalResult>((resolve) => {
      showModal(props, resolve);
    });
  }, [props]);
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation('ui');
  const {
    message,
    title = t('confirmationModal.defaultTitle'),
    confirmLabel = t('confirmationModal.defaultConfirmButtonName'),
    declineLabel = t('confirmationModal.defaultDeclineButtonName'),
    confirmButtonType = 'default',
  } = props;
  const { closeModal } = React.useContext(ConfirmationModelContext);
  const onDecline = useCallback(() => closeModal({ confirmed: false }), []);
  const onConfirm = useCallback(() => closeModal({ confirmed: true }), []);
  return (
    <Modal.Content small lock>
      <Modal.Header withCloseAction={false}>{title}</Modal.Header>
      <Typography.p>{message}</Typography.p>
      <Modal.Actions>
        <Button
          label={confirmLabel}
          onClick={onConfirm}
          alert={confirmButtonType === 'alert'}
        />
        <Button label={declineLabel} onClick={onDecline} regular />
      </Modal.Actions>
    </Modal.Content>
  );
};
