import React, { useMemo, useState } from 'react';

import { SingleSelect } from '@nl-lms/ui/components';
import { adminApi } from '../../../_common/services/api';

type Props = {
  onChange: (itemIds: string[]) => void;
  name: string;
  isClearable?: boolean;
  hasError?: boolean;
  returnEntireItemOnChange?: boolean;
  clearSelection?: boolean;
  excludedItems?: any[];
  disabled?: boolean;
  initialSelectedItem?: { label: any; value: any };
};

const { useListChecklistItemsForSelectQuery } = adminApi;

export const AdminChecklistItemSingleSelect = (props: Props) => {
  const {
    onChange,
    name,
    hasError = false,
    isClearable = false,
    returnEntireItemOnChange = false,
    clearSelection = false,
    excludedItems = [],
    disabled = false,
    initialSelectedItem = null,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const { data, isLoading } = useListChecklistItemsForSelectQuery(inputValue);
  const options = useMemo(() => {
    if (!data) return [];
    return data
      .map((item) => ({
        label: item.title,
        value: item.id,
        item,
      }))
      .filter((item) => !excludedItems.includes(item.value));
  }, [data]);
  return (
    <SingleSelect
      name={name}
      onChangeInputValue={setInputValue}
      isLoading={isLoading}
      placeholder="Search by item name"
      onChange={onChange}
      options={options}
      isClearable={isClearable}
      returnEntireItemOnChange={returnEntireItemOnChange}
      hasError={hasError}
      shouldClearSelection={clearSelection}
      disabled={disabled}
// @ts-ignore
      initialSelectedItem={initialSelectedItem}
    />
  );
};
