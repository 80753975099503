import React from 'react';
import { useTranslation } from 'react-i18next';

import { MultiSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';

const parseSelectedItems = (selectedItems, options) => {
  if (!selectedItems) return undefined;
  return selectedItems.reduce((acc, item) => {
    const option = options.find((o) => o.value === item);
    if (!option) return acc;
    return [...acc, option];
  }, []);
};

export const AdminVenueFeaturesSelect = ({
  onChange,
  name = 'course-req-select',
  initialValue = [],
  value = null,
  placeholder = 'Select features',
}) => {
  const { t } = useTranslation('learner');
  const options = _.map(C.VENUE_FEATURES, (feature) => ({
    value: feature,
    // @ts-ignore
    label: t(`assets.body.venueFeatures.${feature}`) as string,
  }));
  return (
    <MultiSelect
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      initialSelectedItems={initialValue}
      name={name}
      selectedItems={parseSelectedItems(value, options)}
    />
  );
};
