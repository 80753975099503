import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useShowModal } from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';

export const useShowEntityEditSideModalForm = ({
  ModalComponent,
  entityName,
  buildInitialEntity,
}: {
  ModalComponent: React.FC;
  entityName: string;
  buildInitialEntity?: (val: unknown) => unknown;
}) => {
  const dispatch = useDispatch();
  const showSideModalForm = useShowModal(ModalComponent);
  const { addAlertNotification } = useNotifications();
  return useCallback(
    async (row) => {
      let entity = row;
      if (buildInitialEntity) {
        const result = await buildInitialEntity(row);
        if (!result) {
          addAlertNotification({ message: 'Unable to load form data' });
          return;
        }
        entity = result;
      }
      return await showSideModalForm({ [entityName]: entity });
    },
    [showSideModalForm, dispatch]
  );
};
