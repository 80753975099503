import React, { forwardRef, useMemo } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './Checkbox.scss';

export type CheckboxProps = TidComponent<{
  label?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  className?: string;
  title?: string;
  readOnly?: boolean;
  name: string;
  success?: boolean;
  value?: any;
  hasError?: boolean;
  small?: boolean;
}>;

type Ref = HTMLInputElement;

export const Checkbox = forwardRef<Ref, CheckboxProps>((props, ref) => {
  const {
    label = '',
    checked = null,
    disabled = false,
    defaultChecked = false,
    className = '',
    readOnly = false,
    name,
    hasError = false,
    success = false,
    small = false,
    ...rest
  } = props;

  const commonProps = useTestProps(props);

  const classNameProps = useClassNameProps(
    'checkbox',
    props,
    success ? 'checkbox--success' : null
  );
  const inputClassNameProps = useClassNameProps(
    'checkbox__input',
    hasError ? 'checkbox__input--has-error' : null,
    readOnly ? 'checkbox__input--readonly' : null,
    small ? 'checkbox__input--small' : null
  );
  const labelClassNameProps = useClassNameProps(
    'checkbox__label',
    disabled ? 'checkbox__label--disabled' : null,
    readOnly ? 'checkbox__label--readonly' : null
  );

  const checkedProps = useMemo(() => {
    if (defaultChecked) {
      return { defaultChecked };
    } else {
      return { checked: checked as boolean };
    }
  }, [checked, defaultChecked]);

  return (
    <label {...classNameProps} {...commonProps}>
      <input
        disabled={disabled || readOnly}
        name={name}
        ref={ref}
        type="checkbox"
        value={props?.value}
        {...inputClassNameProps}
        {...checkedProps}
        {...rest}
      />
      {label ? <span {...labelClassNameProps}>{label}</span> : null}
    </label>
  );
});
