import * as z from 'zod';

import { FieldConditionSchema, TimeDeltaSchema } from '@nl-lms/common/shared';

export type RuleConditionsEquation = {
  value: string | RuleConditionsEquation[];
  combinator?: 'and' | 'or' | null;
};

export const RuleConditionsEquationSchema: z.ZodType<RuleConditionsEquation> =
  z.object({
    value: z.union([
      z.string(),
      z.lazy(() => z.array(RuleConditionsEquationSchema)),
    ]),
    combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
  });

export const ScheduleDateSchema = z
  .union([
    z.object({
      type: z.literal('fixed'),
      value: z.string().nullable(),
    }),
    z.object({
      type: z.literal('relative'),
      value: TimeDeltaSchema.nullable(),
    }),
  ])
  .nullable();

export type ScheduleDate = z.infer<typeof ScheduleDateSchema>;

export const RuleConditionSchema = z.object({
  id: z.string(),
  ruleId: z.string(),
  eventName: z.string(),
  referenceEntityId: z.string(),
  match: FieldConditionSchema.nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RuleCondition = z.infer<typeof RuleConditionSchema>;

export const RuleSettingsSchema = z.object({
  triggerNTimes: z.number().nullish(),
});

export const RuleSchema = z.object({
  id: z.string(),
  name: z.string(),
  automationFlowId: z.string().nullable(),
  actionName: z.string(),
  entityName: z.string(),
  entityId: z.string(),
  conditionsEquation: RuleConditionsEquationSchema,
  scheduleDate: ScheduleDateSchema.nullable(),
  active: z.boolean(),
  settings: RuleSettingsSchema,
  payload: z.record(z.string(), z.unknown()).nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Rule = z.infer<typeof RuleSchema>;

export enum ActionStatus {
  planned,
  scheduled,
  queued,
  performed,
  failed,
  canceled,
}

export const ActionSchema = z.object({
  id: z.string(),
  automationFlowId: z.string().nullable(),
  entityName: z.string(),
  entityId: z.string(),
  entityInstanceId: z.string().nullable(),
  name: z.string(),
  learnerId: z.string(),
  ruleId: z.string().nullable(),
  triggersEquation: RuleConditionsEquationSchema.nullish(),
  scheduledOn: z.date().nullable(),
  payload: z.record(z.string(), z.unknown()).nullable(),
  status: z.nativeEnum(ActionStatus),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Action = z.infer<typeof ActionSchema>;

export const ActionTriggerSchema = z.object({
  id: z.string(),
  actionId: z.string(),
  learnerId: z.string(),
  referenceEntityId: z.string(),
  eventName: z.string(),
  triggered: z.boolean(),
  triggeredOn: z.date().nullable(),
  triggeredBy: z.string().nullable(),
  match: FieldConditionSchema.nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ActionTrigger = z.infer<typeof ActionTriggerSchema>;

export const AutomationFlowSchema = z.object({
  id: z.string(),
  entityId: z.string(),
  entityName: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  active: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AutomationFlow = z.infer<typeof AutomationFlowSchema>;
