import { useCallback, useEffect, useMemo } from 'react';

export const supportsBroadcastAPI = (() => window && window.BroadcastChannel)();
export const useBroadcastChannel = ({
  channel,
  onMessageReceived: _onMessageReceived,
}: {
  channel: string;
  onMessageReceived?: (...args: any[]) => any;
}) => {
  if (!supportsBroadcastAPI) {
    console.warn('Broadcast channels not supported.');
    return {
      broadcastMessage: () => {},
    };
  }

  const broadcastChannel = useMemo(
    () => new BroadcastChannel(channel),
    [channel]
  );

  const onMessageReceived = useCallback(
    (...args) => {
      if (!_onMessageReceived) return;

      _onMessageReceived(...args);
    },
    [_onMessageReceived]
  );

  useEffect(() => {
    broadcastChannel.onmessage = (...args) => {
      onMessageReceived(...args);
    };
  }, [broadcastChannel, onMessageReceived]);

  const broadcastMessage = useCallback(
    (message) => {
      broadcastChannel.postMessage(message);
    },
    [broadcastChannel]
  );

  return { broadcastMessage };
};
