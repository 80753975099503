import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  IApiMethods,
  IApiService,
  Checklist as IChecklist,
  ChecklistItem as IChecklistItem,
} from './types';

export class Checklist
  extends ApiService
  implements IApiService<'checklist'>, IApiMethods<'checklist'>
{
  static serviceBase = '/checklist';
  public paths = {
    create: () => `${Checklist.serviceBase}`,
    list: () => `${Checklist.serviceBase}`,
    bulkDelete: () => `${Checklist.serviceBase}`,
    get: (id) => `${Checklist.serviceBase}/${id}`,
    update: (id) => `${Checklist.serviceBase}/${id}`,
    remove: (id) => `${Checklist.serviceBase}/${id}`,
    listItems: () => `${Checklist.serviceBase}/item`,
    createItem: () => `${Checklist.serviceBase}/item`,
    bulkDeleteItems: () => `${Checklist.serviceBase}/item`,
    getItem: (itemId) => `${Checklist.serviceBase}/item/${itemId}`,
    updateItem: (itemId) => `${Checklist.serviceBase}/item/${itemId}`,
    listInstances: () => `${Checklist.serviceBase}/instance`,
    listItemInstances: (instanceId) =>
      `${Checklist.serviceBase}/instance/${instanceId}/item/all`,
    listItemInstancesByChecklistId: (id) =>
      `${Checklist.serviceBase}/${id}/instance/item/all`,
    listForSelect: () => `/list/checklist`,
    listItemsForSelect: () => `/list/checklist/item`,
    bulkDeleteInstances: () => `${Checklist.serviceBase}/instance`,
  };

  get = (id) => this.api.get(this.paths.get(id));
  getItem = (itemId) => this.api.get(this.paths.getItem(itemId));
  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));
  createItem = (itemEntity) =>
    this.api.post(this.paths.createItem(), snakelize(itemEntity));
  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));
  updateItem = (itemEntity) =>
    this.api.patch(this.paths.updateItem(itemEntity.id), snakelize(itemEntity));
  remove = ({ id }) => this.api.delete(this.paths.remove(id));
  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });
  bulkDeleteItems = (itemIds) =>
    this.api.delete(this.paths.bulkDeleteItems(), { data: { list: itemIds } });
  bulkDeleteInstances = (instanceIds) =>
    this.api.delete(this.paths.bulkDeleteInstances(), {
      data: { list: instanceIds },
    });
  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), {
      params: { query },
    });
  listItems = (query: AppQuery) =>
    this.api.get(this.paths.listItems(), {
      params: { query },
    });
  listInstances = (query: AppQuery) =>
    this.api.get(this.paths.listInstances(), {
      params: { query },
    });
  listItemInstances = (instanceId: string) =>
    this.api.get(this.paths.listItemInstances(instanceId));

  listItemInstancesByChecklistId = (id: string) =>
    this.api.get(this.paths.listItemInstancesByChecklistId(id));

  listForSelect = (search = '') =>
    this.api.get<null, IChecklist[]>(this.paths.listForSelect(), {
      params: {
        query: { search },
      },
    });

  listItemsForSelect = (search = '') =>
    this.api.get<null, IChecklistItem[]>(this.paths.listItemsForSelect(), {
      params: {
        query: { search },
      },
    });
}
