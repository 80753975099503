import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { Command } from 'cmdk';
import React from 'react';

import './CommandMenu.scss';

export const CommandMenuRoot = ({
  shouldFilter = false,
  className = '',
  loop = true,
  ...rest
}: React.ComponentProps<typeof Command>) => {
  return (
    <Command
      shouldFilter={shouldFilter}
      loop={loop}
      className={clsx('command-menu', className)}
      {...rest}
    />
  );
};

const CommandMenuDialog = ({
  isOpen,
  setIsOpen,
  children,
}: React.PropsWithChildren<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="command-menu__modal-overlay" />
        <Dialog.Content className="command-menu__modal-content">
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const CommandMenuInput = ({
  className,
  ...rest
}: React.ComponentProps<typeof Command.Input>) => {
  return (
    <Command.Input
      className={clsx('command-menu__input', className)}
      {...rest}
    />
  );
};

const CommandMenuHeader = ({ children }) => {
  return <div className="command-menu__header">{children}</div>;
};

const CommandMenuEmpty = ({
  children,
  hide = false,
  ...rest
}: React.ComponentProps<typeof Command.Empty> & { hide?: boolean }) => {
  if (!children) {
    return (
      <Command.Empty
        className="command-menu__empty"
        data-is-visible={!hide}
        {...rest}
      >
        No options available
      </Command.Empty>
    );
  }
  return (
    <Command.Empty
      className="command-menu__empty"
      data-is-visible={!hide}
      {...rest}
    >
      {children}
    </Command.Empty>
  );
};

const CommandMenuList = ({
  className,
  ...rest
}: React.ComponentProps<typeof Command.List>) => {
  return (
    <Command.List className={clsx('command-menu__list', className)} {...rest} />
  );
};

const CommandMenuGroup = ({
  className,
  ...rest
}: React.ComponentProps<typeof Command.Group>) => {
  return (
    <Command.Group
      className={clsx('command-menu__group', className)}
      {...rest}
    />
  );
};

const CommandMenuItem = ({
  className,
  ...rest
}: React.ComponentProps<typeof Command.Item>) => {
  return (
    <Command.Item className={clsx('command-menu__item', className)} {...rest} />
  );
};

const CommandMenuLoading = ({
  className,
  show = true,
  ...rest
}: React.ComponentProps<typeof Command.Loading> & { show?: boolean }) => {
  return (
    <Command.Loading
      className={clsx('command-menu__loading', className)}
      data-is-visible={show}
      {...rest}
    />
  );
};

const CommandMenuSeparator = ({
  className,
  ...rest
}: React.ComponentProps<typeof Command.Separator>) => (
  <Command.Separator
    className={clsx('command-menu__separator', className)}
    {...rest}
  />
);

export const CommandMenu = Object.assign(CommandMenuRoot, {
  Input: CommandMenuInput,
  Empty: CommandMenuEmpty,
  List: CommandMenuList,
  Item: CommandMenuItem,
  Loading: CommandMenuLoading,
  Separator: CommandMenuSeparator,
  Dialog: CommandMenuDialog,
  Group: CommandMenuGroup,
  Header: CommandMenuHeader,
});
