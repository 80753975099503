import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AppQuery, FilterOperator } from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import { PrettyDate, Table, useTableRowSelection } from '@nl-lms/ui/components';

import { learningAssignmentsApi, useApi } from '../../../_common/services/api';

const { useListLearningAssignmentsQuery } = learningAssignmentsApi;

interface Props {
  onChange: (ids: string[]) => void;
  elearningCourseId: string;
  value?: string[];
  hasError?: boolean;
}
export const AdminLearningAssignmentSelect = (props: Props) => {
  const { onChange, elearningCourseId, value: initialValue = [] } = props;
  if (!elearningCourseId) return <>No assignments</>;

  const [value, setValue] = useState(initialValue);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    rowCount: 0,
  });

  const api = useApi();

  const query = useMemo<AppQuery>(
    () => ({
      pagination,
      filters: {
        id: '',
        value: [
          {
            id: '',
            value: {
              operator: FilterOperator.Equals,
              value: { label: '', value: elearningCourseId },
              field: { label: '', field: 'elearning_course_id' },
            },
          },
        ],
      },
    }),
    [elearningCourseId, pagination]
  );

  const { data, isLoading } = useListLearningAssignmentsQuery({
    query: { query: transformTsRestQuery(query) },
  });
  const learningAssignments = data?.rows || [];
  const { selectedRows, onSelectRow } = useTableRowSelection({
    data: learningAssignments,
    initialSelectedRows: [],
  });

  const onChangePagination = useCallback(
    (newPagination) => {
      setPagination(newPagination);
    },
    [setPagination]
  );

  useEffect(() => {
    //@ts-ignore
    onChange(selectedRows.map((r) => r.id));
  }, [selectedRows]);

  return (
    <div className="assignment-table">
      <Table
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => {
              if (row.original.name) {
                return row.original.name;
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Total',
            accessor: 'total',
            maxWidth: 71,
            Cell: ({ row }) => row.original.total,
          },
          {
            Header: 'Mandatory',
            accessor: 'mandatory',
            sortField: 'mandatory',
            maxWidth: 110,
            Cell: ({ row }) => (row.original.mandatory ? 'Yes' : 'No'),
          },
          {
            Header: 'Due Date',
            accessor: 'dueDate',
            sortField: 'dueDate',
            Cell: ({ row }) => (
              <PrettyDate value={row.original.dueDate} withTime />
            ),
          },
          {
            Header: 'Start Date',
            accessor: 'startDate',
            sortField: 'startDate',
            Cell: ({ row }) => (
              <PrettyDate value={row.original.startDate} withTime />
            ),
          },
          {
            Header: 'Assigned At',
            accessor: 'createdAt',
            Cell: ({ row }) => (
              <PrettyDate value={row.original.createdAt} withTime />
            ),
          },
        ]}
        data={learningAssignments}
        isLoading={isLoading}
        // @ts-ignore
        pagination={{
          ...query.pagination,
          rowCount: data?.count || 0,
        }}
        onChangePagination={onChangePagination}
        // @ts-ignore
        selectedRows={selectedRows}
        onSelectRow={onSelectRow}
      />
    </div>
  );
};
