import { chunk } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { dateFns } from '@nl-lms/vendor';

import { Card } from '../Card/Card';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import * as Icons from '../Icon';
import * as Icon from '../Icon';
import { ReactPortal } from '../ReactPortal/ReactPortal';
import { Separator } from '../Separator/Separator';
import { DialogTooltip } from '../Tooltip/Tooltip';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './Timeline.scss';
import {
  TimelineContextType,
  TimelineDayEvent,
  TimelineDayEventWithGridPosition,
} from './Timeline.types';
import {
  addDays,
  getAllDatesBetweenTwoDates,
  getMonday,
  orderEvents,
  subtractDays,
} from './Timeline.utils';

// @ts-ignore
const TimelineContext = createContext<TimelineContextType>(null);
const useTimelineContext = () => useContext(TimelineContext);

type TimelineProps = TidComponent<{
  events: TimelineDayEvent[];
  title: string;
  onChangeDates?: (start: Date, end: Date) => void;
  showUpcomingEvents?: boolean;
  className?: string;
  tooltipHeight?: number;
}>;

export const Timeline = ({
  title,
  onChangeDates,
  events,
  showUpcomingEvents = false,
  className,
  tooltipHeight = 120,
  ...props
}: TimelineProps) => {
  const commonProps = useTestProps(props, { passThrough: true });

  return (
    <TimelineProvider className={className} {...commonProps}>
      <TimelineHeader>
        <TimelineName>{title}</TimelineName>
        {onChangeDates ? (
          <TimelineNavigation
            onChange={onChangeDates}
            showUpcomingEvents={showUpcomingEvents}
          />
        ) : null}
      </TimelineHeader>
      <TimelineBody events={events} tooltipHeight={tooltipHeight} />
    </TimelineProvider>
  );
};

export const TimelineProvider = ({
  children,
  defaultWeeks = 1,
  defaultCurrentDate = new Date(),
  ...props
}) => {
  const commonProps = useTestProps(props, { passThrough: true });
  const classNameProps = useClassNameProps('timeline', props);

  const currentDate = useMemo(() => defaultCurrentDate, []);
  const [weeks, setWeeks] = useState(defaultWeeks);
  const [relativeDate, setRelativeDate] = useState(currentDate);

  const startDate = useMemo(
    () => dateFns.startOfWeek(relativeDate, { weekStartsOn: 1 }),
    [relativeDate]
  );
  const endDate = useMemo(
    () =>
      dateFns.endOfDay(
        addDays(
          dateFns.startOfWeek(relativeDate, { weekStartsOn: 1 }),
          weeks * 7 - 1
        )
      ),
    [relativeDate, weeks]
  );
  const dates = useMemo(
    () => getAllDatesBetweenTwoDates(startDate, endDate),
    [startDate, endDate]
  );

  return (
    <TimelineContext.Provider
      value={{
        dates,
        currentDate,
        startDate,
        endDate,
        setRelativeDate,
        setWeeks,
        relativeDate,
        weeks,
      }}
    >
      <Card paddingType="none" {...classNameProps} {...commonProps}>
        <div className="timeline__content">{children}</div>
      </Card>
    </TimelineContext.Provider>
  );
};

export const TimelineHeader = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="timeline__header" {...commonProps}>
      <div className="timeline__header-content">{children}</div>
      <Separator marginBottom={10} marginTop={20} />
    </div>
  );
};

export const TimelineActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="timeline__actions" {...commonProps}>
      {children}
    </div>
  );
};

export const TimelineName = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="timeline__title" {...commonProps}>
      <Typography.h3>{children}</Typography.h3>
    </div>
  );
};

export const TimelineNavigation = ({
  onChange,
  showUpcomingEvents,
  ...props
}) => {
  const {
    setRelativeDate,
    setWeeks,
    relativeDate,
    startDate,
    endDate,
    currentDate,
    weeks,
  } = useTimelineContext();

  const commonProps = useTestProps(props);

  const goToNextWeek = useCallback(() => {
    setRelativeDate(addDays(relativeDate, weeks * 7));
  }, [relativeDate]);

  const goToPreviousWeek = useCallback(() => {
    setRelativeDate(subtractDays(relativeDate, weeks * 7));
  }, [relativeDate]);

  const isOnChangeEffectFirstRun = useRef(true);
  useEffect(() => {
    if (isOnChangeEffectFirstRun.current) {
      isOnChangeEffectFirstRun.current = false;
      return;
    }
    onChange(startDate, endDate);
  }, [startDate, endDate]);

  const weekSelectorOptions = useMemo(() => {
    return [1, 2, 3, 4].map((w) => ({
      name: `Show ${w} Week${w > 1 ? 's' : ''}`,
      handler: () => setWeeks(w),
    }));
  }, [setWeeks]);

  return (
    <>
      <div className="timeline__navigation" {...commonProps}>
        {(!showUpcomingEvents || currentDate < startDate) && (
          <div
            className="timeline__navigation-action"
            role="button"
            onClick={goToPreviousWeek}
          >
            <Icons.ArrowLeftIcon />
          </div>
        )}

        <Typography.p>
          {startDate.toLocaleDateString('en-gb', {
            day: 'numeric',
            month: 'short',
          })}
        </Typography.p>
        <Typography.p>{`  -  `}</Typography.p>
        <Typography.p>
          {endDate.toLocaleDateString('en-gb', {
            day: 'numeric',
            month: 'short',
          })}
        </Typography.p>
        <div
          className="timeline__navigation-action"
          role="button"
          onClick={goToNextWeek}
        >
          <Icons.ArrowRightIcon />
        </div>
      </div>
      <div className="timeline__week-select">
        <FloatingMenu items={weekSelectorOptions}>
          <div className="timeline__week-selector">
            <span>{`${weeks} Week${weeks > 1 ? 's' : ''}`}</span>
            <Icon.ArrowDownIcon />
          </div>
        </FloatingMenu>
      </div>
    </>
  );
};

export const TimelineBody = ({
  events,
  tooltipHeight,
  ...props
}: TidComponent<{
  events: TimelineDayEvent[];
  tooltipHeight: number;
}>) => {
  const commonProps = useTestProps(props);

  const { dates, currentDate } = useTimelineContext();
  const renderDates = useMemo(() => chunk(dates, 7), [dates]);

  const renderEvents = useMemo(() => {
    return renderDates.map((dates) => orderEvents(events, dates));
  }, [events, renderDates]);

  return (
    <div className="timeline__body" {...commonProps}>
      {renderDates.map((weekDates, week) => (
        <div
          key={week}
          className="timeline__grid"
          style={{
            gridTemplateColumns: `repeat(${weekDates.length}, 1fr)`,
          }}
        >
          {weekDates.map((weekDate) => (
            <div
              className="timeline__grid-item"
              key={`day-grid-item-${weekDate.toISOString()}`}
            >
              <div
                className={`timeline__grid-item-header ${
                  weekDate.getTime() === currentDate.getTime()
                    ? 'timeline__grid-item-header--active'
                    : ''
                }`}
              >
                <Typography.h1>{weekDate.getDate()} </Typography.h1>
                <Typography.h5>
                  {weekDate.toLocaleString('en', { weekday: 'short' })}
                </Typography.h5>
              </div>
            </div>
          ))}
          {renderEvents[week].map((event, index) => (
            <TimelineEvent
              index={index}
              event={event}
              key={`timeline-event--${event.id}`}
              tooltipHeight={tooltipHeight}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

type TimelineEventProps = TidComponent<{
  index: number;
  event: TimelineDayEventWithGridPosition;
  tooltipHeight?: number;
}>;
export const TimelineEvent = ({
  event,
  index,
  tooltipHeight,
  ...props
}: TimelineEventProps) => {
  const commonProps = useTestProps(props);

  const [isClicked, setIsClicked] = useState(false);
  const eventDivRef = useRef<HTMLDivElement>(null);
  const globalOnClickListener = useCallback((e) => {
    if (eventDivRef.current && !eventDivRef.current.contains(e.target)) {
      setIsClicked(false);
    }
  }, []);
  const onClick = useCallback(() => {
    setIsClicked(true);
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', globalOnClickListener);
    return () => {
      document.removeEventListener('mousedown', globalOnClickListener);
    };
  }, []);

  const TooltipComponent = event.TooltipComponent;
  return (
    <div
      className="timeline__grid-item timeline__grid-item--event"
      style={{ gridColumn: `${event.columnStart} / ${event.columnEnd}` }}
      ref={eventDivRef}
      {...commonProps}
    >
      <div
        tabIndex={index}
        className={`timeline__event timeline__event--${event.type} ${
          isClicked ? 'timeline__event--active' : ''
        } `}
        // For some reason the slide animation doesn't work when it is specified in
        // the `--active` class but it works from here
        style={{
          backgroundPosition: isClicked ? 'left bottom' : 'right bottom',
        }}
        onClick={onClick}
      >
        {event.title}
      </div>
      {isClicked && event.TooltipComponent ? (
        <DialogTooltip
          type={event?.type}
          tooltipHeight={tooltipHeight}
          containerRef={eventDivRef}
        >
          {/* @ts-ignore */}
          <TooltipComponent
            eventObject={event.eventObject}
            id={event.id}
            title={event.title}
          />
        </DialogTooltip>
      ) : null}
    </div>
  );
};
