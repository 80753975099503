import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SubscriptionInstance } from '@nl-lms/common/feature/types';
import {
  Box,
  Button,
  DateTime,
  FormField,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';

import { useAction } from '../../../_common/hooks/useAction';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminLearnerSingleSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminSubscriptionSelect } from './AdminSubscriptionSelect';

type SchemaType = {
  startDate: Date;
  endDate: Date;
  learnerId: string;
  subscriptionId: string;
};
type Props = {
  subscriptionInstance: SubscriptionInstance & {
    learner: { firstName: string; lastName: string; id: string };
    subscription: { name: string; id: string };
  };
};
const Schema: yup.ObjectSchema<SchemaType> = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  subscriptionId: yup.string().required(),
  learnerId: yup.string().required(),
});

const { useUpdateSubscriptionInstanceMutation } = subscriptionsApi;

export const AdminSubscriptionInstanceUpdateFormSideModal = ({
  subscriptionInstance,
}: Props) => {
  const { hide } = useModal();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...subscriptionInstance,
    },
  });

  const [updateSubscriptionInstance, { isLoading, error }] =
    useUpdateSubscriptionInstanceMutation();

  const onSubmit = useCallback(async (data: SchemaType) => {
    const res = await updateSubscriptionInstance({
      subscriptionInstanceId: subscriptionInstance.id,
      learnerId: data.learnerId,
      startDate: data.startDate,
      endDate: data.endDate,
      subscriptionId: data.subscriptionId,
    });

    hide(res);
  }, []);

  const onSubmitAction = useAction(onSubmit, {
    successMessage: 'Subscription successfully updated',
    alertMessage: 'Subscription could not be updated',
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Update Subscription</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Subscription Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Learner"
              helpText="The learner for which the subscription will be updated"
              helpTextOffset={60}
              required
              errorMessage={errors?.learnerId?.message}
            >
              <Controller
                name="learnerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={{
                      label: `${subscriptionInstance.learner?.firstName} ${subscriptionInstance.learner?.lastName}`,
                      value: subscriptionInstance.learnerId,
                    }}
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Subscription"
              helpText="The subscription you want to track for the elarner"
              helpTextOffset={30}
              errorMessage={errors?.subscriptionId?.message}
            >
              <Controller
                control={control}
                name="subscriptionId"
                render={({ field }) => (
                  <AdminSubscriptionSelect
                    {...field}
                    isClearable
                    initialSelectedItem={{
                      label: subscriptionInstance.subscription.name,
                      value: subscriptionInstance.subscriptionId,
                    }}
                    hasError={!!errors?.subscriptionId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Start Date">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="End Date">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <Button
          label={'Update'}
          onClick={handleSubmit(onSubmitAction)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
