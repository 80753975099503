import React, { useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { formatSecondsToStandard } from '@nl-lms/common/shared';

import { useTestProps } from '../../hooks';
import { Typography } from '../Typography/Typography';
import './CircleTimer.scss';

const RenderTimeComponent = ({
  elapsedTime,
  duration,
  onComplete,
  timeSpent,
}) => {
  // For some reason the onComplete function passed to CircleTimer is not being referenced properly
  // Hence the useEffect workaround
  useEffect(() => {
    if (!duration || !elapsedTime) return;

    if (elapsedTime - duration === 0) onComplete({ timeSpent });
  }, [elapsedTime, duration, onComplete]);
  return (
    <Typography.p style={{ fontSize: '13px' }}>
      {formatSecondsToStandard(Math.round(duration - elapsedTime))}
    </Typography.p>
  );
};
export const CircleTimer = ({
  initialRemainingTime,
  duration,
  onComplete,
  timeSpent,
  ...props
}) => {
  const commonProps = useTestProps(props);

  return (
    <div className="circle-timer" {...commonProps}>
      <CountdownCircleTimer
        isPlaying={true}
        size={60}
        strokeWidth={3}
        duration={duration}
        colors={'#000000'}
        initialRemainingTime={initialRemainingTime}
      >
        {({ elapsedTime }) => (
          <RenderTimeComponent
            duration={duration}
            elapsedTime={elapsedTime}
            onComplete={onComplete}
            timeSpent={timeSpent}
          />
        )}
      </CountdownCircleTimer>
    </div>
  );
};

CircleTimer.minutesToSeconds = (minutes) => minutes * 60;
CircleTimer.hoursToSeconds = (hours) =>
  hours * CircleTimer.minutesToSeconds(60);
