import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, FormField, Modal, useModal } from '@nl-lms/ui/components';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { learnerApi } from '../../../_common/services/api';
import { LearnerLiveSessionCancelRegistrationReasonSelect } from './LearnerLiveSessionCancelRegistrationReasonSelect';

const { useTrainingSessionCancelRegistrationMutation } = learnerApi;
export const LearnerLiveSessionCancelRegistrationModal = ({
  learnerId,
  id,
  ...rest
}) => {
  const { hide } = useModal();
  const { t } = useTranslation('learner');

  const [selectedReason, setSelectedReason] = useState(null);
  const [trainingSessionCancelRegistration, { isLoading }] =
    useTrainingSessionCancelRegistrationMutation();
  const trainingSessionCancelRegistrationAction = useAction(
    trainingSessionCancelRegistration,
    {
      successMessage: t('learningunit.registrationcanceled'),
    }
  );

  const onSubmit = useCallback(async () => {
    const result = await trainingSessionCancelRegistrationAction({
      learnerId,
      sessionId: id,
      data: { reason: selectedReason },
    });

    hide(result?.data);
  }, [learnerId, id, selectedReason]);

  return (
    <Modal.Content style={{ width: '600px' }}>
      <Modal.Header>
        <Modal.Title>{t('learningunit.selectcancellationreason')}</Modal.Title>
      </Modal.Header>
      <Box>
        <FormField label={t('learningunit.reasons')}>
          <LearnerLiveSessionCancelRegistrationReasonSelect
            name="cancelRegistrationReasons"
            onChange={setSelectedReason}
            {...rest}
          />
        </FormField>
      </Box>
      <Modal.Actions>
        <Button
          label={t('learningunit.submit')}
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={!selectedReason}
        />
      </Modal.Actions>
    </Modal.Content>
  );
};
