import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassNameProps, useTestProps } from '../../hooks';
import { prettyDate } from '../../utils/prettyDate';
import { Banner } from '../Banner/Banner';
import { Checkbox } from '../Checkbox/Checkbox';
import { CheckboxGroup } from '../CheckboxGroup/CheckboxGroup';
import { DateTime } from '../DateTimePicker/DateTimePicker';
import * as Icon from '../Icon';
import { RadioButton } from '../RadioButton/RadioButton';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { RangeButton } from '../RangeButton/RangeButton';
import { RangeButtonGroup } from '../RangeButtonGroup/RangeButtonGroup';
import { RatingButton } from '../RatingButton/RatingButton';
import { RatingButtonGroup } from '../RatingButtonGroup/RatingButtonGroup';
import { Select } from '../Select/Select';
import { Textarea } from '../Textarea/Textarea';
import { ToggleButtonGroup } from '../ToggleButtonGroup/ToggleButtonGroup';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './AssessmentQuestion.scss';

export enum AssessmentQuestionType {
  SingleChoice = 2,
  MultipleChoice = 1,
  FreeText = 3,
  Range = 4,
  NPS = 5,
  Dropdown = 6,
  Date = 7,
  Rating = 8,
}

export type AssessmentQuestionOption = {
  value: string;
  label: string;
  isCorrect?: boolean;
};

type AssessmentQuestionProps = TidComponent<{
  type: AssessmentQuestionType;
  question: string;
  description?: string;
  className?: string;
  inputProps: any;
  index?: number;
  value: string[];
  required?: boolean;
}>;

const AssessmentQuestionWithValidationTypeToFormInputMap = {
  [AssessmentQuestionType.SingleChoice]: RadioButton,
  [AssessmentQuestionType.MultipleChoice]: Checkbox,
  [AssessmentQuestionType.FreeText]: Textarea,
  [AssessmentQuestionType.Date]: DateTime,
  [AssessmentQuestionType.Range]: RangeButton,
  [AssessmentQuestionType.NPS]: RangeButton,
  [AssessmentQuestionType.Dropdown]: RadioButton,
  [AssessmentQuestionType.Rating]: RatingButton,
};

type AssessmentQuestionWithValidationProps = TidComponent<{
  type: AssessmentQuestionType;
  question: string;
  className?: string;
  description?: string;
  index: number;
  options: AssessmentQuestionOption[];
  learnerAnswer: string[];
  allowManualScoring?: boolean;
  id: string;
  onChangeScoring?: (isCorrect: boolean, id: string) => void;
  isCorrect?: boolean;
  showCorrect?: boolean;
}>;

export const AssessmentQuestionWithValidation = (
  props: AssessmentQuestionWithValidationProps
) => {
  const {
    type,
    index,
    question,
    description = '',
    options = [],
    learnerAnswer,
    allowManualScoring = false,
    onChangeScoring,
    id,
    isCorrect,
    showCorrect = true,
  } = props;

  const parsedLearnerAnswer = useMemo(() => {
    return learnerAnswer?.map((answer) => answer?.trimStart());
  }, [learnerAnswer]);

  const Component = AssessmentQuestionWithValidationTypeToFormInputMap[type];

  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('assessment-question', props);

  if (!Component) return null;

  const inputProps = {
    readOnly: true,
    name: `${question}-input`,
  } as any;

  if (type === AssessmentQuestionType.FreeText) {
    inputProps.value = parsedLearnerAnswer[0];
    inputProps.disabled = true;
  } else if (
    type === AssessmentQuestionType.SingleChoice ||
    type === AssessmentQuestionType.Dropdown ||
    type === AssessmentQuestionType.Range ||
    type === AssessmentQuestionType.NPS ||
    type === AssessmentQuestionType.Rating
  ) {
    inputProps.selectedOption = options.find((o) => o.isCorrect);
    inputProps.options = options.map((o) => ({
      ...o,
      hasError: !o.isCorrect && parsedLearnerAnswer.includes(o.value),
    }));
  } else if (type === AssessmentQuestionType.MultipleChoice) {
    inputProps.selectedOptions = options.filter((o) => o.isCorrect);
    inputProps.options = options.map((o) => ({
      ...o,
      hasError: !o.isCorrect && parsedLearnerAnswer.includes(o.value),
    }));
  } else if (type === AssessmentQuestionType.Date) {
    inputProps.date = prettyDate(parsedLearnerAnswer[0]);
    inputProps.disabled = true;
  }

  const defaultInputProps = {
    readOnly: true,
    onClick: () => null,
    name: '',
    label: '',
    value: '',
  };

  if (
    type === AssessmentQuestionType.FreeText ||
    type === AssessmentQuestionType.Date
  ) {
    return (
      <div {...classNameProps} {...commonProps}>
        <div className="assessment-question__label">
          <h2>{`${index + 1}. ${question}`}</h2>
          {description && !_.isEmpty(description) && (
            <Typography.h3>{description}</Typography.h3>
          )}
          {typeof isCorrect !== 'undefined' && (
            <span
              className={`assessment-question__point assessment-question__point--${
                isCorrect ? 'correct' : 'wrong'
              }`}
            >
              {isCorrect ? '1 Point' : '0 Points'}
            </span>
          )}
        </div>
        <div className="assessment-question__input">
          {type == AssessmentQuestionType.FreeText ? (
            <Textarea {...inputProps} />
          ) : (
            <DateTime {...inputProps} />
          )}
        </div>

        {allowManualScoring && (
          <ToggleButtonGroup
            className="assessment-question__scoring"
            options={[
              { label: 'Correct', value: 'Correct' },
              { label: 'Incorrect', value: 'Incorrect' },
            ]}
            // @ts-ignore
            onChange={(value) => onChangeScoring(value === 'Correct', id)}
            name={`${question}-manual-scoring`}
          />
        )}
      </div>
    );
  }
  return (
    <div {...classNameProps} {...commonProps}>
      <div className="assessment-question__label">
        <h2>{`${index + 1}. ${question}`}</h2>
        {description && !_.isEmpty(description) && (
          <Typography.h3>{description}</Typography.h3>
        )}
        {typeof isCorrect !== 'undefined' && (
          <span
            className={`assessment-question__point assessment-question__point--${
              isCorrect ? 'correct' : 'wrong'
            }`}
          >
            {isCorrect ? '1 Point' : '0 Points'}
          </span>
        )}
      </div>
      <div className="assessment-question__input">
        <table>
          <thead>
            <tr>
              {showCorrect && (
                <th>
                  <Icon.CheckIcon />
                </th>
              )}
              <th>
                <Icon.UserIcon />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {options.map((option, index) => {
              const learnerChecked = !!parsedLearnerAnswer.find(
                (l) =>
                  l
                    .replace(/&amp;/g, '&')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&quot;/g, '"') === option.value
              );
              return (
                <tr key={index}>
                  {showCorrect && (
                    <td>
                      <div className="assessment-question__input-wrapper">
                        <Component
                          checked={option.isCorrect}
                          count={index + 1}
                          {...defaultInputProps}
                        />
                      </div>
                    </td>
                  )}
                  <td>
                    <div className="assessment-question__input-wrapper">
                      <Component
                        count={index + 1}
                        checked={learnerChecked}
                        {...defaultInputProps}
                      />
                    </div>
                  </td>
                  <td>
                    <Typography.p
                      className={'assessment-question__input-label'}
                    >
                      {option.label}
                    </Typography.p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {allowManualScoring && (
        <ToggleButtonGroup
          className="assessment-question__scoring"
          options={[
            { label: 'Correct', value: 'Correct' },
            { label: 'Incorrect', value: 'Incorrect' },
          ]}
          // @ts-ignore
          onChange={(value) => onChangeScoring(value === 'Correct', id)}
          name={`${question}-manual-scoring`}
        />
      )}
    </div>
  );
};

const AssessmentQuestionTypeToFormInputMap = {
  [AssessmentQuestionType.SingleChoice]: RadioButtonGroup,
  [AssessmentQuestionType.MultipleChoice]: CheckboxGroup,
  [AssessmentQuestionType.FreeText]: Textarea,
  [AssessmentQuestionType.Range]: RangeButtonGroup,
  [AssessmentQuestionType.NPS]: RangeButtonGroup,
  [AssessmentQuestionType.Dropdown]: Select,
  [AssessmentQuestionType.Date]: DateTime,
  [AssessmentQuestionType.Rating]: (props) => {
    const { t } = useTranslation('learner');
    return (
      <div>
        <RatingButtonGroup {...props} />
        <br />
        <Banner
          icon={<Icon.AlertCircleIcon />}
          text={t('learningunit.surveyratinginfo')}
        />
      </div>
    );
  },
};

const getValueProps = (type: AssessmentQuestionType, value: string[]) => {
  if (
    (type === AssessmentQuestionType.FreeText ||
      type === AssessmentQuestionType.SingleChoice ||
      type === AssessmentQuestionType.Range ||
      type === AssessmentQuestionType.Dropdown ||
      type === AssessmentQuestionType.NPS ||
      type === AssessmentQuestionType.Rating) &&
    value?.length
  ) {
    return value[0];
  }

  if (type === AssessmentQuestionType.Date) {
    if (!value?.length) return null;
    return prettyDate(value[0]);
  }

  if (!value || !value.length) return [];
  return value;
};

export const AssessmentQuestion: React.FunctionComponent<
  AssessmentQuestionProps
> = (props) => {
  const {
    className = '',
    question,
    description = '',
    index,
    inputProps: _inputProps = {},
    type,
    value,
    required = false,
  } = props;

  const Component = AssessmentQuestionTypeToFormInputMap[type];
  const _value = getValueProps(type, value);

  const commonProps = useTestProps(props);

  let header = question;
  // @ts-ignore
  if (Number.isInteger(index)) header = `${index + 1}. ${question}`;

  const inputProps = { ..._inputProps };
  const onChangeDropdownValue = useCallback(
    (option) => {
      _inputProps.onChange({
        target: { value: option.value, name: inputProps.name },
      });
    },
    [_inputProps]
  );

  if (type === AssessmentQuestionType.Dropdown) {
    inputProps.onChange = onChangeDropdownValue;
    inputProps.initialValue = _value;
  } else if (type === AssessmentQuestionType.Date) {
    inputProps.showTimeSelect = false;
    inputProps.date = _value;
  }

  if (!Component) return null;

  return (
    <div className={`assessment-question ${className}`} {...commonProps}>
      <div className="assessment-question__label">
        <Typography.h2>
          {header}
          {required && (
            <span className="assessment-question__label--required"> *</span>
          )}
        </Typography.h2>
      </div>
      {description && !_.isEmpty(description) && (
        <div className="assessment-question__description">
          <Typography.h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography.h3>
        </div>
      )}
      <div className="assessment-question__input">
        <Component {...inputProps} value={_value} />
      </div>
    </div>
  );
};

export default AssessmentQuestion;
