import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  IApiMethods,
  IApiService,
  Trainer as ITrainer,
  TrainerStatistics as ITrainerStatistic,
  ListResponse,
  LiveCourse,
  LiveSession,
  TrainerCourseStatistics,
  Vendor,
} from './types';

export class Trainer
  extends ApiService
  implements IApiService<'trainer'>, IApiMethods<'trainer'>
{
  static serviceBase = '/resource/trainer';
  public paths = {
    get: (id: string) => `${Trainer.serviceBase}/${id}`,
    list: () => `${Trainer.serviceBase}`,
    bulkDelete: () => `${Trainer.serviceBase}`,
    create: () => `${Trainer.serviceBase}`,
    listWithSessions: () => `${Trainer.serviceBase}/sessions`,
    update: (id: string) => `${Trainer.serviceBase}/${id}`,
    remove: (id: string) => `${Trainer.serviceBase}/${id}`,
    statistics: (id: string) => `${Trainer.serviceBase}/${id}/statistics`,
    allStatistics: () => `${Trainer.serviceBase}/statistics/all`,
    courseStatistics: () => `${Trainer.serviceBase}/statistics/course`,
    listCourses: () => `/list/trainer/courses`,
    listVendors: () => `/list/trainer/vendors`,
  };

  get = (id) => this.api.get<null, ITrainer>(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get<null, ListResponse<ITrainer>>(this.paths.list(), {
      params: { query },
    });

  listWithSessions = (query: AppQuery) =>
    this.api.get<null, ListResponse<Trainer & { events: LiveSession[] }>>(
      this.paths.listWithSessions(),
      {
        params: { query },
      }
    );

  bulkDelete = (ids) =>
    this.api.delete<null, boolean>(this.paths.bulkDelete(), {
      data: { list: ids },
    });

  create = (entity) => {
    const parsedEntity = {
      ...entity,
      courses: entity.courses.filter((c) => c.deleted === false),
    };
    return this.api.post<null, boolean>(
      this.paths.create(),
      snakelize(parsedEntity)
    );
  };

  update = (entity) =>
    this.api.patch<null, boolean>(
      this.paths.update(entity.id),
      snakelize(entity)
    );

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete<null, boolean>(this.paths.remove(id), entity);
  };

  statistics = (id) =>
    this.api.get<
      null,
      ITrainer & {
        trainedMinutes: number | null;
        trainedLearners: number | null;
        deliveredSessions: number | null;
        canceledSessions: number | null;
      }
    >(this.paths.statistics(id));

  allStatistics = (query: AppQuery) =>
    this.api.get<null, ITrainerStatistic>(this.paths.allStatistics(), {
      params: { query },
    });
  courseStatistics = (query: AppQuery) =>
    this.api.get<null, TrainerCourseStatistics>(this.paths.courseStatistics(), {
      params: { query },
    });

  listCourses = () =>
    this.api.get<null, LiveCourse[]>(this.paths.listCourses());

  listVendors = () => this.api.get<null, Vendor[]>(this.paths.listVendors());
}
