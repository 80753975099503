import React, { useMemo } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { HelpIcon } from '../Icon/HelpIcon';
import { Skeleton } from '../Skeleton/Skeleton';
import { Tooltip, TooltipAlignment } from '../Tooltip/Tooltip';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './FormField.scss';

type FormFieldProps = TidComponent<{
  className?: string;
  errorMessage?:
    | string
    | null
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>;
  label?: string;
  description?: string | React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  noPadding?: boolean;
  isLoading?: boolean;
  helpText?: string;
  helpTextOffset?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}>;

export const FormField = ({
  children,
  errorMessage = '',
  label = '',
  description = '',
  disabled = false,
  required = false,
  noPadding = false,
  isLoading = false,
  // @ts-ignore
  helpText = null,
  style = {},
  ...props
}: FormFieldProps) => {
  const classNameProps = useClassNameProps(
    'form-field',
    props,
    noPadding ? 'form-field--no-padding' : null
  );
  const commonProps = useTestProps(props);
  const labelClassNameProps = useClassNameProps(
    'form-field__label',
    disabled ? 'form-field__label--disabled' : null,
    errorMessage ? 'form-field__label--hasError' : null
  );
  const parsedErrorMessage = useMemo(() => {
    if (!errorMessage) return '';
    if (typeof errorMessage === 'string') return errorMessage;
    return errorMessage.message as unknown as string;
  }, [errorMessage]);
  return (
    <div style={style} {...classNameProps} {...commonProps}>
      {label ? (
        <div {...labelClassNameProps}>
          {label}
          {required && <span className="form-field__label--required"> *</span>}
          {helpText && (
            <Tooltip
              title={helpText}
              className="form-field__label-tooltip"
              align={TooltipAlignment.BOTTOM}
            >
              <HelpIcon />
            </Tooltip>
          )}
        </div>
      ) : null}

      {!!description && (
        <span className="form-field__description">{description}</span>
      )}

      <div className="form-field__input">
        {isLoading ? (
          <Skeleton className="form-field__input-skeleton" />
        ) : (
          children
        )}
      </div>
      {/* {required && !errorMessage && (
        <div className="form-field__helper">
          Required
        </div>
      )} */}
      {errorMessage && (
        <div className="form-field__error" title={parsedErrorMessage}>
          {parsedErrorMessage}
        </div>
      )}
    </div>
  );
};

export const FormFieldRoot = ({
  children,
  style = {},
  ...props
}: TidComponent<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}>) => {
  const commonProps = useTestProps(props);
  return (
    <div className="form-field" style={style} {...commonProps}>
      {children}
    </div>
  );
};

export const FormFieldHeader = ({
  children,
  ...props
}: TidComponent<{
  children: React.ReactNode;
}>) => {
  const commonProps = useTestProps(props);
  return (
    <div className="form-field__header" {...commonProps}>
      {children}
    </div>
  );
};

export const FormFieldLabel = ({
  children,
  spacing = 's',
  required = false,
  disabled = false,
  ...props
}: TidComponent<{
  children: React.ReactNode;
  disabled?: boolean;
  spacing?: 's' | 'xs';
  required?: boolean;
}>) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Typography.h4
      type={disabled ? 'muted' : 'default'}
      className={`form-field__label form-field__label--spacing-${spacing}`}
      {...commonProps}
    >
      {children}
      {required && <span className="form-field__label--required"> *</span>}
    </Typography.h4>
  );
};

const FormFieldDescription = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Typography.p
      type="muted"
      className="form-field__description"
      {...commonProps}
    >
      {children}
    </Typography.p>
  );
};

const FormFieldError = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });

  if (!children) return null;

  return (
    <Typography.p type="alert" className="form-field__error" {...commonProps}>
      {children}
    </Typography.p>
  );
};

const FormFieldInput = ({ children, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <div className="form-field__input" {...commonProps}>
      {children}
    </div>
  );
};

FormField.Root = FormFieldRoot;
FormField.Label = FormFieldLabel;
FormField.Header = FormFieldHeader;
FormField.Error = FormFieldError;
FormField.Input = FormFieldInput;
FormField.Description = FormFieldDescription;

type SwitchInputFieldProps = TidComponent<{
  label: string;
  description: string;
  children: React.ReactNode;
  errorMessage?: string;
  disabled?: boolean;
}>;

export const SwitchInputField = ({
  label,
  description,
  children,
  errorMessage = '',
  disabled = false,
  ...props
}: SwitchInputFieldProps) => {
  const commonProps = useTestProps(props);

  return (
    <div className="form-field" {...commonProps}>
      <div className="form-field__header">
        <Typography.h4
          className="form-field__label"
          type={disabled ? 'muted' : 'default'}
        >
          {label}
        </Typography.h4>
        {children}
      </div>
      <Typography.p type="muted" className="form-field__description">
        {description}
      </Typography.p>
      {errorMessage && (
        <div className="form-field__error" title={errorMessage}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export const CheckboxField = ({
  errorMessage,
  label,
  helpText,
  children,
  ...props
}: TidComponent<{
  errorMessage?: string;
  label: string;
  helpText?: string;
  children: React.ReactNode;
}>) => {
  const commonProps = useTestProps(props);

  return (
    <div className="form-field" {...commonProps}>
      <div
        className={`form-field__label ${
          errorMessage && 'form-field__label--hasError'
        }`}
      >
        <div className="form-field__checkbox">{children}</div>
        {label}
        {helpText && (
          <Tooltip
            title={helpText}
            className="form-field__label-tooltip"
            align={TooltipAlignment.BOTTOM}
          >
            <HelpIcon />
          </Tooltip>
        )}
      </div>

      {errorMessage && (
        <div className="form-field__error" title={errorMessage}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
