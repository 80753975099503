import { z } from 'zod';

export const LearnerManagersSchema = z.array(
  z.object({
    id: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  })
);

export const LearnerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  customerInternalId: z.string(),
  isManager: z.boolean(),
  details: z.record(z.string(), z.unknown()),
  deletedAt: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  managers: LearnerManagersSchema.optional(),
});

export type Learner = z.infer<typeof LearnerSchema>;
export type LearnerManagers = z.infer<typeof LearnerManagersSchema>;

export const LearnerHistoryDiffSchema = z
  .object({
    firstName: z.object({
      old: z.string(),
      new: z.string(),
    }),
    lastName: z.object({ old: z.string(), new: z.string() }),
    email: z.object({ old: z.string(), new: z.string() }),
    customerInternalId: z.object({ old: z.string(), new: z.string() }),
    isManager: z.object({ old: z.boolean(), new: z.boolean() }),
    details: z.object({
      old: z.record(z.string(), z.unknown()),
      new: z.record(z.string(), z.unknown()),
    }),
    deletedAt: z.object({ old: z.date().nullable(), new: z.date().nullable() }),

    learnerRelationships: z.object({
      old: LearnerManagersSchema,
      new: LearnerManagersSchema,
    }),
  })
  .partial();

export const LearnerHistorySchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  customerInternalId: z.string(),
  isManager: z.boolean(),
  details: z.record(z.string(), z.unknown()),
  deletedAt: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  diff: LearnerHistoryDiffSchema,
  learnerRelationships: LearnerManagersSchema,
});

export const LearnerRelationShipSchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  relatedLearnerId: z.string(),
  type: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearnerRelationship = z.infer<typeof LearnerRelationShipSchema>;
export type LearnerHistoryDiff = z.infer<typeof LearnerHistoryDiffSchema>;
export type LearnerHistory = z.infer<typeof LearnerHistorySchema>;

export const AudienceSchema = z.object({
  learnerIds: z.array(z.string()),
  learnerGroupIds: z.array(z.string()),
});

export type Audience = z.infer<typeof AudienceSchema>;
