import React, { useCallback, useMemo, useState } from 'react';

import { useClassNameProps, useTestProps } from '../../hooks';
import { Icon } from '../index';
import { TidComponent } from '../index.types';
import './Accordion.scss';

export enum AccordionTabState {
  Closed,
  Open,
}

export type AccordionTabProps = TidComponent<{
  state?: AccordionTabState;
  index: number;
  children: React.ReactNode[] | React.ReactNode;
  header: ({ index }: { index: number }) => React.ReactNode | React.ReactNode[];
  actions?: ({
    index,
  }: {
    index: number;
  }) => React.ReactNode | React.ReactNode[];
  onHeaderClick?: (index: number) => void;
}>;

export type AccordionProps = {
  children:
    | React.ReactElement<AccordionTabProps>[]
    | React.ReactElement<AccordionTabProps>;
  onToggle?: (tabId, state) => void;
  className?: string;
};

export const AccordionTab = ({
  state = AccordionTabState.Closed,
  index,
  children,
  header,
  actions,
  onHeaderClick,
  ...props
}: AccordionTabProps) => {
  const classNameProps = useClassNameProps(
    'accordion-tab',
    state === AccordionTabState.Open
      ? 'accordion-tab--open'
      : 'accordion-tab--closed'
  );
  const commonProps = useTestProps(props);

  return (
    <div {...classNameProps} {...commonProps}>
      <div className="accordion-tab-header">
        <div
          className="accordion-tab-header__arrow"
          // @ts-ignore
          onClick={() => onHeaderClick(index)}
        >
          {state === AccordionTabState.Open && <Icon.ArrowDownIcon />}
          {state === AccordionTabState.Closed && <Icon.ArrowUpIcon />}
        </div>
        <div
          className="accordion-tab-header__content"
          // @ts-ignore
          onClick={() => onHeaderClick(index)}
        >
          {header({ index })}
        </div>
        <div className="accordion-tab-header__actions">
          {!!actions && actions({ index })}
        </div>
      </div>

      <div className="accordion-tab-content">{children}</div>
    </div>
  );
};

export const Accordion = ({
  children,
  ...props
}: TidComponent<AccordionProps>) => {
  const classNameProps = useClassNameProps('accordion', props);
  const commonProps = useTestProps(props);

  const initialTabs = useMemo(() => {
    let _children = Array.isArray(children) ? children : [children];

    return _children.filter((child) => !!child);
  }, [children]);

  const [tabState, setTabState] = useState<AccordionTabState[]>(
    initialTabs.map((tab) => {
      if (typeof tab.props.state === 'undefined') {
        return AccordionTabState.Closed;
      }
      return tab.props.state;
    })
  );

  const onTabClick = useCallback(
    (index) => {
      const _tabState = [...tabState];
      _tabState[index] =
        _tabState[index] === AccordionTabState.Open
          ? AccordionTabState.Closed
          : AccordionTabState.Open;

      setTabState(_tabState);
    },
    [tabState]
  );

  const tabs = useMemo(() => {
    return initialTabs.map((tab, index) => {
      return React.cloneElement(tab, {
        ...tab.props,
        state: tabState[index],
        index,
        onHeaderClick: (_index: number) => {
          if (tab.props.onHeaderClick) tab.props.onHeaderClick(index);
          onTabClick(index);
        },
      });
    });
  }, [initialTabs, tabState]);

  return (
    <div {...classNameProps} {...commonProps}>
      <div className="accordion__container">{tabs}</div>
    </div>
  );
};
