type KeyFromValue<V, T extends Record<PropertyKey, PropertyKey>> = {
  [K in keyof T]: V extends T[K] ? K : never;
}[keyof T];

type Invert<T extends Record<PropertyKey, PropertyKey>> = {
  [V in T[keyof T]]: KeyFromValue<V, T>;
};

function numericInvert<T extends Record<number, string>>(obj: T): Invert<T> {
  return Object.keys(obj).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(key)) return acc;
    return {
      ...acc,
      [obj[key]]: parseInt(key, 10),
    };
  }, {}) as Invert<T>;
}

function stringInvert<T extends Record<string, string>>(obj: T): Invert<T> {
  return Object.keys(obj).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(key)) return acc;
    return {
      ...acc,
      [obj[key]]: key,
    };
  }, {}) as Invert<T>;
}

export const REQUEST_QUERY_OPERATORS = {
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  ne: 'ne',
  eq: 'eq',
  not: 'not',
  between: 'between',
  notBetween: 'notBetween',
  in: 'in',
  notIn: 'notIn',
  like: 'like',
  notLike: 'notLike',
  iLike: 'iLike',
  notILike: 'notILike',
};

type USER_ROLE =
  | 'manager'
  | 'coordinator'
  | 'learner'
  | 'admin'
  | 'evaluator'
  | 'auditor';
const USER_ROLES: USER_ROLE[] = [
  'manager',
  'coordinator',
  'learner',
  'admin',
  'evaluator',
  'auditor',
];

type CURRENCY = 'EUR' | 'RON' | 'USD' | 'HUF' | 'BGN' | 'PLN' | 'GBP';

const CURRENCIES: Record<number, CURRENCY> = {
  1: 'EUR',
  2: 'RON',
  3: 'USD',
  4: 'HUF',
  5: 'BGN',
  6: 'PLN',
  7: 'GBP',
};

const TIMEZONES = [
  'Europe/Bucharest',
  'Europe/Sofia',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Brussels',
  'Europe/Helsinki',
  'Europe/Stockholm',
  'Europe/Amsterdam',
  'Europe/Madrid',
  'Europe/Prague',
  'Europe/Athens',
  'Europe/Kiev',
  'Europe/Bratislava',
  'Europe/Rome',
  'Europe/Belgrade',
  'Europe/Sarajevo',
  'Europe/Belfast',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Europe/Vienna',
  'Europe/Zurich',
  'Europe/Berlin',
  'Europe/Warsaw',
  'Europe/London',
  'Europe/Paris',
  'Europe/Istanbul',
  'Europe/Budapest',
  'Europe/Zagreb',
  'US/Pacific',
  'US/Eastern',
  'US/Central',
  'US/Alaska',
  'US/Hawaii',
  'US/Arizona',
];

type LANGUAGE = 'EN' | 'RO' | 'DE' | 'ES' | 'FR';

const LANGUAGES: Record<number, LANGUAGE> = {
  1: 'EN',
  2: 'RO',
  3: 'DE',
  4: 'ES',
  5: 'FR',
};

const COUNTRIES: Record<number, 'RO'> = {
  1: 'RO',
};

type LEARNING_TYPE =
  | 'IN_CLASS'
  | 'CONFERENCE'
  | 'VIRTUAL_CLASS'
  | 'VIRTUAL_CONFERENCE';

const LEARNING_TYPES: Record<number, LEARNING_TYPE> = {
  1: 'IN_CLASS',
  2: 'CONFERENCE',
  3: 'VIRTUAL_CLASS',
  4: 'VIRTUAL_CONFERENCE',
};

type VIRTUAL_LOCATION_TYPE = 'GMEET' | 'MS_TEAMS';

const VIRTUAL_LOCATION_TYPES: Record<number, VIRTUAL_LOCATION_TYPE> = {
  1: 'GMEET',
  2: 'MS_TEAMS',
};

type SCHEDULE_BLOCK_TYPE = 'CONTENT_DELIVERY' | 'BREAK';
const COURSE_SCHEDULE_BLOCK_MAP: Record<number, SCHEDULE_BLOCK_TYPE> = {
  1: 'CONTENT_DELIVERY',
  2: 'BREAK',
};

type COURSE_REGISTRATION_TYPE = 'MANUAL' | 'REGISTRATION_FORM';
const COURSE_REGISTRATION_TYPE_MAP: Record<number, COURSE_REGISTRATION_TYPE> = {
  1: 'MANUAL',
  2: 'REGISTRATION_FORM',
};

type SESSION_STATUS =
  | 'DRAFT'
  | 'PROCESSING'
  | 'READY'
  | 'COMPLETED'
  | 'CANCELED';
const SESSION_STATUSES: Record<number, SESSION_STATUS> = {
  1: 'DRAFT',
  2: 'PROCESSING',
  3: 'READY',
  4: 'COMPLETED',
  5: 'CANCELED',
};
type SESSION_RESOURCE_STATUS = 'DRAFT' | 'WAITING' | 'CONFIRMED' | 'DECLINED';
const SESSION_RESOURCE_STATUSES_RECORD: Record<
  number,
  SESSION_RESOURCE_STATUS
> = {
  1: 'DRAFT',
  2: 'WAITING',
  3: 'CONFIRMED',
  4: 'DECLINED',
};

type SESSION_CANCELLATION_REASON =
  | 'MISTAKE'
  | 'NOT_NEEDED'
  | 'CANT_PARTICIPATE'
  | 'UNAVAILABLE'
  | 'OTHER';
const SESSION_CANCELLATION_REASON_RECORD: Record<
  number,
  SESSION_CANCELLATION_REASON
> = {
  1: 'MISTAKE',
  2: 'NOT_NEEDED',
  3: 'CANT_PARTICIPATE',
  4: 'UNAVAILABLE',
  5: 'OTHER',
};

type LEARNER_STATUS =
  | 'NOT_INVITED'
  | 'INVITED'
  | 'REGISTERED'
  | 'DECLINED'
  | 'CANCELLED'
  | 'ATTENDED'
  | 'ABSENT'
  | 'GO_SHOW'
  | 'APPROVAL'
  | 'APPROVAL_DECLINED'
  | 'WAITING_LIST';
const LEARNER_STATUSES_RECORD: Record<number, LEARNER_STATUS> = {
  1: 'NOT_INVITED',
  2: 'INVITED',
  3: 'REGISTERED',
  4: 'DECLINED',
  5: 'CANCELLED',
  6: 'ATTENDED',
  7: 'ABSENT',
  8: 'GO_SHOW',
  9: 'APPROVAL',
  10: 'APPROVAL_DECLINED',
  11: 'WAITING_LIST',
};

type TRAINING_SESSION_LEARNER_LEARNING_ASSIGNMENT_STATUS =
  | 'NOT_REGISTERED'
  | 'APPROVAL'
  | 'REGISTERED'
  | 'NOT_INVITED'
  | 'INVITED'
  | 'ATTENDED'
  | 'GO_SHOW'
  | 'CANCELED'
  | 'APPROVAL_DECLINED'
  | 'DECLINED'
  | 'CANCELLED'
  | 'ABSENT'
  | 'WAITING_LIST';

const TRAINING_SESSION_LEARNER_LEARNING_ASSIGNMENT_STATUSES_RECORD: Record<
  number,
  TRAINING_SESSION_LEARNER_LEARNING_ASSIGNMENT_STATUS
> = {
  1: 'NOT_INVITED',
  2: 'INVITED',
  3: 'REGISTERED',
  4: 'DECLINED',
  5: 'CANCELLED',
  6: 'ATTENDED',
  7: 'ABSENT',
  8: 'GO_SHOW',
  9: 'APPROVAL',
  10: 'APPROVAL_DECLINED',
  11: 'WAITING_LIST',
  12: 'NOT_REGISTERED',
};

type APPROVAL_TYPE =
  | 'NONE'
  | 'TRAINING_SESSION_DIRECT_MANAGER'
  | 'TRAINING_SESSION_HIERARCHIC_MANAGER';
const APPROVAL_TYPES_RECORD: Record<number, APPROVAL_TYPE> = {
  1: 'NONE',
  2: 'TRAINING_SESSION_DIRECT_MANAGER',
  3: 'TRAINING_SESSION_HIERARCHIC_MANAGER',
};

type ELEARNING_SESSION_STATUS =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED'
  | 'COMPLETED_PASSED'
  | 'COMPLETED_FAILED'
  | 'CANCELED';
const ELEARNING_SESSION_STATUSES_RECORD: Record<
  number,
  ELEARNING_SESSION_STATUS
> = {
  1: 'NOT_STARTED',
  2: 'IN_PROGRESS',
  3: 'COMPLETED',
  4: 'FAILED',
  5: 'COMPLETED_PASSED',
  6: 'COMPLETED_FAILED',
  7: 'CANCELED',
};
type ELEARNING_SESSION_RESULT = 'PASSED' | 'FAILED';
const ELEARNING_SESSION_RESULT_STATUSES_RECORD: Record<
  number,
  ELEARNING_SESSION_RESULT
> = {
  1: 'PASSED',
  2: 'FAILED',
};
type CONTENT_FILE_TYPE = 'SCORM' | 'DOCUMENT' | 'EMBED' | 'MEDIA';
const CONTENT_FILE_TYPES_RECORD: Record<number, CONTENT_FILE_TYPE> = {
  1: 'SCORM',
  2: 'DOCUMENT',
  3: 'EMBED',
  4: 'MEDIA',
};
type CONTENT_FILE_VERSION =
  | 'SCORM12'
  | 'SCORM2004'
  | 'YOUTUBE'
  | 'VIMEO'
  | 'SHAREPOINT'
  | 'WEB'
  | 'PDF'
  | 'DOC'
  | 'DOCX'
  | 'PPT'
  | 'PPTX'
  | 'MP4'
  | 'WEBM'
  | 'OGG';
const CONTENT_FILE_VERSION_RECORD: Record<string, CONTENT_FILE_VERSION> = {
  '1.2': 'SCORM12',
  '2004': 'SCORM2004',
  youtube: 'YOUTUBE',
  vimeo: 'VIMEO',
  sharepoint: 'SHAREPOINT',
  web: 'WEB',
  pdf: 'PDF',
  doc: 'DOC',
  docx: 'DOCX',
  ppt: 'PPT',
  pptx: 'PPTX',
  mp4: 'MP4',
  webm: 'WEBM',
  ogg: 'OGG',
};

type COST_TYPE_RESOURCE_TYPE =
  | 'Trainer'
  | 'Learner'
  | 'Venue'
  | 'Session'
  | 'Catering';

type ASSESSMENT_VALIDATION_TYPE = 'LATEST_SCORE' | 'HIGHEST_SCORE';
const ASSESSMENT_VALIDATION_TYPE_RECORD: Record<
  number,
  ASSESSMENT_VALIDATION_TYPE
> = {
  1: 'LATEST_SCORE',
  2: 'HIGHEST_SCORE',
};
type ASSESSMENT_FORM_QUESTION_ITEM_TYPE = 'QUESTION' | 'FILTER';
const ASSESSMENT_FORM_QUESTION_ITEM_TYPE_RECORD: Record<
  number,
  ASSESSMENT_FORM_QUESTION_ITEM_TYPE
> = {
  1: 'QUESTION',
  2: 'FILTER',
};
type ASSESSMENT_QUESTION_TYPE =
  | 'MULTIPLE_CHOICE'
  | 'SINGLE_CHOICE'
  | 'FREE_TEXT'
  | 'RANGE'
  | 'NPS'
  | 'DROPDOWN'
  | 'DATE'
  | 'RATING';

const ASSESSMENT_QUESTION_TYPE_RECORD: Record<
  number,
  ASSESSMENT_QUESTION_TYPE
> = {
  1: 'MULTIPLE_CHOICE',
  2: 'SINGLE_CHOICE',
  3: 'FREE_TEXT',
  4: 'RANGE',
  5: 'NPS',
  6: 'DROPDOWN',
  7: 'DATE',
  8: 'RATING',
};
type ASSESSMENT_INSTANCE_ATTEMPT_STATUS = 'IN_PROGRESS' | 'SCORING' | 'ENDED';
const ASSESSMENT_INSTANCE_ATTEMPT_STATUS_RECORD: Record<
  number,
  ASSESSMENT_INSTANCE_ATTEMPT_STATUS
> = {
  1: 'IN_PROGRESS',
  2: 'SCORING',
  3: 'ENDED',
};
type ASSESSMENT_INSTANCE_STATUS =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'SCORING'
  | 'PASSED'
  | 'FAILED'
  | 'CANCELED';
const ASSESSMENT_INSTANCE_STATUS_RECORD: Record<
  number,
  ASSESSMENT_INSTANCE_STATUS
> = {
  1: 'NOT_STARTED',
  2: 'IN_PROGRESS',
  3: 'SCORING',
  4: 'PASSED',
  5: 'FAILED',
  6: 'CANCELED',
};

type LEARNING_PATH_INSTANCE_STATUS =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED';
const LEARNING_PATH_INSTANCE_STATUS_RECORD: Record<
  number,
  LEARNING_PATH_INSTANCE_STATUS
> = {
  4: 'NOT_STARTED',
  1: 'IN_PROGRESS',
  2: 'COMPLETED',
  3: 'FAILED',
};
type LEARNING_PATH_ITEM_INSTANCE_STATUS =
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELED'
  | 'NOT_STARTED';

const LEARNING_PATH_ITEM_INSTANCE_STATUS_RECORD: Record<
  number,
  LEARNING_PATH_ITEM_INSTANCE_STATUS
> = {
  1: 'IN_PROGRESS',
  2: 'COMPLETED',
  3: 'FAILED',
  4: 'CANCELED',
  5: 'NOT_STARTED',
};

type LEARNING_PATH_ITEM_VALIDATION_TYPE = 'LATEST_SCORE' | 'HIGHEST_SCORE';
const LEARNING_PATH_ITEM_VALIDATION_TYPE_RECORD: Record<
  number,
  LEARNING_PATH_ITEM_VALIDATION_TYPE
> = {
  1: 'LATEST_SCORE',
  2: 'HIGHEST_SCORE',
};

type LEARNER_LEARNING_TYPE =
  | 'ELEARNING'
  | 'ASSESSMENT'
  | 'LEARNING_PATH'
  | 'SURVEY'
  | 'LIVE_COURSE'
  | 'CHECKLIST'
  | 'INDIVIDUAL';
const LEARNER_LEARNING_TYPE_RECORD: Record<number, LEARNER_LEARNING_TYPE> = {
  1: 'ELEARNING',
  2: 'ASSESSMENT',
  3: 'LEARNING_PATH',
  4: 'LIVE_COURSE',
  5: 'SURVEY',
  6: 'CHECKLIST',
  7: 'INDIVIDUAL',
};

const LEARNER_RELATIONSHIP_TYPES_RECORD: Record<string, 'manager'> = {
  MANAGER: 'manager',
};

export const LEARNING_ASSIGNMENT_LEARNING_ITEM_INSTANCE_STATUS: Array<
  | ASSESSMENT_INSTANCE_STATUS
  | ELEARNING_SESSION_STATUS
  | LEARNING_PATH_INSTANCE_STATUS
> = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'SCORING',
  'COMPLETED',
  'COMPLETED_FAILED',
  'COMPLETED_PASSED',
  'PASSED',
  'FAILED',
  'CANCELED',
];
const LEARNING_ASSIGNMENT_LEARNING_ITEM_TYPE = [
  'elearning',
  'learning_path',
  'assessment',
  'live_course',
];
type LEARNING_ASSIGNMENT_DUE_DATE_TYPE = 'RELATIVE' | 'FIXED';
const LEARNING_ASSIGNMENT_DUE_DATE_TYPES_RECORD: Record<
  number,
  LEARNING_ASSIGNMENT_DUE_DATE_TYPE
> = {
  1: 'FIXED',
  2: 'RELATIVE',
};
type LEARNING_ASSIGNMENT_TYPE =
  | 'ELEARNING'
  | 'ASSESSMENT'
  | 'LEARNING_PATH'
  | 'LIVE_COURSE'
  | 'SURVEY'
  | 'CHECKLIST';
const LEARNING_ASSIGNMENT_TYPE_RECORD: Record<
  number,
  LEARNING_ASSIGNMENT_TYPE
> = {
  1: 'ELEARNING',
  2: 'ASSESSMENT',
  3: 'LEARNING_PATH',
  4: 'LIVE_COURSE',
  5: 'SURVEY',
  6: 'CHECKLIST',
};

type SURVEY_FORM_TYPE =
  | 'PRIVATE_REGISTRATION'
  | 'FEEDBACK'
  | 'PUBLIC_REGISTRATION';
const SURVEY_FORM_TYPE_RECORD: Record<number, SURVEY_FORM_TYPE> = {
  1: 'FEEDBACK',
  2: 'PRIVATE_REGISTRATION',
  3: 'PUBLIC_REGISTRATION',
};

type SURVEY_FORM_INSTANCE_STATUS =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'CANCELED';
const SURVEY_FORM_INSTANCE_STATUS_RECORD: Record<
  number,
  SURVEY_FORM_INSTANCE_STATUS
> = {
  1: 'NOT_STARTED',
  2: 'IN_PROGRESS',
  3: 'COMPLETED',
  4: 'CANCELED',
};

type LEARNING_ACTIVITY_TYPE =
  | 'ELEARNING_SESSION'
  | 'ASSESSMENT_INSTANCE'
  | 'LEARNING_PATH_INSTANCE'
  | 'TRAINING_SESSION'
  | 'SURVEY_INSTANCE'
  | 'CHECKLIST_INSTANCE';
const LEARNING_ACTIVITY_TYPE_RECORD: Record<number, LEARNING_ACTIVITY_TYPE> = {
  1: 'TRAINING_SESSION',
  2: 'ELEARNING_SESSION',
  3: 'ASSESSMENT_INSTANCE',
  4: 'LEARNING_PATH_INSTANCE',
  5: 'SURVEY_INSTANCE',
  6: 'CHECKLIST_INSTANCE',
};

type CHECKLIST_INSTANCE_STATUS =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELED';
const CHECKLIST_INSTANCE_STATUS_RECORD: Record<
  number,
  CHECKLIST_INSTANCE_STATUS
> = {
  1: 'NOT_STARTED',
  2: 'IN_PROGRESS',
  3: 'COMPLETED',
  4: 'FAILED',
  5: 'CANCELED',
};

type INDIVIDUAL_LEARNING_TYPE = 'MENTORING' | 'ONE_TO_ONE' | 'EXTERNAL';
const INDIVIDUAL_LEARNING_TYPE_RECORD: Record<
  number,
  INDIVIDUAL_LEARNING_TYPE
> = {
  1: 'MENTORING',
  2: 'ONE_TO_ONE',
  3: 'EXTERNAL',
};

type INDIVIDUAL_LEARNING_DELIVERY_TYPE = LEARNING_TYPE | 'ELEARNING';
const INDIVIDUAL_LEARNING_DELIVERY_TYPE_RECORD: Record<
  number,
  INDIVIDUAL_LEARNING_DELIVERY_TYPE
> = {
  1: 'IN_CLASS',
  2: 'CONFERENCE',
  3: 'VIRTUAL_CLASS',
  4: 'VIRTUAL_CONFERENCE',
  5: 'ELEARNING',
};

type VENDOR_TYPE = 'INTERNAL' | 'EXTERNAL';
const VENDOR_TYPE_RECORD: Record<number, VENDOR_TYPE> = {
  1: 'INTERNAL',
  2: 'EXTERNAL',
};

export const C = {
  USER: {
    ROLES: USER_ROLES,
  },
  CURRENCIES,
  TIMEZONES,
  LANGUAGES,
  COUNTRIES,
  COUNTIES: {},
  LEARNING_TYPES,
  VIRTUAL_LOCATION_TYPES,
  COURSE: {
    SCHEDULE_BLOCK: COURSE_SCHEDULE_BLOCK_MAP,
    REGISTRATION_TYPE: COURSE_REGISTRATION_TYPE_MAP,
  },
  SESSION: {
    LANGUAGES: LANGUAGES,
    STATUSES: SESSION_STATUSES,
    RESOURCE: {
      STATUSES: SESSION_RESOURCE_STATUSES_RECORD,
    },
    REQUIREMENTS: {
      pcs: 'Computers',
      whiteboard: 'Whiteboard',
      speaker: 'Speakers',
    },
    CANCELLATION_REASONS: SESSION_CANCELLATION_REASON_RECORD,
  },
  LEARNING_ASSIGNMENT: {
    TYPE: LEARNING_ASSIGNMENT_TYPE_RECORD,
    DUE_DATE_TYPES: LEARNING_ASSIGNMENT_DUE_DATE_TYPES_RECORD,
    ITEM_INSTANCE_STATUS: LEARNING_ASSIGNMENT_LEARNING_ITEM_INSTANCE_STATUS,
    ITEM_TYPE: LEARNING_ASSIGNMENT_LEARNING_ITEM_TYPE,
  },
  LEARNER: {
    STATUSES: LEARNER_STATUSES_RECORD,
    RELATIONSHIP_TYPES: LEARNER_RELATIONSHIP_TYPES_RECORD,
  },

  APPROVAL: {
    TYPES: APPROVAL_TYPES_RECORD,
  },
  TRAINING_SESSION_LEARNER: {
    ASSIGNMENT_STATUSES:
      TRAINING_SESSION_LEARNER_LEARNING_ASSIGNMENT_STATUSES_RECORD,
  },
  ELEARNING_SESSION: {
    STATUSES: ELEARNING_SESSION_STATUSES_RECORD,
    RESULT_STATUSES: ELEARNING_SESSION_RESULT_STATUSES_RECORD,
    SCORM: {
      STATE: {
        NOT_INITIALIZED: 0,
        INITIALIZED: 1,
      },
    },
  },
  CONTENT_FILE: {
    TYPES: CONTENT_FILE_TYPES_RECORD,
    VERSIONS: CONTENT_FILE_VERSION_RECORD,
  },
  SURVEY: {
    TYPES: SURVEY_FORM_TYPE_RECORD,
    STATUS: SURVEY_FORM_INSTANCE_STATUS_RECORD,
  },
  LEARNING_ACTIVITY: {
    TYPES: LEARNING_ACTIVITY_TYPE_RECORD,
  },
  INDIVIDUAL_LEARNING: {
    TYPES: INDIVIDUAL_LEARNING_TYPE_RECORD,
    DELIVERY_TYPE: INDIVIDUAL_LEARNING_DELIVERY_TYPE_RECORD,
  },
  CHECKLIST: {
    INSTANCE_STATUS: CHECKLIST_INSTANCE_STATUS_RECORD,
  },
  VENDOR: {
    TYPES: VENDOR_TYPE_RECORD,
  },
  ACTIVITY_LOG: {
    EVENTS: {
      CREATED: 'CREATED',
      UPDATED_DATE: 'UPDATED_DATE',
      UPDATED_LOCATION: 'UPDATED_LOCATION',
      UPDATED_COURSE: 'UPDATED_COURSE',
      UPDATED_STATUS: 'UPDATED_STATUS',
      UPDATED_LEARNER_STATUS: 'UPDATED_LEARNER_STATUS',

      ADDED_TRAINER: 'ADDED_TRAINER',
      UPDATED_TRAINER: 'UPDATED_TRAINER',
      REMOVED_TRAINER: 'REMOVED_TRAINER',
      UPDATED_TRAINER_STATUS: 'UPDATED_TRAINER_STATUS',
      BOOKED_TRAINER: 'BOOKED_TRAINER',
      TRAINER_CONFIRMED: 'TRAINER_CONFIRMED',
      TRAINER_DECLINED: 'TRAINER_DECLINED',
      SENT_TRAINER_CONFIRMED_EMAIL: 'SENT_TRAINER_CONFIRMED_EMAIL',
      SENT_TRAINER_REMINDER_EMAIL: 'SENT_TRAINER_REMINDER_EMAIL',

      ADDED_VENUE: 'ADDED_VENUE',
      REMOVED_VENUE: 'REMOVED_VENUE',
      UPDATED_VENUE: 'UPDATED_VENUE',
      UPDATED_VENUE_STATUS: 'UPDATED_VENUE_STATUS',
      BOOKED_VENUE: 'BOOKED_VENUE',
      VENUE_CONFIRMED: 'VENUE_CONFIRMED',
      VENUE_DECLINED: 'VENUE_DECLINED',

      UPDATED_COSTS: 'UPDATED_COSTS',
      ADDED_COSTS: 'ADDED_COSTS',
      REMOVED_COSTS: 'REMOVED_COSTS',

      ADDED_LEARNERS: 'ADDED_LEARNERS',
      REMOVED_LEARNERS: 'REMOVED_LEARNERS',
      UPDATED_LEARNERS: 'UPDATED_LEARNERS',
      INVITED_LEARNERS: 'INVITED_LEARNERS',
      REMINDED_LEARNERS: 'REMINDED_LEARNERS',
      FOLLOWUP_LEARNERS: 'FOLLOWUP_LEARNERS',
      NOTIFY_LEARNERS: 'NOTIFY_LEARNERS',
      LEARNER_CONFIRMED: 'LEARNER_CONFIRMED',
      LEARNER_DECLINED: 'LEARNER_DECLINED',
      LEARNER_CANCELLED: 'LEARNER_CANCELLED',
      LEARNER_REGISTERED: 'LEARNER_REGISTERED',

      LEARNER_CREATED: 'LEARNER_CREATED',
      LEARNER_UPDATED: 'LEARNER_UPDATED',

      LEARNER_ADDED_TO_WAITING_LIST: 'LEARNER_ADDED_TO_WAITING_LIST',
      INVITED_WAITING_LEARNERS: 'INVITED_WAITING_LEARNERS',
      WAITING_LEARNER_CONFIRMED: 'WAITING_LEARNER_CONFIRMED',
      WAITING_LEARNER_DECLINED: 'WAITING_LEARNER_DECLINED',
      ADDED_LEARNERS_FROM_WAITING_LIST: 'ADDED_LEARNERS_FROM_WAITING_LIST',
      REMOVED_WAITING_LEARNERS: 'REMOVED_WAITING_LEARNERS',

      SEND_FEEDBACK_FORM_EMAIL_TO_LEARNERS:
        'SEND_FEEDBACK_FORM_EMAIL_TO_LEARNERS',
      SEND_MANAGER_LEARNER_ABSENT_EMAIL: 'SEND_MANAGER_LEARNER_ABSENT_EMAIL',
      SEND_FEEDBACK_FORM_EMAIL_REMINDER_TO_LEARNERS:
        'SEND_FEEDBACK_FORM_EMAIL_REMINDER_TO_LEARNERS',
      SEND_TRAINER_ATTENDANCE_EMAIL: 'SEND_TRAINER_ATTENDANCE_EMAIL',
      REINVITED_LEARNERS: 'REINVITED_LEARNERS',
      EMAIL_REPLY: 'EMAIL_REPLY',

      SEND_FEEDBACK_FORM_REMINDER_EMAIL_TO_LEARNERS:
        'SEND_FEEDBACK_FORM_REMINDER_EMAIL_TO_LEARNERS',
    },
  },
  EVENTS: {
    CREATED: 'CREATED',
    UPDATED_SESSION: 'UPDATED_SESSION',
    UPDATED_DATE: 'UPDATED_DATE',
    UPDATED_LOCATION: 'UPDATED_LOCATION',
    UPDATED_COURSE: 'UPDATED_COURSE',
    UPDATED_STATUS: 'UPDATED_STATUS',

    ADDED_TRAINER: 'ADDED_TRAINER',
    UPDATED_TRAINER: 'UPDATED_TRAINER',
    REMOVED_TRAINER: 'REMOVED_TRAINER',
    UPDATED_TRAINER_STATUS: 'UPDATED_TRAINER_STATUS',
    BOOKED_TRAINER: 'BOOKED_TRAINER',
    SENT_TRAINER_BOOKING_EMAIL: 'SENT_TRAINER_BOOKING_EMAIL',
    TRAINER_CONFIRMED: 'TRAINER_CONFIRMED',
    TRAINER_DECLINED: 'TRAINER_DECLINED',
    SENT_TRAINER_CONFIRMED_EMAIL: 'SENT_TRAINER_CONFIRMED_EMAIL',
    SENT_TRAINER_REMINDER_EMAIL: 'SENT_TRAINER_REMINDER_EMAIL',

    ADDED_VENUE: 'ADDED_VENUE',
    REMOVED_VENUE: 'REMOVED_VENUE',
    UPDATED_VENUE: 'UPDATED_VENUE',
    UPDATED_VENUE_STATUS: 'UPDATED_VENUE_STATUS',
    BOOKED_VENUE: 'BOOKED_VENUE',
    SENT_VENUE_BOOKING_EMAIL: 'SENT_VENUE_BOOKING_EMAIL',
    VENUE_CONFIRMED: 'VENUE_CONFIRMED',
    VENUE_DECLINED: 'VENUE_DECLINED',

    UPDATED_COSTS: 'UPDATED_COSTS',
    ADDED_COSTS: 'ADDED_COSTS',
    REMOVED_COSTS: 'REMOVED_COSTS',

    ADDED_LEARNERS: 'ADDED_LEARNERS',
    LEARNER_CANCELLED: 'LEARNER_CANCELLED',
    UPDATED_LEARNER_STATUS: 'UPDATED_LEARNER_STATUS',
    REMOVED_LEARNERS: 'REMOVED_LEARNERS',
    UPDATED_LEARNERS: 'UPDATED_LEARNERS',
    INVITED_LEARNERS: 'INVITED_LEARNERS',
    REMINDED_LEARNERS: 'REMINDED_LEARNERS',
    FOLLOWUP_LEARNERS: 'FOLLOWUP_LEARNERS',
    NOTIFY_LEARNERS: 'NOTIFY_LEARNERS',
    LEARNERS_ATTENDED: 'LEARNERS_ATTENDED',
    LEARNER_CONFIRMED: 'LEARNER_CONFIRMED',
    LEARNER_DECLINED: 'LEARNER_DECLINED',
    LEARNER_ABSENT: 'LEARNER_ABSENT',

    LEARNER_ADDED_TO_WAITING_LIST: 'LEARNER_ADDED_TO_WAITING_LIST',
    INVITED_WAITING_LEARNERS: 'INVITED_WAITING_LEARNERS',
    WAITING_LEARNER_MOVED_TO_LEARNER_LIST:
      'WAITING_LEARNER_MOVED_TO_LEARNER_LIST',
    WAITING_LEARNER_DECLINED: 'WAITING_LEARNER_DECLINED',
    REMOVED_WAITING_LEARNERS: 'REMOVED_WAITING_LEARNERS',
    UPDATED_WAITING_LEARNER_STATUS: 'UPDATED_WAITING_LEARNER_STATUS',

    TIME_EVENT: 'TIME_EVENT',
    LEARNER_REGISTERED: 'LEARNER_REGISTERED',
    ADDED_LEARNER: 'ADDED_LEARNER',
    MAX_PAX_REACHED: 'MAX_PAX_REACHED',
    MAX_PAX_PASSED: 'MAX_PAX_PASSED',
    MIN_PAX_REACHED: 'MIN_PAX_REACHED',
    ADDED_START_DATE: 'ADDED_START_DATE',
    REMOVED_START_DATE: 'REMOVED_START_DATE',
    UPDATED_START_DATE: 'UPDATED_START_DATE',
    SEND_FEEDBACK_FORM_EMAIL_TO_LEARNERS:
      'SEND_FEEDBACK_FORM_EMAIL_TO_LEARNERS',
    SEND_FEEDBACK_FORM_EMAIL_REMINDER_TO_LEARNERS:
      'SEND_FEEDBACK_FORM_EMAIL_REMINDER_TO_LEARNERS',
    SEND_TRAINER_ATTENDANCE_EMAIL: 'SEND_TRAINER_ATTENDANCE_EMAIL',
    REINVITED_LEARNERS: 'REINVITED_LEARNERS',

    SEND_FEEDBACK_FORM_REMINDER_EMAIL_TO_LEARNERS:
      'SEND_FEEDBACK_FORM_REMINDER_EMAIL_TO_LEARNERS',
  },
  TIME_EVENTS: {
    MINUTES_RELATIVE_TO_SESSION_START: 'MINUTES_RELATIVE_TO_SESSION_START',
    MINUTES_RELATIVE_TO_SESSION_END: 'MINUTES_RELATIVE_TO_SESSION_END',
    HOURS_RELATIVE_TO_SESSION_START: 'HOURS_RELATIVE_TO_SESSION_START',
    WEEKS_RELATIVE_TO_SESSION_START: 'WEEKS_RELATIVE_TO_SESSION_START',
    MONTHS_RELATIVE_TO_SESSION_START: 'MINUTES_RELATIVE_TO_SESSION_START',
    DAYS_RELATIVE_TO_SESSION_END: 'DAYS_RELATIVE_TO_SESSION_END',
    DAYS_RELATIVE_TO_SESSION_START: 'DAYS_RELATIVE_TO_SESSION_START',
    HOURS_RELATIVE_TO_SESSION_END: 'HOURS_RELATIVE_TO_SESSION_END',
    WEEKS_RELATIVE_TO_SESSION_END: 'WEEKS_RELATIVE_TO_SESSION_END',
    MONTHS_RELATIVE_TO_SESSION_END: 'MONTHS_RELATIVE_TO_SESSION_END',
    SPECIFIC_DAY_OF_MONTH: 'SPECIFIC_DAY_OF_MONTH',
    SPECIFIC_DAY_OF_WEEK: 'SPECIFIC_DAY_OF_WEEK',
    LAST_BUSINESS_DAY_OF_MONTH: 'LAST_BUSINESS_DAY_OF_MONTH',
  },
  COST_TYPE: {
    RESOURCE_TYPES: [
      'Trainer',
      'Learner',
      'Venue',
      'Catering',
      'Session',
    ] as COST_TYPE_RESOURCE_TYPE[],
  },
  ASSESSMENT: {
    VALIDATION_TYPE: ASSESSMENT_VALIDATION_TYPE_RECORD,
    FORM_QUESTION_ITEM_TYPE: ASSESSMENT_FORM_QUESTION_ITEM_TYPE_RECORD,
    QUESTION_TYPE: ASSESSMENT_QUESTION_TYPE_RECORD,
    INSTANCE_ATTEMPT_STATUS: ASSESSMENT_INSTANCE_ATTEMPT_STATUS_RECORD,
    INSTANCE_STATUS: ASSESSMENT_INSTANCE_STATUS_RECORD,
  },
  LEARNING_PATH: {
    INSTANCE_STATUS: LEARNING_PATH_INSTANCE_STATUS_RECORD,
    ITEM: {
      INSTANCE_STATUS: LEARNING_PATH_ITEM_INSTANCE_STATUS_RECORD,
      VALIDATION_TYPE: LEARNING_PATH_ITEM_VALIDATION_TYPE_RECORD,
    },
  },
  LEARNER_LEARNING: {
    TYPE: LEARNER_LEARNING_TYPE_RECORD,
  },
  DEFAULTS: {
    APP: { CURRENCY: 3 },
  },
  I: {
    CURRENCIES: numericInvert(CURRENCIES),
    LANGUAGES: numericInvert(LANGUAGES),
    COUNTRIES: numericInvert(COUNTRIES),
    LEARNING_TYPES: numericInvert(LEARNING_TYPES),
    VIRTUAL_LOCATION_TYPES: numericInvert(VIRTUAL_LOCATION_TYPES),
    COURSE: {
      SCHEDULE_BLOCK: numericInvert(COURSE_SCHEDULE_BLOCK_MAP),
      REGISTRATION_TYPE: numericInvert(COURSE_REGISTRATION_TYPE_MAP),
    },
    SESSION: {
      LANGUAGES: numericInvert(LANGUAGES),
      STATUSES: numericInvert(SESSION_STATUSES),
      RESOURCE: {
        STATUSES: numericInvert(SESSION_RESOURCE_STATUSES_RECORD),
      },

      CANCELLATION_REASONS: numericInvert(SESSION_CANCELLATION_REASON_RECORD),
    },
    LEARNER: {
      STATUSES: numericInvert(LEARNER_STATUSES_RECORD),
      RELATIONSHIP_TYPES: numericInvert(LEARNER_RELATIONSHIP_TYPES_RECORD),
    },

    TRAINING_SESSION_LEARNER: {
      ASSIGNMENT_STATUSES: numericInvert(
        TRAINING_SESSION_LEARNER_LEARNING_ASSIGNMENT_STATUSES_RECORD,
      ),
    },
    ELEARNING_SESSION: {
      STATUSES: numericInvert(ELEARNING_SESSION_STATUSES_RECORD),
      RESULT_STATUSES: numericInvert(ELEARNING_SESSION_RESULT_STATUSES_RECORD),
    },
    CONTENT_FILE: {
      TYPES: numericInvert(CONTENT_FILE_TYPES_RECORD),
      VERSIONS: stringInvert(CONTENT_FILE_VERSION_RECORD),
    },
    APPROVAL: {
      TYPES: numericInvert(APPROVAL_TYPES_RECORD),
    },
    LEARNING_ASSIGNMENT: {
      DUE_DATE_TYPES: numericInvert(LEARNING_ASSIGNMENT_DUE_DATE_TYPES_RECORD),
      TYPE: numericInvert(LEARNING_ASSIGNMENT_TYPE_RECORD),
    },
    SURVEY: {
      TYPES: numericInvert(SURVEY_FORM_TYPE_RECORD),
      STATUS: numericInvert(SURVEY_FORM_INSTANCE_STATUS_RECORD),
    },
    INDIVIDUAL_LEARNING: {
      TYPES: numericInvert(INDIVIDUAL_LEARNING_TYPE_RECORD),
      DELIVERY_TYPE: numericInvert(INDIVIDUAL_LEARNING_DELIVERY_TYPE_RECORD),
    },
    ASSESSMENT: {
      VALIDATION_TYPE: numericInvert(ASSESSMENT_VALIDATION_TYPE_RECORD),
      FORM_QUESTION_ITEM_TYPE: numericInvert(
        ASSESSMENT_FORM_QUESTION_ITEM_TYPE_RECORD,
      ),
      QUESTION_TYPE: numericInvert(ASSESSMENT_QUESTION_TYPE_RECORD),
      INSTANCE_ATTEMPT_STATUS: numericInvert(
        ASSESSMENT_INSTANCE_ATTEMPT_STATUS_RECORD,
      ),
      INSTANCE_STATUS: numericInvert(ASSESSMENT_INSTANCE_STATUS_RECORD),
    },
    LEARNING_PATH: {
      INSTANCE_STATUS: numericInvert(LEARNING_PATH_INSTANCE_STATUS_RECORD),
      ITEM: {
        INSTANCE_STATUS: numericInvert(
          LEARNING_PATH_ITEM_INSTANCE_STATUS_RECORD,
        ),
        VALIDATION_TYPE: numericInvert(
          LEARNING_PATH_ITEM_VALIDATION_TYPE_RECORD,
        ),
      },
    },
    LEARNER_LEARNING: {
      TYPE: numericInvert(LEARNER_LEARNING_TYPE_RECORD),
    },
    LEARNING_ACTIVITY: {
      TYPES: numericInvert(LEARNING_ACTIVITY_TYPE_RECORD),
    },
    CHECKLIST: {
      INSTANCE_STATUS: numericInvert(CHECKLIST_INSTANCE_STATUS_RECORD),
    },
    VENDOR: {
      TYPES: numericInvert(VENDOR_TYPE_RECORD),
    },
  },
};
