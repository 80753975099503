import * as z from 'zod';

import { Subscription, SubscriptionInstance } from './subscription.types.sdk';

export const SubscriptionSchema: z.ZodType<Subscription> = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  vendorId: z.string(),
  tagIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const SubscriptionInstanceSchema: z.ZodType<SubscriptionInstance> =
  z.object({
    id: z.string(),
    startDate: z.date(),
    endDate: z.date(),
    subscriptionId: z.string(),
    learnerId: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  });

export const CreateSubscriptionSchema: z.ZodType<
  Omit<Subscription, 'id' | 'createdAt' | 'updatedAt'>
> = z.object({
  name: z.string(),
  url: z.string(),
  vendorId: z.string(),
  tagIds: z.array(z.string()).optional(),
});
export const UpdateSubscriptionSchema: z.ZodType<
  Partial<Omit<Subscription, 'id' | 'createdAt' | 'updatedAt'>>
> = z.object({
  name: z.string().optional(),
  url: z.string().optional(),
  vendorId: z.string().optional(),
  tagIds: z.array(z.string()).optional(),
});

export const CreateSubscriptionInstanceSchema: z.ZodType<
  Omit<SubscriptionInstance, 'id' | 'createdAt' | 'updatedAt'>[]
> = z.array(
  z.object({
    learnerId: z.string(),
    subscriptionId: z.string(),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
  })
);

export const UpdateSubscriptionInstanceSchema: z.ZodType<
  Partial<Omit<SubscriptionInstance, 'id' | 'createdAt' | 'updatedAt'>>
> = z.object({
  learnerId: z.string().optional(),
  subscriptionId: z.string().optional(),
  startDate: z.coerce.date().optional(),
  endDate: z.coerce.date().optional(),
});

export const SubscriptionIdParamSchema = z.object({
  subscriptionId: z.string(),
});

export const SubscriptionInstanceIdParamSchema = z.object({
  subscriptionInstanceId: z.string(),
});
