import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { ElearningCourse } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  FormWizard,
  Input,
  Modal,
  MultiSelect,
  Separator,
  SideModal,
  TextEditor,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';
import { AdminCertificationSelect } from '@nl-lms/web/adminApp/modules/feature-certification/AdminCertificationSelect';

import { useUploadFile } from '../../../_common/hooks';
import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { AdminCompetencySelect } from '../AdminCompetency/AdminCompetencySelect';
import { AdminContentFileSelect } from '../AdminContentFile/AdminContentFileSelect';
import { AdminLearnerGroupMultiSelect } from '../AdminLearner/AdminLearnerGroupSelect';
import { AdminSurveySingleSelect } from '../AdminSurvey/AdminSurveySelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminLearningAssignmentSelect } from './AdminLearningAssignmentSelect';

type Props = {
  elearningCourse?: Partial<ElearningCourse> & {
    surveyFormIds?: string[];
    certificationIds?: string[];
  };
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  contentFileId: yup.string().required(),
  feedbackFormId: yup.string().nullable(),
  vendorId: yup.string().nullable(),
  certificationId: yup.string().nullable(),
  description: yup.string(),
  duration: yup.number().nullable(),
  thumbnail: yup.string(),
  competencyIds: yup.array(),
  tagIds: yup.array(),
  learningAssignmentIds: yup.array(),
  assignNotStarted: yup.boolean(),
});

const InfoEditForm = ({ register, control, errors, elearningCourse }) => {
  const { onUpload } = useUploadFile({ type: 'text_editor_asset' });
  return (
    <>
      <Box>
        <Box>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input required hasError={!!errors?.name?.message} {...field} />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Competencies">
            <Controller
              name="competencyIds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AdminCompetencySelect
                  onChange={onChange}
                  selectedItems={value}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <FormField label="Learner Visibility">
          <Controller
            name="targetLearnerGroupIds"
            control={control}
            render={({ field }) => (
              <AdminLearnerGroupMultiSelect
                selectedItems={field.value}
                {...field}
              />
            )}
          />
        </FormField>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Content"
            required
            errorMessage={errors?.contentFileId?.message}
          >
            <Controller
              name="contentFileId"
              control={control}
              render={({ field }) => (
                <AdminContentFileSelect
                  {...field}
                  initialSelectedItem={
                    elearningCourse?.contentFile
                      ? {
                          value: elearningCourse?.contentFileId,
                          label: elearningCourse?.contentFile?.name,
                        }
                      : undefined
                  }
                  hasError={!!errors?.contentFileId?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Feedback Form"
            errorMessage={errors?.feedbackFormId?.message}
          >
            <Controller
              name="feedbackFormId"
              control={control}
              render={({ field }) => (
                <AdminSurveySingleSelect
                  {...field}
                  initialSelectedItem={field.value}
                  isClearable
                  hasError={!!errors?.feedbackFormId?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <FormField label="Vendor" errorMessage={errors?.vendorId?.message}>
          <Controller
            name="vendorId"
            control={control}
            render={({ field }) => (
              <AdminVendorSelect
                {...field}
                initialSelectedItem={field.value}
                isClearable
                hasError={!!errors?.vendorId?.message}
              />
            )}
          />
        </FormField>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Certification"
            errorMessage={errors?.certificationId?.message}
          >
            <Controller
              name="certificationId"
              control={control}
              render={({ field }) => (
                <AdminCertificationSelect
                  {...field}
                  initialSelectedItem={field.value}
                  isClearable
                  hasError={!!errors?.certificationId?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Duration (minutes)"
            errorMessage={errors?.duration?.message}
          >
            <Controller
              name="duration"
              control={control}
              render={({ field }) => (
                <Input
                  required
                  hasError={!!errors?.name?.message}
                  {...field}
                  type="number"
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Description"
            errorMessage={errors?.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextEditor {...field} onUploadFile={onUpload} />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Thumbnail"
            errorMessage={errors?.thumbnail?.message}
          >
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput
                  {...field}
                  initialS3Url={elearningCourse.thumbnail}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const VersionEditForm = ({ control, elearningCourse }) => {
  const name = useWatch({ control, name: 'name' });
  const contentFileId = useWatch({ control, name: 'contentFileId' });

  const allowedDiffKeys = ['contentFileId', 'name'];
  const nextValues = { name, contentFileId };
  const prevValues = elearningCourse;
  const diff = _.diff(
    _.pick(prevValues, allowedDiffKeys),
    _.pick(nextValues, allowedDiffKeys)
  );
  const hasChanges = !!diff.length;

  const currentVersion = elearningCourse?.elearningCourseHistory?.version || 0;
  const nextVersion = hasChanges ? currentVersion + 1 : currentVersion;

  const reassignStatusOptions = useMemo(() => {
    return _.map(C.ELEARNING_SESSION_STATUSES, (label, value) => ({
      value: parseInt(value),
      label,
    })).filter((o) => o.value !== C.I_ELEARNING_SESSION_STATUSES.CANCELED);
  }, []);

  return (
    <>
      <Box>
        <Box>
          <FormField label="Changes">
            {!hasChanges && 'No changes made'}
            {hasChanges && `${diff.length} changes (${diff.join(', ')})`}
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Current Version">
            <Input
              name="currentVersion"
              value={`v${currentVersion}`}
              disabled
            />
          </FormField>
        </Box>
        <Box>
          <FormField label="Next Version">
            <Input name="nextVersion" value={`v${nextVersion}`} disabled />
          </FormField>
        </Box>
      </Box>

      <Box>
        <Box>
          <Controller
            name="learningAssignmentIds"
            control={control}
            render={({ field }) => {
              return (
                <FormField
                  label="Reassign"
                  helpText={`The new selected version is about to affect ${
                    (field.value || []).length
                  } assignments.`}
                >
                  <AdminLearningAssignmentSelect
                    {...field}
                    elearningCourseId={elearningCourse.id}
                  />
                </FormField>
              );
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Reassign for"
            helpText="Select elearning session statuses for which to reassign"
          >
            <Controller
              name="assignForStatuses"
              control={control}
              render={({ field }) => {
                return (
                  <MultiSelect
                    name="assignForStatuses"
                    placeholder="Select statuses for which you want to reassign"
                    options={reassignStatusOptions}
                    onChange={field.onChange}
                    isCreatable={false}
                    selectedItems={(field.value || []).map((v) => ({
                      label: C.ELEARNING_SESSION_STATUSES[v],
                      value: v,
                    }))}
                    initialSelectedItems={(field.value || []).map((v) => ({
                      label: C.ELEARNING_SESSION_STATUSES[v],
                      value: v,
                    }))}
                  />
                );
              }}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

export const AdminElearningCourseEditFormSideModal = ({
  elearningCourse = {},
}: Props) => {
  const defaultValues = useMemo(
    () => ({
      ..._.omit(elearningCourse, 'id'),
      name: elearningCourse?.name || '',
      assignNotStarted: true,
      learningAssignmentIds: [],
      duration: 0,
      feedbackFormId: (elearningCourse?.surveyFormIds || [])[0] || '',
      vendorId: elearningCourse?.vendorId || null,
      competencyIds: elearningCourse?.competencyIds || [],
      tagIds: elearningCourse?.tagIds || [],
      contentFileId: elearningCourse?.contentFileId || '',
      description: elearningCourse?.description || '',
      thumbnail: elearningCourse?.thumbnail || '',
      certificationId: (elearningCourse?.certificationIds || [])[0] || '',
    }),
    [elearningCourse]
  );
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const { hide, show } = useModal();
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateElearningCourseMutation',
    updateHookName: 'useUpdateElearningCourseMutation',
    entityId: elearningCourse.id,
  });

  return (
    <FormWizard.Provider steps={['info', 'version']}>
      <SideModal.Content>
        <SideModal.Header>
          <SideModal.Title>
            {elearningCourse.id
              ? 'Edit Elearning Course'
              : 'Create New Elearning Course'}
          </SideModal.Title>
        </SideModal.Header>
        <Separator marginTop={0} />
        <SideModal.Body>
          <FormWizard.Tabs />
          <Separator marginBottom={20} marginTop={0} />
          <FormWizard.Step name="info">
            <InfoEditForm
              register={register}
              errors={errors}
              control={control}
              elearningCourse={elearningCourse}
            />
          </FormWizard.Step>
          <FormWizard.Step name="version">
            <VersionEditForm
              control={control}
              elearningCourse={elearningCourse}
            />
          </FormWizard.Step>
        </SideModal.Body>
        <SideModal.Actions>
          <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
          <SubmitButton
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </SideModal.Actions>
      </SideModal.Content>
    </FormWizard.Provider>
  );
};

const SubmitButton = ({ onSubmit, isLoading }) => {
  const { activeStep } = useContext(FormWizard.Context);
  return (
    <Button
      label={(() => {
        if (activeStep === 'info') return 'Save';
        if (activeStep === 'version') return 'Save & Assign';
        return 'Save';
      })()}
      onClick={onSubmit}
      isLoading={isLoading}
    />
  );
};
