import React from 'react';
import * as Icon from '../../../components/Icon';
import { useClassNameProps } from '@nl-lms/ui/hooks';

export const FilterBarBadge = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onRemove?: () => void;
    onClick?: () => void;
    type: string;
  }
>(({ children, type, onRemove = null, onClick }, ref) => {
  const componentsClassNameProps = useClassNameProps(
    'filter-bar__badge-components',
    onClick ? 'filter-bar__badge-components--active' : null
  );
  return (
    <div className="filter-bar__badge" ref={ref} data-badge-type={type}>
      <div className="filter-bar__badge-content">
        <span {...componentsClassNameProps} onClick={onClick}>
          {children}
        </span>
        {onRemove ? (
          <FilterBarBadgeComponent title="Remove" onClick={onRemove}>
            <Icon.CloseIcon strokeWidth="1" />
          </FilterBarBadgeComponent>
        ) : null}
      </div>
    </div>
  );
});

export const FilterBarBadgeComponent = ({
  children,
  title,
// @ts-ignore
  onClick = null,
}: {
  children: React.ReactNode;
  title: string;
  onClick?: () => void;
}) => {
  const classNameProps = useClassNameProps(
    'filter-bar__badge-component',
// @ts-ignore
    onClick ? 'filter-bar__badge-component--active' : null
  );
  return (
    <span title={title} onClick={onClick} {...classNameProps}>
      {children}
    </span>
  );
};
