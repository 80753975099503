export const AutomationRouterPrefix = '/automation';

export const AutomationScope = {
  viewAction: { resource: 'action', action: 'view' },
  createAction: { resource: 'action', action: 'create' },
  updateAction: { resource: 'action', action: 'update' },
  deleteAction: { resource: 'action', action: 'delete' },
  viewRule: { resource: 'rule', action: 'view' },
  createRule: { resource: 'rule', action: 'create' },
  updateRule: { resource: 'rule', action: 'update' },
  deleteRule: { resource: 'rule', action: 'delete' },
} as const;
