import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Spinner, useModal } from '@nl-lms/ui/components';
import { decodeHTML } from '@nl-lms/ui/utils';

import { SurveyPlayer } from '../../../_common/modules/SurveyPlayer/SurveyPlayer';
import { learnerApi } from '../../../_common/services/api';
import './LearnerSurveyModal.scss';

const parseResponse = (surveyForm) => ({
  ...surveyForm,
  questions: surveyForm?.questions?.map((q) => ({
    ...q,
    options: q.options.map((o) => ({
      ...o,
      title: decodeHTML(o.title),
    })),
  })),
});

export type LearnerSurveyModalReturnType = {
  success: boolean;
  payload: {
    answers: any;
    timeSpent: number;
  };
};

const { useGetSurveyFormForRegistrationQuery } = learnerApi;
export const LearnerSurveyModal = ({
  learnerId,
  id,
}: {
  learnerId: string;
  id: string;
}) => {
  const { hide } = useModal<LearnerSurveyModalReturnType>();
  const [formTimeSpent, setFormTimeSpent] = useState(0);

  const fetchArguments = useMemo(
    () => ({
      learnerId,
      id,
    }),
    [id]
  );
  const { isLoading: isLoadingSurveyForm, data: rawData } =
    useGetSurveyFormForRegistrationQuery(fetchArguments);
  const surveyForm = useMemo(() => parseResponse(rawData || {}), [rawData]);

  const onSubmit = useCallback(
    async (id, answers, timeSpent) => {
      setFormTimeSpent(Math.ceil(timeSpent / 1000));
      hide({
        success: true,
        payload: { answers, timeSpent },
      });
    },
    [learnerId, id, formTimeSpent]
  );

  const { t } = useTranslation('learner');

  return (
    <Modal.Content className="learner-survey-player-modal">
      <Modal.Header>a</Modal.Header>
      {isLoadingSurveyForm ? (
        <Spinner />
      ) : (
        <SurveyPlayer
          submitBtnLabel={t('externalActions.surveySendRegistration')}
          surveyForm={{ ...surveyForm, timeSpent: formTimeSpent }}
          onSubmitSurveyAnswers={onSubmit}
        />
      )}
    </Modal.Content>
  );
};
