import React, { createContext, useMemo } from 'react';

import { AppQuery } from '@nl-lms/common/shared';
import { NotificationTemplate } from '@nl-lms/feature/notifications/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import { _ } from '@nl-lms/vendor';
import { notificationsApi } from '@nl-lms/web/_common/services/api';

const { useListNotificationTemplatesQuery } = notificationsApi;

interface NotificationTemplateContextType {
  templatesByName: Record<string, NotificationTemplate>;
}

export const NotificationTemplateContext =
  createContext<NotificationTemplateContextType>(
    {} as NotificationTemplateContextType
  );

// TODO: This is a temporary solution so that we always have the notification
// templates ready for use inside a component/form.
// We should move it to some kind of external store with selector support
export const NotificationTemplateProvider = ({ children }) => {
  const listNotificationTemplatesQuery = useMemo<AppQuery>(() => {
    return {
      pagination: { disabled: true },
    };
  }, []);
  const { data: notificationTemplates } = useListNotificationTemplatesQuery({
    query: {
      query: transformTsRestQuery(listNotificationTemplatesQuery),
    },
  });

  const templatesByName = useMemo(() => {
    if (!notificationTemplates) return {};
    return _.indexBy(notificationTemplates.rows, 'name');
  }, [notificationTemplates]);

  return (
    <NotificationTemplateContext.Provider value={{ templatesByName }}>
      {children}
    </NotificationTemplateContext.Provider>
  );
};
