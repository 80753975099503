import React from 'react';

export const TagIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.59 12.41L12.42 19.58C12.0449 19.9556 11.5358 20.1666 11.005 20.1666C10.4742 20.1666 9.96514 19.9556 9.59 19.58L1 11V1H11L19.59 9.59C20.3654 10.3701 20.3654 11.6299 19.59 12.41Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <rect x="5" y="5" width="2" height="2" fill="currentColor" />
  </svg>
);
