import { z } from 'zod';

export enum ElearningSessionScormState {
  NOT_INITIALIZED = 0,
  INITIALIZED = 1,
}

export enum ElearningSessionStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  FAILED = 4,
  COMPLETED_PASSED = 5,
  COMPLETED_FAILED = 6,
  CANCELED = 7,
}

export enum ElearningSessionResult {
  PASSED = 1,
  FAILED = 2,
}

export enum ContentFileType {
  SCORM = 1,
  DOCUMENT = 2,
  EMBED = 3,
  MEDIA = 4,
}

export const ContentFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
  metadata: z.record(z.string(), z.unknown()),
  type: z.nativeEnum(ContentFileType),
  isReady: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ContentFile = z.infer<typeof ContentFileSchema>;

export const ElearningCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  contentFileType: z.nativeEnum(ContentFileType),
  contentFileVersion: z.string(),
  contentFileId: z.string().nullable(),
  vendorId: z.string().nullable(),
  userId: z.string(),
  thumbnail: z.string(),
  duration: z.number().nullable(),
  customerInternalId: z.string().nullable(),
  competencyIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  elearningCourseHistoryId: z.string().nullable(),
  rating: z.number().nullable(),
  ratingCount: z.number().nullable(),
  deletedAt: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ElearningCourse = z.infer<typeof ElearningCourseSchema>;

export const ElearningCourseHistorySchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  contentFileType: z.number(),
  contentFileVersion: z.string(),
  contentFileId: z.string().nullable(),
  userId: z.string(),
  thumbnail: z.string(),
  customerInternalId: z.string().nullable(),
  competencyIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  elearningCourseId: z.string().nullable(),
  version: z.number(),
  rating: z.number().nullable(),
  ratingCount: z.number().nullable(),
  deletedAt: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ElearningCourseHistory = z.infer<
  typeof ElearningCourseHistorySchema
>;

export const ElearningSessionSchema = z.object({
  id: z.string(),
  elearningCourseId: z.string(),
  learnerId: z.string(),
  state: z.nativeEnum(ElearningSessionScormState),
  contentFileId: z.string().nullable(),
  payload: z.record(z.string(), z.unknown()),
  startedOn: z.date().nullable(),
  finishedOn: z.date().nullable(),
  status: z.nativeEnum(ElearningSessionStatus),
  score: z.number().nullable(),
  timeSpent: z.number().nullable(),
  learningAssignmentInstanceId: z.string().nullable(),
  elearningCourseHistoryId: z.string().nullable(),
  learnerHistoryId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ElearningSession = z.infer<typeof ElearningSessionSchema>;
