import { useEffect, useSyncExternalStore } from 'react';
import store from 'store';

export function useLocalStorageState<T>(
  stateKey: string,
  defaultValue: T
): [T, (value: T) => void] {
  const setState = (newValue: T) => {
    store.set(stateKey, newValue);
    window.dispatchEvent(
      // @ts-expect-error
      new StorageEvent('storage', { key: stateKey, newValue })
    );
  };
  const getSnapshot = () => store.get(stateKey) as T;
  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  };
  const state = useSyncExternalStore(subscribe, getSnapshot);
  const actualValue = state !== undefined ? state : defaultValue;
  return [actualValue, setState];
}
