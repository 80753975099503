import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { SubscriptionsRouterPrefix } from './subscription.constants.sdk';
import {
  CreateSubscriptionInstanceSchema,
  CreateSubscriptionSchema,
  SubscriptionIdParamSchema,
  SubscriptionInstanceIdParamSchema,
  SubscriptionInstanceSchema,
  SubscriptionSchema,
  UpdateSubscriptionInstanceSchema,
  UpdateSubscriptionSchema,
} from './subscription.schema.sdk';

const contract = initContract();

// the description field is for indicating if it's a mutation or a query

export const SubscriptionsRouterContract = contract.router({
  listSubscriptions: {
    method: 'GET',
    path: `${SubscriptionsRouterPrefix}/`,
    responses: ContractPaginatedResponse(SubscriptionSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  removeSubscriptions: {
    method: 'DELETE',
    path: `${SubscriptionsRouterPrefix}/`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  createSubscription: {
    method: 'POST',
    path: `${SubscriptionsRouterPrefix}/`,
    responses: ContractStandardResponse(SubscriptionSchema),
    body: CreateSubscriptionSchema,
  },
  updateSubscription: {
    method: 'POST',
    path: `${SubscriptionsRouterPrefix}/:subscriptionId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateSubscriptionSchema,
    pathParams: SubscriptionIdParamSchema,
  },
  getSubscription: {
    method: 'GET',
    path: `${SubscriptionsRouterPrefix}/:subscriptionId`,
    responses: ContractStandardResponse(SubscriptionSchema),
    pathParams: SubscriptionIdParamSchema,
  },
  listSubscriptionInstances: {
    method: 'GET',
    path: `${SubscriptionsRouterPrefix}/instance`,
    responses: ContractPaginatedResponse(SubscriptionInstanceSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createSubscriptionInstances: {
    method: 'POST',
    path: `${SubscriptionsRouterPrefix}/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: CreateSubscriptionInstanceSchema,
  },
  updateSubscriptionInstance: {
    method: 'POST',
    path: `${SubscriptionsRouterPrefix}/instance/:subscriptionInstanceId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateSubscriptionInstanceSchema,
    pathParams: SubscriptionInstanceIdParamSchema,
  },
  removeSubscriptionInstances: {
    method: 'DELETE',
    path: `${SubscriptionsRouterPrefix}/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
});
