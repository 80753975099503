import { PasteRule } from '@remirror/pm/paste-rules';
import { useChainedCommands, useCurrentSelection } from '@remirror/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { PlainExtension } from 'remirror';

import { Button } from '../Button/Button';
import * as Icon from '../Icon';
import { Modal, useModal } from '../Modal/Modal';
import { TextEditorContext } from './TextEditorContext';

export class TextEditorFileUploadExtension extends PlainExtension {
  get name() {
    return 'file-upload' as const;
  }

  createPasteRules(): PasteRule[] {
    return [
      {
        type: 'file',
        regexp: /^((?!image).)*$/i,
        fileHandler: (props) => {
          let pos: number | undefined;

          // if (props.type === 'drop') {
          //   pos = props.pos;
          // }
          // const element = document.getElementById(
          //   'text-editor-file-upload-input'
          // );
          // TODO: Not working atm
          // I've been trying to fire a change event on the file input
          // in order to show the upload progress modal but no luck so far
          // if (element) {
          //   const event = new Event('change');
          //   console.log(event);
          //   // @ts-ignore
          //   event.target.files = props.files;
          //   element.dispatchEvent(event);
          // }

          return true;
        },
      },
    ];
  }
}

export const TextEditorInsertFilesButton = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback((e) => {
    e.stopPropagation();
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);
  return (
    <div className="texteditor__toggle-button" title="Insert File">
      <Modal.Provider>
        <button onClick={onClick}>
          <Icon.FileIcon />
        </button>
        <TextEditorAddFilesInput ref={fileInputRef} />
      </Modal.Provider>
    </div>
  );
};

const TextEditorAddFilesInput = React.forwardRef<HTMLInputElement, {}>(
  (props, forwardedRef) => {
    const { show } = useModal();
    const { onUploadFile } = useContext(TextEditorContext);
    const chain = useChainedCommands();
    const { from } = useCurrentSelection();

    const onChange = useCallback(
      async (e) => {
        const files = (await show({ files: e.target.files })) as {
          name: string;
          src: string;
        }[];
        if (!files) return;
        let startPosition = from;
        files.forEach((file) => {
          const endPosition = startPosition + file.name.length;
          chain
            .insertText(file.name, { from: startPosition })
            .updateLink(
              { href: file.src, auto: false },
              { from: startPosition, to: endPosition }
            );
          startPosition = endPosition + 1;
        }, []);
        chain.focus().run();
      },
      [from]
    );
    if (!onUploadFile) return null;
    return (
      <>
        <input
          ref={forwardedRef}
          onChange={onChange}
          id="text-editor-file-upload-input"
          type="file"
          multiple
          accept="*"
          style={{ display: 'none' }}
        />
        <Modal.Content>
          <TextEditorFileUploadProgress onUploadFile={onUploadFile} />
        </Modal.Content>
      </>
    );
  }
);

export const TextEditorInsertImagesButton = () => {
  const { onUploadFile } = useContext(TextEditorContext);
  const ref = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    if (ref.current) ref.current.click();
  }, []);
  if (!onUploadFile) return <></>;
  return (
    <div className="texteditor__toggle-button" title="Insert Image">
      <Modal.Provider>
        <button onClick={onClick}>
          <Icon.ImageIcon />
        </button>
        <TextEditorAddImagesInput onUploadImage={onUploadFile} ref={ref} />
      </Modal.Provider>
    </div>
  );
};

const TextEditorAddImagesInput = React.forwardRef<
  HTMLInputElement,
  { onUploadImage: (file: File) => Promise<string> }
>(({ onUploadImage }, forwardedRef) => {
  const { show } = useModal();
  const chain = useChainedCommands();

  const onChange = useCallback(async (e) => {
    const files = (await show({ files: e.target.files })) as {
      name: string;
      src: string;
    }[];
    if (!files) return;
    for (const file of files) {
      chain.insertImage({
        src: file.src,
        name: file.name,
        title: file.name,
        width: '90%',
        minWidth: '50px',
        objectFit: 'contain',
      });
    }
    chain.run();
  }, []);
  return (
    <>
      <input
        ref={forwardedRef}
        onChange={onChange}
        type="file"
        multiple
        accept=".svg,.jpg,.gif,.jpeg,.png"
        style={{ display: 'none' }}
      />
      <Modal.Content>
        <TextEditorFileUploadProgress onUploadFile={onUploadImage} />
      </Modal.Content>
    </>
  );
});

type FileUploadState = Record<string, 'inprogress' | 'error' | 'completed'>;
type FileUploadAction =
  | { type: 'error'; payload: string }
  | { type: 'completed'; payload: string }
  | { type: 'inprogress'; payload: string };
const fileUploadReducer = (
  state: FileUploadState,
  action: FileUploadAction
): FileUploadState => {
  switch (action.type) {
    case 'inprogress':
      return {
        ...state,
        [action.payload]: 'inprogress',
      };
    case 'completed':
      return {
        ...state,
        [action.payload]: 'completed',
      };
    case 'error':
      return {
        ...state,
        [action.payload]: 'error',
      };
  }
};

const getLabelFromUploadState = (uploadState: FileUploadAction['type']) => {
  if (!uploadState) return 'Pending';
  if (uploadState === 'inprogress') return 'Uploading';
  if (uploadState === 'error') return 'Failed';
  return 'Completed';
};

const TextEditorFileUploadProgress = ({
  files: _files = undefined,
  onUploadFile,
}: {
  files?: FileList;
  onUploadFile: (file: File) => Promise<string>;
}) => {
  const files = useMemo(() => {
    if (!_files) return;
    return Array.from(_files);
  }, []);
  const { hide } = useModal();
  const [uploadState, dispatch] = useReducer<
    React.Reducer<FileUploadState, FileUploadAction>
  >(fileUploadReducer, {});
  const uploadFiles = useCallback(async () => {
    const uploadedFiles: { name: string; src: string }[] = [];
    if (!files) return;
    for (const file of files) {
      dispatch({ type: 'inprogress', payload: file.name });
      const src = await onUploadFile(file);
      if (!src) {
        dispatch({ type: 'error', payload: file.name });
      } else {
        dispatch({ type: 'completed', payload: file.name });
        uploadedFiles.push({ name: file.name, src });
      }
    }
    hide(uploadedFiles);
  }, [files]);

  useEffect(() => {
    uploadFiles();
  }, []);
  return (
    <>
      <Modal.Header>
        <Modal.Title>Upload Progress</Modal.Title>
      </Modal.Header>
      <div className="texteditor__file-upload-list">
        {!!files
          ? files.map((file) => (
              <div className="texteditor__file-upload-list-item">
                <div>{file.name}</div>
                <div>{getLabelFromUploadState(uploadState[file.name])}</div>
              </div>
            ))
          : null}
      </div>
      <Modal.Actions>
        <Modal.Close disabled>
          <Button label="Submit" isLoading disabled />
        </Modal.Close>
      </Modal.Actions>
    </>
  );
};
