import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRoutingAction = ({
  route,
  raw,
}: { route?: any; raw?: boolean } = {}) => {
  const navigate = useNavigate();
  return useCallback(
    (args: string | null = null) => {
      let routePath;
      let _raw = raw;
      if (route) {
        routePath = typeof route === 'string' ? route : route(args);
      } else if (typeof args === 'string') {
        routePath = args;
      } else {
        console.warn('No path provided');
        routePath = '/404';
        _raw = true;
      }

      if (_raw) {
        window.location = routePath;
      } else {
        navigate(routePath);
      }
    },
    [route]
  );
};
