import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  selectors,
  LearnerAssessmentPlayerLocation,
} from './learnerAssessmentPlayerSlice';

import { CircleTimer } from '@nl-lms/ui/components';
import { LearnerAssessmentPlayerHome } from './LearnerAssessmentPlayerHome/LearnerAssessmentPlayerHome';
import { LearnerAssessmentPlayerAttemptForm } from './LearnerAssessmentPlayerAttemptForm/LearnerAssessmentPlayerAttemptForm';
import { LearnerAssessmentResultMessage } from './LearnerAssessmentPlayerAttemptResult/LearnerAssessmentPlayerAttemptResult';
import {
  useEndAttempt,
  usePersistAssessmentFormAnswers,
} from './learnerAssessmentPlayerHooks';

import './LearnerAssessmentPlayer.scss';
import { useBroadcastChannel } from '@nl-lms/ui/hooks';

const PlayerStateToComponentMap = {
  [LearnerAssessmentPlayerLocation.Home]: LearnerAssessmentPlayerHome,
  [LearnerAssessmentPlayerLocation.AttemptForm]:
    LearnerAssessmentPlayerAttemptForm,
  [LearnerAssessmentPlayerLocation.AttemptResult]:
    LearnerAssessmentResultMessage,
};

const LearnerAssessmentPlayer = () => {
  const playerState = useSelector(selectors.getPlayerLocation);
  const Component = PlayerStateToComponentMap[playerState];
  usePersistAssessmentFormAnswers();
  const { broadcastMessage } = useBroadcastChannel({
    channel: 'Assessment Player',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [playerState]);

  const handlePageUnload = useCallback(() => {
    broadcastMessage('CLOSED');
  }, [broadcastMessage]);

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageUnload, true);
    return () =>
      window.removeEventListener('beforeunload', handlePageUnload, true);
  }, [handlePageUnload]);

  return (
    <div className="learner-assessment-player">
      {Component && <Component />}
    </div>
  );
};

const LearnerAssessmentTimer = () => {
  const playerState = useSelector(selectors.getPlayerLocation);
  const timeLimit = useSelector(selectors.getAttemptTimeLimit);
  const remainingTime = useSelector(selectors.getRemainingAttemptTime);
  const { onEndAttempt } = useEndAttempt();
  const userTimeSpent = useSelector(selectors.getUserTimeSpent) as number;
  const assessmentTimeSpent = (useSelector(selectors.getAttemptTimeSpent) ||
    0) as number;

  if (playerState !== LearnerAssessmentPlayerLocation.AttemptForm) {
    return null;
  }

  return (
    <CircleTimer
      duration={timeLimit * 60}
      onComplete={onEndAttempt}
      initialRemainingTime={remainingTime}
      timeSpent={userTimeSpent + assessmentTimeSpent * 1000}
    />
  );
};

LearnerAssessmentPlayer.Timer = LearnerAssessmentTimer;

export default LearnerAssessmentPlayer;
