import clsx from 'clsx';
import React from 'react';

import './CardIconCaption.scss';

export const CardIconCaption = ({ icon, caption, muted = false }) => {
  return (
    <div
      className={clsx('card-icon-caption', muted && 'card-icon-caption--muted')}
    >
      <span className="card-icon-caption__icon">{icon}</span>
      <span className="card-icon-caption__content">{caption}</span>
    </div>
  );
};
