import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QueryFilter,
  QueryFilterCombinator,
  QueryOperator,
} from '@nl-lms/common/shared';
import {
  AsyncMultiSelect,
  AsyncSingleSelect,
  Icon,
  Sensitive,
  Tooltip,
  useListParserModal,
} from '@nl-lms/ui/components';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { adminApi } from '../../../_common/services/api';
import { fetchAndMapLearners } from '../../_common/utils/fetchEntitiesForSelectMethods';

const { useLazyListLearnersByIdsQuery, useLazyListLearnersQuery } = adminApi;

export const AdminLearnerSingleSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItem = null,
    returnEntireItemOnChange = true,
    isClearable,
  } = props;

  const onChangeInputValue = (value) => {
    if (!value) {
      onChange('');
    }
  };

  return (
    <Sensitive>
      <AsyncSingleSelect
        name={name}
        placeholder="Search by name or email"
        loadOptions={fetchAndMapLearners}
        onChange={onChange}
        initialSelectedItem={initialSelectedItem}
        returnEntireItemOnChange={returnEntireItemOnChange}
        onChangeInputValue={onChangeInputValue}
        isClearable={isClearable}
      />
    </Sensitive>
  );
};

export const AdminLearnerMultiSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItems: _initialSelectedItems = [],
    returnEntireItemOnChange = true,
    disabled = false,
  } = props;

  const [listLearnersByIds, { isLoading: isFetchByIdLoading }] =
    useLazyListLearnersByIdsQuery();

  const [initialSelectedItems, setInitialSelectedItems] = useState(
    _initialSelectedItems
  );
  const [isPasteLoading, setIsPasteLoading] = useState(false);

  const initialSelectedItemsAreIds = useMemo(() => {
    return (
      initialSelectedItems &&
      initialSelectedItems.length &&
      typeof initialSelectedItems[0] === 'string'
    );
  }, [initialSelectedItems]);

  const onLoadInitialSelected = useCallback(async () => {
    if (!initialSelectedItemsAreIds) return;

    const result = await listLearnersByIds(initialSelectedItems);

    if (!result.data) return;
    if (result.data.rows.length > 1000) return;

    setInitialSelectedItems(
      result.data.rows.map((l) => ({
        value: l.id,
        label: `${l.firstName} ${l.lastName} · ${l.email}`,
        learner: l,
      }))
    );
  }, []);

  const onPasteLearners = useCallback(
    (learners) => {
      // TODO: This is a temp fix. It will fail if the user selects some learners
      // and then pastes a list
      setInitialSelectedItems(learners);
      if (returnEntireItemOnChange) {
        onChange(learners);
      } else {
        onChange(
          learners.map((l) => l.value),
          name
        );
      }
    },
    [returnEntireItemOnChange, name]
  );

  useEffect(() => {
    onLoadInitialSelected();
  }, []);

  return (
    <Sensitive>
      <AsyncMultiSelect
        name={name}
        placeholder="Search by name or email"
        loadOptions={fetchAndMapLearners}
        returnEntireItemOnChange={returnEntireItemOnChange}
        onChange={onChange}
        isLoading={isFetchByIdLoading || isPasteLoading}
        disabled={disabled}
        key={`learner-select-${name}-${initialSelectedItemsAreIds}-${isPasteLoading}`}
        initialSelectedItems={initialSelectedItems}
      >
        <AdminPasteLearnersModalButton
          setInitialSelectedItems={onPasteLearners}
          setIsLoading={setIsPasteLoading}
          disabled={disabled}
        />
      </AsyncMultiSelect>
    </Sensitive>
  );
};

const AdminPasteLearnersModalButton = ({
  setIsLoading,
  setInitialSelectedItems,
  disabled,
}) => {
  const showListParserModal = useListParserModal();
  const [listLearners] = useLazyListLearnersQuery();
  const listLearnersAction = useAction(listLearners);

  const onClickIcon = useCallback(async () => {
    if (disabled) return;
    const parseResult = await showListParserModal({
      fieldOptions: [
        { value: 'email', label: 'Email' },
        { value: 'customerInternalId', label: 'Internal ID' },
      ],
      title: 'Paste a List of Learners',
    });

    const list = parseResult?.list || [];
    const field = parseResult?.field || 'email';
    if (!list.length) return;

    setIsLoading(true);
    const queryFilter = new QueryFilter();
    list.forEach((value) => {
      queryFilter.add(
        {
          field,
          operator: QueryOperator.Like,
          value,
        },
        { combinator: QueryFilterCombinator.Or }
      );
    });

    const learners = await listLearnersAction({
      pagination: { disabled: true },
      filters: queryFilter.appQueryFilter,
    });
    setIsLoading(false);

    if (!learners?.data) return;

    setInitialSelectedItems(
      learners.data.rows.map((l) => ({
        value: l.id,
        label: `${l.firstName} ${l.lastName} · ${l.email}`,
        learner: l,
      }))
    );
  }, [disabled]);

  return (
    <Tooltip
      onClick={onClickIcon}
      title="Paste from a list"
      className={`downshift-select__input-icon ${
        disabled ? 'downshift-select__input-icon--disabled' : ''
      }`}
    >
      <Icon.ClipboardIcon />
    </Tooltip>
  );
};
