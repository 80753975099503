import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Button,
  Separator,
  Spinner,
  Tooltip,
  Typography,
} from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { authStore } from '../../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../../_common/services/api';
import { routes } from '../../../../lib/routes';
import { useStartAttempt } from '../learnerAssessmentPlayerHooks';
import { actions, selectors } from '../learnerAssessmentPlayerSlice';
import './LearnerAssessmentPlayerHome.scss';

const {
  useLazyLearnerAssessmentInstanceAttemptQuery,
  useLazyLearnerAssessmentInstanceQuery,
} = learnerApi;

const useLoadInitialData = (assessmentInstanceId) => {
  const dispatch = useDispatch();

  const [
    fetchAssessmentInstance,
    {
      isLoading: isFetchInstanceLoading,
      error: fetchInstanceError,
      isSuccess: hasFetchedInstance,
    },
  ] = useLazyLearnerAssessmentInstanceQuery();
  const fetchAssessmentInstanceAction = useAction(fetchAssessmentInstance);

  const [
    fetchAssessmentAttempt,
    {
      isLoading: isFetchAttemptLoading,
      error: fetchAttemptError,
      isSuccess: hasFetchedAttempt,
    },
  ] = useLazyLearnerAssessmentInstanceAttemptQuery();
  const fetchAssessmentAttemptAction = useAction(fetchAssessmentAttempt);

  const loadInitialData = useCallback(async () => {
    if (!authStore.learnerId) return;

    const getInstanceResult = await fetchAssessmentInstanceAction({
      assessmentInstanceId,
      learnerId: authStore.learnerId,
    });

    const getAttemptResult = await fetchAssessmentAttemptAction(
      // @ts-expect-error
      {
        assessmentInstanceId,
        learnerId: authStore.learnerId,
      },
      false
    );

    if (
      !getInstanceResult?.error &&
      !getAttemptResult?.error &&
      getInstanceResult?.data &&
      getAttemptResult?.data
    ) {
      dispatch(
        actions.fetchedInitialData({
          instance: getInstanceResult.data,
          attempt: getAttemptResult.data,
        })
      );
    }
  }, [
    dispatch,
    assessmentInstanceId,
    fetchAssessmentInstance,
    fetchAssessmentAttempt,
  ]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return {
    isLoading: isFetchAttemptLoading || isFetchInstanceLoading,
    error: fetchAttemptError || fetchInstanceError,
    hasFetchedData: hasFetchedInstance || hasFetchedAttempt,
  };
};

export const LearnerAssessmentPlayerHome = () => {
  const { id: assessmentInstanceId } = useParams();
  const { t } = useTranslation('learner');
  const attemptsCount = useSelector(selectors.getAttemptsCount);
  const maxAttempts = useSelector(selectors.getMaxAttempts);
  const assessmentName = useSelector(selectors.getName);
  const assessmentMinScore = useSelector(selectors.getMinScore);
  const assessmentMaxScore = useSelector(selectors.getMaxScore);
  const assessmentDescription = useSelector(selectors.getDescription);
  const assessmentTimeLimit = useSelector(selectors.getAttemptTimeLimit);
  const hasReachedMaxAttempts = useSelector(selectors.hasReachedMaxAttempts);
  const goToLearnerHome = useRoutingAction({
    route: routes.portal.path.full(),
  });
  const remainingAttemptsCount = useSelector(
    selectors.getRemainingAttemptsCount
  );
  const { isLoading, error, hasFetchedData } =
    useLoadInitialData(assessmentInstanceId);
  const { isLoading: isStartAttemptLoading, onStartAttempt } =
    useStartAttempt(assessmentInstanceId);
  if (error) {
    return (
      <div className="learner-assessment-player-greeting-message">
        <div className="learner-assessment-player-greeting-message__loading-spinner-wrapper">
          <Typography.h1>{t('learningunit.assessmenterror')}</Typography.h1>
          <Typography.h2 className="learner-assessment-player-greeting-message__subtitle">
            {t('learningunit.assessmenterrordescription')}
          </Typography.h2>
          <div className="learner-assessment-player-greeting-message__actions">
            <Button
              label={t('common.back')}
              regular
              className="learner-assessment-player-greeting-message__action"
              // @ts-ignore
              onClick={goToLearnerHome}
            />
          </div>
        </div>
      </div>
    );
  }

  if (isLoading || !hasFetchedData) {
    return (
      <div className="learner-assessment-player-greeting-message">
        <div className="learner-assessment-player-greeting-message__loading-spinner-wrapper">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="learner-assessment-player-greeting-message">
      {!hasReachedMaxAttempts ? (
        <>
          <Typography.h3>{`${t('learningunit.assessmentattempt')} ${
            attemptsCount + 1
          }/${maxAttempts}`}</Typography.h3>
          <Separator margin={20} />
        </>
      ) : null}
      <Typography.h1>{assessmentName}</Typography.h1>
      <div
        className="learner-assessment-player-greeting-message__subtitle"
        dangerouslySetInnerHTML={{ __html: assessmentDescription }}
      ></div>
      <Typography.p className="learner-assessment-player-greeting-message__description">
        {remainingAttemptsCount === 0 ? (
          t('learningunit.assessmentnoattempts')
        ) : (
          <Trans
            t={t}
            i18nKey="learningunit.assessmentattemptdescription" // optional -> fallbacks to defaults if not provided
            values={{
              remainingAttemptsCount,
              assessmentMinScore,
              assessmentMaxScore,
              assessmentTimeLimit,
            }}
            components={{ br: <br />, italic: <i />, b: <b /> }}
          />
        )}
      </Typography.p>
      <div className="learner-assessment-player-greeting-message__actions">
        <Button
          label={t('common.back')}
          regular
          className="learner-assessment-player-greeting-message__action"
          // @ts-ignore
          onClick={goToLearnerHome}
        />
        {hasReachedMaxAttempts ? (
          <Tooltip.topLeft
            title={t('learningunit.assessmentmaxattemptsreached')}
          >
            <Button
              label={t('learningunit.assessmentstart')}
              className="learner-assessment-player-greeting-message__action"
              onClick={onStartAttempt}
              disabled
            />
          </Tooltip.topLeft>
        ) : (
          <Button
            label={t('learningunit.assessmentstart')}
            className="learner-assessment-player-greeting-message__action"
            onClick={onStartAttempt}
            isLoading={isStartAttemptLoading}
          />
        )}
      </div>
    </div>
  );
};
