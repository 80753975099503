import React from 'react';

export const CircleCheckIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0001 10.0862V11.0062C20.9975 15.4349 18.0824 19.3344 13.8354 20.5901C9.58847 21.8458 5.02145 20.1585 2.61101 16.4433C0.200573 12.7281 0.52092 7.86987 3.39833 4.50331C6.27574 1.13674 11.0248 0.0637601 15.0701 1.86623"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M21 3.00586L11 13.0159L8 10.0159"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
