import { Ability } from '@casl/ability';

import { authStore } from './_common/modules/Auth/auth';

const userPermissions = authStore?.user?.scope;

// !!!!!!TO DO ADD CHECKLIST PERMISSIONS
// checklist.list,
// checklist.create,
// checklist.edit,
// checklist.assign,
// checklist.delete,
// checklist_item.list,
// checklist_item.create,
// checklist_item.export,
// checklist_item.edit,
// checklist_item.delete,
// checklist_page.view,
// checklist_instance.list,
// "checklist_instance.delete",
// "checklist_item_instance.list"

// const adminPermissions = [
//   'current_user',
//   'report',
//   'dashboard',
//   'issue.report',
//   'file.upload',
//   'search.lms',
//   'learners.manage',
//   'resource.manage',
//   'session.list',
//   'session.manage',
//   'session.learner.add',
//   'session.learner.list',
//   'session.learner.remove',
//   'session.learner.invite',
//   'session.learner.remind',
//   'session.learner.notify',
//   'session.learner.followup',
//   'session.learner.status',
//   'session.learner.import',
//   'dashboard_page.view',
//   'learning_activity_page.view',
//   'live_session_page.view',
//   'live_session.list',
//   'live_session.list_individual',
//   'live_session.view',
//   'live_session.create',
//   'live_session.edit',
//   'live_session.update',
//   'live_session_learner.view',
//   'live_session_learner.import',
//   'live_session_learner.add',
//   'live_session_learner.send_email',
//   'live_session_learner.update_status',
//   'live_session_learner.delete',
//   'live_session_learner.export',
//   'live_session_learner.confirm',
//   'live_session_learner.decline',
//   'live_session_learner.download_attendance',
//   'live_session_learner.move',
//   'live_session_trainer.view',
//   'live_session_trainer.add',
//   'live_session_trainer.update_status',
//   'live_session_trainer.change',
//   'live_session_trainer.delete',
//   'live_session_trainer.send_email',
//   'live_session_info.view',
//   'live_session_venue.view',
//   'live_session_venue.add',
//   'live_session_venue.update_status',
//   'live_session_venue.change',
//   'live_session_venue.book',
//   'live_session_venue.delete',
//   'live_session_cost.view',
//   'live_session_cost.add',
//   'live_session_cost.delete',
//   'live_session_automation.view',
//   'live_session_automation.update',
//   'live_session_activity.view',
//   'live_session_survey.view',
//   'live_session_survey.export',
//   'live_session_survey.add',
//   'live_session_survey.copy',
//   'elearning_session.list',
//   'elearning_session.delete',
//   'elearning_session.update',
//   'assessment_instance_page.view',
//   'assessment_instance.list',
//   'assessment_instance.edit',
//   'assessment_instance.remove_attempt',
//   'assessment_instance.view_evaluation',
//   'assignment_page.view',
//   'assignment.list',
//   'assignment.create',
//   'assignment.delete',
//   'assignment.edit',
//   'assignment_instance.delete',
//   'analytics_page.view',
//   'analytics_charts.view',
//   'analytics_report.list',
//   'analytics_report.export',
//   'analytics_report.delete',
//   'analytics_report.download',
//   'analytics_report.edit',
//   'analytics_report_session_attendance.export',
//   'analytics_report_elearning_history.export',
//   'analytics_report_mandatory_elearning_history.export',
//   'analytics_report_elearning_and_live_session_activity.export',
//   'analytics_report_learning_history.export',
//   'analytics_report_best_result_learning_history.export',
//   'analytics_report_survey_responses.export',
//   'analytics_report_survey_responses_grouped.export',
//   'analytics_report_assessment_responses.export',
//   'analytics_report_assessment_instances.export',
//   'analytics_report_learning_paths.export',
//   'analytics_report_learning_path_items.export',
//   'analytics_report_assignments.export',
//   'analytics_report_costs.export',
//   'analytics_report_learners.export',
//   'learning_catalogue_page.view',
//   'live_course_page.view',
//   'live_course.list',
//   'live_course.create',
//   'live_course.edit',
//   'live_course.delete',
//   'elearning_course_page.view',
//   'elearning_course.list',
//   'elearning_course.edit',
//   'elearning_course.assign',
//   'elearning_course.create',
//   'elearning_course.delete',
//   'elearning_course.validate',
//   'assessment_form.list',
//   'assessment_form.assign',
//   'assessment_form.create',
//   'assessment_form.edit',
//   'learning_path_page.view',
//   'learning_path.list',
//   'learning_path.assign',
//   'learning_path.create',
//   'learning_path.edit',
//   'learning_path.delete',
//   'survey_page.view',
//   'survey.create',
//   'survey.list',
//   'survey.edit',
//   'learners_page.view',
//   'learner_page.view',
//   'learner.approve',
//   'learner.list',
//   'learner.create',
//   'learner.edit',
//   'learner.delete',
//   'learner.import',
//   'learner_group.list',
//   'learner_group.create',
//   'learner_group.edit',
//   'learner_group.delete',
//   'learner_group.export',
//   'learner_group.import',
//   'learner_group.assign',
//   'resource_page.view',
//   'trainer.create',
//   'trainer.edit',
//   'trainer.delete',
//   'trainer.list',
//   'venue.create',
//   'venue.edit',
//   'venue.delete',
//   'venue.list',
//   'vendor.create',
//   'vendor.edit',
//   'vendor.delete',
//   'vendor.list',
//   'cost.create',
//   'cost.edit',
//   'cost.delete',
//   'cost.list',
//   'tag.create',
//   'tag.edit',
//   'tag.delete',
//   'tag.list',
//   'competency.create',
//   'competency.edit',
//   'competency.delete',
//   'competency.list',
//   'content_file.add',
//   'content_file.delete',
//   'content_file.list',
//   'assessment_question.import',
//   'assessment_question.create',
//   'assessment_question.list',
//   'assessment_question.edit',
//   'assessment_question.delete',
//   'assessment_question.export',
//   'profile_page.view',
//   'email_page.view',
//   'email.list',
//   'email.send',
//   'user.list',
//   'current_user.edit',
//   'current_user.view',
//   'current_user.refresh',
//   'assignment.update_result',
//   'assignment.convert',
//   'assignment_instance.add',
//   'assignment_instance.list',
//   'live_session.delete',
//   'email_template.edit',
//   'email_template.delete',
//   'email_template.create',
//   'activity_log.list',
//   'learner.sync',
//   'learner.impersonate',
// ];

// const managerPermissions = [
//   'current_user',
//   'report',
//   'dashboard',
//   'learners.manage',
//   'resource.manage',
//   'session.list',
//   'session.manage',
//   'session.learner.list',
//   'session.learner.invite',
//   'session.learner.remind',
//   'session.learner.notify',
//   'session.learner.followup',
//   'session.learner.status',
//   'issue.report',
//   'file.upload',
//   'search.lms',
//   'tag.list',
//   'competency.list',
//   'dashboard_page.view',
//   'learning_activity_page.view',
//   'live_session_page.view',
//   'live_session_learner.view',
//   'live_session_learner.confirm',
//   'live_session_learner.decline',
//   'live_session_trainer.view',
//   'live_session_info.view',
//   'live_session_venue.view',
//   'analytics_page.view',
//   'analytics_report.list',
//   'analytics_report.export',
//   'analytics_report.download',
//   'analytics_report.edit',
//   'analytics_report_learning_history.export',
//   'analytics_report_mandatory_elearning_history.export',
//   'analytics_report_best_result_learning_history.export',
//   'learners_page.view',
//   'learner_page.view',
//   'learner_team.list',
//   'profile_page.view',
//   'live_session.list_individual',
//   'elearning_session.list',
//   'learner.list',
//   'learner.approve',
//   'user.list',
//   'current_user.edit',
//   'current_user.view',
//   'current_user.refresh',
//   'assignment_instance.list',
//   'learning_path.list',
//   'elearning_course.list',
//   'assessment_form.list',
//   'learner.impersonate',
// ];

// const evaluatorPermissions = [
//   'current_user',
//   'report',
//   'issue.report',
//   'file.upload',
//   'search.lms',
//   'dashboard',
//   'learners.manage',
//   'resource.manage',
//   'session.list',
//   'session.manage',
//   'session.learner.list',
//   'dashboard_page.view',
//   'learning_activity_page.view',
//   'assessment_instance_page.view',
//   'assessment_instance.list',
//   'assessment_instance.edit',
//   'assessment_instance.remove_attempt',
//   'assessment_instance.view_evaluation',
//   'assignment_page.view',
//   'assignment.list',
//   'assignment.create',
//   'assignment.delete',
//   'assignment.edit',
//   'assignment_instance.delete',
//   'analytics_page.view',
//   'analytics_report.list',
//   'analytics_report.export',
//   'analytics_report.download',
//   'analytics_report.edit',
//   'analytics_report_assessment_responses.export',
//   'analytics_report_assessment_instances.export',
//   'learning_catalogue_page.view',
//   'assessment_form.list',
//   'assessment_form.assign',
//   'assessment_form.create',
//   'assessment_form.edit',
//   'resource_page.view',
//   'assessment_question.import',
//   'assessment_question.create',
//   'assessment_question.list',
//   'assessment_question.edit',
//   'assessment_question.delete',
//   'assessment_question.export',
//   'profile_page.view',
//   'current_user.edit',
//   'current_user.view',
//   'current_user.refresh',
//   'assignment.update_result',
//   'assignment.convert',
//   'assignment_instance.add',
//   'assignment_instance.list',
//   'learner.impersonate',
//   'learner.list',
//   'learner_group.list',
//   'learner_group.assign',
//   'tag.list',
//   'competency.list',
// ];

// const auditorPermissions = [
//   'current_user',
//   'report',
//   'analytics_page.view',
//   'analytics_report.list',
//   'analytics_report.export',
//   'analytics_report.download',
//   'analytics_report.edit',
//   'analytics_report_auditor.export',
// ];

// const learnerPermissions = [
//   'learner.app',
//   'tag.list',
//   'issue.report',
//   'analytics_report_all_learning_history_of_a_learner.export',
//   'competency.list',
// ];
// const connectPermissions = ['connect'];

export default new Ability(
  userPermissions?.map((permission) => {
    let action = permission.split('.')[1];
    let subject = permission.split('.')[0];

    return {
      action,
      subject,
    };
  })
);
