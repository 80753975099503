import React from 'react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { Tracker, TrackingProvider } from '@nl-lms/ui/modules';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';

import { App } from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tracker = new Tracker({
  key: 'phc_15hqqkHtC7CMjfDUwg2rVUIsW0WfR6rXSfMOkD0g7Jc',
  name: 'web-tracker',
  getUser: () => authStore.user,
});

let isNavigating = false;
let lastLocation = { ...window.location };

window.addEventListener('popstate', function () {
  if (!isNavigating && lastLocation?.pathname !== location?.pathname) {
    tracker.pageLeave({
      $current_url: lastLocation.href,
      $host: lastLocation.host,
      $pathname: lastLocation.pathname,
    });
    tracker.pageView();
  }

  lastLocation = { ...window.location };
});

function wrap(fn) {
  return function (state, _, url) {
    // url is of form: /some/path?query=string
    const urlPathname = url.split('?')[0];
    const locationPathname = location.pathname;

    // only track if the page changes and not the query string
    const isTracked = !isNavigating && url && urlPathname !== locationPathname;

    if (isTracked) {
      isNavigating = true;
      tracker.pageLeave();
    }

    fn.apply(this, arguments);

    if (isTracked) {
      setTimeout(function () {
        tracker.pageView();

        lastLocation = { ...window.location };
        isNavigating = false;
      }, 50);
    }
  };
}

history.pushState = wrap(history.pushState);
history.replaceState = wrap(history.replaceState);

root.render(
  <StrictMode>
    <TrackingProvider tracker={tracker}>
      <App />
    </TrackingProvider>
  </StrictMode>
);
