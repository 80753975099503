import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FieldType,
  FieldTypeToOperatorsRecord,
  OperatorToLabelRecord,
  QueryOperator,
  StringFieldMetadata,
} from '@nl-lms/common/shared';

import {
  FilterBarOptionsComponentProps,
  StringValueOption,
} from '../FilterBar.types';
import { getOptionClassName, getOptionId } from '../filterUtils';

export const FilterBarStringFieldSuggestOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<StringFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const option: StringValueOption = {
    id: getOptionId(
      `${QueryOperator.Contains}-${inputValue}`,
      field.name,
      'StringValueOption'
    ),
    type: 'StringValueOption',
    field,
    operator: QueryOperator.Contains,
    value: inputValue,
    label: `${t(
      // @ts-ignore
      `common.filterbar.${
        OperatorToLabelRecord[QueryOperator.Contains]
      }` as const
    )} "${inputValue}"`,
  };

  return (
    <>
      <span className="filter-bar__option-separator">{field.label}</span>
      <li
        className={getOptionClassName(option.id === activeOptionId, false)}
        {...getOptionProps(option, option.id)}
      >
        {option.label}
      </li>
    </>
  );
};

export const FilterBarStringFieldOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<StringFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const operatorOptions = useMemo<StringValueOption[]>(() => {
    return FieldTypeToOperatorsRecord[FieldType.string].map((operator) => {
      return {
        id: getOptionId(
          `${operator}-${inputValue}`,
          field.name,
          'StringValueOption'
        ),
        field,
        type: 'StringValueOption',
        value: inputValue,
        label: `${t(
          // @ts-ignore
          `common.filterbar.${OperatorToLabelRecord[operator]}`
        )} "${inputValue}"`,
        operator: operator,
      };
    });
  }, [inputValue, field]);

  return (
    <>
      <span className="filter-bar__option-separator">
        {t('common.filterbar.values')}
      </span>

      {operatorOptions.map((option) => (
        <li
          key={option.id}
          className={getOptionClassName(option.id === activeOptionId, false)}
          {...getOptionProps(option, option.id)}
        >
          {option.label}
        </li>
      ))}
    </>
  );
};
