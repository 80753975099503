import React, { useEffect } from 'react';

import { Separator } from '@nl-lms/ui/components';
import { NotificationsContainer, useTracker } from '@nl-lms/ui/modules';

import './SurveyLayout.scss';

export const SurveyLayout = (props: {
  children: React.ReactNode;
  className?: string;
  bgImage?: string;
}) => {
  const { children, className = '', bgImage = '' } = props;
  const tracker = useTracker();

  useEffect(() => {
    tracker.stopRecording();
  }, []);

  return (
    <div
      className={`survey-layout ${className}`}
      style={bgImage ? { backgroundImage: `url(${bgImage})` } : {}}
    >
      <div className="survey-layout__wrapper">{children}</div>

      <div className="notifications-container">
        <NotificationsContainer />
      </div>
    </div>
  );
};

const SurveyLayoutHeader = (props: {
  light?: boolean;
  children: React.ReactNode;
  className?: string;
  withSeparator?: boolean;
}) => {
  const {
    light = false,
    children,
    className = '',
    withSeparator = false,
  } = props;
  return (
    <div
      className={`survey-layout__header ${
        light ? 'survey-layout__header--light' : ''
      } ${className}`}
    >
      {children}
      {withSeparator ? (
        // @ts-ignore
        <Separator className="survey-layout__separator" marginBottom={0} />
      ) : null}
    </div>
  );
};

const SurveyLayoutLogo = ({ children }: { children: React.ReactNode }) => (
  <>
    <div className="survey-layout__header-logo">{children}</div>
    <Separator className="survey-layout__separator" />
  </>
);

const SurveyLayoutHeaderDetails = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="survey-layout__header-details">{children}</div>;

const AssessmentLayoutContent = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="survey-layout__content">{children}</div>;

SurveyLayout.Header = SurveyLayoutHeader;
SurveyLayout.HeaderLogo = SurveyLayoutLogo;
SurveyLayout.HeaderDetails = SurveyLayoutHeaderDetails;
SurveyLayout.Content = AssessmentLayoutContent;

export default SurveyLayout;
