export const CertificationRouterPrefix = '/certification';
export const CertificationInstanceRouterPrefix = '/certification-instance';

export const CertificationScope = {
  view: { resource: 'certification', action: 'view' },
  update: { resource: 'certification', action: 'update' },
  delete: { resource: 'certification', action: 'delete' },
  create: { resource: 'certification', action: 'create' },
  link: { resource: 'certification', action: 'link' },
} as const;

export const CertificationInstanceScope = {
  download: { resource: 'certification-instance', action: 'download' },
  view: { resource: 'certification-instance', action: 'view' },
} as const;
