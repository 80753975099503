import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryFilterCombinator } from '@nl-lms/common/shared';
import { FloatingMenu, Icon, Tooltip } from '@nl-lms/ui/components';

import { FilterBarContext } from '../FilterBarContext/FilterBarContext';

export const FilterBarSettingsButton = () => {
  const { defaultCombinator, onChangeDefaultCombinator } =
    useContext(FilterBarContext);
  const { settingsMenuOptions } = useContext(FilterBarContext);
  const { t } = useTranslation('learner');
  return (
    <FloatingMenu
      className="filter-bar__input-icon"
      usePortal={true}
      items={[
        ...settingsMenuOptions,
        [
          {
            name: (
              <FloatingMenu.CheckedOption
                label={t('common.filterbar.and')}
                isChecked={defaultCombinator === QueryFilterCombinator.And}
              />
            ),
            sectionLabel: 'Default Combinator',
            handler: () => onChangeDefaultCombinator(QueryFilterCombinator.And),
          },
          {
            name: (
              <FloatingMenu.CheckedOption
                label={t('common.filterbar.or')}
                isChecked={defaultCombinator === QueryFilterCombinator.Or}
              />
            ),
            handler: () => onChangeDefaultCombinator(QueryFilterCombinator.Or),
          },
        ],
      ]}
    >
      <Tooltip title="Settings">
        <Icon.SlidersIcon />
      </Tooltip>
    </FloatingMenu>
  );
};
