import Axios from 'axios';
import React, { useCallback, useState } from 'react';

import { sanitizeFileName } from '@nl-lms/common/shared';
import { FileInput } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import { useApi } from '../../services/api';
import './ImageUploadInput.scss';

interface S3ImageUploadInputProps {
  initialS3Url?: string;
  disabled?: boolean;
  s3Url?: string;
  fileType?: string;
  withPreview?: boolean;
  onChange: (s3Url: string) => void;
  value?: string;
}

const FILE_SIZE_LIMIT_IN_MB = 5;

const axios = Axios.create();
delete axios.defaults.headers.put['Content-Type'];

export const ImageUploadInput = (props: S3ImageUploadInputProps) => {
  const {
    initialS3Url = '',
    disabled = false,
    s3Url: _s3Url = '',
    withPreview = true,
    fileType = 'thumbnail',
    onChange,
    value,
  } = props;
  const [s3Url, setS3Url] = useState(() => {
    return initialS3Url || value;
  });
  const [localFileName, setLocalFileName] = useState(value || null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const uploadFileWithSignedUrl = useUploadFileWithSignedUrl();
  const uploadFileThroughApi = useUploadFileThroughApi();
  const onChangeFile = useCallback(
    async (e) => {
      const fileName = sanitizeFileName(e.target.files[0].name);
      const file = new File([e.target.files[0]], fileName);

      const fileSizeInMb = file.size / (1024 * 1024);
      if (fileSizeInMb > FILE_SIZE_LIMIT_IN_MB) {
        setErrorMessage(
          `Invalid file size. Maximum image size is ${FILE_SIZE_LIMIT_IN_MB} MB`
        );
        return false;
      }
      setIsLoading(true);
      setErrorMessage('');
      try {
        const fileUrl =
          C.UPLOAD_STRATEGY === 'direct'
            ? await uploadFileWithSignedUrl(file, fileType)
            : await uploadFileThroughApi(file, fileType);
        setLocalFileName(file.name);
        setErrorMessage('');
        setS3Url(fileUrl);
        onChange(fileUrl);
      } catch (e) {
        setErrorMessage('Unable to upload file');
      } finally {
        setIsLoading(false);
      }
    },
    [
      setLocalFileName,
      setErrorMessage,
      setS3Url,
      onChange,
      setErrorMessage,
      setIsLoading,
    ]
  );
  return (
    <div className="s3-image-upload-input">
      <FileInput
        isLoading={isLoading}
        onChange={onChangeFile}
        disabled={disabled}
        // @ts-ignore
        value={localFileName}
        accept=".svg,.png,.jpg"
        placeholder="Select Image to Upload"
      />
      {errorMessage && (
        <div className="s3-image-upload-input__error-message">
          {errorMessage}
        </div>
      )}
      {withPreview && (
        <div className="s3-image-upload-input__image-wrapper">
          <div
            className="s3-image-upload-input__image"
            style={{
              backgroundImage:
                s3Url || _s3Url ? `url("${s3Url || _s3Url}")` : 'none',
            }}
          >
            {s3Url ? '' : 'No Image'}
          </div>
        </div>
      )}
    </div>
  );
};

const useUploadFileWithSignedUrl = () => {
  const api = useApi();

  return useCallback(async (file, type) => {
    const { putSignedUrl, fileUrl } = await api.common.getSignedPutUrl(
      file.name,
      type
    );
    await axios.put(putSignedUrl, file, {
      headers: { 'Content-Type': file.type },
    });
    return fileUrl;
  }, []);
};

const useUploadFileThroughApi = () => {
  const api = useApi();
  return useCallback(async (file, type) => {
    const { fileUrl } = await api.common.uploadFile('thumbnail', file);
    return fileUrl;
  }, []);
};
