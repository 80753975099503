import {
  LearningAssignmentAutomation,
  LearningAssignmentDomainEventNames,
} from '@nl-lms/feature/learning-assignments/sdk';
import {
  LearningProgramAutomation,
  LearningProgramDomainEventNames,
} from '@nl-lms/feature/learning-programs/sdk';

import { DefaultReferenceEntities } from '../learning-assignment-rule/utils/constants';

export const LearningProgramGraphEdgeColorMap = {
  [LearningAssignmentDomainEventNames.LearnerJoinedAudience]: '#1578B2',
  [LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted]:
    '#20A775',
  [LearningAssignmentDomainEventNames.LearningAssignmentInstanceCreated]:
    '#F5A236',
  [LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed]:
    '#D7263D',
  [LearningAssignmentDomainEventNames.LearningAssignmentInstanceStarted]:
    '#B8DC57',
  Default: '#7171DD',
};

export enum ProgramRulesTemplateTypes {
  recurrence = 'recurrence',
  recurrenceFailed = 'recurrence-failed',
  assignStarted = 'assign-started',
  assignCompleted = 'assign-completed',
  assignFailed = 'assign-failed',
  assignCreated = 'assign-created',

  programCompleted = 'program-completed',
  programFailed = 'program-failed',
  programCreated = 'program-created',
}

export const ProgramRuleTemplates = [
  {
    labelWithReference: (refName) => `${refName} is completed`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
    value: ProgramRulesTemplateTypes.recurrence,
    payload: {
      name: 'Recurrence',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
          referenceEntity: {
            id: DefaultReferenceEntities.assignmentId.value,
            label: DefaultReferenceEntities.assignmentId.label,
          },
          referenceEntityId: DefaultReferenceEntities.assignmentId.value,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} is failed`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
    value: ProgramRulesTemplateTypes.recurrenceFailed,
    payload: {
      name: 'Reassign On Failure',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
          referenceEntity: {
            id: DefaultReferenceEntities.assignmentId.value,
            label: DefaultReferenceEntities.assignmentId.label,
          },
          referenceEntityId: DefaultReferenceEntities.assignmentId.value,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} is started`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceStarted,
    value: ProgramRulesTemplateTypes.assignStarted,
    payload: {
      name: 'Assignment Started',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceStarted,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} is completed`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
    value: ProgramRulesTemplateTypes.assignCompleted,
    payload: {
      name: 'Assignment Completed',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} is created`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceCreated,
    value: ProgramRulesTemplateTypes.assignCreated,
    payload: {
      name: 'Assignment Created',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceCreated,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} is failed`,
    domainEventValue:
      LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
    value: ProgramRulesTemplateTypes.assignFailed,
    payload: {
      name: 'Assignment Failed',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName) => `${refName} learner joins program`,
    domainEventValue:
      LearningProgramDomainEventNames.LearningProgramInstanceCreated,
    value: ProgramRulesTemplateTypes.programCreated,
    payload: {
      name: 'Auto-Assign',
      entityName: LearningAssignmentAutomation.entityName,
      actionName: LearningAssignmentAutomation.actions.createInstance,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      conditions: {
        value: {
          eventName:
            LearningProgramDomainEventNames.LearningProgramInstanceCreated,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName = '') =>
      refName ? `${refName} is completed` : 'Is Completed',
    domainEventValue: LearningProgramAutomation.actions.markInstanceAsCompleted,
    value: ProgramRulesTemplateTypes.programCompleted,
    payload: {
      name: 'Assignment Completed',
      entityName: LearningProgramAutomation.entityName,
      actionName: LearningProgramAutomation.actions.markInstanceAsCompleted,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
  {
    labelWithReference: (refName = '') =>
      refName ? `${refName} is failed` : 'Is Failed',
    domainEventValue: LearningProgramAutomation.actions.markInstanceAsFailed,
    value: ProgramRulesTemplateTypes.programFailed,
    payload: {
      name: 'Assignment Failed',
      entityName: LearningProgramAutomation.entityName,
      actionName: LearningProgramAutomation.actions.markInstanceAsFailed,
      scheduleDate: {
        type: 'relative',
        options: {},
        value: null,
      },
      settings: { triggerNTimes: '1' },
      conditions: {
        value: {
          eventName:
            LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
          referenceEntity: {},
          referenceEntityId: null,
          match: null,
        },
        combinator: null,
      },
    },
  },
];
