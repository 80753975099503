import { EntityLoadCalendarDayEvent } from 'libs/ui/src/lib/components/EntityLoadCalendar/EntityLoadCalendar.types';
import React, { useCallback, useMemo, useState } from 'react';

import { AppQuery } from '@nl-lms/common/shared';
import {
  Card,
  EntityLoadCalendarBody,
  EntityLoadCalendarDateNavigation,
  EntityLoadCalendarHeader,
  EntityLoadCalendarHourNavigation,
  EntityLoadCalendarName,
  EntityLoadCalendarProvider,
  PrettyDate,
  StatusTag,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useListViewContext } from '@nl-lms/ui/modules';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi } from '../../../../_common/services/api';
import { routes } from '../../../../lib/routes';
import './AdminAnalyticsCoursesLoadCalendarCard.scss';

const StatusToEventTypeRecord: Record<
  keyof typeof C.SESSION_STATUSES,
  EntityLoadCalendarDayEvent['type']
> = {
  [C.I_SESSION_STATUSES.DRAFT]: 'grey',
  [C.I_SESSION_STATUSES.CANCELED]: 'red',
  [C.I_SESSION_STATUSES.COMPLETED]: 'green',
  [C.I_SESSION_STATUSES.READY]: 'blue',
  [C.I_SESSION_STATUSES.PROCESSING]: 'yellow',
};

const setStartDateWithOffset = (reference) => {
  const start = new Date(reference.valueOf());
  start.setDate(reference.getDate() - 14);
  return start;
};

const setEndDateWithOffset = (reference) => {
  const start = new Date(reference.valueOf());
  start.setDate(reference.getDate() + 14);
  return start;
};

export const AdminAnalyticsCoursesLoadCalendarCard = () => {
  const { useListLiveCoursesWithSessionsQuery } = adminApi;

  const activeDate = useMemo(() => new Date(), []);
  const [startDate, setStartDate] = useState(() =>
    setStartDateWithOffset(activeDate)
  );
  const [endDate, setEndDate] = useState(() =>
    setEndDateWithOffset(activeDate)
  );

  const onChangeDates = useCallback((start, end) => {
    setStartDate(setStartDateWithOffset(start));
    setEndDate(setEndDateWithOffset(end));
  }, []);

  const { query } = useListViewContext();
  // @ts-ignore
  const calendarQuery: AppQuery = useMemo(() => {
    return {
      pagination: { disabled: true },
      sorting: {
        firstName: 'asc',
      },
      filters: query?.filters,
    };
  }, [query]);

  const {
    isLoading,
    data: coursesWithSessions,
    error,
  } = useListLiveCoursesWithSessionsQuery(calendarQuery);
  useQueryErrorHandler({ error });

  const parseTrainersWithSessions = useMemo(
    () =>
      coursesWithSessions?.rows?.map((tws) => ({
        ...tws,
        title: tws?.name,
        entityUrl: routes.admin.catalogue.liveCourses.item.path.full(
          // @ts-ignore
          tws?.id
        ),
        events: tws?.trainingSessions?.map((twse) => ({
          ...twse,
          type: StatusToEventTypeRecord[twse?.status],
          TooltipComponent: TrainerCalendarEventTooltip,
        })),
      })),
    [coursesWithSessions]
  );

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card className="admin-analytics-courses-load-calendar__card">
      {parseTrainersWithSessions && parseTrainersWithSessions?.length ? (
        <EntityLoadCalendarProvider>
          <EntityLoadCalendarHeader>
            <EntityLoadCalendarName>Sessions by course</EntityLoadCalendarName>
            <EntityLoadCalendarDateNavigation
              onChange={onChangeDates}
              showUpcomingEvents={false}
            />
            <EntityLoadCalendarHourNavigation />
          </EntityLoadCalendarHeader>
          <EntityLoadCalendarBody
            entityTitle="Selected Courses"
            // @ts-ignore
            groups={parseTrainersWithSessions}
            tooltipHeight={120}
          />
        </EntityLoadCalendarProvider>
      ) : (
        <Typography.h2>No data available</Typography.h2>
      )}
    </Card>
  );
};

const TrainerCalendarEventTooltip = ({ eventObject: session }) => {
  const goToLiveSessionViewPage = useRoutingAction({
    route: (id) => routes.admin.manage.liveSessions.item.path.full(id),
  });
  return (
    <>
      <div
        className="admin-analytics-courses-load-calendar__link"
        onMouseDown={() => goToLiveSessionViewPage(session.id)}
      >
        <Typography.h4>{session.name}</Typography.h4>
      </div>
      <div className="admin-analytics-courses-load-calendar__status">
        <StatusTag status={C.SESSION_STATUSES[session.status]} />
      </div>
      <Typography.p className="admin-analytics-courses-load-calendar__date">
        <PrettyDate value={session.startDate} withTime />
        -
        <PrettyDate value={session.endDate} withTime />
      </Typography.p>
      <Typography.p className="admin-analytics-courses-load-calendar__pax">
        {session.registeredPax}/{session.maxPax} Learners
      </Typography.p>
    </>
  );
};
