import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AssessmentInstance,
  Competency,
  ElearningSession,
} from '@nl-lms/sdk/backend';
import { Icon, StatusTag, Typography } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { parseDistance, prettyDistance } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import {
  CardIconCaption,
  LearnerLearningCard,
  LearningBanner,
} from '../../../../_common/components';
import './LearnerLearningActivityCard.scss';

type Props = {
  mandatory?: boolean;
  dueDate?: Date;
  name: string;
  description: string;
  type: number;
  duration: number | string;
  thumbnail: string;
  actions: ReactElement[];
  status?: string | number;
  startedAt?: string;
  competencies?: Competency[];
  assessmentFormInstance?: AssessmentInstance;
  learningPathInstance?: any;
  elearningSession?: ElearningSession;
  learningProgramId?: string;
  learningProgramName?: string;
  learningAssignment?: {
    id: string;
  };
};

export const LearnerLearningActivityCard = ({
  mandatory,
  dueDate,
  name,
  description,
  type,
  duration,
  thumbnail,
  actions,
  status,
  startedAt,
  assessmentFormInstance,
  competencies,
  elearningSession,
  learningProgramName,
}: Props) => {
  const { t } = useTranslation('learner');

  let startedAtDetail;
  if (startedAt && type !== C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE) {
    const distance = prettyDistance(startedAt);
    const parsedDistance = parseDistance(distance.content);

    startedAtDetail = _.toLower(
      `${t('common.learningactivitycard.started')}
       ${
         // @ts-ignore
         parsedDistance.est
           ? // @ts-ignore
             t(`common.learningactivitycard.${parsedDistance.est}`)
           : ''
       }
       ${t('common.learningactivitycard.ago', {
         // @ts-ignore
         distance: `${parsedDistance.value} ${t(
           // @ts-ignore
           `common.time.${parsedDistance.unit}`
         )}`,
       })}`
    );
  }

  let durationCaption = '';
  let typeCaption = t('learningunit.none');
  let TypeIcon = Icon.AlertCircleIconSmall;
  let thumbnailType = LearnerLearningCard.ThumbnailType.Elearning;
  let statusDetail;
  switch (type) {
    case C.I_LEARNER_LEARNING_TYPES.ASSESSMENT:
      typeCaption = t('common.learningunit.assessment');
      TypeIcon = Icon.BorderedCheckIconSmall;
      durationCaption = `${duration} ${
        duration === 1 ? t('common.time.minute') : t('common.time.minutes')
      }`;
      thumbnailType = LearnerLearningCard.ThumbnailType.Assessment;
      statusDetail = status ? C.ASSESSMENT_INSTANCE_STATUSES[status] : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.SURVEY:
      typeCaption = t('common.learningunit.survey');
      TypeIcon = Icon.BorderedCheckIconSmall;
      thumbnailType = LearnerLearningCard.ThumbnailType.Survey;
      statusDetail = status ? C.SURVEY_STATUSES[status] : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.ELEARNING:
      typeCaption = t('common.learningunit.elearning');
      if (
        !elearningSession?.ContentFile?.metadata?.manifestData?.scormVersion
      ) {
        typeCaption =
          C.CONTENT_FILE_VERSIONS[
            elearningSession?.ContentFile?.metadata?.version
          ];
      }

      TypeIcon = Icon.PlayerIconSmall;
      thumbnailType = LearnerLearningCard.ThumbnailType.Elearning;
      statusDetail = status ? C.ELEARNING_SESSION_STATUSES[status] : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH:
      typeCaption = t('common.learningunit.learningpath');
      TypeIcon = Icon.GitIconSmall;
      durationCaption = `${duration} ${
        duration === 1 ? t('common.time.hour') : t('common.time.hours')
      }`;
      thumbnailType = LearnerLearningCard.ThumbnailType.LearningPath;
      statusDetail = status ? C.LEARNING_PATH_INSTANCE_STATUSES[status] : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE:
      typeCaption = t('common.learningunit.course');
      TypeIcon = Icon.ClipboardIconSmall;
      durationCaption = `${duration} ${
        duration === 1 ? t('common.time.hour') : t('common.time.hours')
      }`;
      thumbnailType = LearnerLearningCard.ThumbnailType.InClass;
      statusDetail = status
        ? C.TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES[status]
        : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.CHECKLIST:
      typeCaption = t('common.learningunit.checklist');
      TypeIcon = Icon.BorderedCheckIconSmall;
      thumbnailType = LearnerLearningCard.ThumbnailType.Checklist;
      statusDetail = status ? C.CHECKLIST_INSTANCE_STATUSES[status] : null;
      break;
  }

  const leftAttemtpsCount =
    // @ts-ignore
    assessmentFormInstance?.maxAttemptsCount -
    // @ts-ignore
    assessmentFormInstance?.attemptsCount;

  return (
    <LearnerLearningCard className="learner-learning-activity-card">
      <LearningBanner
        dueDate={dueDate}
        mandatory={mandatory}
        learningProgramName={learningProgramName}
      />

      <LearnerLearningCard.Thumbnail
        imageUrl={thumbnail}
        type={thumbnailType}
      />

      <LearnerLearningCard.Title>{name}</LearnerLearningCard.Title>

      <CardIconCaption icon={<TypeIcon />} caption={typeCaption} />
      {duration && durationCaption ? (
        <CardIconCaption
          icon={<Icon.ClockIconSmall />}
          caption={durationCaption}
        />
      ) : null}
      {assessmentFormInstance && (
        <CardIconCaption
          icon={<Icon.GridIconSmall />}
          caption={`
            ${leftAttemtpsCount}
            ${t(
              leftAttemtpsCount === 1
                ? 'common.learningactivitycard.try'
                : 'common.learningactivitycard.tries'
            )}
          `}
        />
      )}

      <LearnerLearningCard.Description description={description} />

      {status && statusDetail ? (
        <LearnerLearningCard.Detail>
          <StatusTag status={statusDetail} />
        </LearnerLearningCard.Detail>
      ) : null}

      {startedAt && startedAtDetail ? (
        <LearnerLearningCard.Detail>
          <Typography.p className="learner-learning-activity-card__started-at">
            {startedAtDetail}
          </Typography.p>
        </LearnerLearningCard.Detail>
      ) : null}

      {!!(competencies && competencies.length) && (
        <LearnerLearningCard.Tags>
          {competencies.map((tag) => tag.title)}
        </LearnerLearningCard.Tags>
      )}

      {actions.map((action, index) => (
        <LearnerLearningCard.Action key={index}>
          {action}
        </LearnerLearningCard.Action>
      ))}
    </LearnerLearningCard>
  );
};
