import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export type OnClickOutsideProps = {
  handler: () => void;
  ref?: React.RefObject<HTMLElement>;
  matchFn?: (e: MouseEvent) => boolean;
};

export const useOnClickOutside = ({
  ref: _ref = undefined,
  handler,
  matchFn = undefined,
}: OnClickOutsideProps) => {
  const localRef = useRef<HTMLElement>(null);

  const ref = _ref || localRef;
  const onClickOutside = useCallback((e: MouseEvent) => {
    const typedTarget = e.target as unknown as Node;
    if (ref.current && !ref.current.contains(typedTarget)) {
      if (matchFn && matchFn(e)) return;
      handler();
    }
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, []);

  return ref;
};
