import React, { useMemo } from 'react';

import {
  AppQueryFilter,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { Button, Card } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { ListViewProvider } from '@nl-lms/ui/modules';

import { routes } from '../../../../lib/routes';
import { AdminAssessmentFormInstanceActivityTable } from '../../AdminAssessment/AdminAssessmentFormInstanceListTable';
import './AdminAssessmentProgressListTable.scss';

export const AdminEvaluatorAssessmentProgressListTable = () => {
  const baseFilters = useMemo<AppQueryFilter>(
    () => ({
      id: 'assessment-instance-status',
      value: [
        {
          id: 'assessment-status',
          value: {
            field: { label: 'status', field: 'status' },
            operator: {
              label: 'Includes',
              value: QueryOperator.Includes,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: 'Scoring',
              value: [C.I_ASSESSMENT_INSTANCE_STATUSES.SCORING],
            },
          },
        },
      ],
    }),
    []
  );

  const goToAssessmentsPage = useRoutingAction({
    route: routes.admin.activity.assessments.path.full(),
  });

  return (
    <Card className="admin-assessment-progress-list-table">
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Assessment Scoring</Card.HeaderTitle>
        <Card.HeaderActions>
          <Button
            ghost
            label="view assessments"
            // @ts-ignore
            onClick={goToAssessmentsPage}
          />
        </Card.HeaderActions>
      </Card.Header>
      <Card.Content>
        <ListViewProvider
          key="list-assessment-instances"
          name="list-assessment-instances"
          paginationLimit={10}
          initialSorting={{ field: 'createdAt', order: 'desc' }}
          baseFilters={baseFilters}
        >
          <AdminAssessmentFormInstanceActivityTable />
        </ListViewProvider>
      </Card.Content>
    </Card>
  );
};
