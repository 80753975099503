import React from 'react';

export const NiftyMonogram = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.758347 0.71582C0.341055 0.71582 0.00146484 1.0483 0.00146484 1.46109V5.98344C0.00146484 6.1849 0.0820455 6.37509 0.227378 6.51597L16.3133 21.9497C17.7983 23.4036 20.2056 23.4036 21.6877 21.9497C23.1727 20.4972 23.1727 18.1416 21.6877 16.6877L5.92414 0.938416C5.78025 0.797532 5.58599 0.71582 5.38166 0.71582H0.758347Z"
      fill="#001A4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10447 23.1921H1.36046C0.608397 23.1921 0.000488281 22.5849 0.000488281 21.8368V18.1043C0.000488281 17.3548 0.608397 16.749 1.36046 16.749H5.10447C5.85654 16.749 6.46444 17.3548 6.46444 18.1043V21.8368C6.46444 22.5849 5.85654 23.1921 5.10447 23.1921Z"
      fill="#001A4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9593 7.87448C16.9751 7.87448 15.3662 6.27117 15.3662 4.29515C15.3662 2.31778 16.9751 0.71582 18.9593 0.71582C20.9421 0.71582 22.551 2.31778 22.551 4.29515C22.551 6.27117 20.9421 7.87448 18.9593 7.87448Z"
      fill="#001A4D"
    />
  </svg>
);
