import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import store from 'store';

import { Dashboard, Widget } from '@nl-lms/feature/dashboard/sdk';

import { authStore } from '../../../_common/modules/Auth/auth';
import { dashboardApi } from '../../../_common/services/api';
import { WidgetConfig, WidgetsMap } from './AdminDashboardWidgetsMap';

const {
  useListDashboardsQuery,
  useCreateDashboardMutation,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} = dashboardApi;

type CreateDashboardArg = {
  label: string;
  targetLearnerGroupIds: string[];
};

type AdminDashboardContextType = {
  activeDashboard: Dashboard;
  availableDashboards: Dashboard[];
  isEditingLayout: boolean;
  onSelectDashboard: (dashboardId: string) => void;
  onCreateDashboard: (arg: CreateDashboardArg) => Promise<void>;
  onUpdateDashboard: (arg?: CreateDashboardArg) => void;
  onChangeWidgetPositions: (widgetPositions: Widget['position'][]) => void;
  onRemoveWidget: (widgetIndex: number) => void;
  onAddWidget: (widget: Widget) => void;
  onUpdateWidget: (widgetLabel: string, widget: Widget) => void;
  onRemoveDashboard: () => void;
  onClickEditLayout: () => void;
};

export const AdminDashboardContext =
  // @ts-ignore
  createContext<AdminDashboardContextType>(null);

type AdminDashboardContextState = {
  activeDashboard: Dashboard | null;
  draftDashboard: Dashboard | null;
  isEditingLayout: boolean;
  // Flag used to signal if an update should be made or not
  // This way we don't have to always have to have the latest
  // state in callbacks and just rely on the reducer state
  // keeping things cleaner
  performUpdate: boolean;
};

type AdminDashboardContextAction =
  | { type: 'change-active-dashboard'; payload: Dashboard | null }
  | { type: 'add-draft'; payload: Dashboard }
  | { type: 'submit-draft' }
  | { type: 'submit-draft'; payload: CreateDashboardArg }
  | { type: 'save-draft' }
  | { type: 'discard-draft' }
  | { type: 'start-edit' }
  | { type: 'change-widget-positions'; payload: Widget['position'][] }
  | { type: 'add-widget'; payload: Widget }
  | { type: 'update-widget'; payload: { widget: Widget; widgetLabel: string } }
  | { type: 'remove-widget'; payload: number };

const LOCAL_STORAGE_KEY = 'dashboard';

export const AdminDashboardContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer<
    React.Reducer<AdminDashboardContextState, AdminDashboardContextAction>
  >(dashboardContextReducer, {
    activeDashboard: store.get(LOCAL_STORAGE_KEY) || null,
    draftDashboard: null,
    isEditingLayout: false,
    performUpdate: false,
  });
  const { isEditingLayout, performUpdate, activeDashboard, draftDashboard } =
    state;
  const updatedActiveDashboardFromServerData = useRef(false);
  const { data } = useListDashboardsQuery();
  const defaultDashboard = useDefaultDashboard();
  const availableDashboards = data || [];
  const dashboard = useMemo(() => {
    if (!activeDashboard) return defaultDashboard;
    if (draftDashboard) return draftDashboard;
    return activeDashboard;
  }, [availableDashboards, draftDashboard, activeDashboard]);
  const [updateDashboard] = useUpdateDashboardMutation();
  const [createDashboard] = useCreateDashboardMutation();
  const [removeDashboard] = useDeleteDashboardMutation();

  useEffect(() => {
    store.set(LOCAL_STORAGE_KEY, activeDashboard);
  }, [activeDashboard]);

  useEffect(() => {
    // the db is the final source of truth here
    // if something changed there a page refresh should reflect the new state
    if (
      activeDashboard &&
      data?.length &&
      !updatedActiveDashboardFromServerData.current
    ) {
      const latestActiveDashboard =
        data.find((w) => w.id === activeDashboard.id) || null;
      dispatch({
        type: 'change-active-dashboard',
        payload: latestActiveDashboard,
      });
      updatedActiveDashboardFromServerData.current = true;
    }
  }, [data]);

  const saveDraft = useCallback(async () => {
    if (!draftDashboard) return;
    const result = await updateDashboard({
      ...draftDashboard,
      dashboardId: draftDashboard.id,
    });
    if ('error' in result) {
      dispatch({ type: 'discard-draft' });
    }
    dispatch({ type: 'save-draft' });
  }, [draftDashboard]);

  useEffect(() => {
    if (performUpdate) {
      saveDraft();
    }
  }, [performUpdate]);

  const onRemoveWidget = useCallback(async (widgetIndex) => {
    dispatch({ type: 'remove-widget', payload: widgetIndex });
  }, []);
  const onSelectDashboard = useCallback(
    (dashboardId) => {
      const selectedDashboard =
        availableDashboards.find((d) => d.id === dashboardId) || null;
      dispatch({ type: 'change-active-dashboard', payload: selectedDashboard });
    },
    [availableDashboards]
  );
  const onClickEditLayout = useCallback(() => {
    dispatch({ type: 'start-edit' });
  }, []);
  const onCreateDashboard = useCallback(async (dashboard) => {
    const result = await createDashboard({
      label: dashboard.label,
      targetLearnerGroupIds: dashboard.targetLearnerGroupIds,
      widgets: [],
    });
    if ('data' in result) {
      //@ts-ignore
      dispatch({ type: 'change-active-dashboard', payload: result.data });
    }
  }, []);
  const onUpdateDashboard = useCallback(
    async (updatePayload?: CreateDashboardArg) => {
      dispatch({ type: 'submit-draft', payload: updatePayload });
    },
    []
  );
  const onChangeWidgetPositions = useCallback(
    (widgetPositions: Widget['position'][]) => {
      dispatch({ type: 'change-widget-positions', payload: widgetPositions });
    },
    []
  );
  const onAddWidget = useCallback((widget: Widget) => {
    dispatch({ type: 'add-widget', payload: widget });
  }, []);
  const onUpdateWidget = useCallback((oldName: string, widget: Widget) => {
    dispatch({
      type: 'update-widget',
      payload: { widget, widgetLabel: oldName },
    });
  }, []);
  const onRemoveDashboard = useCallback(async () => {
    if (!activeDashboard) return;
    const result = await removeDashboard({ dashboardId: activeDashboard.id });
    if ('data' in result) {
      dispatch({ type: 'change-active-dashboard', payload: null });
    }
  }, [activeDashboard]);
  return (
    <AdminDashboardContext.Provider
      value={{
        activeDashboard: dashboard,
        availableDashboards,
        isEditingLayout,
        onSelectDashboard,
        onCreateDashboard,
        onUpdateDashboard,
        onChangeWidgetPositions,
        onRemoveWidget,
        onAddWidget,
        onRemoveDashboard,
        onUpdateWidget,
        onClickEditLayout,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
};

const dashboardContextReducer = (
  state: AdminDashboardContextState,
  action: AdminDashboardContextAction
) => {
  const currentDraft = state.draftDashboard || state.activeDashboard;
  switch (action.type) {
    case 'change-active-dashboard':
      return {
        ...state,
        activeDashboard: action.payload,
      };
    case 'add-draft':
      return {
        ...state,
        draftDashboard: action.payload,
      };
    case 'submit-draft':
      // TODO: Validate draft here
      if (!currentDraft) return state;
      else {
        const newDraft = { ...currentDraft };
        if ('payload' in action && action.payload) {
          newDraft.label = action.payload.label;
          newDraft.targetLearnerGroupIds = action.payload.targetLearnerGroupIds;
        }
        return {
          ...state,
          draftDashboard: newDraft,
          isEditingLayout: false,
          performUpdate: true,
        };
      }
    case 'save-draft':
      return {
        ...state,
        activeDashboard: state.draftDashboard,
        draftDashboard: null,
        performUpdate: false,
      };
    case 'discard-draft':
      return {
        ...state,
        draftDashboard: null,
        performUpdate: false,
      };
    case 'start-edit':
      return {
        ...state,
        draftDashboard: state.activeDashboard,
        isEditingLayout: true,
      };
    case 'change-widget-positions':
      if (!currentDraft || !state.isEditingLayout) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.map((widget, index) => ({
            ...widget,
            position: action.payload[index],
          })),
        },
      };
    case 'add-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: [...currentDraft.widgets, action.payload],
        },
        performUpdate: true,
      };
    case 'update-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.map((widget) => {
            if (widget.label === action.payload.widgetLabel) {
              return action.payload.widget;
            }
            return widget;
          }),
        },
        performUpdate: true,
      };
    case 'remove-widget':
      if (!currentDraft) return state;
      return {
        ...state,
        draftDashboard: {
          ...currentDraft,
          widgets: currentDraft.widgets.filter(
            (widget, index) => action.payload !== index
          ),
        },
        performUpdate: true,
      };
  }
};

const widgetConfigToWidget = (
  config: WidgetConfig,
  position: { x: number; y: number }
): Widget => ({
  label: config.label,
  type: config.type,
  payload: config.payload,
  position: { ...config.position, ...position },
});
const useDefaultDashboard = () => {
  const { hasAdminRole, hasManagerRole, hasEvaluatorRole } = authStore;
  const userId = authStore.user.id;
  return useMemo<Dashboard>(() => {
    const widgets: Widget[] = [];
    if (hasAdminRole) {
      widgets.push(
        widgetConfigToWidget(WidgetsMap.liveSessionTimeline, { x: 0, y: 0 }),
        widgetConfigToWidget(WidgetsMap.learningProgress, { x: 0, y: 18 }),
        widgetConfigToWidget(WidgetsMap.learnerLearners, { x: 4, y: 18 }),
        widgetConfigToWidget(WidgetsMap.fillRate, { x: 4, y: 21 }),
        widgetConfigToWidget(WidgetsMap.cancelledSessions, { x: 8, y: 18 }),
        widgetConfigToWidget(WidgetsMap.costPerLearner, { x: 8, y: 21 }),
        widgetConfigToWidget(WidgetsMap.activityLog, { x: 0, y: 24 })
      );
    }

    if (hasManagerRole) {
      widgets.push(
        widgetConfigToWidget(WidgetsMap.learningApprovals, { x: 0, y: 0 }),
        widgetConfigToWidget(WidgetsMap.addReports, { x: 0, y: 10 }),
        widgetConfigToWidget(WidgetsMap.myTeam, { x: 6, y: 6 })
      );
    }

    if (hasEvaluatorRole) {
      widgets.push(
        widgetConfigToWidget(WidgetsMap.assessmentProgress, { x: 0, y: 0 })
      );
    }

    if (hasEvaluatorRole && !hasManagerRole) {
      widgets.push(
        widgetConfigToWidget(WidgetsMap.learningAssignments, { x: 0, y: 10 }),
        widgetConfigToWidget(WidgetsMap.addReports, { x: 6, y: 10 })
      );
    }

    return {
      id: 'default',
      label: 'Your Dashboard',
      widgets,
      creator: {
        id: 'Test',
        firstName: 'default',
        lastName: 'default',
        email: 'default',
      },
      userId,
      targetLearnerGroupIds: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }, []);
};
