import React from 'react';

import { AsyncSingleSelect } from '@nl-lms/ui/components';
import { fetchAndMapReportTemplates } from '../../../_common/utils/fetchEntitiesForSelectMethods';

export const AdminAnalyticsReportTemplateSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItem = null,
    returnEntireItemOnChange = true,
    isClearable,
  } = props;

  const onChangeInputValue = (value) => {
    if (!value) {
      onChange('');
    }
  };

  return (
    <AsyncSingleSelect
      name={name}
      placeholder="Search by name"
      loadOptions={fetchAndMapReportTemplates}
      onChange={onChange}
      initialSelectedItem={initialSelectedItem}
      returnEntireItemOnChange={returnEntireItemOnChange}
      onChangeInputValue={onChangeInputValue}
      isClearable={isClearable}
    />
  );
};
