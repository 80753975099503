import React, { useCallback, useMemo } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { Button } from '../Button/Button';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import * as Icon from '../Icon';
import { ArrowLeftIcon } from '../Icon/ArrowLeftIcon';
import { ArrowRightIcon } from '../Icon/ArrowRightIcon';
import { TidComponent } from '../index.types';
import './Pagination.scss';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const fetchPaginationPages = ({ pageCount, currentPage, pageNeighbours }) => {
  /*
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;

  if (pageCount > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(pageCount - 1, currentPage + pageNeighbours);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const basePages = _.range(startPage, endPage + 1);
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = pageCount - endPage > 1;
    const spillOffset = totalNumbers - (basePages.length + 1);

    const pages = (() => {
      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = _.range(startPage - spillOffset, startPage);
        return [LEFT_PAGE, ...extraPages, ...basePages];
      } else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = _.range(endPage + 1, endPage + spillOffset + 1);
        return [...basePages, ...extraPages, RIGHT_PAGE];
      } else {
        return [LEFT_PAGE, ...basePages, RIGHT_PAGE];
      }
    })();

    return [1, ...pages, pageCount];
  }

  return _.range(1, pageCount + 1);
};

type PaginationProps = TidComponent<{
  pagination: {
    offset: number;
    limit: number;
    rowCount: number;
  };
  variant?: 'transparent' | 'white';
  className?: string;
  selectLimit?: boolean;
  entityName?: string;
  onChangePage: (props: { offset: number; limit: number }) => void;
}>;

export const Pagination = ({
  pagination,
  onChangePage,
  entityName = 'row',
  variant = 'transparent',
  selectLimit = false,
  ...props
}: PaginationProps) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'pagination',
    props,
    variant === 'white' ? 'pagination--white' : null
  );

  const { offset, limit, rowCount } = pagination;
  const pageCount = Math.ceil(rowCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;
  const paginationPages = fetchPaginationPages({
    pageCount,
    currentPage,
    pageNeighbours: 2,
  });

  const onChangePagination = useCallback(
    (page) => {
      if (page < 1 || page > pageCount || page === currentPage) return;
      let nextOffset = (page - 1) * limit;
      if (nextOffset >= rowCount) nextOffset = rowCount;

      onChangePage({ offset: nextOffset, limit });
    },
    [currentPage, pageCount, limit, rowCount]
  );

  const onChangePaginationLimit = useCallback(
    (newLimit) => {
      onChangePage({ limit: newLimit, offset: 0 });
    },
    [offset]
  );

  const paginationLimitOptions = useMemo(
    () =>
      [10, 20, 30, 40, 50]
        .filter((v) => v !== limit)
        .map((v) => ({
          name: `Show ${v} ${entityName}s`,
          handler: () => onChangePaginationLimit(v),
        })),
    [limit, onChangePaginationLimit, rowCount]
  );

  if (rowCount === 0) return null;
  if (rowCount < limit) {
    return (
      <div {...classNameProps} {...commonProps}>
        <div className="pagination__info">
          <span>{rowCount}</span> {` ${entityName}${rowCount > 1 ? 's' : ''}`}
        </div>
        {selectLimit ? (
          <div className="pagination__limit">
            <FloatingMenu items={paginationLimitOptions}>
              <div className="pagination__limit-input">
                <span>{`${limit} per page`}</span>
                <Icon.ArrowDownIcon />
              </div>
            </FloatingMenu>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div {...classNameProps} {...commonProps}>
      <div className="pagination__info">
        {selectLimit ? (
          <>
            <span>{rowCount}</span>
            {` ${entityName}s `}
          </>
        ) : (
          <>
            <span>{offset + 1}</span>
            {` to `}
            <span>{currentPage === pageCount ? rowCount : offset + limit}</span>
            {` out of `}
            <span>{rowCount}</span>
            {` ${entityName}s `}
          </>
        )}
      </div>

      <div className="pagination__pages">
        {_.map(paginationPages, (pageNo) => {
          if (pageNo === LEFT_PAGE) {
            return (
              <Button
                key={pageNo}
                className="pagination__item"
                onClick={() => onChangePagination(currentPage - 1)}
                ghost
                small
                label={''}
                icon={<ArrowLeftIcon />}
              />
            );
          }

          if (pageNo === RIGHT_PAGE) {
            return (
              <Button
                key={pageNo}
                className="pagination__item"
                onClick={() => onChangePagination(currentPage + 1)}
                ghost
                small
                label={''}
                icon={<ArrowRightIcon />}
              />
            );
          }

          return (
            <Button
              key={pageNo}
              ghost={pageNo !== currentPage}
              onClick={() => onChangePagination(pageNo)}
              label={pageNo}
              className="pagination__item"
              small
            />
          );
        })}
      </div>
      {selectLimit ? (
        <div className="pagination__limit">
          <FloatingMenu items={paginationLimitOptions}>
            <div className="pagination__limit-input">
              <span>{`${limit} per page`}</span>
              <Icon.ArrowDownIcon />
            </div>
          </FloatingMenu>
        </div>
      ) : null}
    </div>
  );
};
