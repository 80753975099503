export const getSelectOptions = (
  resourceList,
  getLabel = (resource) => resource.name,
  getId = (resource) => resource.id
) => {
  const mapper = (resource) => ({
    label: getLabel(resource),
    value: getId(resource),
  });
  return resourceList.map(mapper);
};
