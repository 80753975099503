import { stringify, parse } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSetUrlQuery = (url: string, query: any) => {
  return `${url}?${stringify(query)}`;
};

export const useGetUrlQuery = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => parse(search) || {}, [search]);

  return queryParams;
};
