import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AssessmentQuestionWithValidation,
  Button,
  Card,
  Separator,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';
import { routes } from '@nl-lms/web/lib/routes';

import { useStartAttempt } from '../learnerAssessmentPlayerHooks';
import { selectors } from '../learnerAssessmentPlayerSlice';
import './LearnerAssessmentPlayerAttemptResult.scss';

export const LearnerAssessmentResultMessage = () => {
  const { t } = useTranslation('learner');

  const assessment = useSelector(selectors.getAssessment);
  const userAnswers = useSelector(selectors.getUserAnswers);
  const assessmentId = useSelector(selectors.getAssessmentId);
  const score = useSelector(selectors.getAttemptScore);
  const minScore = useSelector(selectors.getMinScore);
  const maxScore = useSelector(selectors.getMaxScore);
  const hasReachedMaxAttempts = useSelector(selectors.hasReachedMaxAttempts);
  const { onStartAttempt, isLoading } = useStartAttempt(assessmentId);

  const orderedQuestions = useMemo(
    () =>
      _.sortBy(
        assessment?.attempt?.assessmentFormInstanceAttemptQuestions || [],
        (q) => q.order,
      ),
    [assessment],
  );

  const retakeButtonLabel = useMemo(() => {
    if (score < minScore) {
      return t('learningunit.retakeAssessmentFailed');
    } else {
      return t('learningunit.retakeAssessmentPassed');
    }
  }, [minScore, score]);

  return (
    <Card
      className="learner-assessment-player-result-message"
      paddingType="medium"
    >
      <Card.Content>
        <AssessmentAttemptTitle />
        <AssessmentAttemptPassedSubtitle />
        <AssessmentAttemptFailedSubtitle />
        <AssessmentAttemptsDecription />

        {assessment?.showResults && (
          <div>
            {orderedQuestions.map((attemptQuestion, index) => {
              const question = assessment.attemptQuestions.find(
                (r) => r.id === attemptQuestion.assessmentQuestionId,
              );
              return (
                <div
                  className="learner-assessment-player-result-message__row"
                  key={index}
                >
                  <div className="learner-assessment-player-result-message__row-column">
                    <Separator />
                    <AssessmentQuestionWithValidation
                      // @ts-ignore
                      type={question.type}
                      // @ts-ignore
                      question={question.title}
                      index={index}
                      // @ts-ignore
                      options={question.options.map((q) => ({
                        value: q.title,
                        label: q.title,
                      }))}
                      // @ts-ignore
                      id={question.id}
                      learnerAnswer={
                        // @ts-ignore
                        userAnswers[question.id]?.map((answer) =>
                          answer?.trimStart(),
                        ) || ['']
                      }
                      allowManualScoring={false}
                      showCorrect={false}
                      isCorrect={attemptQuestion.isCorrect}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <Separator />

        <div className="learner-assessment-player-result-message__actions">
          {score < maxScore ? (
            <Button
              label={retakeButtonLabel}
              regular
              isLoading={isLoading}
              onClick={onStartAttempt}
              disabled={hasReachedMaxAttempts}
            />
          ) : null}
        </div>
      </Card.Content>
    </Card>
  );
};

const AssessmentAttemptTitle = () => {
  const { t } = useTranslation('learner');
  const score = useSelector(selectors.getAttemptScore);
  if (score !== null) {
    return (
      <Typography.h1>
        {t('learningunit.assessmentscorefinal', { score })}
      </Typography.h1>
    );
  }
  return (
    <Typography.h1>{t('learningunit.assessmentscorescoring')}</Typography.h1>
  );
};

const AssessmentAttemptPassedSubtitle = () => {
  const status = useSelector(selectors.getAttemptStatus);
  const { t } = useTranslation('learner');
  const score = useSelector(selectors.getAttemptScore);
  const minScore = useSelector(selectors.getMinScore);
  const assessment = useSelector(selectors.getAssessment);
  if (status !== C.I_ASSESSMENT_INSTANCE_ATTEMPT_STATUSES.ENDED) return null;

  if (score < minScore) return null;
  if (assessment?.hasCertification) {
    return (
      <>
        <Typography.h3 className="learner-assessment-player-result-message__subtitle">
          <Trans i18nKey="learningunit.assessmentWithCertificatePassedSubtitle">
            Congratulations, you have passed the test! Your certificate is now
            being generated and it will be sent to you over email.
          </Trans>
        </Typography.h3>
        <Typography.p className="learner-assessment-player-result-message__description">
          <Trans i18nKey="learningunit.assessmentWithCertificatePassedDescription">
            After that you can always access it from the{' '}
            <a href={routes.portal.activity.path.full()}>learning activity</a>{' '}
            page.
          </Trans>
        </Typography.p>
      </>
    );
  }

  return (
    <>
      <Typography.h3 className="learner-assessment-player-result-message__subtitle">
        {t('learningunit.assessmentsuccesssubtitle')}
      </Typography.h3>
      <Typography.p className="learner-assessment-player-result-message__description">
        {t('learningunit.assessmentsuccessdescription')}
      </Typography.p>
      <Separator marginBottom={10} />
    </>
  );
};

const AssessmentAttemptFailedSubtitle = () => {
  const status = useSelector(selectors.getAttemptStatus);
  const { t } = useTranslation('learner');
  const score = useSelector(selectors.getAttemptScore);
  const minScore = useSelector(selectors.getMinScore);
  if (status !== C.I_ASSESSMENT_INSTANCE_ATTEMPT_STATUSES.ENDED) return null;
  if (score >= minScore) return null;
  return (
    <Typography.h3 className="learner-assessment-player-result-message__subtitle">
      {t('learningunit.assessmentfaildescription')}
    </Typography.h3>
  );
};

const AssessmentAttemptsDecription = () => {
  const { t } = useTranslation('learner');
  const maxAttempts = useSelector(selectors.getMaxAttempts);
  const score = useSelector(selectors.getAttemptScore);
  const maxScore = useSelector(selectors.getMaxScore);
  const remainingAttemptsCount = useSelector(
    selectors.getRemainingAttemptsCount,
  );
  const remainingAttemptsLiteral =
    remainingAttemptsCount > 1
      ? t('learningunit.assessmentremainingattemptmultiple', {
          remainingAttemptsCount,
        })
      : t('learningunit.assessmentremainingattemptsingle');
  if (maxAttempts === 1 || maxScore === score) return null;
  if (remainingAttemptsCount === 0) {
    return (
      <Typography.p className="learner-assessment-player-result-message__description">
        {remainingAttemptsCount === 0
          ? t('learningunit.assessmentnoattempts')
          : remainingAttemptsLiteral}
      </Typography.p>
    );
  }
  if (remainingAttemptsCount > 1) {
    return (
      <Typography.p className="learner-assessment-player-result-message__description">
        {t('learningunit.assessmentremainingattemptmultiple', {
          remainingAttemptsCount,
        })}
      </Typography.p>
    );
  }
  return (
    <Typography.p className="learner-assessment-player-result-message__description">
      {t('learningunit.assessmentremainingattemptsingle')}
    </Typography.p>
  );
};
