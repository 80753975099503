import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import { Notification, NotificationWithLoadingState } from '@nl-lms/ui/modules';
import { _ } from '@nl-lms/vendor';

import { NotificationModel, NotificationTypes } from './Notification.types';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type NotificationContextType = {
  notifications: NotificationModel[];
  onAddNotification: (
    notification: PartialBy<
      Omit<NotificationModel, 'id' | 'timestamp' | 'visible'>,
      'duration'
    >
  ) => string;
  onRemoveNotification: (notificationId: string) => void;
};

const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

type NotificationsReducerState = {
  notifications: NotificationModel[];
};

type NotificationsReducerAction =
  | {
      type: 'add-notification';
      payload: NotificationModel;
    }
  | {
      type: 'remove-notification';
      payload: string;
    };

export const NotificationsProvider = ({ children }) => {
  const [{ notifications }, dispatch] = useReducer<
    React.Reducer<NotificationsReducerState, NotificationsReducerAction>
  >(notificationsReducer, { notifications: [] });

  const onAddNotification = useCallback(
    (notification) => {
      const notificationId = _.uniqueId('success-notification');
      const timestamp = new Date().toISOString();
      const duration = typeof notification.duration === 'number' || 5;

      dispatch({
        type: 'add-notification',
        payload: { ...notification, id: notificationId, timestamp, duration },
      });

      return notificationId;
    },
    [notifications]
  );

  const onRemoveNotification = useCallback(
    (notificationId: string) => {
      dispatch({
        type: 'remove-notification',
        payload: notificationId,
      });
    },
    [notifications]
  );
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        onAddNotification,
        onRemoveNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const NotificationsContainer = () => {
  const { notifications, onRemoveNotification } =
    useContext(NotificationContext);

  return (
    <>
      {notifications.map((notification, index) => (
        <Notification
          key={`notification-${notification.id}-${index}`}
          notification={notification}
          onClose={() => onRemoveNotification(notification.id)}
        />
      ))}
    </>
  );
};

const notificationsReducer = (
  state: NotificationsReducerState,
  action: NotificationsReducerAction
) => {
  switch (action.type) {
    case 'add-notification':
      const existingNotification = state.notifications.find(
        (n) => n.message == action.payload.message
      );
      if (existingNotification) return { notifications: state.notifications };

      return {
        notifications: [action.payload, ...state.notifications.slice(0, 10)],
      };
    case 'remove-notification':
      return {
        notifications: state.notifications.filter(
          (n) => n.id !== action.payload
        ),
      };
  }
};

type AddNotificationProps = {
  code?: number;
  message: string;
  details?: string;
  link?: string;
};

export const useNotifications = () => {
  const { onAddNotification, onRemoveNotification } =
    useContext(NotificationContext);

  const addNotification = useCallback(
    (props: AddNotificationProps & { type: NotificationTypes }) => {
      return onAddNotification(props);
    },
    []
  );

  const addSuccessNotification = useCallback(
    (props: string | AddNotificationProps) => {
      const message = typeof props === 'string' ? props : props.message;
      const _props = typeof props === 'string' ? {} : props;
      return addNotification({
        type: NotificationTypes.success,
        ..._props,
        message,
      });
    },
    []
  );
  const addAlertNotification = useCallback(
    (props: string | AddNotificationProps) => {
      const message = typeof props === 'string' ? props : props.message;
      const _props = typeof props === 'string' ? {} : props;
      return addNotification({
        type: NotificationTypes.alert,
        ..._props,
        message,
      });
    },
    []
  );
  const addInfoNotification = useCallback(
    (props: string | AddNotificationProps) => {
      const message = typeof props === 'string' ? props : props.message;
      const _props = typeof props === 'string' ? {} : props;
      return addNotification({
        type: NotificationTypes.info,
        ..._props,
        message,
      });
    },
    []
  );
  const addWarningNotification = useCallback(
    (props: string | AddNotificationProps) => {
      const message = typeof props === 'string' ? props : props.message;
      const _props = typeof props === 'string' ? {} : props;
      return addNotification({
        type: NotificationTypes.warning,
        ..._props,
        message,
      });
    },
    []
  );

  return {
    addAlertNotification,
    addInfoNotification,
    addWarningNotification,
    addSuccessNotification,
    removeNotification: onRemoveNotification,
  };
};
