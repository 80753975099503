import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldType, OperatorToLabelRecord } from '@nl-lms/common/shared';
import { Icon } from '@nl-lms/ui/components';
import { useClassNameProps } from '@nl-lms/ui/hooks';

import { useHotkey } from '../../../hooks/useHotkey';
import { getModifierKey } from '../../../utils/getModifierKey';
import { FilterBarFilteringState } from '../FilterBar.types';
import {
  FieldTypeToOptionsComponentMap,
  FieldTypeToOptionsSuggestComponentMap,
  FilterBarContext,
} from '../FilterBarContext/FilterBarContext';
import { FilterBarFieldOption } from '../FilterBarOptions';

export const FilterBarInputSearchIcon = () => (
  <div className="filter-bar__search-icon">
    <Icon.FilterIcon />
  </div>
);

export const FilterBarInputActiveFilterLabel = () => {
  const { activeFilter } = useContext(FilterBarContext);

  const label = useMemo(() => {
    if (!activeFilter) return '';
    const operatorLabel = activeFilter.operator
      ? ` ${OperatorToLabelRecord[activeFilter.operator]}`
      : '';
    return `${activeFilter.field.label}${operatorLabel}:`;
  }, [activeFilter]);

  if (!label) return null;

  return <div className="filter-bar__active-filter">{label}</div>;
};

export const FilterBarTextInputWrapper = ({ children }) => (
  <div className="filter-bar__input-wrapper">{children}</div>
);

export const FilterBarInputVerticalSeparator = () => (
  <span className="filter-bar__input-icon-separator" />
);

export const FilterBarTextInput = () => {
  const { t } = useTranslation('learner');
  const {
    isLoading,
    filteringState,
    activeFilter,
    inputValue,
    inputRef,
    onChangeInputValue,
    onFocusInput,
    onInputKeyDown,
  } = useContext(FilterBarContext);

  const onPressHotkey = useCallback(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);
  useHotkey(`${getModifierKey()}+shift+k`, onPressHotkey);

  const placeholder = useMemo(() => {
    if (filteringState === FilterBarFilteringState.EditingFilterOperator) {
      return t('common.filterbar.selectoperator');
    }

    if (filteringState === FilterBarFilteringState.EditingFilterValue) {
      if (
        activeFilter.field.type === FieldType.string ||
        activeFilter.field.type === FieldType.number
      ) {
        return t('common.filterbar.typevalue');
      }

      if (activeFilter.field.type === FieldType.date) {
        return t('common.filterbar.selectdate');
      }
      if (activeFilter.field.type === FieldType.datetime) {
        return t('common.filterbar.selectdate');
      }
      return t('common.filterbar.selectoption');
    }

    return t('common.filterbar.typesearchterm');
  }, [filteringState, activeFilter]);

  const onChange = useCallback((e) => onChangeInputValue(e.target.value), []);

  return (
    <input
      className="filter-bar__input"
      data-testid="filter-bar-input"
      value={inputValue}
      ref={inputRef}
      placeholder={placeholder}
      disabled={isLoading}
      onKeyDown={onInputKeyDown}
      onChange={onChange}
      onFocus={onFocusInput}
      onClick={onFocusInput}
    />
  );
};

export const FilterBarInputLoadingSpinner = () => {
  const { isLoading } = useContext(FilterBarContext);

  if (!isLoading) return null;
  return <div className="filter-bar__spinner" />;
};

export const FilterBarInputMenu = ({ className = '' }) => {
  const {
    isMenuOpen,
    filteringState,
    inputValue,
    optionsMenuRef,
    activeOptionId,
    activeFilter,
    onSelectOption,
    getOptionProps,
    hiddenFields,
    fields,
  } = useContext(FilterBarContext);

  const { t } = useTranslation('learner');
  const optionsClassNameProps = useClassNameProps(
    'filter-bar__options-menu',
    className
  );

  const visibleFields = useMemo(
    () =>
      fields.filter((field) => {
        if (hiddenFields.includes(field.name)) return false;

        const OptionsComponent = FieldTypeToOptionsComponentMap[field.type];
        return !!OptionsComponent;
      }),
    [fields]
  );

  if (!isMenuOpen) return null;

  const renderOptions = () =>
    visibleFields.map((field, index) => {
      const key = `field-input-menu-options-${field.name}-${index}`;

      // default to regular option compoent
      let OptionsComponent = FieldTypeToOptionsComponentMap[field.type];

      if (
        // only show field name component
        filteringState === FilterBarFilteringState.NotEditing &&
        !inputValue
      ) {
        OptionsComponent = FilterBarFieldOption;
      } else if (
        // suggest component
        filteringState === FilterBarFilteringState.NotEditing &&
        inputValue
      ) {
        OptionsComponent = FieldTypeToOptionsSuggestComponentMap[field.type];
      } else if (activeFilter?.field?.name !== field.name) {
        return null;
      }

      return (
        <OptionsComponent
          key={key}
          inputValue={inputValue}
          activeOptionId={activeOptionId}
          filteringState={filteringState}
          activeFilter={activeFilter}
          field={field}
          onSelectOption={onSelectOption}
          getOptionProps={getOptionProps}
        />
      );
    });

  return (
    <div className="filter-bar__options-menu-wrapper">
      <ul {...optionsClassNameProps} ref={optionsMenuRef}>
        {filteringState === FilterBarFilteringState.NotEditing &&
        !inputValue ? (
          <span className="filter-bar__option-separator">
            {t('common.filterbar.fields')}
          </span>
        ) : null}
        {renderOptions()}
      </ul>
    </div>
  );
};
