import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Icon, Input } from '@nl-lms/ui/components';

import './AdminAssessmentAddQuestionOptionsInput.scss';

type Option = {
  title: string;
  correct?: boolean;
};

type Props = {
  onChange: (options: Option[]) => void;
  initialOptions?: Option[];
  isMultiSelect?: boolean;
  type?: number;
  name: string;
};

export const AdminAssessmentAddQuestionOptionsInput = (props: Props) => {
  const {
    onChange,
    name,
    isMultiSelect = false,
    initialOptions = [],
    type,
  } = props;
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (initialOptions) onChangeOptions(initialOptions);
  }, [type]);

  const onChangeOptions = useCallback(
    (newOptions) => {
      setOptions(newOptions);
      onChange(newOptions);
    },
    [onChange, setOptions]
  );

  const onClickOption = useCallback(
    (option, optionIndex) => {
      if (isMultiSelect) {
        onChangeOptions(
          options.map((o, index) => ({
            ...o,
            correct: index === optionIndex ? !o.correct : o.correct,
          }))
        );
      } else {
        onChangeOptions(
          options.map((o, index) => ({
            ...o,
            correct: index === optionIndex,
          }))
        );
      }
    },
    [isMultiSelect, onChangeOptions, options]
  );

  const onCreateOption = useCallback(() => {
    const option = { title: inputValue, correct: false };
    if (!options.find((o) => o.title === inputValue)) {
      onChangeOptions([...options, option]);
      setInputValue('');
    }
  }, [inputValue, onChangeOptions, options]);

  const onRemoveOption = useCallback(
    (option) => {
      onChangeOptions(options.filter((o) => o.title !== option.title));
    },
    [onChangeOptions, options]
  );

  const onInputKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && inputValue) onCreateOption();
    },
    [inputValue, onCreateOption]
  );

  return (
    <div className="add-question-options-input">
      <div className="add-question-options-input__options">
        {options.map((option, index) => (
          <div
            className="add-question-options-input__option"
            key={`${option.title}-${index}`}
          >
            <div
              className={`add-question-options-input__option-label ${
                option.correct
                  ? 'add-question-options-input__option-label--is-correct'
                  : ''
              }`}
              onClick={() => onClickOption(option, index)}
            >
              <span>{option.title}</span>
            </div>
            <span
              className="add-question-options-input__option-delete"
              onClick={() => onRemoveOption(option)}
            >
              <Icon.DeleteIcon />
            </span>
          </div>
        ))}
        <Input
          className="add-question-options-input__option-input"
          name={name}
          // @ts-ignore
          ref={inputRef}
          placeholder="Type your option here"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onKeyDown={onInputKeyDown}
        />
      </div>
      <Button
        className="add-question-options-input__button"
        label="Add Option"
        disabled={!inputValue}
        onClick={onCreateOption}
        regular
      />
    </div>
  );
};
