import React from 'react';

import { AppQueryFilter } from '@nl-lms/common/shared';
import {
  Card,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
} from '@nl-lms/ui/components';
import { Typography } from '@nl-lms/ui/components';
import { prettyDistance } from '@nl-lms/ui/utils';

import { adminApi } from '../../../_common/services/api';

const parseResponse = (activityLogs) =>
  [...activityLogs]?.sort((a, b) => {
    // @ts-ignore
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

export const AdminActivityLogGroupedCard = ({
  filters,
}: {
  filters: AppQueryFilter;
}) => {
  const { useListActivityLogGroupedQuery } = adminApi;
  const { isLoading, data: activityLogsRes } =
    useListActivityLogGroupedQuery(filters);

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  const activityLogs = parseResponse(activityLogsRes);

  return (
    <Card>
      <Card.Header>
        <Card.HeaderTitle>Latest Updates</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <Card.List>
          {activityLogs.length === 0 ? (
            <NoDataPlaceholder
              iconName="ArchiveIcon"
              title="There are no logs available"
              color={NoDataPlaceholderColor.success}
            />
          ) : (
            <>
              {activityLogs.map((activity, index) => (
                <Card.ListItem key={`${activity.message}-${index}`}>
                  <Card.ListItemHeader>
                    <Card.ListItemTitle>{activity.message}</Card.ListItemTitle>
                    <Card.ListItemActions>
                      <Typography.p>
                        {prettyDistance(activity.timestamp).content}
                      </Typography.p>
                    </Card.ListItemActions>
                  </Card.ListItemHeader>
                </Card.ListItem>
              ))}
            </>
          )}
        </Card.List>
      </Card.Content>
    </Card>
  );
};
