import React from 'react';

import { dateFns } from '@nl-lms/vendor';

import { prettyDate } from '../utils/prettyDate';

type PrettyDateProps = {
  value?: Date | string | null;
  withTime?: boolean;
  withDate?: boolean;
  children?: any;
};

const getDate = (date: string | Date | number): Date => {
  if (typeof date === 'string') return new Date(date);
  else if (typeof date === 'number') return dateFns.toDate(date);
  else if (date instanceof Date) return date;
  else return dateFns.toDate(date); // should throw error
};

export const PrettyDate = ({
  value,
  withTime,
  children,
  withDate = true,
}: PrettyDateProps) => {
  if (!value) return <>-</>;
  const date = getDate(value || children || null);
  const content = prettyDate(date, { withTime, withDate });

  return <>{content || '-'}</>;
};
