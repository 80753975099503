import { SyntheticEvent } from 'react';

import {
  ArraySelectFieldMetadata,
  BooleanFieldMetadata,
  DateFieldMetadata,
  DatetimeFieldMetadata,
  FieldMetadata,
  FieldType,
  JsonArraySelectFieldMetadata,
  NumberFieldMetadata,
  QueryOperator,
  SelectFieldMetadata,
  StringFieldMetadata,
} from '@nl-lms/common/shared';

export type OperatorOption = {
  id: string;
  field: FieldMetadata;
  type: 'OperatorOption';
  value: QueryOperator;
  label: string;
};

export type FieldOption = {
  id: string;
  field: FieldMetadata;
  type: 'FieldOption';
  value: string;
  label: string;
};

export type SettingsOption = {
  type: 'SettingsOptions';
  value: 'EditValue' | 'EditOperator' | 'RemoveFilter';
  label: string;
};

export type StringValueOption = {
  id: string;
  field: StringFieldMetadata;
  type: 'StringValueOption';
  operator: QueryOperator;
  label: string;
  value: string;
};

export type NumberValueOption = {
  id: string;
  field: NumberFieldMetadata;
  type: 'NumberValueOption';
  operator: QueryOperator;
  label: string;
  value: number;
};

export type BooleanValueOption = {
  id: string;
  field: BooleanFieldMetadata;
  type: 'BooleanValueOption';
  operator: QueryOperator;
  label: string;
  value: boolean;
};

export type DateValueOption = {
  id: string;
  field: DateFieldMetadata;
  type: 'DateValueOption';
  operator: QueryOperator;
  label: string;
  value: string;
};

export type DatetimeValueOption = {
  id: string;
  field: DatetimeFieldMetadata;
  type: 'DatetimeValueOption';
  operator: QueryOperator;
  label: string;
  value: string;
};

export interface SelectValueOption {
  id: string;
  field:
    | SelectFieldMetadata
    | JsonArraySelectFieldMetadata
    | ArraySelectFieldMetadata;
  type: 'SelectValueOption';
  operator: QueryOperator;
  Component?: any;
  label: string;
  isLoading?: boolean;
  isChecked: boolean;
  value: number | string;
}

export type ValueOption =
  | StringValueOption
  | DateValueOption
  | DatetimeValueOption
  | BooleanValueOption
  | NumberValueOption
  | SelectValueOption;

export type FilterBarOption =
  | OperatorOption
  | FieldOption
  | ValueOption
  | SettingsOption;

export enum FilterBarFilteringState {
  NotEditing = 'NotEditing',
  EditingFilter = 'EditingFilter',
  EditingFilterOperator = 'EditingFilterOperator',
  EditingFilterValue = 'EditingFilterValue',
}

type DateFilterBarActiveFilter = {
  field: DateFieldMetadata;
  type: 'DateField';
  operator?: QueryOperator;
  value?: string;
  index?: number;
};
type DatetimeFilterBarActiveFilter = {
  field: DatetimeFieldMetadata;
  type: 'DatetimeField';
  operator?: QueryOperator;
  value?: string;
  index?: number;
};
type StringFilterBarActiveFilter = {
  field: StringFieldMetadata;
  type: 'StringField';
  operator?: QueryOperator;
  value?: string;
  index?: number;
};
type NumberFilterBarActiveFilter = {
  field: NumberFieldMetadata;
  type: 'NumberField';
  operator?: QueryOperator;
  value?: number;
  index?: number;
};
type BooleanFilterBarActiveFilter = {
  field: BooleanFieldMetadata;
  type: 'BooleanField';
  operator?: QueryOperator;
  value?: boolean;
  index?: number;
};
type SelectFilterBarActiveFilter = {
  type: 'SelectField';
  field:
    | SelectFieldMetadata
    | JsonArraySelectFieldMetadata
    | ArraySelectFieldMetadata;
  operator?: QueryOperator;
  value?: number[] | string[];
  index?: number;
};
export type FilterBarActiveFilter =
  | SelectFilterBarActiveFilter
  | NumberFilterBarActiveFilter
  | DateFilterBarActiveFilter
  | DatetimeFilterBarActiveFilter
  | BooleanFilterBarActiveFilter
  | StringFilterBarActiveFilter;
export type FilterBarActiveFilterType = FilterBarActiveFilter['type'];
export const FieldTypeToFilterBarActiveFilterType: Record<
  FieldType,
  FilterBarActiveFilterType
> = {
  [FieldType.select]: 'SelectField',
  [FieldType.jsonArraySelect]: 'SelectField',
  [FieldType.arraySelect]: 'SelectField',
  [FieldType.number]: 'NumberField',
  [FieldType.string]: 'StringField',
  [FieldType.date]: 'DateField',
  [FieldType.datetime]: 'DatetimeField',
  [FieldType.boolean]: 'BooleanField',
};

export type FilterBarOptionsComponentProps<Field extends FieldMetadata> = {
  inputValue: string;
  activeOptionId: string;
  filteringState: FilterBarFilteringState;
  activeFilter: FilterBarActiveFilter;
  field: Field;
  onSelectOption: (option: FilterBarOption) => void;
  getOptionProps: (
    option: FieldOption | OperatorOption | ValueOption | SelectValueOption,
    id: string
  ) => {
    onMouseEnter: (e: any) => void;
    'data-list-item-id': string;
    'data-list-item-type': 'option';
    onClick: (e: SyntheticEvent, isCheckbox?: boolean) => void;
  };
};

export type FilterBarField = FieldMetadata;
