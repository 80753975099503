import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryFilter, QueryOperator } from '@nl-lms/common/shared';
import { LearnerPortalSearchResult } from '@nl-lms/sdk/backend';
import { CommandMenu, Icon, Link } from '@nl-lms/ui/components';
import { useHotkey, useRoutingAction } from '@nl-lms/ui/hooks';
import { getModifierKey } from '@nl-lms/ui/utils';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';
import { learnerApi } from '@nl-lms/web/_common/services/api';
import { routes } from '@nl-lms/web/lib/routes';

const { useSearchQuery } = learnerApi;

type SearchResult = {
  label: string;
  type: string;
  link: string;
  count: number;
  details: Record<string, unknown>;
};

type SearchResultType = LearnerPortalSearchResult['type'];

export const LearnerAppSearchCommandMenu = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState(inputValue);
  const fetchArgument = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      searchTerm,
    }),
    [searchTerm]
  );
  const [isOpen, setIsOpen] = useState(false);
  // @ts-expect-error
  const { data, isLoading, isFetching } = useSearchQuery(fetchArgument);
  const results = data || [];
  const goToRoute = useRoutingAction();
  const debounceSetSearchTerm = useCallback(_.debounce(setSearchTerm, 200), []);

  useHotkey(`${getModifierKey()}+k`, () => setIsOpen(true));

  const onChangeInputValue = useCallback((value) => {
    setInputValue(value);
    debounceSetSearchTerm(value);
  }, []);

  const onSelectItem = useCallback((value) => {
    goToRoute(value);
    setIsOpen(false);
    setInputValue('');
  }, []);

  const { t } = useTranslation('learner');
  const parsedResults = useMemo<Record<string, SearchResult[]>>(() => {
    if (!searchTerm)
      return {
        pages: [
          {
            label: t('common.searchbar.dashboard'),
            type: 'pages',
            count: 9,
            link: routes.portal.dashboard.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.liveCourses'),
            type: 'catalogue',
            count: 9,
            link: routes.portal.catalogue.liveCourses.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.elearningCourses'),
            type: 'catalogue',
            count: 9,
            link: routes.portal.catalogue.elearningCourses.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.learningPaths'),
            type: 'catalogue',
            count: 9,
            link: routes.portal.catalogue.learningPaths.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.elearningActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.elearning.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.learningPathsActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.learningPath.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.liveSessionsActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.liveSession.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.assessmentsActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.assessment.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.surveysActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.survey.path.full(),
            details: {},
          },
          {
            label: t('common.searchbar.checklistsActivity'),
            type: 'activity',
            count: 9,
            link: routes.portal.activity.checklist.path.full(),
            details: {},
          },
        ],
      };
    const mappedResults: SearchResult[] = results.map((result) => ({
      label: result.name,
      type: result.type,
      details: result.details,
      count: result.count,
      link: SearchResultTypeToGetViewPathRecord[result.type](
        result.id,
        inputValue
      ),
    }));
    const groupedResults = _.groupBy(mappedResults, 'type');

    for (const type in groupedResults) {
      const typeResults = groupedResults[type];
      if (typeResults[0].count > typeResults.length) {
        groupedResults[type] = [
          ...typeResults,
          {
            label: 'View More',
            type: 'view-more',
            details: { type: typeResults[0].type },
            count: typeResults[0].count - typeResults.length,
            link: SearchResultTypeToGetListPathRecord[type](inputValue),
          },
        ];
      } else {
        groupedResults[type] = typeResults;
      }
    }
    return groupedResults;
  }, [results, searchTerm]);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="learner-app-main-layout__search-button"
      >
        <Icon.SearchIcon />
        Search
      </button>
      <CommandMenu.Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <CommandMenu>
          <CommandMenu.Header>
            <CommandMenu.Input
              value={inputValue}
              onValueChange={onChangeInputValue}
              placeholder="Search for any type of learning resource"
            />
            <CommandMenu.Loading show={isLoading || isFetching} />
          </CommandMenu.Header>
          <CommandMenu.Separator alwaysRender />
          <CommandMenu.Empty hide={isLoading || isFetching}>
            No results found
          </CommandMenu.Empty>
          <CommandMenu.List>
            {Object.keys(parsedResults).map((type, index) => {
              return (
                <>
                  {index > 0 && <CommandMenu.Separator alwaysRender />}
                  <CommandMenu.Group heading={t(`common.searchbar.${type}`)} />
                  {parsedResults[type].map((result, index) => (
                    <CommandMenu.Item
                      key={`${result.type}-${index}`}
                      onSelect={onSelectItem}
                      value={result.link}
                    >
                      {result.type !== 'view-more' ? (
                        result.label
                      ) : (
                        <Link to={result.link}>
                          {t(`common.searchbar.viewMore`, {
                            count: result.count,
                          })}
                        </Link>
                      )}
                    </CommandMenu.Item>
                  ))}
                </>
              );
            })}
            {}
          </CommandMenu.List>
        </CommandMenu>
      </CommandMenu.Dialog>
    </>
  );
};

const SearchResultTypeToGetViewPathRecord: Record<
  SearchResultType,
  (id: string, searchTerm: string) => string
> = {
  'live course': (id) => routes.portal.liveCourse.item.path.full(id),
  'elearning course': (id) => routes.portal.elearningCourse.item.path.full(id),
  'learning path': (id) => routes.portal.learningPath.item.path.full(id),
};

const SearchResultTypeToGetListPathRecord: Record<
  SearchResultType,
  (searchTerm: string) => string
> = {
  'live course': (searchTerm) =>
    `${routes.portal.catalogue.liveCourses.path.full()}?${getSearchQueryString(
      searchTerm
    )}&pageId=${searchTerm}`,
  'learning path': (searchTerm) =>
    `${routes.portal.catalogue.learningPaths.path.full()}?${getSearchQueryString(
      searchTerm
    )}&pageId=${searchTerm}`,
  'elearning course': (searchTerm) =>
    `${routes.portal.catalogue.elearningCourses.path.full()}?${getSearchQueryString(
      searchTerm
    )}&pageId=${searchTerm}`,
};

const getSearchQueryString = (searchTerm: string): string => {
  const searchParams = new URLSearchParams();
  const queryFilter = new QueryFilter();
  queryFilter.add({
    field: 'search',
    operator: QueryOperator.Contains,
    value: searchTerm,
  });

  searchParams.set(
    'query',
    JSON.stringify({ filters: queryFilter.apiQueryFilter })
  );
  return searchParams.toString();
};
