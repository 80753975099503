import { createDraft, finishDraft, immerable } from 'immer';

import {
  AppQueryFilter,
  FieldMetadata,
  FieldType,
  QueryFilterCombinator,
  QueryOperator,
  isGeneralSelectFieldMetadata,
} from '@nl-lms/common/shared';

import {
  BooleanValueOption,
  DateValueOption,
  DatetimeValueOption,
  FieldTypeToFilterBarActiveFilterType,
  FilterBarActiveFilter,
  FilterBarActiveFilterType,
  FilterBarFilteringState,
  NumberValueOption,
  SelectValueOption,
  StringValueOption,
} from '../FilterBar.types';
import {
  FilterBarFilterComponent,
  FilterBarFilterComponentType,
  FilterBarFiltersStore,
} from '../FilterBarFilters/FilterBarFiltersStore';

export const FilterComponentTypeToActiveFilterType: Record<
  FilterBarFilterComponentType,
  FilterBarActiveFilterType
> = {
  SelectFilterComponent: 'SelectField',
  DateFilterComponent: 'DateField',
  DatetimeFilterComponent: 'DatetimeField',
  StringFilterComponent: 'StringField',
  NumberFilterComponent: 'NumberField',
  BooleanFilterComponent: 'BooleanField',
};

/**
 * Most of the interaction logic for the filter goes here
 * This is mostly because of two reasons
 * - It's easier to understand what's happening by just going through each class method
 *   instead of trying to figure out the flow in a page full of hooks
 * - It's easier to test without having to deal with component rendering
 *
 * The store is responsible for keeping track of the actual filtering flow in between
 * various user interactions (options select, filter badge click, etc.)
 * For this to work we make use of 3 main properties in the store:
 *  - filteringState - It tells you what options should be displayed
 *  - activeFilter - The filter that we are "operating" with. And by "operating" I mean
 *    you can either "edit the filter" or you can "create a new filter"
 *  - filtersStore - How the final filter values are stored and methods to interact with them
 */
export class FilterBarStore {
  [immerable] = true;
  filteringState: FilterBarFilteringState;
  activeFilter: FilterBarActiveFilter;
  filtersStore: FilterBarFiltersStore;
  fields: FieldMetadata[];
  inputValue: string;
  isMenuOpen: boolean;
  onChangeFilters: (v: AppQueryFilter) => void;
  activeOptionId: string;

  constructor(props: {
    initialFilters?: AppQueryFilter;
    fields: FieldMetadata[];
    onChangeFilters: (v: AppQueryFilter) => void;
  }) {
    this.filteringState = FilterBarFilteringState.NotEditing;
    this.fields = props.fields;
    this.filtersStore = new FilterBarFiltersStore({
      initialFilters: props.initialFilters,
      fields: props.fields,
    });
    this.onChangeFilters = props.onChangeFilters;
    this.isMenuOpen = false;
    this.inputValue = '';
    // @ts-ignore
    this.activeOptionId = null;
  }

  get isFilterBarActiveFilterValid(): boolean {
    return (
      !!this.activeFilter &&
      !!this.activeFilter.field &&
      !!this.activeFilter.value &&
      !!this.activeFilter.operator
    );
  }

  updateOnChange(val): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.onChangeFilters = val;
    return finishDraft(draft);
  }

  selectField(field: FieldMetadata): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    // @ts-ignore
    draft.activeFilter = {
      field,
      type: FieldTypeToFilterBarActiveFilterType[field.type],
      operator: null,
      value: null,
    } as FilterBarActiveFilter;
    draft.filteringState = FilterBarFilteringState.EditingFilterValue;
    draft.inputValue = '';
    if (isGeneralSelectFieldMetadata(field)) {
      draft.activeFilter.operator = QueryOperator.Includes;
    }
    if (field.type === FieldType.date) {
      draft.activeFilter.operator = QueryOperator.GreaterThanEqual;
    }
    if (field.type === FieldType.datetime) {
      draft.activeFilter.operator = QueryOperator.GreaterThanEqual;
    }
    return finishDraft(draft);
  }

  selectOperator(operator: QueryOperator): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.activeFilter.operator = operator;
    if (draft.activeFilter.index) {
      return this.completeFilteringFlow(draft.activeFilter);
    }
    draft.filteringState = FilterBarFilteringState.EditingFilterValue;
    draft.inputValue = '';
    return finishDraft(draft);
  }

  selectValue(
    option:
      | StringValueOption
      | NumberValueOption
      | DateValueOption
      | DatetimeValueOption
      | BooleanValueOption
  ): FilterBarStore {
    const { operator, value, field } = option;
    // @ts-ignore
    const activeFilter: FilterBarActiveFilter = {
      ...this.activeFilter,
      operator,
      type: FieldTypeToFilterBarActiveFilterType[field.type],
      value,
      field,
    };
    return this.completeFilteringFlow(activeFilter);
  }

  selectSelectValueOption(
    option: SelectValueOption,
    preventSubmit: boolean = false
  ): FilterBarStore {
    const { field, value, operator } = option;

    const currentValue = (this.activeFilter?.value || []) as
      | string[]
      | number[];
    // @ts-ignore
    const isDeselect = currentValue.includes(value);

    const newValue = isDeselect
      ? // @ts-ignore
        currentValue.filter((v) => v !== value)
      : [...currentValue, value];

    const activeFilter = {
      ...this.activeFilter,
      operator,
      type: FieldTypeToFilterBarActiveFilterType[field.type],
      value: newValue,
      field: { ...field },
    } as FilterBarActiveFilter;

    if (!preventSubmit) return this.completeFilteringFlow(activeFilter);

    const draft = createDraft<FilterBarStore>(this);
    draft.filtersStore = this.upsertFilter(activeFilter);
    const index = activeFilter.index || draft.filtersStore.filters.length - 2;
    draft.activeFilter = {
      ...activeFilter,
      index,
    };
    draft.filteringState = FilterBarFilteringState.EditingFilterValue;
    if (newValue.length)
      this.onChangeFilters(draft.filtersStore.appQueryFilter);

    return finishDraft(draft);
  }

  selectEditOperator(): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.filteringState = FilterBarFilteringState.EditingFilterOperator;
    return finishDraft(draft);
  }

  selectEditValue(): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.filteringState = FilterBarFilteringState.EditingFilterValue;
    return finishDraft(draft);
  }

  // selectGroupFilter(): FilterBarStore {}

  removeFilter(filterIndex): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.filtersStore = this.filtersStore.removeFilter(filterIndex);
    draft.isMenuOpen = false;
    draft.inputValue = '';
    this.onChangeFilters(draft.filtersStore.appQueryFilter);
    return finishDraft(draft);
  }

  updateFields(fields: FieldMetadata[]): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.fields = fields;
    draft.filtersStore = this.filtersStore.updateFields(fields);
    return finishDraft(draft);
  }

  upsertFilter(activeFilter: FilterBarActiveFilter): FilterBarFiltersStore {
    if (activeFilter.index) {
      return this.filtersStore.updateFilter(activeFilter);
    } else {
      return this.filtersStore.addFilter(activeFilter);
    }
  }

  groupFilters(start: number, end: number) {
    const draft = createDraft<FilterBarStore>(this);
    draft.filtersStore = this.filtersStore.groupFilters(start, end);
    return finishDraft(draft);
  }

  addFilters(
    filters: FilterBarActiveFilter[],
    combinator: QueryFilterCombinator
  ) {
    const draft = createDraft<FilterBarStore>(this);
    const startIndex =
      this.filtersStore.filters.length === 2
        ? 1
        : this.filtersStore.filters.length;
    filters.forEach((filter, index) => {
      if (index === 0) {
        draft.filtersStore = this.filtersStore.addFilter(filter);
      } else {
        draft.filtersStore = draft.filtersStore.addFilter(filter, combinator);
      }
    });
    const endIndex = draft.filtersStore.filters.length - 1;
    draft.filtersStore = draft.filtersStore.groupFilters(startIndex, endIndex);

    this.onChangeFilters(draft.filtersStore.appQueryFilter);
    draft.inputValue = '';
    draft.isMenuOpen = false;
    draft.filteringState = FilterBarFilteringState.NotEditing;
    // @ts-ignore
    draft.activeFilter = null;
    return finishDraft(draft);
  }

  completeFilteringFlow(activeFilter?: FilterBarActiveFilter): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    if (activeFilter) {
      draft.filtersStore = this.upsertFilter(activeFilter);
      this.onChangeFilters(draft.filtersStore.appQueryFilter);
    }
    draft.inputValue = '';
    draft.isMenuOpen = false;
    draft.filteringState = FilterBarFilteringState.NotEditing;
    // @ts-ignore
    draft.activeFilter = null;
    return finishDraft(draft);
  }

  changeInputValue(inputValue: string): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.inputValue = inputValue;
    return finishDraft(draft);
  }

  hideMenu(): FilterBarStore {
    if (this.isFilterBarActiveFilterValid) {
      return this.completeFilteringFlow();
    }
    const draft = createDraft<FilterBarStore>(this);
    draft.isMenuOpen = false;
    return finishDraft(draft);
  }

  showMenu(): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.isMenuOpen = true;
    return finishDraft(draft);
  }

  toggleMenu(isVisible: boolean): FilterBarStore {
    if (!isVisible && this.isFilterBarActiveFilterValid) {
      return this.completeFilteringFlow();
    }
    const draft = createDraft<FilterBarStore>(this);
    draft.isMenuOpen = isVisible;
    return finishDraft(draft);
  }

  reset(): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.inputValue = '';
    draft.isMenuOpen = false;
    // @ts-ignore
    draft.activeFilter = null;
    draft.filteringState = FilterBarFilteringState.NotEditing;
    draft.filtersStore = this.filtersStore.reset();
    this.onChangeFilters(draft.filtersStore.appQueryFilter);
    return finishDraft(draft);
  }

  selectFilter(filter: FilterBarFilterComponent): FilterBarStore {
    const activeFilter = {
      index: filter.index,
      value: filter.value,
      field: filter.field,
      operator: filter.operator,
      type: FilterComponentTypeToActiveFilterType[filter.type],
    } as FilterBarActiveFilter;
    const draft = createDraft<FilterBarStore>(this);
    draft.activeFilter = activeFilter;
    draft.filteringState = FilterBarFilteringState.EditingFilter;
    draft.isMenuOpen = true;
    // @ts-ignore
    draft.inputValue =
      activeFilter.field.type === FieldType.string ||
      activeFilter.field.type === FieldType.number
        ? activeFilter.value
        : '';
    return finishDraft(draft);
  }

  changeDefaultCombinator(combinator: QueryFilterCombinator): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.filtersStore = this.filtersStore.changeCombinator(combinator);
    return finishDraft(draft);
  }

  toggleCombinator(combinatorIndex: number): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.filtersStore = draft.filtersStore.toggleCombinator(combinatorIndex);
    this.onChangeFilters(draft.filtersStore.appQueryFilter);
    return finishDraft(draft);
  }

  setActiveOptionId(id: string): FilterBarStore {
    const draft = createDraft<FilterBarStore>(this);
    draft.activeOptionId = id;
    return finishDraft(draft);
  }
}
