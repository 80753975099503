import React, { useCallback, useMemo, useState } from 'react';

import { AppQuery } from '@nl-lms/common/shared';
import { MultiSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { prettyDate } from '@nl-lms/ui/utils';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi, useApi } from '../../../_common/services/api';

const { useListLiveSessionsQuery } = adminApi;

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

export const AdminLiveSessionSelect = (props) => {
  const {
    onChange,
    name,
    filters,
    returnEntireItemOnChange = false,
    initialSelectedItems = [],
    selectedItems = [],
  } = props;
  const api = useApi();

  const [inputValue, setInputValue] = useState('');

  const query: AppQuery = useMemo(() => {
    return {
      search: inputValue,
      filters,
    };
  }, [filters, inputValue]);

  const { data, isLoading, error } = useListLiveSessionsQuery(query);
  useQueryErrorHandler({ error });

  const options = useMemo(() => {
    if (!data) return [];
    const toDate = (value) => (value ? prettyDate(new Date(value)) : '-');
    return data?.rows.map((l) => ({
      value: l.id,
      label: `${l.name} • ${C.LEARNING_TYPES[l.type]} • ${toDate(l.startDate)}`,
      learner: l,
    }));
  }, [data]);

  return (
    <MultiSelect
      name={name}
      placeholder="Search sessions"
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      returnEntireItemOnChange={returnEntireItemOnChange}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={initialSelectedItems}
    />
  );
};
