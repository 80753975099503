import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  AddIcon,
  Box,
  Button,
  Checkbox,
  Draggable,
  FormField,
  Icon,
  IconButton,
  Input,
  Separator,
  SwitchInput,
  Typography,
} from '@nl-lms/ui/components';
import { TextEditorWithFileUpload } from '@nl-lms/web/_common/modules/TextEditorWithFileUpload';
import { adminApi } from '@nl-lms/web/_common/services/api';

import { AdminChecklistItemSingleSelect } from '../AdminChecklistItemSelect';
import './AdminChecklistEditFormSideModal.scss';

const ChecklistFormItem = (props) => {
  const {
    order,
    title,
    description,
    onMoveItem,
    actions,
    index,
    mandatory,
    onChangeMandatory,
    isSubitem,
    onChangeIsSubitem,
  } = props;
  return (
    <Draggable type="checklist-item" index={index} onDrop={onMoveItem} asChild>
      <div
        className={`checklist-form-item ${
          isSubitem ? 'checklist-form-item--subitem' : ''
        }`}
      >
        <div className="checklist-form-item__content">
          {isSubitem ? (
            <Typography.h4>{`${(order || index) + 1}. ${title}`}</Typography.h4>
          ) : (
            <Typography.h3>{`${(order || index) + 1}. ${title}`}</Typography.h3>
          )}

          {description && (
            <div className="checklist-form-item__description">
              <Typography.h4>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography.h4>
            </div>
          )}
          <div className="checklist-form-item__checkboxes">
            <Checkbox
              label="Mandatory for completion"
              name={`checklist-form-item-mandatory-${index}`}
              className="checklist-form-item__checkbox"
              checked={mandatory}
              onChange={() => onChangeMandatory(order || index)}
            />
            {(order || index) !== 0 ? (
              <Checkbox
                label="Is sub-item"
                name={`checklist-form-item-subitem-${index}`}
                className="checklist-form-item__checkbox"
                checked={isSubitem}
                onChange={() => onChangeIsSubitem(order || index)}
              />
            ) : null}
          </div>
        </div>
        <Draggable.HoverActions asChild>
          <div className="checklist-form-item__actions">
            <Draggable.DragButton />
            {actions.map((action) => (
              <IconButton
                label={action.name}
                onClick={() => action?.handler({ order: order || index })}
              >
                <action.Icon />
              </IconButton>
            ))}
          </div>
        </Draggable.HoverActions>
      </div>
    </Draggable>
  );
};

const AdminChecklistEditFormCreateItem = ({ name, onChange, currentItem }) => (
  <div className="checklist-form-item__create-form">
    <Box>
      <FormField label="Title" required>
        <Input
          name="title"
          placeholder="New Item title..."
          onChange={(e) => {
            onChange({ ...currentItem, title: e?.target?.value });
          }}
          value={currentItem?.title}
        />
      </FormField>
    </Box>
    <Box>
      <FormField label="Description">
        <TextEditorWithFileUpload
          onChange={(value) => {
            onChange({ ...currentItem, description: value });
          }}
          value={currentItem?.description}
        />
      </FormField>
    </Box>
  </div>
);

export const AdminChecklistEditFormItemsInput = ({
  onChange,
  initialItems = [],
  itemAddMode = ItemAddMode.ADD,
}) => {
  const [items, setItems] = useState(
    // @ts-ignore
    _.sortBy(initialItems, (item) => item.order)
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [clearSelection, setClearSelection] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const onChangeItem = useCallback((item) => {
    if (!item) return;
    setSelectedItem(item?.item);
  }, []);
  const onChangeNewItem = useCallback((newItem) => {
    if (!newItem || _.isEmpty(newItem)) return;
    setNewItem(newItem);
  }, []);

  useEffect(() => {
    const parsedItems = items.map((item, index) => {
      let parentChecklistItemId = null;
      // @ts-ignore
      if (item?.parentChecklistItemId && !item?.isSubitem) {
        // @ts-ignore
        parentChecklistItemId = item?.parentChecklistItemId;
        // @ts-ignore
      } else if (item?.isSubitem) {
        for (let i = index - 1; i >= 0; i--) {
          // @ts-ignore
          if (!items[i]?.isSubitem) {
            // @ts-ignore
            parentChecklistItemId = items[i]?.id;
            break;
          }
        }
      }
      return {
        // @ts-ignore
        ...item,
        // @ts-ignore
        mandatory: !item?.mandatory ? false : true,
        parentChecklistItemId,
        isSubitem: !!parentChecklistItemId,
      };
    });
    onChange(parsedItems);
  }, [items]);

  const onAddItem = useCallback(() => {
    if (!selectedItem) return;
    // @ts-ignore
    if (items.map((item) => item.id).includes(selectedItem.id)) return;
    setItems([
      // @ts-ignore
      ...items,
      // @ts-ignore
      {
        // @ts-ignore
        ...selectedItem,
        order: items.length,
      },
    ]);
    setClearSelection(true);
    setSelectedItem(null);
  }, [selectedItem, items]);

  const { useCreateChecklistItemMutation } = adminApi;
  const [createItem] = useCreateChecklistItemMutation();

  const onCreateItem = useCallback(async () => {
    // @ts-ignore
    let parseNewItem = { ...newItem };
    let span = document.createElement('span');
    // @ts-ignore
    span.innerHTML = newItem?.description;
    if (!(span.textContent || span.innerText)) {
      parseNewItem = { ...parseNewItem, description: '' };
      setNewItem(parseNewItem);
    }
    // @ts-ignore
    const { data: newItemData } = await createItem(parseNewItem);
    if (newItemData) {
      setItems([
        // @ts-ignore
        ...items,
        // @ts-ignore
        {
          // @ts-ignore
          ..._.omit(newItemData, ['createdAt', 'updatedAt']),
          order: items.length,
        },
      ]);
      setNewItem(null);
    }
  }, [newItem]);

  const onToggleRequired = useCallback(
    (itemIndex) => {
      const i = items[itemIndex];
      if (!i) return;
      const clone = _.clone(items);
      // @ts-ignore
      clone[itemIndex] = { ...i, mandatory: !i.mandatory };
      setItems(clone);
    },
    [items]
  );

  const onToggleIsSubitem = useCallback(
    (itemIndex) => {
      const i = items[itemIndex];
      if (!i) return;
      const clone = _.clone(items);
      // @ts-ignore
      let newItem = { ...i, isSubitem: !i.isSubitem };
      if (!newItem?.isSubitem && !!newItem?.parentChecklistItemId) {
        newItem = { ...newItem, parentChecklistItemId: null };
      }
      // @ts-ignore
      clone[itemIndex] = newItem;
      setItems(clone);
    },
    [items]
  );

  const onMoveItem = useCallback(
    (from: number, to: number) => {
      let newItems = [...items];
      const [movedItem] = newItems.splice(from, 1);
      newItems.splice(to, 0, movedItem);
      // @ts-ignore
      newItems[0].isSubitem = false;
      // @ts-ignore
      newItems[0].parentChecklistItemId = null;
      // @ts-ignore
      newItems = newItems.map((i, index) => ({ ...i, order: index }));
      setItems(newItems);
    },
    [items]
  );

  const onRemoveItem = useCallback(
    (row) => {
      const iIndex = row?.order;
      const i = items[iIndex];
      if (!i) return;
      const clone = _.clone(items);
      clone.splice(iIndex, 1);
      // @ts-ignore
      const newItems = clone.map((i, index) => ({ ...i, order: index }));
      if (!!newItems[0]?.isSubitem) {
        newItems[0] = {
          ...newItems[0],
          isSubitem: false,
        };
      }
      // @ts-ignore
      setItems(newItems);
    },
    [items]
  );

  useEffect(() => {
    if (selectedItem) {
      setClearSelection(false);
    }
  }, [selectedItem]);

  return (
    <>
      {itemAddMode === ItemAddMode.ADD ? (
        <>
          <AdminChecklistItemSingleSelect
            name="checklist-item-select"
            onChange={onChangeItem}
            returnEntireItemOnChange
            isClearable
            clearSelection={clearSelection}
            // @ts-ignore
            excludedItems={items?.map((item) => item.id)}
            initialSelectedItem={{
              // @ts-ignore
              value: selectedItem?.id,
              // @ts-ignore
              label: selectedItem?.title,
            }}
          />
          <Button
            icon={<AddIcon />}
            label="Add Item"
            regular
            onClick={onAddItem}
            disabled={!selectedItem}
            className="checklist-form-item__add-button"
          />
        </>
      ) : (
        <>
          <AdminChecklistEditFormCreateItem
            name="checklist-item-create"
            onChange={onChangeNewItem}
            currentItem={newItem}
          />
          <Button
            icon={<AddIcon />}
            label="Create & Add Item"
            regular
            onClick={onCreateItem}
            // @ts-ignore
            disabled={!newItem || !newItem?.title}
            className="checklist-form-item__add-button"
          />
        </>
      )}
      <Separator marginTop={10} />
      <div className="checklist-form-item__items-list">
        {items.length
          ? items?.map((item, index) => (
              <ChecklistFormItem
                // @ts-ignore
                title={item?.title}
                // @ts-ignore
                description={item?.description}
                // @ts-ignore
                order={item?.order}
                onMoveItem={onMoveItem}
                index={index}
                // @ts-ignore
                mandatory={!!item?.mandatory}
                onChangeMandatory={onToggleRequired}
                // @ts-ignore
                isSubitem={!!(item?.isSubitem || item?.parentChecklistItemId)}
                onChangeIsSubitem={onToggleIsSubitem}
                actions={[
                  {
                    name: 'Remove',
                    handler: onRemoveItem,
                    Icon: Icon.DeleteIcon,
                  },
                ]}
              />
            ))
          : null}
      </div>
    </>
  );
};

enum ItemAddMode {
  ADD = 'add',
  CREATE = 'create',
}

export const ChecklistItemsSection = ({ control, checklist, getValues }) => {
  const [itemAddMode, setItemAddMode] = useState(ItemAddMode.ADD);
  return (
    <>
      <Typography.h2>Content</Typography.h2>
      <Box>
        <SwitchInput
          className="checklist-form-item__items-switch"
          name="checklist-item-add-switch"
          options={[
            { label: 'select item', value: ItemAddMode.ADD },
            { label: 'create new item', value: ItemAddMode.CREATE },
          ]}
          initialActiveOption={itemAddMode}
          onChange={(value) => setItemAddMode(value)}
        />
      </Box>
      <Box>
        <FormField
          // @ts-ignore
          label={itemAddMode === ItemAddMode.ADD ? 'Add Items' : null}
        >
          <Controller
            name="items"
            control={control}
            render={({ field }) => (
              <AdminChecklistEditFormItemsInput
                {...field}
                initialItems={
                  getValues('items') ||
                  checklist.items ||
                  checklist.checklistItems
                }
                itemAddMode={itemAddMode}
              />
            )}
          />
        </FormField>
      </Box>
    </>
  );
};
