import { useAbility } from '@casl/react';
import React, { useCallback, useMemo } from 'react';

import {
  FieldMetadata,
  FieldType,
  QueryFilter,
  QueryOperator,
} from '@nl-lms/common/shared';
import { LiveSessionLearner } from '@nl-lms/sdk/backend';
import {
  Button,
  ErrorPlaceholder,
  FloatingMenu,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  StatusTag,
  TableAction,
  TableColumn,
  TableWithFullPagination,
  Typography,
  TypographyProps,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { AbilityContext } from '../../../../Can';
import { useTableAction } from '../../../../_common/hooks';
import { authStore } from '../../../../_common/modules/Auth/auth';
import { adminApi } from '../../../../_common/services/api';
import { routes } from '../../../../lib/routes';
import {
  fetchAndMapLearners,
  mapAndLoadConstantsForSelect,
} from '../../../_common/utils/fetchEntitiesForSelectMethods';
import { useDownloadReport } from '../../AdminAnalytics/useDownloadReport';
import { ExtendableListTable } from '../../types';
import { AdminLiveSessionLearnerCopyModal } from '../AdminLiveSessionLearnerCopyModal/AdminLiveSessionLearnerCopyModal';
import { AdminLiveSessionUpdateLearnerStatusModal } from './AdminLiveSessionUpdateLearnerStatusModal';

const {
  useListLiveSessionLearnersQuery,
  useRemoveLiveSessionLearnersMutation,
} = adminApi;

export const AdminLiveSessionLearnerListTable = ({
  columnFilter,
  rowActions: _rowActions = [],
  tableActions: _tableActions = [],
  columns: _columns = [],
  tableActionsFilter,
  rowActionsFilter,
  children = null,
}: ExtendableListTable & {
  sessionId?: string;
}) => {
  const {
    onChangePagination,
    sorting,
    disablePagination,
    onChangeSorting,
    query,
    filters,
  } = useListViewContext();
  const { data, isLoading, refetch, error } =
    useListLiveSessionLearnersQuery(query);
  // @ts-ignore
  const [rows, pagination] = useListViewTableData(data);
  const ability = useAbility(AbilityContext);

  const [removeLiveSessionLearners] = useRemoveLiveSessionLearnersMutation();

  const showUpdateLearnerStatusModal = useShowModal(
    AdminLiveSessionUpdateLearnerStatusModal
  );

  const showCopyLearnersModal = useShowModal(AdminLiveSessionLearnerCopyModal);

  const downloadReport = useDownloadReport();
  const exportLearners = useCallback(async (filtersOrListOfIds) => {
    const queryFilter = new QueryFilter();
    if (Array.isArray(filtersOrListOfIds)) {
      queryFilter.add({
        field: 'id',
        operator: QueryOperator.Includes,
        value: filtersOrListOfIds,
      });
    } else {
      queryFilter.add(filtersOrListOfIds);
    }

    await downloadReport({
      query: { filters: queryFilter.appQueryFilter },
      name: 'live_session_attendance',
    });
  }, []);

  const onClickExportLearners = useTableAction(exportLearners, {
    showConfirmation: false,
    successMessage: 'The live session learners have been exported successfully',
    baseFilters: query.filters,
  });

  const onClickRemoveLiveSessionLearners = useTableAction(
    removeLiveSessionLearners,
    {
      confirmationMessage:
        'Are you sure that you want to remove the selected learners ?',
      successMessage:
        'The live session learners have been removed successfully',
      baseFilters: query.filters,
    }
  );

  const onClickUpdateLearnerStatus = useTableAction(
    showUpdateLearnerStatusModal,
    {
      showConfirmation: false,
      baseFilters: query.filters,
      withRowCountProp: true,
    }
  );

  const onClickCopyLearners = useTableAction(showCopyLearnersModal, {
    showConfirmation: false,
    baseFilters: query.filters,
    withRowCountProp: true,
  });

  const baseColumns = [...AdminLiveSessionLearnerListTableColumns, ..._columns];
  const availableColumns = columnFilter
    ? columnFilter(baseColumns)
    : baseColumns;
  const [columns, onChangeColumns] = useListViewTableColumns(
    availableColumns,
    availableColumns.map((c) => c.accessor)
  );

  const baseTableActions: TableAction<LiveSessionLearner>[] = [
    {
      item: { name: 'Update Status', handler: onClickUpdateLearnerStatus },
      action: 'update_status',
      resource: 'live_session_learner',
    },
    {
      item: { name: 'Copy', handler: onClickCopyLearners },
      action: 'add',
      resource: 'live_session_learner',
    },
    {
      item: { name: 'Export', handler: onClickExportLearners },
      action: 'export',
      resource: 'live_session_learner',
    },
    {
      item: { name: 'Remove', handler: onClickRemoveLiveSessionLearners },
      action: 'delete',
      resource: 'live_session_learner',
    },
  ]
    .filter((itemWithScope) => {
      return ability.can(itemWithScope.action, itemWithScope.resource);
    })
    .map(({ item }) => item);

  const tableActions = useMemo(() => {
    let _actions = baseTableActions;
    if (_tableActions !== undefined) {
      _actions = [..._tableActions, ..._actions];
    }

    if (tableActionsFilter) return tableActionsFilter(_actions);
    return _actions;
  }, [_tableActions, baseTableActions, tableActionsFilter]);

  const rowActions = useMemo(() => {
    let _actions = [];
    // @ts-ignore
    if (_rowActions !== undefined) _actions = _rowActions;
    if (rowActionsFilter) return rowActionsFilter(_actions);
    return _actions;
  }, [_rowActions]);

  return (
    <TableWithFullPagination
      columns={columns}
      data={rows}
      isLoading={isLoading}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
      tableActions={tableActions}
      rowActions={rowActions}
      pagination={pagination}
      // @ts-ignore
      onChangePagination={disablePagination ? null : onChangePagination}
      sorting={sorting}
    >
      {children ? (
        children
      ) : error ? (
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          <ErrorPlaceholder.Title />
          <ErrorPlaceholder.Description>
            Something went wrong during the fetching process. Try to refresh the
            table data or if the problem persists please get in contact with us
            using the app help channel
          </ErrorPlaceholder.Description>
          <Button label="Reload" onClick={refetch} />
        </ErrorPlaceholder>
      ) : (
        <NoDataPlaceholder
          iconName={filters ? 'UserIcon' : 'UserPlusIcon'}
          color={
            filters
              ? NoDataPlaceholderColor.warning
              : NoDataPlaceholderColor.success
          }
          title={
            filters
              ? 'There are no learners that match your search'
              : 'There are no learners'
          }
          subtitle={
            filters
              ? 'You can try changing the active filters'
              : 'You can start adding learners'
          }
        />
      )}
    </TableWithFullPagination>
  );
};

const AdminLiveSessionLearnerListTableColumns: TableColumn[] = [
  {
    Header: 'Learner',
    accessor: 'learner.firstName',
    sortField: 'learnerName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return (
        <Sensitive>
          {ability.can('view', 'learner_page') ? (
            <Link
              to={routes.admin.learners.item.path.full(row?.original.learnerId)}
            >
              {row.original.learner.firstName} {row.original.learner.lastName}
            </Link>
          ) : (
            <span>
              {row.original.learner.firstName} {row.original.learner.lastName}
            </span>
          )}
        </Sensitive>
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'learner.email',
    sortField: 'learnerEmail',
    Cell: ({ row }) => <Sensitive>{row?.original?.learner?.email}</Sensitive>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortField: 'status',
    Cell: ({ row }) => (
      <StatusTag status={C.LEARNER_STATUS[row.original.status]} />
    ),
  },
  {
    Header: 'Session',
    accessor: 'trainingSession.name',
    sortField: 'trainingSessionName',
    Cell: ({ row }) => {
      const ability = useAbility(AbilityContext);
      return ability.can('view', 'live_session') ? (
        <Link
          to={routes.admin.manage.liveSessions.item.path.full(
            row.original.trainingSessionId
          )}
        >
          {row.original.trainingSession.name}
        </Link>
      ) : (
        row.original.trainingSession.name
      );
    },
  },
  {
    Header: 'Start Date',
    accessor: 'trainingSession.startDate',
    sortField: 'trainingSessionStartDate',
    Cell: ({ row }) =>
      row.original.trainingSession.startDate ? (
        <PrettyDate value={row.original.trainingSession.startDate} withTime />
      ) : (
        'No Start Date'
      ),
  },
  {
    Header: 'End Date',
    accessor: 'trainingSession.endDate',
    sortField: 'trainingSessionEndDate',
    Cell: ({ row }) =>
      row.original.trainingSession.endDate ? (
        <PrettyDate value={row.original.trainingSession.endDate} withTime />
      ) : (
        'No End Date'
      ),
  },
];

export const AdminLiveSessionLearnerListTableFilters = ({
  fieldNames = [
    'learner_id',
    'learner_email',
    'status',
    'training_session_name',
    'training_session_start_date',
    'training_session_end_date',
    'depth',
    'learner_customer_internal_id',
  ],
  showGroupByOptions = false,
  extraFields = [],
}: {
  fieldNames?: (typeof AdminLiveSessionLearnerListTableFiltersFields)[number]['name'][];
  showGroupByOptions?: boolean;
  extraFields?: FieldMetadata[];
}) => {
  const { filters, onChangeFilters } = useListViewContext();
  const fields = useMemo(
    () => [
      ...(AdminLiveSessionLearnerListTableFiltersFields.filter((f) =>
        fieldNames.includes(f.name)
      ) as FieldMetadata[]),
      ...extraFields,
    ],
    [fieldNames, extraFields]
  );

  const goToLiveSessionLearnersList = useRoutingAction({
    route: routes.admin.activity.liveSessionLearners.path.full(),
  });

  const goToLiveSessionsList = useRoutingAction({
    route: routes.admin.manage.liveSessions.path.full(),
  });

  const groupByOptions = useMemo(() => {
    if (!showGroupByOptions) return [];
    return [
      [
        {
          name: 'Live Session',
          sectionLabel: 'Group By',
          handler: goToLiveSessionsList,
        },
        {
          name: <FloatingMenu.CheckedOption label="Learner" isChecked={true} />,
          handler: goToLiveSessionLearnersList,
        },
      ],
    ];
  }, [showGroupByOptions]);

  return (
    <FilterBar
      id="learner-card"
      fields={fields.filter((f) => {
        if (f.name === 'depth') {
          return authStore.isOnlyManager;
        }

        return true;
      })}
      initialFilters={filters}
      settingsMenuOptions={groupByOptions}
      onChangeFilters={onChangeFilters}
    />
  );
};

const AdminLiveSessionLearnerListTableFiltersFields = [
  {
    name: 'learner_id',
    label: 'Learner',
    type: FieldType.select,
    loadOptions: fetchAndMapLearners,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'learner_customer_internal_id',
    label: 'Learner Internal ID',
    type: FieldType.string,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'depth',
    label: 'Learner Level',
    type: FieldType.number,
  },
  {
    name: 'learner_email',
    label: 'Email',
    type: FieldType.string,
    ValueComponent: ({ children }) => {
      return <Sensitive>{children}</Sensitive>;
    },
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.select,
    loadOptions: mapAndLoadConstantsForSelect(C.LEARNER_STATUS),
  },
  {
    name: 'training_session_name',
    label: 'Session',
    type: FieldType.string,
  },
  {
    name: 'training_session_start_date',
    label: 'Start Date',
    type: FieldType.datetime,
  },
  {
    name: 'training_session_end_date',
    label: 'End Date',
    type: FieldType.datetime,
  },
] as const;
