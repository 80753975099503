import * as Dialog from '@radix-ui/react-dialog';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import * as Icon from '../Icon';
import { Separator } from '../Separator/Separator';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './Modal.scss';

type ModalContextType = {
  isOpen: boolean;
  modalProps: Record<string, unknown>;
  setIsOpen: (isOpen: boolean) => void;
  setModalProps: (modalProps: Record<string, unknown>) => void;
  resolveResultRef: React.MutableRefObject<
    ((value: unknown) => void) | undefined
  >;
  resultRef: React.MutableRefObject<unknown | undefined>;
};

export const createModalContext = () =>
  createContext<ModalContextType>({} as ModalContextType);
export const ModalContext = createModalContext();

type ModalProviderProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  defaultOpened?: boolean;
  Context?: typeof ModalContext;
  onOpenChange?: (open: boolean) => void;
  onClose?: (value: unknown) => void;
};

const ModalProvider = (props: ModalProviderProps) => {
  const {
    children,
    defaultOpened = false,
    isOpen: _isOpen = undefined,
    Context = ModalContext,
    onOpenChange: _onOpenChange = undefined,
    onClose,
  } = props;
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const [modalProps, setModalProps] = useState({});
  const resultRef = useRef<unknown | undefined>(undefined);
  const resolveResultRef = useRef<((value: unknown) => void) | undefined>(
    undefined
  );
  const open = _isOpen !== undefined ? _isOpen : isOpen;

  const onOpenChange = useCallback(
    (openState) => {
      if (!openState && onClose) {
        onClose(resultRef.current);
        resultRef.current = undefined;
      }
      if (!openState) {
        setModalProps({});
      }
      if (_onOpenChange) _onOpenChange(openState);
      setIsOpen(openState);
    },
    [onClose, _onOpenChange]
  );

  return (
    <Context.Provider
      value={{
        isOpen: open,
        modalProps,
        setModalProps,
        setIsOpen: onOpenChange,
        resultRef,
        resolveResultRef,
      }}
    >
      <Dialog.Root onOpenChange={onOpenChange} open={open}>
        {children}
      </Dialog.Root>
    </Context.Provider>
  );
};

export function useModal<T = unknown, K = Record<string, unknown>>(
  modalContext = ModalContext
): {
  show: (props: K) => Promise<T>;
  hide: (value?: T) => void;
  isOpen: boolean;
} {
  const { setIsOpen, isOpen, setModalProps, resultRef, resolveResultRef } =
    useContext(modalContext);

  const show = useCallback(async (props: K): Promise<T> => {
    return new Promise((resolve) => {
      if (props) {
        setModalProps(props);
      }
      resolveResultRef.current = resolve;
      setIsOpen(true);
    });
  }, []);

  const hide = useCallback((result?: T) => {
    if (resolveResultRef.current) resolveResultRef.current(result);
    resolveResultRef.current = undefined;
    resultRef.current = result;
    setIsOpen(false);
  }, []);

  return { show, hide, isOpen };
}

const ModalTrigger = React.forwardRef<
  React.ElementRef<typeof Dialog.Trigger>,
  React.ComponentPropsWithoutRef<typeof Dialog.Trigger>
>(({ asChild = true, ...props }, ref) => {
  return <Dialog.Trigger asChild ref={ref} {...props} />;
});
ModalTrigger.displayName = 'ModalTrigger';

export const ModalContent = React.forwardRef<
  HTMLDivElement,
  TidComponent<{
    children: React.ReactNode;
    container?: Dialog.PortalProps['container'];
    scrollable?: boolean;
    small?: boolean;
    className?: string;
    style?: React.CSSProperties;
    lock?: boolean;
    Context?: typeof ModalContext;
  }>
>(
  (
    {
      children,
      scrollable = false,
      lock = false,
      small = false,
      className = '',
      style = {},
      Context = ModalContext,
      ...rest
    },
    forwardedRef
  ) => {
    const { modalProps } = useContext(Context);
    const commonProps = useTestProps(rest);
    const contentClassNameProps = useClassNameProps(
      'radix-modal__content',
      scrollable ? 'radix-modal__content--scrollable' : null,
      small ? 'radix-modal__content--small' : null,
      className
    );
    const { setIsOpen, isOpen } = useContext(Context);

    const childrenWithProps = useMemo(() => {
      if (React.Children.count(children) === 1 && modalProps) {
        return React.Children.map(children, (child) => {
          if (React.isValidElement(child))
            return React.cloneElement(child, modalProps);
          return child;
        });
      }
      return children;
    }, [modalProps, children]);

    // Prevent previous modal from closing when pressing escape
    const onEscapeKeyDown = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isOpen && !lock) setIsOpen(false);
        return false;
      },
      [isOpen, lock]
    );

    const onPointerDownOutside = useCallback(
      (e) => {
        if (lock) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      },
      [lock]
    );

    return (
      <Dialog.Portal {...rest}>
        <Dialog.Overlay className="radix-modal__overlay" />
        <Dialog.Content
          onEscapeKeyDown={onEscapeKeyDown}
          ref={forwardedRef}
          onPointerDownOutside={onPointerDownOutside}
          style={style}
          {...contentClassNameProps}
          {...commonProps}
        >
          {childrenWithProps}
        </Dialog.Content>
      </Dialog.Portal>
    );
  }
);

export const ModalHeader = ({
  children,
  withSeparator = true,
  withCloseAction = true,
  ...props
}) => {
  const commonProps = useTestProps(props);
  return (
    <div className="radix-modal__header" {...commonProps}>
      {children}
      {withCloseAction ? (
        <Dialog.Close asChild>
          <button title="Close" className="radix-modal__header-close-button">
            <Icon.CloseIcon />
          </button>
        </Dialog.Close>
      ) : null}
      <Separator
        transparent={!withSeparator}
        marginTop={20}
        marginBottom={20}
      />
    </div>
  );
};

export const ModalTitle = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <Dialog.Title asChild {...commonProps}>
      <Typography.h2>{children}</Typography.h2>
    </Dialog.Title>
  );
};

export const ModalActions = ({ children, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <div className="radix-modal__actions" {...commonProps}>
      <Separator marginTop={20} marginBottom={20} />
      <div className="radix-modal__actions-buttons">{children}</div>
    </div>
  );
};

export const ModalClose = ({ children, disabled = false, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <Dialog.Close asChild disabled={disabled} {...commonProps}>
      {children}
    </Dialog.Close>
  );
};

export const ModalError = ({ children, ...props }) => {
  const commonProps = useTestProps(props);

  return (
    <div className="radix-modal__error" {...commonProps}>
      {children}
    </div>
  );
};

export const Modal = Object.assign(ModalContent, {
  Provider: ModalProvider,
  Title: ModalTitle,
  Header: ModalHeader,
  Content: ModalContent,
  Trigger: ModalTrigger,
  Actions: ModalActions,
  Close: ModalClose,
  Error: ModalError,
});
