import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  DateTime,
  FormField,
  Modal,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { learningAssignmentsApi } from '../../../_common/services/api';

const {
  useUpdateLearningAssignmentInstancesMutation,
  useUpdateLearningAssignmentsMutation,
} = learningAssignmentsApi;

export const LearningAssignmentAndLearningAssignmentInstanceEditDueDateModal =
  ({ selection = null, editAssignment = true }) => {
    const { hide } = useModal();
    const [dueDate, setDueDate] = useState(null);
    const [
      updateLearningAssignmentInstances,
      { isLoading: loadingUpdateInstance, error: errorUpdateInstance },
    ] = useUpdateLearningAssignmentInstancesMutation();
    const [
      updateLearningAssignments,
      { isLoading: loadingUpdate, error: errorUpdate },
    ] = useUpdateLearningAssignmentsMutation();
    const onChangeDueDate = useCallback((e) => {
      setDueDate(e.target.value);
    }, []);
    const minDate = useMemo(() => new Date(), []);
    const onSubmit = useCallback(async () => {
      const mutateMethod = editAssignment
        ? updateLearningAssignments
        : updateLearningAssignmentInstances;
      const mutatePayload = editAssignment
        ? { type: 'fixed', value: dueDate }
        : dueDate;
      const result = await mutateMethod({
        // @ts-ignore
        ...selection,
        dueDate: mutatePayload,
      });
      if ('data' in result) {
        hide();
      }
    }, [dueDate, selection, editAssignment]);
    const error = useMemo(
      () => (editAssignment ? errorUpdate : errorUpdateInstance),
      [editAssignment]
    );
    const isLoading = useMemo(
      () => (editAssignment ? loadingUpdate : loadingUpdateInstance),
      [editAssignment]
    );

    return (
      <Modal.Content>
        <Modal.Header withSeparator={false}>
          <Modal.Title>Edit Due Date</Modal.Title>
        </Modal.Header>
        <FormField label="Due Date">
          <DateTime
            date={dueDate}
            onChange={onChangeDueDate}
            minDate={minDate}
          />
        </FormField>
        <Modal.Actions>
          <Modal.Error>{getMessageFromError(error)}</Modal.Error>
          <Button
            onClick={onSubmit}
            isLoading={isLoading}
            disabled={!dueDate}
            label="Submit"
          />
        </Modal.Actions>
      </Modal.Content>
    );
  };
