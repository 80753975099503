import React from 'react';

export const CloseIcon = ({
  strokeWidth = '2',
}: {
  strokeWidth?: '1' | '2';
}) => (
  <svg
    className="close-icon"
    style={{ minWidth: '9px', minHeight: '9px' }}
    viewBox="0 0 9 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 1.5L1.5 7.5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 1.5L7.5 7.5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
