import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { NotificationMessageBlock } from '@nl-lms/feature/notifications/sdk';
import { _ } from '@nl-lms/vendor';

import { MessageInputContext, MessageInputProps } from './types';

export const NotificationMessageContext = createContext<MessageInputContext>(
  {} as MessageInputContext
);

export const NotificationMessageEditorProvider = ({
  children,
  message,
  mentionOptions,
  onChange,
}: PropsWithChildren<MessageInputProps>) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] = useState<number | null>(null);
  const { onUndo, onRedo } = useHistory({
    value: message,
    onChange,
  });
  const onExpand = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const onMinimize = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const onAddBlock = useCallback(
    (block: NotificationMessageBlock, index?: number) => {
      const blockIndex = index !== undefined ? index : message.length;
      const newMessage = [...message];
      newMessage.splice(blockIndex, 0, block);
      onChange(newMessage);
    },
    [message]
  );

  const onRemoveBlock = useCallback(
    (blockIndex: number) => {
      onChange(message.filter((b, index) => index !== blockIndex));
    },
    [message]
  );

  const onMoveBlock = useCallback(
    (targetIndex: number, destinationIndex: number) => {
      const newMessage = [...message];
      const [movedBlock] = newMessage.splice(targetIndex, 1);
      newMessage.splice(destinationIndex, 0, movedBlock);
      onChange(newMessage);
    },
    [message]
  );

  const onEditBlock = useCallback(
    (blockIndex: number, propPath: string, propValue: unknown) => {
      if (!message[blockIndex]) {
        return;
      }
      const newMessage = [...message];
      _.set(newMessage[blockIndex], propPath, propValue);
      onChange(newMessage);
    },
    [message]
  );

  const onReorderBlocks = useCallback(
    (from: number, to: number) => {
      const copiedBlocks = [...message];
      const [movedBlock] = copiedBlocks.splice(from, 1);
      copiedBlocks.splice(to, 0, movedBlock);
      setActiveBlockIndex(to);
      onChange(copiedBlocks);
    },
    [message]
  );

  const onSelectBlock = useCallback((blockIndex: number | null) => {
    setActiveBlockIndex(blockIndex);
  }, []);

  const onChangeTitle = useCallback(
    (value) => {
      const titleBlockIndex = message.findIndex(
        (block) => block.type === 'title'
      );
      const newMessage = [...message];
      if (titleBlockIndex === -1) {
        newMessage.push({
          type: 'title',
          value: value,
          attributes: { translations: {} },
        });
      } else {
        newMessage[titleBlockIndex].value = value;
      }
      onChange(newMessage);
    },
    [message]
  );

  return (
    <NotificationMessageContext.Provider
      value={{
        message,
        isExpanded,
        mentionOptions,
        activeBlockIndex,
        onUndo,
        onRedo,
        onExpand,
        onMinimize,
        onChangeTitle,
        onAddBlock,
        onReorderBlocks,
        onRemoveBlock,
        onEditBlock,
        onMoveBlock,
        onSelectBlock,
      }}
    >
      {children}
    </NotificationMessageContext.Provider>
  );
};

type HistoryProps<T> = {
  value: T;
  onChange: (value: T) => void;
};

function useHistory<T>(props: HistoryProps<T>) {
  const { value, onChange } = props;
  const changes = useRef<T[]>([value]);
  const activeChangeIndex = useRef<number | null>(null);

  useEffect(() => {
    if (activeChangeIndex.current === null) {
      changes.current.push(value);
    } else if (changes.current[activeChangeIndex.current] !== value) {
      changes.current.slice(0, activeChangeIndex.current);
      changes.current.push(value);
      activeChangeIndex.current = null;
    }
  }, [value]);

  const onUndo = useCallback(() => {
    if (!changes.current.length) return;
    const currentActiveChangeIndex =
      activeChangeIndex.current || changes.current.length;
    const newActiveChangeIndex = currentActiveChangeIndex - 1;
    if (newActiveChangeIndex < 0 || !changes.current[newActiveChangeIndex])
      return;
    activeChangeIndex.current = newActiveChangeIndex;
    onChange(changes.current[newActiveChangeIndex]);
  }, [onChange]);

  const onRedo = useCallback(() => {
    if (!changes.current.length) return;
    const currentActiveChangeIndex =
      activeChangeIndex.current || changes.current.length;
    const newActiveChangeIndex = currentActiveChangeIndex + 1;
    if (
      newActiveChangeIndex >= changes.current.length ||
      !changes.current[newActiveChangeIndex]
    )
      return;
    activeChangeIndex.current = newActiveChangeIndex;
    onChange(changes.current[newActiveChangeIndex]);
  }, [onChange]);

  return {
    onUndo,
    onRedo,
  };
}
