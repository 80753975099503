import store from 'store';

import { getClients } from '@nl-lms/feature/automation/sdk';

import { apiBaseUrl } from './api';

const { raw: automationApiClient, rtk: automationRtkApiClient } = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export { automationApiClient };

export const automationApi = automationRtkApiClient;
automationApi.enhanceEndpoints<'Rule' | 'Action'>({
  endpoints: {
    listActions: { providesTags: [{ type: 'Action', id: 'LIST' }] },
    listRules: { providesTags: [{ type: 'Rule', id: 'LIST' }] },
    scheduleActions: {
      invalidatesTags: [{ type: 'Action', id: 'LIST' }],
    },
    removeActions: {
      invalidatesTags: [{ type: 'Action', id: 'LIST' }],
    },
    toggleRules: {
      invalidatesTags: [{ type: 'Rule', id: 'LIST' }],
    },
  },
});
