import { Controller } from 'react-hook-form';

import { Box, FormField, SwitchInput, Typography } from '@nl-lms/ui/components';

import { LearningAssignmentRulesConditionsFilterbar } from './LearningAssignmentRulesConditionsFilterBar';
import './LearningAssignmentRulesForm.scss';
import {
  AssignationRulesStartDateSelect,
  AssignationRulesTriggerTimesInput,
} from './LearningAssignmentRulesListForm';

export const LearningAssignmentRulesAdvancedForm = ({ control, register }) => {
  return (
    <FormField.Root style={{ paddingTop: '20px', paddingBottom: '0' }}>
      <FormField style={{ padding: '15px 0' }}>
        <FormField.Label spacing="s">
          <Typography.h3>Assignation Time</Typography.h3>
        </FormField.Label>
        <FormField.Description>
          This refers to the specific point in time when the assignment will be
          created. This can be either fixed - a specific date, or relative - a
          time interval/hour/weekdays after the conditions have been fulfilled.
        </FormField.Description>
        <Box flex={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography.h4
            style={{
              whiteSpace: 'nowrap',
              paddingRight: '10px',
            }}
          >
            Create assignation at
          </Typography.h4>
          <Controller
            name="startDateType"
            control={control}
            render={({ field }) => (
              <SwitchInput
                {...field}
                options={[
                  { label: 'Relative Date', value: 'relative' },
                  { label: 'Fixed Date', value: 'fixed' },
                ]}
                activeOption={field?.value}
              />
            )}
          />
        </Box>
      </FormField>
      <FormField noPadding>
        <AssignationRulesStartDateSelect control={control} />
      </FormField>
      <FormField.Root style={{ paddingBottom: 0 }}>
        <Box>
          <FormField.Label>
            <Typography.h3>Settings</Typography.h3>
          </FormField.Label>
          <AssignationRulesTriggerTimesInput
            control={control}
            register={register}
          />
        </Box>
      </FormField.Root>
      <Box>
        <FormField.Label>
          <Typography.h3>Conditions (If?)</Typography.h3>
        </FormField.Label>
        <FormField.Description>
          These are the conditions that will need to be fulfilled in order to
          create the assignation.
        </FormField.Description>
        <FormField>
          <Controller
            name="conditions"
            control={control}
            render={({ field }) => (
              <LearningAssignmentRulesConditionsFilterbar
                {...field}
                conditions={field?.value}
                disabled={false}
                isLoading={false}
              />
            )}
          />
        </FormField>
      </Box>
    </FormField.Root>
  );
};
