import {
  TimelineDayEvent,
  TimelineDayEventWithGridPosition,
} from './Timeline.types';

export const addDays = (baseDate: Date, days: number) => {
  const date = new Date(baseDate.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const subtractDays = (baseDate: Date, days: number) => {
  const date = new Date(baseDate.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

export function getAllDatesBetweenTwoDates(startDate: Date, stopDate: Date) {
  let dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
// @ts-ignore
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export function getMonday(d: Date) {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const formatDate = (date: Date) => date.toLocaleDateString('en-us');

/**
 *  Order events so that they will fit properly in the timeline UI
 *
 *  Context
 *  - The timeline UI does not have a Y axis. So we don't place them based on
 *  when it should take place in that day.
 *  - Some events might take place over multiple days.
 *  - The UI process the events as a list. Placing them from start (top left corner)
 *  to end (bottom right corner)
 *
 *  Given that we order each event by checking when it starts and when it ends and then
 *  we "continue on the same row" with an event that starts the day after the current event.
 *  AKA we pop an event from the array -> Check the grid column position -> Look for
 *  the next event that starts a day after the current event ends
 *
 */
export const orderEvents = (
  events: TimelineDayEvent[],
  dates: Date[]
): TimelineDayEventWithGridPosition[] => {
  const formattedDates = dates.map((d) => formatDate(d));
  const totalNumberOfDays = formattedDates.length;
  const eventsGroupedByDays: TimelineDayEventWithGridPosition[][] = [];
  if (dates.length < 2) return [];
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];
// @ts-ignore
  const validEventsWithGridPosition: TimelineDayEventWithGridPosition[] = events
    .map((ev) => {
      const startDateString = formatDate(ev.start);
      const endDateString = formatDate(ev.end);

      let startIndex = formattedDates.indexOf(startDateString);
      let endIndex = formattedDates.indexOf(endDateString);
      let eventStartDate = ev.start;
      let eventEndDate = ev.end;

      if (startIndex === -1 && endIndex > -1) {
        startIndex = 0;
        eventStartDate = startDate;
      }
      if (startIndex > -1 && endIndex === -1) {
        endIndex = formattedDates.length - 1;
        eventEndDate = endDate;
      }
      if (startIndex === -1 || endIndex === -1) return null;
      if (startIndex > endIndex) return null;
      const parsedEvent = {
        ...ev,
        columnStart: startIndex + 1,
        columnEnd: endIndex + 2,
        start: eventStartDate,
        endDate: eventEndDate,
      };
      if (!eventsGroupedByDays[startIndex])
        eventsGroupedByDays[startIndex] = [];
      eventsGroupedByDays[startIndex].push(parsedEvent);
      return parsedEvent;
    })
    .filter((ev) => !!ev);

  const orderedEvents: TimelineDayEventWithGridPosition[] = [];
  let activeDayIndex = 0;
  while (validEventsWithGridPosition.length) {
    const activeDate = formattedDates[activeDayIndex];
    const activeEventIndex = validEventsWithGridPosition.findIndex(
      (ev) => formatDate(ev.start) === activeDate
    );
    if (activeEventIndex === -1) {
      activeDayIndex =
        activeDayIndex + 1 === totalNumberOfDays ? 0 : activeDayIndex + 1;
      continue;
    }
    let activeEvent = validEventsWithGridPosition[activeEventIndex];
    activeDayIndex =
      activeEvent.columnEnd - 1 >= totalNumberOfDays
        ? 0
        : activeEvent.columnEnd - 1;
    orderedEvents.push(activeEvent);
    validEventsWithGridPosition.splice(activeEventIndex, 1);
  }

  return orderedEvents;
};
