import React, {
  MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { CloseIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './Input.scss';

type BaseProps = {
  required?: boolean;
  isResettable?: boolean;
  hasError?: boolean;
  className?: string;
  step?: string;
  returnEventOnChange?: boolean;
  value?: string | number;
  type?: string;
  defaultValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  autofocus?: boolean;
  name: string;
  isDebounced?: boolean;
  onBlur?: React.EventHandler<any>;
  onFocus?: React.EventHandler<any>;
  onKeyDown?: React.KeyboardEventHandler;
  style?: React.CSSProperties;
  min?: number | string;
  max?: number | string;
};

type ReturnSimpleValueProps = BaseProps & {
  returnEventOnChange: false;
  onChange?: (value: string) => void;
};

type ReturnEventProps = BaseProps & {
  returnEventOnChange?: false;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

type Props = TidComponent<ReturnEventProps | ReturnSimpleValueProps>;

type Ref = HTMLInputElement;

export const Input = forwardRef<Ref, Props>((props, _ref) => {
  const {
    required = false,
    isResettable = false,
    hasError = false,
    type = 'text',
    placeholder = null,
    isDebounced = false,
    onChange = null,
    autofocus = false,
    returnEventOnChange = true,
    ...rest
  } = props;
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('text-input', props);
  const elementClassNameProps = useClassNameProps(
    'text-input__element',
    hasError ? 'text-input__element--with-errors' : null
  );
  const localRef = useRef<HTMLInputElement>(null);
  const ref = (_ref || localRef) as MutableRefObject<HTMLInputElement>;
  const debouncedOnChange = useCallback(
    // @ts-ignore
    _.debounce((e) => onChange(e), 500),
    [onChange]
  );
  const getOnChangeValue = useCallback(
    (e) => {
      if (!returnEventOnChange) return e.target.value;
      if (isDebounced) return { target: { value: e.target.value } };
      return e;
    },
    [isDebounced, returnEventOnChange]
  );
  useEffect(() => {
    // @ts-ignore
    if (autofocus && ref?.current) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [autofocus]);
  const onChangeValue = useCallback(
    (e) => {
      if (onChange) {
        const value = getOnChangeValue(e);
        if (isDebounced) debouncedOnChange(value);
        else onChange(value);
      }
    },
    [isDebounced, onChange, debouncedOnChange, getOnChangeValue]
  );
  const onReset = useCallback(() => {
    onChangeValue({ target: { value: '' } });
    if (ref.current) {
      ref.current.value = '';
    }
  }, []);

  return (
    <div {...classNameProps} {...commonProps}>
      <input
        ref={ref}
        // @ts-ignore
        placeholder={placeholder}
        onChange={onChangeValue}
        required={required}
        type={type}
        {...rest}
        {...elementClassNameProps}
      />
      {isResettable && (
        <span className="text-input__reset" onClick={onReset}>
          <CloseIcon />
        </span>
      )}
    </div>
  );
});
