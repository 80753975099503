import _ from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Position } from 'reactflow';

import {
  LearningAssignment,
  LearningAssignmentLearningContentType,
} from '@nl-lms/common/feature/types';
import { LearningProgramDomainEventNames } from '@nl-lms/feature/learning-programs/sdk';
import {
  FlowGraphConnection,
  FlowGraphEdgeType,
  FlowGraphEntity,
  FlowGraphNodeType,
  FlowGraphNodeTypes,
  useConfirmationModal,
} from '@nl-lms/ui/components';

import { LearningProgramUpsertRulesBaseType } from '../learning-program/LearningProgramUpsertRulesSideModal';
import { ProgramRulesTemplateTypes } from './constants';
import {
  END_NODE,
  ProgramRule,
  START_NODE,
  edgeTypesMap,
  nodeTypesMap,
} from './types';
import {
  buildLearningProgramGraphNodesRuleByTemplate,
  buildOrReplaceLearningProgramRule,
  isProgramCompletionRule,
  isProgramFailedRule,
  isProgramRule,
  parseProgramDataToFlowGraph,
} from './utils';

export type LearningProgramRulesGraphContextProps = {
  assignments: (LearningAssignment & {
    content: {
      type: LearningAssignmentLearningContentType;
      id: string;
      name: string;
    };
  })[];
  rules?: ProgramRule[] | [];
  isConnectable?: boolean;
  learningProgramId: string;
  onDeleteNode?: ((id: string) => Promise<boolean>) | null;
  onNodeAdd?: ((nodeId: string) => void) | null;
  onChange?:
    | ((payload: LearningProgramUpsertRulesBaseType['connections']) => void)
    | null;
  onEditNode?: ((assignment: LearningAssignment) => void) | null;
  setIsValid?: ((isValid: boolean) => void) | null;
};

export type LearningProgramRulesGraphContextType = {
  connections: FlowGraphConnection[];
  entities: FlowGraphEntity[];
  unconnectedEntities: FlowGraphEntity[];
  assignments: (LearningAssignment & {
    content: {
      type: LearningAssignmentLearningContentType;
      id: string;
      name: string;
    };
  })[];
  rules?: ProgramRule[] | [];
  showConnectModal: boolean;
  edgeTypesMap: Record<FlowGraphEdgeType, ReactNode>;
  nodeTypesMap: Record<FlowGraphNodeType, ReactNode>;
  completionPaths?: { 'End-Completed': string[][]; 'End-Failed': string[][] };
  startingAssignmentIds?: string[];
  isConnectable?: boolean;
  highlightedEndNodePath?: string[][];
  learningProgramId: string;
  setShowConnectModal: (show: boolean) => void;
  showLegend: boolean;
  setShowLegend: (show: boolean) => void;
  editingRuleData:
    | ({
        index: number;
        conditionIndex: number;
      } & ProgramRule)
    | null;
  layout?: 'TB' | 'RL';
  highlightMode?: boolean;
  setHighlightedEndNodePath?: (path: string[][]) => void;
  setHighlightMode?: (isH: boolean) => void;
  setLayout?: (newLayout: string) => void;
  setEditingRuleData: (
    data:
      | ({
          index: number;
          conditionIndex: number;
        } & ProgramRule)
      | null
  ) => void;
  onDeleteEdge?: ({
    ruleIndex,
    conditionIndex,
  }: {
    ruleIndex: number;
    conditionIndex?: number;
  }) => void;
  onDeleteNode?: ((id: string, type: string) => void) | null;
  onChangeNodes: (nodes: any) => void;
  onChangeEdges: (edges: any) => void;
  onClick: (e: any) => void;
  onClickEdge: (index: number, edgeData: any) => void;
  onClickNode?: ((data: any, type: string) => void) | null;
  onConnect: (data: any) => void;
  onUpsertEdge?: ({
    template,
    entities,
    name,
    combinator,
    editingRuleData,
  }) => void;
  onDragNode?: (entity: FlowGraphEntity) => void;
  onDropNode?: (entity: FlowGraphEntity, position: Position) => void;
  draggingNode?: FlowGraphEntity | undefined;
  draggedNodes?: FlowGraphEntity[] | undefined;
};

type RulesPayloadBaseType =
  LearningProgramUpsertRulesBaseType['connections']['rules'];

export const LearningProgramRulesGraphContext =
  createContext<LearningProgramRulesGraphContextType>(
    {} as LearningProgramRulesGraphContextType
  );

export const LearningProgramRulesGraphProvider = (
  props: LearningProgramRulesGraphContextProps & { children: ReactNode }
) => {
  const [highlightedEndNodePath, setHighlightedEndNodePath] = useState<
    string[][] | null
  >(null);
  const [highlightMode, setHighlightMode] = useState<boolean>(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [editingRuleData, setEditingRuleData] = useState<
    | ({
        index: number;
        conditionIndex: number;
      } & ProgramRule)
    | null
  >(null);
  const [draggedNodes, setDraggedNodes] = useState<FlowGraphEntity[]>([]);
  const [draggingNode, setDraggingNode] = useState<FlowGraphEntity>();

  const [graphNodes, setNodes] = useState([]);
  const [graphEdges, setEdges] = useState([]);

  const [layout, setLayout] = useState('TB');

  const getAllTargetIds = useCallback(
    (nodeId: string) => {
      const allIds: string[] = [];
      props?.rules?.map((rule, index) => {
        rule?.conditions?.map((cond) => {
          if (
            cond?.value?.referenceEntityId === nodeId &&
            rule?.entityId !== nodeId
          ) {
            if (isProgramRule(rule)) {
              if (isProgramCompletionRule(rule)) {
                allIds.push(`${END_NODE.COMPLETED}_${index}`);
              } else if (isProgramFailedRule(rule)) {
                allIds.push(`${END_NODE.FAILED}_${index}`);
              }
            } else {
              allIds.push(rule?.entityId);
            }
          }
        });
      });
      return allIds;
    },
    [props]
  );

  const startingAssignmentIds = useMemo(
    () =>
      props?.rules
        ?.filter((rule) => {
          // @ts-expect-error
          return rule?.conditions?.some(
            (condition) =>
              condition?.value.eventName ===
              LearningProgramDomainEventNames.LearningProgramInstanceCreated
          );
        })
        .map((rule) => rule.entityId),
    [props]
  );

  const completionPaths = useMemo(() => {
    const currentPath: string[] = [];
    const allCompletionPaths: string[][] = [];
    const allFailurePaths: string[][] = [];

    const getAllPaths = (nodeId: string) => {
      if (!nodeId || currentPath.includes(nodeId)) {
        return;
      }
      // Add current node to the current path
      currentPath.push(nodeId);
      // If current node is a leaf node (end-node), add the current path to allPaths
      if (nodeId.includes(END_NODE.COMPLETED)) {
        allCompletionPaths.push([...currentPath]);
      } else if (nodeId.includes(END_NODE.FAILED)) {
        allFailurePaths.push([...currentPath]);
      } else {
        const allTargetIds = _.uniq(getAllTargetIds(nodeId));
        allTargetIds?.forEach((tId) => getAllPaths(tId));
      }
      currentPath.pop();
      return { allCompletionPaths, allFailurePaths };
    };

    startingAssignmentIds?.map((asa) => getAllPaths(asa));

    return {
      [FlowGraphNodeTypes.End.Completed]: allCompletionPaths,
      [FlowGraphNodeTypes.End.Failed]: allFailurePaths,
    };
  }, [startingAssignmentIds]);

  const { connections, entities } = useMemo(
    () =>
      parseProgramDataToFlowGraph({
        assignments: props?.assignments,
        assignmentRules: props?.rules,
        isConnectable: props?.isConnectable,
        completionPaths: completionPaths as any,
        startingAssignmentIds,
        isHighlighted: (nodeId) =>
          highlightMode
            ? highlightedEndNodePath?.flat(1)?.includes(nodeId) ?? false
            : true,
      }),
    [props, highlightedEndNodePath, highlightMode, completionPaths]
  );

  const unconnectedEntities = useMemo(
    () =>
      props?.isConnectable
        ? entities?.filter(
            (ent) =>
              !connections?.some(
                (conn) => conn?.source === ent?.id || conn?.target === ent?.id
              ) &&
              !draggedNodes?.some((dn) => dn?.id === ent?.id) &&
              ![START_NODE, END_NODE.COMPLETED, END_NODE.FAILED]?.includes(
                ent?.id
              )
          )
        : [],
    [connections, entities, draggedNodes, props]
  );

  const onUpsertEdge = useCallback(
    ({
      template,
      entities,
      name,
      combinator,
      editingRuleData,
      scheduleDate,
    }) => {
      const { rule: newRule, replaceIndex } = buildOrReplaceLearningProgramRule(
        {
          programId: props?.learningProgramId as string,
          template,
          name,
          entities,
          combinator,
          editingRuleData,
          rules: props?.rules ?? [],
          scheduleDate,
        }
      );

      if (template === ProgramRulesTemplateTypes.programCreated) {
        // assignment is connected to start - starting assignment rule
        props?.onChange?.({
          rules: [...(props?.rules ?? []), newRule] as RulesPayloadBaseType,
        });
      } else {
        let newRules = [] as RulesPayloadBaseType;
        const assignmentRulesCopy = [...(props?.rules || [])];
        // replace existing rule by adding additional condition

        if ((replaceIndex as number) > -1) {
          assignmentRulesCopy?.splice(
            replaceIndex as number,
            1,
            newRule as ProgramRule
          );
          newRules = assignmentRulesCopy as RulesPayloadBaseType;
          // add new rule
        } else {
          newRules = [...assignmentRulesCopy, newRule] as RulesPayloadBaseType;
        }

        props?.onChange?.({
          rules: newRules as RulesPayloadBaseType,
        });
      }
      return newRule as ProgramRule & { conditionIndex?: number };
    },
    [props]
  );

  const onDragNode = useCallback(
    (entity: FlowGraphEntity) => {
      setDraggingNode(entity);
    },
    [props, draggingNode, setNodes]
  );

  const onDropNode = useCallback(
    (entity: FlowGraphEntity, position: Position) => {
      if (!draggedNodes?.includes(entity)) {
        setDraggedNodes([...draggedNodes, { ...entity, position }]);
      }
    },
    [props, draggedNodes, entities]
  );

  const onDeleteEdge = useCallback(
    async (edge: {
      ruleIndex: number;
      conditionIndex?: number;
      source?: string;
      target?: string;
    }) => {
      const currentRulesCopy = [...(props?.rules || [])];
      const currentRuleCopy = { ...props?.rules?.[edge.ruleIndex] };

      if (!edge?.conditionIndex && edge?.conditionIndex !== 0) {
        currentRulesCopy.splice(edge.ruleIndex, 1);
      } else {
        if (currentRuleCopy?.conditions?.[edge.conditionIndex]) {
          currentRuleCopy?.conditions?.splice(edge.conditionIndex, 1);

          if (currentRuleCopy?.conditions?.length === 1) {
            currentRuleCopy.conditions[0].combinator = null;
          }

          if (!currentRuleCopy?.conditions?.length) {
            currentRulesCopy?.splice(edge.ruleIndex, 1);
          } else {
            currentRulesCopy?.splice(
              edge.ruleIndex,
              1,
              currentRuleCopy as ProgramRule
            );
          }
        }
      }

      props?.onChange?.({
        rules: currentRulesCopy as RulesPayloadBaseType,
      });
    },
    [props]
  );

  const onClickEdge = useCallback(
    (ruleIndex: number, rule: any) => {
      const entityId = rule?.entityId || rule?.entity?.id;

      setEditingRuleData({
        ...rule,
        index: ruleIndex,
        conditionIndex: rule?.index,
        entityId,
      });

      setShowConnectModal(true);
    },
    [props, setEditingRuleData, setShowConnectModal]
  );

  const onClickNode = useCallback(
    (nodeData: LearningAssignment, type: FlowGraphNodeType) => {
      if (type === FlowGraphNodeTypes.Entity) {
        props?.onEditNode?.(nodeData);
      } else if (
        type === FlowGraphNodeTypes.End.Completed ||
        type === FlowGraphNodeTypes.End.Failed
      ) {
        setHighlightMode?.(true);
        setHighlightedEndNodePath?.(
          completionPaths?.[type]?.filter?.(
            (path) => path[path.length - 1] === nodeData?.id
          ) || []
        );
      }
    },
    [props, connections, setHighlightMode, completionPaths]
  );

  const onConnect = useCallback(
    (params) => {
      if (props?.isConnectable) {
        if (params?.source === START_NODE) {
          // connect to start node - build new starting rule
          const newEdge = buildLearningProgramGraphNodesRuleByTemplate({
            source: params?.target,
            sourceHandle: params?.targetHandle,
            target: props?.learningProgramId as string,
            targetHandle: 'Learning Program',
            template: ProgramRulesTemplateTypes.programCreated,
          });
          onUpsertEdge?.({
            ...newEdge,
            combinator: null,
            editingRuleData: null,
            scheduleDate: null,
          });
        } else if (
          params?.target?.includes(END_NODE.FAILED) ||
          params?.target?.includes(END_NODE.COMPLETED)
        ) {
          // connect to end node - settings update
          const newEdge = buildLearningProgramGraphNodesRuleByTemplate({
            source: params?.target,
            sourceHandle: params?.targetHandle,
            target: params?.source,
            targetHandle: params?.sourceHandle,
            template: params?.target?.includes(END_NODE.COMPLETED)
              ? ProgramRulesTemplateTypes.programCompleted
              : ProgramRulesTemplateTypes.programFailed,
          });
          onUpsertEdge?.({
            ...newEdge,
            combinator: null,
            editingRuleData: null,
            scheduleDate: null,
          });
        } else if (params?.source && params?.target) {
          // connect two assignments - new rule
          const newEdge = buildLearningProgramGraphNodesRuleByTemplate({
            source: params?.target,
            sourceHandle: params?.targetHandle,
            target: params?.source,
            targetHandle: params?.sourceHandle,
            template:
              params?.source === params?.target
                ? ProgramRulesTemplateTypes.recurrenceFailed
                : ProgramRulesTemplateTypes.assignCompleted,
          });
          onUpsertEdge?.({
            ...newEdge,
            combinator: null,
            editingRuleData: null,
            scheduleDate: null,
          });
        }
      }
    },
    [props]
  );

  const showRemoveEndConfirmationModal = useConfirmationModal({
    message:
      'Are you sure that you want to perform this action? Keep in mind that this removes all the end node connections',
  });
  const onDeleteGraphNode = useCallback(
    async (nodeId: string, nodeType: FlowGraphNodeType) => {
      if (nodeType === FlowGraphNodeTypes.Entity) {
        return await props?.onDeleteNode?.(nodeId);
      } else {
        const confirmationResult = await showRemoveEndConfirmationModal();
        if (!confirmationResult.confirmed) return false;
        const ruleIndex = nodeId
          ?.replace(`${END_NODE.COMPLETED}_`, '')
          ?.replace(`${END_NODE.FAILED}_`, '');
        onDeleteEdge?.({ ruleIndex: parseInt(ruleIndex) ?? -1 });
      }
    },
    [props]
  );

  const onNodeBlur = (e) => {
    if (!e?.target?.className?.includes?.('node')) {
      setHighlightMode?.(false);
    }
  };

  return (
    <LearningProgramRulesGraphContext.Provider
      value={{
        connections,
        entities,
        unconnectedEntities,
        completionPaths: completionPaths as
          | {
              'End-Completed': string[][];
              'End-Failed': string[][];
            }
          | undefined,
        isConnectable: props?.isConnectable,
        assignments: props?.assignments,
        highlightedEndNodePath: highlightedEndNodePath as string[][],
        setHighlightedEndNodePath,
        highlightMode,
        setHighlightMode,
        showConnectModal,
        setShowConnectModal,
        editingRuleData,
        setEditingRuleData,
        startingAssignmentIds,
        edgeTypesMap: edgeTypesMap as any,
        nodeTypesMap: nodeTypesMap as any,
        onDeleteEdge,
        onDeleteNode: onDeleteGraphNode,
        onChangeNodes: setNodes,
        onChangeEdges: setEdges,
        onClick: onNodeBlur,
        onUpsertEdge,
        onConnect,
        onClickNode,
        onClickEdge,
        layout: layout as any,
        setLayout,
        learningProgramId: props?.learningProgramId,
        showLegend,
        setShowLegend,
        onDragNode,
        onDropNode,
        draggingNode,
        draggedNodes,
      }}
    >
      {props?.children}
    </LearningProgramRulesGraphContext.Provider>
  );
};
