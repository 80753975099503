import { useCallback } from 'react';

import { useRoutingAction } from '@nl-lms/ui/hooks';

import { routes } from '../../lib/routes';
import { authStore } from '../modules/Auth/auth';

export const useGoToHome = () => {
  const goToLearnerDashboard = useRoutingAction({
    route: routes.portal.path.full(),
  });
  const goToAdminDashboard = useRoutingAction({
    route: routes.admin.path.full(),
  });

  const goToHome = useCallback(() => {
    if (authStore.activeApp === 'admin') {
      goToAdminDashboard();
    } else if (authStore.activeApp === 'portal') {
      goToLearnerDashboard();
    } else if (
      authStore.hasAdminRole ||
      authStore.hasManagerRole ||
      authStore.hasEvaluatorRole ||
      authStore.hasAuditorRole
    ) {
      goToAdminDashboard();
    } else {
      goToLearnerDashboard();
    }
  }, []);

  return goToHome;
};
