import { z } from 'zod';

import {
  LearnerGroupSchema,
  LearnerSchema,
  LearningAssignmentDateSchema,
  LearningAssignmentInstanceSchema,
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
  LearningAssignmentNotificationSchema,
  LearningAssignmentPlannedInstanceSchema,
  LearningAssignmentPlannedInstanceStatus,
  LearningAssignmentPlannedInstanceTriggerSchema,
  LearningAssignmentRuleConditionSchema,
  LearningAssignmentRuleMatchSchema,
  LearningAssignmentRuleSchema,
  LearningAssignmentRuleSettingsSchema,
  LearningAssignmentSchema,
  LearningProgramSchema,
} from '@nl-lms/common/feature/types';
import {
  DeleteResourcesBodySchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

export const LearningAssignmentAudienceSchema = z
  .object({
    learnerGroupIds: z.array(z.string()),
    learnerIds: z.array(z.string()),
  })
  .refine(
    (audience) => {
      const { learnerIds, learnerGroupIds } = audience;
      return learnerIds.length || learnerGroupIds.length;
    },
    { message: 'No audience members specified' }
  );

export const CreateLearningAssignmentPayloadSchema = z
  .object({
    name: z.string(),
    audience: z.object({
      learnerGroupIds: z.array(z.string()),
      learnerIds: z.array(z.string()),
      createInstances: z.boolean(),
      scheduleOn: z.string().optional(),
    }),
    dueDate: LearningAssignmentDateSchema.nullable(),
    mandatory: z.boolean(),
    notifications: z.array(LearningAssignmentNotificationSchema),
    learningProgramId: z.string().nullish(),
    assessmentFormId: z.string().nullable(),
    learningPathId: z.string().nullable(),
    surveyFormId: z.string().nullable(),
    checklistId: z.string().nullable(),
    elearningCourseId: z.string().nullable(),
    courseId: z.string().nullable(),
    showAfterDueDate: z.boolean(),
  })
  .refine(
    ({
      assessmentFormId,
      courseId,
      elearningCourseId,
      learningPathId,
      checklistId,
      surveyFormId,
    }) => {
      return (
        assessmentFormId !== undefined ||
        courseId !== undefined ||
        elearningCourseId !== undefined ||
        surveyFormId !== undefined ||
        checklistId !== undefined ||
        learningPathId !== undefined
      );
    },
    { message: 'No content id was specified' }
  )
  .refine(
    ({ audience }) => {
      const { learnerIds, learnerGroupIds } = audience;
      return learnerIds.length || learnerGroupIds.length;
    },
    { message: 'No audience members specified' }
  );

const ConditionSchema = z.object({
  eventName: z.string(),
  match: LearningAssignmentRuleMatchSchema.nullable(),
  referenceId: z.string().nullish(),
});

export const AddRuleConditionsEquationSchema = z.object({
  value: z.union([
    ConditionSchema,
    z.lazy(() => z.array(AddRuleConditionsEquationSchema)),
  ]),
  combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
});

export const AddLearningAssignmentRulePayloadSchema = z.object({
  createPlannedInstances: z.boolean(),
  rule: z.object({
    name: z.string(),
    startDate: LearningAssignmentDateSchema.nullable(),
    settings: LearningAssignmentRuleSettingsSchema.nullable(),
    conditionsEquation: AddRuleConditionsEquationSchema,
  }),
});

export const AddLearningAssignmentRulesPayloadSchema = z.array(
  z.object({
    createPlannedInstances: z.boolean(),
    rule: z.object({
      name: z.string(),
      learningAssignmentId: z.string(),
      startDate: LearningAssignmentDateSchema.nullable(),
      settings: LearningAssignmentRuleSettingsSchema.nullable(),
      conditionsEquation: AddRuleConditionsEquationSchema,
    }),
  })
);

export const UpdateLearningAssignmentRulesPayloadSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    learningAssignmentId: z.string(),
    startDate: LearningAssignmentDateSchema.nullable(),
    settings: LearningAssignmentRuleSettingsSchema.nullable(),
    conditionsEquation: AddRuleConditionsEquationSchema,
  })
);

export const UpdateLearningAssignmentRulePayloadSchema = z.object({
  name: z.string(),
  startDate: LearningAssignmentDateSchema.nullable(),
  settings: LearningAssignmentRuleSettingsSchema.nullable(),
  conditionsEquation: AddRuleConditionsEquationSchema.nullable(),
});

export const UpdateLearningAssignmentPayloadSchema = z.object({
  name: z.string(),
  dueDate: LearningAssignmentDateSchema.nullable(),
  showAfterDueDate: z.boolean(),
  mandatory: z.boolean(),
});

export const UpdateLearningAssignmentsPayloadSchema = z
  .object({
    dueDate: LearningAssignmentDateSchema.optional(),
    showAfterDueDate: z.boolean().optional(),
    mandatory: z.boolean().optional(),
  })
  .extend(DeleteResourcesBodySchema.shape)
  .refine(
    ({ dueDate, mandatory, showAfterDueDate }) =>
      mandatory !== undefined ||
      dueDate !== undefined ||
      showAfterDueDate !== undefined,
    { message: 'At least one field is required' }
  );

export const UpdateLearningAssignmentInstancePayloadSchema = z
  .object({
    dueDate: TransformedDateSchema.optional(),
    mandatory: z.boolean().optional(),
  })
  .extend(DeleteResourcesBodySchema.shape)
  .refine(
    ({ dueDate, mandatory }) =>
      mandatory !== undefined || dueDate !== undefined,
    { message: 'At least one field is required' }
  );

export const UpdateLearningAssignmentPlannedInstancePayloadSchema =
  LearningAssignmentPlannedInstanceSchema.pick({
    triggersEquation: true,
    scheduledOn: true,
  });

export const RescheduleLearningAssignmentPlannedInstancePayloadSchema =
  z.object({
    scheduleOn: TransformedDateSchema,
  });

export const ListLearningAssignmentInstanceSchema =
  LearningAssignmentInstanceSchema.extend({
    learner: LearnerSchema,
    status: z.nativeEnum(LearningAssignmentInstanceStatus),
    learningAssignment: LearningAssignmentSchema,
    type: z.nativeEnum(LearningAssignmentLearningContentType),
  });

export const ListLearningAssignmentPlannedInstanceSchema =
  LearningAssignmentPlannedInstanceSchema.extend({
    learner: LearnerSchema,
    learningAssignment: LearningAssignmentSchema,
    status: z.nativeEnum(LearningAssignmentPlannedInstanceStatus),
    triggers: z.array(LearningAssignmentPlannedInstanceTriggerSchema),
  });

export const GetLearningAssignmentSchema = LearningAssignmentSchema.extend({
  content: z.object({
    type: z.nativeEnum(LearningAssignmentLearningContentType),
    name: z.string(),
    id: z.string(),
  }),
  learners: z.array(LearnerSchema),
  learnerGroups: z.array(LearnerGroupSchema),
  userName: z.string(),
  learningProgram: LearningProgramSchema.nullable(),
  rules: z.array(
    LearningAssignmentRuleSchema.extend({
      conditions: z.array(LearningAssignmentRuleConditionSchema),
    })
  ),
});

export const ScheduleLearningAssignmentInstancesPayloadSchema = z.object({
  audience: LearningAssignmentAudienceSchema,
  scheduleOn: TransformedDateSchema,
  learningAssignmentId: z.string(),
});
