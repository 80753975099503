import React from 'react';

export const DatabaseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    stroke="currentColor"
    strokeLinecap="square"
    strokeLinejoin="bevel"
  >
    <path d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z" />
    <path d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.0054 4.89449C3.17067 3.28359 7.10638 2 12 2C17 2 21 3.34 21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5L3.0054 4.89449Z"
    />
  </svg>
);
