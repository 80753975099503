import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import {
  ApiQuery,
  ApiQueryFilter,
  AppQuery,
  AppQueryFilter,
  AppQueryPagination,
  AppQuerySorting,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
  QueryPagination,
  combineFilters,
  transformQuery,
} from '@nl-lms/common/shared';
import { _ } from '@nl-lms/vendor';

import {
  TableColumn,
  TablePagination,
} from '../../components/Table/Table.types';
import {
  ListViewState,
  ListViewTableColumn,
  Pagination,
  Sorting,
} from './ListView.types';
import { ListViewStore } from './ListViewStore';

type ListViewProps = {
  name: string;
  paginationLimit?: number;
  disablePagination?: boolean;
  columns?: ListViewTableColumn[];
  initialFilters?: AppQueryFilter;
  initialSorting?: Sorting;
  persistToLocalStorage?: boolean;
  useUrlQuery?: boolean;
  // Filter that will always be included in the final query
  // Ex: In live session resources list you always need to query by session id
  // and that filter should not be visible in the FilterBar
  baseFilters?: AppQueryFilter;
  children: React.ReactNode;
};

export type ListViewContextType = {
  filters?: AppQueryFilter;
  pagination: Pagination;
  sorting: Sorting;
  disablePagination: boolean;
  columns: ListViewTableColumn[];
  query: AppQuery;
  onChangeFilters: (value: AppQueryFilter) => void;
  onChangePagination: (value: Partial<Pagination>) => void;
  onChangeSorting: (value: Sorting) => void;
  onChangeColumns: (value: ListViewTableColumn[]) => void;
};

const ListViewContext = createContext<ListViewContextType>(
  {} as ListViewContextType
);
const defaultColumns = [];
export const ListViewProvider = (props: ListViewProps) => {
  const {
    children,
    paginationLimit = 0,
    disablePagination = false,
    initialSorting,
    persistToLocalStorage = true,
    useUrlQuery = true,
    columns: _columns = defaultColumns,
    initialFilters = null,
    baseFilters = null,
    name,
  } = props;
  const {
    sorting,
    pagination,
    filters,
    query,
    columns,
    onChangePagination,
    onChangeColumns,
    onChangeSorting,
    onChangeFilters,
  } = useListView({
    paginationLimit,
    disablePagination,
    // @ts-ignore
    initialSorting,
    // @ts-ignore
    columns: _columns,
    persistToLocalStorage,
    useUrlQuery,
    // @ts-ignore
    initialFilters,
    // @ts-ignore
    baseFilters,
    name,
  });
  return (
    <ListViewContext.Provider
      value={{
        sorting,
        pagination,
        filters,
        // @ts-ignore
        query,
        columns,
        disablePagination,
        onChangeSorting,
        onChangePagination,
        onChangeFilters,
        onChangeColumns,
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

export const useListView = ({
  paginationLimit = 0,
  disablePagination = false,
  initialSorting = null,
  columns: _columns = defaultColumns,
  initialFilters = null,
  baseFilters = null,
  persistToLocalStorage = true,
  useUrlQuery = true,
  name,
}) => {
  const listViewStore = useMemo(() => new ListViewStore(name), [name]);
  const {
    viewState,
    onChangePagination,
    onChangeColumns,
    onChangeSorting,
    onChangeFilters,
  } = useListViewReducer({
    listViewStore,
    paginationLimit,
    columns: _columns,
    initialSorting,
    initialFilters,
    persistToLocalStorage,
    useUrlQuery,
  });
  const { pagination, sorting, filters, columns } = viewState;

  const [query, queryWithoutBaseFilters] = useAppQuery({
    pagination,
    sorting,
    filters,
    baseFilters,
    disablePagination,
  });

  useEffect(() => {
    if (persistToLocalStorage) {
      listViewStore.viewState = viewState;
    }
  }, [name, viewState, persistToLocalStorage]);

  useEffect(() => {
    if (!useUrlQuery) return;
    // @ts-ignore
    const urlQueryString = getUrlStringFromAppQuery(queryWithoutBaseFilters);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('query', urlQueryString);
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${urlSearchParams.toString()}`
    );
  }, [queryWithoutBaseFilters]);

  return {
    sorting,
    pagination,
    filters,
    query,
    columns,
    onChangeSorting,
    onChangePagination,
    onChangeFilters,
    onChangeColumns,
  };
};

const useListViewReducer = ({
  listViewStore,
  paginationLimit: _paginationLimit,
  initialSorting,
  columns,
  initialFilters,
  persistToLocalStorage,
  useUrlQuery,
}) => {
  const defaultInitialState: ListViewState = {
    pagination: { offset: 0, limit: _paginationLimit },
    sorting: initialSorting,
    filters: initialFilters,
    columns,
  };
  const initialState = hydrateView(
    listViewStore,
    defaultInitialState,
    persistToLocalStorage,
    useUrlQuery
  );
  const [viewState, dispatch] = useReducer<
    React.Reducer<ListViewState, ListViewAction>
  >(listDataReducer, initialState);
  const { pagination } = viewState;
  const onChangeFilters = useCallback((filters) => {
    dispatch({ type: 'CHANGE_FILTERS', payload: filters });
  }, []);
  const onChangeSorting = useCallback((sorting) => {
    dispatch({ type: 'CHANGE_SORTING', payload: sorting });
  }, []);
  const onChangeColumns = useCallback((columns) => {
    dispatch({ type: 'CHANGE_COLUMNS', payload: columns });
  }, []);
  const onChangePagination = useCallback(
    (newPagination) => {
      const _pagination = { ...pagination };
      if (newPagination.offset === 0 || newPagination.offset)
        _pagination.offset = newPagination.offset;
      if (newPagination.limit === 0 || newPagination.limit)
        _pagination.limit = newPagination.limit;
      dispatch({ type: 'CHANGE_PAGINATION', payload: _pagination });
    },
    [pagination]
  );

  return {
    viewState,
    onChangePagination,
    onChangeFilters,
    onChangeSorting,
    onChangeColumns,
  };
};

type ListViewAction =
  | {
      type: 'CHANGE_SORTING';
      payload: Sorting;
    }
  | {
      type: 'CHANGE_PAGINATION';
      payload: Pagination;
    }
  | {
      type: 'CHANGE_FILTERS';
      payload: AppQueryFilter;
    }
  | {
      type: 'CHANGE_COLUMNS';
      payload: ListViewTableColumn[];
    };

const listDataReducer = (state: ListViewState, action: ListViewAction) => {
  switch (action.type) {
    case 'CHANGE_SORTING':
      return {
        ...state,
        sorting: action.payload,
        pagination: {
          ...state.pagination,
          offset: 0,
        },
      };
    case 'CHANGE_PAGINATION':
      return {
        ...state,
        pagination: action.payload,
      };
    case 'CHANGE_COLUMNS':
      return {
        ...state,
        columns: action.payload,
      };
    case 'CHANGE_FILTERS':
      return {
        ...state,
        filters: action.payload,
        pagination: {
          ...state.pagination,
          offset: 0,
        },
      };
  }
};

const useAppQuery = ({
  pagination,
  sorting,
  filters,
  disablePagination,
  baseFilters,
}) => {
  const paginationOffset = pagination.offset;
  const paginationLimit = pagination.limit;
  const queryPagination = useMemo<QueryPagination>(() => {
    if (disablePagination) return { disabled: true };
    return {
      limit: paginationLimit,
      offset: paginationOffset,
    };
  }, [paginationOffset, paginationLimit, disablePagination]);
  const querySorting = useMemo(() => {
    if (!sorting) return null;
    return {
      [sorting.field]: sorting.order,
    };
  }, [sorting]);

  return useMemo(() => {
    return [
      {
        pagination: queryPagination,
        sorting: querySorting,
        filters: combineFilters(filters, baseFilters),
      },
      {
        pagination: queryPagination,
        sorting: querySorting,
        filters,
      },
    ];
  }, [querySorting, baseFilters, queryPagination, filters]);
};

const hydrateView = (
  listViewStore: ListViewStore,
  defaultInitialState: ListViewState,
  persistToLocalStorage: boolean,
  useUrlQuery: boolean
): ListViewState => {
  const storeState = listViewStore.viewState;
  const finalInitialState =
    storeState && persistToLocalStorage
      ? storeState
      : { ...defaultInitialState };
  if (!useUrlQuery) return finalInitialState;
  const urlState = getAppQueryFromUrlState();
  if (!urlState) return finalInitialState;
  if (urlState.sorting) {
    const [field] = Object.keys(urlState.sorting);
    const [order] = Object.values(urlState.sorting);
    if (field && order) {
      finalInitialState.sorting = { field, order };
    }
  }
  if (urlState.pagination && !urlState.pagination.disabled) {
    finalInitialState.pagination = {
      offset:
        // @ts-ignore
        parseInt(urlState.pagination.offset) ||
        finalInitialState.pagination.offset,
      limit:
        // @ts-ignore
        parseInt(urlState.pagination.limit) ||
        finalInitialState.pagination.limit,
    };
  }
  if (urlState.filters) {
    finalInitialState.filters = urlState.filters;
    if (!urlState.pagination && finalInitialState.pagination?.offset > 0) {
      finalInitialState.pagination = {
        offset: 0,
        limit: finalInitialState.pagination.limit,
      };
    }
  }
  return finalInitialState;
};

export const useListViewContext = () => useContext(ListViewContext);

type onChangeTableColumnsType = (columns: TableColumn[]) => void;

export function useListViewTableColumns(
  baseColumns: TableColumn[],
  availableColumns?: string[]
): [TableColumn[], onChangeTableColumnsType] {
  const { onChangeColumns: _onChangeColumns, columns: _columns } =
    useListViewContext();

  const onChangeColumns = useCallback((columns: TableColumn[]) => {
    const parsedColumns: ListViewTableColumn[] = columns.map((c) => {
      const { Header, Cell, sortField, ...rest } = c;

      return rest;
    });
    _onChangeColumns(parsedColumns);
  }, []);
  // @ts-ignore
  const columns = useMemo<TableColumn[]>(() => {
    const base = !_columns.length
      ? baseColumns
      : baseColumns.map((baseColumn) => {
          if (
            availableColumns &&
            !availableColumns.includes(baseColumn.accessor)
          )
            return null;
          const contextColumn = _columns.find(
            (cc) => cc.accessor === baseColumn.accessor
          );
          if (!contextColumn) return baseColumn;
          const parsedColumn = { ...baseColumn };
          if (contextColumn.isVisible !== undefined) {
            parsedColumn.isVisible = contextColumn.isVisible;
          }
          if (contextColumn.width !== undefined) {
            parsedColumn.width = contextColumn.width;
          }
          return parsedColumn;
        });
    if (!availableColumns || !availableColumns.length) return base;
    return base.filter((c) => {
      if (!c) return false;
      return availableColumns.includes(c.accessor);
    });
  }, [_columns, availableColumns]);
  return [columns, onChangeColumns];
}

export function useListViewTableData<Entity>(
  data:
    | {
        rows: Entity[];
        count: number;
      }
    | undefined
): [Entity[], TablePagination] {
  const { pagination: _pagination, onChangePagination } = useListViewContext();

  const rows = data?.rows || [];

  useEffect(() => {
    if (data?.count && data?.count < _pagination.offset) {
      onChangePagination({ offset: 0, limit: _pagination.limit });
    }
  }, [_pagination, data]);

  const pagination = {
    ..._pagination,
    rowCount: data?.count || 0,
  };

  return [rows, pagination];
}

const getAppQueryFromUrlState = (): AppQuery | null => {
  const searchParams = new URLSearchParams(window.location.search);
  const appQueryString = searchParams.get('query');
  if (!appQueryString) return null;
  try {
    const apiQuery = JSON.parse(appQueryString) as ApiQuery;
    // @ts-ignore
    let pagination: AppQueryPagination = null;
    // @ts-ignore
    let sorting: AppQuerySorting = null;
    // @ts-ignore
    let filters: AppQueryFilter = null;
    if (apiQuery.pagination) {
      pagination = apiQuery.pagination;
    }
    if (apiQuery.sorting) {
      sorting = apiQuery.sorting;
    }
    if (apiQuery.filters && apiQuery.filters.value) {
      filters = transformApiQueryFilterToAppQueryFilter(apiQuery.filters);
    }
    return { filters, sorting, pagination };
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getUrlStringFromAppQuery = (query: AppQuery) => {
  if (!query) return '';
  return JSON.stringify({
    ...query,
    ...transformQuery(query),
  });
};

export const transformApiQueryFilterToAppQueryFilter = (
  filter: ApiQueryFilter
): AppQueryFilter => {
  // @ts-ignore
  if (!filter || !filter.value) return null;
  const baseFilter: { id: string; combinator?: QueryFilterCombinator } = {
    id: 'url-filter',
  };
  if (filter.combinator) {
    baseFilter.combinator = filter.combinator;
  }
  if (Array.isArray(filter.value)) {
    return {
      ...baseFilter,
      value: filter.value.map((fv) =>
        transformApiQueryFilterToAppQueryFilter(fv)
      ),
    };
  }
  return {
    ...baseFilter,
    // @ts-ignore
    value: {
      value: { value: filter.value.value, label: 'value' },
      operator: {
        value: filter.value.operator as QueryOperator,
        type:
          typeof filter.value.value === 'boolean'
            ? QueryFilterOperatorType.Unary
            : QueryFilterOperatorType.Binary,
      },
      field: {
        field: filter.value.field,
        label: _.camelCase(filter.value.field),
      },
    },
  };
};
