import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ScheduleDateSchema } from '@nl-lms/common/feature/types';
import { FieldConditionSchema } from '@nl-lms/common/shared';
import {
  Box,
  Button,
  Card,
  SideModal,
  useConfirmationModal,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import {
  learningAssignmentsApi,
  learningProgramsApi,
} from '../../../_common/services/api';
import { getAssignmentsByProgramIdFilterQuery } from '../learning-assignment-rule/utils/utils';
import { LearningAssignmentEditSideModal } from '../learning-assignment/LearningAssignmentEditSideModal';
import { LearningProgramRulesGraph } from '../learning-program-rule-graph/LearningProgramRulesGraph';
import { LearningProgramRulesGraphProvider } from '../learning-program-rule-graph/LearningProgramRulesGraphContext';
import {
  parseFormRulesToProgramsPayload,
  parseRulePayloadToProgramComponent,
} from '../learning-program-rule-graph/utils';

const RuleConditionValueBaseSchema = z.object({
  eventName: z.string(),
  match: FieldConditionSchema.nullable(),
  referenceEntityId: z.string().nullish(),
  referenceEntity: z.object({ id: z.string(), name: z.string() }).nullish(),
});

export const RuleConditionBaseSchema = z.object({
  value: z.union([
    RuleConditionValueBaseSchema,
    z.lazy(() => z.array(RuleConditionBaseSchema)),
  ]),
  combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
});

export const LearningProgramUpsertRulesBaseSchema = z.object({
  connections: z.object({
    rules: z.array(
      z.object({
        name: z.string(),
        entityName: z.string(),
        entityId: z.string(),
        actionName: z.string(),
        scheduleDate: ScheduleDateSchema.nullable(),
        settings: z.record(z.string(), z.unknown()),
        conditionsEquation: z
          .object({
            value: z.union([
              z.string(),
              z.array(z.object({ value: z.string() })),
            ]),
          })
          .nullish(),
        conditions: z.array(RuleConditionBaseSchema),
      })
    ),
  }),
});

export type LearningProgramUpsertRulesBaseType = z.infer<
  typeof LearningProgramUpsertRulesBaseSchema
>;

const { useListLearningAssignmentsQuery } = learningAssignmentsApi;
const {
  useRemoveLearningProgramAssignmentsMutation,
  useGetLearningProgramQuery,
  useListLearningProgramRulesQuery,
} = learningProgramsApi;

export const LearningProgramUpsertRulesSideModal = ({
  learningProgramId,
  onSubmit,
}) => {
  const programAssignmentsQuery = useMemo(
    () => getAssignmentsByProgramIdFilterQuery(learningProgramId),
    [learningProgramId]
  );

  const { data: fetchAssignmentsResult, isLoading: assignmentsLoading } =
    useListLearningAssignmentsQuery(programAssignmentsQuery);
  const { data: fetchProgramResult, isLoading: isProgramLoading } =
    useGetLearningProgramQuery({ id: learningProgramId });

  const assignments = useMemo(
    () => fetchAssignmentsResult?.rows || [],
    [fetchAssignmentsResult]
  );
  const programSettings = useMemo(() => {
    if (fetchProgramResult && fetchProgramResult?.settings)
      return fetchProgramResult?.settings;
    return null;
  }, [fetchProgramResult]);

  const { data: fetchProgramRulesResult, isLoading: programRulesLoading } =
    useListLearningProgramRulesQuery({
      id: learningProgramId,
    });

  const assignmentRules = useMemo(
    () => fetchProgramRulesResult?.rows || [],
    [fetchProgramRulesResult]
  );

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(LearningProgramUpsertRulesBaseSchema),
    mode: 'onSubmit',
    defaultValues: {
      connections: {
        rules: assignmentRules as any,
      },
    },
  });

  useEffect(() => {
    if (assignmentRules && assignmentRules.length) {
      setValue('connections', {
        rules: assignmentRules,
      });
    }
  }, [assignmentRules]);

  const isEdit = useMemo(() => !!assignmentRules?.length, [assignmentRules]);

  const { hide } = useModal();
  const {
    onSubmit: onSubmitAssignmentRules,
    isLoading: isSubmitting,
    error,
  } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useAddLearningProgramRulesMutation',
    updateHookName: 'useAddLearningProgramRulesMutation',
    onSuccess: hide,
  });

  const showConfirmationModal = useConfirmationModal({
    title: 'Missing Connections',
    message:
      'Are you sure you want to submit these changes? Make sure all assignments are connected and that you have set a starting point for your program. Otherwise, they will not be included in the assignation flow.',
  });

  const connections = watch('connections');
  const hasUnconnectedNodes = useMemo(
    () =>
      assignments?.some((a) =>
        connections?.rules?.every(
          (rule) =>
            rule?.entityId !== a?.id &&
            rule?.conditions?.every((cond) => cond?.referenceEntityId !== a?.id)
        )
      ),
    [connections, assignments]
  );

  const onSubmitUpsert = useCallback(
    async (formData) => {
      const payload = formData?.connections;
      let rules = [];
      if (payload?.rules && payload?.rules?.length) {
        rules = payload?.rules?.map((ruleData) =>
          parseFormRulesToProgramsPayload(ruleData)
        );
      }
      if (hasUnconnectedNodes) {
        const confirmResult = await showConfirmationModal();
        if (!confirmResult.confirmed) return;
      }

      await onSubmitAssignmentRules({
        id: learningProgramId,
        rules,
        settings: {
          assignmentsOrder: programSettings?.assignmentsOrder ?? [],
        },
      });
      await onSubmit?.();
    },
    [learningProgramId, onSubmit, hasUnconnectedNodes]
  );

  const errorMessage = useMemo(() => {
    // @ts-ignore
    if (errors?.connections?.rules && !errors?.connections?.rules?.length) {
      return `Wrong rules data`;
      // @ts-ignore
    } else if (errors?.connections?.rules?.length) {
      // @ts-ignore
      return `${errors?.connections?.rules?.length} rules have incorrect data`;
    }
    return null;
  }, [errors]);

  const [removeAssignments] = useRemoveLearningProgramAssignmentsMutation();
  const showRemoveAssignmentConfirmationModal = useConfirmationModal({
    message:
      'Are you sure that you want to perform this action? Keep in mind that this removes the assignment from the learning program',
  });
  const { addAlertNotification, addSuccessNotification } = useNotifications();
  const onDeleteAssignment = useCallback(async (id: string) => {
    const confirmationResult = await showRemoveAssignmentConfirmationModal();
    if (!confirmationResult.confirmed) return false;
    const removeResult = await removeAssignments({ list: [id] });
    if ('data' in removeResult) {
      addSuccessNotification({ message: 'Successfully removed assignment' });
      return true;
    } else if ('error' in removeResult) {
      addAlertNotification({ message: 'Unable to remove assignment' });
      return false;
    }
    return false;
  }, []);

  const [currentRules] = useMemo(
    () => [
      connections?.rules?.map((rule) =>
        parseRulePayloadToProgramComponent(rule, '')
      ),
    ],
    [connections]
  );
  const showAssignmentEditModal = useShowModal(LearningAssignmentEditSideModal);
  const onEditAssignment = useCallback((assignment) => {
    showAssignmentEditModal({ assignment, showMandatory: false });
  }, []);

  const isLoading = useMemo(
    () => isProgramLoading || assignmentsLoading || programRulesLoading,
    [isProgramLoading, assignmentsLoading, programRulesLoading]
  );

  return (
    <SideModal.Content fullScreen>
      <SideModal.Header>
        <SideModal.Title>
          {isEdit ? 'Edit' : 'Create'} Learning Program Structure
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Controller
          name="connections"
          control={control}
          render={({ field }) => (
            <div style={{ height: '100%' }}>
              {isLoading ? (
                <Card>
                  <Card.Content paddingType="none">
                    <Card.Body>
                      <Box padding={{ bottom: 'm' }}>
                        <Card.BodySkeleton />
                      </Box>
                      <Box padding={{ bottom: 'm' }}>
                        <Card.BodySkeleton />
                      </Box>
                      <Box padding={{ bottom: 'm' }}>
                        <Card.BodySkeleton />
                      </Box>
                      <Box padding={{ bottom: 'm' }}>
                        <Card.BodySkeleton />
                      </Box>
                    </Card.Body>
                  </Card.Content>
                </Card>
              ) : (
                <LearningProgramRulesGraphProvider
                  {...field}
                  learningProgramId={learningProgramId}
                  isConnectable
                  assignments={assignments}
                  rules={currentRules}
                  onDeleteNode={onDeleteAssignment}
                  onEditNode={onEditAssignment}
                >
                  <LearningProgramRulesGraph
                    id="program-graph-edit-modal"
                    containerStyle={{
                      width: '100%',
                      height: '90%',
                    }}
                  />
                </LearningProgramRulesGraphProvider>
              )}
            </div>
          )}
        />
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getMessageFromError(error) || <>{errorMessage}</>}
        </SideModal.Error>
        <div id="add-rule-button" />
        <Button
          label="Submit"
          disabled={
            (!connections?.rules?.length && !assignments?.length) || !isDirty
          }
          onClick={handleSubmit(onSubmitUpsert)}
          isLoading={isSubmitting}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
