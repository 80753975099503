import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './ToggleInput.scss';

type Props = TidComponent<{
  name: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  value?: any;
  mode?: 'toggle' | 'switch';
  className?: string;
}>;

type Ref = HTMLInputElement;

export const ToggleInput = forwardRef<Ref, Props>((props, ref) => {
  const {
    checked = null,
    disabled = false,
    name = '',
    onChange = null,
    defaultChecked = false,
    mode = 'toggle',
  } = props;

  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('toggle-switch', props);

  const value = props.value ? props.value : undefined;
  const checkedProps = {} as { checked?: boolean; defaultChecked?: boolean };
  if (defaultChecked) {
    checkedProps.defaultChecked = defaultChecked;
  } else {
    // @ts-ignore
    checkedProps.checked = checked;
  }
  const _onChange = useCallback(
    ({ target: { checked } }) => {
      if (onChange) {
        onChange(!!checked);
      }
    },
    [onChange]
  );

  return (
    <label {...classNameProps} {...commonProps}>
      <input
        ref={ref}
        name={name}
        type="checkbox"
        className={`toggle-switch__input toggle-switch__input--${mode}`}
        disabled={disabled}
        onChange={_onChange}
        value={value}
        {...checkedProps}
      />
      <span
        className={`toggle-switch__slider toggle-switch__slider--${mode}`}
      />
    </label>
  );
});
