import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RangeButton } from '../RangeButton/RangeButton';

import './RangeButtonGroup.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type RangeButtonGroupOption = TidComponent<{
  value: string;
  label: string;
  disabled?: boolean;
  hasError?: boolean;
}>;

type RangeButtonGroupProps = TidComponent<{
  name: string;
  onChange?: any;
  value?: string;
  options: RangeButtonGroupOption[];
  readOnly?: boolean;
  controlled?: boolean;
  className?: string;
  disabled?: boolean;
}>;

export const RangeButtonGroup = (props: RangeButtonGroupProps) => {
  const {
    name,
    onChange,
    options,
    readOnly = false,
    value = null,
    controlled = false,
    disabled = false,
  } = props;

  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('range-button-group', props);

  const [selectedOption, setSelectedOption] = useState<RangeButtonGroupOption>(
// @ts-ignore
    value ? options.find((o) => o.value === value) : null
  );

  const _selectedOption = useMemo(() => {
    if (!controlled) return selectedOption;
    return value ? options.find((o) => o.value === value) : null;
  }, [value, controlled, selectedOption]);

  const handleChange = (option) => () => {
    setSelectedOption(option);
    if (onChange) {
      onChange({
        target: {
          value: option.value,
          name,
        },
      });
    }
  };

  return (
    <div {...classNameProps} {...commonProps}>
      {options.map((option, i) => {
        return (
          <RangeButton
            key={i}
            name={option.value}
            label={option.label}
            value={option.value}
            hasError={option.hasError}
            checked={_selectedOption?.value === option.value}
            disabled={option.disabled || disabled}
            onClick={handleChange(option)}
            readOnly={readOnly}
            count={i + 1}
            tid={option.tid}
          />
        );
      })}
    </div>
  );
};
