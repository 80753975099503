import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LearnerLearning } from '@nl-lms/sdk/backend';
import { Button, Icon } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction, useSetUrlQuery } from '@nl-lms/ui/hooks';
import { useListViewContext } from '@nl-lms/ui/modules';

import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi, learningProgramsApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import { LearnerLearningActivityCard } from '../../_common/components/LearnerLearningActivityCard/LearnerLearningActivityCard';

const { useListLearnerLearningQuery } = learnerApi;

export const LearnerLearningListCard = ({ id }) => {
  const { query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({ learnerId: authStore.learnerId, query }),
    [query]
  );

  const { assignment } = useListLearnerLearningQuery(fetchArg as any, {
    selectFromResult: ({ data }) => ({
      assignment: data?.rows?.find((a) => a.id === id),
    }),
  });

  const { type, trainingSessionId, parentId, playerUrl } = assignment as Omit<
    LearnerLearning,
    'tags' | 'learner_groups'
  >;

  const goToPage = useRoutingAction();

  const { t } = useTranslation('learner');

  const onViewMoreClick = () => {
    let path = '';
    switch (type) {
      case C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH:
        goToPage(routes.portal.learningPath.item.path.full(parentId));
        break;
      case C.I_LEARNER_LEARNING_TYPES.ASSESSMENT:
        path = useSetUrlQuery(
          routes.portal.assessmentForm.item.path.full(parentId),
          { instanceId: id }
        );
        goToPage(path);
        break;
      case C.I_LEARNER_LEARNING_TYPES.SURVEY:
        path = useSetUrlQuery(
          routes.portal.surveyForm.item.path.full(parentId),
          {
            instanceId: id,
          }
        );
        goToPage(path);
        break;
      case C.I_LEARNER_LEARNING_TYPES.CHECKLIST:
        path = useSetUrlQuery(
          routes.portal.checklistForm.item.path.full(parentId),
          {
            instanceId: id,
          }
        );
        goToPage(path);
        break;
      case C.I_LEARNER_LEARNING_TYPES.ELEARNING:
        path = useSetUrlQuery(
          routes.portal.elearningCourse.item.path.full(parentId),
          { instanceId: id }
        );
        goToPage(path);
        break;
      case C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE:
        if (trainingSessionId) {
          goToPage(routes.portal.liveSession.item.path.full(trainingSessionId));
        } else {
          goToPage(routes.portal.liveCourse.item.path.full(parentId));
        }
        break;
      default:
        break;
    }
  };

  const goToPlayer = useCallback(() => {
    window.open(playerUrl);
  }, [playerUrl]);

  const onStartClick = useCallback(() => {
    if (type === C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE) {
      const path = useSetUrlQuery(
        routes.portal.liveCourse.item.path.full(parentId),
        {
          focusAvailableSessions: true,
        }
      );
      goToPage(path);
    } else {
      goToPlayer();
    }
  }, [goToPlayer, type, parentId]);

  const actions = [
    <Button
      regular
      onClick={onViewMoreClick}
      label={t('common.learningactivitycard.viewmore')}
    />,
  ];
  if (type !== C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH && !trainingSessionId) {
    actions.push(
      <Button
        onClick={onStartClick}
        label={
          type === C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE
            ? t('common.learningactivitycard.register')
            : t('common.learningactivitycard.start')
        }
        icon={<Icon.PlayIcon />}
      />
    );
  }

  return (
    <LearnerLearningActivityCard {...(assignment as any)} actions={actions} />
  );
};
