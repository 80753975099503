import { ScheduleDate } from '@nl-lms/feature/automation/sdk';
import { FlowGraphEdgeTypes, FlowGraphNodeTypes } from '@nl-lms/ui/components';

import { GraphEdge } from './LearningProgramRulesGraphEdge';
import { GraphNode } from './LearningProgramRulesGraphNode';
import { ProgramRulesTemplateTypes } from './constants';

export const START_NODE = 'start-node';
export const END_NODE = {
  COMPLETED: 'end-node-completed',
  FAILED: 'end-node-failed',
};
export const RULE_NODE = 'rule-node';

export const nodeTypesMap = {
  [FlowGraphNodeTypes.Entity]: GraphNode.Entity,
  [FlowGraphNodeTypes.Start]: GraphNode.Start,
  [FlowGraphNodeTypes.End.Completed]: GraphNode.End,
  [FlowGraphNodeTypes.End.Failed]: GraphNode.End,
  [FlowGraphNodeTypes.Detail]: GraphNode.Detail,
  [FlowGraphNodeTypes.Connection]: GraphNode.Connection,
};
export const edgeTypesMap = {
  [FlowGraphEdgeTypes.Connector]: GraphEdge.Connector,
  [FlowGraphEdgeTypes.Self]: GraphEdge.Self,
  [FlowGraphEdgeTypes.Default]: GraphEdge.Default,
  [FlowGraphEdgeTypes.End.Completed]: GraphEdge.End,
  [FlowGraphEdgeTypes.End.Failed]: GraphEdge.End,
};

export type RuleEventType =
  | 'learning-assignment-instance.created'
  | 'learning-assignment-instance.started'
  | 'learning-assignment-instance.completed'
  | 'learning-assignment-instance.failed'
  | 'learning-assignment.learner-joined-audience';

export type ProgramRuleConditionValue = {
  eventName?: RuleEventType;
  referenceEntityId?: string;
  referenceEntity?: {
    id?: string;
    label?: string;
    type?: 'assignmentId';
  };
  match?: any;
};

export type RuleCondition = {
  combinator: 'and' | 'or' | null;
  value: ProgramRuleConditionValue | RuleCondition | {};
};

export type ProgramRule = {
  name: string;
  entity?: {
    id: string;
    name: string;
  };
  entityName: string;
  entityId: string;
  actionName: string;
  scheduleDate?: ScheduleDate;
  conditions?: RuleCondition[] | [];
  conditionsEquation?: { value: RuleCondition[] | [] };
  settings?: { triggerNTimes?: number };
};

export type UpsertProgramRulePayload = {
  programId: string;
  template: ProgramRulesTemplateTypes;
  name: string;
  entities: {
    source: { id: string; name: string };
    target: { id: string; name: string };
  };
  editingRuleData?:
    | ({ index?: number; conditionIndex?: number } & Partial<ProgramRule>)
    | null;
  combinator: 'and' | 'or' | null;
  rules: [] | ProgramRule[];
  scheduleDate: ProgramRule['scheduleDate'] | null;
};
