import * as z from 'zod';

import {
  NotificationMessageBlockSchema,
  NotificationMessageSchema,
  NotificationReceiverMetadataSchema,
  NotificationReceiverSchema,
} from '@nl-lms/common/feature/types';
import {
  ApiQueryFilterSchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

const preprocessObject = (val) => {
  try {
    if (typeof val === 'string') {
      return JSON.parse(val);
    }
    return val;
  } catch (e) {
    return null;
  }
};

// We preprocess because the schema is used both in GET and POST requests
export const NotificationAudienceSendSchema = z.object({
  name: z.string(),
  additionalData: z
    .preprocess(preprocessObject, z.record(z.string(), z.unknown()))
    .optional(),
  filters: z.preprocess(
    preprocessObject,
    z.union([ApiQueryFilterSchema, z.array(z.string())])
  ),
  metadata: z
    .preprocess(preprocessObject, NotificationReceiverMetadataSchema)
    .optional(),
});

export const SendNotificationReceiverSchema = NotificationReceiverSchema.omit({
  channelIdentifier: true,
}).extend({
  channelIdentifiers: z.record(z.string(), z.unknown()),
});

export const SendNotificationSchema = z.object({
  to: z.union([
    NotificationAudienceSendSchema,
    z.array(SendNotificationReceiverSchema),
  ]),
  message: z.object({
    email: z.union([
      z.string(),
      z.object({
        content: z.array(NotificationMessageBlockSchema),
        options: z.record(z.unknown()),
      }),
    ]),
  }),
  metadata: z.record(z.unknown()).optional(),
});

export const ScheduleNotificationSchema = SendNotificationSchema.extend({
  scheduleDate: TransformedDateSchema,
});

export const RenderNotificationResultSchema = z.object({
  message: z.string(),
  title: z.string(),
  options: z.record(z.unknown()),
});

export const RenderNotificationSchema = z.object({
  receiver: SendNotificationReceiverSchema,
  message: z.union([z.string(), NotificationMessageSchema]),
  channel: z.literal('email'),
});
