import React, { useCallback, useEffect, useState } from 'react';

import { MultiSelect, Sensitive, SingleSelect } from '@nl-lms/ui/components';

import { useApi } from '../../../../_common/services/api';

export const AdminLiveSessionTrainerSelect = (props) => {
  const { onChange, name, courseId, country, region, city, trainingSessionId } =
    props;
  const api = useApi();
  const [options, setOptions] = useState([]);
  const onLoadTrainers = useCallback(
    async (courseId, country, region, city) => {
      const trainers = await api.session.listTrainers({
        trainingSessionId,
        courseId,
        country,
        region,
        city,
      });
      return trainers.map((l) => ({
        value: l.id,
        label: `${l.firstName} ${l.lastName}  ·  ${l.email}`,
        entity: l,
        Component: ({ label }) => <Sensitive>{label}</Sensitive>,
      }));
    },
    []
  );

  useEffect(() => {
    async function getTrainers() {
      const newOpt = await onLoadTrainers(courseId, country, region, city);
      // @ts-ignore
      setOptions(newOpt);
    }
    getTrainers();
  }, []);

  return (
    <SingleSelect
      name={name}
      placeholder="Search trainers by name or email"
      options={options}
      onChange={onChange}
    />
  );
};

export const AdminLiveSessionTrainersSelect = (props) => {
  const {
    onChange,
    initialSelectedItems,
    name,
    courseId,
    country,
    region,
    city,
    trainingSessionId,
  } = props;
  const api = useApi();
  const [options, setOptions] = useState([]);
  const onLoadTrainers = useCallback(
    async (courseId, country, region, city) => {
      const trainers = await api.session.listTrainers({
        trainingSessionId,
        courseId,
        country,
        region,
        city,
      });
      return trainers.map((l) => ({
        value: l.id,
        label: `${l.firstName} ${l.lastName}  ·  ${l.email}`,
        entity: l,
        Component: ({ label }) => <Sensitive>{label}</Sensitive>,
      }));
    },
    [courseId, country, region, city]
  );

  useEffect(() => {
    async function getTrainers() {
      const newOpt = await onLoadTrainers(courseId, country, region, city);
      // @ts-ignore
      setOptions(newOpt);
    }
    getTrainers();
  }, [courseId, country, region, city]);

  return (
    <MultiSelect
      name={name}
      initialSelectedItems={initialSelectedItems}
      placeholder="Search trainers by name or email"
      options={options}
      returnEntireItemOnChange={false}
      onChange={onChange}
    />
  );
};
