import { useAbility } from '@casl/react';
import React from 'react';

import { FieldType } from '@nl-lms/common/shared';
import { LearningProgramScope } from '@nl-lms/feature/learning-programs/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Icon,
  Link,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  TableWithFullPagination,
  Typography,
  TypographyProps,
} from '@nl-lms/ui/components';
import {
  FilterBar,
  useListViewContext,
  useListViewTableColumns,
  useListViewTableData,
} from '@nl-lms/ui/modules';

import { AbilityContext } from '../../../Can';
import {
  useShowEntityEditSideModalForm,
  useTableAction,
} from '../../../_common/hooks';
import { learningProgramsApi } from '../../../_common/services/api';
import { getActionAbility } from '../../../_common/utils/getActionAbility';
import { routes } from '../../../lib/routes';
import { LearningProgramEditSideModal } from './LearningProgramEditSideModal';

const { useListLearningProgramsQuery, useRemoveLearningProgramsMutation } =
  learningProgramsApi;

export const LearningProgramListTable = () => {
  const { sorting, query, onChangePagination, onChangeSorting, filters } =
    useListViewContext();

  const { data, isLoading } = useListLearningProgramsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  const ability = useAbility(AbilityContext);

  const [rows, pagination] = useListViewTableData(data);
  const [removePrograms] = useRemoveLearningProgramsMutation();
  const onEditRow = useShowEntityEditSideModalForm({
    ModalComponent: LearningProgramEditSideModal,
    entityName: 'learningProgram',
  });
  const onRemoveRow = useTableAction(removePrograms, {
    withRowCountProp: true,
    confirmationMessage:
      'Are you sure that you want to remote this learning program?',
  });

  const rowActions = [
    ...getActionAbility(
      LearningProgramScope.updateLearningPrograms.action,
      LearningProgramScope.updateLearningPrograms.resource,
      {
        name: 'Edit',
        handler: onEditRow,
        Icon: Icon.EditIcon,
      }
    ),
    ...getActionAbility(
      LearningProgramScope.deleteLearningPrograms.action,
      LearningProgramScope.deleteLearningPrograms.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
        Icon: Icon.DeleteIcon,
      }
    ),
  ];

  const tableActions = [
    ...getActionAbility(
      LearningProgramScope.deleteLearningPrograms.action,
      LearningProgramScope.deleteLearningPrograms.resource,
      {
        name: 'Delete',
        handler: onRemoveRow,
      }
    ),
  ];

  const [columns, onChangeColumns] = useListViewTableColumns([
    {
      Header: 'Program',
      accessor: 'name',
      sortField: 'name',
      Cell: ({ row }) => {
        if (row.original.name) {
          if (
            ability.can(
              LearningProgramScope.viewLearningPrograms.action,
              LearningProgramScope.viewLearningPrograms.resource
            )
          ) {
            return (
              <Link
                to={routes.admin.manage.programs.item.path.full(
                  row.original.id
                )}
              >
                {row.original.name}
              </Link>
            );
          } else return <span>{row.original.name}</span>;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      sortField: 'createdAt',
      Cell: ({ row }) => {
        return <PrettyDate value={row.original.createdAt} withTime />;
      },
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      sortField: 'updatedAt',
      Cell: ({ row }) => <PrettyDate value={row.original.updatedAt} withTime />,
    },
  ]);

  return (
    <TableWithFullPagination
      columns={columns}
      data={rows}
      isLoading={isLoading}
      sorting={sorting}
      tableActions={tableActions}
      rowActions={rowActions}
      pagination={pagination}
      onChangePagination={onChangePagination}
      onChangeSorting={onChangeSorting}
      onChangeColumns={onChangeColumns}
    >
      <NoDataPlaceholder
        iconName={filters ? 'FileIcon' : 'FileTextIcon'}
        color={
          filters
            ? NoDataPlaceholderColor.warning
            : NoDataPlaceholderColor.default
        }
        title={
          filters
            ? 'There are no programs that match your search'
            : 'There are no programs created'
        }
        // @ts-ignore
        subtitle={filters ? 'You can try changing the active filters' : null}
      />
    </TableWithFullPagination>
  );
};

export const LearningProgramListTableFilters = () => {
  const { filters, onChangeFilters } = useListViewContext();
  return (
    <FilterBar
      id="learning-program-view"
      fields={[
        {
          name: 'name',
          label: 'Name',
          type: FieldType.string,
        },
        {
          name: 'created_at',
          label: 'Created At',
          type: FieldType.date,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

const LearningProgramListTableLabel = ({
  variant = 'h3',
}: {
  variant?: TypographyProps['variant'];
}) => {
  const { query } = useListViewContext();
  const { data, isLoading } = useListLearningProgramsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  if (isLoading) {
    return <Typography variant={variant}>Fetching Rows</Typography>;
  }

  const actualCount = data?.count || 0;
  return (
    <Typography variant={variant}>
      {actualCount || 0} Learning Program
      {actualCount > 1 ? 's' : ''}
    </Typography>
  );
};

LearningProgramListTable.Label = LearningProgramListTableLabel;
