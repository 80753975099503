import store from 'store';

import { getClients } from '@nl-lms/feature/subscriptions/sdk';

import { apiBaseUrl } from './api';

const { raw: subscriptionsApiClient, rtk: subscriptionsRtkApiClient } =
  getClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export { subscriptionsApiClient };

export const subscriptionsApi = subscriptionsRtkApiClient;
subscriptionsApi.enhanceEndpoints<'Subscription' | 'SubscriptionInstance'>({
  endpoints: {
    listSubscriptions: { providesTags: [{ type: 'Subscription', id: 'LIST' }] },
    getSubscription: { providesTags: [{ type: 'Subscription', id: 'LIST' }] },
    updateSubscription: {
      invalidatesTags: [{ type: 'Subscription', id: 'LIST' }],
    },
    removeSubscriptions: {
      invalidatesTags: [{ type: 'Subscription', id: 'LIST' }],
    },
    createSubscription: {
      invalidatesTags: [{ type: 'Subscription', id: 'LIST' }],
    },
    removeSubscriptionInstances: {
      invalidatesTags: [{ type: 'SubscriptionInstance', id: 'LIST' }],
    },
    createSubscriptionInstances: {
      invalidatesTags: [{ type: 'SubscriptionInstance', id: 'LIST' }],
    },
    updateSubscriptionInstance: {
      invalidatesTags: [{ type: 'SubscriptionInstance', id: 'LIST' }],
    },
    listSubscriptionInstances: {
      providesTags: [{ type: 'SubscriptionInstance', id: 'LIST' }],
    },
  },
});

export type SubscriptionsApiEndpointNames =
  keyof typeof subscriptionsApi.endpoints;
type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type SubscriptionApiMutationNames = FilterEndingWith<
  keyof typeof subscriptionsApi,
  'Mutation'
>;
