import axios, { AxiosInstance } from 'axios';

import {
  camelizeTransform,
  compressQuery,
  errorHandler,
  queryTransform,
  setAuthTokenHandler,
  setSessionSpanIdHandler,
  successHandler,
} from '../lib';
import { ActivityLog } from './ActivityLog';
import { Assessment } from './Assessment';
import { AssessmentQuestion } from './AssessmentQuestion';
import { Auth } from './Auth';
import { Checklist } from './Checklist';
import { Common } from './Common';
import { Competency } from './Competency';
import { Connect } from './Connect';
import { CostType } from './CostType';
import { Course } from './Course';
import { ElearningCourse } from './ElearningCourse';
import { ElearningSession } from './ElearningSession';
import { Email } from './Email';
import { IndividualLearning } from './IndividualLearning';
import { Learner } from './Learner';
import { LearnerApp } from './LearnerApp';
import { LearningPath } from './LearningPath';
import { Report } from './Report';
import { Room } from './Room';
import { Session } from './Session';
import { Survey } from './Survey';
import { Tag } from './Tag';
import { Trainer } from './Trainer';
import { User } from './User';
import { Vendor } from './Vendor';

export type ApiInstanceOptions = {
  errorHandler?: any;
  warningHandler?: any;
  tokenGetter: any;
};

export class ApiInstance {
  public instance: AxiosInstance;

  public activityLog: ActivityLog;
  public assessment: Assessment;
  public assessmentQuestion: AssessmentQuestion;
  public auth: Auth;
  public connect: Connect;
  public common: Common;
  public costType: CostType;
  public course: Course;
  public elearningCourse: ElearningCourse;
  public elearningSession: ElearningSession;
  public email: Email;
  public learner: Learner;
  public report: Report;
  public room: Room;
  public session: Session;
  public tag: Tag;
  public survey: Survey;
  public competency: Competency;
  public trainer: Trainer;
  public user: User;
  public vendor: Vendor;
  public learningPath: LearningPath;
  public learnerApp: LearnerApp;
  public individualLearning: IndividualLearning;
  public checklist: Checklist;

  constructor(public url: string, options: ApiInstanceOptions) {
    if (!this.url) throw new Error('No backend URL');

    this.instance = axios.create({
      baseURL: this.url,
    });

    this.instance.defaults.headers.common['Content-Type'] = 'application/json';

    const _errorHandler = errorHandler(options);
    const _successHandler = successHandler(options);
    this.instance.interceptors.request.use(
      // @ts-ignore
      setSessionSpanIdHandler,
      _errorHandler
    );
    this.instance.interceptors.request.use(
      // @ts-ignore
      setAuthTokenHandler(options.tokenGetter),
      _errorHandler
    );
    // @ts-ignore
    this.instance.interceptors.request.use(compressQuery, _errorHandler);
    // @ts-ignore
    this.instance.interceptors.request.use(queryTransform, _errorHandler);

    this.instance.interceptors.response.use(_successHandler, _errorHandler);
    this.instance.interceptors.response.use(camelizeTransform, _errorHandler);

    this.activityLog = new ActivityLog(this.instance);
    this.assessment = new Assessment(this.instance);
    this.assessmentQuestion = new AssessmentQuestion(this.instance);
    this.auth = new Auth(this.instance);
    this.connect = new Connect(this.instance);
    this.common = new Common(this.instance);
    this.costType = new CostType(this.instance);
    this.course = new Course(this.instance);
    this.elearningCourse = new ElearningCourse(this.instance);
    this.elearningSession = new ElearningSession(this.instance);
    this.email = new Email(this.instance);
    this.learner = new Learner(this.instance);
    this.report = new Report(this.instance);
    this.room = new Room(this.instance);
    this.session = new Session(this.instance);
    this.tag = new Tag(this.instance);
    this.survey = new Survey(this.instance);
    this.competency = new Competency(this.instance);
    this.trainer = new Trainer(this.instance);
    this.user = new User(this.instance);
    this.vendor = new Vendor(this.instance);
    this.learningPath = new LearningPath(this.instance);
    this.learnerApp = new LearnerApp(this.instance);
    this.individualLearning = new IndividualLearning(this.instance);
    this.checklist = new Checklist(this.instance);
  }
}
