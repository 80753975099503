import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon, InlineDotList } from '@nl-lms/ui/components';
import {
  PrettyDistanceType,
  parseDistance,
  prettyDistance,
} from '@nl-lms/ui/utils';

import './LearningBanner.scss';

export enum BannerType {
  DEFAULT = 'DEFAULT',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ALERT = 'ALERT',
}

export const InfoBanner = (props: {
  type: BannerType;
  children?: any;
  className?: string;
}) => {
  const { type = BannerType.DEFAULT, children, className } = props;

  const headerTypeClassNameMap = {
    [BannerType.DEFAULT]: 'info-banner--default',
    [BannerType.INFO]: 'info-banner--info',
    [BannerType.ALERT]: 'info-banner--alert',
    [BannerType.WARNING]: 'info-banner--warning',
    [BannerType.SUCCESS]: 'info-banner--success',
  };

  return (
    <div
      className={`info-banner ${headerTypeClassNameMap[type]} ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};

type LearningBannerProps = {
  mandatory?: boolean;
  dueDate?: Date;
  learningProgramName?: string;
  className?: string;
};

export const LearningBanner = ({
  mandatory,
  dueDate,
  learningProgramName,
  className = '',
}: LearningBannerProps) => {
  const { t } = useTranslation('learner');

  let bannerDate;
  let bannerType;

  if (dueDate) {
    const distance = prettyDistance(dueDate);
    const parsedDistance = parseDistance(distance?.content);

    const translateDistance = `${parsedDistance?.value} ${t(
      // @ts-ignore
      `common.time.${parsedDistance?.unit}`
    )}`;
    if (distance.type === PrettyDistanceType.Past) {
      bannerType = BannerType.ALERT;
      bannerDate = `${
        parsedDistance?.est
          ? // @ts-ignore
            t(`common.learningactivitycard.${parsedDistance?.est}`)
          : ''
      } ${translateDistance} ${t('common.learningactivitycard.overdue')}`;
    } else {
      bannerType = BannerType.SUCCESS;
      bannerDate = `${t('common.learningactivitycard.duein')} ${
        parsedDistance?.est
          ? // @ts-ignore
            t(`common.learningactivitycard.${parsedDistance?.est}`)
          : ''
      } ${translateDistance}`;
    }
  } else if (learningProgramName) {
    bannerType = BannerType.INFO;
  } else {
    bannerType = BannerType.DEFAULT;
  }

  return (
    (mandatory || dueDate || learningProgramName) && (
      <InfoBanner type={bannerType} className={className}>
        <InlineDotList>
          {mandatory && !learningProgramName ? (
            <span className="learner-learning-activity-card__mandatory">
              <span className="learner-learning-activity-card__mandatory-icon">
                <Icon.AlertCircleIconSmall />
              </span>
              <span className="learner-learning-activity-card__mandatory-caption">
                {t('common.learningactivitycard.mandatory')}
              </span>
            </span>
          ) : null}
          {learningProgramName ? (
            <span className="learner-learning-activity__due-date">
              <span className="learner-learning-activity-card__mandatory-icon">
                <Icon.GitIconSmall />
              </span>
              <span className="learner-learning-activity-card__mandatory-caption">
                {t('common.learningactivitycard.learningProgram')} -{' '}
                {learningProgramName}
              </span>
            </span>
          ) : null}
          {dueDate ? (
            <Trans key="duedate">
              <span className="learner-learning-activity-card__due-date">
                {bannerDate}
              </span>
            </Trans>
          ) : null}
        </InlineDotList>
      </InfoBanner>
    )
  );
};
