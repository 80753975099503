import { z } from 'zod';

import { NotificationTemplateSchema } from '../notification/notification-template.types';

export enum LearningProgramInstanceStatus {
  'NOT_STARTED' = 1,
  'IN_PROGRESS',
  'BLOCKED',
  'COMPLETED',
  'FAILED',
}

export const LearningProgramGroupSchema = z.object({
  learningAssignmentIds: z.array(z.string()),
  name: z.string(),
  description: z.string(),
});

export type LearningProgramGroup = z.infer<typeof LearningProgramGroupSchema>;

export const LearningProgramSettingsSchema = z.object({
  assignmentsOrder: z.array(z.string()),
  notifications: z
    .array(
      z.object({
        name: z.literal('initial-notification'),
        enabled: z.boolean(),
        payload: z.object({
          template: NotificationTemplateSchema.omit({
            id: true,
            updatedAt: true,
            createdAt: true,
          }),
        }),
      })
    )
    .optional(),
});

export const LearningProgramSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  learnerIds: z.array(z.string()),
  learnerGroupIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()).nullable(),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  settings: LearningProgramSettingsSchema,
  thumbnail: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningProgram = z.infer<typeof LearningProgramSchema>;
export type LearningProgramSettings = z.infer<
  typeof LearningProgramSettingsSchema
>;

export const LearningProgramInstanceSchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  learnerHistoryId: z.string(),
  learningProgramId: z.string(),
  status: z.nativeEnum(LearningProgramInstanceStatus),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningProgramInstance = z.infer<
  typeof LearningProgramInstanceSchema
>;
