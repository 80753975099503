import { AppQuery, QueryFilter, combineFilters } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  Assessment,
  AssessmentInstance,
  AssessmentInstanceAttempt,
  ChecklistInstance,
  FetchListResponse,
  IApiService,
  IndividualLearningInstance,
  LearnerLearning,
  LearnerLearningPathListItem,
  LearnerRegistrationResult,
  LearningPath as LearningPathType,
  SurveyInstance,
} from './types';

type ListForSelectType = 'assessment_question' | 'course';

export class LearnerApp
  extends ApiService
  implements IApiService<'learnerApp'>
{
  public paths: any;
  public serviceBase: string = `/learner_app`;

  constructor(props) {
    super(props);
    this.paths = {
      trainingSessionRegister: (learnerId, sessionId) =>
        `${this.serviceBase}/${learnerId}/session/${sessionId}/register`,
      trainingSessionCancelRegistration: (learnerId, sessionId) =>
        `${this.serviceBase}/${learnerId}/session/${sessionId}/cancel`,
      getTrainingSession: (learnerId, sessionId) =>
        `${this.serviceBase}/${learnerId}/session/${sessionId}`,
      trainingSessionRegisterWithToken: () => `/external/learner/register`,
      getTrainingSessionFeedbackForm: () => `/external/session/feedback`,
      getTrainingSessionRegistrationForm: () =>
        `/external/session/registration`,
      submitTrainingSessionFeedbackForm: () => `/external/session/feedback`,
      getCourse: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/course/${id}`,
      listCourse: (learnerId) => `/learner_app/${learnerId}/course`,
      requestCourse: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/course/${id}/request`,
      removeRequest: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/course/${id}/request`,
      search: (learnerId) => `${this.serviceBase}/${learnerId}/search`,
      getCompetencies: (learnerId) =>
        `${this.serviceBase}/${learnerId}/competencies`,
      getAssessmentForm: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/${id}`,
      getAssessmentAttempt: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/instance/${id}/attempt`,
      getAssessmentInstance: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/instance/${id}`,
      startAssessmentInstance: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/${id}/instance`,
      startAssessmentAttempt: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/instance/${id}/attempt/start`,
      endAssessmentAttempt: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/instance/${id}/attempt/end`,
      persistAssessmentAttemptData: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/assessment/instance/${id}/attempt/persist`,

      getFeedbackSurveyForm: (learnerId, surveyFormId, liveSessionId) =>
        `${this.serviceBase}/${learnerId}/survey/${surveyFormId}/feedback/${liveSessionId}`,
      submitFeedbackSurveyForm: (learnerId, surveyFormId, liveSessionId) =>
        `${this.serviceBase}/${learnerId}/survey/${surveyFormId}/feedback/${liveSessionId}`,

      getSurveyFormWithInstance: (learnerId, surveyFormId) =>
        `${this.serviceBase}/${learnerId}/survey/${surveyFormId}`,
      getSurveyFormInstance: (learnerId, surveyFormInstanced) =>
        `${this.serviceBase}/${learnerId}/survey/instance/${surveyFormInstanced}`,
      createSurveyFormInstance: (learnerId, surveyFormId) =>
        `${this.serviceBase}/${learnerId}/survey/${surveyFormId}/instance`,
      startSurveyFormInstance: (learnerId, surveyFormInstanced) =>
        `${this.serviceBase}/${learnerId}/survey/instance/${surveyFormInstanced}/start`,
      endSurveyFormInstance: (learnerId, surveyFormInstanced) =>
        `${this.serviceBase}/${learnerId}/survey/instance/${surveyFormInstanced}/end`,
      persistSurveyFormInstanceData: (learnerId, surveyFormInstanceId) =>
        `${this.serviceBase}/${learnerId}/survey/instance/${surveyFormInstanceId}/persist`,
      listElearningCourse: (learnerId) =>
        `${this.serviceBase}/${learnerId}/elearning/course`,
      getElearningCourse: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/elearning/course/${id}`,
      getElearningSession: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/elearning/session/${id}`,
      startOrResumeElearningSession: (token) =>
        `/external/elearning/session/${token}/start`,
      stopElearningSession: (token) =>
        `/external/elearning/session/${token}/stop`,
      elearningScormWrapper: (token) =>
        `/external/elearning/session/scorm/${token}/adapter/index.html`,
      elearningScormContent: (token, path) =>
        `/external/elearning/session/scorm/${token}/content/${path}`,
      elearningCommit: (token) =>
        `/external/elearning/session/scorm/${token}/commit`,
      elearningFinalize: (token) =>
        `/external/elearning/session/scorm/${token}/finalize`,
      elearningInitialize: (token) =>
        `/external/elearning/session/scorm/${token}/initialize`,
      listUpcomingSessions: (learnerId) =>
        `/learner_app/${learnerId}/upcoming_sessions`,
      listOpenSessions: (learnerId) =>
        `/learner_app/${learnerId}/open_sessions`,
      listLearningPath: (learnerId) => `/learning_path/learner/${learnerId}`,
      getLearningPath: (learnerId, id) =>
        `/learning_path/learner/${learnerId}/${id}`,
      joinLearningPath: (learnerId, id) =>
        `/learning_path/learner/${learnerId}/${id}/join`,

      listLearning: (learnerId) => `${this.serviceBase}/${learnerId}/learning`,
      listLiveTrainingActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/session`,
      listElearningActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/elearning`,
      listAssessmentActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/assessment`,
      listSurveyActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/survey`,
      listLearningPathActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/learning_path`,
      listIndividualActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/individual`,
      listChecklistActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/activity/checklist`,
      listTagsForSelect: (type: ListForSelectType) => {
        if (!type) return `/list/tag`;
        return `/list/tag/${type}`;
      },

      getSurveyFormForRegistration: (learnerId, id) =>
        `${this.serviceBase}/${learnerId}/survey/${id}/registration`,

      exportLearningHistory: (learnerId) =>
        `${this.serviceBase}/${learnerId}/export`,

      getLearnerReport: (learnerId, report_name) =>
        `${this.serviceBase}/${learnerId}/analytics/${report_name}`,

      createIndividualLearning: (learnerId) =>
        `${this.serviceBase}/${learnerId}/individual`,

      getChecklistWithInstance: (learnerId, checklistId) =>
        `${this.serviceBase}/${learnerId}/checklist/${checklistId}`,
      getChecklistInstance: (learnerId, checklistInstanceId) =>
        `${this.serviceBase}/${learnerId}/checklist/instance/${checklistInstanceId}`,
      startChecklistInstance: (learnerId, checklistInstanceId) =>
        `${this.serviceBase}/${learnerId}/checklist/instance/${checklistInstanceId}/start`,
      persistChecklistInstanceItems: (learnerId, checklistInstanceId) =>
        `${this.serviceBase}/${learnerId}/checklist/instance/${checklistInstanceId}/persist`,
      endChecklistInstance: (learnerId, checklistInstanceId) =>
        `${this.serviceBase}/${learnerId}/checklist/instance/${checklistInstanceId}/end`,
    };
  }

  trainingSessionRegister = ({ learnerId, id, data }) =>
    this.api.post(
      this.paths.trainingSessionRegister(learnerId, id),
      snakelize(data)
    );

  trainingSessionCancelRegistration = ({ learnerId, sessionId, data }) =>
    this.api.post(
      this.paths.trainingSessionCancelRegistration(learnerId, sessionId),
      snakelize(data)
    );

  getTrainingSession = ({ learnerId, id }) =>
    this.api.get(this.paths.getTrainingSession(learnerId, id));

  trainingSessionRegisterWithToken = ({ token, answers = null }) =>
    this.api.post<
      null,
      {
        message: string;
        name: LearnerRegistrationResult;
      }
    >(
      this.paths.trainingSessionRegisterWithToken(),
      snakelize({
        token,
        answers: answers ? snakelize(answers) : null,
      })
    );

  getTrainingSessionFeedbackForm = ({ token }) =>
    this.api.get<null, { sessionData: any; surveyForm: any }>(
      this.paths.getTrainingSessionFeedbackForm(),
      {
        params: { tk: token },
      }
    );

  getFeedbackSurveyForm = ({ learnerId, surveyFormId, learningUnitId }) =>
    this.api.get(
      this.paths.getFeedbackSurveyForm(learnerId, surveyFormId, learningUnitId)
    );

  getSurveyFormWithInstance = ({ learnerId, id }) =>
    this.api.get(this.paths.getSurveyFormWithInstance(learnerId, id));

  getSurveyFormInstance = ({ learnerId, id }) =>
    this.api.get<null, SurveyInstance>(
      this.paths.getSurveyFormInstance(learnerId, id)
    );

  createSurveyFormInstance = ({ learnerId, surveyFormId }) =>
    this.api.post(
      this.paths.createSurveyFormInstance(learnerId, surveyFormId),
      {}
    );

  startSurveyFormInstance = ({ learnerId, id }) =>
    this.api.post(this.paths.startSurveyFormInstance(learnerId, id), {});

  endSurveyFormInstance = ({ learnerId, id, answers, timeSpent }) =>
    this.api.post(this.paths.endSurveyFormInstance(learnerId, id), {
      answers: snakelize(answers),
      time_spent: timeSpent,
    });

  persistSurveyFormInstanceData = ({
    learnerId,
    instanceId,
    timeSpent,
    surveyFormId,
  }) =>
    this.api.post(
      this.paths.persistSurveyFormInstanceData(learnerId, instanceId),
      {
        time_spent: timeSpent,
        surveyFormId,
      }
    );

  listSurveyActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }): Promise<FetchListResponse<SurveyInstance>> =>
    this.api.get(this.paths.listSurveyActivity(learnerId), {
      params: { query },
    });

  listChecklistActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }): Promise<FetchListResponse<ChecklistInstance>> =>
    this.api.get(this.paths.listChecklistActivity(learnerId), {
      params: { query },
    });

  submitFeedbackSurveyForm = ({
    learnerId,
    surveyFormId,
    learningUnitId,
    answers,
    timeSpent,
  }) =>
    this.api.post(
      this.paths.submitFeedbackSurveyForm(
        learnerId,
        surveyFormId,
        learningUnitId
      ),
      { answers: snakelize(answers), time_spent: timeSpent }
    );

  getTrainingSessionRegistrationForm = (token) =>
    this.api.get<null, { sessionData: any; surveyForm: any }>(
      this.paths.getTrainingSessionRegistrationForm(),
      {
        params: { tk: token },
      }
    );

  submitTrainingSessionFeedbackForm = ({ token, answers, timeSpent }) =>
    this.api.post(this.paths.submitTrainingSessionFeedbackForm(), {
      token,
      answers: snakelize(answers),
      time_spent: timeSpent,
    });

  getCourse = ({ learnerId, id }) =>
    this.api.get(this.paths.getCourse(learnerId, id));

  listCourse = ({
    learnerId,
    query,
    categories,
  }: {
    learnerId: string;
    query: AppQuery;
    categories: any;
  }) => {
    const parsedQuery = parseCategoryFilters(query, categories);
    return this.api.get(this.paths.listCourse(learnerId), {
      params: { query: parsedQuery },
    });
  };

  requestCourse = ({ learnerId, id }) =>
    this.api.post(this.paths.requestCourse(learnerId, id));

  removeRequest = ({ learnerId, id }) =>
    this.api.delete(this.paths.removeRequest(learnerId, id));

  getAssessmentAttempt = ({ learnerId, assessmentInstanceId }) =>
    this.api.get(
      this.paths.getAssessmentAttempt(learnerId, assessmentInstanceId)
    ) as Promise<AssessmentInstance>;

  startAssessmentAttempt = ({ learnerId, assessmentInstanceId }) =>
    this.api.post(
      this.paths.startAssessmentAttempt(learnerId, assessmentInstanceId)
    ) as Promise<AssessmentInstance>;

  endAssessmentAttempt = ({
    assessmentInstanceId,
    learnerId,
    answers,
    timeSpent,
  }) =>
    this.api.post(
      this.paths.endAssessmentAttempt(learnerId, assessmentInstanceId),
      {
        answers,
        time_spent: timeSpent,
      }
    ) as Promise<{
      assessmentFormInstance: AssessmentInstance;
      assessmentFormInstanceAttempt: AssessmentInstanceAttempt;
    }>;

  persistAssessmentAttemptData = async ({
    assessmentInstanceId,
    learnerId,
    answers,
    timeSpent,
  }) => {
    await this.api.post(
      this.paths.persistAssessmentAttemptData(learnerId, assessmentInstanceId),
      {
        answers,
        time_spent: timeSpent,
      }
    );
    return Promise.resolve(true);
  };

  getAssessmentInstance = ({ learnerId, assessmentInstanceId }) =>
    this.api.get(
      this.paths.getAssessmentInstance(learnerId, assessmentInstanceId)
    ) as Promise<AssessmentInstance>;

  startAssessmentInstance = ({
    learnerId,
    assessmentFormId,
    data,
  }: {
    learnerId: string;
    assessmentFormId: string;
    data?: any;
  }) =>
    this.api.post(
      this.paths.startAssessmentInstance(learnerId, assessmentFormId),
      snakelize(data)
    ) as Promise<AssessmentInstance>;

  getAssessmentForm = ({ learnerId, id }) =>
    this.api.get(
      this.paths.getAssessmentForm(learnerId, id)
    ) as Promise<Assessment>;

  listElearningCourse = ({
    learnerId,
    query,
    categories,
  }: {
    learnerId: string;
    query: AppQuery;
    categories: any;
  }) => {
    const parsedQuery = parseCategoryFilters(query, categories);
    return this.api.get(this.paths.listElearningCourse(learnerId), {
      params: { query: parsedQuery },
    });
  };

  getElearningCourse = ({ learnerId, id }: { learnerId: string; id: string }) =>
    this.api.get(this.paths.getElearningCourse(learnerId, id));

  listCompetencies = ({ learnerId }: { learnerId: string }) =>
    this.api.get(this.paths.getCompetencies(learnerId));

  getElearningSession = ({
    learnerId,
    id,
  }: {
    learnerId: string;
    id: string;
  }) => this.api.get(this.paths.getElearningSession(learnerId, id));

  startOrResumeElearningSession = ({ token }) =>
    this.api.post<
      null,
      {
        token: string;
        adapterUrl: string;
        contentUrl: string;
        cmsData: Record<string, any>;
        lmsData: Record<string, any>;
        payload: string;
      }
    >(this.paths.startOrResumeElearningSession(token), {
      token,
    });

  stopElearningSession = async ({ token, blurTotalTime, focusTotalTime }) => {
    const stopElearningSessionUrl = `${this.paths.stopElearningSession(
      token
    )}?idle_time=${blurTotalTime}&active_time=${focusTotalTime}`;

    if (navigator.sendBeacon) {
      navigator.sendBeacon(
        `${this.api.defaults.baseURL}${stopElearningSessionUrl}`
      );
    } else {
      await this.api.post(stopElearningSessionUrl);
    }

    return true;
  };

  listUpcomingSessions = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listUpcomingSessions(learnerId), {
      params: { query },
    });

  listOpenSessions = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listOpenSessions(learnerId), {
      params: { query },
    });

  listLearningPath = ({
    learnerId,
    query,
    categories,
  }: {
    learnerId: string;
    query: AppQuery;
    categories: any;
  }): Promise<FetchListResponse<LearnerLearningPathListItem>> => {
    const parsedQuery = parseCategoryFilters(query, categories);
    return this.api.get(this.paths.listLearningPath(learnerId), {
      params: { query: parsedQuery },
    });
  };

  listIndividualActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }): Promise<FetchListResponse<IndividualLearningInstance>> =>
    this.api.get(this.paths.listIndividualActivity(learnerId), {
      params: { query },
    });

  getLearningPath = ({
    learnerId,
    id,
  }: {
    learnerId: string;
    id: string;
  }): Promise<
    FetchListResponse<Omit<LearningPathType, 'tags' | 'learner_groups'>>
  > => this.api.get(this.paths.getLearningPath(learnerId, id));

  joinLearningPath = ({
    learnerId,
    id,
  }: {
    learnerId: string;
    id: string;
  }): Promise<boolean> =>
    this.api.post(this.paths.joinLearningPath(learnerId, id));

  listLearning = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }): Promise<LearnerLearning> => {
    return this.api.get(this.paths.listLearning(learnerId), {
      params: { query },
    });
  };

  listLiveTrainingActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listLiveTrainingActivity(learnerId), {
      params: { query },
    });

  listElearningActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listElearningActivity(learnerId), {
      params: { query },
    });

  listAssessmentActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listAssessmentActivity(learnerId), {
      params: { query },
    });
  listLearningPathActivity = ({
    learnerId,
    query,
  }: {
    learnerId: string;
    query: AppQuery;
  }) =>
    this.api.get(this.paths.listLearningPathActivity(learnerId), {
      params: { query },
    });

  listTagsForSelect = ({ learnerId, type }) =>
    this.api.get(this.paths.listTagsForSelect(type), {
      params: { learner_id: learnerId },
    });

  search = ({ learnerId, searchTerm }) =>
    this.api.get<null, any[]>(this.paths.search(learnerId), {
      params: { term: searchTerm },
    });

  getSurveyFormForRegistration = ({ learnerId, id }) =>
    this.api.get(this.paths.getSurveyFormForRegistration(learnerId, id));

  exportLearningHistory = ({ learnerId }) =>
    this.api.get(this.paths.exportLearningHistory(learnerId), {
      responseType: 'blob',
    });

  getTimeSpentLearning = ({ learnerId }) =>
    this.api.get(this.paths.getLearnerReport(learnerId, 'time_spent_learning'));

  getLearningAssignmentsGroupedByStatus = ({ learnerId }) =>
    this.api.get(
      this.paths.getLearnerReport(
        learnerId,
        'learning_assignments_grouped_by_status'
      )
    );

  getLearningGroupedByDay = ({ learnerId }) =>
    this.api.get(
      this.paths.getLearnerReport(learnerId, 'learning_grouped_by_day')
    );

  getLearningGroupedByType = ({ learnerId }) =>
    this.api.get(
      this.paths.getLearnerReport(learnerId, 'learning_grouped_by_type')
    );

  getLearningProgress = ({ learnerId }) =>
    this.api.get(this.paths.getLearnerReport(learnerId, 'learning_progress'));

  createIndividualLearning = ({ learnerId, data }) =>
    this.api.post(
      this.paths.createIndividualLearning(learnerId),
      snakelize(data)
    );

  getChecklistWithInstance = ({ learnerId, id }) =>
    this.api.get(this.paths.getChecklistWithInstance(learnerId, id));

  getChecklistInstance = ({ learnerId, id }) =>
    this.api.get(this.paths.getChecklistInstance(learnerId, id));

  startChecklistInstance = ({ learnerId, id }) =>
    this.api.post(this.paths.startChecklistInstance(learnerId, id), {});

  persistChecklistInstanceItems = ({ learnerId, id, items, timeSpent }) =>
    this.api.post(this.paths.persistChecklistInstanceItems(learnerId, id), {
      items: snakelize(items),
      time_spent: timeSpent,
    });

  endChecklistInstance = ({ learnerId, id, items, timeSpent }) =>
    this.api.post(this.paths.endChecklistInstance(learnerId, id), {
      items: snakelize(items),
      time_spent: timeSpent,
    });
}

const getCategoryFilterValue = (categories, categoryLabel) => {
  let filter_value = null;
  for (const category of categories) {
    if (category.label === categoryLabel) {
      filter_value = category.filter;
      break;
    } else if (category.children && category.children.length) {
      filter_value = getCategoryFilterValue(category.children, categoryLabel);
      if (filter_value) break;
    }
  }
  return filter_value;
};

const parseCategoryFilters = (query, categories) => {
  if (!query.filters) return query;
  const queryFilter = new QueryFilter({ filters: query.filters });
  const categoryFilterIndex = queryFilter.filters.findIndex(
    (f) => f.type === 'filter' && f.field === 'category'
  );
  if (categoryFilterIndex === -1) return query;
  const categoryLabel = queryFilter.filters[categoryFilterIndex].value;
  const actualFilterValue = getCategoryFilterValue(categories, categoryLabel);
  queryFilter.remove(categoryFilterIndex);
  if (!actualFilterValue)
    return { ...query, filters: queryFilter.appQueryFilter };

  return {
    ...query,
    filters: combineFilters(queryFilter.appQueryFilter, actualFilterValue),
  };
};
