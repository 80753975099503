import { AppQuery } from '@nl-lms/common/shared';

import { ApiService } from '../lib';
import { IApiService, LiveSessionStatistics } from './types';

export class Report extends ApiService implements IApiService<'report'> {
  public paths: any;
  public serviceBase = '/report';

  constructor(props) {
    super(props);

    this.paths = {
      fetchTrainingSessionsProgress: () =>
        `${this.serviceBase}/training_sessions_progress`,
      costPerLearner: () => `${this.serviceBase}/cost_per_learner`,
      learnedLearners: () => `${this.serviceBase}/learned_learners`,
      cancelledSessions: () => `${this.serviceBase}/canceled_sessions`,
      sessionFillRate: () => `${this.serviceBase}/session_fill_rate`,
      costBreakdown: () => `${this.serviceBase}/cost_breakdown`,
      budgetSpending: () => `${this.serviceBase}/budget_spending`,
      costsByCourse: () => `${this.serviceBase}/costs_by_course`,
      getLiveSessionStatistics: (sessionId) =>
        `${this.serviceBase}/session/${sessionId}`,
      mandatoryAssignments: () => `${this.serviceBase}/mandatory_assignments`,
      mandatoryAssignmentsPerLearner: (learnerId) =>
        `${this.serviceBase}/mandatory_assignments/${learnerId}`,
      mostAccessedSession: () => `${this.serviceBase}/most_accessed_session`,
      mostActiveUser: () => `${this.serviceBase}/most_active_user`,
      learnerTimeSpentLearning: (learnerId) =>
        `${this.serviceBase}/time_spent_learning/${learnerId}`,
      learnerLearningProgress: (learnerId) =>
        `${this.serviceBase}/learning_progress/${learnerId}`,
    };
  }

  fetchTrainingSessionsProgress = (filters) =>
    this.api.get(this.paths.fetchTrainingSessionsProgress(), {
      params: { query: { filters } },
    });

  costPerLearner = (filters) =>
    this.api.get<null, { avgParticipantPrice: number }>(
      this.paths.costPerLearner(),
      {
        params: { query: { filters } },
      }
    );

  learnedLearners = (filters) =>
    this.api.get<
      null,
      { totalLearned: number; totalAll: number; percentage: number }
    >(this.paths.learnedLearners(), {
      params: { query: { filters } },
    });

  getLiveSessionStatistics = ({ liveSessionId }) =>
    this.api.get<null, LiveSessionStatistics>(
      this.paths.getLiveSessionStatistics(liveSessionId)
    );

  cancelledSessions = (filters) => {
    const query: AppQuery = {};
    if (filters) query.filters = filters;
    return this.api.get<null, { total: number; statusTotal: number }>(
      this.paths.cancelledSessions(),
      {
        params: { query },
      }
    );
  };

  sessionFillRate = (filters) =>
    this.api.get<null, { percentage: number }>(this.paths.sessionFillRate(), {
      params: { query: { filters } },
    });

  costBreakdown = (filters) =>
    this.api.get<null, any[]>(this.paths.costBreakdown(), {
      params: { query: { filters } },
    });

  budgetSpending = (filters) =>
    this.api.get<null, any[]>(this.paths.budgetSpending(), {
      params: { query: { filters } },
    });

  costsByCourse = (filters) =>
    this.api.get(this.paths.costsByCourse(), {
      params: { query: { filters } },
    });

  mandatoryAssignments = (filters) =>
    this.api.get(this.paths.mandatoryAssignments(), {
      params: { query: { filters } },
    });

  mostAccessedSession = (filters) =>
    this.api.get(this.paths.mostAccessedSession(), {
      params: { query: { filters } },
    });

  mostActiveUser = (filters) =>
    this.api.get(this.paths.mostActiveUser(), {
      params: { query: { filters } },
    });

  learnerTimeSpentLearning = ({ learnerId }) =>
    this.api.get<
      null,
      {
        time_spent_learning: number;
      }[]
    >(this.paths.learnerTimeSpentLearning(learnerId));

  learnerLearningProgress = ({ learnerId }) =>
    this.api.get<
      null,
      {
        status: string;
        type: string;
        value: number;
      }[]
    >(this.paths.learnerLearningProgress(learnerId));
}
