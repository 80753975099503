import React, { useCallback, useMemo } from 'react';

import { C } from '@nl-lms/ui/constants';

import { SingleSelect } from '@nl-lms/ui/components';

type Location = {
  country: string;
  region: string;
  city: string;
};

type LocationSelectProps = {
  onChange: (location: Location) => void;
  initialLocation?: Location;
  name: string;
  showVirtualLocation?: boolean;
  hasError?: boolean;
  onBlur?: () => void;
};

export const LocationSelect = (props: LocationSelectProps) => {
  const {
    initialLocation,
    onChange,
    name,
    hasError = false,
    onBlur = undefined,
  } = props;
  const options = useMemo(() => {
    return C.LOCATION_DATA.map((l) => ({
      entity: {
        country: l.countryCode,
        region: l.regionCode,
        city: l.cityName,
      },
      label: l.label,
      value: l.value,
    }));
  }, []);
  const initialSelectedItem = initialLocation
    ? `${initialLocation.country},${initialLocation.region},${initialLocation.city}`
    : null;
  const onChangeLocation = useCallback(
    (option) => {
      onChange(option?.entity);
    },
    [onChange]
  );
  return (
    <SingleSelect
      onChange={onChangeLocation}
// @ts-ignore
      initialSelectedItem={initialSelectedItem}
      placeholder="Search for location"
      options={options}
      hasError={hasError}
      returnEntireItemOnChange
      name={name}
      onBlur={onBlur}
    />
  );
};
