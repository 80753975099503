import React from 'react';
import { useUploadFile } from '../hooks';
import { TextEditor } from '@nl-lms/ui/components';

export const TextEditorWithFileUpload = (
  props: Omit<React.ComponentProps<typeof TextEditor>, 'onUploadFile'>
) => {
  const { onUpload } = useUploadFile({ type: 'text_editor_asset' });

  return <TextEditor {...props} onUploadFile={onUpload} />;
};
