import React from 'react';

import { C } from '../../constants';
import { Button } from '../Button/Button';
import * as Icon from '../Icon';
import { translateDurationInHours } from '../../utils';
import { useTranslation } from 'react-i18next';

import './ScheduleTemplateList.scss';
import { TidComponent } from '../index.types';
import { useTestProps } from '@nl-lms/ui/hooks';

type ScheduleItem = { type: number | string; duration: number };
type Props = TidComponent<{
  items: ScheduleItem[];
  onRemove?: any;
  onItemClick?: any;
  renderTime?: any;
}>;
export const ScheduleTemplateList = ({
  items,
  onRemove,
  onItemClick,
  renderTime,
  ...props
}: Props) => {
  const commonProps = useTestProps(props);

  const { t } = useTranslation('learner');
  let currentDuration = 0;

  return (
    <div className="schedule-template-list" {...commonProps}>
      {items.map((item, index) => {
        currentDuration += item.duration;

        return (
          <div key={index} className="schedule-template-list__item">
            <div
              className={`schedule-template-list__item-content schedule-template-list__item--${
                item.type == C.I_SCHEDULE_BLOCK.CONTENT_DELIVERY
                  ? 'delivery'
                  : 'break'
              }`}
              onClick={() => {
                if (!onItemClick) return;
                onItemClick(index, item);
              }}
            >
              <div className="schedule-template-list__item-type">
                {item.type == C.I_SCHEDULE_BLOCK.CONTENT_DELIVERY &&
                  t('common.learningunit.contentdelivery')}
                {item.type == C.I_SCHEDULE_BLOCK.BREAK &&
                  t('common.learningunit.break')}
              </div>
              <div className="schedule-template-list__item-duration">
                {renderTime
                  ? renderTime({
                      ...item,
                      currentDuration,
                    })
                  : translateDurationInHours(item.duration)}
              </div>
            </div>
            {onRemove && (
              <div className="schedule-template-list__item-action">
                <Button
                  label=""
                  icon={<Icon.DeleteIcon />}
                  ghost
                  onClick={() => onRemove(index, item)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
