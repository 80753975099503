import { useAbility } from '@casl/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AssessmentScope } from '@nl-lms/feature/assessment/sdk';
import { ChecklistScope } from '@nl-lms/feature/checklist/sdk';
import { ElearningScope } from '@nl-lms/feature/elearning/sdk';
import { IndividualLearningScope } from '@nl-lms/feature/individual-learning/sdk';
import { LearningPathScope } from '@nl-lms/feature/learning-programs/sdk';
import { LiveLearningScope } from '@nl-lms/feature/live-learning/sdk';
import { SurveyScope } from '@nl-lms/feature/surveys/sdk';
import { Box, FloatingMenu, Icon } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useTracker } from '@nl-lms/ui/modules';
import { AbilityContext } from '@nl-lms/web/Can';
import { NavigationMenu } from '@nl-lms/web/_common/layouts/MainLayout/NavigationMenu';

import MainLayout, {
  UserMenuItem,
} from '../../../../_common/layouts/MainLayout/MainLayout';
import {
  authStore,
  logout,
  useChangePassword,
} from '../../../../_common/modules/Auth/auth';
import { routes } from '../../../../lib/routes';
import { LearnerAppSearchCommandMenu } from '../LearnerSearchCommandMenu';
import './LearnerAppMainLayout.scss';

const LearnerAppMainLayout = ({ children }: { children: React.ReactNode }) => {
  const goToAdminDashboard = useRoutingAction({
    route: routes.admin.path.full(),
  });
  const goToAdminAnalytics = useRoutingAction({
    route: routes.admin.analytics.path.full(),
    raw: true,
  });

  const tracker = useTracker();

  useEffect(() => {
    tracker.stopRecording();
  }, []);

  const ability = useAbility(AbilityContext);
  const switchToAdmin = () => {
    authStore.activeApp = 'admin';
    goToAdminDashboard();
  };

  const switchToAdminAnalytics = () => {
    authStore.activeApp = 'admin';
    goToAdminAnalytics();
  };

  const [firstNameInitial = ''] = authStore.user.firstName;
  const [lastNameInitial = ''] = authStore.user.lastName;

  const userMenuItems: UserMenuItem[] = [];

  const { pathname } = useLocation();
  if (authStore.hasAdminRole) {
    userMenuItems.push({ name: 'Admin', handler: switchToAdmin });
  } else if (authStore.hasManagerRole) {
    userMenuItems.push({ name: 'Manager', handler: switchToAdmin });
  } else if (authStore.hasEvaluatorRole) {
    userMenuItems.push({ name: 'Evaluator', handler: switchToAdmin });
  } else if (authStore.hasAuditorRole) {
    userMenuItems.push({ name: 'Auditor', handler: switchToAdminAnalytics });
  }

  const onHelpCLick = useCallback(
    () =>
      C.FEATURES.learnerSupportUrl
        ? window.open(C.FEATURES.learnerSupportUrl)
        : null,
    []
  );
  const { t } = useTranslation('learner');
  const onChangePassword = useChangePassword();
  if (!C.SSO) {
    userMenuItems.push({
      name: 'Change Password',
      handler: onChangePassword,
    });
  }
  const goToProfile = useRoutingAction({
    route: routes.portal.profile.path.full(),
  });
  userMenuItems.push({ name: t('layout.profile'), handler: goToProfile });

  userMenuItems.push({ name: t('layout.logout'), handler: logout });

  return (
    <MainLayout>
      <NavigationMenu.Provider name="portal-navigation" defaultSize="small">
        <NavigationMenu>
          <NavigationMenu.Header>
            <NavigationMenu.HeaderLogo link={routes.portal.path.full()} />
          </NavigationMenu.Header>

          <Box flex={{ flex: 1, alignItems: 'center' }}>
            <NavigationMenu.List className="learner-app-main-layout__navigation-list">
              <NavigationMenu.ListItem
                isActive={pathname === routes.portal.path.full()}
                link={routes.portal.path.full()}
              >
                <NavigationMenu.ListItemContent>
                  <NavigationMenu.ListItemIcon>
                    <Icon.HomeIcon />
                  </NavigationMenu.ListItemIcon>
                  <NavigationMenu.ListItemLabel>
                    {t('layout.dashboard')}
                  </NavigationMenu.ListItemLabel>
                </NavigationMenu.ListItemContent>
              </NavigationMenu.ListItem>
              <NavigationMenu.ListItem
                isActive={pathname.startsWith(
                  routes.portal.catalogue.path.full()
                )}
                isVisible={
                  ability.can(
                    LiveLearningScope.portal.viewLiveCourse.action,
                    LiveLearningScope.portal.viewLiveCourse.resource
                  ) ||
                  ability.can(
                    ElearningScope.portal.viewElearningCourse.action,
                    ElearningScope.portal.viewElearningCourse.resource
                  ) ||
                  ability.can(
                    LearningPathScope.portal.viewLearningPath.action,
                    LearningPathScope.portal.viewLearningPath.resource
                  )
                }
              >
                <NavigationMenu.ListItemContent>
                  <NavigationMenu.ListItemIcon>
                    <Icon.ListIcon />
                  </NavigationMenu.ListItemIcon>
                  <NavigationMenu.ListItemLabel>
                    {t('layout.opportunities')}
                  </NavigationMenu.ListItemLabel>
                  <NavigationMenu.ListItemArrow />
                </NavigationMenu.ListItemContent>
                <NavigationMenu.Sublist>
                  <NavigationMenu.SublistItem
                    link={routes.portal.catalogue.liveCourses.path.full()}
                    isVisible={ability.can(
                      LiveLearningScope.portal.viewLiveCourse.action,
                      LiveLearningScope.portal.viewLiveCourse.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.livecourses')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.catalogue.elearningCourses.path.full()}
                    isVisible={ability.can(
                      ElearningScope.portal.viewElearningCourse.action,
                      ElearningScope.portal.viewElearningCourse.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.elearningcourses')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.catalogue.learningPaths.path.full()}
                    isVisible={ability.can(
                      LearningPathScope.portal.viewLearningPath.action,
                      LearningPathScope.portal.viewLearningPath.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.learningpaths')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                </NavigationMenu.Sublist>
              </NavigationMenu.ListItem>
              <NavigationMenu.ListItem
                isActive={pathname.startsWith(
                  routes.portal.activity.path.full()
                )}
              >
                <NavigationMenu.ListItemContent>
                  <NavigationMenu.ListItemIcon>
                    <Icon.ArchiveIcon />
                  </NavigationMenu.ListItemIcon>
                  <NavigationMenu.ListItemLabel>
                    {t('layout.activity')}
                  </NavigationMenu.ListItemLabel>
                  <NavigationMenu.ListItemArrow />
                </NavigationMenu.ListItemContent>
                <NavigationMenu.Sublist>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.elearning.path.full()}
                    isVisible={ability.can(
                      ElearningScope.portal.viewElearningSession.action,
                      ElearningScope.portal.viewElearningSession.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.elearningsessions')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.learningPath.path.full()}
                    isVisible={ability.can(
                      LearningPathScope.portal.viewLearningPathInstance.action,
                      LearningPathScope.portal.viewLearningPathInstance.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.learningpaths')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.liveSession.path.full()}
                    isVisible={ability.can(
                      LiveLearningScope.portal.viewLiveSessionLearner.action,
                      LiveLearningScope.portal.viewLiveSessionLearner.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.livesessions')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.individual.path.full()}
                    isVisible={ability.can(
                      IndividualLearningScope.portal
                        .viewIndividualLearningInstance.action,
                      IndividualLearningScope.portal
                        .viewIndividualLearningInstance.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.individual')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.assessment.path.full()}
                    isVisible={ability.can(
                      AssessmentScope.portal.viewAssessmentFormInstance.action,
                      AssessmentScope.portal.viewAssessmentFormInstance.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.assessments')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.survey.path.full()}
                    isVisible={ability.can(
                      SurveyScope.portal.viewSurveyFormInstance.action,
                      SurveyScope.portal.viewSurveyFormInstance.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.surveys')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                  <NavigationMenu.SublistItem
                    link={routes.portal.activity.checklist.path.full()}
                    isVisible={ability.can(
                      ChecklistScope.portal.viewChecklistInstance.action,
                      ChecklistScope.portal.viewChecklistInstance.resource
                    )}
                  >
                    <NavigationMenu.SublistItemLabel>
                      {t('layout.checklists')}
                    </NavigationMenu.SublistItemLabel>
                  </NavigationMenu.SublistItem>
                </NavigationMenu.Sublist>
              </NavigationMenu.ListItem>
            </NavigationMenu.List>
          </Box>
          <NavigationMenu.Footer>
            <FloatingMenu items={userMenuItems} align="left">
              <button className="navigation-menu__user-button">
                <Icon.UserIcon />
                <span className="navigation-menu__user-button-label">
                  {authStore.user.firstName} {authStore.user.lastName}
                </span>
              </button>
            </FloatingMenu>
            {C.FEATURES.learnerSupportUrl ? (
              <div
                className={`main-layout__menu-help`}
                onClick={onHelpCLick}
                style={{ marginBottom: '0' }}
              >
                <Icon.HelpIcon />
                <div className="main-layout__menu-help-label">
                  {t('layout.helpcenter')}
                </div>
              </div>
            ) : null}
          </NavigationMenu.Footer>
        </NavigationMenu>
        <MainLayout.Header>
          <LearnerAppSearchCommandMenu />
        </MainLayout.Header>
        <MainLayout.MainContent>{children}</MainLayout.MainContent>
      </NavigationMenu.Provider>
    </MainLayout>
  );
};

export const LearnerAppLayoutCardGrid = ({ children }) => (
  <div className="learner-app-main-layout__card-grid">{children}</div>
);

export const LearnerAppLayoutTileGrid = ({ children }) => (
  <div className="learner-app-main-layout__tile-grid">{children}</div>
);

export const LearnerAppLayoutCardGridItem = ({ children }) => (
  <div className="learner-app-main-layout__card-grid-item">{children}</div>
);

export const LearnerAppLayoutTileGridItem = ({ children }) => (
  <div className="learner-app-main-layout__tile-grid-item">{children}</div>
);

export const LearnerAppLayoutCardGridNoData = ({ children }) => (
  <div className="learner-app-main-layout__card-grid-no-data">{children}</div>
);

export const LearnerAppLayoutTileGridNoData = ({ children }) => (
  <div className="learner-app-main-layout__tile-grid-no-data">{children}</div>
);

LearnerAppMainLayout.CardGrid = LearnerAppLayoutCardGrid;
LearnerAppMainLayout.CardGridNoData = LearnerAppLayoutCardGridNoData;
LearnerAppMainLayout.CardGridItem = LearnerAppLayoutCardGridItem;
LearnerAppMainLayout.TileGrid = LearnerAppLayoutTileGrid;
LearnerAppMainLayout.TileGridItem = LearnerAppLayoutTileGridItem;
LearnerAppMainLayout.TileGridNoData = LearnerAppLayoutTileGridNoData;
export default LearnerAppMainLayout;
