import React from 'react';

export const BorderedCheckIconSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1H9.75C10.4404 1 11 1.54721 11 2.22222V10.7778C11 11.4528 10.4404 12 9.75 12H2.25C1.55964 12 1 11.4528 1 10.7778V2.22222C1 1.54721 1.55964 1 2.25 1H5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4 7L5 8L8 5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
