import React, { useContext } from 'react';
import { FieldOption } from '../FilterBar.types';
import { FilterBarContext } from '../FilterBarContext/FilterBarContext';
import { getOptionClassName, getOptionId } from '../filterUtils';

export const FilterBarFieldOption = ({ field }) => {
  const { activeOptionId, getOptionProps } = useContext(FilterBarContext);

  const option: FieldOption = {
    id: getOptionId(field.name, field.name, 'FieldOption'),
    type: 'FieldOption',
    value: field.name,
    label: field.label,
    field,
  };

  return (
    <li
      className={getOptionClassName(option.id === activeOptionId, false)}
      {...getOptionProps(option, option.id)}
    >
      {field.label}
    </li>
  );
};
