import { useMemo } from 'react';
import store from 'store';

import { ApiInstance } from '@nl-lms/sdk/backend';
import { C } from '@nl-lms/ui/constants';

export const apiBaseUrl = (version = 'v1') => {
  return `${C.ENV.API_BASE}/${C.ENV.API_ROOT}/${version}`;
};

export const api = new ApiInstance(apiBaseUrl(), {
  tokenGetter: () => store.get('accessToken'),
});

export const useApi = (
  options: {
    errorHandler?: any;
    warningHandler?: any;
  } = {}
) => {
  const { errorHandler = console.error, warningHandler = console.warn } =
    options;

  return useMemo(() => {
    return new ApiInstance(apiBaseUrl(), {
      errorHandler,
      warningHandler,
      tokenGetter: () => store.get('accessToken'),
    });
  }, []);
};
