import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Box,
  Button,
  DateTime,
  FormField,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';

import { useAction } from '../../../_common/hooks/useAction';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminLearnerMultiSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminSubscriptionSelect } from './AdminSubscriptionSelect';

type SchemaType = {
  startDate: Date;
  endDate: Date;
  learnerIds: string[];
  subscriptionId: string;
};
const Schema: yup.ObjectSchema<SchemaType> = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  subscriptionId: yup.string().required(),
  learnerIds: yup.array(yup.string().required()).required(),
});

const { useCreateSubscriptionInstancesMutation } = subscriptionsApi;

export const AdminSubscriptionInstanceCreateFormSideModal = ({
  subscriptionId,
}: {
  subscriptionId?: string;
}) => {
  const { hide } = useModal();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      subscriptionId,
    },
  });

  const [createSubscriptionInstances, { isLoading, error }] =
    useCreateSubscriptionInstancesMutation();

  const onSubmit = useCallback(async (data: SchemaType) => {
    const res = await createSubscriptionInstances(
      data.learnerIds.map((learnerId) => ({
        learnerId,
        startDate: data.startDate,
        endDate: data.endDate,
        subscriptionId: data.subscriptionId,
      }))
    );

    hide();

    return res;
  }, []);

  const onSubmitAction = useAction(onSubmit, {
    successMessage: 'Subscriptions successfully added',
    alertMessage: 'Subscriptions could not be added',
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Create New Subscription</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Subscription Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Learners"
              helpText="The list of learners for which the subscription will be added"
              helpTextOffset={60}
              required
              errorMessage={errors?.learnerIds?.message}
            >
              <Controller
                name="learnerIds"
                control={control}
                render={({ field }) => (
                  <AdminLearnerMultiSelect
                    {...field}
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        {!subscriptionId ? (
          <Box>
            <Box>
              <FormField
                required
                label="Subscription"
                helpText="The subscription you want to track for the elarner"
                helpTextOffset={30}
                errorMessage={errors?.subscriptionId?.message}
              >
                <Controller
                  control={control}
                  name="subscriptionId"
                  render={({ field }) => (
                    <AdminSubscriptionSelect
                      {...field}
                      isClearable
                      hasError={!!errors?.subscriptionId?.message}
                    />
                  )}
                />
              </FormField>
            </Box>
          </Box>
        ) : null}

        <Box>
          <Box>
            <FormField label="Start Date">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="End Date">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <Button
          label={'Create'}
          onClick={handleSubmit(onSubmitAction)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
