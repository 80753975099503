import { useCallback, useMemo, useState } from 'react';
import { useFlexLayout, useTable } from 'react-table';

import { TableSelectionMode } from './Table.types';

function addOrExcludeRowFromSelection<Entity extends Record<string, unknown>>(
  row: Entity,
  selectedRows: Entity[],
  rowIdAccessor: string
) {
  const isSelected = !!selectedRows.find(
    (r) => r[rowIdAccessor] === row[rowIdAccessor]
  );
  if (isSelected) {
    return selectedRows.filter((r) => r[rowIdAccessor] !== row[rowIdAccessor]);
  }
  return [...selectedRows, row];
}

export function useTableRowSelection<
  Entity extends Record<string, unknown>
>(props: {
  data: Entity[];
  rowIdAccessor?: string;
  initialSelectedRows?: Entity[];
  selectionMode?: TableSelectionMode;
}) {
  const {
    data,
    rowIdAccessor = 'id',
    initialSelectedRows = [],
    selectionMode = 'checkbox',
  } = props;
  const [selectedRows, setSelectedRows] =
    useState<Entity[]>(initialSelectedRows);
  const [allRowsAreSelected, setAllRowsAreSelected] = useState(false);
  const [excludedRows, setExcludedRows] = useState<Entity[]>([]);

  const onSelectRadioButton = useCallback((row: Entity | boolean) => {
    if (typeof row === 'boolean') {
      console.error('Can not select all rows in radio mode');
      return;
    }

    setSelectedRows([row]);
  }, []);

  const onChangeSelection = useCallback(
    (row: Entity | Entity[]) => {
      if (Array.isArray(row)) {
        setSelectedRows(row);
        if (row.length === data.length) {
          setAllRowsAreSelected(false);
        }
        return;
      }
      const isSelected = !!selectedRows.find(
        (r) => r[rowIdAccessor] === row[rowIdAccessor]
      );
      if (isSelected) {
        setSelectedRows(
          selectedRows.filter((r) => r[rowIdAccessor] !== row[rowIdAccessor])
        );
      } else {
        setSelectedRows([...selectedRows, row]);
      }
    },
    [selectedRows, data]
  );
  const onChangeExclusion = useCallback(
    (row: Entity) => {
      const isExcluded = !!excludedRows.find(
        (r) => r[rowIdAccessor] === row[rowIdAccessor]
      );
      if (isExcluded) {
        setExcludedRows(
          excludedRows.filter((r) => r[rowIdAccessor] !== row[rowIdAccessor])
        );
      } else {
        setExcludedRows([...excludedRows, row]);
      }
    },
    [excludedRows, data]
  );

  const onSelectCheckbox = useCallback(
    (row: Entity | boolean) => {
      if (typeof row === 'boolean') {
        if (row && selectionMode === 'checkbox-without-all-selection') {
          return;
        }
        if (row) {
          setAllRowsAreSelected(true);
          if (selectedRows.length !== data.length) {
            setSelectedRows(data);
          }
          setExcludedRows([]);
        } else {
          setAllRowsAreSelected(false);
          setSelectedRows([]);
          setExcludedRows([]);
        }
      } else {
        const rowId = row[rowIdAccessor];
        if (!rowId) {
          console.error('Invalid row id');
          return;
        }
        if (allRowsAreSelected) {
          onChangeSelection(row);
          onChangeExclusion(row);
        } else {
          onChangeSelection(row);
        }
      }
    },
    [data, selectedRows, onChangeExclusion, onChangeSelection]
  );
  const onSelectRow = useCallback(
    (row: Entity | Entity[] | boolean) => {
      if (Array.isArray(row)) {
        onChangeSelection(row);
      } else if (selectionMode === 'radio') {
        onSelectRadioButton(row);
      } else if (selectionMode === 'none') {
      } else {
        onSelectCheckbox(row);
      }
    },
    [onSelectRadioButton, onSelectCheckbox, selectionMode]
  );

  return {
    selectedRows,
    onSelectRow,
    excludedRows,
    allRowsAreSelected,
  };
}

export const useTableExpandedRows = () => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const expandRow = useCallback(
    (rowId) => {
      setExpandedRows({
        ...expandedRows,
        [rowId]: !expandedRows[rowId],
      });
    },
    [expandedRows]
  );

  return {
    expandedRows,
    expandRow,
  };
};

export const useTableRowActions = (_rowActions, isHeaderEditable) =>
  useMemo(() => {
    // We use RowActionCells for both row actions and column edit menu
    const isRowActionsVisible = !!_rowActions.length || isHeaderEditable;
    const customIconActions = _rowActions.filter((r) => !!r.Icon);
    const floatingMenuActions = _rowActions.filter((r) => !r.Icon);
    const iconsCount = floatingMenuActions.length
      ? 1 + customIconActions.length
      : customIconActions.length;
    const floatingMenuWidth =
      floatingMenuActions.length || isHeaderEditable ? 14 : 0;
    const rowActionsCellWidth =
      customIconActions.length * 24 +
      (iconsCount - 1) * 5 +
      floatingMenuWidth +
      20;
    return {
      customIconActions,
      floatingMenuActions,
      rowActionsCellWidth,
      isRowActionsVisible,
    };
  }, [_rowActions]);

const defaultColumnSize = {
  minWidth: 20,
  maxWidth: 200,
};

const ExpanderColumn = {
  id: 'Expansion',
  type: 'Expansion',
  width: 20,
  minWidth: 30,
  canResize: false,
};

const SelectionColumn = {
  type: 'Selection',
  id: 'Selection',
  width: 20,
  minWidth: 20,
  canResize: false,
};

const RowActionsColumn = {
  type: 'RowActions',
  id: 'RowActions',
  accessor: 'rowActions',

  width: 10,
};

export function useReactTable<Entity extends Record<string, unknown>>({
  columns,
  isRowActionsVisible,
  pagination,
  data,
  rowIdAccessor,
  isSelectable,
  isExpandable,
  isLoading,
}) {
  const loadingRowsCount = pagination ? pagination.limit : 10;
  const tableData = useMemo(
    () =>
      isLoading
        ? [...Array(loadingRowsCount).keys()].map((index) => ({
            id: `${index}`,
          }))
        : data,
    [data, isLoading, loadingRowsCount]
  );
  const tableColumns = useMemo(() => {
    return columns
      .filter((c) => c.isVisible === undefined || c.isVisible)
      .map((col) => ({
        ...col,
        type: 'Data',
        label: col.Header,
      }));
  }, [isLoading, columns]);
  const parseTableHooks = useCallback(
    (hooks) => {
      const additionalColumns = [];
      if (isSelectable) {
        // @ts-ignore
        additionalColumns.push(SelectionColumn);
      }
      if (isExpandable) {
        // @ts-ignore
        additionalColumns.push(ExpanderColumn);
      }
      const rowActionsColumns = [];
      if (isRowActionsVisible) {
        // @ts-ignore
        rowActionsColumns.push(RowActionsColumn);
      }
      hooks.visibleColumns.push((columns) => [
        ...additionalColumns,
        ...columns,
        ...rowActionsColumns,
      ]);
    },
    [isSelectable, isExpandable, isRowActionsVisible]
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable<Entity>(
      {
        columns: tableColumns,
        data: tableData,
        defaultColumn: defaultColumnSize,
        // @ts-ignore
        getRowId: (row) => row[rowIdAccessor],
      },
      useFlexLayout,
      parseTableHooks
    );

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  };
}
