import { _ } from '@nl-lms/vendor';

import { C } from '../constants';
import { formatConstantString } from './formatConstantString';

export const getSessionLearnerStatusOptions = () =>
  _.map(C.LEARNER_STATUS, (key, value) => ({
    label: formatConstantString(key),
    value: parseInt(value),
  }));
export const getSessionStatusOptions = () =>
  _.map(C.SESSION_STATUSES, (key, value) => ({
    label: formatConstantString(key),
    value: parseInt(value),
  }));
export const getElearningSessionStatusOptions = () =>
  _.map(C.ELEARNING_SESSION_STATUSES, (key, value) => ({
    label: formatConstantString(key),
    value: parseInt(value),
  }));
