import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { LearningPortalRouterContract } from './live-learning.router-contract';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'liveLearningStore';

  const tsRest = buildTsRestInstance<typeof LearningPortalRouterContract>(
    apiInstance,
    LearningPortalRouterContract,
    apiOptions,
  );

  const rtk = buildRtkInstance<{
    listVisibleLiveCoursesWithLiveSessions: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningPortalRouterContract.listVisibleLiveCoursesWithLiveSessions.query
          >
        >
      >,
      z.infer<
        (typeof LearningPortalRouterContract.listVisibleLiveCoursesWithLiveSessions.responses)['200']
      >
    >;
    listLiveSessions: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof LearningPortalRouterContract.listLiveSessions.query>
        >
      >,
      z.infer<
        (typeof LearningPortalRouterContract.listLiveSessions.responses)['200']
      >
    >;
    getLiveCourse: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          {},
          {
            params: z.infer<
              typeof LearningPortalRouterContract.getLiveCourse.pathParams
            >;
          }
        >
      >,
      z.infer<
        (typeof LearningPortalRouterContract.getLiveCourse.responses)['200']
      >
    >;
  }>(tsRest, LearningPortalRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
