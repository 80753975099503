import React, { forwardRef } from 'react';

import { useClassNameProps, useTestProps } from '../../hooks';
import { CloseIcon } from '../Icon/CloseIcon';
import { TidComponent } from '../index.types';
import './Badge.scss';

export enum BadgeTypes {
  INFO = 'info',
  ALERT = 'alert',
  SUCCESS = 'success',
  WARNING = 'warning',
  DEFAULT = 'default',
  LIGHT = 'light',
}

type Ref = HTMLDivElement;
export type BadgeProps = TidComponent<{
  type?: BadgeTypes;
  onClick?: (e: React.MouseEvent) => void;
  onClose?: (e: React.MouseEvent) => void;
  onCloseTitle?: string;
  onClickTitle?: string;
  label: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
}>;

export const Badge = forwardRef<Ref, BadgeProps>((props, ref) => {
  const {
    type = BadgeTypes.DEFAULT,
    onClick = null,
    onClose: _onClose = null,
    disabled = false,
    onClickTitle = '',
    onCloseTitle = '',
    style = {},
    label,
  } = props;

  const classNameProps = useClassNameProps('badge', `badge--${type}`, props);
  const commonProps = useTestProps(props);

  const onClose = (e) => {
    e.stopPropagation();
    // @ts-ignore
    _onClose(e);
  };

  return (
    <div
      ref={ref}
      // @ts-ignore
      onClick={disabled ? null : onClick}
      style={style}
      {...classNameProps}
      {...commonProps}
    >
      <span className="badge__label" title={onClickTitle}>
        {label}
      </span>
      {_onClose && (
        <span
          className="badge__icon"
          onClick={onClose}
          onMouseDown={onClose}
          title={onCloseTitle}
        >
          <CloseIcon />
        </span>
      )}
    </div>
  );
});

export const AlertBadge = forwardRef<Ref, BadgeProps>((props, ref) => (
  <Badge {...props} type={BadgeTypes.ALERT} ref={ref} />
));
export const InfoBadge = forwardRef<Ref, BadgeProps>((props, ref) => (
  <Badge {...props} type={BadgeTypes.INFO} ref={ref} />
));
export const SuccessBadge = forwardRef<Ref, BadgeProps>((props, ref) => (
  <Badge {...props} type={BadgeTypes.SUCCESS} ref={ref} />
));
export const WarningBadge = forwardRef<Ref, BadgeProps>((props, ref) => (
  <Badge {...props} type={BadgeTypes.WARNING} ref={ref} />
));
export const LightBadge = forwardRef<HTMLDivElement, BadgeProps>(
  (props, ref) => <Badge {...props} type={BadgeTypes.LIGHT} ref={ref} />
);
