import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const ReactPortal = ({ children }) => {
  const appRoot = document.body;
  const el = document.createElement('div');
  el.style.zIndex = '199999';

  useEffect(() => {
    appRoot.appendChild(el);
    return function cleanup() {
      appRoot.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, el);
};
