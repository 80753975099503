import { yupResolver } from '@hookform/resolvers/yup';
import { add } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Box,
  Button,
  DateTime,
  FormField,
  Input,
  Separator,
  SideModal,
  Textarea,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useNotifications } from '@nl-lms/ui/modules';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { IndividualLearningSelect } from '../../../_common/modules/IndividualLearningSelect';
import { IndividualLearningTypeSelect } from '../../../_common/modules/IndividualLearningTypeSelect';
import { adminApi, useApi } from '../../../_common/services/api';
import {
  AdminLearnerMultiSelect,
  AdminLearnerSingleSelect,
} from '../AdminLearner/AdminLearnerSelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminIndividualLearningDeliveryTypeSelect } from './AdminIndividualLearningDeliveryTypeSelect';

const stringArraySchema = yup.array().of(yup.string());
const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.number().required(),
  deliveryType: yup.number().required(),
  learnerGroupIds: stringArraySchema,
  learnerIds: stringArraySchema.required().min(1),
  mentorLearnerId: yup.string().nullable(),
  vendorId: yup.string().nullable(),
  tagIds: yup.array(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  duration: yup.number().required(),
  details: yup.string().nullable(),
  individualLearningId: yup.string().nullable(),
});

const { useCreateIndividualLearningWithInstancesMutation } = adminApi;

export const AdminIndividualLearningInstanceCreateFormSideModal = () => {
  const [activeItem, setActiveItem] = useState();
  const { addSuccessNotification, addAlertNotification } = useNotifications();
  const api = useApi();

  const [mutate, { isLoading, error }] =
    useCreateIndividualLearningWithInstancesMutation();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      type: C.I_INDIVIDUAL_LEARNING_TYPES.MENTORING,
      deliveryType: C.I_INDIVIDUAL_LEARNING_DELIVERY_TYPES.IN_CLASS,
      startDate: new Date(),
      endDate: undefined,
      duration: 0,
      learnerIds: [],
      tagIds: [],
      mentorLearnerId: null,
      individualLearningId: null,
      details: null,
      vendorId: null,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      const res = await mutate({
        ...data,
        endDate: data.endDate.toISOString(),
        startDate: data.startDate.toISOString(),
        timeSpent: data.duration * 60 * 60,
      });

      // @ts-ignore
      if (res.error) {
        // @ts-ignore
        addAlertNotification({ message: res.error.message });
      } else {
        addSuccessNotification({
          message: 'Instances successfully created',
        });
        // @ts-ignore
        onCloseModal({ success: true });
      }
    },
    [activeItem]
  );

  const onChangeIndividualLearning = useCallback(
    async (id) => {
      if (!id) {
        setValue('individualLearningId', null);
        // @ts-ignore
        setValue('type', null);
        setValue('name', '');
        setValue('vendorId', null);
        setValue('details', null);
        setValue('tagIds', []);

        setActiveItem(undefined);
        return;
      }

      const item = (await api.individualLearning.get(id)) as any;

      setValue('name', item.name);
      setValue('type', item.type);
      setValue('individualLearningId', id);
      setValue('vendorId', item.vendorId);
      setValue('tagIds', item.tagIds);
      setValue('details', item.details);

      setActiveItem(item);
    },
    [setValue]
  );

  const onChangeStartDate = useCallback(
    async (data) => {
      const startDate = new Date(data.target.value);
      // @ts-ignore
      const endDate = new Date(watch('endDate'));
      const newEndDate = add(startDate, {
        seconds: (watch('duration') || 0) * 60 * 60,
      });

      setValue('startDate', data.target.value);
      if (newEndDate.getTime() > endDate.getTime()) {
        // @ts-ignore
        setValue('endDate', newEndDate.toISOString());
      }
    },
    [setValue]
  );

  const onChangeEndDate = useCallback(
    async (data) => {
      setValue('endDate', data.target.value);
    },
    [setValue, watch]
  );

  const onChangeDuration = useCallback(
    (data) => {
      if (!data.target.value) return setValue('duration', 0);

      const _duration = parseInt(data.target.value as string);
      // @ts-ignore
      setValue('duration', _duration);

      const startDate = new Date(watch('startDate'));
      // @ts-ignore
      const endDate = watch('endDate') ? new Date(watch('endDate')) : null;
      const newEndDate = add(startDate, {
        seconds: _duration * 60 * 60,
      });

      if (!endDate) {
        // @ts-ignore
        setValue('endDate', newEndDate.toISOString());
      } else if (newEndDate.getTime() > endDate.getTime()) {
        // @ts-ignore
        setValue('endDate', newEndDate.toISOString());
      }
    },
    [setValue, watch]
  );

  const minEndDate = (() => {
    const duration = watch('duration') || 0;
    const startDate = new Date(watch('startDate'));

    return add(startDate, {
      seconds: duration * 60 * 60,
    });
  })();

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Create Individual Instances</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Individual Learning"
              errorMessage={
                errors?.individualLearningId?.message as string | undefined
              }
            >
              <Controller
                name="individualLearningId"
                control={control}
                render={({ field }) => (
                  <IndividualLearningSelect
                    {...field}
                    isAdmin={true}
                    placeholder={'Select Individual Learning'}
                    onChange={onChangeIndividualLearning}
                    isClearable
                    hasError={!!errors?.individualLearningId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message as string | undefined}
            >
              <Input
                {...register('name')}
                hasError={!!errors?.name?.message}
                placeholder="Name"
                required
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor" required>
              <Controller
                control={control}
                name="vendorId"
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    // @ts-ignore
                    selectedItem={field?.value}
                    hasError={!!errors?.vendorId?.message}
                    isClearable
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Type"
              errorMessage={errors?.type?.message}
              required
            >
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  // @ts-ignore
                  <IndividualLearningTypeSelect
                    {...field}
                    hasError={!!errors?.type?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Delivery Type"
              required
              errorMessage={errors?.deliveryType?.message}
            >
              <Controller
                name="deliveryType"
                control={control}
                render={({ field }) => (
                  // @ts-ignore
                  <AdminIndividualLearningDeliveryTypeSelect
                    {...field}
                    hasError={!!errors?.deliveryType?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Mentor"
              // @ts-ignore
              errorMessage={errors.mentorLearnerId?.message}
            >
              <Controller
                name="mentorLearnerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    returnEntireItemOnChange={false}
                    isClearable={true}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Learners"
              required
              // @ts-ignore
              errorMessage={errors.learnerIds?.message}
            >
              <Controller
                name="learnerIds"
                control={control}
                render={({ field }) => (
                  <AdminLearnerMultiSelect
                    {...field}
                    returnEntireItemOnChange={false}
                    selectedItems={null}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Start Date"
              required
              errorMessage={errors?.startDate?.message}
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={onChangeStartDate}
                    hasError={errors?.startDate?.message}
                    placeholder="Start Date"
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="End Date"
              required
              errorMessage={errors?.endDate?.message as string | undefined}
            >
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={onChangeEndDate}
                    minDate={minEndDate}
                    hasError={errors?.endDate?.message}
                    placeholder="End date"
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Duration"
              errorMessage={errors?.duration?.message as string}
              required
            >
              <Controller
                name="duration"
                control={control}
                render={({ field }) => (
                  <Input
                    name="duration"
                    type="number"
                    // @ts-ignore
                    value={field.value}
                    onChange={onChangeDuration}
                    placeholder="Hours"
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Details"
              // @ts-ignore
              errorMessage={errors?.details?.message}
            >
              <Controller
                name="details"
                control={control}
                render={({ field }) => (
                  <Textarea
                    name="details"
                    placeholder="Some details about the sessions"
                    onChange={(e) => field.onChange(e.target.value)}
                    // @ts-ignore
                    value={field.value}
                    // @ts-ignore
                    hasError={errors?.details?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label="Create"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
