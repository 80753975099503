export const SubscriptionsRouterPrefix = '/subscription';

export const SubscriptionsScope = {
  update: { resource: 'subscription', action: 'update' },
  delete: { resource: 'subscription', action: 'delete' },
  view: { resource: 'subscription', action: 'view' },
  create: { resource: 'subscription', action: 'create' },

  viewInstance: { resource: 'subscription_instance', action: 'view' },
  updateInstance: { resource: 'subscription_instance', action: 'update' },
  deleteInstance: { resource: 'subscription_instance', action: 'delete' },
  createInstance: { resource: 'subscription_instance', action: 'create' },
} as const;
