import clsx from 'clsx';
import React, { ReactNode, useMemo } from 'react';

import {
  Box,
  Card,
  Modal,
  Separator,
  Skeleton,
  Typography,
} from '@nl-lms/ui/components';
import { useClassNameProps } from '@nl-lms/ui/hooks';

import { InfoBanner, LearningBanner } from '../../../../_common/components';
import './LearnerLearningUnitPageLayout.scss';

const getChildrenTypes = (children) => {
  let banner;
  let navigationIcon;
  let navigationLink;
  let image;
  let cardStatus;
  let cardTitle;
  let cardInfo;
  const cardSections = [];
  let cardHistory;
  const sections = [];
  const footerInfo = [];
  const footerActions = [];

  const parsedChildren = (
    Array.isArray(children) ? children : [children]
  ).flat();

  parsedChildren.forEach((child) => {
    if (!child) return;
    switch (child.type) {
      case LearningBanner:
      case InfoBanner:
        banner = child;
        break;
      case LearnerLearningUnitPageLayoutNavigationLink:
        navigationLink = child;
        break;
      case LearnerLearningUnitPageLayoutHeroImage:
        image = child;
        break;
      case LearnerLearningUnitPageLayoutCardHeaderStatus:
        cardStatus = child;
        break;
      case LearnerLearningUnitPageLayoutCardHeaderTitle:
        cardTitle = child;
        break;
      case LearnerLearningUnitPageLayoutCardHeaderItem:
        cardInfo = child;
        break;
      case LearnerLearningUnitPageLayoutCardBodySection:
        // @ts-ignore
        cardSections.push(child);
        break;
      case LearnerLearningUnitPageLayoutCardHistory:
        cardHistory = child;
        break;
      case LearnerLearningUnitPageLayoutSection:
        // @ts-ignore
        sections.push(child);
        break;
      case LearnerLearningUnitPageLayoutFooterItem:
      case LearnerLearningUnitPageLayoutFooterSeparator:
        // @ts-ignore
        footerInfo.push(child);
        break;
      case LearnerLearningUnitPageLayoutFooterAction:
        // @ts-ignore
        footerActions.push(child);
        break;
      default:
        break;
    }
  });

  return {
    banner,
    navigationLink,
    navigationIcon,
    image,
    cardStatus,
    cardTitle,
    cardInfo,
    cardSections,
    cardHistory,
    sections,
    footerInfo,
    footerActions,
  };
};

export const LearnerLearningUnitPageLayout = ({
  children,
  isLoading = false,
  ...props
}: {
  children: React.ReactNode;
  isLoading?: boolean;
  className?: string;
}) => {
  if (isLoading) {
    return (
      <div className="learner-learning-unit-layout">
        <div className="learner-learning-unit-layout__header">
          <Skeleton className="learner-learning-unit-layout__image-skeleton" />
        </div>
        <div className="learner-learning-unit-layout__body">
          <Card
            className="learner-learning-unit-layout__card learner-learning-unit-layout__card-skeleton"
            paddingType="large"
          >
            <Skeleton className="learner-learning-unit-layout__card-header-title-skeleton" />
            <Skeleton className="learner-learning-unit-layout__card-header-item-skeleton" />
          </Card>
        </div>
      </div>
    );
  }
  const {
    banner,
    navigationLink,
    navigationIcon,
    image,
    cardStatus,
    cardTitle,
    cardInfo,
    cardSections,
    cardHistory,
    sections,
    footerInfo,
    footerActions,
  } = getChildrenTypes(children);

  const classNameProps = useClassNameProps(
    'learner-learning-unit-layout',
    props
  );

  return (
    <div {...classNameProps}>
      <div className="learner-learning-unit-layout__banner">{banner}</div>
      <div className="learner-learning-unit-layout__header">
        <div className="learner-learning-unit-layout__navigation">
          {navigationIcon}
          {navigationLink}
        </div>
        {image}
      </div>
      <div className="learner-learning-unit-layout__body">
        <Card
          className="learner-learning-unit-layout__card"
          paddingType="large"
        >
          <div className="learner-learning-unit-layout__card__header">
            {cardStatus}
            {cardTitle}
            {cardInfo}
            <Separator marginTop={20} marginBottom={20} />
          </div>
          <div className="learner-learning-unit-layout__card__body">
            {cardSections}
          </div>
          {cardHistory ? (
            <div>
              <Separator marginTop={30} marginBottom={30} />
              {cardHistory}
            </div>
          ) : null}
        </Card>
        {sections}
      </div>

      {(!!footerInfo.length || !!footerActions.length) && (
        <div className="learner-learning-unit-layout__footer">
          <div className="learner-learning-unit-layout__footer__info">
            {footerInfo}
          </div>
          <div className="learner-learning-unit-layout__footer__actions">
            {footerActions}
          </div>
        </div>
      )}
    </div>
  );
};

const LearnerLearningUnitPageLayoutNavigationLink = ({ children }) => (
  <div className="learner-learning-unit-layout__navigation__link">
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutHeroImage = ({ imageUrl }) => {
  const actualImageUrl = useMemo(() => {
    if (!imageUrl) return '';
    if (imageUrl.startsWith('"')) return imageUrl.slice(1, imageUrl.length - 1);
    return imageUrl;
  }, [imageUrl]);
  return (
    <Modal.Provider>
      <Modal.Trigger asChild>
        <div
          className="learner-learning-unit-layout__image"
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        ></div>
      </Modal.Trigger>

      <Modal.Content className="learner-learning-unit-layout__image-modal">
        <img src={actualImageUrl} alt="Hero Image" />
      </Modal.Content>
    </Modal.Provider>
  );
};

const LearnerLearningUnitPageLayoutCardHeaderStatus = ({ children }) => (
  <div className="learner-learning-unit-layout__card__header__item">
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutCardHeaderTitle = ({ children }) => (
  <div className="learner-learning-unit-layout__card__header__title">
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutCardHeaderItem = ({ children }) => (
  <div className="learner-learning-unit-layout__card__header__item">
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutCardBodySectionDescription = ({
  children,
}) => (
  <div className="learner-learning-unit-layout__card__body__section__description">
    {children}
  </div>
);

export enum CardBodySectionAlignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

const LearnerLearningUnitPageLayoutCardBodySection = ({
  title = '',
  align = CardBodySectionAlignment.LEFT,
  children,
}) => {
  return (
    <div
      className={clsx(
        'learner-learning-unit-layout__card__body__section',
        align === CardBodySectionAlignment.LEFT &&
          'learner-learning-unit-layout__card__body__section--left',
        align === CardBodySectionAlignment.CENTER &&
          'learner-learning-unit-layout__card__body__section--center',
        align === CardBodySectionAlignment.RIGHT &&
          'learner-learning-unit-layout__card__body__section--right'
      )}
    >
      {title && (
        <div className="learner-learning-unit-layout__card__body__section__title">
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

const LearnerLearningUnitPageLayoutCardHistory = ({ children }) => (
  <div className="learner-learning-unit-layout__card__footer">
    <div className="learner-learning-unit-layout__card__footer__content">
      {children}
    </div>
    {/*<div className="learner-learning-unit-layout__card__footer__icon">*/}
    {/*  <Icon.HistoryIcon />*/}
    {/*</div>*/}
  </div>
);

const LearnerLearningUnitPageLayoutCardActions = ({ children }) => (
  <div className="learner-learning-unit-layout__card__actions__bar">
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutSection = ({ children }) => (
  <div className="learner-learning-unit-layout__section">
    <Separator marginTop={30} marginBottom={30} />
    {children}
  </div>
);

const LearnerLearningUnitPageLayoutSectionTitle = ({ title }) => (
  <Typography.h3>{title}</Typography.h3>
);

const LearnerLearningUnitPageLayoutSectionSubTitle = ({ text }) => (
  <Box padding={{ top: 'xs', bottom: 'm' }}>
    <Typography.h4 type="muted">{text}</Typography.h4>
  </Box>
);

const LearnerLearningUnitPageLayoutFooterItem = ({
  label,
  children,
  withSeparator = false,
}) => (
  <div
    className={`learner-learning-unit-layout__footer__item ${
      withSeparator ? 'learner-learning-unit-layout__footer__item--border' : ''
    }`}
  >
    {!!label && (
      <div className="learner-learning-unit-layout__footer__item__label">
        {label}
      </div>
    )}
    <div className="learner-learning-unit-layout__footer__item__value">
      {children}
    </div>
  </div>
);

const LearnerLearningUnitPageLayoutFooterSeparator = () => (
  <div className="learner-learning-unit-layout__footer__item">
    <div className="learner-learning-unit-layout__footer__separator"></div>
  </div>
);

const LearnerLearningUnitPageLayoutFooterAction = ({ children }) => (
  <div className="learner-learning-unit-layout__footer__action">{children}</div>
);

const LearnerLearningUnitPageLayoutSectionCarousel = ({
  children,
  columns = 2,
}: {
  children: ReactNode;
  columns?: 1 | 2 | 3 | 4;
}) => (
  <div
    className={`learner-learning-unit-layout__section__carousel learner-learning-unit-layout__section__carousel--col-${columns}`}
  >
    {children}
  </div>
);

LearnerLearningUnitPageLayout.NavigationLink =
  LearnerLearningUnitPageLayoutNavigationLink;
LearnerLearningUnitPageLayout.HeroImage =
  LearnerLearningUnitPageLayoutHeroImage;

LearnerLearningUnitPageLayout.CardHeaderStatus =
  LearnerLearningUnitPageLayoutCardHeaderStatus;
LearnerLearningUnitPageLayout.CardHeaderTitle =
  LearnerLearningUnitPageLayoutCardHeaderTitle;
LearnerLearningUnitPageLayout.CardHeaderItem =
  LearnerLearningUnitPageLayoutCardHeaderItem;
LearnerLearningUnitPageLayout.CardBodySection =
  LearnerLearningUnitPageLayoutCardBodySection;
LearnerLearningUnitPageLayout.CardBodySectionDescription =
  LearnerLearningUnitPageLayoutCardBodySectionDescription;
LearnerLearningUnitPageLayout.CardHistory =
  LearnerLearningUnitPageLayoutCardHistory;
LearnerLearningUnitPageLayout.CardActions =
  LearnerLearningUnitPageLayoutCardActions;

LearnerLearningUnitPageLayout.Section = LearnerLearningUnitPageLayoutSection;
LearnerLearningUnitPageLayout.SectionTitle =
  LearnerLearningUnitPageLayoutSectionTitle;
LearnerLearningUnitPageLayout.SectionSubTitle =
  LearnerLearningUnitPageLayoutSectionSubTitle;
LearnerLearningUnitPageLayout.SectionCarousel =
  LearnerLearningUnitPageLayoutSectionCarousel;

LearnerLearningUnitPageLayout.FooterItem =
  LearnerLearningUnitPageLayoutFooterItem;
LearnerLearningUnitPageLayout.FooterSeparator =
  LearnerLearningUnitPageLayoutFooterSeparator;
LearnerLearningUnitPageLayout.FooterAction =
  LearnerLearningUnitPageLayoutFooterAction;
