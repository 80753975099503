import { z } from 'zod';

import { FieldConditionSchema } from '@nl-lms/common/shared';

import { NotificationMessageSchema } from './notification-template.types';

export enum NotificationStatus {
  // Used in cases where you first want to build the notifications, review them, and send them afterwards
  draft,
  scheduled,
  // Notifications can be dropped/cancelled because of 3 reasons
  // - the notification template is disabled
  // - the cancelOn property has been validated (this applies to scheduled notifications)
  // - the condition property has not been validated
  cancelled,
  // Signals that the notification has been added to a queue and will be sent ASAP
  sending,
  sent,
  failed,
}

export const NotificationSendConditionSchema = FieldConditionSchema.extend({
  combinator: z.union([z.literal('or'), z.literal('and')]).optional(),
});

export type NotificationSendCondition = z.infer<
  typeof NotificationSendConditionSchema
>;

export const NotificationReceiverMetadataSchema = z.intersection(
  z.record(z.string(), z.unknown()),
  z.object({
    sendConditions: z.array(NotificationSendConditionSchema).optional(),
  })
);

export const NotificationReceiverSchema = z.object({
  name: z.string(),
  audienceName: z.string(),
  data: z.record(z.string(), z.unknown()),
  channelIdentifier: z.unknown(),
  language: z.string(),
  metadata: NotificationReceiverMetadataSchema,
});

export type NotificationReceiver = z.infer<typeof NotificationReceiverSchema>;
export const NotificationSchema = z.object({
  id: z.string(),
  receiver: NotificationReceiverSchema,
  channel: z.string(),
  message: z.string().nullable(),
  title: z.string(),
  content: NotificationMessageSchema,
  options: z.record(z.string(), z.unknown()),
  deliveryId: z.string().nullable(),
  userId: z.string().nullable(),
  status: z.nativeEnum(NotificationStatus),
  scheduledOn: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Notification = z.infer<typeof NotificationSchema>;
