import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import {
  CertificationInstanceRouterPrefix,
  CertificationRouterPrefix,
} from './certification.constants.sdk';
import {
  CertificationIdParamSchema,
  CertificationInstanceIdParamSchema,
  CertificationInstanceSchema,
  CertificationSchema,
  CreateCertificationSchema,
  UpdateCertificationSchema,
} from './certification.schema.sdk';

const contract = initContract();

// the description field is for indicating if it's a mutation or a query

export const CertificationRouterContract = contract.router({
  listCertifications: {
    method: 'GET',
    path: `${CertificationRouterPrefix}/`,
    responses: ContractPaginatedResponse(CertificationSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  removeCertification: {
    method: 'DELETE',
    path: `${CertificationRouterPrefix}/`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  createCertification: {
    method: 'POST',
    path: `${CertificationRouterPrefix}/`,
    responses: ContractStandardResponse(CertificationSchema),
    body: CreateCertificationSchema,
  },
  updateCertification: {
    method: 'POST',
    path: `${CertificationRouterPrefix}/:id`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateCertificationSchema,
    pathParams: CertificationIdParamSchema,
  },
  getCertification: {
    method: 'GET',
    path: `${CertificationRouterPrefix}/:id`,
    responses: ContractStandardResponse(CertificationSchema),
    pathParams: CertificationIdParamSchema,
  },
  listCertificationInstances: {
    method: 'GET',
    path: `${CertificationRouterPrefix}/instances`,
    responses: ContractPaginatedResponse(CertificationInstanceSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  downloadCertificate: {
    method: 'GET',
    path: `${CertificationInstanceRouterPrefix}/:certificationInstanceId/download`,
    responses: ContractStandardResponse(z.object({ signedUrl: z.string() })),
    pathParams: CertificationInstanceIdParamSchema,
  },
});
