import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LiveCourseType,
  LiveCourseWithLiveSessions,
  LiveSessionLearnerStatus,
} from '@nl-lms/feature/live-learning/sdk';
import {
  Box,
  Button,
  FormField,
  Icon,
  InlineDotList,
  Link,
  Modal,
  PrettyDate,
  SideModal,
  Skeleton,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { translateDuration } from '@nl-lms/ui/utils';

import {
  CardIconCaption,
  LiveCourseTypeIcon,
} from '../../../../_common/components';
import { LiveTrainingRegisterButton } from '../../../../learnerApp/_common/components/LiveTrainingRegisterButton';
import './PublicLiveCourseCard.scss';

export const PublicLiveCourseCard = ({
  liveCourse,
  onSuccess,
}: {
  liveCourse: LiveCourseWithLiveSessions;
  onSuccess: () => void;
}) => {
  const availableSessions = useMemo(() => {
    return liveCourse.liveSessions.filter((session) => {
      if (!session.startDate) return false;
      const dateDiff =
        new Date().getTime() - new Date(session.startDate).getTime();
      return dateDiff <= 0;
    });
  }, [liveCourse]);
  const showModal = useShowModal(PublicLiveCourseViewModal);
  const onClickCourseName = useCallback((liveCourse) => {
    showModal({ liveCourse });
  }, []);
  const activeSession = useMemo(() => {
    return liveCourse.liveSessions.find(
      (session) =>
        session.learner &&
        ![
          LiveSessionLearnerStatus.CANCELLED,
          LiveSessionLearnerStatus.DECLINED,
        ].includes(session.learner?.status)
    );
  }, [liveCourse]);
  return (
    <div className="public-live-course-card">
      <div
        className="public-live-course-card__thumbnail"
        style={{ backgroundImage: `url("${liveCourse.thumbnail}")` }}
      />
      <div className="public-live-course-card__details">
        <div className="public-live-course-card__info">
          <Link onClick={() => onClickCourseName(liveCourse)}>
            <Typography.h2>{liveCourse.name}</Typography.h2>
          </Link>
          <InlineDotList className="public-live-course-card__info-details">
            <CardIconCaption
              muted
              icon={<Icon.ClipboardIcon />}
              caption="In class (room/virtual)"
            />
            <CardIconCaption
              muted
              icon={<Icon.ClockIcon />}
              caption={translateDuration(liveCourse.duration)}
            />
          </InlineDotList>
        </div>
        <PublicLiveCourseCardActiveSession
          onSuccess={onSuccess}
          liveSession={activeSession}
        />
        {!activeSession ? (
          <PublicLiveCourseCardActiveSessions
            liveSessions={availableSessions}
            onSuccess={onSuccess}
          />
        ) : null}
      </div>
    </div>
  );
};

const PublicLiveCourseCardActiveSession = ({
  liveSession,
  onSuccess,
}: {
  liveSession?: LiveCourseWithLiveSessions['liveSessions'][number];
  onSuccess: () => void;
}) => {
  const sessionIsInThePast = useMemo(() => {
    if (!liveSession || !liveSession.startDate) return false;
    const dateDiff =
      new Date().getTime() - new Date(liveSession.startDate).getTime();
    return dateDiff > 0;
  }, [liveSession]);

  if (!liveSession || !liveSession.learner) {
    return <></>;
  }

  if (sessionIsInThePast) {
    return (
      <div className="public-live-course-card__active-session">
        <Typography.h3>
          Pe {<PrettyDate value={liveSession.startDate} />} ai participat la o
          sesiune pentru acest curs.
        </Typography.h3>

        <LiveTrainingRegisterButton
          className="public-live-course-card__active-session-action"
          session={liveSession}
          onSuccess={onSuccess}
          registrationForm={liveSession.registrationForm}
          courseId={liveSession.courseId}
          learnerStatus={liveSession.learner.status}
        />
      </div>
    );
  }

  return (
    <div className="public-live-course-card__active-session">
      <Typography.h3>
        Esti inscris la sesiunea de pe{' '}
        {<PrettyDate value={liveSession.startDate} withTime />}.
      </Typography.h3>
      <Typography.h4>
        Poți reprograma participarea anulând invitația pe care o ai în calendar
        cu minim 4 zile lucrătoare înainte de data programată. Doar astfel vei
        avea posibilitatea să te reînscrii la o altă dată, revenind la
        calendarul pe care deja l-ai primit cu datele disponibile.
      </Typography.h4>
      <LiveTrainingRegisterButton
        className="public-live-course-card__active-session-action"
        session={liveSession}
        onSuccess={onSuccess}
        registrationForm={liveSession.registrationForm}
        courseId={liveSession.courseId}
        learnerStatus={liveSession.learner.status}
      />
    </div>
  );
};

const PublicLiveCourseCardActiveSessions = ({
  liveSessions,
  onSuccess,
}: {
  liveSessions: LiveCourseWithLiveSessions['liveSessions'];
  onSuccess: () => void;
}) => {
  if (!liveSessions.length) {
    return (
      <div className="public-live-course-card__active-session">
        <Typography.h3>Nicio sesiune disponibila</Typography.h3>
      </div>
    );
  }
  return (
    <div className="public-live-course-card__sessions">
      <Typography.h3>Sesiuni disponibile</Typography.h3>
      <div className="public-live-course-card__sessions-list">
        {liveSessions.map((liveSession) => {
          return (
            <PublicLiveCourseRegistrationSessionDateButton
              key={liveSession.id}
              onSuccess={onSuccess}
              liveSession={liveSession}
            />
          );
        })}
      </div>
    </div>
  );
};
const CardSkeleton = () => {
  return (
    <div className="public-live-course-card">
      <div
        className="public-live-course-card__thumbnail"
        style={{ padding: '5px' }}
      >
        <Skeleton style={{ width: '100%', height: '190px' }} />
      </div>
      <div className="public-live-course-card__details">
        <Skeleton style={{ width: '100%', height: '20px' }} />
      </div>
    </div>
  );
};

const PublicLiveCourseRegistrationSessionDateButton = ({
  liveSession,
  onSuccess,
}: {
  liveSession: LiveCourseWithLiveSessions['liveSessions'][number];
  onSuccess: () => void;
}) => {
  if (!liveSession.startDate) return <></>;

  const { month, day, hour, minute } = useMemo(() => {
    if (!liveSession.startDate) return {};

    const startDate = new Date(liveSession.startDate);
    const month = startDate
      .toLocaleDateString(navigator.language, {
        month: 'short',
      })
      .replace('.', '');
    const day = startDate.getDate().toString().padStart(2, '0');
    const hour = startDate.getHours().toString().padStart(2, '0');
    const minute = startDate.getMinutes().toString().padStart(2, '0');

    return {
      month,
      day,
      hour,
      minute,
    };
  }, [liveSession.startDate]);

  return (
    <Modal.Provider>
      <Modal.Trigger>
        <div className="public-live-course-card__sessions-list-item">
          <span className="public-live-course-card__time">
            {hour}:{minute}
          </span>
          <span className="public-live-course-card__day">{day}</span>
          <span className="public-live-course-card__month">{month}</span>
        </div>
      </Modal.Trigger>
      <Modal.Content lock>
        <RegisterModalContent liveSession={liveSession} onSuccess={onSuccess} />
      </Modal.Content>
    </Modal.Provider>
  );
};

const RegisterModalContent = ({
  liveSession,
  onSuccess,
}: {
  liveSession: LiveCourseWithLiveSessions['liveSessions'][number];
  onSuccess: () => void;
}) => {
  const { hide } = useModal();
  return (
    <>
      <Modal.Header>Register to a session</Modal.Header>
      <Typography.p>
        Urmeaza sa te inscrii la sesiunea <b>{liveSession.name}</b> ce va avea
        loc pe <b>{<PrettyDate value={liveSession.startDate} withTime />}</b>.
      </Typography.p>
      <Modal.Actions>
        <Modal.Close>
          <Button label="Close" regular />
        </Modal.Close>
        <LiveTrainingRegisterButton
          session={liveSession}
          onSuccess={onSuccess}
          onShowRegistrationForm={hide}
          registrationForm={liveSession.registrationForm}
          courseId={liveSession.courseId}
          buttonStyle="primary"
        />
      </Modal.Actions>
    </>
  );
};

const PublicLiveCourseViewModal = ({
  liveCourse,
}: {
  liveCourse: LiveCourseWithLiveSessions;
}) => {
  const { hide } = useModal();
  const { t } = useTranslation('learner');
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>{liveCourse.name}</SideModal.Title>
        <Box flex={{ flexDirection: 'row', alignItems: 'center' }}>
          <InlineDotList>
            <CardIconCaption
              icon={<LiveCourseTypeIcon type={liveCourse.type} />}
              caption={t(
                // @ts-ignore
                `common.constants.${LiveCourseType[liveCourse?.type]}`
              )}
            />
            <CardIconCaption
              icon={<Icon.ClockIconSmall />}
              caption={translateDuration(liveCourse.duration)}
            />
          </InlineDotList>
        </Box>
      </SideModal.Header>
      <SideModal.Body>
        {liveCourse?.description ? (
          <FormField label="Description">
            <div
              dangerouslySetInnerHTML={{ __html: liveCourse?.description }}
            ></div>
          </FormField>
        ) : null}
        {liveCourse?.prework && (
          <FormField label="Prework">
            <div
              dangerouslySetInnerHTML={{ __html: liveCourse?.prework }}
            ></div>
          </FormField>
        )}
      </SideModal.Body>
      <SideModal.Actions>
        <Button label="Close" onClick={hide} regular />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

PublicLiveCourseCard.Skeleton = CardSkeleton;
