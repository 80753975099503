import React from 'react';

export const HelpIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.09009 7.99987C8.57552 6.61991 9.98547 5.79128 11.4273 6.03858C12.8691 6.28589 13.9223 7.53702 13.9201 8.99987C13.9201 10.9999 10.9201 11.9999 10.9201 11.9999"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9999 16H11.0099"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
