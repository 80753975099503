import _ from 'lodash';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { LearningAssignmentLearningContentType } from '@nl-lms/common/feature/types';
import {
  AsyncSingleSelect,
  Box,
  Button,
  FormField,
  Icon,
  Modal,
  NoDataPlaceholder,
  SideModal,
  Table,
  Typography,
  useConfirmationModal,
  useModal,
} from '@nl-lms/ui/components';
import { formatConstantString, getMessageFromError } from '@nl-lms/ui/utils';

import {
  learningProgramsApi,
  learningProgramsApiClient,
} from '../../../_common/services/api';

const { useAddLearningProgramAssignmentsMutation } = learningProgramsApi;

type AssignmentPayload = {
  id: string;
  type: LearningAssignmentLearningContentType;
  name: string;
};

type LearningProgramAddAssignmentsButtonTriggerProps = {
  children: ReactNode;
  learningProgramId: string;
  learningItemIds?: string[] | [];
  onSubmit?: () => void;
};

export const LearningProgramAddAssignmentsButtonTrigger = ({
  children,
  learningProgramId,
  learningItemIds,
  onSubmit,
}: LearningProgramAddAssignmentsButtonTriggerProps) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>{children}</Modal.Trigger>
      <SideModal.Content>
        <LearningProgramAddAssignmentsModal
          learningProgramId={learningProgramId}
          onSubmit={onSubmit}
          learningItemIds={learningItemIds}
        />
      </SideModal.Content>
    </Modal.Provider>
  );
};

const LearningProgramAddAssignmentsModal = ({
  learningProgramId,
  onSubmit: onProgramAssignmentsSubmit,
  learningItemIds,
}: {
  learningProgramId: string;
  learningItemIds?: string[];
  onSubmit?: () => void;
}) => {
  const { hide } = useModal();
  const [addAssignments, { error, isLoading }] =
    useAddLearningProgramAssignmentsMutation();
  const [assignments, setAssignments] = useState<AssignmentPayload[]>([]);
  const [duplicateContent, setDuplicateContent] = useState<boolean>(false);

  const onLoadOptions = useCallback(async (input) => {
    const res = await learningProgramsApiClient.searchLearningContent({
      query: { q: input },
    });
    if (res.status !== 200) {
      return [];
    }
    return res.body.map((c) => {
      const learningTypeLiteral = getLearningTypeLiteral(c.type);
      return {
        label: `${c.name} - ${learningTypeLiteral}`,
        value: c.id,
        item: c,
      };
    });
  }, []);

  useEffect(() => {
    setDuplicateContent(
      learningItemIds?.some((li) => assignments?.some((a) => a?.id === li)) ||
        false
    );
  }, [assignments, learningItemIds]);

  const onChange = useCallback(
    (learningItem) => {
      if (!learningItem) return;
      setAssignments(
        _.uniqBy(
          [
            ...assignments,
            {
              type: learningItem.item.type,
              name: learningItem.label as string,
              id: learningItem.value as string,
            },
          ],
          'id'
        )
      );
    },
    [assignments]
  );

  const showSubmitConfirmationModal = useConfirmationModal({
    message:
      'This learning program already contains some of the items in this list. Submitting this form will create additional assignments for them, are you sure?',
  });
  const onSubmit = useCallback(async () => {
    if (!assignments.length) return [];

    if (duplicateContent) {
      const confirmationResult = await showSubmitConfirmationModal();
      if (!confirmationResult.confirmed) return false;
    }

    const payload = assignments.map((assignment) => {
      const contentType = assignment.type;
      const contentId = assignment.id;

      return {
        assessmentFormId:
          contentType === LearningAssignmentLearningContentType.assessment
            ? contentId
            : null,
        learningPathId: null,
        surveyFormId:
          contentType === LearningAssignmentLearningContentType.survey
            ? contentId
            : null,
        checklistId:
          contentType === LearningAssignmentLearningContentType.checklist
            ? contentId
            : null,
        elearningCourseId:
          contentType === LearningAssignmentLearningContentType.elearning
            ? contentId
            : null,
        courseId:
          contentType === LearningAssignmentLearningContentType['live-learning']
            ? contentId
            : null,
        name: assignment.name,
      };
    });

    const result = await addAssignments({
      id: learningProgramId,
      assignments: payload,
    });
    if ('data' in result) {
      hide();
      setAssignments([]);
      onProgramAssignmentsSubmit?.();
    }
  }, [assignments, learningProgramId, duplicateContent]);

  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Add Learning Content</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.p type="muted">
            Search and select all the learning content that you want to use in
            your program. After that, submit the form, and then defined the
            structure of the program by conneting each item.
          </Typography.p>
        </Box>
        <Box flex={{ flexDirection: 'row', gap: 'm' }}>
          <FormField label="Learning Item">
            <AsyncSingleSelect
              name="select-learning-item"
              onChange={onChange}
              loadOptions={onLoadOptions}
              selectedItem={{ label: '', value: '' }}
              returnEntireItemOnChange
              placeholder="Search for a specific learning item"
            />
          </FormField>
        </Box>
        <Typography.h3>Learning Items that will be added:</Typography.h3>
        <Table
          data={assignments}
          rowActions={[
            {
              name: 'Remove',
              handler: (row) =>
                setAssignments(assignments.filter((r) => r.id !== row.id)),
              Icon: Icon.DeleteIcon,
            },
          ]}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              Cell: ({ row }) => row.original.name,
            },
            {
              Header: 'Type',
              accessor: 'type',
              width: 50,
              Cell: ({ row }) => getLearningTypeLiteral(row.original.type),
            },
          ]}
        >
          <NoDataPlaceholder
            title="Select the learning items that you want to add to the program"
            iconName="ClipboardIcon"
          />
        </Table>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button label="Add Content" onClick={onSubmit} isLoading={isLoading} />
      </SideModal.Actions>
    </>
  );
};

const getLearningTypeLiteral = (
  type: LearningAssignmentLearningContentType
) => {
  const parsedType =
    type === LearningAssignmentLearningContentType['live-learning']
      ? 'live-course'
      : LearningAssignmentLearningContentType[type];
  return formatConstantString(parsedType);
};
