import { useCombobox } from 'downshift';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './DownshiftSelect.scss';
import {
  BaseSingleSelectProps,
  CreateOptionName,
  SelectOption,
} from './Select.types';

export type AsyncSingleSelectProps = TidComponent<
  BaseSingleSelectProps & {
    loadOptions: (inputValue: string) => Promise<SelectOption[]>;
    initialSelectedItem?: SelectOption;
    selectedItem?: SelectOption;
    shouldClearSelection?: boolean;
  }
>;

export const AsyncSingleSelect = forwardRef<
  HTMLInputElement,
  AsyncSingleSelectProps
>((props, ref) => {
  const {
    onChange,
    onChangeInputValue,
    name,
    loadOptions,
    hasError = false,
    isClearable = false,
    placeholder = 'Select or type value to search',
    isCreatable = false,
    isLoading = false,
    initialSelectedItem = null,
    initialHighlightedIndex = 0,
    returnEntireItemOnChange = false,
    shouldClearSelection = false,
    selectedItem: _selectedItem = undefined,
    disabled = false,
  } = props;
  const wrapperClassNameProps = useClassNameProps(
    'downshift-select__input-wrapper',
    hasError ? 'downshift-select__input-wrapper--has-error' : null,
    disabled ? 'downshift-select__input-wrapper--disabled' : null
  );
  const commonProps = useTestProps(props);
  const [inputValue, setInputValue] = useState('');
  const [localSelectedItem, setSelectedItem] = useState(initialSelectedItem);
  const selectedItem = _selectedItem || localSelectedItem;
  const { isLoadOptionsLoading, debouncedSetInputAndLoadOptions, options } =
    useAsyncSelect({ loadOptions });
  const onInputValueChange = useCallback(
    (changes) => {
      setInputValue(changes.inputValue);
      if (onChangeInputValue) onChangeInputValue(changes.inputValue);
      // @ts-ignore
      if (loadOptions) {
        debouncedSetInputAndLoadOptions(changes.inputValue);
      }
    },
    [loadOptions, debouncedSetInputAndLoadOptions, setInputValue]
  );
  const onSelectedItemChange = useCallback(
    (changes) => {
      if (!changes.selectedItem) return;
      setSelectedItem(changes.selectedItem);
      if (onChangeInputValue) onChangeInputValue(changes.selectedItem?.label);
      if (returnEntireItemOnChange) {
        onChange(changes.selectedItem, name);
      } else {
        onChange(changes.selectedItem.value, name);
      }
    },
    [onChange, setSelectedItem, onChangeInputValue]
  );
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    toggleMenu,
  } = useCombobox({
    items: options,
    initialHighlightedIndex,
    selectedItem,
    onInputValueChange,
    inputValue,
    initialSelectedItem,
    onSelectedItemChange,
    // @ts-ignore
    itemToString: (option) => option?.label,
  });

  const onRemoveSelectedOption = useCallback(() => {
    setSelectedItem({ value: '', label: '' });
    onChange(null, name);
  }, [setSelectedItem, onChange, name]);
  useEffect(() => {
    if (shouldClearSelection) {
      setSelectedItem({ value: '', label: '' });
      onChange(null, name);
    }
  }, [shouldClearSelection]);
  const isMenuOpen = isCreatable ? options.length && isOpen : isOpen;
  return (
    <div className="downshift-select" {...commonProps}>
      <div {...wrapperClassNameProps} {...getComboboxProps({ disabled })}>
        <input
          {...getInputProps({
            disabled,
            placeholder,
            onClick: toggleMenu,
            ref,
          })}
          name={name}
          className={`downshift-select__input ${
            disabled ? 'downshift-select__input--disabled' : ''
          }`}
        />
        <div className="downshift-select__input-icons">
          {isLoading || isLoadOptionsLoading ? (
            <div className="downshift-select__spinner" />
          ) : null}
          {isClearable && selectedItem?.value ? (
            <div
              className="downshift-select__input-icon"
              onClick={onRemoveSelectedOption}
            >
              <CloseIcon />
            </div>
          ) : null}
          <div
            className={`downshift-select__input-icon ${
              disabled ? 'downshift-select__input-icon--disabled' : ''
            }`}
            {...getToggleButtonProps({ disabled })}
            aria-label="toggle menu"
          >
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
      </div>
      <div
        {...getMenuProps()}
        className="downshift-select__options-menu-wrapper"
      >
        {isMenuOpen ? (
          <ul className="downshift-select__options-menu">
            {options.map((item: any, index) => {
              // @ts-ignore
              if (item.type === CreateOptionName) {
                return (
                  <li
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                    className={`downshift-select__option ${
                      highlightedIndex === index
                        ? 'downshift-select__option--active'
                        : ''
                    }`}
                  >
                    {`Create ${inputValue}`}
                  </li>
                );
              }
              return (
                <li
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                  className={`downshift-select__option ${
                    highlightedIndex === index
                      ? 'downshift-select__option--active'
                      : ''
                  }`}
                >
                  {/*// @ts-ignore */}
                  {item.Component ? (
                    <item.Component label={item.label} entity={item.entity} />
                  ) : (
                    item.label
                  )}
                </li>
              );
            })}
            {options.length === 0 && !isCreatable ? (
              <li
                key="no-options"
                className="downshift-select__option downshift-select__option--none-available"
              >
                No options available
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
});

const useAsyncSelect = ({ loadOptions }) => {
  const [options, setOptions] = useState([]);
  const [isLoadOptionsLoading, setIsLoadOptionsLoading] = useState(false);
  const loadOptionsAndUpdateState = useCallback(
    async (inputValue) => {
      setIsLoadOptionsLoading(true);
      let options = [];
      try {
        const res = await loadOptions(inputValue);
        options = res;
        setOptions(res);
      } catch (e) {
      } finally {
        setIsLoadOptionsLoading(false);
      }
      return options;
    },
    [setIsLoadOptionsLoading, setOptions]
  );
  const debouncedSetInputAndLoadOptions = useCallback(
    _.debounce((inputValue) => loadOptionsAndUpdateState(inputValue), 200),
    [loadOptionsAndUpdateState, setOptions]
  );

  useEffect(() => {
    loadOptionsAndUpdateState('');
  }, [loadOptionsAndUpdateState]);

  return {
    debouncedSetInputAndLoadOptions,
    isLoadOptionsLoading,
    loadOptionsAndUpdateState,
    options,
  };
};
