import { AppQuery } from '@nl-lms/common/shared';

import { ApiService } from '../lib';
import { IApiService, Competency as ICompetency, ListResponse } from './types';

type ListForSelectType = 'course';

export class Competency
  extends ApiService
  implements IApiService<'competency'>
{
  public paths: any;
  public serviceBase: string = '/resource/competency';

  constructor(props) {
    super(props);

    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => `${this.serviceBase}`,
      create: () => `${this.serviceBase}`,
      update: (id) => `${this.serviceBase}/${id}`,
      remove: (id) => `${this.serviceBase}/${id}`,
      bulkDelete: () => `${this.serviceBase}`,
      listForSelect: () => `/list/competency`,
    };
  }

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get<null, ListResponse<ICompetency>>(this.paths.list(), {
      params: { query },
    });

  listForSelect = () =>
    this.api.get<null, ICompetency[]>(this.paths.listForSelect());

  create = (entity) => this.api.post(this.paths.create(), entity);

  update = (entity) => this.api.patch(this.paths.update(entity.id), entity);

  remove = ({ id }) => this.api.delete(this.paths.remove(id));

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });
}
