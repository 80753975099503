import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LearningProgramInstanceStatus } from '@nl-lms/common/feature/types';
import {
  AppQuery,
  AppQueryFilter,
  QueryFilter,
  QueryFilterCombinator,
  QueryOperator,
} from '@nl-lms/common/shared';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Box,
  ErrorPlaceholder,
  NoDataPlaceholder,
  Pagination,
  SingleSelect,
  ToggleButtonGroup,
} from '@nl-lms/ui/components';
import { useWindowSize } from '@nl-lms/ui/hooks';
import {
  ListViewProvider,
  useListViewContext,
  useListViewTableData,
} from '@nl-lms/ui/modules';

import { LearnerLearningCard } from '../../../_common/components';
import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { learningProgramsApi } from '../../../_common/services/api/learningProgramsApi';
import {
  LearnerAppLayoutCardGrid,
  LearnerAppLayoutCardGridItem,
  LearnerAppLayoutCardGridNoData,
} from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import {
  allFilter,
  assignedFilter,
  optionalFilter,
} from './LearnerLearningFilters';
import './LearnerLearningList.scss';
import { LearnerLearningListCard } from './LearnerLearningListCard';

const { useListLearningProgramInstancesForALearnerQuery } = learningProgramsApi;

const { useListLearnerLearningQuery } = learnerApi;

export const LearnerLearningToDoList = () => {
  const { query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({ learnerId: authStore.learnerId, query }),
    [query]
  );
  const { isLoading, isSuccess, data, isError, isFetching } =
    // @ts-ignore
    useListLearnerLearningQuery(fetchArg, { refetchOnFocus: true });

  // @ts-ignore
  const [assignments] = useListViewTableData(data);
  const { t } = useTranslation('learner');

  if (isLoading || isFetching) {
    return (
      <LearnerAppLayoutCardGrid>
        <LearnerAppLayoutCardGridItem>
          <LearnerLearningCard.Skeleton />
        </LearnerAppLayoutCardGridItem>
        <LearnerAppLayoutCardGridItem>
          <LearnerLearningCard.Skeleton />
        </LearnerAppLayoutCardGridItem>
        <LearnerAppLayoutCardGridItem>
          <LearnerLearningCard.Skeleton />
        </LearnerAppLayoutCardGridItem>
      </LearnerAppLayoutCardGrid>
    );
  }

  if (isSuccess && !assignments.length) {
    return (
      <LearnerAppLayoutCardGridNoData>
        <NoDataPlaceholder
          iconName="ClipboardIcon"
          title={t('dashboard.emptyassignments.title')}
          subtitle={t('dashboard.emptyassignments.subtitle')}
        />
      </LearnerAppLayoutCardGridNoData>
    );
  }

  if (isError) {
    return (
      <LearnerAppLayoutCardGridNoData>
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          {/*// @ts-ignore */}
          <ErrorPlaceholder.Title>
            {/*// @ts-ignore */}
            {t('dashboard.error.title')}
          </ErrorPlaceholder.Title>
          <ErrorPlaceholder.Description>
            {t('dashboard.error.description')}
          </ErrorPlaceholder.Description>
        </ErrorPlaceholder>
      </LearnerAppLayoutCardGridNoData>
    );
  }

  return (
    <LearnerAppLayoutCardGrid>
      {assignments.map((assignment) => (
        <LearnerAppLayoutCardGridItem key={assignment?.id}>
          <LearnerLearningListCard id={assignment?.id} />
        </LearnerAppLayoutCardGridItem>
      ))}
    </LearnerAppLayoutCardGrid>
  );
};

export const ListPagination = ({ className = '' }) => {
  const { pagination, onChangePagination, query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({ learnerId: authStore.learnerId, query }),
    [query]
  );

  // @ts-ignore
  const { data } = useListLearnerLearningQuery(fetchArg);
  const { width } = useWindowSize();

  useEffect(() => {
    if ((width ?? 0) > 1800) {
      onChangePagination({ ...pagination, limit: 8 });
    }
  }, []);

  const rowCount = data?.count || 0;
  return (
    <Pagination
      className={className}
      pagination={{ ...pagination, rowCount }}
      onChangePage={onChangePagination}
    />
  );
};

export const LearnerLearningListContext = ({ children }) => {
  return (
    <ListViewProvider
      key="learner-learning"
      name="learner-learning"
      paginationLimit={6}
      persistToLocalStorage={false}
      initialFilters={assignedFilter}
      useUrlQuery={true}
    >
      {children}
    </ListViewProvider>
  );
};

export const Filters = () => {
  const [primaryFilter, setPrimaryFilter] = useState('assigned');
  const { onChangeFilters, query, filters } = useListViewContext();
  const learnerId = authStore.learnerId as string;
  const fetchArg = useMemo(() => ({ learnerId, query }), [query]);

  const listProgramsQuery = useMemo<AppQuery>(() => {
    const queryFilter = new QueryFilter();
    queryFilter.add({
      field: 'status',
      value: [
        LearningProgramInstanceStatus.NOT_STARTED,
        LearningProgramInstanceStatus.IN_PROGRESS,
        LearningProgramInstanceStatus.BLOCKED,
      ],
      operator: QueryOperator.Includes,
    });
    return {
      pagination: { offset: 0, limit: 100 },
      filters: queryFilter.appQueryFilter,
    };
  }, []);
  const { isLoading, isFetching } = useListLearnerLearningQuery(fetchArg);
  const { data: availableLearningPrograms } =
    useListLearningProgramInstancesForALearnerQuery({
      learnerId,
      query: { query: transformTsRestQuery(listProgramsQuery) },
      params: { learnerId },
    });

  const hasLearningPrograms = useMemo(() => {
    const count = availableLearningPrograms?.rows?.length || 0;
    return count > 0;
  }, [availableLearningPrograms]);

  const selectedLearningProgram = useMemo(() => {
    if (!hasLearningPrograms || !filters) return undefined;
    const selectedLearningProgramId = getLearningProgramIdFromFilters(filters);
    const selectedLearningProgram = availableLearningPrograms?.rows?.find(
      (lp) => lp.learningProgram.id === selectedLearningProgramId
    )?.learningProgram;
    if (!selectedLearningProgram) return undefined;
    return {
      value: selectedLearningProgram.id,
      label: selectedLearningProgram.name,
    };
  }, [availableLearningPrograms, filters, hasLearningPrograms]);

  const { t } = useTranslation('learner');

  const getCurrentFilter = (filter) => {
    if (filter === 'assigned') return assignedFilter;
    if (filter === 'optional') return optionalFilter;
    if (filter === 'all') return allFilter;
  };

  const onSelectPrimaryFilter = useCallback(
    (selectedFilter) => {
      setPrimaryFilter(selectedFilter);
      const currentFilter = getCurrentFilter(selectedFilter) as AppQueryFilter;
      const newFilter: AppQueryFilter = {
        id: 'combined',
        combinator: QueryFilterCombinator.And,
        value: [currentFilter],
      };
      if (selectedLearningProgram) {
        // @ts-expect-error
        newFilter.value.push({
          id: 'learning_program_id',
          combinator: QueryFilterCombinator.And,
          value: {
            field: {
              field: 'learning_program_id',
              label: 'learning_program_id',
            },
            operator: QueryOperator.Equals,
            value: {
              value: selectedLearningProgram.value,
              label: selectedLearningProgram.label,
            },
          },
        });
      }

      onChangeFilters?.(newFilter);
    },
    [primaryFilter, selectedLearningProgram]
  );

  const onSelectLearningProgramFilter = useCallback(
    (value) => {
      const currentFilter = getCurrentFilter(primaryFilter) as AppQueryFilter;
      const newFilter: AppQueryFilter = {
        id: 'combined',
        combinator: QueryFilterCombinator.And,
        value: [currentFilter],
      };
      if (value) {
        // @ts-expect-error
        newFilter.value.push({
          id: 'learning_program_id',
          combinator: QueryFilterCombinator.And,
          value: {
            field: {
              field: 'learning_program_id',
              label: 'learning_program_id',
            },
            operator: QueryOperator.Equals,
            value: { value: value, label: value },
          },
        });
      }
      onChangeFilters(newFilter);
    },
    [primaryFilter]
  );

  return (
    <Box
      flex={{
        alignItems: 'center',
        gap: 'm',
      }}
      className="learner-learning-list__filters"
    >
      <ToggleButtonGroup
        options={[
          { value: 'assigned', label: t('dashboard.assigned') },
          { value: 'optional', label: t('dashboard.inprogress') },
          { value: 'all', label: t('dashboard.all') },
        ]}
        initialSelectedOption={'assigned'}
        isLoading={isLoading || isFetching}
        onChange={onSelectPrimaryFilter}
        name="filters"
        className="learner-learning-list__filters__toggle-button-group"
      />
      {!hasLearningPrograms ? null : (
        <div className="learner-learning-list__filters__select">
          <SingleSelect
            name="program-select"
            placeholder={t('dashboard.filterbyprogram')}
            onChange={onSelectLearningProgramFilter}
            selectedItem={selectedLearningProgram}
            options={
              availableLearningPrograms?.rows?.map((lp) => ({
                value: lp.learningProgram.id,
                label: lp.learningProgram.name,
              })) || []
            }
            isLoading={isLoading || isFetching}
            isClearable
          />
        </div>
      )}
    </Box>
  );
};

LearnerLearningToDoList.Pagination = ListPagination;
LearnerLearningToDoList.ListContext = LearnerLearningListContext;
LearnerLearningToDoList.Filters = Filters;

export default LearnerLearningToDoList;

function getLearningProgramIdFromFilters(
  filters: AppQueryFilter,
  recurrenceCounter = 0
) {
  if (recurrenceCounter > 50) return null;
  let learningProgramId = '';
  if (!filters.value) return learningProgramId;
  if (Array.isArray(filters.value)) {
    for (const filter of filters.value) {
      const programIdFromRecurrence = getLearningProgramIdFromFilters(
        filter,
        recurrenceCounter + 1
      );
      if (programIdFromRecurrence) {
        learningProgramId = programIdFromRecurrence;
        break;
      }
    }
  } else if (
    filters.value.field.field === 'learningProgramId' ||
    filters.value.field.field === 'learning_program_id'
  ) {
    learningProgramId = filters.value.value?.value as string;
  }
  return learningProgramId;
}
