export const RegistrationRouterPrefix = '/registration';

export const RegistrationScope = {
  view: { resource: 'registration', action: 'view' },
  delete: { resource: 'registration', action: 'delete' },
  confirmEmail: {
    resource: 'registration',
    action: 'confirm-email',
  },
  markInReview: {
    resource: 'registration',
    action: 'mark-in-review',
  },
  decline: {
    resource: 'registration',
    action: 'decline',
  },
  complete: {
    resource: 'registration',
    action: 'complete',
  },
  block: {
    resource: 'registration',
    action: 'block',
  },
};

export const RegistrationNotificationAudienceName = 'registration';

export const RegistrationNotificationTemplateNames = {
  EmailConfirmation: 'RegistrationEmailConfirmationNotificationTemplate',
  EmailConfirmed: 'RegistrationEmailConfirmedNotificationTemplate',
  InReview: 'RegistrationInReviewNotificationTemplate',
  Declined: 'RegistrationDeclinedNotificationTemplate',
  Completed: 'RegistrationCompletedNotificationTemplate',
} as const;

export const RegistrationNotificationMentionOptions = [
  {
    id: '{{registration.firstName}}',
    label: '{{registration.firstName}}',
  },
  { id: '{{registration.lastName}}', label: '{{registration.lastName}}' },
  { id: '{{registration.email}}', label: '{{registration.email}}' },
];
