import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { ContentLibraryItemRouterContract } from './content-library-item.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'contentLibraryItemsStore';

  const tsRest = buildTsRestInstance<typeof ContentLibraryItemRouterContract>(
    apiInstance,
    ContentLibraryItemRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listContentLibraryItems: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof ContentLibraryItemRouterContract.listContentLibraryItems.query
          >
        >
      >,
      z.infer<
        (typeof ContentLibraryItemRouterContract.listContentLibraryItems.responses)['200']
      >
    >;
    removeContentLibraryItems: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof ContentLibraryItemRouterContract.removeContentLibraryItems.body
        >
      >,
      z.infer<
        (typeof ContentLibraryItemRouterContract.removeContentLibraryItems.responses)['200']
      >
    >;
    createContentLibraryItem: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof ContentLibraryItemRouterContract.createContentLibraryItem.body
        >
      >,
      z.infer<
        (typeof ContentLibraryItemRouterContract.createContentLibraryItem.responses)['200']
      >
    >;
    updateContentLibraryItem: RtkMutation<
      RtkFullParams<
        {},
        z.infer<
          typeof ContentLibraryItemRouterContract.updateContentLibraryItem.body
        >,
        z.infer<
          typeof ContentLibraryItemRouterContract.updateContentLibraryItem.pathParams
        >
      >,
      z.infer<
        (typeof ContentLibraryItemRouterContract.updateContentLibraryItem.responses)['200']
      >
    >;
    getContentLibraryItem: RtkQuery<
      RtkFullParams<
        {},
        {},
        z.infer<
          typeof ContentLibraryItemRouterContract.getContentLibraryItem.pathParams
        >
      >,
      z.infer<
        (typeof ContentLibraryItemRouterContract.getContentLibraryItem.responses)['200']
      >
    >;
  }>(tsRest, ContentLibraryItemRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
