export const formatSecondsToStandard = (secondCount: any) => {
  let newSeconds = secondCount;
  if (!secondCount || secondCount === '0') return '00:00:00';
  if (typeof secondCount !== 'number') {
    newSeconds = parseInt(secondCount);
  }
  let hours = Math.floor(newSeconds / 3600);
  let hoursRest = secondCount - hours * 3600;
  let minutes = Math.floor(hoursRest / 60);
  let seconds = hoursRest - minutes * 60;

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const formatMinutesToStandard = (minutes: any) => {
  let newMinutes = minutes;
  if (!minutes || minutes === '0') newMinutes = 0;
  if (typeof minutes !== 'number') {
    newMinutes = parseInt(minutes);
  }
  return formatSecondsToStandard(newMinutes * 60);
};

export const formatHoursToStandard = (hours: any) => {
  let newHours = hours;
  if (!hours || hours === '0') newHours = 0;
  if (typeof hours !== 'number') {
    newHours = parseInt(hours);
  }
  return formatSecondsToStandard(newHours * 3600);
};
