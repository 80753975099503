import { zodResolver } from '@hookform/resolvers/zod';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { TimeDeltaSchema } from '@nl-lms/common/shared';
import { Certification } from '@nl-lms/feature/certification/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  ScheduleDateInput,
  Separator,
  SideModal,
  Textarea,
  Typography,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';

type Props = {
  certification?: Partial<Certification>;
};

const Schema = z.object({
  name: z.string(),
  validityPeriod: TimeDeltaSchema.nullable(),
  template: z.string(),
});

export const AdminCertificationEditFormSideModal = ({
  certification = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...certification,
      template: certification.template?.html,
    },
  });

  const entityId = useMemo(() => certification.id, [certification]);
  console.log(entityId);

  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateCertificationMutation',
    createHookName: 'useCreateCertificationMutation',
    entityId,
    parseEntity: (entity) => ({
      ...entity,
      template: { html: entity.template },
    }),
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {certification.id ? 'Edit Certification' : 'Create New Certification'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Tag Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Valid Until"
              errorMessage={errors?.validityPeriod?.message}
            >
              <Controller
                name="validityPeriod"
                control={control}
                render={({ field }) => (
                  <ScheduleDateInput
                    value={{ value: field.value || null, type: 'relative' }}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Template"
              errorMessage={errors?.template?.message}
            >
              <Textarea
                // @ts-ignore
                name="template"
                hasError={!!errors.template?.message}
                {...register('template')}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={certification.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
