import { z } from 'zod';

import {
  LearningAssignmentRuleStarDate,
  ScheduleDateSchema,
} from '@nl-lms/common/feature/types';
import {
  ArraySelectFieldMetadata,
  BooleanFieldMetadata,
  DateFieldMetadata,
  DatetimeFieldMetadata,
  FieldMetadata,
  FieldType,
  JsonArraySelectFieldMetadata,
  NumberFieldMetadata,
  QueryFilterCombinator,
  QueryOperator,
  SelectFieldMetadata,
  StringFieldMetadata,
} from '@nl-lms/common/shared';

type FieldOptionType =
  | 'ReferenceEntityOption'
  | 'ReferenceEventOption'
  | 'StringValueOption'
  | 'NumberValueOption'
  | 'BooleanValueOption'
  | 'DateValueOption'
  | 'DatetimeValueOption'
  | 'SelectValueOption'
  | 'OperatorOption'
  | 'FieldOption'
  | 'SettingsOptions';

type FieldOptionMetadata =
  | FieldMetadata
  | AssignationRuleEvent
  | AssignationRuleEventEntity
  | StringFieldMetadata
  | NumberFieldMetadata
  | BooleanFieldMetadata
  | DateFieldMetadata
  | DatetimeFieldMetadata
  | SelectFieldMetadata
  | ArraySelectFieldMetadata
  | JsonArraySelectFieldMetadata;

type FieldOptionValue =
  | number
  | string
  | boolean
  | QueryOperator
  | 'EditValue'
  | 'EditOperator'
  | 'RemoveFilter';

export type GenericFieldOption = {
  id: string;
  field: FieldOptionMetadata;
  type: FieldOptionType;
  operator?: QueryOperator;
  value: FieldOptionValue;
  label: string;
  Component?: any;
  isLoading?: boolean;
  isChecked?: boolean;
  payload?: AssignationRuleEvent['payload'];
  filterFields?: AssignationRuleEvent['filterFields'];
};

export type AssignationRuleFilterbarReferencesMap = Record<
  'assignmentId' | 'learnerId' | 'learnerGroupId',
  AssignationRuleReference | {}
>;

export type AssignationRuleEventEntityType =
  | 'assignmentId'
  | 'learnerId'
  | 'learnerGroupId';

export type AssignationRuleEventType =
  | 'learning-assignment-instance.created'
  | 'learning-assignment-instance.started'
  | 'learning-assignment-instance.completed'
  | 'learning-assignment-instance.failed'
  | 'learning-assignment.updated'
  | 'learning-assignment-instance.updated'
  | 'learning-assignment.removed'
  | 'learning-assignment-instance.removed'
  | 'learning-assignment.learner-joined-audience'
  | 'learning-assignment.learner-left-audience';

export const AssignationRuleEventTypeSchema = z.union([
  z.literal('learning-assignment-instance.created'),
  z.literal('learning-assignment-instance.updated'),
  z.literal('learning-assignment-instance.removed'),
  z.literal('learning-assignment-instance.started'),
  z.literal('learning-assignment-instance.completed'),
  z.literal('learning-assignment-instance.failed'),
  z.literal('learning-assignment.updated'),
  z.literal('learning-assignment.removed'),
  z.literal('learning-assignment.learner-joined-audience'),
  z.literal('learning-assignment.learner-left-audience'),
  z.literal(''),
]);

export type AssignationRuleReference = {
  value: string;
  label: string;
  type: AssignationRuleEventEntityType;
};

export type AssignationRuleEvent = {
  name: AssignationRuleEventType;
  label: string;
  fieldLabel: string;
  type: FieldType;
  payload:
    | {
        mandatory?: boolean;
        name: string;
        label: string;
        type: FieldType.select;
        loadOptions?: (inputValue: string) => Promise<any[]>;
      }[]
    | {}[];
  mandatoryFilterFields?: boolean;
  filterFields: AssignationRuleFilterFieldComponent['field'][];
};

export type AssignationRuleFilterFieldComponent = {
  type:
    | 'SelectFilterComponent'
    | 'BooleanFilterComponent'
    | 'DatetimeFilterComponent'
    | 'DateFilterComponent'
    | 'StringFilterComponent'
    | 'NumberFilterComponent';
  index: number;
  field: {
    type: FieldType;
    name: string;
    label: string;
  };
  operator: QueryOperator;
  value: boolean;
};

export type AssignationRuleFilterFieldComponentType =
  AssignationRuleFilterFieldComponent['type'];

export type AssignationRuleFilterCombinatorComponent = {
  index: number;
  type: 'CombinatorComponent';
  value: QueryFilterCombinator;
};

export type AssignationRuleFilterBracketComponent = {
  index: number;
  type: 'BracketComponent';
  value: '(' | ')';
};

export type AssignationRuleFilterMatch =
  | AssignationRuleFilterFieldComponent
  | AssignationRuleFilterCombinatorComponent
  | AssignationRuleFilterBracketComponent;

export type AssignationRuleEventEntity = {
  name: string;
  type: AssignationRuleEventEntityType;
};

export type AssignationRuleConditionValue = {
  event?: AssignationRuleEvent['name'];
  eventName?: AssignationRuleEventType;
  reference?: {
    id?: string;
    label?: string;
    type?: 'assignmentId';
  };
  learner?: {
    id?: string;
    label?: string;
    type?: 'learnerId';
  };
  learnerGroup?: {
    id?: string;
    label?: string;
    type?: 'learnerGroupId';
  };
  match?: AssignationRuleFilterMatch;
};

export type AssignationRuleCond = {
  combinator: 'and' | 'or' | null;
  value: AssignationRuleConditionValue | AssignationRuleCond | {};
};

export type AssignationRule = {
  name: string;
  subject?: {
    id: string;
    name: string;
  };
  subjectId: string;
  actionName: string;
  learningAssignmentId?: string;
  startDate?: LearningAssignmentRuleStarDate;
  conditions?: AssignationRuleCond[] | [];
  settings?: { triggerNTimes?: number };
};

const AssignmentRuleConditionsArraySchema = z.array(
  z.object({
    value: z.union([
      z.object({
        value: z.object({}),
        combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
      }),
      z.object({
        eventName: AssignationRuleEventTypeSchema.nullish(),
        reference: z
          .union([
            z.object({
              id: z.string(),
              label: z.string(),
            }),
            z.object({
              id: z.string(),
              name: z.string(),
            }),
          ])
          .nullish(),
        referenceId: z.string().nullable(),
        match: z
          .object({
            field: z.string().nullish(),
            fieldLabel: z.string().nullish(),
            fieldType: z.string().nullish(),
            fieldLoadOptions: z.function().nullish(),
            operator: z.string().nullish(),
            value: z
              .union([
                z.string(),
                z.number(),
                z.boolean(),
                z.array(z.string()),
                z.array(z.number()),
              ])
              .nullish(),
            type: z.string().nullish(),
          })
          .nullable(),
      }),
    ]),
    combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
  })
);

export const LearningAssignmentRulesFormBaseSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  subject: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullish(),
  subjectId: z.string().nullish(),
  startDate: ScheduleDateSchema.nullish(),
  settings: z.object({
    triggerNTimes: z.string().nullish(),
  }),
  createPlannedInstances: z.boolean().nullish(),
  conditions: AssignmentRuleConditionsArraySchema,
});

export const LearningAssignmentRulePayloadBaseSchema = z.object({
  name: z.string(),
  subject: z.object({
    id: z.string().min(1, { message: 'Field is required' }),
    name: z.string().min(1, { message: 'Field is required' }),
  }),
  template: z.string().nullish(),
  startDate: ScheduleDateSchema.nullish(),
  createPlannedInstances: z.boolean(),
  triggerTimes: z.string().refine((n) => parseInt(n) > 0),
  conditions: AssignmentRuleConditionsArraySchema,
});
