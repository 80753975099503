import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { IApiMethods, IApiService, Tag as ITag, ListResponse } from './types';

type ListForSelectType = 'assessment_question' | 'course' | 'subscription';

export class Tag
  extends ApiService
  implements IApiService<'tag'>, IApiMethods<'tag'>
{
  static serviceBase = '/resource/tag';
  public paths = {
    get: (id) => `${Tag.serviceBase}/${id}`,
    list: () => `${Tag.serviceBase}`,
    create: () => `${Tag.serviceBase}`,
    update: (id) => `${Tag.serviceBase}/${id}`,
    remove: (id) => `${Tag.serviceBase}/${id}`,
    bulkDelete: () => `${Tag.serviceBase}`,
    listForSelect: (type: ListForSelectType) => {
      if (!type) return `/list/tag`;
      return `/list/tag/${type}`;
    },
  };

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get<null, ListResponse<ITag>>(this.paths.list(), {
      params: { query },
    });

  listForSelect = (type?: ListForSelectType) =>
    // @ts-ignore
    this.api.get<null, ITag[]>(this.paths.listForSelect(type));

  create = (entity) => this.api.post(this.paths.create(), entity);

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  remove = ({ id }) => this.api.delete(this.paths.remove(id));

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });
}
