import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  FormField,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  Separator,
  Typography,
} from '@nl-lms/ui/components';

import { LearningUnitCard } from './AdminLearningPathLearningUnitCard';
import { AdminLearningPathLearningUnitSelect } from './AdminLearningPathLearningUnitSelect';
import './AdminLearningPathLearningUnitsSection.scss';

export const LearningPathLearningUnitsCardSection = ({
  onChange,
  value,
  errors,
  learningPath,
}) => {
  const [items, setItems] = useState(value || []);

  const sortedItems = useMemo(
    () => items.sort(({ order: o1 }, { order: o2 }) => o1 - o2),
    [items]
  );

  useEffect(() => {
    onChange(
      items.map((item, index) => ({
        ...item,
        order: index,
        learningPathId: learningPath?.id,
      }))
    );
  }, [items]);

  const onAddUnit = ({ unit, unitType }) => {
    const unitTypeMap = {
      'In Class': 'course',
      'Virtual In Class': 'course',
      Conference: 'course',
      Elearning: 'elearningCourse',
      Assessment: 'assessmentForm',
    };
    const unitTypeIdMap = {
      'In Class': 'courseId',
      'Virtual In Class': 'courseId',
      Conference: 'courseId',
      Elearning: 'elearningCourseId',
      Assessment: 'assessmentFormId',
    };

    setItems([
      ...items,
      {
        mandatory: false,
        sequential: false,
        [unitTypeMap[unitType]]: unit,
        [unitTypeIdMap[unitType]]: unit.id,
      },
    ]);
  };

  const onChangeUnit = (unitId, ...args) => {
    let updatedItems = items;

    if (args.length) {
      const [prop, propValue] = args;
      switch (prop) {
        case 'next':
          {
            const itemOrder = sortedItems.findIndex((item) => {
              return (
                item.courseId === unitId ||
                item.elearningCourseId === unitId ||
                item.assessmentFormId === unitId
              );
            });
            if (itemOrder + 1 < items.length) {
              const nextItem = sortedItems[itemOrder + 1];
              const currentItem = sortedItems[itemOrder];

              updatedItems = sortedItems.map((item, index) => {
                let _item = item;
                if (index === itemOrder) _item = nextItem;
                if (index === itemOrder + 1) _item = currentItem;
                _item.order = index;
                return _item;
              });
            }
          }
          break;
        case 'prev':
          {
            const itemOrder = sortedItems.findIndex((item) => {
              return (
                item.courseId === unitId ||
                item.elearningCourseId === unitId ||
                item.assessmentFormId === unitId
              );
            });

            if (itemOrder - 1 >= 0) {
              const prevItem = sortedItems[itemOrder - 1];
              const currentItem = sortedItems[itemOrder];

              updatedItems = sortedItems.map((item, index) => {
                let _item = item;
                if (index === itemOrder) _item = prevItem;
                if (index === itemOrder - 1) _item = currentItem;
                _item.order = index;
                return _item;
              });
            }
          }
          break;
        case 'remove':
          updatedItems = sortedItems.filter((item) => {
            return (
              item.courseId !== unitId &&
              item.elearningCourseId !== unitId &&
              item.assessmentFormId !== unitId
            );
          });
          break;
        case 'mandatory':
          updatedItems = sortedItems.map((item) => {
            if (
              item.courseId === unitId ||
              item.elearningCourseId === unitId ||
              item.assessmentFormId === unitId
            ) {
              return { ...item, mandatory: propValue };
            }

            return item;
          });
          break;
        case 'sequential':
          updatedItems = sortedItems.map((item) => {
            if (
              item.courseId === unitId ||
              item.elearningCourseId === unitId ||
              item.assessmentFormId === unitId
            ) {
              return { ...item, sequential: propValue };
            }

            return item;
          });
          break;
        case 'validationType':
          updatedItems = sortedItems.map((item) => {
            if (
              item.courseId === unitId ||
              item.elearningCourseId === unitId ||
              item.assessmentFormId === unitId
            ) {
              return { ...item, validationType: propValue };
            }

            return item;
          });
          break;
      }
    }

    setItems(updatedItems);
  };

  return (
    <>
      <Typography.h2>Add Learning Unit</Typography.h2>
      <Box>
        <Box>
          <FormField
            label="Learning Unit"
            required
            errorMessage={errors?.items?.message}
          >
            <AdminLearningPathLearningUnitSelect
              onSubmit={onAddUnit}
              hasError={!!errors?.items?.message}
            />
          </FormField>
        </Box>
      </Box>
      <Separator />
      <Typography.h2>
        Path Review
        <div className="learning-units-section__subtitle">
          {`${items.length} learning ${items.length === 1 ? 'unit' : 'units'}`}
        </div>
      </Typography.h2>
      {sortedItems.length > 0 ? (
        <div className="learning-units-section__items-container">
          <div className="learning-units-section__cards">
            {sortedItems.map((item, index) => (
              <LearningUnitCard
                unit={item}
                key={index}
                onChange={onChangeUnit}
                disabledSequential={index === 0}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <NoDataPlaceholder
            iconName="AddIcon"
            color={NoDataPlaceholderColor.success}
            title="There are no learning units added"
            subtitle="You can start selecting learning units now"
          />
        </div>
      )}
    </>
  );
};
