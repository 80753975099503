import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LiveCourse } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  Editor,
  FormField,
  Input,
  Separator,
  SideModal,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useUploadFile } from '../../../_common/hooks';
import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import { AdminDailyScheduleTemplateInput } from '../../_common/AdminDailyScheduleTemplateInput/AdminDailyScheduleTemplateInput';
import { AdminCompetencySelect } from '../AdminCompetency/AdminCompetencySelect';
import { AdminLearnerGroupMultiSelect } from '../AdminLearner/AdminLearnerGroupSelect';
import { AdminLearnerMultiSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminLiveSessionApprovalTypeSelect } from '../AdminLiveSession/AdminLiveSessionApprovalTypeSelect';
import {
  AdminSurveyMultiSelect,
  AdminSurveySingleSelect,
} from '../AdminSurvey/AdminSurveySelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminVenueFeaturesSelect } from '../AdminVenue/AdminVenueFeaturesSelect';
import { AdminLiveCourseLanguageSelect } from './AdminLiveCourseLanguageSelect';
import AdminLiveCourseLearningTypeSelect from './AdminLiveCourseLearningTypeSelect';

type Props = {
  liveCourse?: Partial<LiveCourse>;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  language: yup.string().required(),
  prework: yup.string(),
  description: yup.string(),
  thumbnail: yup.string(),
  minPax: yup.number().required(),
  maxPax: yup.number().required(),
  canRegisterToMultipleSessions: yup.boolean().required(),
  feedbackFormIds: yup.array(),
  registrationFormId: yup.string().nullable(),
  tagIds: yup.array(),
  targetLearnerGroupIds: yup.array(),
  scheduleTemplate: yup.array().required().min(1),
  requirements: yup.array().of(yup.string()),
  trainingSessionApprovalType: yup
    .number()
    .oneOf(Object.values(C.I_APPROVAL_TYPES))
    .required(),
  trainingSessionApproverIds: yup.array(),
  type: yup.number().oneOf(Object.values(C.I_LEARNING_TYPES)).required(),
  competencyIds: yup.array(),
  managerLearnerAttendedNotificationMessage: yup.string().nullable(),
  vendorId: yup.string().nullable(),
});

const parseEntity = (course: Partial<LiveCourse>) => {
  const surveys = [];
  if (course.feedbackFormIds) {
    surveys.push(
      // @ts-ignore
      ...course.feedbackFormIds.map((id) => ({
        surveyId: id,
        type: C.I_SURVEY_TYPES.FEEDBACK,
      })),
    );
  }
  if (course.registrationFormId) {
    // @ts-ignore
    surveys.push({
      surveyId: course.registrationFormId,
      type: C.I_SURVEY_TYPES.PRIVATE_REGISTRATION,
    });
  }
  return {
    ...course,
    surveys,
    trainingSessionApproverIds: course.trainingSessionApproverIds?.map(
      // @ts-ignore
      (a) => a.value,
    ),
  };
};
export const AdminLiveCourseEditFormSideModal = ({
  liveCourse = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ..._.omit(liveCourse, 'id'),
    },
  });

  const { onUpload } = useUploadFile({ type: 'text_editor_asset' });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateLiveCourseMutation',
    updateHookName: 'useUpdateLiveCourseMutation',
    entityId: liveCourse.id,
    parseEntity,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {liveCourse.id ? 'Edit Live Course' : 'Create New Live Course'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Box margin={{ bottom: 'm' }}>
            <Typography.h2>General</Typography.h2>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Language"
              required
              errorMessage={errors?.language?.message}
            >
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <AdminLiveCourseLanguageSelect
                    {...field}
                    // @ts-ignore
                    initialValue={liveCourse.language}
                    hasError={!!errors?.language?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Type"
              required
              errorMessage={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <AdminLiveCourseLearningTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={liveCourse.type}
                    hasError={!!errors?.type?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Description"
              errorMessage={errors?.description?.message}
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Editor {...field} onUploadFile={onUpload} />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Agenda"
              errorMessage={errors?.description?.message}
            >
              <Controller
                name="prework"
                control={control}
                render={({ field }) => <TextEditorWithFileUpload {...field} />}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Registration</Typography.h2>
        </Box>
        <Box>
          <FormField
            label="Registration Form"
            errorMessage={errors?.registrationFormId?.message}
          >
            <Controller
              name="registrationFormId"
              control={control}
              render={({ field }) => (
                <AdminSurveySingleSelect
                  {...field}
                  initialSelectedItem={
                    field.value || liveCourse?.registrationFormId
                  }
                  isClearable
                  hasError={!!errors?.registrationFormId?.message}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Approval Type"
              required
              errorMessage={errors?.trainingSessionApprovalType?.message}
            >
              <Controller
                name="trainingSessionApprovalType"
                control={control}
                render={({ field }) => (
                  <AdminLiveSessionApprovalTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={liveCourse.trainingSessionApprovalType}
                    hasError={!!errors?.trainingSessionApprovalType?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="Approvers">
              <Controller
                name="trainingSessionApproverIds"
                control={control}
                render={({ field }) => (
                  <AdminLearnerMultiSelect
                    {...field}
                    initialSelectedItems={liveCourse.trainingSessionApprovers?.map(
                      (a) => ({
                        value: a.id,
                        label: `${a.firstName} ${a.lastName}`,
                      }),
                    )}
                    disabled={
                      watch('trainingSessionApprovalType') !=
                      C.I_APPROVAL_TYPES.TRAINING_SESSION_HIERARCHIC_MANAGER
                    }
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <Checkbox
              {...register('canRegisterToMultipleSessions')}
              label="Can Register To Multiple Sessions"
            />
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor">
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    initialSelectedItem={{
                      value: liveCourse?.vendorId,
                      // @ts-ignore
                      label: liveCourse?.vendorName,
                    }}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Tags">
              <Controller
                name="tagIds"
                control={control}
                render={({ field }) => (
                  <AdminTagSelect {...field} selectedItems={field.value} />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Competencies">
              <Controller
                name="competencyIds"
                control={control}
                render={({ field }) => (
                  <AdminCompetencySelect
                    onChange={field.onChange}
                    selectedItems={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Learner Visibility">
            <Controller
              name="targetLearnerGroupIds"
              control={control}
              render={({ field }) => (
                <AdminLearnerGroupMultiSelect
                  selectedItems={field.value}
                  {...field}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Min Pax"
              required
              errorMessage={errors?.minPax?.message}
            >
              <Input
                required
                {...register('minPax')}
                type="number"
                hasError={!!errors?.minPax?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Max Pax"
              required
              errorMessage={errors?.maxPax?.message}
            >
              <Input
                required
                {...register('maxPax')}
                type="number"
                hasError={!!errors?.maxPax?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Requirements">
            <Controller
              name="requirements"
              control={control}
              render={({ field }) => (
                <AdminVenueFeaturesSelect
                  {...field}
                  // @ts-ignore
                  initialValue={liveCourse.requirements}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <FormField label="Feedback Forms">
            <Controller
              name="feedbackFormIds"
              control={control}
              render={({ field }) => (
                <AdminSurveyMultiSelect
                  {...field}
                  selectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Thumbnail"
              errorMessage={errors?.thumbnail?.message}
            >
              <Controller
                name="thumbnail"
                control={control}
                render={({ field }) => (
                  <ImageUploadInput
                    {...field}
                    initialS3Url={liveCourse.thumbnail}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Schedule</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Schedule"
              required
              errorMessage={
                errors?.scheduleTemplate?.message as string | undefined
              }
            >
              <Controller
                name="scheduleTemplate"
                control={control}
                render={({ field }) => (
                  <AdminDailyScheduleTemplateInput
                    {...field}
                    initialValue={liveCourse.scheduleTemplate}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Notifications</Typography.h2>
        </Box>
        <Box>
          <Box>
            <Controller
              name="managerLearnerAttendedNotificationMessage"
              control={control}
              render={({ field }) => (
                <TextEditorWithFileUpload
                  mentionOptions={[
                    {
                      id: '{{learner.first_name}}',
                      label: '{{learner.first_name}}',
                    },
                    {
                      id: '{{learner.last_name}}',
                      label: '{{learner.last_name}}',
                    },
                    { id: '{{learner.email}}', label: '{{learner.email}}' },
                    {
                      id: '{{manager.first_name}}',
                      label: '{{manager.first_name}}',
                    },
                    {
                      id: '{{manager.last_name}}',
                      label: '{{manager.last_name}}',
                    },
                    { id: '{{manager.email}}', label: '{{manager.email}}' },
                    {
                      id: '{{training_session.name}}',
                      label: '{{training_session.name}}',
                    },
                    {
                      id: '{{training_session.course}}',
                      label: '{{training_session.course}}',
                    },
                    {
                      id: '{{training_session.location}}',
                      label: '{{training_session.location}}',
                    },
                    {
                      id: '{{training_session.description}}',
                      label: '{{training_session.description}}',
                    },
                    {
                      id: '{{training_session.venue}}',
                      label: '{{training_session.venue}}',
                    },
                    {
                      id: '{{training_session.formatted_end_date}}',
                      label: '{{training_session.formatted_end_date}}',
                    },
                    {
                      id: '{{training_session.formatted_start_date}}',
                      label: '{{training_session.formatted_start_date}}',
                    },
                  ]}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={liveCourse.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
