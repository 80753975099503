import { ReactNode } from 'react';

import {
  Badge,
  BadgeGroup,
  BadgeTypes,
  Skeleton,
  Tooltip,
} from '@nl-lms/ui/components';

import './LearnerLearningTile.scss';

export const LearnerLearningTile = ({
  children,
  className = '',
  isLoading = false,
  disabled = false,
  style = {},
}) => {
  if (isLoading) return <Skeleton className={className} />;

  let thumbnail = <Thumbnail imageUrl="" />;
  let title = <Title>Title missing</Title>;
  let detail: ReactNode;
  let description: ReactNode;
  let tags: ReactNode;
  let titleIcon: ReactNode;
  const actions: ReactNode[] = [];

  const parsedChildren = (
    Array.isArray(children) ? children : [children]
  ).flat();

  parsedChildren.forEach((child) => {
    if (!child) return;

    if (child.type === Thumbnail) thumbnail = child;
    else if (child.type === TitleIcon) titleIcon = child;
    else if (child.type === Title) title = child;
    else if (child.type === Detail) detail = child;
    else if (child.type === Description) description = child;
    else if (child.type === Tags) tags = child;
    else if (child.type === Action) actions.push(child);
  });

  return (
    <div
      className={`learner-learning-tile ${className || ''} ${
        disabled && 'learner-learning-tile--disabled'
      }`}
      style={style}
    >
      <Header>
        {thumbnail}
        <HeaderContent>
          {title}
          {detail}
        </HeaderContent>
        {titleIcon}
      </Header>
      <Body>
        {tags}
        {description}
      </Body>
      {!!actions.length && (
        <div className="learner-learning-tile__actions">{actions}</div>
      )}
    </div>
  );
};

const Body = ({ children }) => (
  <div className="learner-learning-tile__body">{children}</div>
);

const Header = ({ children }) => (
  <div className="learner-learning-tile__header">{children}</div>
);

const HeaderContent = ({ children }) => (
  <div className="learner-learning-tile__header-content">{children}</div>
);

const Thumbnail = ({ imageUrl }) => (
  <div
    className="learner-learning-tile__image"
    style={{ backgroundImage: `url("${imageUrl}")` }}
  ></div>
);

const Title = ({ children }) => (
  <div className="learner-learning-tile__title">
    <Tooltip title={children}>{children}</Tooltip>
  </div>
);

const Detail = ({ children }) => (
  <div className="learner-learning-tile__detail">{children}</div>
);

const TitleIcon = ({ children, type }) => (
  <div
    className={`learner-learning-tile__title-icon learner-learning-tile__title-icon--${type}`}
  >
    {children}
  </div>
);

const Description = ({ children }) => (
  <div
    className="learner-learning-tile__description"
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

const Action = ({ children }) => (
  <div className="learner-learning-tile__action">{children}</div>
);

const Tags = ({ children, type = BadgeTypes.INFO }) => {
  if (!children) return;
  const parsedChildren = Array.isArray(children) ? children : [children];

  return (
    <div className="learner-learning-tile__tags">
      <BadgeGroup name="learningLibraryListTags" type={type}>
        {parsedChildren.map((label, index) => (
          <Badge label={label} type={type} key={index} />
        ))}
      </BadgeGroup>
    </div>
  );
};

LearnerLearningTile.Thumbnail = Thumbnail;
LearnerLearningTile.Title = Title;
LearnerLearningTile.Detail = Detail;
LearnerLearningTile.Description = Description;
LearnerLearningTile.Action = Action;
LearnerLearningTile.Tags = Tags;
LearnerLearningTile.Skeleton = Skeleton;
LearnerLearningTile.TitleIcon = TitleIcon;
