import { getTotalScheduleDuration } from '../../../_common/utils';
import './AdminDailyScheduleTemplateInput.scss';
import { ScheduleBlockAddInput } from './ScheduleBlockAddInput';
import { ScheduleDayAddInput } from './ScheduleDayAddInput';
import { DailyScheduleBlock, ScheduleBlock } from './types';
import {
  FormField,
  AccordionTab,
  Accordion,
  Icon,
  Input,
  Separator,
  ScheduleTemplateList,
  DateTime,
  ScheduleDayHeader,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { dateFns } from '@nl-lms/vendor';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  onChange: (schedule: DailyScheduleBlock[]) => void;
  initialValue?: DailyScheduleBlock[];
  name: string;
  value?: DailyScheduleBlock[];
  withDates?: boolean;
  startDate?: Date;
  disabled?: boolean;
  errors?: {
    startDate?: { type: string; message: string };
    endDate?: { type: string; message: string };
  }[];
};

export const AdminDailyScheduleTemplateInput = (props: Props) => {
  const {
    onChange,
    initialValue = [],
    disabled = false,
    value: _value = null,
    withDates = false,
    startDate,
    errors = [],
  } = props;

  const [value, setValue] = useState<DailyScheduleBlock[]>(
    _value || initialValue || []
  );
  const setValueAndOnChange = useCallback(
    (v) => {
      setValue(v);
      if (!onChange) return;

      onChange(v);
    },
    [onChange, disabled]
  );

  const [dayBlockEditValue, setDayBlockEditValue] = useState<{
    [key: string]: ScheduleBlock & { index: number };
  }>({});

  const onAddScheduleDay = useCallback(
    (hours) => {
      if (disabled) return;

      let _startDate = null;
      let _endDate = null;
      let _duration = parseDuration(hours);

      if (withDates && startDate) {
        // @ts-ignore
        _startDate = dateFns.add(startDate, { days: value.length });
        // @ts-ignore
        _endDate = dateFns.add(_startDate, { minutes: _duration });

        // prevent adding/updating when end date becomes next day
        // @ts-ignore
        if (dateFns.compareAsc(dateFns.endOfDay(_startDate), _endDate) === -1) {
          return;
        }
      }

      const newDay = {
        startDate: _startDate,
        endDate: _endDate,
        schedule: [
          {
            type: C.I_SCHEDULE_BLOCK.CONTENT_DELIVERY,
            duration: _duration,
          },
        ],
      };

      setValueAndOnChange([...value, newDay]);
    },
    [value, startDate, withDates, disabled]
  );

  const onSaveScheduleBlock = useCallback(
    (dayIndex, block) => {
      if (disabled) return;

      const _value = _.cloneDeep(value);
      // prevent adding/updating when sum is greater than 24 hours
      if (block.duration && block.duration >= 24) return;

      if (dayBlockEditValue[dayIndex]) {
        _value[dayIndex].schedule[dayBlockEditValue[dayIndex].index] = {
          type: block.type,
          duration: parseDuration(block.duration),
        };

        if (withDates && startDate) {
          // @ts-ignore
          const endDate = dateFns.add(_value[dayIndex].startDate, {
            minutes: getTotalScheduleDuration(_value[dayIndex].schedule),
          });

          // prevent adding/updating when end date becomes next day
          if (
            dateFns.compareAsc(
              // @ts-ignore
              dateFns.endOfDay(_value[dayIndex].startDate),
              endDate
            ) === -1
          ) {
            return;
          }

          _value[dayIndex].endDate = endDate;
        }
      } else {
        _value[dayIndex].schedule.push({
          ...block,
          duration: parseDuration(block.duration),
        });

        if (withDates && startDate) {
          // @ts-ignore
          const endDate = dateFns.add(_value[dayIndex].startDate, {
            minutes: getTotalScheduleDuration(_value[dayIndex].schedule),
          });

          // prevent adding/updating when end date becomes next day
          if (
            dateFns.compareAsc(
              // @ts-ignore
              dateFns.endOfDay(_value[dayIndex].startDate),
              endDate
            ) === -1
          ) {
            return;
          }

          _value[dayIndex].endDate = endDate;
        }
      }

      setValueAndOnChange(_value);
      setDayBlockEditValue({ ...dayBlockEditValue, [dayIndex]: undefined });
    },
    [value, dayBlockEditValue, withDates, startDate, disabled]
  );

  const onRemoveScheduleBlock = useCallback(
    (day, index) => {
      if (disabled) return;

      const _value = _.cloneDeep(value);
      _value[day].schedule = _value[day].schedule.filter(
        (val, i) => i !== index
      );
      if (withDates && startDate) {
        // @ts-ignore
        _value[day].endDate = dateFns.add(_value[day].startDate, {
          minutes: getTotalScheduleDuration(_value[day].schedule),
        });
      }

      setValueAndOnChange(_value);
    },
    [value, withDates, startDate, disabled]
  );

  const onRemoveScheduleDay = useCallback(
    (day) => {
      if (disabled) return;

      setValueAndOnChange(value.filter((val, index) => index !== day));
    },
    [value, disabled]
  );

  const setScheduleBlockEdit = useCallback(
    (day, index) => {
      if (disabled) return;

      const _value = _.cloneDeep(value);
      if (!_value[day].schedule[index]) return;

      setDayBlockEditValue({
        ...dayBlockEditValue,
        [day]: {
          index,
          ..._value[day].schedule[index],
          duration: _value[day].schedule[index].duration / 60,
        },
      });
    },
    [value, dayBlockEditValue, disabled]
  );

  const onEditStartDate = useCallback(
    (dayIndex, newStartDate) => {
      if (disabled) return;
      if (!withDates || !startDate) return;

      const _value = _.cloneDeep(value);
      _value[dayIndex].startDate = newStartDate;
      _value[dayIndex].endDate = dateFns.add(newStartDate, {
        minutes: getTotalScheduleDuration(_value[dayIndex].schedule),
      });
      setValueAndOnChange(_value);
    },
    [value, startDate, disabled]
  );

  const onEditEndDate = useCallback(
    (dayIndex, newEndDate) => {
      if (disabled) return;
      if (!withDates || !startDate) return;
      // new end date smaller than start date
      const _value = _.cloneDeep(value);
      // @ts-ignore
      if (dateFns.compareAsc(_value[dayIndex].startDate, newEndDate) === 1) {
        return;
      }

      _value[dayIndex].endDate = newEndDate;
      _value[dayIndex].schedule[_value[dayIndex].schedule.length - 1] = {
        ..._value[dayIndex].schedule[_value[dayIndex].schedule.length - 1],
        duration: dateFns.differenceInMinutes(
          newEndDate,
          // @ts-ignore
          _value[dayIndex].startDate
        ),
      };
      setValueAndOnChange(_value);
    },
    [value, startDate, disabled]
  );

  const getMinStartDateForDay = useCallback(
    (dayIndex) => {
      if (disabled) return;

      if (dayIndex === 0) return startDate;

      const _value = _.cloneDeep(value);
      // @ts-ignore
      const nextDayFromPrevious = dateFns.add(_value[dayIndex - 1].startDate, {
        days: 1,
      });
      return dateFns.startOfDay(nextDayFromPrevious);
    },
    [value, startDate, disabled]
  );

  const updateScheduleOnStartDateChange = useCallback(
    (newStartDate) => {
      if (!withDates) return;
      const _value = updateScheduleTemplateBasedOnStartDate(
        newStartDate,
        value
      );
      if (!_value) return;
      setValueAndOnChange(_value);
    },
    [value, withDates, disabled]
  );

  // update schedule with new start date
  useEffect(() => {
    updateScheduleOnStartDateChange(startDate);
  }, [startDate]);

  useEffect(() => {
    if (_value && _value.length) setValue(_value);
  }, [_value, onChange]);

  const className = useMemo(() => {
    const base = 'dsti';
    if (disabled) return `${base} dsti--disabled`;
    return base;
  }, [disabled]);

  return (
    <div className={className}>
      <ScheduleDayAddInput onSubmit={onAddScheduleDay} />

      <Accordion className="dsti__days">
        {value.map((scheduleDay, index) => (
          <AccordionTab
            key={index}
            index={index}
            header={({ index }) => (
              <ScheduleDayHeader
                index={index}
                {...scheduleDay}
                withDates={withDates}
              />
            )}
            actions={({ index }) => (
              <div
                className="dsti__delete"
                onClick={() => onRemoveScheduleDay(index)}
              >
                <Icon.DeleteIcon />
              </div>
            )}
          >
            <div className="dsti-day">
              {withDates && !!startDate && (
                <>
                  <div className="dsti-day__date">
                    <FormField
                      label="Day"
                      className="dsti-day__date-component"
                      errorMessage={errors[index]?.startDate?.message}
                    >
                      <DateTime
                        date={scheduleDay.startDate}
                        disabled={index === 0 || disabled}
                        placeholder="Start date"
                        dateFormat="dd/MM/yyyy"
                        minDate={getMinStartDateForDay(index)}
                        showTimeSelect={false}
                        onChange={(e) => {
                          const baseDate = new Date(e.target.value);
                          const minutes = new Date(
                            // @ts-ignore
                            scheduleDay.startDate
                          ).getMinutes();
                          const hours = new Date(
                            // @ts-ignore
                            scheduleDay.startDate
                          ).getHours();
                          baseDate.setMinutes(minutes);
                          baseDate.setHours(hours);
                          onEditStartDate(index, baseDate);
                        }}
                      />
                    </FormField>
                    <FormField
                      label="Start Time"
                      className="dsti-day__date-component"
                      errorMessage={errors[index]?.startDate?.message}
                    >
                      <Input
                        name="start-time"
                        type="time"
                        disabled={index === 0 || disabled}
                        value={dateFns.format(
                          // @ts-ignore
                          new Date(scheduleDay.startDate),
                          'HH:mm'
                        )}
                        onChange={(e) => {
                          const timeValue = e.target.value;
                          const [hours, minutes] = timeValue.split(':');
                          // @ts-ignore
                          const baseDate = new Date(scheduleDay.startDate);
                          baseDate.setHours(parseInt(hours));
                          baseDate.setMinutes(parseInt(minutes));
                          onEditStartDate(index, baseDate);
                        }}
                        placeholder="Set start time"
                      />
                    </FormField>
                    <FormField
                      label="End Time"
                      className="dsti-day__date-component"
                      errorMessage={errors[index]?.endDate?.message}
                    >
                      <Input
                        name="end-time"
                        type="time"
                        disabled={scheduleDay.schedule.length > 1 || disabled}
                        value={dateFns.format(
                          // @ts-ignore
                          new Date(scheduleDay.endDate),
                          'HH:mm'
                        )}
                        min={dateFns.format(
                          // @ts-ignore
                          new Date(scheduleDay.startDate),
                          'HH:mm'
                        )}
                        onChange={(e) => {
                          const timeValue = e.target.value;
                          const [hours, minutes] = timeValue.split(':');
                          // @ts-ignore
                          const baseDate = new Date(scheduleDay.startDate);
                          baseDate.setHours(parseInt(hours));
                          baseDate.setMinutes(parseInt(minutes));
                          onEditEndDate(index, baseDate);
                        }}
                        placeholder="Set end time"
                      />
                    </FormField>
                  </div>
                  {/*// @ts-ignore */}
                  <Separator marginTop={0} />
                </>
              )}

              <div className="dsti-day__block">
                <ScheduleBlockAddInput
                  value={dayBlockEditValue[index]}
                  onSubmit={(e) => onSaveScheduleBlock(index, e)}
                  disabled={disabled}
                />
              </div>
              <Separator />

              <ScheduleTemplateList
                items={scheduleDay.schedule}
                onRemove={(blockIndex) =>
                  onRemoveScheduleBlock(index, blockIndex)
                }
                onItemClick={(blockIndex) =>
                  setScheduleBlockEdit(index, blockIndex)
                }
              />
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};

export const updateScheduleTemplateBasedOnStartDate = (
  startDate,
  scheduleTemplate
) => {
  if (!scheduleTemplate[0]) return;

  let _scheduleTemplate = _.cloneDeep(scheduleTemplate);

  if (!startDate && _scheduleTemplate[0].startDate) {
    // remove start date
    _scheduleTemplate = _scheduleTemplate.map((val) => ({
      ...val,
      startDate: null,
      endDate: null,
    }));
  } else if (startDate && !_scheduleTemplate[0].startDate) {
    // set initial start date
    _scheduleTemplate = _scheduleTemplate.map((val, dayIndex) => {
      // make sure date is set for all days, not only first
      const dayStartDate = dateFns.add(startDate, { days: dayIndex });
      return {
        ...val,
        startDate: dayStartDate,
        endDate: dateFns.add(dayStartDate, {
          minutes: getTotalScheduleDuration(val.schedule),
        }),
      };
    });
  } else if (startDate && _scheduleTemplate[0].startDate) {
    // start date changed
    if (dateFns.isEqual(startDate, _scheduleTemplate[0]?.startDate)) return;

    const dateDiffInMinutes = dateFns.differenceInMinutes(
      startDate,
      _scheduleTemplate[0].startDate
    );

    _scheduleTemplate = _scheduleTemplate.map((val) => ({
      ...val,
      startDate: dateFns.add(val.startDate, { minutes: dateDiffInMinutes }),
      endDate: dateFns.add(val.startDate, {
        minutes: getTotalScheduleDuration(val.schedule) + dateDiffInMinutes,
      }),
    }));
  } else if (!startDate && !_scheduleTemplate[0].startDate) {
    return;
  }

  return _scheduleTemplate;
};

// round the number to prevent floating point values (0.11 hours === 6.6 minutes)
const parseDuration = (durationInHours) => Math.round(durationInHours * 60);
