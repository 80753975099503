import { Separator } from '@radix-ui/react-dropdown-menu';
import React, { Component, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  EmailButtonsBlock,
  NotificationMessageBlock,
} from '@nl-lms/feature/notifications/sdk';
import {
  Box,
  Button,
  FormField,
  Icon,
  IconButton,
  Input,
  Modal,
  TextEditor,
  Typography,
} from '@nl-lms/ui/components';
import { _ } from '@nl-lms/vendor';

import { NotificationMessageContext } from '../NotificationMessageContext';

type NotificationEmailMessageBlockToolbarProps = {
  block: NotificationMessageBlock;
  index: number;
};

export class NotificationEmailMessageBlockToolbar extends Component<
  NotificationEmailMessageBlockToolbarProps,
  any
> {
  static contextType = NotificationMessageContext;
  declare context: React.ContextType<typeof NotificationMessageContext>;
  rootElement: HTMLElement | null;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.rootElement = document.getElementById(
      'notification-email-message-input__active-section-toolbar'
    );
  }

  get text() {
    return (
      <>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.FontSizeMenu />
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.TextColorMenu />
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton title="Toggle Bold" commandName="toggleBold">
            <Icon.BoldIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Toggle Italic"
            commandName="toggleItalic"
          >
            <Icon.ItalicIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Toggle Underline"
            commandName="toggleUnderline"
          >
            <Icon.UnderlineIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Toggle Quote"
            commandName="toggleBlockquote"
          >
            <Icon.QuoteIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Increase Indent"
            commandName="increaseIndent"
          >
            <Icon.IndentRightIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Decrease Indent"
            commandName="decreaseIndent"
          >
            <Icon.IndentLeftIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton title="Align Left" commandName="leftAlign">
            <Icon.AlignLeftIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Align Center"
            commandName="centerAlign"
          >
            <Icon.AlignCenterIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton title="Align Right" commandName="rightAlign">
            <Icon.AlignRightIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Toggle Bullet List"
            commandName="toggleBulletList"
          >
            <Icon.ListIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.ToggleButton
            title="Toggle Ordered List"
            commandName="toggleOrderedList"
          >
            <Icon.OrderedListIcon />
          </TextEditor.ToggleButton>
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.EditLinkButton />
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.InsertFilesButton />
        </div>
        <div className="notification-email-message-input__toolbar-item">
          <TextEditor.InsertImagesButton />
        </div>
      </>
    );
  }

  get buttons() {
    return (
      <>
        <ButtonUrlModal />
      </>
    );
  }

  get separator() {
    return null;
  }

  get header() {
    return null;
  }

  render() {
    const { block, index } = this.props;
    if (this.context.activeBlockIndex !== index) {
      return null;
    }

    const renderFunction = this[_.camelCase(block.type)];
    if (!renderFunction || !this.rootElement) return null;

    return createPortal(this[_.camelCase(block.type)], this.rootElement);
  }
}

const ButtonUrlModal = () => {
  const { onEditBlock, activeBlockIndex, mentionOptions, message } = useContext(
    NotificationMessageContext
  );
  const [buttons, setButtons] = useState<EmailButtonsBlock['value']>([]);

  const onChangeInput = useCallback(
    (e) => {
      if (activeBlockIndex === null) return;
      const newButtons = [...buttons];
      _.set(newButtons, e.target.name, e.target.value);
      setButtons(newButtons);
      onEditBlock(activeBlockIndex, 'value', newButtons);
    },
    [buttons]
  );

  const resetFormState = useCallback(() => {
    setButtons([]);
  }, []);

  const initFormState = useCallback(() => {
    if (activeBlockIndex === null) return;
    const buttonsBlock = message[activeBlockIndex] as EmailButtonsBlock;
    if (buttonsBlock.type !== 'buttons') return;
    setButtons(buttonsBlock.value);
  }, [activeBlockIndex, message]);

  const onSubmit = useCallback(() => {
    if (activeBlockIndex === null) return;
    onEditBlock(activeBlockIndex, 'value', buttons);
  }, [buttons, activeBlockIndex]);

  return (
    <Modal.Provider onClose={resetFormState}>
      <Modal.Trigger>
        <div
          onClick={initFormState}
          className="notification-email-message-input__toolbar-item"
        >
          <Icon.LinkIcon />
        </div>
      </Modal.Trigger>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Buttons</Modal.Title>
        </Modal.Header>
        <Box margin={{ bottom: 's' }}>
          <Typography.p type="muted">
            {`Type in the links that will be used for each button. Use '{{' to access dynamic values based on the available data.`}
          </Typography.p>
        </Box>
        {buttons.map((button, index) => {
          return (
            <>
              <FormField key={`button-${index}`} noPadding label={button.label}>
                <TextEditor.Provider
                  mentionOptions={mentionOptions}
                  onChange={(htmlValue, markdownValue) =>
                    onChangeInput({
                      target: {
                        value: markdownValue,
                        name: `[${index}].href`,
                      },
                    })
                  }
                  value={button.href}
                >
                  <TextEditor.Input className="notification-subject-input" />
                  <TextEditor.MentionsMenu />
                </TextEditor.Provider>
              </FormField>
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Separator />
              ) : null}
            </>
          );
        })}
        <Modal.Actions>
          <Modal.Close>
            <Button label="Submit" onClick={onSubmit} />
          </Modal.Close>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Provider>
  );
};
