import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { CertificationRouterContract } from './certification.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'certificationStore';

  const tsRest = buildTsRestInstance<typeof CertificationRouterContract>(
    apiInstance,
    CertificationRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listCertifications: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof CertificationRouterContract.listCertifications.query>
        >
      >,
      z.infer<
        (typeof CertificationRouterContract.listCertifications.responses)['200']
      >
    >;
    removeCertification: RtkMutation<
      RtkFullParams<
        z.infer<typeof CertificationRouterContract.removeCertification.body>
      >,
      z.infer<
        (typeof CertificationRouterContract.removeCertification.responses)['200']
      >
    >;
    createCertification: RtkMutation<
      RtkFullParams<
        z.infer<typeof CertificationRouterContract.createCertification.body>
      >,
      z.infer<
        (typeof CertificationRouterContract.createCertification.responses)['200']
      >
    >;
    updateCertification: RtkMutation<
      RtkFullParams<
        {},
        z.infer<typeof CertificationRouterContract.updateCertification.body>,
        z.infer<
          typeof CertificationRouterContract.updateCertification.pathParams
        >
      >,
      z.infer<
        (typeof CertificationRouterContract.updateCertification.responses)['200']
      >
    >;
    getCertification: RtkQuery<
      RtkFullParams<
        {},
        {},
        z.infer<typeof CertificationRouterContract.getCertification.pathParams>
      >,
      z.infer<
        (typeof CertificationRouterContract.getCertification.responses)['200']
      >
    >;
    downloadCertificate: RtkQuery<
      RtkFullParams<
        {},
        {},
        z.infer<
          typeof CertificationRouterContract.downloadCertificate.pathParams
        >
      >,
      z.infer<
        (typeof CertificationRouterContract.downloadCertificate.responses)['200']
      >
    >;
  }>(tsRest, CertificationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
