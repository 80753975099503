import {
  ApiQueryFilter,
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '../constants';

export type FilterModel = {
  value: string | number | string[] | number[] | boolean | undefined;
  field: string;
  operator: QueryOperator;
  valueLabel?: string;
  operatorType?: QueryFilterOperatorType;
  type: 'filter';
};

export const isFilterModel = (item: object): item is FilterModel => {
  return 'value' in item && 'field' in item && 'operator' in item;
};

export type FilterCombinator = {
  value: QueryFilterCombinator;
  type: 'combinator';
};

export type FilterBracket = {
  value: '(' | ')';
  type: 'bracket';
};

const UnaryOperators = [
  QueryOperator.IsTrue,
  QueryOperator.IsFalse,
  QueryOperator.IsNull,
  QueryOperator.IsNotNull,
];

type QueryFilterParams =
  | {
      filters?: AppQueryFilter;
      combinator?: QueryFilterCombinator;
    }
  | { apiQueryFilter?: ApiQueryFilter; combinator?: QueryFilterCombinator };
type Combinator = 'and' | 'or';
type ArrayOfFiltersFilter = QueryFilter | Omit<FilterModel, 'type'>;
type ArrayOfFilters = (ArrayOfFiltersFilter | Combinator | ArrayOfFilters)[];

type FilterComponent = FilterModel | FilterCombinator | FilterBracket;

export class QueryFilter {
  filters: FilterComponent[];
  defaultCombinator: QueryFilterCombinator;
  constructor(params: QueryFilterParams = {}) {
    const { combinator = QueryFilterCombinator.And } = params;
    if ('filters' in params && params.filters) {
      this.filters = this.fromAppQueryFilter(params.filters);
    } else if ('apiQueryFilter' in params && params.apiQueryFilter) {
      this.filters = this.fromApiQueryFilter(params.apiQueryFilter);
    } else {
      this.filters = [];
    }
    this.defaultCombinator = combinator;
  }

  get appQueryFilter(): AppQueryFilter {
    return this.toAppQueryFilter(this.filters);
  }

  get apiQueryFilter(): ApiQueryFilter {
    return this.toApiQueryFilter(this.filters);
  }

  set combinator(combinator: QueryFilterCombinator) {
    this.defaultCombinator = combinator;
  }

  get pretty() {
    let value = '';

    this.filters.forEach((filter) => {
      if (filter.type === 'bracket') {
        value = `${value} ${filter.value}`;
      } else if (filter.type === 'combinator') {
        value = `${value} ${filter.value}`;
      } else {
        value = `${value} <${filter.field} ${filter.operator} ${filter.value}>`;
      }
    });
    return value;
  }

  private addQueryFilter = (
    queryFilter: QueryFilter,
    options: {
      position?: number;
      combinator?: QueryFilterCombinator;
      group?: boolean;
    }
  ) => {
    const {
      combinator = this.defaultCombinator,
      position = this.filters.length,
      group = false,
    } = options;
    if (
      !queryFilter.filters.length ||
      (queryFilter.filters.length <= 2 &&
        queryFilter.filters[0].type === 'bracket')
    ) {
      return;
    }
    if (position < 0 || position > this.filters.length) {
      throw new Error('Invalid filter position');
    }
    if (group) {
      queryFilter.group(0, queryFilter.filters.length - 1);
    }
    const filtersLength = queryFilter.filters.length;

    this.filters.splice(position, 0, ...queryFilter.filters);
    let initialPosition = position;
    let endPosition = position + filtersLength;

    if (this.filters.length <= 3) {
      return;
    }
    if (this.filters[position].type !== 'bracket') {
      this.filters.splice(position, 0, {
        type: 'bracket',
        value: '(',
      });
      this.filters.splice(position + filtersLength + 1, 0, {
        type: 'bracket',
        value: ')',
      });
      initialPosition -= 1;
      endPosition += 2;
    }

    // A and B and C -> A and B D and C -> A and B and D and C
    // (A and B) -> (A and B) C -> (A and B) and C
    if (
      this.filters[initialPosition]?.type === 'filter' ||
      this.filters[initialPosition]?.type === 'bracket'
    ) {
      this.filters.splice(position, 0, {
        type: 'combinator',
        value: combinator,
      });
    } else if (
      this.filters[endPosition]?.type === 'filter' ||
      (this.filters[endPosition]?.type === 'bracket' &&
        this.filters[endPosition]?.value === '(')
    ) {
      this.filters.splice(position + 1, 0, {
        type: 'combinator',
        value: combinator,
      });
    }
  };

  private addFilterModel = (
    filter: Omit<FilterModel, 'type'>,
    options?: { position?: number; combinator?: QueryFilterCombinator }
  ) => {
    const position = options?.position || this.filters.length;
    const combinator = options?.combinator || this.defaultCombinator;

    if (!filter.field || !filter.operator) {
      throw new Error('Invalid filter value');
    }
    if (position < 0 || position > this.filters.length) {
      throw new Error('Invalid filter position');
    }
    if (
      position !== this.filters.length &&
      this.filters[position].type !== 'filter'
    ) {
      throw new Error('You can only add filters over other filters');
    }

    this.filters.splice(position, 0, {
      ...filter,
      operatorType: filter.operatorType || QueryFilterOperatorType.Binary,
      type: 'filter',
    });

    if (this.filters[position - 1]?.type === 'filter') {
      this.filters.splice(position, 0, {
        type: 'combinator',
        value: combinator,
      });
    } else if (this.filters[position + 1]?.type === 'filter') {
      this.filters.splice(position + 1, 0, {
        type: 'combinator',
        value: combinator,
      });
    }
  };

  private addArray = (
    filter: ArrayOfFilters,
    options?: { position?: number; combinator?: QueryFilterCombinator }
  ) => {
    const position = options?.position || this.filters.length;
    const combinator = options?.combinator || this.defaultCombinator;
    if (!filter.length) {
      throw new Error('Invalid filter value');
    }
    // TODO: Check if it is a valid array

    const tempFilters = new QueryFilter();
    if (typeof this.filters[0] === 'string') {
      this.filters.splice(position, 0, {
        type: 'combinator',
        value: combinator,
      });
    }
    let activeCombinator = combinator;
    filter.forEach((component) => {
      if (Array.isArray(component)) {
        tempFilters.addArray(component, { combinator });
      } else if (component === 'or' || component === 'and') {
        activeCombinator =
          component === 'or'
            ? QueryFilterCombinator.Or
            : QueryFilterCombinator.And;
      } else {
        // @ts-ignore
        tempFilters.add(component, {
          combinator: activeCombinator,
        });
      }
    });
    this.add(tempFilters);
  };

  add(
    filter: Omit<FilterModel, 'type'>,
    options?: {
      group?: boolean;
      position?: number;
      combinator?: QueryFilterCombinator;
    }
  ): void;
  add(
    filter: QueryFilter,
    options?: {
      group?: boolean;
      position?: number;
      combinator?: QueryFilterCombinator;
    }
  ): void;
  add(
    filter: ArrayOfFilters,
    options?: {
      group?: boolean;
      position?: number;
      combinator?: QueryFilterCombinator;
    }
  ): void;
  add(
    filter: ApiQueryFilter,
    options?: {
      group?: boolean;
      position?: number;
      combinator?: QueryFilterCombinator;
    }
  ): void;
  add(
    filter:
      | QueryFilter
      | Omit<FilterModel, 'type'>
      | ApiQueryFilter
      | ArrayOfFilters,
    options: { position?: number; combinator?: QueryFilterCombinator } = {}
  ) {
    if (Array.isArray(filter)) {
      this.addArray(filter, options);
    } else if (filter instanceof QueryFilter) {
      this.addQueryFilter(filter, options);
    } else if (isFilterModel(filter)) {
      this.addFilterModel(filter, options);
    } else {
      // @ts-expect-error
      const tempQueryFilter = new QueryFilter({ apiQueryFilter: filter });
      this.addQueryFilter(tempQueryFilter, options);
    }
  }

  or(filter: Omit<FilterModel, 'type'>): void;
  or(filter: QueryFilter): void;
  or(filter: QueryFilter | Omit<FilterModel, 'type'>) {
    const options = {
      position: this.filters.length,
      combinator: QueryFilterCombinator.Or,
    };
    // @ts-ignore
    this.add(filter, options);
  }

  and(filter: Omit<FilterModel, 'type'>): void;
  and(filter: QueryFilter): void;
  and(filter: QueryFilter | Omit<FilterModel, 'type'>) {
    const options = {
      position: this.filters.length,
      combinator: QueryFilterCombinator.And,
    };
    // @ts-ignore
    this.add(filter, options);
  }

  remove = (filterIndex: number) => {
    const filter = this.filters[filterIndex];
    if (!filter || filter.type !== 'filter') {
      throw new Error(`Invalid filter index - ${filterIndex}`);
    }
    const componentIndexesToRemove = [filterIndex];
    const beforeComponent = this.filters[filterIndex - 1];
    const afterComponent = this.filters[filterIndex + 1];
    if (beforeComponent?.type === 'combinator') {
      componentIndexesToRemove.push(filterIndex - 1);
    } else if (afterComponent?.type === 'combinator') {
      componentIndexesToRemove.push(filterIndex + 1);
    }

    if (
      beforeComponent?.type === 'bracket' &&
      beforeComponent?.value === '(' &&
      this.filters[filterIndex + 3]?.type === 'bracket' &&
      this.filters[filterIndex + 3]?.value === ')'
    ) {
      componentIndexesToRemove.push(filterIndex - 1);
      componentIndexesToRemove.push(filterIndex + 3);
    } else if (
      afterComponent?.type === 'bracket' &&
      afterComponent?.value === ')' &&
      this.filters[filterIndex - 3]?.type === 'bracket' &&
      this.filters[filterIndex - 3]?.value === '('
    ) {
      componentIndexesToRemove.push(filterIndex + 1);
      componentIndexesToRemove.push(filterIndex - 3);
    }

    this.filters = this.filters.filter(
      (component, index) => !componentIndexesToRemove.includes(index)
    );
  };

  update = (filterIndex: number, newFilter: Omit<FilterModel, 'type'>) => {
    if (!newFilter.field || !newFilter.operator || !newFilter.value) {
      throw new Error('Invalid filter value');
    }
    if (
      !this.filters[filterIndex] ||
      this.filters[filterIndex].type !== 'filter'
    ) {
      throw new Error(`Invalid filter index - ${filterIndex}`);
    }

    this.filters[filterIndex] = { ...newFilter, type: 'filter' };
  };

  group = (fromIndex: number, toIndex: number) => {
    const fromComponent = this.filters[fromIndex];
    const toComponent = this.filters[toIndex];
    if (fromIndex >= toIndex) {
      throw new Error('Invalid index values');
    }
    if (!toComponent || !fromComponent) {
      throw new Error('Invalid index values');
    }
    if (toComponent.type !== 'filter' || fromComponent.type !== 'filter') {
      throw new Error('Invalid component');
    }
    this.filters.splice(fromIndex, 0, {
      type: 'bracket',
      value: '(',
    });
    this.filters.splice(toIndex + 2, 0, {
      type: 'bracket',
      value: ')',
    });
  };

  ungroup = (bracketIndex: number) => {
    const bracketComponent = this.filters[bracketIndex];
    if (!bracketComponent || bracketComponent.type !== 'bracket') {
      throw new Error('Invalid component');
    }
    const indexesToRemove = [bracketIndex];
    if (bracketComponent.value === '(') {
      indexesToRemove.push(this.getMatchingClosingBracketIndex(bracketIndex));
    } else {
      indexesToRemove.push(this.getMatchingOpenBracketIndex(bracketIndex));
    }
    this.filters = this.filters.filter(
      (f, index) => !indexesToRemove.includes(index)
    );
  };

  move = (fromIndex: number, toIndex: number) => {};

  private getMatchingOpenBracketIndex(bracketIndex: number) {
    if (this.filters[bracketIndex]?.type !== 'bracket') {
      throw new Error('Invalid bracket index');
    }
    let openBracketIndex: number | null = null;
    let bracketCounter = 1;
    let activeIndex = bracketIndex - 1;
    while (typeof openBracketIndex !== 'number' && activeIndex > 0) {
      const component = this.filters[activeIndex];
      if (component.type === 'bracket') {
        if (component.value === ')') {
          bracketCounter += 1;
        } else {
          bracketCounter -= 1;
        }

        if (bracketCounter === 0) {
          openBracketIndex = activeIndex;
        }

        activeIndex -= 1;
      }
    }

    if (!openBracketIndex) throw new Error('Unable to find open bracket index');
    return openBracketIndex;
  }

  private getMatchingClosingBracketIndex = (bracketIndex: number) => {
    if (this.filters[bracketIndex]?.type !== 'bracket') {
      throw new Error('Invalid bracket index');
    }

    let closingBracketIndex;
    let bracketCounter = 1;
    this.filters.some((component, index) => {
      if (index <= bracketIndex) return false;
      if (component.type !== 'bracket') return false;

      if (component.value === '(') {
        bracketCounter += 1;
      } else {
        bracketCounter -= 1;
      }
      if (bracketCounter === 0) {
        closingBracketIndex = index;
        return true;
      }
      return false;
    });

    if (!closingBracketIndex)
      throw new Error('Unable to find closed bracket index');
    return closingBracketIndex;
  };

  private toAppQueryFilter = (
    filters: FilterComponent[],
    options: {
      combinator?: QueryFilterCombinator;
      startIndex?: number;
      endIndex?: number;
    } = {}
  ): AppQueryFilter => {
    const appQueryFiltersArray: AppQueryFilter[] = [];
    const {
      combinator = undefined,
      startIndex = 0,
      endIndex = filters.length,
    } = options;
    let activeCombinator: QueryFilterCombinator | undefined = undefined;
    let index = startIndex;
    while (index < endIndex) {
      const component = filters[index];
      const activeIndex = index;
      index += 1;
      if (
        component.type === 'bracket' &&
        activeIndex !== 0 &&
        component.value === '('
      ) {
        const endIndex = this.getMatchingClosingBracketIndex(activeIndex);
        appQueryFiltersArray.push(
          this.toAppQueryFilter(this.filters, {
            combinator: activeCombinator,
            startIndex: index,
            endIndex: endIndex,
          })
        );
        index = endIndex + 1;
      } else if (component.type === 'combinator') {
        activeCombinator = component.value;
      } else if (component.type === 'filter') {
        let operatorType = component.operatorType;
        if (!operatorType) {
          operatorType = UnaryOperators.includes(component.operator)
            ? QueryFilterOperatorType.Unary
            : QueryFilterOperatorType.Binary;
        }
        appQueryFiltersArray.push({
          id: `${activeIndex}`,
          combinator: activeCombinator,
          // @ts-ignore
          value: {
            field: { field: component.field, label: component.field },
            operator: {
              value: component.operator,
              type: operatorType,
            },
            value: { label: component.valueLabel, value: component.value },
          },
        });
      }
    }
    return {
      id: 'random-id',
      combinator,
      value: appQueryFiltersArray,
    };
  };

  private toApiQueryFilter = (
    filters: FilterComponent[],
    options: {
      combinator?: QueryFilterCombinator;
      startIndex?: number;
      endIndex?: number;
    } = {}
  ): ApiQueryFilter => {
    const apiQueryFiltersArray: ApiQueryFilter[] = [];
    const {
      combinator = undefined,
      startIndex = 0,
      endIndex = filters.length,
    } = options;
    let activeCombinator: QueryFilterCombinator | undefined = undefined;
    let index = startIndex;
    while (index < endIndex) {
      const component = filters[index];
      if (component.type === 'bracket' && index > 0) {
        const endIndex = this.getMatchingClosingBracketIndex(index);
        apiQueryFiltersArray.push(
          this.toApiQueryFilter(this.filters, {
            combinator: activeCombinator,
            startIndex: index + 1,
            endIndex: endIndex,
          })
        );
        index = endIndex + 1;
      } else if (component.type === 'combinator') {
        activeCombinator = component.value;
        index += 1;
      } else if (component.type === 'filter') {
        apiQueryFiltersArray.push({
          combinator: activeCombinator,
          value: {
            field: component.field,
            operator: component.operator,
            value: component.value,
          },
        });
        index += 1;
      }
    }
    return {
      combinator,
      value: apiQueryFiltersArray,
    };
  };

  private fromAppQueryFilter = (
    appQueryFilter: AppQueryFilter,
    index = 0,
    depth = 0
  ): FilterComponent[] => {
    const filters: FilterComponent[] = [];
    if (index && appQueryFilter.combinator) {
      filters.push({
        type: 'combinator',
        value: appQueryFilter.combinator,
      });
    }
    if (Array.isArray(appQueryFilter.value)) {
      if (depth > 0 && appQueryFilter.value.length > 1) {
        filters.push({
          type: 'bracket',
          value: '(',
        });
      }
      const nestedFilters = appQueryFilter.value
        .map((v, index) => this.fromAppQueryFilter(v, index, depth + 1))
        .flat();
      filters.push(...nestedFilters);
      if (depth > 0 && appQueryFilter.value.length > 1) {
        filters.push({
          type: 'bracket',
          value: ')',
        });
      }
    } else {
      filters.push({
        type: 'filter',
        // @ts-ignore
        value: appQueryFilter.value.value.value,
        // @ts-ignore
        valueLabel: appQueryFilter.value.value.label,
        // @ts-ignore
        field: appQueryFilter.value.field.field,
        // @ts-ignore
        operator: appQueryFilter.value.operator.value,
        // @ts-ignore
        operatorType: appQueryFilter.value.operator.type,
      });
    }
    return filters;
  };

  private fromApiQueryFilter = (
    apiQueryFilter: ApiQueryFilter,
    index = 0,
    depth = 0
  ): FilterComponent[] => {
    const filters: FilterComponent[] = [];
    if (index && apiQueryFilter.combinator) {
      filters.push({
        type: 'combinator',
        value: apiQueryFilter.combinator,
      });
    }
    if (Array.isArray(apiQueryFilter.value)) {
      if (depth > 0 && apiQueryFilter.value.length > 1) {
        filters.push({
          type: 'bracket',
          value: '(',
        });
      }
      const nestedFilters = apiQueryFilter.value
        .map((v, index) => this.fromApiQueryFilter(v, index, depth + 1))
        .flat();
      filters.push(...nestedFilters);
      if (depth > 0 && apiQueryFilter.value.length > 1) {
        filters.push({
          type: 'bracket',
          value: ')',
        });
      }
    } else {
      filters.push({
        type: 'filter',
        // @ts-ignore
        value: apiQueryFilter.value.value,
        // @ts-ignore
        field: apiQueryFilter.value.field,
        // @ts-ignore
        operator: apiQueryFilter.value.operator as QueryOperator,
      });
    }
    return filters;
  };
}
