import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon, FormField, SingleSelect, Button } from '@nl-lms/ui/components';
import { _ } from '@nl-lms/vendor';
import { adminApi } from '../../../../_common/services/api';

import './AdminAssessmentSelectQuestionsInput.scss';

type Props = {
  onChange: (questionIds: string[]) => void;
  name: string;
  excludedQuestionIds?: string[];
};
const { useListAssessmentQuestionsForSelectQuery } = adminApi;

export const AdminAssessmentSelectQuestionsInput = (props: Props) => {
  const { onChange, name, excludedQuestionIds } = props;

  // @ts-ignore
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>(null);

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);

  const [clearInput, setClearInput] = useState<boolean>(false);

  const { data: result, isLoading } =
    useListAssessmentQuestionsForSelectQuery('');
  const data = result || [];

  const dataById = useMemo(() => _.keyBy(data, 'id'), [data]);

  const onSelectQuestion = useCallback(
    (question) => {
      setSelectedQuestionId(question);
    },
    [setSelectedQuestionId]
  );

  const onAddQuestion = useCallback(() => {
    if (selectedQuestionIds.includes(selectedQuestionId)) return;
    if (!selectedQuestionId) return;

    const newlySelectedQuestionIds = [
      ...selectedQuestionIds,
      selectedQuestionId,
    ];

    setSelectedQuestionIds(newlySelectedQuestionIds);
    // @ts-ignore
    setSelectedQuestionId(null);

    onChange(newlySelectedQuestionIds);
  }, [
    setSelectedQuestionIds,
    selectedQuestionIds,
    selectedQuestionId,
    onChange,
  ]);

  const onRemoveQuestion = useCallback(
    (questionId) => {
      const newlySelectedQuestionIds = selectedQuestionIds.filter(
        (id) => id !== questionId
      );
      setSelectedQuestionIds(newlySelectedQuestionIds);
      onChange(newlySelectedQuestionIds);
    },
    [selectedQuestionIds, setSelectedQuestionIds, onChange]
  );

  const filteredQuestionOptions = useMemo(() => {
    return (
      data
        // @ts-ignore
        .filter((q) => !selectedQuestionIds.includes(q.id))
        // @ts-ignore
        .filter((q) => !excludedQuestionIds.includes(q.id))
        .map((q) => ({
          value: q.id,
          label: q.title,
          question: q,
        }))
    );
  }, [data, selectedQuestionIds, excludedQuestionIds]);

  const selectedQuestions = selectedQuestionIds
    .map((id) => {
      if (dataById[id]) return dataById[id];
      return;
    })
    .filter((q) => q);

  useEffect(() => {
    setClearInput(!selectedQuestionId);
  }, [selectedQuestionId]);

  return (
    <div className="select-questions-input">
      <FormField>
        <div className="select-questions-input__select-container">
          <SingleSelect
            name={name}
            placeholder="Search by question name"
            onChange={onSelectQuestion}
            isLoading={isLoading}
            // @ts-ignore
            options={filteredQuestionOptions}
            shouldClearSelection={clearInput}
          />
          <Button
            label="Add"
            className="select-questions-input__question-action"
            onClick={onAddQuestion}
            disabled={clearInput}
          />
        </div>
      </FormField>
      <div className="select-questions-input__selected-questions">
        {selectedQuestions.map((question, index) => (
          <div
            className="select-questions-input__question"
            // @ts-ignore
            key={`${question.id}-${index}`}
          >
            <div className="select-questions-input__question-title">
              {/*// @ts-ignore */}
              <span>{question.title}</span>
            </div>
            <span
              className="select-questions-input__question-action"
              // @ts-ignore
              onClick={() => onRemoveQuestion(question.id)}
            >
              <Icon.DeleteIcon />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
