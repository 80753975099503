import React from 'react';

export const RoIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#00319C"
        fillRule="evenodd"
        d="M24 24h-8.002V0H24v24z"
        clipRule="evenodd"
      />
      <path
        fill="#FFDE00"
        fillRule="evenodd"
        d="M15.998 24H8.002V0h7.996v24z"
        clipRule="evenodd"
      />
      <path
        fill="#DE2110"
        fillRule="evenodd"
        d="M8.002 24H0V0h8.002v24z"
        clipRule="evenodd"
      />
    </svg>
);
