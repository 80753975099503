import * as z from 'zod';

import { TimeDeltaSchema } from '@nl-lms/common/shared';

import { Certification } from './certification.types.sdk';

export const CertificationSchema: z.ZodType<Certification> = z.object({
  id: z.string(),

  name: z.string(),
  template: z.object({ html: z.string() }),
  validityPeriod: TimeDeltaSchema.nullable(),

  createdAt: z.date(),
  updatedAt: z.date(),
});

export const CertificationInstanceSchema: z.ZodType<Certification> = z.object({
  id: z.string(),

  name: z.string(),
  template: z.object({ html: z.string() }),
  validityPeriod: TimeDeltaSchema.nullable(),

  createdAt: z.date(),
  updatedAt: z.date(),
});

export const CreateCertificationSchema: z.ZodType<
  Omit<Certification, 'id' | 'createdAt' | 'updatedAt'>
> = z.object({
  name: z.string(),
  template: z.object({ html: z.string() }),
  validityPeriod: TimeDeltaSchema.nullable(),
});

export const UpdateCertificationSchema: z.ZodType<
  Partial<Omit<Certification, 'id' | 'createdAt' | 'updatedAt'>>
> = z.object({
  name: z.string(),
  template: z.object({ html: z.string() }),
  validityPeriod: TimeDeltaSchema.nullable(),
});

export const CertificationIdParamSchema = z.object({
  id: z.string(),
});

export const CertificationInstanceIdParamSchema = z.object({
  certificationInstanceId: z.string(),
});
