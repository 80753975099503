import { FieldMetadata, FieldType } from '@nl-lms/common/shared';
import { Sensitive } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import { authStore } from '../../../_common/modules/Auth/auth';

export const getLearnerPropertiesFilterFields = (
  usePrefix = false
): FieldMetadata[] => {
  const isEvaluator = authStore?.isOnlyEvaluator;
  const isManager = authStore?.isOnlyManager;

  if (!C || !C.LEARNER_PROPERTIES) return [];
  if (isEvaluator || isManager) return [];

  return C.LEARNER_PROPERTIES.map((prop) => {
    if (prop.name === 'isManager') return null;
    return {
      // todo add snakecase transformation here
      name: usePrefix ? `${prop.name}` : prop.name,
      label: `Learner ${prop.fieldName}`,
      type: FieldType.string, // add more types once we have more than text/string
      ValueComponent: ({ children }) => {
        return <Sensitive>{children}</Sensitive>;
      },
    };
  }).filter((field) => !!field);
};
