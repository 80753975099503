import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  LearningAssignmentNotificationSchema,
  LearningAssignmentSchema,
  ListLearningAssignmentRuleSchema,
  ListLearningAssignmentSchema,
} from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
  DeleteResourcesResponseSchema,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { LearningAssignmentRouterPrefix } from './learning-assignment.constants.sdk';
import {
  AddLearningAssignmentRulePayloadSchema,
  AddLearningAssignmentRulesPayloadSchema,
  CreateLearningAssignmentPayloadSchema,
  GetLearningAssignmentSchema,
  LearningAssignmentAudienceSchema,
  ListLearningAssignmentInstanceSchema,
  ListLearningAssignmentPlannedInstanceSchema,
  RescheduleLearningAssignmentPlannedInstancePayloadSchema,
  ScheduleLearningAssignmentInstancesPayloadSchema,
  UpdateLearningAssignmentInstancePayloadSchema,
  UpdateLearningAssignmentPayloadSchema,
  UpdateLearningAssignmentRulePayloadSchema,
  UpdateLearningAssignmentsPayloadSchema,
} from './learning-assignment.schema';

const contract = initContract();

export const LearningAssignmentRouterContract = contract.router({
  listLearningAssignments: {
    method: 'GET',
    path: `${LearningAssignmentRouterPrefix}`,
    responses: ContractPaginatedResponse(ListLearningAssignmentSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createLearningAssignment: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}`,
    responses: ContractStandardResponse(LearningAssignmentSchema),
    body: CreateLearningAssignmentPayloadSchema,
  },
  removeLearningAssignments: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema.extend({
      removeContent: z.boolean(),
    }),
  },
  updateLearningAssignment: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/:id`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateLearningAssignmentPayloadSchema,
    pathParams: z.object({ id: z.string() }),
  },
  updateLearningAssignments: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateLearningAssignmentsPayloadSchema,
  },
  updateLearningAssignmentNotifications: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/:id/notifications`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      notifications: z.array(LearningAssignmentNotificationSchema),
    }),
    pathParams: z.object({ id: z.string() }),
  },
  addLearningAssignmentAudience: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/:id/audience`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: LearningAssignmentAudienceSchema,
    pathParams: z.object({ id: z.string() }),
  },
  removeLearningAssignmentAudience: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}/:id/audience`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: LearningAssignmentAudienceSchema,
    pathParams: z.object({ id: z.string() }),
  },
  addLearningAssignmentRule: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/:id/rule`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: AddLearningAssignmentRulePayloadSchema,
    pathParams: z.object({ id: z.string() }),
  },
  removeLearningAssignmentRule: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}/:id/rule/:ruleId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({}),
    pathParams: z.object({ id: z.string(), ruleId: z.string() }),
  },
  removeLearningAssignmentRules: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}/rule`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  enableLearningAssignmentRule: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/:id/rule/:ruleId/enable`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    pathParams: z.object({ id: z.string(), ruleId: z.string() }),
    body: z.object({}),
  },
  disableLearningAssignmentRule: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/:id/rule/:ruleId/disable`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({}),
    pathParams: z.object({ id: z.string(), ruleId: z.string() }),
  },
  applyLearningAssignmentRule: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/:id/rule/:ruleId/apply`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: LearningAssignmentAudienceSchema,
    pathParams: z.object({ id: z.string(), ruleId: z.string() }),
  },
  updateLearningAssignmentRule: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/:id/rule/:ruleId`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateLearningAssignmentRulePayloadSchema,
    pathParams: z.object({ id: z.string(), ruleId: z.string() }),
  },
  listLearningAssignmentRules: {
    method: 'GET',
    path: `${LearningAssignmentRouterPrefix}/rule`,
    responses: ContractPaginatedResponse(ListLearningAssignmentRuleSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  addLearningAssignmentRules: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/rule/add`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({ payload: AddLearningAssignmentRulesPayloadSchema }),
  },
  listLearningAssignmentInstances: {
    method: 'GET',
    path: `${LearningAssignmentRouterPrefix}/instance`,
    responses: ContractPaginatedResponse(ListLearningAssignmentInstanceSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  addLearningAssignmentInstances: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      learningAssignmentId: z.string(),
      audience: LearningAssignmentAudienceSchema,
    }),
  },
  updateLearningAssignmentInstances: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateLearningAssignmentInstancePayloadSchema,
  },
  removeLearningAssignmentInstances: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}/instance`,
    responses: ContractStandardResponse(DeleteResourcesResponseSchema),
    body: DeleteResourcesBodySchema.extend({
      removeContent: z.boolean(),
    }),
  },
  resetLearningAssignmentInstances: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/instance/reset`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  cancelLearningAssignmentInstances: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/instance/cancel`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  listLearningAssignmentPlannedInstances: {
    method: 'GET',
    path: `${LearningAssignmentRouterPrefix}/planned-instance`,
    responses: ContractPaginatedResponse(
      ListLearningAssignmentPlannedInstanceSchema
    ),
    query: z.object({ query: ApiQuerySchema }),
  },
  scheduleLearningAssignmentInstances: {
    method: 'POST',
    path: `${LearningAssignmentRouterPrefix}/planned-instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: ScheduleLearningAssignmentInstancesPayloadSchema,
  },
  removeLearningAssignmentPlannedInstances: {
    method: 'DELETE',
    path: `${LearningAssignmentRouterPrefix}/planned-instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  rescheduleLearningAssignmentPlannedInstances: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/planned-instance/reschedule`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: RescheduleLearningAssignmentPlannedInstancePayloadSchema.extend(
      DeleteResourcesBodySchema.shape
    ),
  },
  forceResolveLearningAssignmentPlannedInstances: {
    method: 'PATCH',
    path: `${LearningAssignmentRouterPrefix}/planned-instance/force-resolve`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  getLearningAssignment: {
    method: 'GET',
    path: `${LearningAssignmentRouterPrefix}/:id`,
    responses: ContractStandardResponse(GetLearningAssignmentSchema),
    pathParams: z.object({ id: z.string() }),
  },
  // updateLearningAssignmentPlannedInstances: {
  //   method: 'PATCH',
  //   path: `${LearningAssignmentRouterPrefix}/planned-instance`,
  //   responses: ContractStandardResponse(BooleanResponseSchema),
  //   body: z.object({}),
  // },
});
