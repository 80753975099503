import React, { useCallback, useMemo } from 'react';

import { AsyncSingleSelect, MultiSelect } from '@nl-lms/ui/components';
import { getSelectOptions } from '@nl-lms/ui/utils';

import { adminApi, useApi } from '../../../_common/services/api';

const buildLabel = (checklist) => {
  return checklist.name;
};

const parseOptions = (res) => getSelectOptions(res, buildLabel);

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

const { useListChecklistsForSelectQuery } = adminApi;

export const AdminChecklistSingleSelect = (props) => {
  const {
    onChange,
    name,
    disabled,
    initialSelectedItem,
    hasError,
    returnEntireItemOnChange,
    selectedItem,
  } = props;
  const api = useApi();
  const onLoadChecklists = useCallback(async (input) => {
    const res = await api.checklist.listForSelect(input);
    // @ts-ignore
    return res.map((checklist) => ({
      label: checklist.name,
      value: checklist.id,
      checklist: checklist,
      item: checklist,
    }));
  }, []);

  return (
    <AsyncSingleSelect
      name={name}
      placeholder="Select checklist"
      onChange={onChange}
      initialSelectedItem={initialSelectedItem}
      selectedItem={selectedItem}
      isClearable
      returnEntireItemOnChange={returnEntireItemOnChange}
      loadOptions={onLoadChecklists}
      disabled={disabled}
      hasError={hasError}
    />
  );
};

export const AdminChecklistMultiSelect = (props) => {
  const { onChange, name, initialSelectedItems, selectedItems } = props;
  const { data, isLoading } = useListChecklistsForSelectQuery('');
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data);
  }, [data]);

  return (
    <MultiSelect
      name={name}
      placeholder="Search checklists"
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={initialSelectedItems}
    />
  );
};

AdminChecklistMultiSelect.defaultProps = {
  initialSelectedItems: [],
  selectedItems: null,
};

AdminChecklistSingleSelect.defaultProps = {
  initialSelectedItem: null,
  selectedItem: null,
};
