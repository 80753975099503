import { AppQueryFilter, QueryFilterCombinator } from '../constants';

export const combineFilters = (
  filters: AppQueryFilter,
  baseFilters?: AppQueryFilter,
  combinator: QueryFilterCombinator = QueryFilterCombinator.And
): AppQueryFilter | null => {
  if (!baseFilters && !filters) return null;
  if (!baseFilters) return filters;
  if (!filters) return baseFilters;

  const parsedFiltersValue = Array.isArray(filters.value)
    ? filters.value
    : [filters];

  return {
    ...filters,
    value: [
      baseFilters,
      {
        id: '',
        value: parsedFiltersValue,
        combinator,
      },
    ],
  };
};
