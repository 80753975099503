import React, { useCallback, useMemo } from 'react';

import {
  Checkbox,
  Draggable,
  FloatingMenu,
  RadioButton,
  Skeleton,
  Tooltip,
} from '@nl-lms/ui/components';
import { useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import * as Icon from '../Icon';
import { useTableContext } from './TableContext';

const ColumnEditItem = ({ column, onChangeColumn }) => {
  const name = column.Header;
  const isVisible = column.isVisible !== undefined ? column.isVisible : true;
  const onChangeCheckbox = useCallback(
    (e) => {
      onChangeColumn({
        ...column,
        isVisible: e.target.checked,
      });
    },
    [column, onChangeColumn]
  );
  const onClickCheckbox = useCallback((e) => {
    e.stopPropagation();
    return false;
  }, []);
  return (
    <div className="table__column-edit-menu-item">
      <Checkbox
        onClick={onClickCheckbox}
        onChange={onChangeCheckbox}
        checked={isVisible}
        name="edit-column"
      />
      <span>{name}</span>
    </div>
  );
};

export const RowActionsHeaderCell = ({ ...props }) => {
  const { rowActionsCellWidth, isHeaderEditable, onChangeColumns, columns } =
    useTableContext();

  const commonProps = useTestProps(props);

  const columnEditItems = useMemo(() => {
    if (!isHeaderEditable) return [];
    return columns.map((c, index) => {
      const onChangeColumn = (column) => {
        const isVisible = c.isVisible !== undefined ? c.isVisible : true;
        const newColumns = _.clone(columns);
        newColumns[index] = column || {
          ...newColumns[index],
          isVisible: !isVisible,
        };
        // @ts-ignore
        onChangeColumns(newColumns);
        return false;
      };
      return {
        name: <ColumnEditItem column={c} onChangeColumn={onChangeColumn} />,
        handler: onChangeColumn,
      };
    });
  }, [isHeaderEditable, onChangeColumns, columns]);
  return (
    <div
      style={{
        minWidth: rowActionsCellWidth,
        flex: '0 0 auto',
        boxSizing: 'border-box',
      }}
      className="table__row-actions-cell"
      {...commonProps}
    >
      {isHeaderEditable ? (
        <FloatingMenu
          topOffset={25}
          menuClassName="table__column-edit-menu-options"
          className="table__column-edit-menu"
          items={columnEditItems}
        >
          <Icon.EyeIcon />
        </FloatingMenu>
      ) : (
        <div />
      )}
      <div />
    </div>
  );
};

export const RowActionsBodyCell = ({ row, ...props }) => {
  const {
    rowActionsCellWidth,
    customIconActions,
    floatingMenuActions,
    isDraggable,
  } = useTableContext();

  const commonProps = useTestProps(props);

  return (
    <div
      className="table__row-actions-cell"
      style={{
        minWidth: rowActionsCellWidth,
        flex: '0 0 auto',
        boxSizing: 'border-box',
      }}
      {...commonProps}
    >
      {isDraggable ? (
        <Draggable.DragButton className="table__row-action-icon table__row-action-icon--default" />
      ) : null}
      {customIconActions.length
        ? customIconActions.map((rowAction) => {
            if (rowAction.isVisible && !rowAction.isVisible(row.original))
              return null;
            return (
              <CustomActionElement
                key={`rowAction-${rowAction.name}`}
                type={rowAction?.type || 'default'}
                action={{
                  ...rowAction,
                  onClick: rowAction.handler
                    ? () => rowAction.handler(row.original)
                    : () => ({}),
                  // @ts-ignore
                  onMouseEnter: rowAction.onMouseEnter
                    ? // @ts-ignore
                      () => rowAction.onMouseEnter(row.original)
                    : () => ({}),
                  // @ts-ignore
                  options: rowAction.options
                    ? // @ts-ignore
                      rowAction.options.map((o) => ({
                        ...o,
                        handler: () => o.handler(row.original),
                      }))
                    : null,
                }}
              />
            );
          })
        : null}
      {floatingMenuActions.length ? (
        <FloatingMenu
          items={floatingMenuActions.map((action) => ({
            ...action,
            handler: () => action.handler(row.original),
          }))}
        >
          <Icon.DotsIcon />
        </FloatingMenu>
      ) : null}
    </div>
  );
};

export const CustomActionElement = ({ action, type }) => {
  if (action.options) {
    return (
      <FloatingMenu
        className={`table__row-action-icon table__row-action-icon--${type}`}
        items={action.options}
      >
        <Tooltip title={action.name}>
          <action.Icon />
        </Tooltip>
      </FloatingMenu>
    );
  }

  return (
    <Tooltip
      title={action.name}
      className={`table__row-action-icon table__row-action-icon--${type}`}
      onClick={action.onClick}
      onMouseEnter={action.onMouseEnter}
    >
      <action.Icon />
    </Tooltip>
  );
};
export const ExpanderHeaderCell = ({ column, ...props }) => {
  const cellProps = column.getHeaderProps();
  const { isSelectable } = useTableContext();

  const commonProps = useTestProps(props);

  const style = useMemo(() => {
    if (isSelectable) {
      return {
        ...cellProps.style,
        minWidth: 14,
        width: 14,
        flex: '0 0 auto',
      };
    }
    return {
      ...cellProps.style,
      minWidth: 30,
      flex: '2 0 auto',
    };
  }, [isSelectable, cellProps]);

  const allCellProps = _.omit(
    {
      ...cellProps,
      style,
      ...commonProps,
    },
    'key'
  );
  return (
    <div
      className={`table__expander-cell ${
        isSelectable ? 'table__expander-cell--no-padding' : ''
      } `}
      {...allCellProps}
    >
      <div />
    </div>
  );
};

export const ExpanderBodyCell = ({ row, cell, ...props }) => {
  const { expandRow, expandedRows, isSelectable, rowIdAccessor } =
    useTableContext();
  const commonProps = useTestProps(props);
  const isExpanded = expandedRows[row.original[rowIdAccessor]];
  const cellProps = cell.getCellProps();
  const style = useMemo(() => {
    if (isSelectable) {
      return {
        ...cellProps.style,
        minWidth: 14,
        width: 14,
        flex: '0 0 auto',
      };
    }
    return {
      ...cellProps.style,
      minWidth: 30,
      flex: '2 0 auto',
    };
  }, [isSelectable, cellProps]);

  const allCellProps = _.omit(
    {
      ...{
        ...cellProps,
        style,
      },
      ...commonProps,
    },
    'key'
  );

  return (
    <div
      className={`table__expander-cell ${
        isSelectable ? 'table__expander-cell--no-padding' : ''
      } `}
      {...allCellProps}
    >
      <div
        onClick={() => {
          expandRow(row.original[rowIdAccessor]);
        }}
        className={`table__expander-icon ${
          isExpanded ? 'table__expander-icon--expanded' : ''
        }`}
        title="Expands row"
      >
        <Icon.ArrowDownIcon />
      </div>
    </div>
  );
};

export const SelectionHeaderCell = ({ column, ...props }) => {
  const { data, allRowsAreSelected, selectedRows, selectionMode, onSelectRow } =
    useTableContext();

  const commonProps = useTestProps(props);

  const onChangeSelection = useCallback(
    (e) => {
      const selection = e.target.checked ? data : false;
      onSelectRow(selection);
    },
    [onSelectRow, data]
  );
  const cellProps = column.getHeaderProps();

  if (!data.length) {
    const allCellProps = _.omit(
      {
        ...cellProps,
        style: {
          ...cellProps.style,
          minWidth: 46,
        },
        ...commonProps,
      },
      'key'
    );

    return <div className="table__checkbox-cell" {...allCellProps} />;
  }

  const isChecked = allRowsAreSelected || data.length === selectedRows.length;

  const allCellProps = _.omit(
    {
      ...cellProps,
      style: {
        ...cellProps.style,
        minWidth: 46,
      },
      ...commonProps,
    },
    'key'
  );

  return (
    <div className="table__checkbox-cell" {...allCellProps}>
      {selectionMode === 'checkbox' ||
      selectionMode ===
        'checkbox-without-the-ability-to-select-rows-that-are-not-visible' ? (
        <Checkbox
          name={`checkbox-col-${column.id}`}
          checked={isChecked}
          onChange={onChangeSelection}
          title="Toggle selection for all rows"
        />
      ) : null}
    </div>
  );
};

export const SelectionBodyCell = ({ row, cell, ...props }) => {
  const {
    onSelectRow,
    selectionMode,
    rowSelection,
    rowExclusion,
    allRowsAreSelected,
    rowIdAccessor,
  } = useTableContext();
  const commonProps = useTestProps(props);
  const onChangeSelection = useCallback(() => onSelectRow(row.original), [row]);
  const cellProps = cell.getCellProps();
  const isChecked = allRowsAreSelected
    ? !rowExclusion[row[rowIdAccessor]]
    : !!rowSelection[row[rowIdAccessor]];

  const allCellProps = _.omit(
    {
      ...cellProps,
      style: {
        ...cellProps.style,
        minWidth: 46,
      },
      ...commonProps,
    },
    'key'
  );

  return (
    <div className="table__checkbox-cell" {...allCellProps}>
      {(selectionMode === 'checkbox' ||
        selectionMode === 'checkbox-without-all-selection' ||
        selectionMode ===
          'checkbox-without-the-ability-to-select-rows-that-are-not-visible') && (
        <Checkbox
          name={`checkbox-row-${row.id}`}
          checked={isChecked}
          onChange={onChangeSelection}
        />
      )}
      {selectionMode === 'radio' && (
        <RadioButton
          checked={isChecked}
          onClick={onChangeSelection}
          name={`checkbox-row-${row.id}`}
          value={isChecked}
        />
      )}
    </div>
  );
};

export const DataHeaderCell = ({ column, ...props }) => {
  const { sorting, isSortable, onChangeSorting } = useTableContext();
  const commonProps = useTestProps(props);
  const headerCellProps = column.getHeaderProps();
  const isColumnSortable = isSortable && !!column.sortField;
  const isSorted = isSortable && sorting?.field === column.sortField;
  const SortingIcon =
    sorting?.order === 'desc' ? Icon.ArrowDownIcon : Icon.ArrowUpIcon;
  const onClick = useCallback(() => {
    if (!isColumnSortable) return;
    let nextSortingOrder: 'asc' | 'desc' = 'asc';
    if (isSorted) {
      if (sorting?.order === 'desc') {
        // @ts-ignore
        nextSortingOrder = null;
      } else {
        nextSortingOrder = 'desc';
      }
    }
    if (!nextSortingOrder) onChangeSorting();
    else {
      onChangeSorting({
        field: column.sortField,
        order: nextSortingOrder,
      });
    }
  }, [isSorted, sorting, column, isColumnSortable]);
  const cellTitle = useMemo(() => {
    if (!isColumnSortable) return '';
    if (!isSorted) return 'Sort Ascending';
    if (sorting?.order === 'asc') return 'Sorted Ascending';
    return 'Sorted Descending';
  }, [isSorted, isColumnSortable, sorting]);
  return (
    <div
      {..._.omit(headerCellProps, 'key')}
      onClick={onClick}
      className={`table__header ${
        isColumnSortable ? 'table__header--sortable' : ''
      }`}
      title={cellTitle}
      {..._.omit(commonProps, 'key')}
    >
      {column.label}
      <span
        className={`table__header-sorting-icon ${
          isColumnSortable && !isSorted
            ? 'table__header-sorting-icon--unsorted'
            : ''
        }`}
      >
        {isSortable && column.sortField && isSorted ? <SortingIcon /> : null}
      </span>
    </div>
  );
};

export const DataBodyCell = (props) => {
  const { cell } = props;
  const commonProps = useTestProps(props);
  const { isLoading, onClickRow } = useTableContext();

  const cellProps = cell.getCellProps();

  if (isLoading) {
    return (
      <div {...cellProps} className="table__body-cell" {...commonProps}>
        <div
          className={`table__body-cell-content ${
            isLoading ? 'table__body-cell-content--is-loading' : ''
          }`}
        >
          <Skeleton className="table__cell-skeleton">&zwnj;</Skeleton>
        </div>
      </div>
    );
  }
  const cellClassName = `table__body-cell ${
    // @ts-ignore
    onClickRow ? 'table__body-cell--clickable' : ''
  }`;
  const onClick = (e) => {
    if (onClickRow) onClickRow(cell.row.original);
    if (cellProps.onClick) cellProps.onClick(e);
  };

  const allCellProps = {};
  return (
    <div
      {..._.omit(cellProps, 'key')}
      onClick={onClick}
      className={cellClassName}
      {..._.omit(commonProps, 'key')}
    >
      <div className="table__body-cell-content">{cell.render('Cell')}</div>
    </div>
  );
};
