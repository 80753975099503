import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  NotificationReceiverSchema,
  NotificationSchema,
  NotificationTemplateSchema,
} from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

import { NotificationsRouterPrefix } from './notification.constants.sdk';
import {
  NotificationAudienceSendSchema,
  RenderNotificationResultSchema,
  RenderNotificationSchema,
  ScheduleNotificationSchema,
  SendNotificationSchema,
} from './schemas/notification.schema.sdk';

const contract = initContract();

export const NotificationsRouterContract = contract.router({
  listNotifications: {
    method: 'GET',
    path: `${NotificationsRouterPrefix}/`,
    responses: ContractPaginatedResponse(NotificationSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  sendNotifications: {
    method: 'POST',
    path: `${NotificationsRouterPrefix}/send`,
    responses: ContractStandardResponse(z.object({ deliveryId: z.string() })),
    body: SendNotificationSchema,
  },
  scheduleNotifications: {
    method: 'POST',
    path: `${NotificationsRouterPrefix}/schedule`,
    responses: ContractStandardResponse(z.object({ deliveryId: z.string() })),
    body: ScheduleNotificationSchema,
  },
  renderNotification: {
    method: 'PATCH',
    path: `${NotificationsRouterPrefix}/preview`,
    responses: ContractStandardResponse(RenderNotificationResultSchema),
    body: RenderNotificationSchema,
  },
  removeNotifications: {
    method: 'DELETE',
    path: `${NotificationsRouterPrefix}`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  rescheduleNotifications: {
    method: 'PATCH',
    path: `${NotificationsRouterPrefix}/reschedule`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema.extend({
      scheduleDate: TransformedDateSchema,
    }),
  },
  sampleReceivers: {
    method: 'GET',
    path: `${NotificationsRouterPrefix}/receivers`,
    responses: ContractStandardResponse(
      z.object({
        unique: z.number(),
        total: z.number(),
        receivers: z.array(NotificationReceiverSchema),
      })
    ),
    query: NotificationAudienceSendSchema,
  },
  listNotificationTemplates: {
    method: 'GET',
    path: `${NotificationsRouterPrefix}/template`,
    responses: ContractPaginatedResponse(NotificationTemplateSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
});
