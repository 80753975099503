import { AxiosError } from 'axios';

export type ApiClientOptions = {
  baseUrl: string;

  errorHandler?: any;
  warningHandler?: any;
  tokenGetter: () => string;
};

export enum SdkEndpointType {
  Query = 'Query',
  Mutation = 'Mutation',
}

export interface ApiError {
  message: string;
  name: string;
  code: string;
}

export class SdkError extends AxiosError implements ApiError {
  public original: AxiosError;
  public name: string;
  public code: string;
  public message: string;

  constructor(
    name: string,
    message: string,
    code: string,
    original: AxiosError
  ) {
    super(message, code, original.config, original.request, original.response);

    this.original = original;
    this.name = name;
    this.code = code;
    this.message = message;
  }

  toJSON = () => {
    return { name: this.name, message: this.message, code: this.code };
  };
}
