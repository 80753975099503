import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CreateLearningProgramPayload } from '@nl-lms/feature/learning-programs/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { routes } from '@nl-lms/web/lib/routes';

import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import { learningProgramsApi } from '../../../_common/services/api';

const { useCreateLearningProgramMutation } = learningProgramsApi;

const FormSchema = z.object({
  name: z.string().min(1, { message: 'Program Name is required' }),
  description: z.string(),
  thumbnail: z.string(),
});

export const LearningProgramCreateSideModal = () => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      description: '',
      thumbnail: '',
      learnerGroupIds: [],
      learnerIds: [],
    },
  });
  const goTo = useRoutingAction();
  const { hide } = useModal();
  const [createLearningProgram, { error, isLoading }] =
    useCreateLearningProgramMutation();
  const onSubmit = useCallback(async () => {
    const entity = getValues() as z.infer<typeof FormSchema>;
    const parsedEntity: CreateLearningProgramPayload = {
      name: entity.name,
      description: entity.description,
      thumbnail: entity.thumbnail,
    };
    const result = await createLearningProgram(parsedEntity);
    if ('data' in result) {
      hide();
      if (!result.data?.id) return;
      goTo(routes.admin.manage.programs.item.path.full(result.data.id));
    }
  }, []);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Create Learning Program</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <FormField label="Name" required errorMessage={errors.name?.message}>
            <Input
              hasError={!!errors.name}
              {...register('name')}
              placeholder="Program Name"
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Description"
            errorMessage={errors?.description?.message}
            helpText="This field will also be used as the description field for the calendar event (if the calendar integration option is enabled). Keep in mind that images will not be visible in the calendar event. "
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextEditorWithFileUpload {...field} />}
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Thumbnail"
            errorMessage={errors?.thumbnail?.message}
          >
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput {...field} s3Url={field.value} />
              )}
            />
          </FormField>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label="Create"
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
