import { _ } from '@nl-lms/vendor';

const stringifyLocationValue = (countryCode, regionCode, city) =>
  `${countryCode},${regionCode},${city}`;

export const parseLocationData = (locationData) =>
  _.reduce(
    locationData,
    (countryAcc, countryObject) =>
      countryAcc.concat(
        _.reduce(
          countryObject.regions,
          (regionAcc, regionObject) =>
            regionAcc.concat(
              _.reduce(
                regionObject.cities,
                (citiesAcc, cityName) =>
                  citiesAcc.concat([
                    // @ts-ignore
                    {
                      countryName: countryObject.name,
                      countryCode: countryObject.code,
                      regionName: regionObject.name,
                      regionCode: regionObject.code,
                      value: stringifyLocationValue(
                        countryObject.code,
                        regionObject.code,
                        cityName
                      ),
                      label: `${cityName}, ${regionObject.name}, ${countryObject.name}`,
                      cityName,
                    },
                  ]),
                []
              )
            ),
          []
        )
      ),
    []
  );
