import clsx from 'clsx';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';
import { formatConstantString } from '@nl-lms/ui/utils';

import { Button } from '../Button/Button';
import { Icon } from '../index';
import { TidComponent } from '../index.types';
import './FormWizard.scss';

type FormWizardStep = {
  name: string;
  disabled?: boolean;
  onShow?: () => void;
  // If true the step will not be render its children when is not active
  removeWhenInactive?: boolean;
};

type FormWizardContextType = {
  steps: FormWizardStep[];
  setActiveStep: (s: string) => void;
  activeStep: string;
};

// @ts-ignore
const FormWizardContext = createContext<FormWizardContextType>(null);

const FormWizardProvider = ({
  steps,
  initialActiveStep,
  children,
  withContainer = true,
  ...props
}: TidComponent<{
  children: React.ReactNode;
  steps: string[] | FormWizardStep[];
  initialActiveStep?: string;
  withContainer?: boolean;
}>) => {
  const parsedSteps = useMemo<FormWizardStep[]>(() => {
    if (typeof steps[0] === 'string') {
      return steps.map((step) => ({
        name: step,
      }));
    }
    return steps as FormWizardStep[];
  }, [steps]);
  const commonProps = useTestProps(props);

  const [activeStep, setActiveStep] = useState(() => {
    if (initialActiveStep) {
      return initialActiveStep;
    }
    return parsedSteps[0].name;
  });
  return (
    <FormWizardContext.Provider
      value={{
        steps: parsedSteps,
        setActiveStep,
        activeStep,
      }}
    >
      {withContainer ? (
        <div className="form-wizard" {...commonProps}>
          {children}
        </div>
      ) : (
        children
      )}
    </FormWizardContext.Provider>
  );
};

const FormWizardStep = ({
  children,
  name,
  activeStyle = { display: 'block' },
  hiddenStyle = { display: 'none' },
  ...props
}: TidComponent<{
  children: React.ReactNode;
  name: string;
  activeStyle?: React.CSSProperties;
  hiddenStyle?: React.CSSProperties;
}>) => {
  const commonProps = useTestProps(props);
  const { steps } = useContext(FormWizardContext);

  const removeWhenInactive = useMemo(() => {
    const currentStep = steps.find((step) => step.name === 'name');
    return currentStep?.removeWhenInactive;
  }, [name, steps]);
  const { activeStep } = useContext(FormWizardContext);

  if (removeWhenInactive && activeStep !== name) {
    return null;
  }
  return (
    <div
      style={activeStep !== name ? hiddenStyle : activeStyle}
      {...commonProps}
    >
      {children}
    </div>
  );
};

const FormWizardNavigation = () => {
  const { activeStep, steps, setActiveStep } = useContext(FormWizardContext);
  const activeStepIndex = steps.findIndex((step) => step.name === activeStep);
  return (
    <>
      {activeStepIndex > 0 ? (
        <Button
          regular
          label={formatConstantString(steps[activeStepIndex - 1].name)}
          onClick={() => {
            const nextStep = steps[activeStepIndex - 1];
            if (nextStep.onShow) nextStep.onShow();
            setActiveStep(nextStep.name);
          }}
          icon={<Icon.ArrowLeftIcon />}
        />
      ) : null}
      {activeStepIndex < steps.length - 1 ? (
        <Button
          regular
          label={formatConstantString(steps[activeStepIndex + 1].name)}
          onClick={() => {
            const nextStep = steps[activeStepIndex + 1];
            if (nextStep.onShow) nextStep.onShow();
            setActiveStep(nextStep.name);
          }}
          icon={<Icon.ArrowRightIcon />}
        />
      ) : null}
    </>
  );
};

const FormWizardTabs = (props) => {
  const commonProps = useTestProps(props);
  const { activeStep, steps, setActiveStep } = useContext(FormWizardContext);
  const activeStepIndex = steps.findIndex((step) => activeStep === step.name);
  return (
    <div className="form-wizard__tabs" {...commonProps}>
      {steps.map((step, index) => (
        <div
          className={clsx(
            'form-wizard__tab',
            index === activeStepIndex && 'form-wizard__tab--active',
            step.disabled && 'form-wizard__tab--disabled'
          )}
          key={index}
          onClick={() => setActiveStep(step.name)}
        >
          {formatConstantString(step.name)}
        </div>
      ))}
    </div>
  );
};

export const FormWizard = Object.assign(FormWizardProvider, {
  Provider: FormWizardProvider,
  Navigation: FormWizardNavigation,
  Tabs: FormWizardTabs,
  Step: FormWizardStep,
  Context: FormWizardContext,
});
