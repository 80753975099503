import { Button, Card, Typography } from '@nl-lms/ui/components';
import React from 'react';
import imgSrc from './reports.png';
import {
  AdminAnalyticsUpsertReportSideModalTrigger,
  useShowUpsertReportSideModal,
} from '../../AdminAnalytics/AdminAnalyticsReportForms/AdminAnalyticsUpsertReportSideModal';

import './AdminReportsCard.scss';

export const AdminReportsCard = () => {
  const onClickGenerateReport = useShowUpsertReportSideModal();
  return (
    <Card className="admin-reports-card">
      <Card.Header withSeparator={false}>
        <div className="admin-reports-card__title">
          <Typography.h3>Reports</Typography.h3>
        </div>
        <div className="admin-reports-card__subtitle">
          <Typography.h2>Your data, your way!</Typography.h2>
        </div>
      </Card.Header>
      <div className="admin-reports-card__actions">
        <AdminAnalyticsUpsertReportSideModalTrigger>
          <Button
            label="generate report"
            ghost
            className="admin-reports-card__action"
          />
        </AdminAnalyticsUpsertReportSideModalTrigger>
      </div>
      <div className="admin-reports-card__image">
        <img src={imgSrc} />
      </div>
    </Card>
  );
};
