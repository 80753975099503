import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Command, useCommandState } from 'cmdk';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';

import { TimeDelta, getRelativeDate } from '@nl-lms/common/shared';
import { ScheduleDate } from '@nl-lms/feature/automation/sdk';
import { dateFns, timeSuggest } from '@nl-lms/vendor';

import { formatTimeDelta } from '../../utils/formatTimeDelta';
import { prettyDate } from '../../utils/prettyDate';
import { FloatingCard } from '../FloatingCard/FloatingCard';
import {
  ArrowRightIcon,
  CheckIcon,
  DotIcon,
  SearchIcon,
  SlidersIcon,
} from '../Icon';
import { ScrollArea } from '../ScrollArea';
import { Tooltip, TooltipAlignment } from '../Tooltip/Tooltip';
import './ScheduleDateInput.scss';

type Props = {
  initialValue?: ScheduleDate | null;
  value?: ScheduleDate | null;
  onChange: (value: ScheduleDate | null) => void;
  placeholder?: string;
  disabled?: boolean;
  showOnlyTheRelativeInput?: boolean;
};

enum ScheduleDateInputType {
  relative = 'relative',
  fixed = 'fixed',
}

type ScheduleDateInputContextType = {
  scheduleDate: ScheduleDate | null;
  onChangeScheduleDate: (value: ScheduleDate | null) => void;
};

enum RelativeScheduleDateGranularity {
  none,
  day,
  week,
  month,
  year,
}

const ScheduleDateInputContext =
  // @ts-expect-error
  React.createContext<ScheduleDateInputContextType>(null);

/**
 * Let's get a bit of cntext here.
 * This component is used in automation flows where we can schedule
 * when an action should happen relative to the moment that it was triggered.
 *
 * The whole logic and UI components use the ScheduleDate type as a base. And the complicate things down the road.
 *
 * The fixed scenario is as easy as it gets -> you just show a date picker and that's it.
 *
 * The complicated part is the relative date flow. Because of several reasons:
 * - The input makes use of a library that was passed around from Cron(Notion Calendar) to laserfocus to us. It only supports english as a language
 *   and if we want to extend it we'll probably have to refactor most of it. It works for scenarios like "in X unit" but it would be better
 *   if we could include all the expressions like that fit our use case. "Next monday, at 08:00", "In two weeks, skip over weekends". Basically what we show
 *   when the user configures settings.
 * - There's a lot of logic spread around in useMemo calls that describes what options to show and what is disabled based on the current delta values.
 *   It would be great if that was more centralized. I'm thinking about some kind of external data store here that would make it easier to understand, extened, and test.
 */

export function ScheduleDateInput({
  value: _value,
  initialValue,
  onChange,
  placeholder = 'Click on the input',
  disabled = false,
  showOnlyTheRelativeInput = false,
}: Props) {
  const scheduleDateRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [scheduleDate, setScheduleDate] = useState<ScheduleDate | null>(
    initialValue || null
  );
  const value = _value !== undefined ? _value : scheduleDate;
  const [isFloatingCardOpen, setIsFloatingCardOpen] = useState(false);
  const [isInputTextValueOverflowing, setIsInputTextValueOverflowing] =
    useState(false);
  const [inputType, setInputType] = useState<ScheduleDateInputType>(
    value?.type
      ? ScheduleDateInputType[value.type]
      : ScheduleDateInputType.relative
  );

  const onChangeScheduleDate = useCallback(
    (newValue: ScheduleDate | null) => {
      setIsFloatingCardOpen(false);
      setScheduleDate(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const onChangeInputType = useCallback(
    (newValue) => {
      setInputType(newValue);
      onChangeScheduleDate(null);
    },
    [onChangeScheduleDate]
  );

  const isRelativeScheduleDate = useMemo(() => {
    return value?.type === 'relative';
  }, [value]);

  const inputValue = useMemo(() => {
    if (!value) return '';
    if (!value.value) return '';
    if (value.type === 'relative') {
      return formatTimeDelta(value.value as TimeDelta);
    }

    return prettyDate(value.value, { withTime: true, withDate: true });
  }, [value]);

  const relativeScheduleDateGranularity = useMemo(() => {
    if (!isRelativeScheduleDate) {
      return RelativeScheduleDateGranularity.none;
    }
    if (!value?.value) return RelativeScheduleDateGranularity.none;
    const { days, weeks, months, years, settings } = value.value as TimeDelta;

    if (years) return RelativeScheduleDateGranularity.year;
    if (months) return RelativeScheduleDateGranularity.month;
    if (weeks) return RelativeScheduleDateGranularity.week;
    if (!!days && days > 30) return RelativeScheduleDateGranularity.month;
    if (!!days && days >= 7 && days < 32)
      return RelativeScheduleDateGranularity.week;
    if (!!days && days < 7) return RelativeScheduleDateGranularity.day;
    if (settings?.month) return RelativeScheduleDateGranularity.month;
    if (settings?.dayOfMonth || settings?.dayOfWeek)
      return RelativeScheduleDateGranularity.day;
    return RelativeScheduleDateGranularity.none;
  }, [value, isRelativeScheduleDate]);

  const onSelectTimeDeltaSettingsOption = useCallback(
    (e) => {
      if (!isRelativeScheduleDate) return;
      const timeDelta = value?.value as TimeDelta | null;
      if (!timeDelta) return;
      const timeDeltaSettings = timeDelta.settings || {};
      try {
        const targetSettings = JSON.parse(e.target.dataset.itemValue);
        onChangeScheduleDate({
          type: 'relative',
          value: {
            ...timeDelta,
            settings: { ...timeDeltaSettings, ...targetSettings },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [value, onChangeScheduleDate, isRelativeScheduleDate]
  );

  const skipWeekends = useMemo(() => {
    if (!value || value.type !== 'relative') return false;
    return value?.value?.settings?.skipWeekends;
  }, [value]);

  const isSkipWeekendsDisabled = useMemo(() => {
    if (!value || value.type !== 'relative' || !value.value) return true;
    const { settings } = value.value as TimeDelta;
    return (
      relativeScheduleDateGranularity ===
        RelativeScheduleDateGranularity.none ||
      settings?.dayOfWeek !== undefined
    );
  }, [value, relativeScheduleDateGranularity]);

  const hoursOptions = useMemo(() => {
    return generateHours().map((date) => ({
      label: dateFns.format(date, 'HH:mm'),
      value: { minute: date.getMinutes(), hour: date.getHours() },
    }));
  }, []);

  const daysOfWeekOptions = useMemo(() => {
    return generateDaysOfWeek().map((date) => ({
      label: dateFns.format(date, 'EEEE'),
      value: { dayOfWeek: date.getDay() },
    }));
  }, []);

  const daysOfMonthOptions = useMemo(() => {
    return generateDaysOfMonth().map((date) => ({
      label: dateFns.format(date, 'dd'),
      value: { dayOfMonth: date.getDate() },
    }));
  }, []);

  const daysOptions = useMemo(() => {
    if (
      relativeScheduleDateGranularity <= RelativeScheduleDateGranularity.week
    ) {
      return daysOfWeekOptions;
    }
    return daysOfMonthOptions;
  }, [daysOfMonthOptions, daysOfWeekOptions, relativeScheduleDateGranularity]);

  useEffect(() => {
    const clientWidth = inputRef?.current?.clientWidth;
    const scrollWidth = inputRef?.current?.scrollWidth;
    if (clientWidth !== scrollWidth) {
      setIsInputTextValueOverflowing(true);
    } else {
      setIsInputTextValueOverflowing(false);
    }
  }, [inputValue]);
  const monthsOptions = useMemo(() => {
    return generateMonths().map((date) => ({
      label: dateFns.format(date, 'MMMM'),
      value: { minutes: date.getMonth() },
    }));
  }, []);

  return (
    <ScheduleDateInputContext.Provider
      value={{
        scheduleDate: value,
        onChangeScheduleDate: onChangeScheduleDate,
      }}
    >
      <FloatingCard
        open={isFloatingCardOpen}
        onOpenChange={setIsFloatingCardOpen}
      >
        <FloatingCard.Anchor asChild>
          <div className="schedule-date" ref={scheduleDateRef}>
            <div className="schedule-date__input-container">
              <FloatingCard.Trigger asChild>
                <div className="schedule-date__input-tooltip">
                  {isInputTextValueOverflowing ? (
                    <Tooltip
                      className="schedule-date__input-tooltip"
                      title={inputValue}
                    >
                      <input
                        className="schedule-date__input"
                        ref={inputRef}
                        value={inputValue}
                        placeholder={placeholder}
                        disabled={disabled}
                      />
                    </Tooltip>
                  ) : (
                    <input
                      className="schedule-date__input"
                      ref={inputRef}
                      value={inputValue}
                      placeholder={placeholder}
                      disabled={disabled}
                    />
                  )}
                </div>
              </FloatingCard.Trigger>
              <div className="schedule-date__input-actions">
                <DropdownMenu.Root>
                  <div className="dropdown-menu">
                    <DropdownMenu.Trigger asChild>
                      <div className="schedule-date__input-action">
                        <SlidersIcon />
                      </div>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                      <DropdownMenu.Content className="dropdown-menu__content">
                        <DropdownMenu.Arrow className="dropdown-menu__arrow" />
                        {showOnlyTheRelativeInput ? null : (
                          <>
                            <DropdownMenu.Label className="dropdown-menu__label">
                              Input Type
                            </DropdownMenu.Label>
                            <DropdownMenu.RadioGroup
                              value={inputType}
                              onValueChange={onChangeInputType}
                            >
                              <DropdownMenu.RadioItem
                                className="dropdown-menu__radio-item"
                                value={ScheduleDateInputType.relative}
                              >
                                <DropdownMenu.ItemIndicator className="dropdown-menu__item-indicator">
                                  <DotIcon />
                                </DropdownMenu.ItemIndicator>
                                Relative
                              </DropdownMenu.RadioItem>

                              <DropdownMenu.RadioItem
                                className="dropdown-menu__radio-item"
                                value={ScheduleDateInputType.fixed}
                              >
                                <DropdownMenu.ItemIndicator className="dropdown-menu__item-indicator">
                                  <DotIcon />
                                </DropdownMenu.ItemIndicator>
                                Fixed
                              </DropdownMenu.RadioItem>
                            </DropdownMenu.RadioGroup>
                            <DropdownMenu.Separator className="dropdown-menu__separator" />
                          </>
                        )}

                        <DropdownMenu.CheckboxItem
                          checked={skipWeekends}
                          disabled={isSkipWeekendsDisabled}
                          onSelect={onSelectTimeDeltaSettingsOption}
                          data-item-value={JSON.stringify({
                            skipWeekends: !skipWeekends,
                          })}
                          className="dropdown-menu__checkbox-item"
                        >
                          <DropdownMenu.ItemIndicator className="dropdown-menu__item-indicator">
                            <CheckIcon />
                          </DropdownMenu.ItemIndicator>
                          Skip Weekends
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.Sub>
                          <DropdownMenu.SubTrigger
                            disabled={
                              relativeScheduleDateGranularity <=
                              RelativeScheduleDateGranularity.none
                            }
                            className="dropdown-menu__sub-trigger"
                          >
                            Time
                            <div className="dropdown-menu__sub-indicator">
                              <ArrowRightIcon />
                            </div>
                          </DropdownMenu.SubTrigger>
                          <DropdownMenu.Portal>
                            <DropdownMenu.SubContent
                              asChild
                              className="dropdown-menu__sub-content"
                              style={{ height: '300px' }}
                            >
                              <ScrollArea>
                                <ScrollArea.Viewport>
                                  {hoursOptions.map((option, index) => (
                                    <DropdownMenu.Item
                                      onSelect={onSelectTimeDeltaSettingsOption}
                                      data-item-value={JSON.stringify(
                                        option.value
                                      )}
                                      key={`hour-option-${option.label}-${index}`}
                                      className="dropdown-menu__item"
                                    >
                                      {option.label}
                                    </DropdownMenu.Item>
                                  ))}
                                </ScrollArea.Viewport>
                                <ScrollArea.Scrollbar orientation="vertical" />
                              </ScrollArea>
                            </DropdownMenu.SubContent>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Sub>

                        <DropdownMenu.Sub>
                          <DropdownMenu.SubTrigger
                            disabled={
                              relativeScheduleDateGranularity <=
                              RelativeScheduleDateGranularity.day
                            }
                            className="dropdown-menu__sub-trigger"
                          >
                            Day
                            <div className="dropdown-menu__sub-indicator">
                              <ArrowRightIcon />
                            </div>
                          </DropdownMenu.SubTrigger>
                          <DropdownMenu.Portal>
                            <DropdownMenu.SubContent
                              asChild
                              className="dropdown-menu__sub-content"
                            >
                              <ScrollArea>
                                <ScrollArea.Viewport
                                  style={{ maxHeight: '300px' }}
                                >
                                  {daysOptions.map((option, index) => (
                                    <DropdownMenu.Item
                                      onSelect={onSelectTimeDeltaSettingsOption}
                                      data-item-value={JSON.stringify(
                                        option.value
                                      )}
                                      key={`day-option-${option.label}-${index}`}
                                      className="dropdown-menu__item"
                                    >
                                      {option.label}
                                    </DropdownMenu.Item>
                                  ))}
                                </ScrollArea.Viewport>
                                <ScrollArea.Scrollbar orientation="vertical" />
                              </ScrollArea>
                            </DropdownMenu.SubContent>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Sub>

                        <DropdownMenu.Sub>
                          <DropdownMenu.SubTrigger
                            disabled={
                              relativeScheduleDateGranularity <=
                              RelativeScheduleDateGranularity.month
                            }
                            className="dropdown-menu__sub-trigger"
                          >
                            Month
                            <div className="dropdown-menu__sub-indicator">
                              <ArrowRightIcon />
                            </div>
                          </DropdownMenu.SubTrigger>
                          <DropdownMenu.Portal>
                            <DropdownMenu.SubContent
                              asChild
                              className="dropdown-menu__sub-content"
                            >
                              <ScrollArea>
                                <ScrollArea.Viewport
                                  style={{ maxHeight: '300px' }}
                                >
                                  {monthsOptions.map((option, index) => (
                                    <DropdownMenu.Item
                                      onSelect={onSelectTimeDeltaSettingsOption}
                                      data-item-value={JSON.stringify(
                                        option.value
                                      )}
                                      key={`month-option-${option.label}-${index}`}
                                      className="dropdown-menu__item"
                                    >
                                      {option.label}
                                    </DropdownMenu.Item>
                                  ))}
                                </ScrollArea.Viewport>
                                <ScrollArea.Scrollbar orientation="vertical" />
                              </ScrollArea>
                            </DropdownMenu.SubContent>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Sub>
                        <DropdownMenu.Separator className="dropdown-menu__separator" />
                        <DropdownMenu.Item
                          disabled={!value}
                          className="dropdown-menu__item"
                          onSelect={() => onChangeScheduleDate(null)}
                        >
                          Reset
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </div>
                </DropdownMenu.Root>
              </div>
            </div>

            <FloatingCard.Content
              className="schedule-date__floating-card"
              align="start"
              sideOffset={10}
              side="bottom"
              style={{
                width:
                  inputType === ScheduleDateInputType.relative
                    ? `${scheduleDateRef.current?.offsetWidth}px`
                    : 'unset',
              }}
            >
              {inputType === ScheduleDateInputType.relative ? (
                <RelativeDateInput />
              ) : (
                <DateTimePickerInput />
              )}
            </FloatingCard.Content>
          </div>
        </FloatingCard.Anchor>
      </FloatingCard>
    </ScheduleDateInputContext.Provider>
  );
}

const suggest = timeSuggest.create({
  allow_someday: false,
  allow_past_dates: false,
  interpreters: ['relative', 'subsequent'],
  results_count: 5,
});

// Ignore these rules since we don't want to show stuff like
// "tomorrow at 8"
const skipRules = [
  'tomorrow daylight?',
  'today>',
  'tonight',
  'this?> daylight?',
  'at?> hour> am?> today',
  'on? next? dayname daylight?',
  'tomorrow daylight? at?> hour? colon?> minute? am? in? timezone?',
];
const RelativeDateInput = () => {
  const { onChangeScheduleDate, scheduleDate } = useContext(
    ScheduleDateInputContext
  );
  const [inputValue, setInputValue] = useState('');
  const options = suggest(inputValue)
    .filter((suggestion) => !skipRules.includes(suggestion.rule))
    .map((suggestion) => {
      const dayOfWeekRules = [
        'on? next? dayname daylight? at?> hour? colon?> minute? am? in? timezone?',
        'on? last? dayname daylight? at?> hour? colon?> minute? am? in? timezone?',
      ];
      let parsedDelta: TimeDelta = suggestion.delta || {};

      const isDayOfWeekRule = dayOfWeekRules.includes(suggestion.rule);
      if (isDayOfWeekRule) {
        parsedDelta.settings = {
          dayOfWeek: suggestion.date.getDay(),
          hour: suggestion.date.getHours(),
          minute: suggestion.date.getMinutes(),
        };
      } else if (!suggestion.delta) {
        parsedDelta = dateFns.intervalToDuration({
          start: new Date(),
          end: suggestion.date,
        });
      }

      return {
        value: parsedDelta,
        label: suggestion.formatted,
      };
    });

  const onSelectOption = useCallback(
    (stringDelta: string) => {
      // persist settings between changes
      const prevSettings =
        scheduleDate?.type === 'relative'
          ? scheduleDate?.value?.settings
          : null;
      let clonedSettings: TimeDelta['settings'] = prevSettings
        ? { ...prevSettings }
        : {};
      // Always make sure that we set the utcOffset on a relative payload
      if (clonedSettings.utcOffset === undefined) {
        clonedSettings.utcOffset = new Date().getTimezoneOffset();
      }

      try {
        const optionDelta = JSON.parse(stringDelta);
        if (optionDelta.settings) {
          clonedSettings = { ...clonedSettings, ...optionDelta.settings };
        }
        onChangeScheduleDate({
          type: 'relative',
          value: { ...optionDelta, settings: clonedSettings },
        });
        setInputValue('');
      } catch (error) {
        console.error(error);
      }
    },
    [onChangeScheduleDate, scheduleDate]
  );
  return (
    <Command
      shouldFilter={false}
      loop
      className="schedule-date__relative-date-input"
    >
      <SearchInputHeader />
      <div className="schedule-date__search-input-container">
        <span className="schedule-date__search-input-icon">
          <SearchIcon />
        </span>

        <Command.Input
          value={inputValue}
          placeholder="Search for a specific time interval"
          onValueChange={setInputValue}
          className="schedule-date__search-input"
        />
      </div>
      <Command.Empty className="schedule-date__relative-input-no-options">
        No options available
      </Command.Empty>
      <Command.List className="schedule-date__relative-input-list">
        {options.map((option, index) => (
          <Command.Item
            className="schedule-date__relative-input-list-item"
            key={`relative-option-${index}`}
            value={JSON.stringify(option.value)}
            onSelect={onSelectOption}
          >
            {option.label}
          </Command.Item>
        ))}
      </Command.List>
      <SearchInputFooter />
    </Command>
  );
};

const SearchInputHeader = () => {
  const { scheduleDate } = useContext(ScheduleDateInputContext);
  const [referenceDate, setReferenceDate] = useState<Date>(new Date());
  const computedDate = useMemo(() => {
    if (!scheduleDate) return null;
    if (scheduleDate?.type === 'fixed') return null;
    if (!scheduleDate.value) return null;
    return getRelativeDate(scheduleDate.value, referenceDate);
  }, [scheduleDate, referenceDate]);

  if (!computedDate) return null;

  const prettyReferenceDate = prettyDate(referenceDate, {
    withTime: true,
    withDate: true,
  });
  const prettyRelativeDate = prettyDate(computedDate, {
    withTime: true,
    withDate: true,
  });
  return (
    <Tooltip
      title={`Based on the selected value, the date that is relative to ${prettyReferenceDate} is ${prettyRelativeDate}`}
    >
      <div className="schedule-date__search-input-header">
        <span>{prettyReferenceDate}</span>
        <span>→</span>
        <span>{prettyRelativeDate}</span>
      </div>
    </Tooltip>
  );
};

const SearchInputFooter = () => {
  const { scheduleDate } = useContext(ScheduleDateInputContext);
  const currentValue = useCommandState((state) => state.value);
  const [referenceDate, setReferenceDate] = useState<Date>(new Date());
  const computedDate = useMemo(() => {
    if (!currentValue) return null;
    if (scheduleDate?.type === 'fixed') return null;
    try {
      const parsedCurrentValue = JSON.parse(currentValue);
      const scheduleDateSettings = scheduleDate?.value?.settings || {};
      const parsedScheduleDate = {
        ...parsedCurrentValue,
        settings: parsedCurrentValue.settings
          ? { ...scheduleDateSettings, ...parsedCurrentValue.settings }
          : scheduleDateSettings,
      };
      return getRelativeDate(parsedScheduleDate, referenceDate);
    } catch (error) {
      console.error(error);
    }
  }, [currentValue, scheduleDate, referenceDate]);

  if (!computedDate) return null;
  const prettyReferenceDate = prettyDate(referenceDate, {
    withTime: true,
    withDate: true,
  });
  const prettyRelativeDate = prettyDate(computedDate, {
    withTime: true,
    withDate: true,
  });
  return (
    <Tooltip
      title={`Based on the highlighted value, the date that is relative to ${prettyReferenceDate} is ${prettyRelativeDate}`}
      side="bottom"
    >
      <div className="schedule-date__search-input-footer">
        <span>{prettyReferenceDate}</span>
        <span>→</span>
        <span>{prettyRelativeDate}</span>
      </div>
    </Tooltip>
  );
};

const DateTimePickerInput = () => {
  const { onChangeScheduleDate, scheduleDate } = useContext(
    ScheduleDateInputContext
  );
  const onChange = useCallback(
    (date) => {
      const parsedDate = new Date(date);
      // Prevent setting the initial date to 00:00
      if (!scheduleDate || !scheduleDate.value) {
        parsedDate.setHours(7);
      }
      onChangeScheduleDate({
        type: 'fixed',
        value: parsedDate.toISOString(),
      });
    },
    [onChangeScheduleDate, scheduleDate]
  );

  const selected = useMemo(() => {
    if (!scheduleDate || scheduleDate.type !== 'fixed') {
      return null;
    }
    return new Date(scheduleDate.value as string);
  }, [scheduleDate]);

  return (
    <div className="schedule-date__datepicker">
      <DatePicker
        locale="enUS"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="dd/MM/yyyy HH:mm"
        minDate={new Date()}
        selected={selected}
        onChange={onChange}
        inline
      />
    </div>
  );
};

function generateMonths(): Date[] {
  const months: Date[] = [];
  const currentYear = new Date().getFullYear();

  for (let month = 0; month < 12; month++) {
    const date = new Date(currentYear, month, 1);
    months.push(date);
  }

  return months;
}

function generateDaysOfWeek(): Date[] {
  const daysOfWeek: Date[] = [];
  const currentDate = new Date(); // Get the current date

  const firstDayOfWeek = new Date(currentDate);
  firstDayOfWeek.setDate(firstDayOfWeek.getDate() - currentDate.getDay());

  for (let i = 0; i < 7; i++) {
    const day = new Date(firstDayOfWeek);
    day.setDate(day.getDate() + i);
    daysOfWeek.push(day);
  }

  return daysOfWeek;
}

function generateDaysOfMonth(): Date[] {
  const daysOfMonth: Date[] = [];
  const currentDate = new Date();
  // Use a month with 31 days
  currentDate.setMonth(12);
  for (let day = 1; day <= 31; day++) {
    const referenceDate = new Date(currentDate);
    referenceDate.setDate(day);
    daysOfMonth.push(referenceDate);
  }

  return daysOfMonth;
}

function generateHours(): Date[] {
  const hours: Date[] = [];
  const currentDate = new Date();
  currentDate.setMinutes(0);

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourDate = new Date(currentDate);
      hourDate.setHours(hour);
      hourDate.setMinutes(minute);
      hours.push(hourDate);
    }
  }

  return hours;
}
