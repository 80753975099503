import { useContext } from 'react';

import { Box, Typography } from '@nl-lms/ui/components';

import { LearningProgramRulesGraphContext } from './LearningProgramRulesGraphContext';
import { LearningAssignmentRulesComponentGraphNodeDetail } from './LearningProgramRulesGraphNode';

export const LearningProgramRulesGraphSidebar = ({ layout, onDragNode }) => {
  const { unconnectedEntities } = useContext(LearningProgramRulesGraphContext);
  return (
    <aside className="learning-program-graph__sidebar">
      <Box padding={{ bottom: 'm' }}>
        <Typography.h4 className="learning-program-graph__sidebar__title">
          Unconnected Assignments
        </Typography.h4>
        <Typography.p className="learning-program-graph__sidebar__subtitle">
          Drag the assignments inside the graph to create connections.
        </Typography.p>
      </Box>
      {unconnectedEntities?.map((ent) => (
        <LearningAssignmentRulesComponentGraphNodeDetail
          data={{
            layout,
            draggable: true,
            detailInfo: ent,
            className: 'learning-program-graph__sidebar__node',
            onDragNode: () => onDragNode(ent),
          }}
        />
      ))}
    </aside>
  );
};
