import { Remirror, ThemeProvider, useRemirror } from '@remirror/react';
import React, { useCallback, useMemo } from 'react';
import json from 'refractor/lang/json.js';
import { CodeBlockExtension } from 'remirror/extensions';

import { useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './JsonEditor.scss';

const extensions = () => [
  new CodeBlockExtension({
    supportedLanguages: [json],
  }),
];

type JsonValue =
  | (string | number | Record<string, unknown>)[]
  | Record<string, unknown>;

type Props = TidComponent<{
  initialValue?: JsonValue;
  onChange: (value: JsonValue) => void;
}>;

export const JsonEditor = ({
  initialValue,
  onChange: _onChange,
  ...props
}: Props) => {
  const commonProps = useTestProps(props);

  const parseInitialValue = useMemo(() => {
    if (!initialValue) {
      return `<pre><code data-code-block-language='json'></code></pre>
      `;
    }
    return `
    <pre><code data-code-block-language='json'>${JSON.stringify(
      initialValue,
      null,
      2
    )}</code></pre>
    `;
  }, [initialValue]);
  const { manager, state, setState } = useRemirror({
    extensions,
    content: parseInitialValue,
    stringHandler: 'html',
  });

  const onChange = useCallback(
    (param) => {
      setState(param.state);
      const jsonTextValue = param.state.doc.textBetween(
        0,
        param.state.doc.content.size
      );
      try {
        const jsonValue = JSON.parse(jsonTextValue);
        _onChange(jsonValue);
      } catch (e) {
        console.error(e);
      }
    },
    [_onChange]
  );
  return (
    <ThemeProvider className="remirror-theme">
      <div className="jsoneditor" {...commonProps}>
        <Remirror
          manager={manager}
          initialContent={state}
          onChange={onChange}
          autoRender
        />
      </div>
    </ThemeProvider>
  );
};
