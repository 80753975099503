import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { useClassNameProps, useTestProps } from '../../hooks';
import './FilterBar.scss';
import './FilterBar.scss';
import { FilterBarFilteringState } from './FilterBar.types';
import {
  FilterBarContext,
  FilterBarProvider,
  FilterBarProviderProps,
} from './FilterBarContext/FilterBarContext';
import {
  FilterBarFiltersList,
  FilterBarFiltersListBadge,
} from './FilterBarFilters/FilterBarFiltersList';
import {
  FilterBarInputActiveFilterLabel,
  FilterBarInputLoadingSpinner,
  FilterBarInputMenu,
  FilterBarInputSearchIcon,
  FilterBarTextInput,
  FilterBarTextInputWrapper,
} from './FilterBarInput/FilterBarInputComponents';
import { FilterBarInputResetButton } from './FilterBarInput/FilterBarInputResetButton';
import { FilterBarPasteListButton } from './FilterBarInput/FilterBarPasteListModalButton';
import { FilterBarSettingsButton } from './FilterBarInput/FilterBarSettingsButton';

export const FilterBar = (
  props: Omit<
    FilterBarProviderProps,
    'children' | 'optionsMenuRef' | 'inputRef'
  >
) => {
  const optionsMenuRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const allProps = { ...props, optionsMenuRef, inputRef };
  const commonProps = useTestProps(props);

  return (
    // @ts-ignore
    <FilterBarProvider {...allProps}>
      <div className="filter-bar" {...commonProps}>
        <FilterBarInput />
        <FilterBarFilters />
      </div>
    </FilterBarProvider>
  );
};

export const FilterBarInput = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    activeFilter,
    filteringState,
    disabled,
    onChangeMenuVisibility,
    isMenuOpen,
    inputValue,
    inputRef,
    optionsMenuRef,
    onSetActiveOptionId,
  } = useContext(FilterBarContext);

  const classNameProps = useClassNameProps(
    'filter-bar__select',
    disabled ? 'filter-bar__select--disabled' : null
  );

  const isSelectingDateValueOption =
    activeFilter &&
    (activeFilter.type === 'DateField' ||
      activeFilter.type === 'DatetimeField') &&
    (filteringState === FilterBarFilteringState.EditingFilterValue ||
      filteringState === FilterBarFilteringState.EditingFilter);

  const onClickOutside = useCallback((e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      onChangeMenuVisibility(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, []);

  useEffect(() => {
    if (isMenuOpen && inputRef) {
      inputRef.current.focus();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen && optionsMenuRef.current) {
      const options = Array.from(
        optionsMenuRef.current.querySelectorAll(
          '[data-list-item-type="option"]'
        )
      );
      // @ts-ignore
      onSetActiveOptionId(options[0]?.dataset.listItemId);
    }
  }, [isMenuOpen, inputValue, filteringState]);

  return (
    <div {...classNameProps} ref={wrapperRef}>
      <FilterBarTextInputWrapper>
        <FilterBarInputSearchIcon />
        <FilterBarInputActiveFilterLabel />
        <FilterBarTextInput />
        <FilterBarInputLoadingSpinner />
        <FilterBarInputResetButton />
        <FilterBarPasteListButton />
        <FilterBarSettingsButton />
      </FilterBarTextInputWrapper>
      <FilterBarInputMenu
        className={
          isSelectingDateValueOption
            ? 'filter-bar__options-menu--date-picker-menu'
            : ''
        }
      />
    </div>
  );
};

export const FilterBarFilters = () => {
  const { filters, onRemoveFilter, onToggleCombinator, onClickFilter } =
    useContext(FilterBarContext);
  return (
    <FilterBarFiltersList>
      {filters.map((filter, index) => {
        // Skip initial brackets
        // We use those to keep a consistent data structure in the filters store
        // but the user doesn't need to see that
        if (index === 0 || index === filters.length - 1) return null;

        return (
          <FilterBarFiltersListBadge
            filter={filter}
            index={index}
            key={`filter-bar-filter-badge-${index}`}
            onRemoveFilter={onRemoveFilter}
            onToggleCombinator={onToggleCombinator}
            onClickFilter={onClickFilter}
          />
        );
      })}
    </FilterBarFiltersList>
  );
};
