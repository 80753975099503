import { chunk } from 'lodash';
import React, {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useClassNameProps, useRoutingAction, useTestProps } from '../../hooks';
import { Card } from '../Card/Card';
import { SingleSelect } from '../DownshiftSelect/SingleSelect';
import * as Icons from '../Icon';
import { ReactPortal } from '../ReactPortal/ReactPortal';
import {
  addDays,
  getAllDatesBetweenTwoDates,
  getMonday,
  subtractDays,
} from '../Timeline/Timeline.utils';
import { Typography } from '../Typography/Typography';
import { TidComponent } from '../index.types';
import './EntityLoadCalendar.scss';
import type {
  EntityLoadCalendarContextType,
  EntityLoadCalendarDayEvent,
  EntityLoadCalendarDayEventWithGridPosition,
  EntityLoadCalendarEventGroup,
} from './EntityLoadCalendar.types';
import { parseGroupEvents } from './EntityLoadCalendar.utils';

const EntityLoadCalendarContext =
  // @ts-ignore
  createContext<EntityLoadCalendarContextType>(null);
const useEntityLoadCalendarContext = () =>
  useContext(EntityLoadCalendarContext);

type EntityLoadCalendarProps = TidComponent<{
  groups: EntityLoadCalendarEventGroup[];
  title: string;
  entityTitle: string;
  onChangeDates?: (start: Date, end: Date) => void;
  showUpcomingEvents?: boolean;
  className?: string;
  tooltipHeight?: number;
}>;

export const EntityLoadCalendar = ({
  title,
  entityTitle,
  onChangeDates,
  groups,
  showUpcomingEvents = false,
  className,
  tooltipHeight = 120,
  ...props
}: EntityLoadCalendarProps) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <EntityLoadCalendarProvider {...commonProps} className={className}>
      <EntityLoadCalendarHeader>
        <EntityLoadCalendarName>{title}</EntityLoadCalendarName>
        {onChangeDates ? (
          <EntityLoadCalendarDateNavigation
            onChange={onChangeDates}
            showUpcomingEvents={showUpcomingEvents}
          />
        ) : null}
        <EntityLoadCalendarHourNavigation />
      </EntityLoadCalendarHeader>
      <EntityLoadCalendarBody
        entityTitle={entityTitle}
        groups={groups}
        tooltipHeight={tooltipHeight}
      />
    </EntityLoadCalendarProvider>
  );
};

export const EntityLoadCalendarProvider = ({
  children,
  className = '',
  ...props
}) => {
  const commonProps = useTestProps(props, { passThrough: true });
  const currentDate = useMemo(() => new Date(), []);
  const [relativeDate, setRelativeDate] = useState(currentDate);
  const [startHour, setStartHour] = useState(8);
  const [endHour, setEndHour] = useState(18);
  const startDate = useMemo(() => getMonday(relativeDate), [relativeDate]);
  const endDate = useMemo(
    () => addDays(getMonday(relativeDate), 6),
    [relativeDate]
  );
  const dates = useMemo(
    () => getAllDatesBetweenTwoDates(startDate, endDate),
    [startDate, endDate]
  );
  const hourStep = useMemo(
    () => Math.ceil((endHour - startHour) / 6),
    [startHour, endHour]
  );
  const weekDayHours = useMemo(() => {
    const hours = [];
    for (let i = startHour; i <= endHour; i += hourStep) {
      // @ts-ignore
      hours.push(i);
    }
    return hours;
  }, [startHour, endHour, hourStep]);
  const setHours = (start: number, end: number) => {
    setStartHour(start);
    setEndHour(end);
  };

  return (
    <EntityLoadCalendarContext.Provider
      value={{
        dates,
        currentDate,
        startDate,
        endDate,
        setRelativeDate,
        relativeDate,
        hours: weekDayHours,
        hourStep,
        startHour,
        endHour,
        setHours,
      }}
    >
      <Card paddingType="none" {...commonProps}>
        <div className="entity-load-calendar">{children}</div>
      </Card>
    </EntityLoadCalendarContext.Provider>
  );
};

export const EntityLoadCalendarHeader = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Card.Header separatorMarginBottom={0} {...commonProps}>
      <div className="entity-load-calendar__header">{children}</div>
    </Card.Header>
  );
};

export const EntityLoadCalendarName = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return <Card.HeaderTitle {...commonProps}>{children}</Card.HeaderTitle>;
};

export const EntityLoadCalendarDateNavigation = ({
  onChange,
  showUpcomingEvents,
  ...props
}) => {
  const commonProps = useTestProps(props);
  const { setRelativeDate, relativeDate, startDate, endDate, currentDate } =
    useEntityLoadCalendarContext();

  const goToNextWeek = useCallback(() => {
    setRelativeDate(addDays(relativeDate, 7));
  }, [relativeDate]);

  const goToPreviousWeek = useCallback(() => {
    setRelativeDate(subtractDays(relativeDate, 7));
  }, [relativeDate]);

  const isOnChangeEffectFirstRun = useRef(true);
  useEffect(() => {
    if (isOnChangeEffectFirstRun.current) {
      isOnChangeEffectFirstRun.current = false;
      return;
    }
    onChange(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <>
      <div className="entity-load-calendar__navigation" {...commonProps}>
        {(!showUpcomingEvents || currentDate < startDate) && (
          <div
            className="entity-load-calendar__navigation-action"
            role="button"
            onClick={goToPreviousWeek}
          >
            <Icons.ArrowLeftIcon />
          </div>
        )}
        <Typography.p>
          {startDate.toLocaleDateString('en-gb', {
            day: 'numeric',
            month: 'short',
          })}
        </Typography.p>
        <Typography.p>{`  -  `}</Typography.p>
        <Typography.p>
          {endDate.toLocaleDateString('en-gb', {
            day: 'numeric',
            month: 'short',
          })}
        </Typography.p>
        <div
          className="entity-load-calendar__navigation-action"
          role="button"
          onClick={goToNextWeek}
        >
          <Icons.ArrowRightIcon />
        </div>
      </div>
    </>
  );
};

export const EntityLoadCalendarHourNavigation = (props) => {
  const commonProps = useTestProps(props);
  const { startHour, endHour, setHours } = useEntityLoadCalendarContext();

  const hourOptions = [];

  for (let i = 0; i <= 24; i++) {
    // @ts-ignore
    hourOptions.push({
      value: i,
      label: `${i}:00`,
    });
  }

  const changeStartHour = useCallback(
    (value) => {
      setHours(value, endHour);
    },
    [endHour]
  );

  const changeEndHour = useCallback(
    (value) => {
      setHours(startHour, value);
    },
    [startHour]
  );

  const endHourOptions = useMemo(
    // @ts-ignore
    () => hourOptions.filter((opt) => opt?.value > startHour),
    [startHour]
  );
  const startHourOptions = useMemo(
    // @ts-ignore
    () => hourOptions.filter((opt) => opt?.value < endHour),
    [endHour]
  );

  return (
    <div className="entity-load-calendar__navigation" {...commonProps}>
      <SingleSelect
        name="startHour"
        placeholder="Start"
        options={startHourOptions}
        initialSelectedItem={hourOptions?.find(
          // @ts-ignore
          (opt) => opt?.value === startHour
        )}
        onChange={changeStartHour}
      />
      <SingleSelect
        name="endHour"
        placeholder="End"
        options={endHourOptions}
        // @ts-ignore
        initialSelectedItem={hourOptions?.find((opt) => opt?.value === endHour)}
        onChange={changeEndHour}
      />
    </div>
  );
};

export const EntityLoadCalendarBody = ({
  groups,
  tooltipHeight,
  entityTitle,
  ...props
}: TidComponent<{
  groups: EntityLoadCalendarEventGroup[];
  tooltipHeight: number;
  entityTitle: string;
}>) => {
  const commonProps = useTestProps(props, { passThrough: true });

  const { dates, currentDate, hours, hourStep } =
    useEntityLoadCalendarContext();
  const renderDates = useMemo(() => chunk(dates, 7), [dates]);

  const parsedEvents = useMemo(() => {
    return renderDates.map((dates) =>
      parseGroupEvents(groups, dates, hours, hourStep)
    );
  }, [groups, renderDates, hours, hourStep]);

  const getEventGroup = (week: number, title: string) =>
    parsedEvents[week].find((ev) => ev?.title === title)?.events;

  const goToEntityUrl = useRoutingAction({
    route: (url) => url,
    raw: true,
  });

  return (
    <Card.Content {...commonProps}>
      {renderDates.map((weekDates, week) => (
        <table className="entity-load-calendar__table" key={week}>
          <thead className="entity-load-calendar__table__thead">
            <tr>
              <th
                rowSpan={2}
                className="entity-load-calendar__table__thead__entity-title"
              >
                {entityTitle}
              </th>
              {weekDates.map((weekDate) => (
                <th
                  className="entity-load-calendar__table__thead__date-title"
                  colSpan={hours?.length}
                  key={`date-item-${weekDate.toISOString()}`}
                >
                  <div
                    className={`entity-load-calendar__table__thead__date-title__date ${
                      weekDate.getTime() === currentDate.getTime()
                        ? 'entity-load-calendar__table__thead__date-title__date--active'
                        : ''
                    }`}
                  >
                    {weekDate.toLocaleString('en', { weekday: 'long' })},
                    {weekDate.getDate()}
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              {weekDates.map((weekDate, index) => (
                <Fragment key={index}>
                  {hours?.map((hour, hourIndex) => (
                    <th
                      key={`${index}${hourIndex}`}
                      className={`entity-load-calendar__table__thead__date-title__hour ${
                        hourIndex === 0
                          ? 'entity-load-calendar__table__thead__date-title__hour--first'
                          : ''
                      } ${
                        (currentDate.getHours() === hour ||
                          currentDate.getHours() === hour + 1) &&
                        weekDate.getTime() === currentDate.getTime()
                          ? 'entity-load-calendar__table__thead__date-title__hour--active'
                          : ''
                      }`}
                    >
                      {`${hour}:00`}
                    </th>
                  ))}
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {groups.map((group, groupIndex) => (
              <tr className="entity-load-calendar__table__tbody__row">
                <td
                  className="entity-load-calendar__table__tbody__row__cell"
                  key={`group-name-item-${groupIndex}`}
                >
                  <div
                    className="entity-load-calendar__table__tbody__row__title__link"
                    // @ts-ignore
                    onMouseDown={() => goToEntityUrl(group?.entityUrl)}
                  >
                    {group?.title}
                  </div>
                  <div className="entity-load-calendar__table__tbody__row__count">
                    {getEventGroup(week, group?.title)?.length} events
                  </div>
                </td>
                <EntityLoadCalendarEventRow
                  daysCount={weekDates?.length}
                  hoursCount={hours?.length}
                  // @ts-ignore
                  eventGroup={getEventGroup(week, group?.title)}
                  tooltipHeight={tooltipHeight}
                />
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </Card.Content>
  );
};

type EntityLoadCalendarEventRowProps = TidComponent<{
  daysCount: number;
  hoursCount: number;
  eventGroup: EntityLoadCalendarDayEventWithGridPosition[];
  tooltipHeight?: number;
}>;

export const EntityLoadCalendarEventRow = ({
  daysCount,
  hoursCount,
  eventGroup,
  tooltipHeight,
  ...props
}: EntityLoadCalendarEventRowProps) => {
  const commonProps = useTestProps(props);
  const { hourStep } = useEntityLoadCalendarContext();

  const indexMap = [];
  for (let i = 0; i < daysCount * hoursCount * hourStep; i += hourStep) {
    // @ts-ignore
    indexMap.push(i);
  }
  const eventsTableData = indexMap?.map((i, iindex) => {
    const checkIfEventInCell = (startIndex, i) => {
      let stepCount = hourStep - 1;
      let isInCell = false;
      while (stepCount >= 0) {
        if (i === startIndex - stepCount) isInCell = true;
        stepCount--;
      }
      return isInCell;
    };

    const allEvents = eventGroup.filter((ev) =>
      checkIfEventInCell(ev?.indexStart, i)
    );

    if (allEvents?.length > 0) {
      let lastIndex = i + 1;
      allEvents.map((aEv) => {
        if (aEv.indexEnd > lastIndex) {
          lastIndex = aEv.indexEnd;
        }
      });
      // @ts-ignore
      i = lastIndex;

      const getEventStyleProps = (aEv) => {
        const cellDiff = hourStep ? aEv.indexStart % hourStep : 0;
        return {
          width: ((aEv?.indexEnd - aEv?.indexStart) / hourStep) * 45,
          left: cellDiff
            ? `${hourStep ? Math.round(100 / hourStep) * cellDiff : 0}%`
            : '0',
        };
      };
      return (
        <>
          <td
            className="entity-load-calendar__table__tbody__row__cell--empty"
            style={{ position: 'relative' }}
            {...commonProps}
          >
            {allEvents.map((aEv, aEvIndex) => (
              <div
                style={{
                  zIndex: 5,
                  position: 'relative',
                  ...getEventStyleProps(aEv),
                }}
                key={aEvIndex}
              >
                <EntityLoadCalendarEvent
                  event={aEv}
                  index={aEvIndex}
                  tooltipHeight={tooltipHeight}
                />
              </div>
            ))}
          </td>
        </>
      );
    } else {
      return (
        <td className="entity-load-calendar__table__tbody__row__cell--empty"></td>
      );
    }
  });

  return <>{eventsTableData}</>;
};

type EntityLoadCalendarEventProps = TidComponent<{
  index: number;
  event: EntityLoadCalendarDayEventWithGridPosition;
  tooltipHeight?: number;
}>;
export const EntityLoadCalendarEvent = ({
  event,
  index,
  tooltipHeight,
  ...props
}: EntityLoadCalendarEventProps) => {
  const commonProps = useTestProps(props);
  const [isClicked, setIsClicked] = useState(false);
  const eventDivRef = useRef<HTMLDivElement>(null);
  const globalOnClickListener = useCallback((e) => {
    if (eventDivRef.current && !eventDivRef.current.contains(e.target)) {
      setIsClicked(false);
    }
  }, []);
  const onClick = useCallback(() => {
    setIsClicked(true);
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', globalOnClickListener);
    return () => {
      document.removeEventListener('mousedown', globalOnClickListener);
    };
  }, []);

  let tooltipPosition = 'bottom';
  let tooltipPos = {};
  if (eventDivRef && eventDivRef.current) {
    const { bottom, top, height, x } =
      eventDivRef.current.getBoundingClientRect();
    const { height: parentHeight } =
      // @ts-ignore
      eventDivRef.current.offsetParent.getBoundingClientRect();

    if (bottom + 100 > parentHeight) tooltipPosition = 'top';

    if (tooltipPosition === 'top') {
      tooltipPos = {
        // @ts-ignore
        top: bottom - height - tooltipHeight - 20,
        left: x,
      };
    } else {
      tooltipPos = {
        top: top + height + 20,
        left: x,
      };
    }
  }

  const TooltipComponent = event.TooltipComponent;

  const eventClassNameProps = useClassNameProps(
    `entity-load-calendar__event`,
    `entity-load-calendar__event--${event.type}`,
    isClicked ? 'entity-load-calendar__event--active' : null
  );

  return (
    <div
      className="entity-load-calendar__grid-item entity-load-calendar__grid-item--event"
      ref={eventDivRef}
      {...commonProps}
    >
      <div
        tabIndex={index}
        {...eventClassNameProps}
        // For some reason the slide animation doesn't work when it is specified in
        // the `--active` class, but it works from here
        style={{
          backgroundPosition: isClicked ? 'left bottom' : 'right bottom',
        }}
        onClick={onClick}
      >
        {event.name}
      </div>
      {isClicked && event.TooltipComponent ? (
        <ReactPortal>
          <div
            className={`
              entity-load-calendar__event-tooltip-element
              entity-load-calendar__event-tooltip-element--${tooltipPosition} entity-load-calendar__event-tooltip-element--${event.type}`}
            style={{
              ...tooltipPos,
              height: tooltipHeight,
            }}
          >
            <div className="entity-load-calendar__event-tooltip-content">
              {/*// @ts-ignore */}
              <TooltipComponent
                eventObject={event}
                id={event.id}
                title={event?.name}
              />
            </div>
          </div>
        </ReactPortal>
      ) : null}
    </div>
  );
};

export { EntityLoadCalendarDayEvent };
