export const useClassNameProps = (
  ...classNames: any[]
): { className?: string } => {
  const className = classNames
    .reduce((acc, c) => {
      if (!c) return acc;

      let className;
      if (typeof c === 'string') className = c;
      if (c?.className) className = c.className;

      if (className) return `${acc} ${className}`;

      return acc;
    }, '')
    .trim();

  if (className) return { className };

  return {};
};
