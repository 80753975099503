import React, { useMemo } from 'react';

import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  AsyncSingleSelect,
  SingleSelectProps,
  getSelectOptions,
} from '@nl-lms/ui/components';

import { certificationApi } from '../../../_common/services/api';
import { fetchAndMapCertifications } from '../../_common/utils/fetchEntitiesForSelectMethods';

const { useListCertificationsQuery } = certificationApi;

const buildLabel = (item) => {
  return item.name;
};

const parseOptions = (res) => getSelectOptions(res, buildLabel);

const parseSelectedItem = (selectedItem, options) => {
  if (!selectedItem) return null;
  if (typeof selectedItem === 'string') {
    return options.find((o) => selectedItem === o.value);
  } else return selectedItem;
};

export const AdminCertificationSelect = (
  props: Omit<SingleSelectProps, 'options'>
) => {
  const {
    onChange,
    name,
    placeholder = 'Search by certification name',
    disabled = false,
    isClearable = false,
    hasError = false,
    initialSelectedItem = null,
    selectedItem = null,
  } = props;

  const { data } = useListCertificationsQuery({
    query: { query: transformTsRestQuery({ pagination: { disabled: true } }) },
  });
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data.rows);
  }, [data]);

  // @ts-ignore
  const selectedValue = parseSelectedItem(selectedItem, options) || null;
  // @ts-ignore
  const initialSelectedValue =
    parseSelectedItem(initialSelectedItem, options) || null;

  const handleOnChangeInputValue = (value) => {
    if (!value) {
      onChange(null, '');
    }
  };

  return (
    <AsyncSingleSelect
      onChange={onChange}
      onChangeInputValue={handleOnChangeInputValue}
      name={name}
      placeholder={placeholder}
      loadOptions={fetchAndMapCertifications}
      isClearable={isClearable}
      disabled={disabled}
      hasError={hasError}
      // @ts-ignore
      initialSelectedItem={initialSelectedValue}
      selectedItem={selectedValue}
    />
  );
};
