import React, { useCallback, useEffect } from 'react';

import {
  Button,
  Card,
  Icon,
  Separator,
  Typography,
} from '@nl-lms/ui/components';

import { SingleActionLayout } from '../../layouts/SingleActionLayout/SingleActionLayout';
import './ServiceDownPage.scss';

const ServiceDownPage = () => {
  return (
    <SingleActionLayout className="service-down-page">
      <div className="service-down-page__icon-wrapper">
        <Icon.CloudOffIcon />
      </div>
      <Card className="service-down-page__card" paddingType="medium">
        <Card.Body>
          <Typography.h1>Services are not available</Typography.h1>
          <Typography.h2 className="service-down-page__subtitle">
            It looks like we're having some internal issues
          </Typography.h2>
          <Typography.p className="service-down-page__description">
            We are sorry for the inconvenience. In the meantime you can contact
            us using in the chat app in order to get more info on the status of
            the issue.
          </Typography.p>
          <Separator />
        </Card.Body>
      </Card>
    </SingleActionLayout>
  );
};

export default ServiceDownPage;
