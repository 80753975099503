import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { SubscriptionsRouterContract } from './subscription.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'subscriptionsStore';

  const tsRest = buildTsRestInstance<typeof SubscriptionsRouterContract>(
    apiInstance,
    SubscriptionsRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listSubscriptions: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof SubscriptionsRouterContract.listSubscriptions.query>
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.listSubscriptions.responses)['200']
      >
    >;
    removeSubscriptions: RtkMutation<
      RtkFullParams<
        z.infer<typeof SubscriptionsRouterContract.removeSubscriptions.body>
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.removeSubscriptions.responses)['200']
      >
    >;
    createSubscription: RtkMutation<
      RtkFullParams<
        z.infer<typeof SubscriptionsRouterContract.createSubscription.body>
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.createSubscription.responses)['200']
      >
    >;
    updateSubscription: RtkMutation<
      RtkFullParams<
        {},
        z.infer<typeof SubscriptionsRouterContract.updateSubscription.body>,
        z.infer<
          typeof SubscriptionsRouterContract.updateSubscription.pathParams
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.updateSubscription.responses)['200']
      >
    >;
    getSubscription: RtkQuery<
      RtkFullParams<
        {},
        {},
        z.infer<typeof SubscriptionsRouterContract.getSubscription.pathParams>
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.getSubscription.responses)['200']
      >
    >;
    listSubscriptionInstances: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof SubscriptionsRouterContract.listSubscriptionInstances.query
          >
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.listSubscriptionInstances.responses)['200']
      >
    >;
    createSubscriptionInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof SubscriptionsRouterContract.createSubscriptionInstances.body
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.createSubscriptionInstances.responses)['200']
      >
    >;
    updateSubscriptionInstance: RtkMutation<
      RtkFullParams<
        {},
        z.infer<
          typeof SubscriptionsRouterContract.updateSubscriptionInstance.body
        >,
        z.infer<
          typeof SubscriptionsRouterContract.updateSubscriptionInstance.pathParams
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.updateSubscriptionInstance.responses)['200']
      >
    >;
    removeSubscriptionInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof SubscriptionsRouterContract.removeSubscriptionInstances.body
        >
      >,
      z.infer<
        (typeof SubscriptionsRouterContract.removeSubscriptionInstances.responses)['200']
      >
    >;
  }>(tsRest, SubscriptionsRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
