import { matchSorter } from 'match-sorter';

import { prettyDate } from '../../utils/prettyDate';
import { FilterBarFilter } from './FilterBarFilters/FilterBarFiltersStore';

export const parseFilterFieldsToOptions = (filters) =>
  Promise.all(
    filters.map(async (filter) => {
      if (
        filter.type !== 'CombinatorComponent' &&
        filter.type !== 'BracketComponent' &&
        filter.field.loadOptions
      ) {
        const valueOptions = await filter.field.loadOptions('');
        return {
          ...filter,
          field: {
            ...filter.field,
            valueOptions,
          },
        };
      }
      return filter;
    })
  );

export const getFilterFieldValueLabel = (filter: FilterBarFilter) => {
  if (filter.type === 'DateFilterComponent') return prettyDate(filter.value);
  if (filter.type === 'DatetimeFilterComponent')
    return prettyDate(filter.value, { withTime: true });

  if (filter.type === 'BooleanFilterComponent') return '';

  if (filter.type === 'SelectFilterComponent') {
    const values = Array.isArray(filter?.value) ? filter?.value : [];

    const foundValues = values
      .map(
        (value) =>
          // @ts-ignore
          filter.field.valueOptions.find((v) => v.value === value)?.label
      )
      .filter((f) => f);

    let label = '';
    if (foundValues.length) {
      label = foundValues.join(', ');

      if (foundValues.length < values.length) {
        const valuesDiff = values.length - foundValues.length;
        label = `${label} + ${valuesDiff} value${valuesDiff > 1 ? 's' : ''}`;
      }
    } else {
      label = `${values.length} value${values.length > 1 ? 's' : ''}`;
    }

    return label;
  }

  return filter.value;
};

export const getOptionId = (value, fieldName, optionType) =>
  `option-${optionType}-${fieldName}-${value}`;

export const filterOptions = (inputValue, options) => {
  if (!inputValue) return options;
  return matchSorter(options, inputValue, { keys: ['label', 'field.label'] });
};

export const getOptionClassName = (isActive, isChecked = false) =>
  `filter-bar__option ${isActive ? 'filter-bar__option--active' : ''} ${
    isChecked ? 'filter-bar__option--checked' : ''
  }`;
