import React, { Component, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  FloatingMenu,
  Separator,
  Typography,
} from '@nl-lms/ui/components';

import {
  ReportIssueForm,
  ReportIssueFormMessageField,
  ReportIssueFormSubjectField,
  ReportIssueFormSubmitButton,
} from '../../../adminApp/_common/AdminReportIssueModal';
import { routes } from '../../../lib/routes';
import './ErrorBoundaryContainer.scss';

type ErrorBoundaryContainerProps = {
  transparentBackground?: boolean;
  children: React.ReactNode;
};

type ErrorBoundaryContainerState = {
  hasError: boolean;
};

const ErrorPage = ({
  onNavigate,
}: {
  onNavigate: () => void;
  transparentBackground: boolean;
}) => {
  const navigate = useNavigate();
  const [wasErrorReported, setWasErrorReported] = useState(false);
  const onClickGoToPrevPage = useCallback(() => {
    navigate(-1);
    onNavigate();
  }, [history, onNavigate]);
  const onClickGoToPage = useCallback(
    (path) => {
      navigate(path);
      onNavigate();
    },
    [history, onNavigate]
  );
  const onClickReload = useCallback(() => {
    location.reload();
    onNavigate();
  }, [onNavigate]);
  const navigationItems = [
    { name: 'Previous Page', handler: onClickGoToPrevPage },
    {
      name: 'Learning Activity',
      handler: () => onClickGoToPage(routes.admin.activity.path.full()),
    },
    {
      name: 'Analytics',
      handler: () => onClickGoToPage(routes.admin.analytics.path.full()),
    },
    {
      name: 'Catalogue',
      handler: () => onClickGoToPage(routes.admin.catalogue.path.full()),
    },
    {
      name: 'Learners',
      handler: () => onClickGoToPage(routes.admin.learners.path.full()),
    },
  ];

  return (
    <div className="error-boundary-container">
      <Card className="error-boundary-container__card" paddingType="medium">
        <ReportIssueForm
          onSuccess={() => setWasErrorReported(true)}
          persistFormState={false}
          initialMessage={''}
        >
          <Card.Body noPadding>
            <Typography.h1>Something&#39;s wrong here...</Typography.h1>
            <br />
            {wasErrorReported ? (
              <>
                <Typography.h2>
                  Thank you for the additional information. We'll follow up on
                  you once we complete our investigation. In the meantime you
                  can navigate away from this crash page by using one of the
                  buttons below.
                </Typography.h2>

                <Separator />
              </>
            ) : (
              <>
                <Typography.h2>
                  It looks like the action you performed led to an unforeseen
                  error. Our team has been notified. If you'd like to help
                  please tell us what happened below.
                </Typography.h2>
                <Separator />
                <ReportIssueFormSubjectField label="Title" />
                <ReportIssueFormMessageField label="What happened?" />
              </>
            )}
          </Card.Body>
          <Card.Actions>
            <Button regular label="Reload Page" onClick={onClickReload} />
            <FloatingMenu
              items={navigationItems}
              className="error-boundary__action"
            >
              <Button regular={!wasErrorReported} label="Go To" />
            </FloatingMenu>
            {!wasErrorReported ? (
              <ReportIssueFormSubmitButton
                disabled={wasErrorReported}
                label="Send Crash Report"
              />
            ) : null}
          </Card.Actions>
        </ReportIssueForm>
      </Card>
    </div>
  );
};

class ErrorBoundaryContainer extends Component<
  ErrorBoundaryContainerProps,
  ErrorBoundaryContainerState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          // @ts-ignore
          transparentBackground={this.props.transparentBackground}
          onNavigate={() => this.setState({ hasError: false })}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryContainer;
