import * as RadixContextMenu from '@radix-ui/react-context-menu';
import React from 'react';
import { ReactNode } from 'react';

import './ContextMenu.scss';

type ContextMenuItem = {
  name: string;
  handler: (e) => void;
  items?: ContextMenuItem[];
  disabled?: boolean;
};

type ContextMenuProps = {
  children: ReactNode;
  items: ContextMenuItem[];
  disabled?: boolean;
};

export const ContextMenu = ({
  children,
  items,
  disabled,
}: ContextMenuProps) => (
  <RadixContextMenu.Root>
    <RadixContextMenu.Trigger
      {...(disabled ? { disabled } : {})}
      className="context-menu__trigger"
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      {children}
    </RadixContextMenu.Trigger>

    <RadixContextMenu.Portal>
      <RadixContextMenu.Content className="context-menu__content">
        {items?.map((item: ContextMenuItem, index: number) =>
          !item?.items?.length ? (
            <RadixContextMenu.Item
              className="context-menu__item"
              onSelect={item?.handler}
              disabled={item?.disabled}
              key={index}
            >
              {item?.name}
            </RadixContextMenu.Item>
          ) : (
            <RadixContextMenu.Sub>
              <RadixContextMenu.SubTrigger
                className="context-menu__sub-trigger"
                onSelect={item?.handler}
                disabled={item?.disabled}
              >
                {item?.name}
              </RadixContextMenu.SubTrigger>
              <RadixContextMenu.SubContent className="context-menu__sub-content">
                {item?.items?.map(
                  (subItem: ContextMenuItem, subIndex: number) => (
                    <RadixContextMenu.Item
                      className="context-menu__item"
                      onSelect={subItem?.handler}
                      disabled={subItem?.disabled}
                      key={index + subIndex}
                    >
                      {subItem?.name}
                    </RadixContextMenu.Item>
                  )
                )}
              </RadixContextMenu.SubContent>
            </RadixContextMenu.Sub>
          )
        )}
      </RadixContextMenu.Content>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Root>
);
