import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { LearningProgramSchema } from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

import { LearningProgramRouterPrefix } from './learning-program.constants.sdk';
import {
  AddLearningAssignmentPayloadSchema,
  CreateLearningProgramPayloadSchema,
  GetLearningProgramInstanceSchema,
  GetLearningProgramSchema,
  LaunchLearningProgramSchema,
  LearningProgramAudienceSchema,
  LearningProgramLearningContentSchema,
  ListLearningProgramInstanceForALearnerSchema,
  ListLearningProgramInstanceSchema,
  ListProgramRulesPayloadSchema,
  UpdateLearningProgramPayloadSchema,
  UpsertAutoEnrollSchema,
  UpsertProgramRulesPayloadSchema,
} from './learning-program.schema.sdk';

const contract = initContract();

export const LearningProgramRouterContract = contract.router({
  listLearningPrograms: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}`,
    responses: ContractPaginatedResponse(LearningProgramSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createLearningProgram: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}`,
    responses: ContractStandardResponse(LearningProgramSchema),
    body: CreateLearningProgramPayloadSchema,
  },
  removeLearningPrograms: {
    method: 'DELETE',
    path: `${LearningProgramRouterPrefix}`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  getLearningProgram: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/:id`,
    responses: ContractStandardResponse(GetLearningProgramSchema),
    pathParams: z.object({ id: z.string() }),
  },
  updateLearningProgram: {
    method: 'PATCH',
    path: `${LearningProgramRouterPrefix}/:id`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateLearningProgramPayloadSchema,
    pathParams: z.object({ id: z.string() }),
  },
  addLearningProgramAssignments: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/:id/assignment`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({ assignments: AddLearningAssignmentPayloadSchema }),
    pathParams: z.object({ id: z.string() }),
  },
  removeLearningProgramAssignments: {
    method: 'DELETE',
    path: `${LearningProgramRouterPrefix}/assignment`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  listLearningProgramRules: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/:id/rules`,
    responses: ContractPaginatedResponse(ListProgramRulesPayloadSchema),
    pathParams: z.object({ id: z.string() }),
  },
  addLearningProgramRules: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/:id/rules`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpsertProgramRulesPayloadSchema,
    pathParams: z.object({ id: z.string() }),
  },
  upsertAutoEnrollRule: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/:id/rules/auto-enroll`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpsertAutoEnrollSchema,
    pathParams: z.object({ id: z.string() }),
  },
  addLearningProgramInstances: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/:id/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      audience: LearningProgramAudienceSchema,
    }),
    pathParams: z.object({ id: z.string() }),
  },
  listLearningProgramInstances: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/instance`,
    responses: ContractPaginatedResponse(ListLearningProgramInstanceSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  searchLearningContent: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/learning-content`,
    responses: ContractStandardResponse(
      z.array(LearningProgramLearningContentSchema)
    ),
    query: z.object({ q: z.string() }),
  },
  listLearningProgramInstancesForALearner: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/learner/:learnerId`,
    pathParams: z.object({ learnerId: z.string() }),
    responses: ContractPaginatedResponse(
      ListLearningProgramInstanceForALearnerSchema
    ),
    query: z.object({ query: ApiQuerySchema }),
  },
  getLearningProgramInstance: {
    method: 'GET',
    path: `${LearningProgramRouterPrefix}/instance/:id`,
    responses: ContractStandardResponse(GetLearningProgramInstanceSchema),
    pathParams: z.object({ id: z.string() }),
  },
  updateLearningProgramInstancesStatus: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/instance/status`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({
      ids: z.array(z.string()),
      status: z.number(),
    }),
  },
  addLearningProgramAssignmentInstances: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/instance/add-assignment-instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema.extend({
      learningAssignmentIds: z.array(z.string()),
    }),
  },
  scheduleLearningProgramAssignmentInstances: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/instance/schedule-assignment-instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema.extend({
      learningAssignmentIds: z.array(z.string()),
      scheduleOn: TransformedDateSchema,
    }),
  },
  resolveLearningProgramInstances: {
    method: 'POST',
    path: `${LearningProgramRouterPrefix}/instance/resolve`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  removeLearningProgramInstances: {
    method: 'DELETE',
    path: `${LearningProgramRouterPrefix}/instance`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
});
