import { z } from 'zod';

export enum ChecklistInstanceStatus {
  'NOT_STARTED' = 1,
  'IN_PROGRESS',
  'COMPLETED',
  'FAILED',
  'CANCELED',
}
export const ChecklistSchema = z.object({
  id: z.string(),
  name: z.string(),
  title: z.string(),
  description: z.string(),
  thumbnail: z.string(),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  targetLearnerGroupIds: z.array(z.string()),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Checklist = z.infer<typeof ChecklistSchema>;

export const ChecklistChecklistItemInstanceSchema = z.object({
  id: z.string(),
  checklistId: z.string(),
  checklistItemId: z.string(),
  checklistInstanceId: z.string(),
  checklistChecklistItemId: z.string(),
  checked: z.boolean(),
  title: z.string(),
  description: z.string().nullable(),
  mandatory: z.boolean(),
  order: z.number(),
  startedOn: z.date().nullable(),
  finishedOn: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChecklistChecklistItemInstance = z.infer<
  typeof ChecklistChecklistItemInstanceSchema
>;

export const ChecklistChecklistItemSchema = z.object({
  id: z.string(),
  checklistId: z.string(),
  checklistItemId: z.string(),
  parentChecklistItemId: z.string().nullable(),
  mandatory: z.boolean(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChecklistChecklistItem = z.infer<
  typeof ChecklistChecklistItemSchema
>;

export const ChecklistInstanceSchema = z.object({
  id: z.string(),
  checklistId: z.string(),
  status: z.nativeEnum(ChecklistInstanceStatus),
  learnerId: z.string(),
  learnerHistoryId: z.string().nullable(),
  checkedItemsCount: z.number().nullable(),
  learningAssignmentInstanceId: z.string().nullable(),
  startedOn: z.date().nullable(),
  finishedOn: z.date().nullable(),
  timeSpent: z.number().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChecklistInstance = z.infer<typeof ChecklistInstanceSchema>;

export const ChecklistItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChecklistItem = z.infer<typeof ChecklistItemSchema>;
