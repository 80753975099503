import { useCallback } from 'react';

import { useModal } from '@nl-lms/ui/components';

import {
  AdminApiMutationNames,
  CertificationApiMutationNames,
  ContentLibraryItemsApiMutationNames,
  ElearningApiMutationNames,
  LearningAssignmentApiMutationNames,
  LearningProgramApiMutationNames,
  SubscriptionApiMutationNames,
  adminApi,
  certificationApi,
  contentLibraryItemsApi,
  elearningApi,
  learningAssignmentsApi,
  learningProgramsApi,
  subscriptionsApi,
} from '../services/api';

type Props = {
  createHookName:
    | AdminApiMutationNames
    | SubscriptionApiMutationNames
    | ElearningApiMutationNames
    | LearningAssignmentApiMutationNames
    | LearningProgramApiMutationNames
    | ContentLibraryItemsApiMutationNames
    | CertificationApiMutationNames;

  updateHookName:
    | AdminApiMutationNames
    | SubscriptionApiMutationNames
    | ElearningApiMutationNames
    | LearningAssignmentApiMutationNames
    | LearningProgramApiMutationNames
    | ContentLibraryItemsApiMutationNames
    | CertificationApiMutationNames;
  entityId?: string;
  parseEntity?: (entity: any) => any;
  onSuccess?: (result: Record<string, unknown>) => void;
  api?: any;
};

export const useSubmitUpsertEntityFromSideModal = ({
  createHookName,
  updateHookName,
  entityId,
  parseEntity = undefined,
  // @ts-ignore
  onSuccess: _onSuccess = null,
}: Props) => {
  const createHook =
    adminApi[createHookName] ||
    subscriptionsApi[createHookName] ||
    learningAssignmentsApi[createHookName] ||
    elearningApi[createHookName] ||
    learningProgramsApi[createHookName] ||
    contentLibraryItemsApi[createHookName] ||
    certificationApi[createHookName];
  const updateHook =
    adminApi[updateHookName] ||
    subscriptionsApi[updateHookName] ||
    learningAssignmentsApi[updateHookName] ||
    learningProgramsApi[updateHookName] ||
    elearningApi[updateHookName] ||
    contentLibraryItemsApi[updateHookName] ||
    certificationApi[updateHookName];
  const [
    createResource,
    { isLoading: isCreateLoading, error: createError },
    // @ts-ignore
  ] = createHook();
  const [
    updateResource,
    { isLoading: isUpdateLoading, error: updateError },
    // @ts-ignore
  ] = updateHook();

  const { hide } = useModal();
  const onSuccess = _onSuccess || hide;
  const mutate = entityId ? updateResource : createResource;
  const onSubmit = useCallback(
    async (formPayload) => {
      const parsedEntity = parseEntity ? parseEntity(formPayload) : formPayload;
      if (entityId) parsedEntity.id = entityId;
      const res = await mutate(parsedEntity);
      if ('data' in res) {
        onSuccess({ success: true, payload: res.data });
      }
    },
    [mutate, entityId]
  );

  return {
    onSubmit,
    error: createError || updateError,
    isLoading: isUpdateLoading || isCreateLoading,
  };
};
