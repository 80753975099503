import React, { useCallback } from 'react';

import {
  AsyncMultiSelect,
  AsyncMultiSelectProps,
  AsyncSingleSelect,
  getSelectOptions,
} from '@nl-lms/ui/components';

import { useApi } from '../../../_common/services/api';

export const AdminLiveCourseSingleSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItem = null,
    isClearable = true,
    returnEntireItemOnChange = true,
    hasError = false,
  } = props;
  const api = useApi();

  const onLoadCourses = useCallback(async (input) => {
    const res = await api.course.listForSelect(input);
    // @ts-ignore
    return res.map((course) => ({
      label: course.name,
      value: course.id,
      course: course,
      item: course,
    }));
  }, []);

  return (
    <AsyncSingleSelect
      name={name}
      onChange={onChange}
      placeholder="Search by course name"
      loadOptions={onLoadCourses}
      initialSelectedItem={initialSelectedItem}
      isClearable={isClearable}
      returnEntireItemOnChange={returnEntireItemOnChange}
      hasError={hasError}
    />
  );
};

export const AdminLiveCourseMultiSelect = (
  props: Omit<AsyncMultiSelectProps, 'loadOptions'>
) => {
  const {
    onChange,
    name,
    returnEntireItemOnChange = true,
    hasError,
    ...rest
  } = props;
  const api = useApi();

  const onLoadCourses = useCallback(async (input) => {
    const res = await api.course.listForSelect(input);
    return getSelectOptions(res);
  }, []);

  return (
    <AsyncMultiSelect
      name={name}
      onChange={onChange}
      placeholder="Search by course name"
      loadOptions={onLoadCourses}
      returnEntireItemOnChange={returnEntireItemOnChange}
      hasError={hasError}
      {...rest}
    />
  );
};
