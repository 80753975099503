import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { LearningProgram } from '@nl-lms/feature/learning-programs/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import { learningProgramsApi } from '../../../_common/services/api';

const { useUpdateLearningProgramMutation } = learningProgramsApi;

const FormSchema = z.object({
  name: z.string().min(1, { message: 'Program Name is required' }),
  description: z.string(),
  thumbnail: z.string(),
});

export const LearningProgramEditSideModal = ({
  learningProgram,
}: {
  learningProgram: LearningProgram;
}) => {
  const { hide } = useModal();
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: learningProgram.name,
      description: learningProgram.description,
      thumbnail: learningProgram.thumbnail,
    },
  });
  const [updateLearningProgram, { error, isLoading }] =
    useUpdateLearningProgramMutation();
  const onSubmit = useCallback(async () => {
    const entity = getValues() as z.infer<typeof FormSchema>;
    const result = await updateLearningProgram({
      id: learningProgram.id,
      ...entity,
    });
    if ('data' in result) {
      hide();
    }
  }, [learningProgram]);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Edit Learning Program</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <FormField label="Name" required errorMessage={errors.name?.message}>
            <Input
              hasError={!!errors.name}
              {...register('name')}
              placeholder="Program Name"
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Description"
            errorMessage={errors?.description?.message}
            helpText="This field will also be used as the description field for the calendar event (if the calendar integration option is enabled). Keep in mind that images will not be visible in the calendar event. "
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextEditorWithFileUpload {...field} />}
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Thumbnail"
            errorMessage={errors?.thumbnail?.message}
          >
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput {...field} s3Url={field.value} />
              )}
            />
          </FormField>
        </Box>
        <Separator marginTop={0} />
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label="Update"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
