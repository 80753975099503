import React from 'react';

export const DotMarkerIcon = (props) => {
  const { color } = props;
  let _color;

  switch (color) {
    case 'orange':
      _color = '#F5A623';
      break;
    case 'blue':
      _color = '#001A4D';
      break;
    case 'red':
      _color = '#F12424';
      break;
    case 'green':
      _color = '#00A470';
      break;
    case 'gray':
    default:
      _color = '#D5D2D2';
  }

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill={_color} />
      <circle cx="12.5" cy="12.5" r="4.5" fill="#F2F3F4" />
    </svg>
  );
};
