import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input } from '@nl-lms/ui/components';

type ScheduleDayAddInputProps = {
  onChange?: (value: number) => void;
  onSubmit: (value: number) => void;
  value?: number | string;
  initialValue?: number | string;
  disabled?: boolean;
};

export const ScheduleDayAddInput = ({
  onChange,
  onSubmit,
  value,
  disabled = false,
  initialValue,
}: ScheduleDayAddInputProps) => {
// @ts-ignore
  const [duration, setDuration] = useState<number>(() => {
    const val = initialValue || value;
    if (!val) return null;
    return parseFloat(val + '');
  });

  const updateOnChange = useCallback(
    (value) => {
      if (disabled) return;
      if (onChange) onChange(value);
    },
    [disabled, duration, onChange]
  );

  const onChangeDuration = useCallback(
    (e) => {
      if (disabled) return;
      const value = parseFloat(e.target.value);

      // prevent adding/updating when sum is greater than 24 hours
      if (value >= 24) {
// @ts-ignore
        setDuration(null);
        return;
      }

      setDuration(value);
      updateOnChange(value);
    },
    [disabled, updateOnChange]
  );

  useEffect(() => {
    if (disabled) return;
    if (!value) return;

    if (value) setDuration(parseFloat(value + ''));
  }, [value, disabled]);

  const onClickSubmit = useCallback(() => {
    if (disabled) return;

    onSubmit(duration);
// @ts-ignore
    setDuration(null);
  }, [duration, disabled, onSubmit]);

  return (
    <div className="dsti__add">
      <Input
        required
        name="newDayHours"
        type="number"
        value={duration || ''}
        onChange={onChangeDuration}
        placeholder="Add duration in hours"
        disabled={disabled}
        max="24"
      />
      <Button
        label="Add Day"
        onClick={onClickSubmit}
        disabled={!duration || disabled}
      />
    </div>
  );
};
