import React from 'react';

import { useClassNameProps, useTestProps } from '../../hooks';
import { StarIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './RatingButton.scss';

type RatingButtonProps = TidComponent<{
  className?: string;
  disabled?: boolean;
  checked: boolean;
  defaultChecked?: boolean;
  readOnly?: boolean;
  onClick: any;
  onMouseOver?: any;
  onMouseLeave?: any;
  name: string;
  value;
}>;

export const RatingButton: React.FunctionComponent<RatingButtonProps> = (
  props
) => {
  const {
    disabled = false,
    checked,
    defaultChecked = false,
    readOnly = false,
    onClick,
    onMouseOver,
    onMouseLeave,
    name,
    value,
  } = props;

  const commonProps = useTestProps(props);

  const classNameProps = useClassNameProps(
    'rating-button',
    props,
    disabled ? 'rating-button--disabled' : null,
    readOnly ? 'rating-button--readonly' : null
  );

  return (
    <div className="rating-button__wrapper" {...commonProps}>
      <label {...classNameProps}>
        <input
          type="radio"
          className="rating-button__input"
          disabled={disabled}
          defaultChecked={defaultChecked || checked}
          readOnly={readOnly}
          onClick={onClick}
          value={value}
          name={name}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        />
        <span className={'rating-button__icon'}>
          <StarIcon type={defaultChecked || checked ? 'filled' : 'outline'} />
        </span>
      </label>
    </div>
  );
};
