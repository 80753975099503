import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  AsyncSingleSelect,
  Box,
  FormField,
  Input,
  SingleSelect,
} from '@nl-lms/ui/components';

import { AdminLearnerGroupSingleSelect } from '../AdminLearner/AdminLearnerGroupSelect';
import {
  AssignationRulesStartDateSelect,
  AssignationRulesTriggerTimesInput,
} from './LearningAssignmentRulesListForm';
import { AssignationRuleTemplates } from './utils/constants';

export const LearningAssignmentRulesTemplatesForm = ({
  control,
  setValue,
  errors,
  register,
  watch,
  fetchAndMapAllAssignments,
}) => {
  const [showStartDate, setShowStartDate] = useState(false);
  const [showTriggerTimes, setShowTriggerTimes] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [showLearnerGroup, setShowLearnerGroup] = useState(false);

  const [selectedReference, setSelectedReference] = useState({});
  const [selectedLearnerGroup, setSelectedLearnerGroup] = useState({});
  const selectedTemplate = watch('template');

  const selectedPayload = useMemo(
    () =>
      AssignationRuleTemplates?.find((t) => t?.value === selectedTemplate)
        ?.payload,
    [selectedTemplate]
  );

  useEffect(() => {
    if (selectedTemplate) {
      setShowStartDate(selectedPayload?.selectStartDate ?? false);
      setShowTriggerTimes(selectedPayload?.selectTriggerTimes ?? false);
      setShowReference(selectedPayload?.selectReference ?? false);
      setShowLearnerGroup(selectedPayload?.selectLearnerGroup ?? false);

      if (selectedPayload?.name) {
        setValue('name', selectedPayload?.name);
      }

      if (selectedPayload?.startDate) {
        setValue('startDateType', selectedPayload?.startDate);
      }

      // @ts-expect-error
      if (selectedPayload?.settings?.triggerNTimes) {
        // @ts-expect-error
        setValue('triggerTimes', selectedPayload?.settings?.triggerNTimes);
      }

      if (!_.isEmpty(selectedPayload?.conditionsEquation)) {
        if (!showReference && !showLearnerGroup) {
          setValue('conditions', [selectedPayload?.conditionsEquation]);
        }
      }
    }
  }, [selectedTemplate]);

  const onChangeReference = useCallback(
    (selection) => {
      setSelectedReference({
        id: selection?.value,
        label: selection?.label,
      });

      setValue('conditions', [
        {
          ...selectedPayload?.conditionsEquation,
          value: {
            ...selectedPayload?.conditionsEquation?.value,
            referenceEntity: { id: selection?.value, label: selection?.label },
            referenceEntityId: selection?.value,
          },
        },
      ]);
    },
    [selectedPayload, selectedLearnerGroup]
  );

  return (
    <FormField.Root style={{ height: 'fit-content', paddingBottom: '0' }}>
      <FormField
        style={{ paddingBottom: '20px', paddingTop: 0 }}
        label="Rule Template"
      >
        <Controller
          name="template"
          control={control}
          render={({ field }) => (
            <SingleSelect
              options={AssignationRuleTemplates}
              placeholder="Select an assignation rule"
              selectedItem={selectedTemplate}
              {...field}
              onChange={(value) => {
                field?.onChange(value);
                setSelectedReference({ value: '', label: '' });
                setSelectedLearnerGroup({ value: '', label: '' });
              }}
            />
          )}
        />
      </FormField>
      <Box flex={{ flex: 'row', gap: 's' }}>
        <FormField
          style={{ paddingTop: 0 }}
          label="Name"
          helpText="Choose a distinctive name for the created rule so you will be able to identify it"
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              // @ts-ignore
              <Input
                {...field}
                {...register('name')}
                type="text"
                defaultValue={field?.value}
                value={field?.value}
              />
            )}
          />
        </FormField>
        {showStartDate ? (
          <AssignationRulesStartDateSelect control={control} />
        ) : null}
      </Box>
      {showTriggerTimes ? (
        <AssignationRulesTriggerTimesInput
          control={control}
          register={register}
          template={selectedTemplate}
        />
      ) : null}
      {showReference ? (
        <AssignationRuleReferenceSelect
          onChange={onChangeReference}
          selectedItem={selectedReference}
          fetchAndMapAllAssignments={fetchAndMapAllAssignments}
        />
      ) : null}
    </FormField.Root>
  );
};

export const AssignationRuleReferenceSelect = ({
  onChange,
  selectedItem,
  fetchAndMapAllAssignments,
}) => {
  return (
    <FormField label="Assignment" style={{ paddingBottom: '20px' }}>
      <AsyncSingleSelect
        name="assignmentReference"
        onChange={onChange}
        loadOptions={fetchAndMapAllAssignments}
        placeholder="Select reference assignment"
        returnEntireItemOnChange
        selectedItem={{ value: selectedItem.id, label: selectedItem.label }}
      />
    </FormField>
  );
};

export const AssignationRulesLearnerGroupSelect = ({
  onChange,
  selectedItem,
}) => (
  <FormField label="Learner Group">
    <AdminLearnerGroupSingleSelect
      onChange={onChange}
      name="learnerGroup"
      placeholder="Select reference learner group"
      returnEntireItemOnChange
      selectedItem={{ value: selectedItem.id, label: selectedItem.label }}
    />
  </FormField>
);
