import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ScheduleDate } from '@nl-lms/feature/automation/sdk';
import { LearningAssignment } from '@nl-lms/feature/learning-assignments/sdk';
import { LearningAssignmentDomainEventNames } from '@nl-lms/feature/learning-assignments/sdk';
import { LearningProgramAutomation } from '@nl-lms/feature/learning-programs/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Link,
  Modal,
  ScheduleDateInput,
  Separator,
  SingleSelect,
  Typography,
} from '@nl-lms/ui/components';
import { learningAssignmentsApi } from '@nl-lms/web/_common/services/api';

import { RuleComponent } from '../learning-assignment-rule/RuleComponent';
import { AssignationRuleConditionValue } from '../learning-assignment-rule/utils/types';
import './LearningProgramRulesGraph.scss';
import { LearningProgramRulesGraphContext } from './LearningProgramRulesGraphContext';
import { ProgramRuleTemplates, ProgramRulesTemplateTypes } from './constants';
import { ProgramRule } from './types';
import { isProgramEndRule, parseRulePayloadToProgramComponent } from './utils';

type LearningProgramGraphRuleEditModalProps = {
  onSubmit: (payload: {
    template: string;
    name: string;
    entities: { source: LearningAssignment; target: LearningAssignment };
    combinator?: 'and' | 'or' | null;
    editingRuleData: ProgramRule & { conditionIndex?: number };
    scheduleDate: ProgramRule['scheduleDate'] | null;
  }) => ProgramRule & { conditionIndex?: number };
};
const { useGetLearningAssignmentQuery } = learningAssignmentsApi;

const parseRuleTemplates = (isSelfRule, subjectEntityName) =>
  ProgramRuleTemplates?.filter((template) =>
    (isSelfRule ? SelfRuleTemplates : RuleTemplates).includes(template?.value)
  )?.map((template) => ({
    value: template?.value,
    label: template?.labelWithReference(subjectEntityName),
  }));

const SelfRuleTemplates = [
  ProgramRulesTemplateTypes.recurrence,
  ProgramRulesTemplateTypes.recurrenceFailed,
];
const RuleTemplates = [
  ProgramRulesTemplateTypes.assignStarted,
  ProgramRulesTemplateTypes.assignCreated,
  ProgramRulesTemplateTypes.assignFailed,
  ProgramRulesTemplateTypes.assignCompleted,
];

export const LearningProgramGraphRuleEditModal = (
  props: LearningProgramGraphRuleEditModalProps
) => {
  const [ruleTemplates, setRuleTemplates] = useState<string[]>([]);
  const [ruleName, setRuleName] = useState<string | undefined>();
  const [ruleScheduleDate, setRuleScheduleDate] = useState<ScheduleDate>(null);
  const [combinators, setCombinators] = useState<('and' | 'or' | null)[]>([]);
  const [touched, setTouched] = useState<string[]>([]);
  const [references, setReferences] = useState<Record<string, string>[]>([]);
  const [error, setError] = useState<Record<string, string> | null>(null);

  const {
    assignments,
    editingRuleData,
    showConnectModal,
    setShowConnectModal,
    setEditingRuleData,
    rules,
  } = useContext(LearningProgramRulesGraphContext);

  const onEditCancel = useCallback(() => {
    setShowConnectModal(false);
    setEditingRuleData(null);
  }, [setShowConnectModal, setEditingRuleData]);

  const editingRule: ProgramRule = useMemo(
    () =>
      editingRuleData
        ? parseRulePayloadToProgramComponent(editingRuleData)
        : ({} as ProgramRule),
    [editingRuleData, rules]
  );

  const isAutoAssign = useMemo(() => {
    if (editingRule?.conditions?.length === 1) {
      const ruleCondition = editingRule?.conditions?.[0]?.value;
      if (
        // @ts-ignore
        editingRule?.entityId === ruleCondition?.referenceEntityId &&
        // @ts-ignore
        ruleCondition?.eventName ===
          LearningAssignmentDomainEventNames.LearnerJoinedAudience
      ) {
        return true;
      }
    }
    return false;
  }, [editingRule]);

  const isEndRule = useMemo(
    () => editingRule?.entityName === LearningProgramAutomation.entityName,
    [editingRule]
  );
  let { data: subjectEntity } = useGetLearningAssignmentQuery({
    id: editingRule?.entityId,
  });
  if (isProgramEndRule(editingRule)) {
    // @ts-ignore
    subjectEntity = {
      id: 'current-program',
      name: 'Learning Program',
    };
  }

  useEffect(() => {
    if (editingRule) {
      const editingRuleConditions = editingRule?.conditions;
      const editingRuleConditionsEquation = editingRule?.conditionsEquation;
      const ruleName = editingRule?.name;

      setRuleName(ruleName);
      setRuleScheduleDate(editingRule?.scheduleDate || null);

      editingRuleConditions?.map((ruleCond, ruleCondIndex) => {
        const refData = assignments?.find(
          (a) => a.id === ruleCond?.value?.referenceEntityId
        );

        // @ts-ignore
        setReferences((currRefs) => {
          const refsCopy = [...currRefs];
          refsCopy.splice(ruleCondIndex, 1, {
            id: refData?.id ?? '',
            name: refData?.name ?? '',
          });
          return refsCopy;
        });

        const templateName =
          ProgramRuleTemplates?.find(
            (templ) =>
              templ?.domainEventValue ===
                (ruleCond?.value as AssignationRuleConditionValue)?.eventName &&
              (ruleCond?.value?.referenceEntityId === subjectEntity?.id
                ? SelfRuleTemplates?.includes(templ?.value)
                : RuleTemplates?.includes(templ?.value))
          )?.value || '';

        setRuleTemplates((currTemplates) => {
          const currTemplatesCopy = [...currTemplates];
          currTemplatesCopy.splice(ruleCondIndex, 1, templateName);
          return currTemplatesCopy;
        });

        // @ts-ignore
        const combinator =
          (ruleCond?.combinator ||
            editingRuleConditionsEquation?.value?.[ruleCondIndex]
              ?.combinator) ??
          null;

        setCombinators((comb: any[]) => {
          const combCopy = [...comb];
          combCopy.splice(ruleCondIndex, 1, combinator);
          return combCopy;
        });
      });
    }
  }, [editingRule, assignments]);

  const getConnectionRuleTemplates = useCallback(
    (ruleCondition, ruleConditionIndex) => {
      const isSelfRule =
        ruleCondition?.value?.referenceEntityId === editingRule?.entityId;
      const refAssignmentName = references?.[ruleConditionIndex]?.name;
      return parseRuleTemplates(isSelfRule, refAssignmentName);
    },
    [props, references, editingRule]
  );

  const onRuleSubmit = useCallback(() => {
    if (!ruleName) {
      setError((err) => ({ ...err, name: 'Please set a name for the rule' }));
    }
    if (ruleTemplates?.some((t) => !t)) {
      setError((err) => ({
        ...err,
        templates: 'Please make sure all conditions are set',
      }));
    }
    if (combinators?.some((c, index) => index > 0 && !c)) {
      setError((err) => ({
        ...err,
        combinators: 'Please make sure all combinators are set',
      }));
    }

    if (!error) {
      let currRule = { ...editingRule };
      editingRule?.conditions?.map((ruleCond, index) => {
        currRule = props?.onSubmit({
          template: ruleTemplates[index],
          name: ruleName as string,
          entities: {
            source: subjectEntity as LearningAssignment,
            // @ts-ignore
            target: references[index] as LearningAssignment,
          },
          combinator: combinators[index],
          editingRuleData: {
            ...editingRule,
            conditions: currRule?.conditions,
            conditionIndex: index,
          },
          scheduleDate: ruleScheduleDate,
        });
      });
    }
  }, [
    ruleName,
    ruleScheduleDate,
    ruleTemplates,
    references,
    subjectEntity,
    combinators,
    editingRule,
  ]);

  const isSubmitDisabled = useMemo(
    () =>
      ruleTemplates?.some((templ) => !templ) ||
      _.isEmpty(ruleName) ||
      !touched?.length,
    [ruleTemplates, ruleName, touched]
  );

  useEffect(() => {
    setTouched([]);
  }, [showConnectModal]);

  return (
    <Modal.Provider onOpenChange={onEditCancel} isOpen={showConnectModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Connection Rule</Modal.Title>
        </Modal.Header>
        <Box className="learning-program-graph-rule-edit-modal__body">
          <Box margin={{ bottom: 'm' }}>
            <Typography.h3 style={{ marginBottom: '10px' }}>
              {editingRule?.name}
            </Typography.h3>

            <RuleComponent
              rule={{
                ...(editingRule || {}),
                entity: subjectEntity,
                entityId: subjectEntity?.id ?? '',
              }}
            />
          </Box>
          <Separator marginBottom={20} marginTop={0} />
          <Typography.p style={{ marginBottom: '20px', color: '#7a7f82' }}>
            Updating the conditions below will change this rule.
          </Typography.p>
          <FormField
            label="Rule Name"
            helpText="Choose a distinctive name for the created rule so you will be able to identify it"
          >
            <Input
              name="graphConnectionName"
              type="text"
              onChange={(name) => {
                setRuleName(name);
                setTouched((touched) => [...touched, 'name']);
              }}
              returnEventOnChange={false}
              value={ruleName}
            />
          </FormField>
          <FormField
            label="Rule Offset"
            helpText="Choose a relative time delay for the trigger, if needed."
          >
            <ScheduleDateInput
              showOnlyTheRelativeInput
              value={ruleScheduleDate}
              onChange={(value) => {
                setRuleScheduleDate(value);
                setTouched((touched) => [...touched, 'scheduleDate']);
              }}
            />
          </FormField>
          <FormField>
            <FormField.Label>
              <span>
                {' '}
                <Link>{`${subjectEntity?.name} `}</Link> will be assigned IF:
              </span>
            </FormField.Label>
            {editingRule?.conditions?.map((ruleCond, ruleCondIndex) => {
              return (
                <>
                  {ruleCondIndex > 0 ? (
                    <Box margin={{ top: 's' }}>
                      <SingleSelect
                        name="graphConditionCombinator"
                        options={[
                          { label: 'AND', value: 'and' },
                          { label: 'OR', value: 'or' },
                        ]}
                        placeholder="Select combinator"
                        onChange={(comb) => {
                          setCombinators((currComb) => {
                            const copy = [...currComb];
                            copy[ruleCondIndex] = comb;
                            return copy;
                          });
                          setTouched((touched) => [...touched, 'combinator']);
                        }}
                        // @ts-ignore
                        selectedItem={combinators[ruleCondIndex]}
                        disabled={isAutoAssign || isEndRule}
                      />
                    </Box>
                  ) : null}
                  <Box margin={{ top: 's' }}>
                    <SingleSelect
                      name="graphConnection"
                      options={getConnectionRuleTemplates(
                        ruleCond,
                        ruleCondIndex
                      )}
                      placeholder="Select a connection rule"
                      onChange={(templ) => {
                        setRuleTemplates((templates) => {
                          const copy = [...templates];
                          copy[ruleCondIndex] = templ;
                          return copy;
                        });
                        setTouched((touched) => [...touched, 'template']);
                      }}
                      selectedItem={getConnectionRuleTemplates(
                        ruleCond,
                        ruleCondIndex
                      )?.find(
                        (templ) => templ?.value === ruleTemplates[ruleCondIndex]
                      )}
                      initialSelectedItem={getConnectionRuleTemplates(
                        ruleCond,
                        ruleCondIndex
                      )?.find(
                        (templ) => templ?.value === ruleTemplates[ruleCondIndex]
                      )}
                      disabled={isAutoAssign || isEndRule}
                    />
                  </Box>
                </>
              );
            })}
          </FormField>
        </Box>

        <Modal.Actions>
          <Modal.Close>
            <Button regular label="Cancel" onClick={onEditCancel} />
          </Modal.Close>
          <Modal.Close>
            <Button
              label="Update connection"
              onClick={onRuleSubmit}
              disabled={isSubmitDisabled}
            />
          </Modal.Close>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Provider>
  );
};
