import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { dateFns } from '@nl-lms/vendor';

import './DateTimePicker.scss';

registerLocale('enUS', dateFns.locales.enUS);

const toDateObject = (date) => {
  if (!date) return null;
  if (date instanceof Date) return date;
  return new Date(date);
};

export const DateTime = (props) => {
  const {
    disabled,
    date,
    onChange,
    placeholder,
    className,
    showTimeSelect,
    showTimeSelectOnly,
    minDate,
    maxDate,
    startDate,
    hasError,
    dateFormat = 'dd/MM/yyyy HH:mm',
    ...rest
  } = props;

  const classNameProps = useClassNameProps(
    'datepicker',
    disabled ? 'datepicker--disabled' : null,
    hasError ? 'datepicker--error' : null,
    showTimeSelect ? 'datepicker--with-time-select' : null,
    props
  );

  const commonProps = useTestProps(props);

  const customOnChange = (value) => {
    const selected = value ? new Date(value) : null;
    return onChange({
      target: {
        value: selected ? selected.toISOString() : null,
        name: rest.name,
      },
    });
  };

  const rawOnChange = (e) => {
    try {
      const selected = new Date(e.target.value);
      return onChange({ target: { value: selected.toISOString() } });
    } catch (err) {
      return onChange({ target: { value: null } });
    }
  };

  const selected = toDateObject(date);
  const dateMinDate = toDateObject(minDate);
  const dateMaxDate = toDateObject(maxDate);
  const dateStartDate = toDateObject(startDate);
  return (
    <div {...classNameProps} {...commonProps}>
      <DatePicker
        locale="enUS"
        selected={selected}
        onChange={customOnChange}
        onChangeRaw={rawOnChange}
        placeholderText={placeholder}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        disabled={disabled}
        dateFormat={dateFormat}
        popperClassName="datepicker__popper"
        minDate={dateMinDate}
        maxDate={dateMaxDate}
        startDate={dateStartDate}
        clearButtonClassName="datepicker__clear-button"
        {...rest}
      />
    </div>
  );
};

DateTime.defaultProps = {
  showTimeSelect: true,
  showTimeSelectOnly: false,
  className: '',
  date: null,
  placeholder: 'Select date',
  disabled: false,
};
