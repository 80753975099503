import React, {
  CSSProperties,
  Context,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactFlow, {
  Background,
  ConnectionMode,
  Controls,
  EdgeTypes,
  MiniMap,
  NodeTypes,
  Panel,
  Position,
  ReactFlowProps,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { SwitchInput } from '../SwitchInput/SwitchInput';
import { LayoutDirection, useGraphElements } from './useGraphElements';

export type FlowGraphNodeType =
  | 'Entity'
  | 'Start'
  | 'Detail'
  | 'End'
  | 'Connection';

export type FlowGraphEdgeType = 'Connector' | 'Self' | 'Start' | 'End';

export const FlowGraphEntityColor = {
  Info: 'info',
  Default: 'default',
};
export const FlowGraphNodeTypes = {
  // for assignments
  Entity: 'Entity',
  // for rules
  Connection: 'Connection',
  // for rule details i.e. startDate, triggerTimes
  Detail: 'Detail',
  // for the start and end nodes
  Start: 'Start',
  End: {
    Completed: 'End-Complete',
    Failed: 'End-Fail',
  },
};

export const FlowGraphEdgeTypes = {
  // links entities and connections
  Connector: 'Connector',
  // for self-referencing rules
  Self: 'Self',
  // links start/end nodes with assignments
  Default: 'Default',
  End: {
    Completed: 'End-Completed',
    Failed: 'End-Failed',
  },
};

export type FlowGraphEntity = {
  id: string;
  type: FlowGraphNodeType;
  sourcePosition?: Position;
  targetPosition?: Position;
  position?: Position;
  nodeData: {
    name: string;
    info: string;
    isSource: boolean;
    isTarget: boolean;
    headerText: string;
    headerColor: 'info' | 'default';
    colors: string[];
  } & any;
};

export type FlowGraphConnection = {
  id: string;
  source: string;
  target: string;
  sourceHandle?: string;
  targetHandle?: string;
  type: FlowGraphEdgeType;
  edgeData: {
    index: number;
    type: FlowGraphEdgeType;
    selfIndex: number;
    name: string;
    color: string;
    parentData: {
      index: number;
      id: string;
    };
  } & any;
};

export type FlowGraphProps = {
  id: string;
  style?: CSSProperties;
  children: ReactNode;
  context: Context<any>;
  ref?: Ref<any>;
} & ReactFlowProps;

export const FlowGraph = ({
  id,
  style,
  context,
  children,
  ref,
  ...rest
}: FlowGraphProps) => {
  const {
    entities,
    connections,
    onDeleteEdge,
    onDeleteNode,
    onConnect,
    onUpsertEdge,
    onClickNode,
    onClickEdge,
    edgeTypesMap,
    nodeTypesMap,
    layout,
    isConnectable,
    onClick,
    unconnectedEntities,
    onDropNode,
    draggingNode,
    draggedNodes,
  } = useContext(context);

  const [direction, setDirection] = useState(
    layout || LayoutDirection.TopToBottom
  );

  const { layoutedNodes, layoutedEdges } = useGraphElements({
    entities,
    connections,
    isConnectable,
    onDeleteEdge,
    onClickEdge,
    onDeleteNode,
    onClickNode,
    onConnect,
    onUpsertEdge,
    layout: direction,
    unconnectedEntities,
    draggedNodes,
  });

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [layoutedNodes, layoutedEdges, setNodes, setEdges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const { screenToFlowPosition } = useReactFlow();

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      onDropNode?.(draggingNode, position);
    },
    [screenToFlowPosition, draggingNode, onDropNode]
  );

  return (
    <div style={style} ref={ref}>
      <ReactFlow
        id={id}
        attributionPosition="top-right"
        fitView
        nodesConnectable={isConnectable}
        panOnScroll={true}
        snapToGrid={true}
        connectionMode={ConnectionMode.Loose}
        nodeTypes={nodeTypesMap as any as NodeTypes}
        edgeTypes={edgeTypesMap as any as EdgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        elementsSelectable={false}
        onClick={onClick}
        onDrop={onDrop}
        onDragOver={onDragOver}
        {...rest}
      >
        {children}
        <Panel position="top-right" style={{ top: -15 }}>
          <SwitchInput
            name="learners"
            options={[
              { label: 'Vertical', value: LayoutDirection.TopToBottom },
              {
                label: 'Horizontal',
                value: LayoutDirection.LeftToRight,
              },
            ]}
            initialActiveOption={direction}
            onChange={setDirection}
          />
        </Panel>
      </ReactFlow>
    </div>
  );
};

export const FlowGraphBackground = ({
  color = '#e5e5e7',
  gap = 50,
  size = 5,
}) => <Background color={color} gap={gap} size={size} />;

export const FlowGraphMap = ({
  style = { height: 100 },
  zoomable = true,
  pannable = true,
}) => <MiniMap style={style} zoomable={zoomable} pannable={pannable} />;

export const FlowGraphControls = ({ children }) => (
  <>
    <Controls />
    {children}
  </>
);

FlowGraph.Background = FlowGraphBackground;
FlowGraph.Map = FlowGraphMap;
FlowGraph.Controls = FlowGraphControls;
