import React, { useMemo } from 'react';

import { SingleSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

export const AdminLiveSessionVirtualLocationTypeSelect = ({
  onChange,
  name = 'virtual-location-type-select',
  initialValue = null,
  value = null,
  hasError = false,
}) => {
  const options = useMemo(() => {
    const options: { label: string; value: number }[] = [];
    if (C.FEATURES.msTeamsIntegrationEnabled) {
      options.push({
        label: 'Microsoft Teams',
        value: parseInt(C.I_VIRTUAL_LOCATION_TYPES.MS_TEAMS + ''),
      });
    }
    if (C.FEATURES.gmeetIntegrationEnabled) {
      options.push({
        label: 'Google Meet',
        value: parseInt(C.I_VIRTUAL_LOCATION_TYPES.GMEET + ''),
      });
    }

    return options;
  }, []);

  return (
    <SingleSelect
      onChange={onChange}
      options={options}
      placeholder="Select Virtual Location Type"
      isClearable={true}
      // @ts-ignore
      initialSelectedItem={initialValue}
      name={name}
      // @ts-ignore
      selectedItem={parseSelectedItem(value, options)}
      hasError={hasError}
    />
  );
};

const parseSelectedItem = (value, options) => {
  const parsedValue = value ? parseInt(value) : null;
  return options.find((o) => o.value === parsedValue);
};
