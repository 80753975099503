import { dateFns } from '@nl-lms/vendor';

import { TimeDelta } from '../constants';

export const getRelativeDate = (
  timeDelta: TimeDelta,
  _referenceDate?: Date,
  mode: 'add' | 'subtract' = 'add'
): Date => {
  const referenceDate = _referenceDate || new Date();
  let relativeDate =
    mode === 'add'
      ? dateFns.add(referenceDate, timeDelta)
      : dateFns.sub(referenceDate, timeDelta);

  if (timeDelta.settings) {
    const {
      utcOffset = 0,
      minute,
      hour,
      year,
      dayOfWeek,
      dayOfMonth,
      month,
      skipWeekends,
    } = timeDelta.settings;

    if (skipWeekends) {
      relativeDate = getDateOutsideOfWeekendDays(relativeDate, mode);
    }

    // 0-11
    if (month !== undefined) {
      relativeDate.setUTCMonth(month);
      const setMonth = relativeDate.getMonth();

      // the day value overflows the number of days in the month
      if (setMonth !== month) {
        const lastDayOfMonth = getLastDayOfMonth(
          new Date().getFullYear(),
          month
        );
        relativeDate.setUTCDate(lastDayOfMonth);
        relativeDate.setUTCMonth(month);
      }

      // delta was empty. prevent dates in the past
      // TODO: this will fail if it's a leap year and the day is 29th of February
      const diffInMonths =
        (relativeDate.getFullYear() - referenceDate.getFullYear()) * 12 +
        (relativeDate.getMonth() - referenceDate.getMonth());
      if (diffInMonths < 1) {
        relativeDate.setUTCFullYear(relativeDate.getFullYear() + 1);
      }
    }

    if (year !== undefined) {
      relativeDate.setUTCFullYear(year);
    }

    // (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    if (dayOfWeek !== undefined) {
      const currentDayOfWeek = relativeDate.getDay();
      const diff = dayOfWeek - currentDayOfWeek;
      relativeDate.setUTCDate(relativeDate.getDate() + diff);
      // Define the number of milliseconds in a day
      if (isTheSameDay(referenceDate, relativeDate)) {
        relativeDate.setUTCDate(relativeDate.getDate() + 7);
      }
    }

    if (dayOfMonth !== undefined) {
      const lastDayOfMonth = getLastDayOfMonth(
        relativeDate.getFullYear(),
        relativeDate.getMonth()
      );
      const parsedDayOfMonth =
        dayOfMonth > lastDayOfMonth ? lastDayOfMonth : dayOfMonth;
      relativeDate.setDate(parsedDayOfMonth);

      // Define the number of milliseconds in a day
      const millisecondsInDay = 24 * 60 * 60 * 1000;
      if (
        relativeDate.getTime() - referenceDate.getTime() <=
        millisecondsInDay
      ) {
        relativeDate.setUTCMonth(relativeDate.getMonth() + 1);
      }
    }

    if (minute !== undefined) {
      relativeDate.setUTCMinutes(minute);
    }

    if (hour !== undefined) {
      relativeDate.setUTCHours(hour);
    }

    // Don't take into account utc unless we have minutes or hours in settings as fixed hour criteria
    if (minute || hour) {
      relativeDate = dateFns.add(relativeDate, { minutes: utcOffset });
    }
  }
  return relativeDate;
};

const getDateOutsideOfWeekendDays = (
  date: Date,
  mode: 'add' | 'subtract'
): Date => {
  const day = date.getDay();
  if (day === 0 || day === 6) {
    const newDate =
      mode === 'add'
        ? dateFns.add(date, { days: 1 })
        : dateFns.sub(date, { days: 1 });
    return getDateOutsideOfWeekendDays(newDate, mode);
  }
  return date;
};

function getLastDayOfMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}

function isTheSameDay(referenceDate: Date, relativeDate: Date) {
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const referenceMidnight = dateFns.startOfDay(referenceDate).getTime();
  const relativeMidnight = dateFns.startOfDay(relativeDate).getTime();
  return relativeMidnight - referenceMidnight < millisecondsInDay;
}
