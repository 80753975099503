import React from 'react';

import './Skeleton.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type SkeletonProps = TidComponent<{
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}>;
export const Skeleton: React.FunctionComponent<SkeletonProps> = ({
  style = {},
  children,
  ...props
}) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('skeleton', props);
  
  return (
    <div {...classNameProps} {...commonProps} style={style}>
      {children}
    </div>
  );
};
