import { useEditorFocus, useMentionAtom, usePositioner } from '@remirror/react';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MentionAtomNodeAttributes, getPositioner } from 'remirror/extensions';

import { TextEditorContext } from './TextEditorContext';

export function TextEditorMentionsMenu() {
  const { mentionOptions: _options } = useContext(TextEditorContext);
  const [options, setOptions] = useState<MentionAtomNodeAttributes[]>([]);
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } =
    useMentionAtom({
      items: options,
    });
  const [isFocused] = useEditorFocus();
  const isMentionsActive = !!state;
  const {
    ref: menuContainerRef,
    active,
    height,
    x: left,
    y: top,
    width,
    visible,
  } = usePositioner(() => {
    const refinedPositioner = getPositioner('cursor');
    return refinedPositioner.active(isFocused);
  }, [isFocused, isMentionsActive]);
  useEffect(() => {
    if (!state) {
      return;
    }

    if (!_options) return;
    const searchTerm = state.query.full.toLowerCase();

    if (!searchTerm) {
      setOptions(_options);
      return;
    }
    const filteredOptions = _options
      .filter((option) => option.label.toLowerCase().includes(searchTerm))
      .sort()
      .slice(0, 5);
    setOptions(filteredOptions);
  }, [state, _options]);

  const enabled = !!state;

  const isOptionsMenuVisible = active && isMentionsActive;
  const containerStyle = useMemo<React.CSSProperties>(() => {
    if (!isOptionsMenuVisible) return {};
    return {
      top: `${top + height}px`,
      left: `${left}px`,
    };
  }, [isOptionsMenuVisible, top, height, left]);
  if (!options.length) return null;
  return (
    <span
      className="mentions-menu"
      style={containerStyle}
      ref={menuContainerRef}
    >
      {isOptionsMenuVisible ? (
        <div {...getMenuProps()} className="suggestions">
          {options.map((option, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);
            return (
              <div
                key={option.id}
                className={clsx(
                  'mentions-menu__option',
                  isHighlighted && 'mentions-menu__option--highlighted',
                  isHovered && 'mentions-menu__option--hovered'
                )}
                {...getItemProps({
                  item: option,
                  index,
                })}
              >
                {parseLabel(option.label)}
              </div>
            );
          })}
        </div>
      ) : null}
    </span>
  );
}

const parseLabel = (label) => {
  if (!label) return label;
  const matchedLabel = label.match(/{{([^}]+)}}/);
  return matchedLabel[1] || label;
};
