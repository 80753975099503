import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateFieldMetadata,
  FieldType,
  FieldTypeToOperatorsRecord,
  OperatorToLabelRecord,
  QueryOperator,
} from '@nl-lms/common/shared';
import { DateTime } from '@nl-lms/ui/components';
import { dateFns } from '@nl-lms/vendor';
import { timeSuggest } from '@nl-lms/vendor';

import {
  DateValueOption,
  FilterBarFilteringState,
  FilterBarOptionsComponentProps,
  OperatorOption,
} from '../FilterBar.types';
import { getOptionClassName, getOptionId } from '../filterUtils';

const suggest = timeSuggest.create({
  allow_someday: false,
  allow_past_dates: true,
  results_count: 3,
});

export const FilterBarDateFieldSuggestOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<DateFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const results = suggest(inputValue);
  if (!results.length) return null;

  const optionLabel = t(
    // @ts-ignore
    `common.filterbar.${OperatorToLabelRecord[QueryOperator.GreaterThanEqual]}`
  );
  const option: DateValueOption = {
    id: getOptionId(
      `${QueryOperator.GreaterThanEqual}-${results[0].date.toISOString()}`,
      field.name,
      'DateValueOption'
    ),
    type: 'DateValueOption',
    field,
    operator: QueryOperator.GreaterThanEqual,
    value: results[0].date.toISOString(),
    label: `${optionLabel} "${results[0].formatted}"`,
  };

  return (
    <>
      <span className="filter-bar__option-separator">{field.label}</span>
      <li
        className={getOptionClassName(option.id === activeOptionId)}
        {...getOptionProps(option, option.id)}
      >
        {option.label}
      </li>
    </>
  );
};

export const FilterBarDateFieldOptions = ({
  filteringState,
  field,
  activeOptionId,
  activeFilter,
  getOptionProps,
  onSelectOption,
}: FilterBarOptionsComponentProps<DateFieldMetadata>) => {
  const { t } = useTranslation('learner');
  const onSelectDateOption = useCallback(
    (e) => {
      onSelectOption({
        id: getOptionId(
          `${activeFilter?.operator}-${e.target.value}`,
          field.name,
          'DateValueOption'
        ),
        type: 'DateValueOption',
        value: dateFns.startOfDay(new Date(e.target.value)).toISOString(),
        label: e.target.label,
        // @ts-ignore
        operator: activeFilter?.operator,
        field: activeFilter?.field as DateFieldMetadata,
      });
    },
    [activeFilter]
  );

  const operatorOptions = useMemo<OperatorOption[]>(() => {
    return FieldTypeToOperatorsRecord[FieldType.date].map((operator) => ({
      id: getOptionId(operator, field.name, 'OperatorOption'),
      type: 'OperatorOption',
      value: operator,
      // @ts-ignore
      label: t(`common.filterbar.${OperatorToLabelRecord[operator]}`) as string,
      field,
    }));
  }, [field]);

  return (
    <>
      <DateTime onChange={onSelectDateOption} showTimeSelect={false} date={activeFilter?.value} inline />
      {filteringState === FilterBarFilteringState.EditingFilter ? (
        <>
          <span className="filter-bar__option-separator">Operators</span>
          {operatorOptions.map((option) => (
            <li
              key={option.id}
              className={getOptionClassName(option.id === activeOptionId)}
              {...getOptionProps(option, option.id)}
            >
              {option.label}
            </li>
          ))}
        </>
      ) : null}
    </>
  );
};
