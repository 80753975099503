import React from 'react';
import { InlineDotList, PrettyDate, Typography } from '../';

import './ScheduleDayHeader.scss';
import { translateDurationInHours } from '@nl-lms/ui/utils';
import i18n from 'i18next';
import { useTestProps } from '@nl-lms/ui/hooks';

export const ScheduleDayHeader = ({
  index,
  endDate,
  startDate,
  schedule,
  withDates,
  ...props
}) => {
  const commonProps = useTestProps(props);

  return (
    <div className="schedule-day-header" {...commonProps}>
      <div className="schedule-day-header__title">
        <Typography.h3>
          <InlineDotList>
            <span>
              {i18n.t('learner:common.time.theday')} {index + 1}
            </span>
            {!!endDate && !!startDate && withDates && (
              <PrettyDate withDate value={startDate} />
            )}
          </InlineDotList>
        </Typography.h3>
      </div>
      <div className="schedule-day-header__hours">
        <Typography.h3 type="muted">
          <InlineDotList>
            {!!endDate && !!startDate && withDates && (
              <span>
                <PrettyDate withTime withDate={false} value={startDate} /> -{' '}
                <PrettyDate withTime withDate={false} value={endDate} />
              </span>
            )}

            <span>
              {translateDurationInHours(
                schedule.reduce(
                  (acc, scheduleBlock) => acc + scheduleBlock.duration,
                  0
                )
              )}
            </span>
          </InlineDotList>
        </Typography.h3>
      </div>
    </div>
  );
};
