import * as Progress from '@radix-ui/react-progress';
import React from 'react';

import './ProgressBar.scss';

export const ProgressBar = ({ value }: { value: number }) => {
  const parsedValue = value > 100 ? 100 : value;
  return (
    <Progress.Root className="progress-bar" value={parsedValue}>
      <Progress.Indicator
        className="progress-bar__indicator"
        style={{ transform: `translateX(-${100 - parsedValue}%)` }}
      />
    </Progress.Root>
  );
};
