import { z } from 'zod';

export const SubscriptionSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  tagIds: z.array(z.string()).optional(),
  vendorId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Subscription = z.infer<typeof SubscriptionSchema>;

export const SubscriptionInstanceSchema = z.object({
  id: z.string(),

  startDate: z.date(),
  endDate: z.date(),

  learnerId: z.string(),
  subscriptionId: z.string(),

  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SubscriptionInstance = z.infer<typeof SubscriptionInstanceSchema>;
