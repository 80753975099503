import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { ReportsRouterContract } from './report.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'reportsStore';

  const tsRest = buildTsRestInstance<typeof ReportsRouterContract>(
    apiInstance,
    ReportsRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listReports: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<z.infer<typeof ReportsRouterContract.listReports.query>>
      >,
      z.infer<(typeof ReportsRouterContract.listReports.responses)['200']>
    >;
    generateReport: RtkMutation<
      RtkFullParams<z.infer<typeof ReportsRouterContract.generateReport.body>>,
      z.infer<(typeof ReportsRouterContract.generateReport.responses)['200']>
    >;
    getReportData: RtkQuery<
      RtkFullParams<z.infer<typeof ReportsRouterContract.getReportData.body>>,
      z.infer<(typeof ReportsRouterContract.getReportData.responses)['200']>
    >;
    scheduleReport: RtkMutation<
      RtkFullParams<z.infer<typeof ReportsRouterContract.scheduleReport.body>>,
      z.infer<(typeof ReportsRouterContract.scheduleReport.responses)['200']>
    >;
    updateReport: RtkMutation<
      RtkFullParams<
        {},
        z.infer<typeof ReportsRouterContract.updateReport.body>,
        z.infer<typeof ReportsRouterContract.updateReport.pathParams>
      >,
      z.infer<(typeof ReportsRouterContract.updateReport.responses)['200']>
    >;
    deleteReports: RtkMutation<
      RtkFullParams<z.infer<typeof ReportsRouterContract.deleteReports.body>>,
      z.infer<(typeof ReportsRouterContract.deleteReports.responses)['200']>
    >;
    listReportTemplates: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.listReportTemplates.query>
        >
      >,
      z.infer<
        (typeof ReportsRouterContract.listReportTemplates.responses)['200']
      >
    >;
    selectReportTemplates: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.selectReportTemplates.query>
        >
      >,
      z.infer<
        (typeof ReportsRouterContract.selectReportTemplates.responses)['200']
      >
    >;
    createReportTemplate: RtkMutation<
      RtkFullParams<
        z.infer<typeof ReportsRouterContract.createReportTemplate.body>
      >,
      z.infer<
        (typeof ReportsRouterContract.createReportTemplate.responses)['200']
      >
    >;
    updateReportTemplate: RtkMutation<
      RtkFullParams<
        {},
        z.infer<typeof ReportsRouterContract.updateReportTemplate.body>,
        z.infer<typeof ReportsRouterContract.updateReportTemplate.pathParams>
      >,
      z.infer<
        (typeof ReportsRouterContract.updateReportTemplate.responses)['200']
      >
    >;
    updateReportTemplateColumns: RtkMutation<
      RtkFullParams<
        z.infer<typeof ReportsRouterContract.updateReportTemplateColumns.body>,
        z.infer<
          typeof ReportsRouterContract.updateReportTemplateColumns.pathParams
        >
      >,
      z.infer<
        (typeof ReportsRouterContract.updateReportTemplateColumns.responses)['200']
      >
    >;
    deleteReportTemplates: RtkMutation<
      RtkFullParams<
        z.infer<typeof ReportsRouterContract.deleteReportTemplates.body>
      >,
      z.infer<
        (typeof ReportsRouterContract.deleteReportTemplates.responses)['200']
      >
    >;
    listLiveCourseStatistics: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.listLiveCourseStatistics.query>
        >,
        {},
        {}
      >,
      z.infer<
        (typeof ReportsRouterContract.listLiveCourseStatistics.responses)['200']
      >
    >;
    getLiveCourseNps: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.getLiveCourseNps.query>
        >,
        {},
        {}
      >,
      z.infer<(typeof ReportsRouterContract.getLiveCourseNps.responses)['200']>
    >;
    listLearnerStatistics: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.listLearnerStatistics.query>
        >,
        {},
        {}
      >,
      z.infer<
        (typeof ReportsRouterContract.listLearnerStatistics.responses)['200']
      >
    >;
    getLearnerAttendance: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.getLearnerAttendance.query>
        >,
        {},
        {}
      >,
      z.infer<
        (typeof ReportsRouterContract.getLearnerAttendance.responses)['200']
      >
    >;
    getLearnerLearnedTime: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ReportsRouterContract.getLearnerLearnedTime.query>
        >,
        {},
        {}
      >,
      z.infer<
        (typeof ReportsRouterContract.getLearnerLearnedTime.responses)['200']
      >
    >;
  }>(tsRest, ReportsRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
