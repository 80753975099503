import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { ElearningRouterContractSdk } from './elearning.router-contract.sdk';

export const getElearningClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'elearningStore';

  const tsRest = buildTsRestInstance<typeof ElearningRouterContractSdk>(
    apiInstance,
    ElearningRouterContractSdk,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    getElearningCourse: RtkQuery<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.getElearningCourse.pathParams>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.getElearningCourse.responses)['200']
      >
    >;
    updateElearningCourse: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.updateElearningCourse.body>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.updateElearningCourse.responses)['200']
      >
    >;
    createElearningCourse: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.createElearningCourse.body>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.createElearningCourse.responses)['200']
      >
    >;
    removeElearningCourses: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.removeElearningCourses.body>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.removeElearningCourses.responses)['200']
      >
    >;
    listElearningCourses: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ElearningRouterContractSdk.listElearningCourses.query>
        >
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.listElearningCourses.responses)['200']
      >
    >;
    listContentFiles: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof ElearningRouterContractSdk.listContentFiles.query>
        >
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.listContentFiles.responses)['200']
      >
    >;
    createContentFile: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.createContentFile.body>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.createContentFile.responses)['200']
      >
    >;
    removeContentFiles: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.removeContentFiles.body>
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.removeContentFiles.responses)['200']
      >
    >;
    validateContentFile: RtkMutation<
      RtkFullParams<
        z.infer<typeof ElearningRouterContractSdk.validateContentFile.body>,
        z.infer<
          typeof ElearningRouterContractSdk.validateContentFile.pathParams
        >
      >,
      z.infer<
        (typeof ElearningRouterContractSdk.validateContentFile.responses)['200']
      >
    >;
  }>(tsRest, ElearningRouterContractSdk, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
