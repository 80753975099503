import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  AssessmentFormItem,
  AssessmentInstance,
  AssessmentInstanceAttempt,
  Assessment as IAssessment,
} from './types';
import { IApiService } from './types';

export class Assessment
  extends ApiService
  implements IApiService<'assessment'>
{
  public paths: any;
  public serviceBase: string = '/assessment/form';

  constructor(props) {
    super(props);

    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      getScoredAttempt: (id) =>
        `${this.serviceBase}/instance/${id}/attempt/scored`,
      list: () => `${this.serviceBase}`,
      listForSelect: () => `/list/assessment/form`,
      scoreAttempt: (instanceId, attemptId) =>
        `${this.serviceBase}/instance/${instanceId}/attempt/${attemptId}/score`,
      listInstances: () => `${this.serviceBase}/instance`,
      listInstancesByAssessmentId: (id) => `${this.serviceBase}/${id}/instance`,
      create: () => `${this.serviceBase}`,
      update: (id) => `${this.serviceBase}/${id}`,
      getItems: (id) => `${this.serviceBase}/${id}/item`,
      addItems: (id) => `${this.serviceBase}/${id}/item`,
      reorderItems: (id) => `${this.serviceBase}/${id}/item/reorder`,
      removeItems: (id) => `${this.serviceBase}/${id}/item`,
      removeInstances: () => `${this.serviceBase}/instance`,
      remove: (id) => `${this.serviceBase}/${id}`,
      bulkDelete: () => `${this.serviceBase}`,
      listInstanceAttempts: (id) =>
        `${this.serviceBase}/instance/${id}/attempt/all`,
      getAttemptById: (instanceId, attemptId) =>
        `${this.serviceBase}/instance/${instanceId}/attempt/${attemptId}`,
      removeInstanceId: (instanceId, instanceAttemptId) =>
        `${this.serviceBase}/instance/${instanceId}/attempt/${instanceAttemptId}`,
    };
  }

  get = (id) => this.api.get(this.paths.get(id)) as Promise<IAssessment>;

  getScoredAttempt = ({ id }) =>
    this.api.get(
      this.paths.getScoredAttempt(id)
    ) as Promise<AssessmentInstance>;

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), {
      params: { query },
    }) as Promise<IAssessment[]>;

  listForSelect = (input) =>
    this.api.get(this.paths.listForSelect(), {
      params: { query: { name: input } },
    }) as Promise<IAssessment[]>;

  listInstances = (query: AppQuery) =>
    this.api.get<null, { rows: AssessmentInstance[]; count: number }>(
      this.paths.listInstances(),
      {
        params: { query },
      }
    );

  listInstancesByAssessmentId = ({
    id,
    query,
  }: {
    id: string;
    query: AppQuery;
  }) =>
    this.api.get<null, { rows: AssessmentInstance[]; count: number }>(
      this.paths.listInstancesByAssessmentId(id),
      {
        params: { query },
      }
    );

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  getItems = ({ id, query }: { id: string; query: AppQuery }) =>
    this.api.get(this.paths.getItems(id), {
      params: { query },
    }) as Promise<AssessmentFormItem[]>;

  addItems = ({ id, items }) =>
    this.api.patch(this.paths.addItems(id), { items: snakelize(items) });

  reorderItems = ({ id, items }) =>
    this.api.patch(this.paths.reorderItems(id), { item_ids: items });

  removeItems = ({ id, itemIds }) =>
    this.api.delete(this.paths.removeItems(id), {
      data: { item_ids: itemIds },
    });

  removeInstances = (ids) =>
    this.api.delete(this.paths.removeInstances(), {
      data: { list: ids },
    });

  remove = ({ id }) => this.api.delete(this.paths.remove(id));

  listInstanceAttempts = ({ id }) =>
    this.api.get(this.paths.listInstanceAttempts(id)) as Promise<
      AssessmentInstanceAttempt[]
    >;

  scoreAttempt = ({ instanceId, attemptId, answers }) =>
    this.api.post(this.paths.scoreAttempt(instanceId, attemptId), {
      answers: snakelize(answers),
    });

  removeInstanceAttempt = ({ instanceId, attemptId }) =>
    this.api.delete(this.paths.removeInstanceId(instanceId, attemptId));

  getAttemptById = ({ instanceId, attemptId }) =>
    this.api.get<null, AssessmentInstance>(
      this.paths.getAttemptById(instanceId, attemptId)
    );
}
