import React, { ReactNode } from 'react';

import {
  Badge,
  BadgeGroup,
  BadgeTypes,
  Box,
  Icon,
  InlineDotList,
  Separator,
  Tooltip,
} from '@nl-lms/ui/components';

import { CardIconCaption } from '../CardIconCaption/CardIconCaption';
import { InfoBanner, LearningBanner } from '../LearningBanner/LearningBanner';
import './LearnerLearningCard.scss';

const Skeleton = ({ className = '' }) => (
  <div className={`learner-learning-card ${className}`}>
    <div className="learner-learning-card__skeleton learner-learning-card__skeleton-thumbnail">
      &zwnj;
    </div>
    <Content>
      <Header>
        <div className="learner-learning-card__skeleton learner-learning-card__skeleton-title">
          &zwnj;
        </div>
      </Header>
      <Info>
        <div className="learner-learning-card__skeleton learner-learning-card__skeleton-content">
          &zwnj;
        </div>
      </Info>
    </Content>
    <div className="learner-learning-card__skeleton learner-learning-card__skeleton-action">
      &zwnj;
    </div>
  </div>
);

export const LearnerLearningCard = ({
  children,
  className = '',
  isLoading = false,
  disabled = false,
}) => {
  if (isLoading) return <Skeleton className={className} />;

  let banner: ReactNode;
  let thumbnail = <Thumbnail imageUrl="" type="" />;
  let title = <Title>Title missing</Title>;
  let description: ReactNode;
  let tags: ReactNode;
  let rating: ReactNode;
  const actions: ReactNode[] = [];
  const captions: ReactNode[] = [];
  const details: ReactNode[] = [];
  const subDetails: ReactNode[] = [];

  const parsedChildren = (
    Array.isArray(children) ? children : [children]
  ).flat();

  parsedChildren.forEach((child) => {
    if (!child) return;

    if (child.type === InfoBanner || child.type === LearningBanner)
      banner = child;
    else if (child.type === Thumbnail) thumbnail = child;
    else if (child.type === Title) title = child;
    else if (child.type === Description) description = child;
    else if (child.type === Detail) details.push(child);
    else if (child.type === SubDetail) subDetails.push(child);
    else if (child.type === Tags) tags = child;
    else if (child.type === CardIconCaption) captions.push(child);
    else if (child.type === Rating) rating = child;
    else if (child.type === Action) actions.push(child);
  });

  return (
    <div
      className={`learner-learning-card ${className || ''} ${
        disabled && 'learner-learning-card--disabled'
      }`}
    >
      <div className="learner-learning-card__banner">{banner}</div>
      {thumbnail}
      <Content>
        <Header>
          {!!captions.length && (
            <InlineDotList className="learner-learning-card__captions">
              {captions}
            </InlineDotList>
          )}
          {title}
          {!!tags && tags}
          {!!details.length && (
            <InlineDotList className="learner-learning-card__details">
              {details}
            </InlineDotList>
          )}
          {!!subDetails.length && (
            <InlineDotList className="learner-learning-card__details">
              {subDetails}
            </InlineDotList>
          )}
        </Header>
        <Info>
          {description}
          {rating}
        </Info>
      </Content>
      <Box padding={{ left: 'm', right: 'm' }}>
        <Separator margin={0} />
      </Box>

      {!!actions.length && (
        <div className="learner-learning-card__actions">{actions}</div>
      )}
    </div>
  );
};

enum ThumbnailType {
  Assessment,
  Elearning,
  InClass,
  LearningPath,
  Survey,
  Checklist,
}

const Thumbnail = ({ imageUrl, type }) => (
  <div
    className="learner-learning-card__image"
    style={{ backgroundImage: `url("${imageUrl}")` }}
  ></div>
);

const Content = ({ children }) => (
  <div className="learner-learning-card__content">{children}</div>
);

const Header = ({ children }) => (
  <div className="learner-learning-card__header">{children}</div>
);

const Info = ({ children }) => (
  <div className="learner-learning-card__info">{children}</div>
);

const Title = ({ children }) => (
  <div className="learner-learning-card__title">
    <Tooltip title={children}>{children}</Tooltip>
  </div>
);

const Detail = ({ children }) => {
  return <div className="learner-learning-card__detail">{children}</div>;
};

const SubDetail = ({ children }) => {
  return <div className="learner-learning-card__detail">{children}</div>;
};

const Description = ({ description }) => (
  <div
    className="learner-learning-card__description"
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

const Action = ({ children }) => (
  <div className="learner-learning-card__action">{children}</div>
);

const Rating = ({ children }) => (
  <div className="learner-learning-card__rating">{children}</div>
);

const Tags = ({ children, type = BadgeTypes.LIGHT }) => {
  if (!children) return null;
  const parsedChildren = Array.isArray(children) ? children : [children];
  return (
    <div className="learner-learning-card__tags">
      <div className="learner-learning-card__tags-icon">
        <Icon.TagIcon />
      </div>
      <div className="learner-learning-card__tags-list">
        <BadgeGroup name="learningActivityCardTags" type={type}>
          {parsedChildren.map((label, index) => (
            <Badge label={label} type={type} key={index} />
          ))}
        </BadgeGroup>
      </div>
    </div>
  );
};

const LearnerLearningCardDuration = ({ duration }) => (
  <div className="learner-learning-card__duration">
    <div className="learner-learning-card__duration-icon">
      <Icon.ClockIcon />
    </div>
    <div className="learner-learning-card__duration-value">{duration}</div>
  </div>
);

LearnerLearningCard.ThumbnailType = ThumbnailType;

LearnerLearningCard.Thumbnail = Thumbnail;
LearnerLearningCard.Content = Content;
LearnerLearningCard.Title = Title;
LearnerLearningCard.Detail = Detail;
LearnerLearningCard.SubDetail = SubDetail;
LearnerLearningCard.Description = Description;
LearnerLearningCard.Action = Action;
LearnerLearningCard.Tags = Tags;
LearnerLearningCard.Rating = Rating;
LearnerLearningCard.Duration = LearnerLearningCardDuration;
LearnerLearningCard.Skeleton = Skeleton;

export default LearnerLearningCard;
