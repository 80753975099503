import { useMemo, useCallback, useEffect, useState } from 'react';

export const useTimer = (onElapsedTime, timeLimit = 5) => {
  // TODO check for memory leaks - error is thrown when multiple notifications are shown
  const [elapsedTime, setElapsedTime] = useState(0);
  const startDate = useMemo(() => new Date(), []);
  const [wasOnElapsedCalled, setWasOnElapsedCalled] = useState(false);

  const tick = useCallback(() => {
    if (timeLimit === 0) return;
    if (wasOnElapsedCalled) return false;
    if (elapsedTime > timeLimit) {
      onElapsedTime();
      setWasOnElapsedCalled(true);
    } else {
      // @ts-ignore
      setElapsedTime((new Date() - startDate) / 1000);
    }
  }, [
    startDate,
    onElapsedTime,
    elapsedTime,
    timeLimit,
    wasOnElapsedCalled,
    setWasOnElapsedCalled,
  ]);

  useEffect(() => {
    const timer = setInterval(() => tick(), 50);
    return () => clearInterval(timer);
  }, [tick]);

  return {
    elapsedTime,
    progress: timeLimit ? (elapsedTime * 100) / timeLimit : 100,
  };
};
