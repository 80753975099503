import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { IApiService } from './types';

export class CostType extends ApiService implements IApiService<'costType'> {
  public paths: any;
  public serviceBase: string = `/resource/cost_type`;

  constructor(props) {
    super(props);
    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => this.serviceBase,
      bulkDelete: () => this.serviceBase,
      create: () => this.serviceBase,
      update: (id) => `${this.serviceBase}/${id}`,
      remove: (id) => `${this.serviceBase}/${id}`,
    };
  }

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), { params: { query } });

  bulkDelete = (ids) =>
    this.api.delete(`${this.paths.bulkDelete()}`, { data: { list: ids } });

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };
}
