import { useCallback } from 'react';

import {
  AppQueryFilter,
  QueryFilter,
  QueryOperator,
  transformFilter,
} from '@nl-lms/common/shared';

import { UseActionProps, useAction } from './useAction';

interface RemoveEntityProps {
  baseFilters?: AppQueryFilter;
  withRowCountProp?: boolean;
}

export function useTableAction<T extends (...args: any) => Promise<unknown>>(
  method: T,
  props: UseActionProps<any> & RemoveEntityProps = {}
) {
  const {
    showConfirmation = true,
    showNotification = true,
    confirmationMessage = 'Are you sure you want to perform this action',
    alertMessage,
    successMessage = 'Action performed successfully on selected rows',
    baseFilters = undefined,
    withRowCountProp = false,
  } = props;

  const action = useAction(method, {
    showConfirmation,
    confirmationMessage,
    alertMessage,
    successMessage,
    showNotification,
  });

  return useCallback(
    async (
      selectedRows,
      allRowsAreSelected?: boolean,
      excludedRows?: Record<string, any>[],
      selectedRowsCount?: number
    ) => {
      let params;
      if (!allRowsAreSelected) {
        // The function can be called from either table actions or row actions
        const selectedIds = Array.isArray(selectedRows)
          ? selectedRows.map((r) => r.id)
          : [selectedRows.id];
        params = withRowCountProp
          ? { list: selectedIds, count: selectedIds.length }
          : selectedIds;
      } else {
        const filters = new QueryFilter({ filters: baseFilters });
        if (excludedRows?.length) {
          filters.add({
            field: 'id',
            operator: QueryOperator.Excludes,
            value: excludedRows?.map((r) => r.id) || [],
          });
        }
        params = withRowCountProp
          ? {
              list: transformFilter(filters.appQueryFilter),
              count: selectedRowsCount,
            }
          : filters.apiQueryFilter;
      }

      // @ts-expect-error
      const result = await action(params);
      // @ts-expect-error
      if (result?.data) return true;
      return false;
    },
    [baseFilters, method]
  );
}
