import { z } from 'zod';

import {
  ContentFileSchema,
  ElearningCourse,
  ElearningCourseHistorySchema,
  ElearningCourseSchema,
  ElearningSessionStatus,
  UserSchema,
  WithTagsCompetenciesTargetLearnerGroupsSchema,
} from '@nl-lms/common/feature/types';

export const GetElearningCourseSchema =
  WithTagsCompetenciesTargetLearnerGroupsSchema(ElearningCourseSchema);

export const ListElearningCourseSchema = z.object({
  ...WithTagsCompetenciesTargetLearnerGroupsSchema(ElearningCourseSchema).shape,
  user: UserSchema.pick({ firstName: true, lastName: true, id: true }),
  elearningCourseHistory: ElearningCourseHistorySchema.pick({
    version: true,
  }),
  contentFile: ContentFileSchema.pick({ isReady: true, name: true, id: true }),
});

export const UpdateElearningCourseSchema = ElearningCourseSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  userId: true,
  contentFileType: true,
  customerInternalId: true,
  contentFileVersion: true,
  elearningCourseHistoryId: true,
  deletedAt: true,
})
  .extend({
    feedbackFormId: z.string().nullable(),
    learningAssignmentIds: z.array(z.string()),
    assignForStatuses: z.array(z.nativeEnum(ElearningSessionStatus)),
  })
  .partial();

export const CreateElearningCourseSchema = ElearningCourseSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  userId: true,
  contentFileType: true,
  elearningCourseHistoryId: true,
  customerInternalId: true,
  contentFileVersion: true,
  deletedAt: true,
  rating: true,
  ratingCount: true,
}).extend({
  feedbackFormId: z.string().nullable().optional(),
  targetLearnerGroupIds: z.array(z.string()).default([]),
});
