import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';

import { useBroadcastChannel } from '@nl-lms/ui/hooks';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { actions, selectors } from './learnerAssessmentPlayerSlice';

const {
  useLazyLearnerAssessmentInstanceAttemptQuery,
  useStartAssessmentAttemptMutation,
  useEndAssessmentAttemptMutation,
} = learnerApi;

export const useStartAttempt = (assessmentInstanceId) => {
  const dispatch = useDispatch();

  const [
    learnerAssessmentInstanceAttempt,
    { isLoading: isFetchAttemptLoading },
  ] = useLazyLearnerAssessmentInstanceAttemptQuery();

  const [startAssessmentAttempt, { isLoading: isStartAttemptLoading }] =
    useStartAssessmentAttemptMutation();

  const startAssessmentAttemptAction = useAction(startAssessmentAttempt);

  const onStartAttempt = useCallback(async () => {
    if (!authStore.learnerId) return;

    const result = await startAssessmentAttemptAction({
      assessmentInstanceId,
      learnerId: authStore.learnerId,
    });
    if (!result?.data) return;

    const fetchResult = await learnerAssessmentInstanceAttempt({
      assessmentInstanceId,
      learnerId: authStore.learnerId,
      rnd: new Date().getTime(),
    });

    if (!fetchResult?.data) return;

    dispatch(actions.startedAssessmentAttempt(fetchResult.data));
  }, [
    dispatch,
    assessmentInstanceId,
    learnerAssessmentInstanceAttempt,
    startAssessmentAttempt,
  ]);

  return {
    isLoading: isStartAttemptLoading || isFetchAttemptLoading,
    onStartAttempt,
  };
};

const getAssessmentFormStorageKey = (assessmentId) =>
  `LEARNER_ASSESSMENT_PLAYER_FORM_ANSWERS_${assessmentId}`;

export const useEndAttempt = () => {
  const dispatch = useDispatch();
  const assessmentInstanceId = useSelector(selectors.getAssessmentId);
  const questionAnswers = useSelector(selectors.getUserAnswers);
  const [endAssessmentAttempt, { isLoading }] =
    useEndAssessmentAttemptMutation();
  const endAssessmentAttemptAction = useAction(endAssessmentAttempt);

  const { broadcastMessage } = useBroadcastChannel({
    channel: 'Assessment Player',
  });
  const storeKey = getAssessmentFormStorageKey(assessmentInstanceId);
  const onEndAttempt = useCallback(
    async ({ timeSpent }) => {
      if (!authStore.learnerId) return;

      const answers = Object.keys(questionAnswers)
        .filter((k) => k !== 'undefined')
        .map((questionId) => ({
          questionId,
          answer: questionAnswers[questionId].map((a) => ` ${a}`),
        }));

      const result = await endAssessmentAttemptAction({
        assessmentInstanceId,
        learnerId: authStore.learnerId,
        answers,
        timeSpent,
      });

      if (result?.data) {
        store.remove(storeKey);
        // @ts-expect-error
        dispatch(actions.endedAttempt(result.data));
      }

      broadcastMessage('CLOSED');
    },
    [assessmentInstanceId, questionAnswers, storeKey]
  );

  return {
    isLoading,
    onEndAttempt,
  };
};

export const usePersistAssessmentFormAnswers = () => {
  const dispatch = useDispatch();
  const [isFormHydrated, setIsFormHydrated] = useState(false);
  const answers = useSelector(selectors.getUserAnswers);
  const assessmentId = useSelector(selectors.getAssessmentId);
  const storeKey = getAssessmentFormStorageKey(assessmentId);
  const remainingTime = useSelector(selectors.getRemainingAttemptTime);
  useEffect(() => {
    const prevAnswersString = store.get(storeKey);
    // If the user returns to the form after it was closed and the time has expired
    // previous answers need to be ignores since the form has already been submitted by the automation
    if (remainingTime === 0) {
      store.set(storeKey, null);
      return;
    }
    if (prevAnswersString) {
      try {
        dispatch(actions.userUpdatedAnswers(JSON.parse(prevAnswersString)));
        setIsFormHydrated(true);
      } catch (e) {
        console.error(e);
      }
    } else {
      setIsFormHydrated(true);
    }
  }, [storeKey, dispatch, setIsFormHydrated, remainingTime]);

  useEffect(() => {
    try {
      if (isFormHydrated) {
        store.set(storeKey, JSON.stringify(answers));
      }
    } catch (e) {
      console.error(e);
    }
  }, [answers, storeKey, isFormHydrated]);
};
