import React, { useCallback, useState } from 'react';

import { QueryOperator } from '@nl-lms/common/shared';
import { Input, Sensitive, Textarea } from '@nl-lms/ui/components';
import { useTestProps } from '@nl-lms/ui/hooks';

import { formatConstantString } from '../../utils/formatConstantString';
import { Button } from '../Button/Button';
import { FormField } from '../FormField/FormField';
import { Modal, useModal } from '../Modal/Modal';
import { useShowModal } from '../Modal/ModalManager';
import { Select } from '../Select/Select';
import { TidComponent } from '../index.types';

export type ListParserModalReturnType = {
  list: string[];
  field: string;
  operator: QueryOperator;
};
export type ListParserModalProps = TidComponent<{
  fieldOptions?: { value: string; label: string }[];
  title?: string;
}>;
export const useListParserModal = () =>
  useShowModal<ListParserModalReturnType, ListParserModalProps>(
    ListParserModal
  );

export const ListParserModal = ({
  fieldOptions = [],
  title = 'Paste a List',
  ...props
}: ListParserModalProps) => {
  const commonProps = useTestProps(props, { passThrough: true });

  const [errors, setErrors] = useState<{
    separator?: string;
    content?: string;
    field?: string;
  }>({});
  const { hide } = useModal<ListParserModalReturnType>();
  const onSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      separator: { value: string };
      content: { value: string };
      operator: { value: string };
      field: { value: string };
    };

    if (fieldOptions.length && !target.field?.value) {
      setErrors({ field: 'Field is required' });
      return;
    }
    if (!target.content.value) {
      setErrors({ content: 'Content is required' });
      return;
    }

    const parsedField = target.field ? target.field.value : null;
    const parsedSeparator = target.separator.value || '\n';
    const parsedContent = target.content.value
      .split(parsedSeparator)
      .map((l) => l.trim())
      .filter((l) => !!l);

    const operator = target.operator.value;

    // @ts-ignore
    hide({ list: parsedContent, field: parsedField, operator });
  }, []);

  const operatorOptions = [
    QueryOperator.Like,
    QueryOperator.Equals,
    QueryOperator.NEqual,
  ].map((v) => ({
    value: v,
    label: formatConstantString(QueryOperator[v]),
  }));

  return (
    <Modal.Content scrollable {...commonProps}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        {fieldOptions.length ? (
          <FormField label="Field" errorMessage={errors.field} required>
            <Select
              options={fieldOptions}
              name="field"
              initialValue={fieldOptions[0].value}
              hasError={!!errors.content}
            />
          </FormField>
        ) : null}
        <FormField label="Content" errorMessage={errors.content} required>
          <Sensitive>
            <Textarea
              name="content"
              autofocus
              hasError={!!errors.content}
              placeholder="Paste here the actual list that should be processed"
            />
          </Sensitive>
        </FormField>
        <FormField
          label="Separator"
          errorMessage={errors.separator}
          helpText="Leave empty if the list is separated by new lines"
        >
          <Input
            placeholder="Leave blank for newline"
            name="separator"
            hasError={!!errors.separator}
          />
        </FormField>
        <FormField label="Operator" errorMessage={errors.field} required>
          <Select
            options={operatorOptions}
            name="operator"
            initialValue={QueryOperator.Like}
            hasError={!!errors.content}
          />
        </FormField>
        <Modal.Actions>
          <Button label="Submit" type="submit" />
        </Modal.Actions>
      </form>
    </Modal.Content>
  );
};
