import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { NotificationsRouterContract } from './notification.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'notificationsStore';

  const tsRest = buildTsRestInstance<typeof NotificationsRouterContract>(
    apiInstance,
    NotificationsRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listNotifications: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof NotificationsRouterContract.listNotifications.query>
        >
      >,
      z.infer<
        (typeof NotificationsRouterContract.listNotifications.responses)['200']
      >
    >;
    listNotificationTemplates: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof NotificationsRouterContract.listNotificationTemplates.query
          >
        >
      >,
      z.infer<
        (typeof NotificationsRouterContract.listNotificationTemplates.responses)['200']
      >
    >;
    sendNotifications: RtkMutation<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.sendNotifications.body>
      >,
      z.infer<
        (typeof NotificationsRouterContract.sendNotifications.responses)['200']
      >
    >;
    scheduleNotifications: RtkMutation<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.scheduleNotifications.body>
      >,
      z.infer<
        (typeof NotificationsRouterContract.scheduleNotifications.responses)['200']
      >
    >;
    renderNotification: RtkMutation<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.renderNotification.body>
      >,
      z.infer<
        (typeof NotificationsRouterContract.renderNotification.responses)['200']
      >
    >;
    removeNotifications: RtkMutation<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.removeNotifications.body>
      >,
      z.infer<
        (typeof NotificationsRouterContract.removeNotifications.responses)['200']
      >
    >;
    rescheduleNotifications: RtkMutation<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.rescheduleNotifications.body>
      >,
      z.infer<
        (typeof NotificationsRouterContract.rescheduleNotifications.responses)['200']
      >
    >;
    sampleReceivers: RtkQuery<
      RtkFullParams<
        z.infer<typeof NotificationsRouterContract.sampleReceivers.query>
      >,
      z.infer<
        (typeof NotificationsRouterContract.sampleReceivers.responses)['200']
      >
    >;
  }>(tsRest, NotificationsRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
