import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Modal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { adminApi } from '../../../../_common/services/api';
import { AdminAssessmentSelectQuestionsInput } from '../AdminAssessmentSelectQuestionsInput/AdminAssessmentSelectQuestionsInput';

const { useAddAssessmentItemsMutation } = adminApi;

type Props =
  | {
      assessmentId: string;
      excludedQuestionIds?: string[];
    }
  | {
      excludedQuestionIds?: string[];
      onSubmit: (ids: string[]) => void;
    };

export const AdminAssessmentEditFormAddQuestionModal = (props: Props) => {
  const { excludedQuestionIds = [] } = props;
  const _onSubmit = 'assessmentId' in props ? null : props.onSubmit;
  const assessmentId = 'assessmentId' in props ? props.assessmentId : null;
  const { hide } = useModal();
  const [questionIds, setQuestionIds] = useState([]);
  const [addItems, { isLoading, error }] = useAddAssessmentItemsMutation();

  const onSubmit = useCallback(async () => {
    if (assessmentId) {
      const result = await addItems({
        items: questionIds.map((qId) => ({
          type: C.I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES.QUESTION,
          assessmentQuestionId: qId,
        })),
        id: assessmentId,
      });
      if ('error' in result) return;
      hide();
    } else if (_onSubmit) {
      _onSubmit(questionIds);
    }
    hide();
  }, [assessmentId, questionIds]);
  return (
    <Modal.Content scrollable>
      <Box
        flex={{ flexDirection: 'column' }}
        asChild
        className="admin-assessment-edit-form__add-question-modal"
      >
        <div>
          <Modal.Header>
            <Modal.Title>Add Question</Modal.Title>
          </Modal.Header>
          <AdminAssessmentSelectQuestionsInput
            // @ts-ignore
            onChange={(questionIds) => setQuestionIds(questionIds)}
            name={'addQuestions'}
            excludedQuestionIds={excludedQuestionIds}
          />
          <Box>
            <Typography.p className="admin-assessment-edit-form__add-question-modal__placeholder">
              {!questionIds?.length
                ? 'The selected questions will be previewed here'
                : null}
            </Typography.p>
          </Box>
          <Modal.Actions>
            <Modal.Error>{getMessageFromError(error)}</Modal.Error>
            <Button
              label="Submit"
              isLoading={isLoading}
              onClick={onSubmit}
              disabled={questionIds.length === 0}
            />
          </Modal.Actions>
        </div>
      </Box>
    </Modal.Content>
  );
};
