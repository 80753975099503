import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { ApiClientOptions } from '../types';

export const successHandlerInterceptor =
  (options: ApiClientOptions) => (response: AxiosResponse) => {
    if (response.data.warnings && response.data.warnings.length) {
      options.warningHandler && options.warningHandler(response.data.warnings);

      if (typeof document !== 'undefined') {
        document.dispatchEvent(
          new CustomEvent('onWarning', { detail: response.data.warnings })
        );
      }
    }

    let data = response.data;
    if (response.data.data || response.data.data === 0) {
      data = response.data.data;
    }

    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && data instanceof Blob) {
      const [, filename] = contentDisposition.split('filename=');
      FileSaver.saveAs(data, filename);
    }

    return data;
  };
