import store from 'store';

import { getClients } from '@nl-lms/feature/reports/sdk';

import { apiBaseUrl } from './api';

const { raw: reportsApiClient, rtk: reportsRtkClient } = getClients({
  baseUrl: `${apiBaseUrl('v2')}`,
  tokenGetter: () => store.get('accessToken'),
});

export { reportsApiClient };

export const reportsApi = reportsRtkClient;
reportsApi.enhanceEndpoints<'Report' | 'ReportTemplate' | 'Statistics'>({
  endpoints: {
    listReports: { providesTags: [{ type: 'Report', id: 'LIST' }] },
    generateReport: { invalidatesTags: [{ type: 'Report', id: 'LIST' }] },
    getReportData: { providesTags: [] },
    scheduleReport: { invalidatesTags: [{ type: 'Report', id: 'LIST' }] },
    updateReport: { invalidatesTags: [{ type: 'Report', id: 'LIST' }] },
    deleteReports: { invalidatesTags: [{ type: 'Report', id: 'LIST' }] },
    listReportTemplates: {
      providesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },
    selectReportTemplates: {
      providesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },
    createReportTemplate: {
      invalidatesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },
    updateReportTemplate: {
      invalidatesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },
    updateReportTemplateColumns: {
      invalidatesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },
    deleteReportTemplates: {
      invalidatesTags: [{ type: 'ReportTemplate', id: 'LIST' }],
    },

    listLiveCourseStatistics: {
      providesTags: [{ type: 'Statistics', id: 'LIST' }],
    }, // todo: replace tagsw with eleanring and live learning tags - refresh statstics when elearning or leive learning data chagnes

    listLearnerStatistics: {
      providesTags: [{ type: 'Statistics', id: 'LIST' }],
    }, // todo: replace tagsw with eleanring and live learning tags - refresh statstics when elearning or leive learning data chagnes
    getLearnerAttendance: {
      providesTags: [{ type: 'Statistics', id: 'LIST' }],
    }, // todo: replace tagsw with eleanring and live learning tags - refresh statstics when elearning or leive learning data chagnes
    getLearnerLearnedTime: {
      providesTags: [{ type: 'Statistics', id: 'LIST' }],
    }, // todo: replace tagsw with eleanring and live learning tags - refresh statstics when elearning or leive learning data chagnes
  },
});
