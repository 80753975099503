import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ChecklistItem } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Separator,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';

type Props = {
  item?: Partial<ChecklistItem>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
});

export const AdminChecklistItemEditFormSideModal = ({ item = {} }: Props) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      title: item?.title || '',
      description: item?.description || '',
    },
  });

  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateChecklistItemMutation',
    updateHookName: 'useUpdateChecklistItemMutation',
    entityId: item.id,
  });

  const onSubmitClick = (onSubmit) => {
    let span = document.createElement('span');
    // @ts-ignore
    span.innerHTML = getValues('description');
    if (!(span.textContent || span.innerText)) {
      setValue('description', '');
    }
    handleSubmit(onSubmit)();
  };
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {item.id ? 'Edit Checklist Item' : 'Create New Checklist Item'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Box>
            <FormField
              label="Title"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Description">
              <Controller
                name="description"
                control={control}
                render={({ field }) => <TextEditorWithFileUpload {...field} />}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={item.id ? 'Save' : 'Create'}
          onClick={() => onSubmitClick(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
