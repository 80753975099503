import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Learner } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { AdminLearnerSingleSelect } from './AdminLearnerSelect';

type Props = {
  learner?: Partial<Learner>;
};

const parseLearner = (learnerPayload) => {
  const detailsFields = C.LEARNER_PROPERTIES.map((prop) => prop.name);
  return {
    ..._.omit(learnerPayload, detailsFields),
    details: detailsFields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: learnerPayload[field],
      }),
      {}
    ),
  };
};

const getLearnerManager = (learner: Partial<Learner>) => {
  if (!learner.hierarchicManagers || !learner.hierarchicManagers.length)
    return {};
  return {
    managerId: learner.hierarchicManagers[0].id,
    managerName: `${learner.hierarchicManagers[0].firstName} ${learner.hierarchicManagers[0].lastName}`,
  };
};

export const AdminLearnerEditFormSideModal = ({ learner = {} }: Props) => {
  const Schema = useMemo(() => {
    const detailsSchema = C.LEARNER_PROPERTIES.reduce(
      (acc, prop) => ({
        ...acc,
        [prop.name]: yup.string(),
      }),
      {}
    );

    return yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email().required(),
      customerInternalId: yup.string(),
      managerId: yup.string(),
      ...detailsSchema,
    });
  }, []);
  const detailsFields = C.LEARNER_PROPERTIES.map((prop) => prop.name);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...learner,
      ...getLearnerManager(learner),
      ...detailsFields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: learner.details ? learner.details[field] : '',
        }),
        {}
      ),
    },
  });
  const { hide, show } = useModal();

  const { isLoading, onSubmit, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateLearnerMutation',
    updateHookName: 'useUpdateLearnerMutation',
    entityId: learner.id,
    parseEntity: parseLearner,
  });
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {learner.id ? 'Edit Learner' : 'Create New Learner'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>General</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="First Name"
              required
              errorMessage={errors?.firstName?.message as string | undefined}
            >
              <Sensitive>
                <Input
                  required
                  {...register('firstName')}
                  hasError={!!errors?.firstName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Last Name"
              errorMessage={errors?.lastName?.message as string | undefined}
              required
            >
              <Sensitive>
                <Input
                  {...register('lastName')}
                  hasError={!!errors?.lastName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Email"
              errorMessage={errors?.email?.message as string | undefined}
            >
              <Sensitive>
                <Input
                  type="email"
                  {...register('email')}
                  required
                  hasError={!!errors?.email?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <Box>
              <FormField
                label="Customer Internal ID"
                errorMessage={
                  errors?.customerInternalId?.message as string | undefined
                }
              >
                <Sensitive>
                  <Input
                    {...register('customerInternalId')}
                    required
                    hasError={!!errors?.customerInternalId?.message}
                  />
                </Sensitive>
              </FormField>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Manager">
              <Controller
                name="managerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={{
                      value: learner.managerId,
                      label: learner.managerName,
                    }}
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        {C.LEARNER_PROPERTIES.map((prop, index) => (
          <Box key={`${prop.name}-${index}`}>
            <FormField label={prop.fieldName}>
              <Sensitive>
                <Input {...register(prop.name)} />
              </Sensitive>
            </FormField>
          </Box>
        ))}
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={learner.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
