import React from 'react';

import { Button, Card, Sensitive } from '@nl-lms/ui/components';
import { useRoutingAction } from '@nl-lms/ui/hooks';

import { adminApi } from '../../../../_common/services/api';
import { routes } from '../../../../lib/routes';
import './AdminTeamCard.scss';

export const AdminManagerTeamCard = () => {
  const goToTeamPage = useRoutingAction({
    route: routes.admin.learners.team.path.full(),
  });

  const {
    useGetMandatoryAssignmentsQuery,
    useGetMostAccessedSessionQuery,
    useGetMostActiveUserQuery,
  } = adminApi;
  const { data: assignmentsData } = useGetMandatoryAssignmentsQuery({});
  const { data: mostAccessedCourseData } = useGetMostAccessedSessionQuery({});
  const { data: mostActiveUserData } = useGetMostActiveUserQuery({});

  return (
    <Card className="admin-team-card">
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Your Team</Card.HeaderTitle>
        <Card.HeaderActions>
          {/*// @ts-ignore */}
          <Button ghost label="view your team" onClick={goToTeamPage} />
        </Card.HeaderActions>
      </Card.Header>
      <Card.Content className="admin-team-card__learner-metrics-container">
        <div className="admin-team-card__learner-metric">
          <div className="admin-team-card__learner-metric__value">
            {assignmentsData?.finishedMandatoryAssignments} /{' '}
            {assignmentsData?.allMandatoryAssignments}
          </div>
          <div className="admin-team-card__learner-metric__label">
            completed mandatory assignments
          </div>
        </div>
        <div className="admin-team-card__learner-metric">
          <div className="admin-team-card__learner-metric__value">
            {mostAccessedCourseData?.courseName || 'No data available'}
          </div>
          <div className="admin-team-card__learner-metric__label">
            most accessed course
          </div>
        </div>
        <div className="admin-team-card__learner-metric">
          <div className="admin-team-card__learner-metric__value">
            <Sensitive>
              {mostActiveUserData?.learnerName || 'No data available'}
            </Sensitive>
          </div>
          <div className="admin-team-card__learner-metric__label">
            most active user
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};
