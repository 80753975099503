import { Model } from 'sequelize-typescript';
import { z } from 'zod';

import {
  CompetencySchema,
  LearnerGroupSchema,
  TagSchema,
} from '@nl-lms/common/feature/types';
import { _ } from '@nl-lms/vendor';

export type WithoutModel<T> = Omit<T, keyof Model>;

export const WithTagsSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>
) =>
  schema.extend({
    tags: z.array(TagSchema.pick({ id: true, title: true, scope: true })),
  });

export const WithCompetenciesSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>
) =>
  schema.extend({
    competencies: z.array(
      CompetencySchema.pick({ id: true, title: true, scope: true })
    ),
  });

export const WithTargetLearnerGroupsSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>
) =>
  schema.extend({
    targetLearnerGroups: z.array(
      LearnerGroupSchema.pick({ id: true, title: true })
    ),
  });

export const WithTagsCompetenciesTargetLearnerGroupsSchema = <
  T extends z.ZodRawShape
>(
  schema: z.ZodObject<T>
) => {
  const r0 = WithTargetLearnerGroupsSchema(schema);
  const r1 = WithCompetenciesSchema(r0);
  const r2 = WithTagsSchema(r1);

  return r2;
};
