export const ChecklistRouterPrefix = '/checklist';

export const ChecklistScope = {
  portal: {
    viewChecklist: {
      resource: 'portal_checklist',
      action: 'view',
    },
    viewChecklistInstance: {
      resource: 'portal_checklist_instance',
      action: 'view',
    },
  },
} as const;
