import { translations } from '@nl-lms/ui';

import { NamespaceTranslations as AdminTranslations } from '../../adminApp';
import { NamespaceTranslations as LearnerTranslations } from '../../learnerApp';
import { NamespaceTranslations as PublicTranslations } from '../../publicApp';

export const defaultNS = 'admin';

export const resources = {
  en: {
    admin: AdminTranslations.en,
    learner: LearnerTranslations.en,
    public: PublicTranslations.en,
    ui: translations.NamespaceTranslations.en,
  },
  ro: {
    admin: AdminTranslations.ro,
    learner: LearnerTranslations.ro,
    public: PublicTranslations.ro,
    ui: translations.NamespaceTranslations.ro,
  },
} as const;
