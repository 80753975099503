import { z } from 'zod';

export const TagSchema = z.object({
  id: z.string(),
  title: z.string(),
  scope: z.string().nullable(),
  adminOnly: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Tag = z.infer<typeof TagSchema>;
