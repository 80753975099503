import _ from 'lodash';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';

import { LearningAssignment } from '@nl-lms/common/feature/types';
import {
  Box,
  ContextMenu,
  FlowGraphNodeType,
  FlowGraphNodeTypes,
  Icon,
  LayoutDirection,
  Tooltip,
  Typography,
} from '@nl-lms/ui/components';

import {
  RuleComponent,
  getRuleAssignationDate,
  getRuleTriggerNTimesLabel,
} from '../learning-assignment-rule/RuleComponent';
import { END_NODE, ProgramRule, RULE_NODE, START_NODE } from './types';

type LearningAssignmentRulesComponentGraphEntityNodeProps = {
  id: string;
  type: 'Entity' | 'Self';
  sourcePosition?: Position;
  targetPosition?: Position;
  data: LearningAssignment & {
    name: string;
    info: string;
    headerText: string;
    headerColor: 'info' | 'default';
    colors: string[];
    color: string;
    isTarget: boolean;
    isSource: boolean;
    onDeleteEdge?: ({
      source,
      target,
      ruleIndex,
      conditionIndex,
    }: {
      source?: string | string[];
      target?: string;
      ruleIndex: number;
      conditionIndex?: number;
    }) => void;
    onDeleteNode?: (id: string, type: string) => boolean;
    onClickNode?: (data: LearningAssignment, type: string) => void;
    onConnect?: (data: any) => void;
    onUpsertEdge?: (data: any) => void;
    layout: LayoutDirection;
    isHighlighted?: (id: string) => boolean;
  };
};

type LearningAssignmentRulesComponentGraphNodeConnectionProps = {
  id: string;
  type: 'Connection';
  sourcePosition?: Position;
  targetPosition?: Position;
  data: Partial<ProgramRule> & {
    index: number;
    onDeleteEdge?: ({
      source,
      target,
      ruleIndex,
      conditionIndex,
    }: {
      source?: string | string[];
      target?: string;
      ruleIndex: number;
      conditionIndex?: number;
    }) => void;
    onClickEdge?: (index: number, rule: ProgramRule) => void;
    layout: LayoutDirection;
    isHighlighted?: (nodeId: string) => boolean;
    color: string;
    targetId: string;
    sourceIds: string[];
  };
};

const LearningAssignmentRulesComponentGraphNodeHandle = ({
  layout,
  name,
  type,
  nodeType,
}) => {
  const isVertical = layout === LayoutDirection.TopToBottom;
  const className = 'learning-program-graph__node-handle';
  const isHandleActive = nodeType !== FlowGraphNodeTypes.Connection;
  const sourceClassName = `${className} ${className}--source ${
    !isHandleActive ? `${className}--source--disabled` : ''
  }`;
  const targetClassName = `${className} ${className}--target ${
    !isHandleActive ? `${className}--target--disabled` : ''
  }`;

  if (type === 'source') {
    return isVertical ? (
      <>
        <Handle
          id={`${name}-bottom`}
          type="source"
          position={Position.Bottom}
          className={sourceClassName}
          isConnectableEnd={false}
          isConnectableStart={isHandleActive}
        />
      </>
    ) : (
      <>
        <Handle
          id={`${name}-right`}
          type="source"
          position={Position.Right}
          className={sourceClassName}
          isConnectableEnd={false}
          isConnectableStart={isHandleActive}
        />
      </>
    );
  } else {
    return isVertical ? (
      <>
        <Handle
          id={`${name}-top`}
          type="target"
          position={Position.Top}
          className={targetClassName}
          isConnectableStart={false}
          isConnectableEnd={isHandleActive}
        />
      </>
    ) : (
      <>
        <Handle
          id={`${name}-left`}
          type="target"
          position={Position.Left}
          className={targetClassName}
          isConnectableStart={false}
          isConnectableEnd={isHandleActive}
        />
      </>
    );
  }
};

export const LearningAssignmentRulesComponentGraphNodeStart = ({
  data: { layout },
}) => (
  <Tooltip
    title="Starting Point"
    description="Connect this node to the assignment(s) you want the program to start
  with."
  >
    <LearningAssignmentRulesComponentGraphNodeHandle
      layout={layout}
      name="startSource"
      type="source"
      nodeType={FlowGraphNodeTypes.Start}
    />
    <Box className="learning-program-graph__node-body learning-program-graph__node-body--start">
      <Typography.h2 className="learning-program-graph__node-body__title">
        Start
      </Typography.h2>
    </Box>
  </Tooltip>
);

export const LearningAssignmentRulesComponentGraphNodeEnd = ({
  id,
  type,
  data: { layout, onDeleteNode, onClickNode, name, isHighlighted },
}) => {
  const { setNodes, setEdges } = useReactFlow();
  const onDeleteEndNode = useCallback(async () => {
    if (!onDeleteNode) return;
    await onDeleteNode?.(id, type);
  }, [id, onDeleteNode, setNodes, setEdges]);

  const onClickEndNode = useCallback(() => {
    if (!onClickNode) return;
    onClickNode?.({ id }, type);
  }, [id, onClickNode]);
  const nodeContextMenuOptions = [
    {
      name: 'Remove Ending',
      handler: onDeleteEndNode,
    },
  ];
  const isDisabled = useMemo(() => !isHighlighted?.(id), [id, isHighlighted]);
  return (
    <Tooltip
      title="Completion Point"
      description="Connect the assignment(s) you want the program to end with to this
    node."
    >
      <ContextMenu items={nodeContextMenuOptions} disabled={!onDeleteNode}>
        <div
          key={id}
          onClick={onClickEndNode}
          className={`learning-program-graph__node ${
            isDisabled ? 'learning-program-graph__node--faded' : ''
          }`}
        >
          <LearningAssignmentRulesComponentGraphNodeHandle
            layout={layout}
            name="endTarget"
            type="target"
            nodeType={type}
          />
          <Box className="learning-program-graph__node-body learning-program-graph__node-body--end">
            <Typography.h2 className="learning-program-graph__node-body__title">
              {name || 'Program End'}
            </Typography.h2>
          </Box>
        </div>
      </ContextMenu>
    </Tooltip>
  );
};

type LearningAssignmentRulesComponentGraphNodeDetailProps = {
  layout: string;
  detailInfo: {
    type: FlowGraphNodeType;
    nodeData: {
      name: string;
      info: string;
    };
  };
  draggable: boolean;
  onDragNode: (entity: any) => void;
  className: string;
};

export const LearningAssignmentRulesComponentGraphNodeDetail = ({
  data: { detailInfo, draggable, onDragNode, className },
}: {
  data: LearningAssignmentRulesComponentGraphNodeDetailProps;
}) => {
  const nodeType = detailInfo.type;
  const nodeClassNameType = `learning-program-graph__node-body learning-program-graph__node-body--detail learning-program-graph__node-body--detail-${_.toLower(
    nodeType
  )}`;
  return (
    <div className={className} draggable={draggable} onDrag={onDragNode}>
      <Box className={nodeClassNameType}>
        <Typography.h4>{detailInfo?.nodeData?.name}</Typography.h4>
        {detailInfo?.nodeData?.info && (
          <Typography.p>{detailInfo?.nodeData?.info}</Typography.p>
        )}
      </Box>
    </div>
  );
};

export const LearningAssignmentRulesComponentGraphNodeEntity = (
  props: LearningAssignmentRulesComponentGraphEntityNodeProps
) => {
  const getNodeBorder = (colors) => {
    if (!colors?.length) {
      return null;
    }
    return (
      <Box flex={{ flexDirection: 'column' }}>
        {colors?.map((color) => (
          <div
            style={{
              borderLeft: `3px solid ${color}`,
              height: `${100 / colors?.length}%`,
            }}
          />
        ))}
      </Box>
    );
  };

  const { setNodes, setEdges, getEdges } = useReactFlow();

  const onDeleteNode = useCallback(async () => {
    if (!props?.data?.onDeleteNode) return;

    const res = await props?.data?.onDeleteNode?.(props?.id, props?.type);
    if (res) {
      getEdges()?.forEach((edge) => {
        const isEdgeRelatedToNode =
          edge.source === props?.id || edge.target === props?.id;

        if (isEdgeRelatedToNode) {
          const isMultiConditionEdge =
            edge?.target?.includes(RULE_NODE) && edge?.source === props?.id;

          const ruleCondIndex =
            isMultiConditionEdge && edge?.data?.conditions?.length > 1
              ? edge?.data?.conditions?.findIndex((cond) =>
                  _.isEqual(cond, edge?.data?.condition)
                )
              : edge?.data?.index;

          props?.data?.onDeleteEdge?.({
            source: edge.source,
            target: edge.target,
            ruleIndex: edge?.data?.parentData?.index,
            conditionIndex: ruleCondIndex,
          });

          if (!isMultiConditionEdge) {
            setEdges((edges) =>
              edges.filter(
                (edge) => edge.source !== props?.id && edge.target !== props?.id
              )
            );
          }
        }
      });

      setNodes((nodes) => nodes.filter((node) => node.id !== props?.id));
    }
  }, [props, setNodes, setEdges]);

  const onEditNode = useCallback(() => {
    if (!props?.data?.onClickNode) return;
    props?.data?.onClickNode?.(props?.data, props?.type);
  }, [props]);

  const onMarkAsEnd = useCallback(
    (endType) => {
      if (!props?.data?.onConnect) return;
      const connectParams = {
        source: props?.data?.id,
        target: endType,
        sourceHandle: '',
        targetHandle: '',
      };
      props?.data?.onConnect?.(connectParams);
    },
    [props]
  );

  const startNodeEdge = useMemo(
    () =>
      getEdges().find(
        (edge) => edge.source === START_NODE && edge.target === props.id
      ),
    [props]
  );

  const onToggleMarkAsStart = useCallback(() => {
    if (startNodeEdge) {
      if (!props?.data?.onDeleteEdge) return;
      setEdges((edges) =>
        edges.filter((edge) => edge.id !== startNodeEdge?.id)
      );
      props?.data?.onDeleteEdge?.({
        source: startNodeEdge?.source,
        target: startNodeEdge?.target,
        ruleIndex: startNodeEdge?.data?.parentData?.index,
        conditionIndex: startNodeEdge?.data?.index,
      });
    } else {
      if (!props?.data?.onConnect) return;
      const connectParams = {
        source: START_NODE,
        target: props?.data?.id,
        sourceHandle: '',
        targetHandle: '',
      };
      props?.data?.onConnect?.(connectParams);
    }
  }, [startNodeEdge, props]);

  const nodeContextMenuOptions = useMemo(
    () => [
      {
        name: 'Edit Assignment',
        handler: onEditNode,
      },
      {
        name: 'Remove From Program',
        handler: onDeleteNode,
      },
      {
        name: 'Mark Program Completed',
        handler: () => onMarkAsEnd(END_NODE.COMPLETED),
      },
      {
        name: 'Mark Program Failed',
        handler: () => onMarkAsEnd(END_NODE.FAILED),
      },
      {
        name: `${startNodeEdge ? 'Unmark' : 'Mark'} as Program Start`,
        handler: onToggleMarkAsStart,
      },
    ],
    [props]
  );

  const isDisabled = useMemo(
    () => !props?.data?.isHighlighted?.(props?.id),
    [props]
  );

  return (
    <ContextMenu
      items={nodeContextMenuOptions}
      disabled={!props?.data?.onDeleteNode || isDisabled}
    >
      <div
        key={props?.id}
        className={`learning-program-graph__node ${
          isDisabled ? 'learning-program-graph__node--faded' : ''
        }`}
      >
        <Box flex={{ flexDirection: 'column' }}>
          <div
            style={{
              borderLeft: `3px solid ${props?.data?.color}`,
              height: `${100}%`,
            }}
          ></div>
        </Box>
        {getNodeBorder(
          isDisabled || !props?.data?.colors?.length
            ? ['#e5e5e7']
            : props?.data?.colors
        )}
        <div className="learning-program-graph__node-container">
          <LearningAssignmentRulesComponentGraphNodeHandle
            layout={props?.data?.layout}
            name={props?.data?.name}
            type="source"
            nodeType={FlowGraphNodeTypes.Entity}
          />
          <div style={{ cursor: 'pointer' }}>
            <Box
              className={`learning-program-graph__node-header learning-program-graph__node-header--${props?.data?.headerColor}`}
              flex={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Typography.h5
                className={`learning-program-graph__node-header__text learning-program-graph__node-header__text--${props?.data?.headerColor}`}
              >
                {props?.data?.headerText}
              </Typography.h5>
            </Box>
            <Box className="learning-program-graph__node-body">
              <Typography.h3 className="learning-program-graph__node-body__title">
                {props?.data?.name}
              </Typography.h3>
              <Typography.h4 className="learning-program-graph__node-body__info">
                {props?.data?.info}
              </Typography.h4>
            </Box>
          </div>
          <LearningAssignmentRulesComponentGraphNodeHandle
            layout={props?.data?.layout}
            name={props?.data?.name}
            type="target"
            nodeType={FlowGraphNodeTypes.Entity}
          />
        </div>
      </div>
    </ContextMenu>
  );
};

type LearningAssignmentRulesComponentGraphNodeSmallProps = {
  type: FlowGraphNodeType;
  style?: CSSProperties;
  className?: string;
  title: string;
  description?: string;
};

export const LearningAssignmentRulesComponentGraphNodeSmall = (
  props: LearningAssignmentRulesComponentGraphNodeSmallProps
) => {
  return (
    <div className="learning-program-graph__node-small">
      <div className="learning-program-graph__node-small-container">
        <Box
          className="learning-program-graph__node-small-header"
          flex={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography.h5 className="learning-program-graph__node-small-header__text">
            Mandatory Status
          </Typography.h5>
        </Box>
        <Box className="learning-program-graph__node-small-body">
          <Typography.h3 className="learning-program-graph__node-small-body__title">
            {props?.title}
          </Typography.h3>
          <Typography.h4 className="learning-program-graph__node-small-body__info">
            {props?.description}
          </Typography.h4>
        </Box>
      </div>
    </div>
  );
};

export const LearningAssignmentRulesComponentGraphNodeConnection = (
  props: LearningAssignmentRulesComponentGraphNodeConnectionProps
) => {
  const [isClicked, setIsClicked] = useState(false);
  const ruleEdgeContainerRef = useRef<HTMLDivElement>(null);

  const globalOnClickListener = useCallback((e) => {
    if (
      ruleEdgeContainerRef.current &&
      !ruleEdgeContainerRef.current.contains(e.target)
    ) {
      setIsClicked(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', globalOnClickListener);
    return () => {
      document.removeEventListener('click', globalOnClickListener);
    };
  }, []);

  const { setEdges } = useReactFlow();

  const onRemoveEdge = useCallback(
    async (e) => {
      e?.stopPropagation();
      setEdges((edges) => edges.filter((edge) => edge.id !== props?.id));

      props?.data?.onDeleteEdge?.({
        source: props?.data?.sourceIds,
        target: props?.data?.targetId,
        ruleIndex: props?.data?.index,
      });
    },
    [setEdges, props]
  );

  const isEdit = useMemo(() => !!props?.data?.onDeleteEdge, [props]);

  const onEditEdge = useCallback(() => {
    props?.data?.onClickEdge?.(props?.data?.index, props?.data as ProgramRule);
  }, [props]);

  const edgeContextMenuOptions = [
    {
      name: 'Edit Rule',
      handler: onEditEdge,
    },
    {
      name: 'Remove Rule',
      handler: onRemoveEdge,
    },
  ];

  const isDisabled = useMemo(
    () => !props?.data?.isHighlighted?.(props?.data?.entityId as string),
    [props]
  );

  const startDateDetails = useMemo(() => {
    let startDate;
    if (props?.data?.scheduleDate && props?.data?.scheduleDate?.value) {
      startDate = (
        <Typography.p>
          {getRuleAssignationDate(props?.data?.scheduleDate)}
        </Typography.p>
      );
    }

    return startDate ? <>{startDate}</> : null;
  }, [props?.data]);

  const settingsDetails = useMemo(() => {
    let triggerTimes = props?.data?.settings?.triggerNTimes as any;
    if (!triggerTimes || triggerTimes === '1' || triggerTimes === 1) {
      return null;
    }
    if (isNaN(triggerTimes)) triggerTimes = parseInt(triggerTimes);

    return (
      <Typography.p>{getRuleTriggerNTimesLabel(triggerTimes)}</Typography.p>
    );
  }, [props?.data]);

  return (
    <ContextMenu items={edgeContextMenuOptions} disabled={!isEdit}>
      <div
        ref={ruleEdgeContainerRef}
        // @ts-ignore
        style={{
          border: `2px solid ${isDisabled ? '#e5e5e7' : props?.data?.color}`,
          // ...edgeComponentStyle,
        }}
        onClick={isEdit ? onEditEdge : () => setIsClicked(true)}
        className={`learning-program-graph__node-connection ${
          isDisabled ? 'learning-program-graph__node-connection--faded' : ''
        }`}
      >
        <LearningAssignmentRulesComponentGraphNodeHandle
          layout={props?.data?.layout}
          name={props?.data?.name}
          type="source"
          nodeType={FlowGraphNodeTypes.Connection}
        />

        {(startDateDetails || settingsDetails) && (
          <div
            style={{
              border: `2px solid ${
                isDisabled ? '#e5e5e7' : props?.data?.color
              }`,
              transform: `translate(40%, 100%)`,
            }}
            className={`learning-program-graph__node-connection__detail ${
              isDisabled
                ? 'learning-program-graph__node-connection__detail--faded'
                : ''
            }`}
          >
            {startDateDetails}
            {settingsDetails}
          </div>
        )}

        <Box
          className="learning-program-graph__edge-header"
          flex={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Icon.GitIconSmall />
          </Box>
          <Box flex={{ flexDirection: 'row' }}>
            <Typography.h4 className="learning-program-graph__edge-header__text">
              {props?.data?.name}
            </Typography.h4>
          </Box>
        </Box>
        {isClicked ? (
          <div
            className="learning-program-graph__edge-tooltip"
            style={{
              height: 'fit-content',
            }}
          >
            <Box className="learning-program-graph__edge-body">
              {/* @ts-ignore */}
              <RuleComponent rule={props?.data} />
            </Box>
          </div>
        ) : null}

        <LearningAssignmentRulesComponentGraphNodeHandle
          layout={props?.data?.layout}
          name={props?.data?.name}
          type="target"
          nodeType={FlowGraphNodeTypes.Connection}
        />
      </div>
    </ContextMenu>
  );
};

export const GraphNode = {
  Start: LearningAssignmentRulesComponentGraphNodeStart,
  End: LearningAssignmentRulesComponentGraphNodeEnd,
  Entity: LearningAssignmentRulesComponentGraphNodeEntity,
  Connection: LearningAssignmentRulesComponentGraphNodeConnection,
  Detail: LearningAssignmentRulesComponentGraphNodeDetail,
};
