import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  IApiService,
  ListResponse,
  LiveCourse as LiveCourseType,
  LiveSession as LiveSessionType,
} from './types';

export class Course extends ApiService implements IApiService<'course'> {
  public paths: any;
  public serviceBase: string = `/resource/course`;

  constructor(props) {
    super(props);
    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => this.serviceBase,
      listWithSessions: () => `${this.serviceBase}/sessions`,
      create: () => this.serviceBase,
      update: (id) => `${this.serviceBase}/${id}`,
      bulkDelete: () => `${this.serviceBase}`,
      remove: (id) => `${this.serviceBase}/${id}`,
      listForSelect: () => `/list/course`,
      statistics: (id: string) => `${this.serviceBase}/${id}/statistics`,
      allStatistics: () => `${this.serviceBase}/statistics/all`,
    };
  }

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), { params: { query } });

  listWithSessions = (query: AppQuery) =>
    this.api.get(this.paths.listWithSessions(), {
      params: { query },
    });

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };

  listForSelect = (input) =>
    this.api.get<string, LiveCourseType[]>(this.paths.listForSelect(), {
      params: { query: { name: input } },
    });

  statistics = (id) =>
    this.api.get<
      null,
      LiveCourseType & {
        totalSessionMinutes: Record<string, number | null>[];
        totalLearnerStatuses: Record<string, number | null>[];
        totalSessionStatuses: Record<string, number | null>[];
        totalSessionTypes: Record<string, number | null>[];
      }
    >(this.paths.statistics(id));

  allStatistics = (query: AppQuery) =>
    this.api.get<
      null,
      LiveCourseType & {
        totalSessionMinutes: Record<string, number | null>[];
        totalLearnerStatuses: Record<string, number | null>[];
        totalSessionStatuses: Record<string, number | null>[];
        totalSessionTypes: Record<string, number | null>[];
      }
    >(this.paths.allStatistics(), {
      params: { query },
    });
}
