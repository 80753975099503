import { useCombobox } from 'downshift';
import { matchSorter } from 'match-sorter';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useClassNameProps } from '../../hooks/useClassNameProps';
import { useTestProps } from '../../hooks/useTestProps';
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './DownshiftSelect.scss';
import {
  BaseSingleSelectProps,
  CreateOptionName,
  SelectOption,
} from './Select.types';

export type SingleSelectInputType = 'input' | 'buttonLink';
export const SingleSelectInputTypes = {
  input: 'input',
  buttonLink: 'buttonLink',
};

export type SingleSelectProps = TidComponent<
  BaseSingleSelectProps & {
    initialSelectedItem?: SelectOption | string | number;
    options: SelectOption[];
    selectedItem?: SelectOption;
    shouldClearSelection?: boolean;
    inputType?: SingleSelectInputType;
  }
>;

function filterItems(inputValue, options, selectedItem?: SelectOption) {
  if (!inputValue) return options;
  if (
    selectedItem &&
    ((['number', 'string'].includes(typeof selectedItem) &&
      !!options.find((o) => o.value === selectedItem)) ||
      selectedItem?.label === inputValue)
  ) {
    return options;
  }
  return matchSorter(options, inputValue, {
    keys: [{ maxRanking: matchSorter.rankings.STARTS_WITH, key: 'label' }],
  });
}

const defaultOnBlur = () => {
  return;
};
export const SingleSelect = forwardRef<HTMLInputElement, SingleSelectProps>(
  (props, ref) => {
    const {
      onChange,
      selectedItem: _selectedItem = undefined,
      name,
      isClearable = false,
      placeholder = 'Select or type value to search',
      options,
      isCreatable = false,
      isLoading = false,
      initialSelectedItem = '',
      hasError = false,
      disabled = false,
      disableFiltering = false,
      onBlur = defaultOnBlur,
      initialHighlightedIndex = 0,
      returnEntireItemOnChange = false,
      shouldClearSelection = false,
      onChangeInputValue = null,
      inputType = 'input',
    } = props;
    const baseClassName =
      inputType === 'buttonLink'
        ? 'downshift-select-button'
        : 'downshift-select';
    const wrapperClassNameProps = useClassNameProps(
      `${baseClassName}__input-wrapper`,
      hasError ? `${baseClassName}__input-wrapper--has-error` : null,
      disabled ? `${baseClassName}__input-wrapper--disabled` : null
    );
    const commonProps = useTestProps(props);

    const [inputValue, setInputValue] = useState(() => {
      if (
        typeof initialSelectedItem === 'string' ||
        typeof initialSelectedItem === 'number'
      ) {
        const item = options.find((o) => o.value === initialSelectedItem);
        return item?.label || '';
      }
      return initialSelectedItem?.label;
    });
    const [selectedItem, setSelectedItem] = useState(() => {
      if (
        typeof initialSelectedItem === 'string' ||
        typeof initialSelectedItem === 'number'
      ) {
        const item = options.find((o) => o.value === initialSelectedItem);
        return item || { label: '', value: '' };
      }
      return initialSelectedItem;
    });
    const onSelectedItemChange = useCallback(
      (changes) => {
        if (!changes.selectedItem) return;
        setInputValue(changes.selectedItem?.label);
        setSelectedItem(changes.selectedItem);
        if (onChangeInputValue) onChangeInputValue(changes.selectedItem?.label);
        if (returnEntireItemOnChange) {
          onChange(changes.selectedItem, name);
        } else {
          onChange(changes.selectedItem.value, name);
        }
      },
      [onChange, setInputValue, onChangeInputValue, setSelectedItem]
    );
    const actualSelectedItem =
      typeof _selectedItem === 'undefined' ? selectedItem : _selectedItem;
    const filteredItems = disableFiltering
      ? options
      : filterItems(inputValue, options, actualSelectedItem);
    const onInputValueChange = useCallback(
      (changes) => {
        if (!changes.inputValue) {
          onRemoveSelectedOption();
        }
        setInputValue(changes.inputValue);
        if (onChangeInputValue) onChangeInputValue(changes.inputValue);
      },
      [setInputValue, onChangeInputValue]
    );

    const itemToString = useCallback(
      (option: SelectOption | string | number) => {
        if (!option) return null;
        if (typeof option === 'string' || typeof option === 'number') {
          const optionObject = options.find((o) => o.value === option);
          return optionObject?.label;
        }
        return option.label;
      },
      [options]
    );
    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      toggleMenu,
    } = useCombobox({
      items: filteredItems,
      initialHighlightedIndex,
      selectedItem: actualSelectedItem,
      onInputValueChange,
      defaultHighlightedIndex: 0,
      inputValue,
      initialSelectedItem,
      onSelectedItemChange,
      // @ts-ignore
      itemToString,
    });

    const onRemoveSelectedOption = useCallback(() => {
      setSelectedItem({ value: '', label: '' });
      onChange(null, name);
    }, [onChange, name]);
    useEffect(() => {
      if (shouldClearSelection) {
        setSelectedItem({ value: '', label: '' });
        onChange(null, name);
      }
    }, [shouldClearSelection]);
    const isMenuOpen = isCreatable ? options.length && isOpen : isOpen;
    const inputProps = getInputProps({
      placeholder,
      disabled,
      onClick: toggleMenu,
      ref,
    });

    if (!inputProps.value) inputProps.value = '';
    return (
      <div className={baseClassName} onBlur={onBlur} {...commonProps}>
        <div {...wrapperClassNameProps} {...getComboboxProps({ disabled })}>
          <input
            {...inputProps}
            name={name}
            className={`${baseClassName}__input ${
              disabled ? `${baseClassName}__input--disabled` : ''
            }`}
          />
          <div className="downshift-select__input-icons">
            {isLoading ? <div className="downshift-select__spinner" /> : null}
            {isClearable && actualSelectedItem?.value ? (
              <div
                className="downshift-select__input-icon"
                onClick={onRemoveSelectedOption}
              >
                <CloseIcon />
              </div>
            ) : null}
            <div
              className={`downshift-select__input-icon ${
                disabled ? 'downshift-select__input-icon--disabled' : ''
              }`}
              {...getToggleButtonProps({ disabled })}
              aria-label="toggle menu"
            >
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        </div>
        <div
          {...getMenuProps()}
          className="downshift-select__options-menu-wrapper"
        >
          {isMenuOpen ? (
            <ul className="downshift-select__options-menu">
              {filteredItems.map((item, index) => {
                if (item.type === CreateOptionName) {
                  return (
                    <li
                      key={`${item}${index}`}
                      {...getItemProps({ item, index })}
                      className={`downshift-select__option ${
                        highlightedIndex === index
                          ? 'downshift-select__option--active'
                          : ''
                      }`}
                    >
                      {`Create ${inputValue}`}
                    </li>
                  );
                }
                return (
                  <li
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                    className={`downshift-select__option ${
                      highlightedIndex === index
                        ? 'downshift-select__option--active'
                        : ''
                    }`}
                  >
                    {item.Component ? (
                      <item.Component label={item.label} entity={item.entity} />
                    ) : (
                      item.label
                    )}
                  </li>
                );
              })}
              {filteredItems.length === 0 && !isCreatable ? (
                <li
                  key="no-options"
                  className="downshift-select__option downshift-select__option--none-available"
                >
                  No options available
                </li>
              ) : null}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
);
