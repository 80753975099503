import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { FetchListResponse, IApiService, LiveSessionLearner } from './types';

export class Connect extends ApiService implements IApiService<'common'> {
  public paths: any;
  public serviceBase: string = `/connect`;

  constructor(props) {
    super(props);
    this.paths = {
      updateSessionLearnerStatus: (id) =>
        `${this.serviceBase}/session/${id}/learner/status`,
      importLearners: () => `${this.serviceBase}/import/learner`,
      listSessionLearners: () => `${this.serviceBase}/session/learner`,
    };
  }

  updateSessionLearnerStatus = ({
    training_session_id,
    learner_statuses,
  }: {
    training_session_id: string;
    learner_statuses: {
      email: string;
      status: number;
    }[];
  }) =>
    this.api.post<null, any>(
      this.paths.updateSessionLearnerStatus(training_session_id),
      { learner_statuses }
    );

  importLearners = (data, params = {}) =>
    this.api.post(this.paths.importLearners(), data, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  listSessionLearners = (query: AppQuery) => {
    return this.api.get<null, FetchListResponse<LiveSessionLearner>>(
      this.paths.listSessionLearners(),
      {
        params: { query },
      }
    );
  };
}
