import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { adminApi, AdminApiEndpointNames } from './adminApi';

export function useGetResourceForTableRowAction(
  endpoint: AdminApiEndpointNames
) {
  const dispatch = useDispatch();

  // @ts-ignore;
  const prefetchResource = adminApi.usePrefetch(endpoint);

  const prefetch = useCallback((row) => {
    prefetchResource(row.id);
  }, []);

  const get = useCallback(async (row) => {
    const resultPromise = dispatch(
      // @ts-ignore
      adminApi.endpoints[endpoint].initiate(row.id)
    );
    const result = await resultPromise;
    // @ts-ignore
    if (result.status == 'fulfilled') {
      // @ts-ignore
      resultPromise.unsubscribe();
      // @ts-ignore
      return result.data;
    }
    return false;
  }, []);

  return { get, prefetch };
}
