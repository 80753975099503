import React, { useCallback, useMemo, useState } from 'react';

import {
  AppQuery,
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { Learner } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Icon,
  Input,
  NoDataPlaceholder,
  Select,
  Sensitive,
  Separator,
  SideModal,
  SingleSelect,
  StatusTag,
  Table,
  Textarea,
  Typography,
  useConfirmationModal,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { ListViewProvider } from '@nl-lms/ui/modules';
import {
  getMessageFromError,
  getSessionLearnerStatusOptions,
} from '@nl-lms/ui/utils';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { useAction } from '../../../../_common/hooks/useAction';
import { adminApi, useApi } from '../../../../_common/services/api';
import { getLearnerPropertiesFilterFields } from '../../../_common/utils/getLearnerPropertiesFilterFields';
import {
  AdminLearnerListTable,
  AdminLearnerListTableFilters,
} from '../../AdminLearner/AdminLearnerListTable';

const {
  useAddLiveSessionLearnersMutation,
  useListLiveSessionAddableLearnersQuery,
  useLazyListLearnersQuery,
  endpoints,
} = adminApi;

export const AdminLiveSessionLearnerAddModal = ({ liveSessionId }) => {
  const { hide } = useModal();
  const { data: liveSession } =
    endpoints.getLiveSession.useQueryState(liveSessionId);
  const calendarEnabled = liveSession?.calendarEnabled;
  const [selectedLearners, setSelectedLearners] = useState<
    { id: string; firstName: string; lastName: string; email: string }[]
  >([]);
  const [status, setLearnerStatus] = useState(C.I_LEARNER_STATUS.NOT_INVITED);
  const [addLiveSessionLearners, { isLoading, error: addLearnersError }] =
    useAddLiveSessionLearnersMutation();
  const addLiveSessionLearnersAction = useAction(addLiveSessionLearners, {
    successMessage: 'The live session learners have been added successfully',
  });

  const onRemoveLearner = useCallback(
    (row) => {
      setSelectedLearners(selectedLearners.filter((l) => l.id !== row.id));
    },
    [selectedLearners]
  );
  const showConfirmationModal = useConfirmationModal({
    message:
      'Once added to the live session, the learners will receive a calendar event invite. Do you want to proceed with this action?',
  });

  const onSubmit = useCallback(async () => {
    if (
      calendarEnabled &&
      ![
        C.I_LEARNER_STATUS.CANCELLED,
        C.I_LEARNER_STATUS.WAITING_LIST,
        C.I_LEARNER_STATUS.APPROVAL,
        C.I_LEARNER_STATUS.APPROVAL_DECLINED,
      ].includes(status)
    ) {
      const confirmationResult = await showConfirmationModal();
      if (!confirmationResult.confirmed) return;
    }

    const selectedLearnerIds = selectedLearners.map((l) => l.id);
    const result = await addLiveSessionLearnersAction({
      liveSessionId,
      learnerIds: selectedLearnerIds,
      status,
    });

    if (result && 'data' in result) hide({ success: true });

    return result;
  }, [selectedLearners, calendarEnabled, status, liveSessionId]);

  const statusOptions = useMemo(() => {
    if (liveSession?.approvalType === C.I_APPROVAL_TYPES.NONE) {
      return getSessionLearnerStatusOptions().filter(
        (s) =>
          s.value !== C.I_LEARNER_STATUS.APPROVAL &&
          s.value !== C.I_LEARNER_STATUS.APPROVAL_DECLINED
      );
    } else {
      return getSessionLearnerStatusOptions().filter(
        (s) => s.value !== C.I_LEARNER_STATUS.APPROVAL_DECLINED
      );
    }
  }, [liveSession]);

  const showAddLearnersFromAListModal = useShowModal<{
    success: boolean;
    payload: { learners: Learner[] };
  }>(AdminLiveSessionAddLearnersFromAListOfLearnersModal);

  const showAddLearnersWithAdvancedFilteringModal = useShowModal<{
    success: boolean;
    payload: { learners: Learner[] };
  }>(AdminLiveSessionAddLearnersUsingAdvancedFilteringModal);

  const onClickShowAddLearnersFromAListModal = useCallback(async () => {
    const result = await showAddLearnersFromAListModal();
    if (result?.success && result?.payload?.learners) {
      setSelectedLearners([...selectedLearners, ...result.payload.learners]);
    }
  }, [selectedLearners]);

  const onClickShowAddLearnersWithAdvancedFilteringModal =
    useCallback(async () => {
      const result = await showAddLearnersWithAdvancedFilteringModal();
      if (result?.success && result?.payload?.learners) {
        setSelectedLearners([...selectedLearners, ...result.payload.learners]);
      }
    }, [selectedLearners]);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Add Learners</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Box>
            <FormField label="Learners">
              <AdminLiveSessionLearnerSelect
                // @ts-ignore
                selectedLearners={selectedLearners}
                liveSessionId={liveSessionId}
                onChange={setSelectedLearners}
              />
            </FormField>
          </Box>
          <Box style={{ width: '50%' }}>
            <FormField label="Status">
              <SingleSelect
                name="learnerStatus"
                options={statusOptions}
                onChange={setLearnerStatus}
                initialSelectedItem={C.I_LEARNER_STATUS.NOT_INVITED}
              />
            </FormField>
          </Box>
        </Box>
        <Separator marginTop={10} />
        <Table
          data={selectedLearners}
          columns={[
            {
              Header: 'Name',
              accessor: 'firstName',
              Cell: ({ row }) => (
                <Sensitive>
                  {row.original.firstName} {row.original.lastName}
                </Sensitive>
              ),
            },
            {
              Header: 'Email',
              accessor: 'email',
              Cell: ({ row }) => <Sensitive>{row.original.email}</Sensitive>,
            },
            {
              Header: 'Status',
              accessor: 'id',
              Cell: () => <StatusTag status={C.LEARNER_STATUS[status]} />,
            },
          ]}
          rowActions={[
            {
              name: 'Remove',
              handler: onRemoveLearner,
              Icon: Icon.DeleteIcon,
            },
          ]}
        >
          <NoDataPlaceholder.Container>
            <NoDataPlaceholder.Icon
              name="UserIcon"
              size="large"
              color="success"
            />
            <NoDataPlaceholder.Title>
              The learners that you select will appear in this table
            </NoDataPlaceholder.Title>
            <NoDataPlaceholder.Subtitle>
              You can add learners just by using the main select input or
              through the other advanced options.
            </NoDataPlaceholder.Subtitle>
            <NoDataPlaceholder.Actions>
              <Button
                regular
                label="Advanced Filtering"
                icon={<Icon.FilterIcon />}
                small
                onClick={onClickShowAddLearnersWithAdvancedFilteringModal}
              />
              <Button
                regular
                style={{ marginLeft: '10px' }}
                icon={<Icon.ClipboardIcon />}
                label="Paste From a List"
                small
                onClick={onClickShowAddLearnersFromAListModal}
              />
            </NoDataPlaceholder.Actions>
          </NoDataPlaceholder.Container>
        </Table>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getMessageFromError(addLearnersError)}
        </SideModal.Error>
        {selectedLearners.length ? (
          <>
            <Button
              regular
              icon={<Icon.ClipboardIcon />}
              label="Paste From List"
              onClick={onClickShowAddLearnersFromAListModal}
            />
            <Button
              regular
              label="Advanced Filtering"
              icon={<Icon.FilterIcon />}
              onClick={onClickShowAddLearnersWithAdvancedFilteringModal}
            />
          </>
        ) : null}
        <Button
          label="Add"
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={!selectedLearners.length}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

export const AdminLiveSessionLearnerSelect = ({
  selectedLearners = [],
  onChange,
  liveSessionId,
}: {
  selectedLearners: any[];
  onChange: any;
  liveSessionId: string;
}) => {
  const api = useApi();
  const [inputValue, setInputValue] = useState('');
  const fetchArguments = useMemo(
    () => ({
      id: liveSessionId,
      query: { search: inputValue },
    }),
    [inputValue, liveSessionId]
  );
  const selectedLearnersIds = useMemo(
    // @ts-ignore
    () => selectedLearners.map((l) => l.id),
    [selectedLearners]
  );
  const { data, error, isLoading } =
    useListLiveSessionAddableLearnersQuery(fetchArguments);
  useQueryErrorHandler({ error });

  const onSelectLearner = useCallback(
    (learnerOption) => {
      const newSelectedLearners = [...selectedLearners, learnerOption.entity];
      onChange(newSelectedLearners);
      setInputValue('');
    },
    [selectedLearners]
  );

  const options = useMemo(() => {
    if (!data || !data.length) return [];

    return data
      .filter((l) => !selectedLearnersIds.includes(l.id))
      .map((l) => ({
        value: l.id,
        label: `${l.firstName} ${l.lastName} · ${l.email}`,
        entity: l,
        Component: ({ label }) => <Sensitive>{label}</Sensitive>,
      }));
  }, [selectedLearnersIds, data]);

  return (
    <Select
      name="select-learners"
      onChange={onSelectLearner}
      options={options}
      isLoading={isLoading}
      inputValue={inputValue}
      onChangeInputValue={setInputValue}
      hasError={!!error}
    />
  );
};

export const AdminLiveSessionAddLearnersUsingAdvancedFilteringModal = () => {
  const { hide } = useModal();

  const onAddRows = useCallback((rows) => {
    hide({
      success: true,
      payload: { learners: rows },
    });
    return Promise.resolve(true);
  }, []);
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Add Learners</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Typography.p>
          Search for learners using various fields and complex operators
        </Typography.p>
        <Separator />
        <ListViewProvider
          key="learner-list-add-to-session"
          name="learner-list-add-to-session"
          persistToLocalStorage={false}
          useUrlQuery={false}
          paginationLimit={20}
          initialSorting={{ field: 'name', order: 'asc' }}
        >
          <Box>
            <AdminLearnerListTableFilters />
          </Box>
          <AdminLearnerListTable
            rowActions={[]}
            selectionMode="checkbox-without-the-ability-to-select-rows-that-are-not-visible"
            tableActions={[
              {
                name: 'Add Learners',
                handler: onAddRows,
              },
            ]}
          />
        </ListViewProvider>
      </SideModal.Body>
    </SideModal.Content>
  );
};

export const AdminLiveSessionAddLearnersFromAListOfLearnersModal = () => {
  const api = useApi();
  const learnerIdentifierOptions = useMemo(
    () => [
      { label: 'Email', value: 'email' },
      { label: 'Internal ID', value: 'customerInternalId' },
      ...getLearnerPropertiesFilterFields().map((f) => ({
        label: f.label.split('Learner ')[1],
        value: f.name,
      })),
    ],
    []
  );
  const [separator, setSeparator] = useState('');
  const [learnerIdentifier, setLearnerIdentifier] =
    useState<(typeof learnerIdentifierOptions)[number]['value']>('email');
  const [learnersString, setLearnersString] = useState('');
  const { hide } = useModal();
  const [listLearners, { isLoading, error }] = useLazyListLearnersQuery();

  const onSubmit = useCallback(async () => {
    const parsedSeparator = separator || '\n';
    const learnersTerms = learnersString
      .split(parsedSeparator)
      .map((l) => l.trim().toLowerCase())
      .filter((l) => !!l);
    if (!learnersTerms.length) {
      return;
    }
    const searchFilter: AppQueryFilter = {
      id: '',
      value: learnersTerms.map((term) => ({
        id: '',
        value: {
          field: { field: learnerIdentifier, label: learnerIdentifier },
          operator: {
            value: QueryOperator.Equals,
            type: QueryFilterOperatorType.Binary,
          },
          value: {
            value: term,
            label: term,
          },
        },
        combinator: QueryFilterCombinator.Or,
      })),
    };
    const searchQuery: AppQuery = {
      pagination: { disabled: true },
      filters: searchFilter,
    };
    const learnersResult = await listLearners(searchQuery);

    hide({
      success: true,
      payload: { learners: learnersResult.data?.rows },
    });
  }, [learnersString, learnerIdentifier, separator]);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Parse and Add Learners</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Typography.p>
          Paste a list of learners from an external tool or document and let us
          handle all the processing
        </Typography.p>
        <Separator />
        <Box>
          <Box>
            <FormField label="Separator">
              <Input
                name="select-delimiter"
                placeholder="Leave blank for newline"
                onChange={(e) => setSeparator(e.target.value)}
                value={separator}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="Identifier">
              <Select
                value={learnerIdentifier}
                options={learnerIdentifierOptions}
                name="select-identifier"
                initialValue={learnerIdentifier}
                // @ts-ignore
                onChange={(v) => setLearnerIdentifier(v.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Learners">
            <Sensitive>
              <Textarea
                name="search-terms"
                value={learnersString}
                onChange={(e) => setLearnersString(e.target.value)}
              />
            </Sensitive>
          </FormField>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label="Parse and Add Learners"
          onClick={onSubmit}
          disabled={!learnersString || !learnerIdentifier}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
