import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  AssignmentArgs,
  FetchListResponse,
  IApiService,
  LearningPath as LearningPathType,
  ListLearningPathResponse,
} from './types';

interface BaseItem {
  mandatory: boolean;
  sequential: boolean;
  order: number;
}
interface ElearningCourseItem extends BaseItem {
  elearningCourseId: string;
}
interface CourseItem extends BaseItem {
  courseId: string;
}
interface AssessmentFormItem extends BaseItem {
  assessmentFormId: string;
}

type CreateArgs = {
  name: string;
  description: string;
  thumbnail: string;
  duration: number;
  tagIds: string[];
  targetLearnerGroupIds: string[];
  competencyIds: string[];
  items: Array<ElearningCourseItem | CourseItem | AssessmentFormItem>;
};

export class LearningPath
  extends ApiService
  implements IApiService<'learningPath'>
{
  public paths: any;
  public serviceBase = '/learning_path';

  constructor(props) {
    super(props);

    this.paths = {
      list: () => this.serviceBase,
      create: () => this.serviceBase,
      get: (id) => `${this.serviceBase}/${id}`,
      remove: (id) => `${this.serviceBase}/${id}`,
      bulkDelete: () => `${this.serviceBase}`,
      update: (id) => `${this.serviceBase}/${id}`,
      getInstance: (id, instanceId) =>
        `${this.serviceBase}/${id}/instance/${instanceId}`,
      getInstances: (id) => `${this.serviceBase}/${id}/instance`,
      removeInstance: (id) => `${this.serviceBase}/${id}/instance`,
      bulkDeleteInstances: () => `${this.serviceBase}/instance`,
      getInstancesWithItemsAsColumns: (id) =>
        `${this.serviceBase}/${id}/instance/items`,
      listForSelect: () => `/list/learning_path`,
    };
  }

  list = (query: AppQuery): Promise<ListLearningPathResponse> =>
    this.api.get(this.paths.list(), { params: { query } });

  getInstances = ({
    id,
    query,
  }: {
    id: string;
    query: AppQuery;
  }): Promise<ListLearningPathResponse> =>
    this.api.get(this.paths.getInstances(id), { params: { query } });

  getInstancesWithItemsAsColumns = ({
    id,
    query,
  }: {
    id: string;
    query: AppQuery;
  }): Promise<
    FetchListResponse<{
      id: string;
      status: number;
      learnerId: string;
      items: { id: string; name: string; order: number }[];
      learner: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      };
    }>
  > =>
    this.api.get(this.paths.getInstancesWithItemsAsColumns(id), {
      params: { query },
    });

  create = (
    data: CreateArgs
  ): Promise<
    Omit<LearningPathType, 'competencies' | 'tags' | 'learner_groups'>
  > => this.api.post(this.paths.create(), snakelize(data));

  get = (id: string) => this.api.get<LearningPath>(this.paths.get(id));

  remove = (id: string) => this.api.delete<LearningPath>(this.paths.remove(id));

  bulkDelete = (ids: string[]) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });

  removeInstance = (instanceId: string) =>
    this.api.delete<LearningPath>(this.paths.removeInstance(instanceId));

  bulkDeleteInstances = (instanceIds) =>
    this.api.delete(this.paths.bulkDeleteInstances(), {
      data: { list: instanceIds },
    });

  update = (
    entity
  ): Promise<
    Omit<LearningPathType, 'competencies' | 'tags' | 'learner_groups'>
  > => this.api.patch(this.paths.update(entity.id), snakelize(entity));

  getInstance = ({ id, instanceId }) =>
    this.api.get<any>(this.paths.getInstance(id, instanceId));

  listForSelect = (input) =>
    this.api.get(this.paths.listForSelect(), {
      params: { query: { search: input } },
    }) as Promise<LearningPathType[]>;
}
