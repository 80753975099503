import { Slot } from '@radix-ui/react-slot';
import React, { createContext, useContext } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from 'react-dnd';

import { useClassNameProps, useTestProps } from '../../hooks';
import { IconButton } from '../Button/Button';
import * as Icon from '../Icon';
import { TidComponent } from '../index.types';
import './Draggable.scss';

type DraggableProps = TidComponent<{
  children: React.ReactNode;
  type: string;
  index: number;
  onDrop: (to: number, from: number) => void;
  className?: string;
  asChild?: boolean;
}>;

type DraggableContextType = {
  drag: ConnectDragSource;
  preview: ConnectDragPreview;
  drop: ConnectDropTarget;
};

const DraggableContext = createContext<DraggableContextType>(
  {} as DraggableContextType
);

export const Draggable = ({
  type,
  index,
  onDrop,
  className = '',
  children,
  asChild = false,
  ...props
}: DraggableProps) => {
  const commonProps = useClassNameProps(props);

  const [{ isDragging, draggedItemIndex }, drag, preview] = useDrag(
    () => ({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        draggedItemIndex: monitor.getItem()?.index,
      }),
    }),
    [index]
  );
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: type,
      collect: (monitor) => ({ isOver: monitor.isOver() }),
      drop: (item: { index: number }) => {
        const { index: dropIndex } = item;
        if (dropIndex !== index) {
          onDrop(dropIndex, index);
        }
      },
    }),
    [onDrop, index]
  );

  const classNameProps = useClassNameProps(
    'draggable',
    props,
    isOver && !isDragging
      ? `draggable--over-${draggedItemIndex > index ? 'top' : 'bottom'}`
      : null,
    isDragging ? 'draggable--dragged' : null
  );

  const Component = asChild ? Slot : 'div';

  if (asChild) {
    return (
      <DraggableContext.Provider value={{ drag, preview, drop }}>
        <Component
          ref={(node) => preview(drop(node))}
          {...classNameProps}
          {...commonProps}
        >
          {children}
        </Component>
      </DraggableContext.Provider>
    );
  }
  return (
    <DraggableContext.Provider value={{ drag, preview, drop }}>
      <Component
        ref={(node) => preview(drop(node))}
        {...classNameProps}
        {...commonProps}
      >
        {isDragging ? <DraggableOverlay /> : null}
        {children}
      </Component>
    </DraggableContext.Provider>
  );
};

const DraggableOverlay = () => (
  <div className="draggable__is-dragged-overlay" />
);

const DraggableHoverActions = ({
  children,
  asChild = false,
  ...props
}: TidComponent<{
  children: React.ReactNode;
  asChild?: boolean;
}>) => {
  const commonProps = useTestProps(props);
  const Component = asChild ? Slot : 'div';
  return (
    <Component className="draggable__hover-actions" {...commonProps}>
      {children}
    </Component>
  );
};

const DragButton = ({
  tooltipLabel = 'Move',
  className = '',
}: {
  tooltipLabel?: string;
  className?: string;
}) => {
  const { drag } = useContext(DraggableContext);
  return (
    <IconButton ref={drag} label={tooltipLabel} className={className}>
      <Icon.MoveIcon />
    </IconButton>
  );
};

Draggable.DragButton = DragButton;
Draggable.Overlay = DraggableOverlay;
Draggable.HoverActions = DraggableHoverActions;
