import React, { useState, useCallback, useMemo, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import './Tabs.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type TabsProps = TidComponent<{
  children: React.ReactElement[];
  initiallySelectedTab?: string;
  name: string;
  onChangeTab?: (tabName: string) => void;
  className?: string;
  listClassName?: string;
  excludeListBorder?: boolean;
}>;

type ParsedTab = {
  name: string;
  label: string;
  disabled: boolean;
  hidden?: boolean;
  listItemClassName: string;
  itemClassName: string;
  element: React.ReactElement;
  hashName: string;
  onLoad?: any;
};

const useTabsLocation = () => {
  // Prevent crashes in test environments
  try {
    return useLocation();
  } catch (e) {
    return { hash: '', pathname: '' };
  }
};
export const Tabs = (props: TabsProps) => {
  const {
    children,
    initiallySelectedTab,
    name,
    onChangeTab = null,
    listClassName = '',
    excludeListBorder = false,
  } = props;

  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('new-tabs', props);
  const listClassNameProps = useClassNameProps(
    'new-tabs__list',
    excludeListBorder ? 'new-tabs__list-item--no-border' : null,
    listClassName
  );

  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab);
  const { hash, pathname } = useTabsLocation();

  const parsedChildren = useMemo(() => {
    if (children.length) return children.filter((child) => !!child);
    return [children];
  }, [children]) as React.ReactElement[];

  const tabNames = useMemo(
    () => parsedChildren.map((child) => child.props.name),
    [parsedChildren]
  );

  const getInitialSelectedTab = useMemo(
    () => children.find((child) => !child.props.hidden),
    []
  );

  const actualSelectedTab = useMemo(() => {
    const hashNames = tabNames.map((tabName) => `${name}-${tabName}`);
    const hashTabNameIndex = hashNames.findIndex(
      (hashName) => `#${hashName}` === hash
    );
    if (!selectedTab && hashTabNameIndex > -1)
      return tabNames[hashTabNameIndex];

    if (!selectedTab && !initiallySelectedTab)
      return getInitialSelectedTab?.props?.name;
    if (!selectedTab && initiallySelectedTab) return initiallySelectedTab;
    return selectedTab;
  }, [selectedTab, initiallySelectedTab, tabNames, name, hash]);

  const header = useMemo(
    () => parsedChildren.find((child) => child.type === Header),
    [parsedChildren]
  );

  const tabs = useMemo(
    () =>
      parsedChildren.reduce((acc, child: ReactElement<TabProps>) => {
        if (!child || child.type !== Tab) return acc;

        const { label, disabled = false, hidden = false } = child.props;

        if (hidden) return acc;

        let itemClassName = child.props.className;
        let listItemClassName;
        if (disabled) {
          listItemClassName = `new-tabs__list-item--disabled ${listItemClassName}`;
          itemClassName = `new-tabs__item--disabled ${itemClassName}`;
        } else if (actualSelectedTab === child.props.name) {
          listItemClassName = `new-tabs__list-item--selected ${listItemClassName}`;
          itemClassName = `new-tabs__item--selected ${itemClassName}`;
        }

        acc[child.props.name] = {
          name: child.props.name,
          label,
          disabled,
          listItemClassName,
          itemClassName,
          element: child,
          hashName: `${name}-${child.props.name}`,
          onLoad: child.props.onLoad,
        };

        return acc;
      }, {}),
    [parsedChildren, actualSelectedTab, name]
  ) as ParsedTab[];

  const selectTab = useCallback(
    (tabName) => {
      let tab = tabs[tabName];

      if (!tab) return;
      if (tab.disabled) return;

      setSelectedTab(tabName);

      if (onChangeTab) onChangeTab(tabName);
      if (tab.onLoad) tab.onLoad(tabName);

      window.history.pushState(
        'Change Tab',
        'Title',
        `${pathname}#${tab.hashName}`
      );
    },
    [setSelectedTab, tabs, onChangeTab, pathname]
  );

  return (
    <div {...classNameProps} {...commonProps}>
      <ul {...listClassNameProps}>
        {Object.values(tabs).map((tab) => (
          <li
            key={tab.name}
            onClick={() => selectTab(tab.name)}
            className={`new-tabs__list-item ${tab.listItemClassName} ${
              excludeListBorder ? 'new-tabs__list-item--no-border' : ''
            }`}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      {header ? header : null}
      <div className="new-tabs__items">
        {tabs[actualSelectedTab] && (
          <div
            key={tabs[actualSelectedTab].name}
            className={`new-tabs__item ${tabs[actualSelectedTab].itemClassName}`}
          >
            {tabs[actualSelectedTab].element}
          </div>
        )}
      </div>
    </div>
  );
};

export type TabProps = TidComponent<{
  name: string;
  label: string;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  onLoad?: any;
}>;

export type HeaderProps = TidComponent<{
  className?: string;
  children: React.ReactNode;
}>;

export const Tab = ({ children, ...props }: TabProps) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('new-tabs__tab', props);
  return (
    <div {...classNameProps} {...commonProps}>
      {children}
    </div>
  );
};

export const Header = ({ children, ...props }: HeaderProps) => {
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps('new-tabs__header', props);
  return (
    <div {...classNameProps} {...commonProps}>
      {children}
    </div>
  );
};

Tabs.Tab = Tab;
Tabs.Header = Header;
