import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import { ApiQuerySchema } from '@nl-lms/common/shared';

import { LiveLearningRouterPrefix } from './live-learning.constants.sdk';
import {
  GetLiveCourseSchema,
  ListLiveSessionsSchema,
  LiveCourseWithLiveSessionsSchema,
} from './live-learning.schema.sdk';

const contract = initContract();

export const LearningPortalRouterContract = contract.router({
  listVisibleLiveCoursesWithLiveSessions: {
    method: 'GET',
    path: `${LiveLearningRouterPrefix}/live-course`,
    responses: ContractPaginatedResponse(LiveCourseWithLiveSessionsSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  getLiveCourse: {
    method: 'GET',
    path: `${LiveLearningRouterPrefix}/live-course/:liveCourseId`,
    responses: ContractStandardResponse(GetLiveCourseSchema),
    pathParams: z.object({ liveCourseId: z.string() }),
  },
  listLiveSessions: {
    method: 'GET',
    path: `${LiveLearningRouterPrefix}/live-session`,
    responses: ContractPaginatedResponse(ListLiveSessionsSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
});
