import React from 'react';

export const GridIconSmall = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="2" fill="currentColor" />
    <rect y="4" width="2" height="2" fill="currentColor" />
    <rect y="8" width="2" height="2" fill="currentColor" />
    <rect x="4" width="2" height="2" fill="currentColor" />
    <rect x="4" y="4" width="2" height="2" fill="currentColor" />
    <rect x="4" y="8" width="2" height="2" fill="currentColor" />
    <rect x="8" width="2" height="2" fill="currentColor" />
    <rect x="8" y="4" width="2" height="2" fill="currentColor" />
  </svg>
);
