import React, { useCallback, useEffect, useState } from 'react';

import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { SingleSelect } from '@nl-lms/ui/components';

import { useApi } from '../../../../../_common/services/api';

export const AdminLiveSessionVenueSelect = (props) => {
  const {
    onChange,
    name,
    country,
    region,
    city,
    maxPax,
    requirements,
    trainingSessionId,
    initialSelectedItem,
  } = props;
  const api = useApi();
  const [options, setOptions] = useState([]);

  const onLoadVenues = useCallback(
    async (country, region, city, maxPax, requirements) => {
      const filters = getAppQueryFilter(
        country,
        region,
        city,
        maxPax,
        requirements
      );
      const venues = await api.session.listVenues(
        {
          filters,
          pagination: { disabled: true },
        },
        trainingSessionId
      );
      return venues.map((l) => ({
        value: l.id,
        label: `${l.name} · ${l.fullAddress}`,
        entity: l,
      }));
    },
    []
  );

  useEffect(() => {
    async function getVenues() {
      const newOpt = await onLoadVenues(
        country,
        region,
        city,
        maxPax,
        requirements
      );
      // @ts-ignore
      setOptions(newOpt);
    }
    getVenues();
  }, []);

  return (
    <SingleSelect
      name={name}
      placeholder="Search venues by name"
      options={options}
      initialSelectedItem={initialSelectedItem}
      onChange={onChange}
    />
  );
};

const getAppQueryFilter = (
  country,
  region,
  city,
  maxPax,
  requirements
): AppQueryFilter => ({
  id: 'list-venues',
  combinator: QueryFilterCombinator.And,
  value: [
    {
      id: 'list-venues-country',
      combinator: QueryFilterCombinator.And,
      value: {
        field: {
          field: 'country',
          label: 'country',
        },
        operator: {
          value: QueryOperator.Equals,
          type: QueryFilterOperatorType.Unary,
        },
        value: {
          label: country,
          value: country,
        },
      },
    },
    {
      id: 'list-venues-city',
      combinator: QueryFilterCombinator.And,
      value: {
        field: {
          field: 'city',
          label: 'city',
        },
        operator: {
          value: QueryOperator.Equals,
          type: QueryFilterOperatorType.Unary,
        },
        value: {
          label: city,
          value: city,
        },
      },
    },
    {
      id: 'list-venues-region',
      combinator: QueryFilterCombinator.And,
      value: {
        field: {
          field: 'region',
          label: 'region',
        },
        operator: {
          value: QueryOperator.Equals,
          type: QueryFilterOperatorType.Unary,
        },
        value: {
          label: region,
          value: region,
        },
      },
    },
    {
      id: 'list-venues-max-pax',
      combinator: QueryFilterCombinator.And,
      value: {
        field: {
          field: 'max_pax',
          label: 'max_pax',
        },
        operator: {
          value: QueryOperator.GreaterThanEqual,
          type: QueryFilterOperatorType.Unary,
        },
        value: {
          label: maxPax,
          value: maxPax,
        },
      },
    },
    {
      id: 'list-venues-requirements',
      combinator: QueryFilterCombinator.And,
      value: {
        field: {
          field: 'requirements',
          label: 'requirements',
        },
        operator: {
          value: QueryOperator.Contains,
          type: QueryFilterOperatorType.Unary,
        },
        value: {
          label: requirements,
          value: requirements,
        },
      },
    },
  ],
});
