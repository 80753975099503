import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CostType } from '@nl-lms/sdk/backend';
import { Sensitive, SingleSelect } from '@nl-lms/ui/components';

import { useApi } from '../../../../../_common/services/api';

export const AdminLiveSessionCostTypeSelect = ({
  onChange,
  currentCost,
  allowedResourceType,
  returnEntireItemOnChange = false,
}) => {
  const api = useApi();

  const [costTypesOptions, setCostTypesOptions] = useState([]);
  const currentInputRef = useRef<HTMLInputElement>();
  const onLoadCostTypes = useCallback(async () => {
    const costTypes = (await api.session.listCostTypes()) as any as CostType[];

    let allowedCostTypes = costTypes;
    if (allowedResourceType)
      allowedCostTypes = costTypes.filter(
        (c) => c.resourceType === allowedResourceType
      );

    return allowedCostTypes.map((cost) => ({
      value: cost.id,
      label: `${
        cost.resourceType ? `${cost.resourceType}/${cost.name}` : cost.name
      } (${cost.unit})`,
      entity: cost,
      Component: ({ label }) => <Sensitive>{label}</Sensitive>,
    }));
  }, [allowedResourceType]);

  useEffect(() => {
    async function getCostTypes() {
      const newOpt = await onLoadCostTypes();
      // @ts-ignore
      setCostTypesOptions(newOpt);
    }

    getCostTypes();
  }, []);

  // TODO find out what this does
  // useEffect(() => {
  //   currentInputRef.current.value = currentCost.resourceType
  //     ? `${currentCost.resourceType}/${currentCost.name}`
  //     : '';
  // }, [currentCost]);

  const initialSelectedCostType = useMemo(
    () =>
      costTypesOptions.find(
        // @ts-ignore
        (option) => option.value === currentCost?.costTypeId
      ),
    [costTypesOptions, currentCost]
  );

  const onSelectCostType = (value) => {
    if (value && returnEntireItemOnChange) {
      const costType = costTypesOptions.find(
        // @ts-ignore
        (type) => type.value === value.value
      );
      // don't do this, kids. listen to your core values
      // @ts-ignore
      onChange({ ...costType.entity, value: value.value, label: value.label });
    } else if (value && !returnEntireItemOnChange) {
      onChange(value);
    }
  };

  const onChangeInputValue = (value) => {
    if (!value) {
      onChange({
        resourceType: null,
        name: null,
        id: null,
        unit: null,
      });
    }
  };

  return (
    <SingleSelect
      name="costTypes"
      placeholder="Search cost types"
      options={costTypesOptions}
      onChange={onSelectCostType}
      // @ts-ignore
      ref={currentInputRef}
      initialSelectedItem={initialSelectedCostType}
      selectedItem={initialSelectedCostType}
      onChangeInputValue={onChangeInputValue}
      returnEntireItemOnChange={returnEntireItemOnChange}
    />
  );
};
