import * as z from 'zod';

import { NotificationMessageBlockSchema } from '@nl-lms/common/feature/types';

export const EmailTitleBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('title'),
  attributes: z.object({
    translations: z.record(z.string(), z.string()),
  }),
  value: z.string(),
});

export const EmailTextBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('text'),
  attributes: z.object({
    translations: z.record(z.string(), z.string()),
    type: z.union([z.literal('title'), z.literal('footer')]).optional(),
  }),
  value: z.string(),
});

export const EmailSeparatorBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('separator'),
});

export const EmailPlainTextBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('plain-text'),
  attributes: z.object({
    translations: z.record(z.string(), z.string()),
  }),
  value: z.string(),
});

export const EmailHeaderBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('header'),
});

export const EmailButtonsBlockSchema = NotificationMessageBlockSchema.extend({
  type: z.literal('buttons'),
  attributes: z.object({
    style: z.record(z.string(), z.string()),
  }),
  value: z.array(
    z.object({
      href: z.string(),
      type: z.union([z.literal('confirm'), z.literal('decline')]).optional(),
      translations: z.record(z.string(), z.string()),
      label: z.string(),
    })
  ),
});

export const EmailMessageBlockSchema = z.union([
  EmailTitleBlockSchema,
  EmailTextBlockSchema,
  EmailSeparatorBlockSchema,
  EmailPlainTextBlockSchema,
  EmailHeaderBlockSchema,
  EmailButtonsBlockSchema,
]);
