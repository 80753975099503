import { z } from 'zod';

import { FieldConditionSchema, TimeDeltaSchema } from '@nl-lms/common/shared';

import { NotificationTemplateSchema } from '../notification/notification-template.types';

export enum LearningAssignmentType {
  'ELEARNING' = 1,
  'ASSESSMENT',
  'LEARNING_PATH',
  'LIVE_COURSE',
  'SURVEY',
  'CHECKLIST',
}

export const LearningAssignmentAudienceSchema = z.object({
  learnerIds: z.array(z.string()).optional(),
  learnerGroupIds: z.array(z.string()).optional(),
});

export type LearningAssignmentAudience = z.infer<
  typeof LearningAssignmentAudienceSchema
>;

export type LearningAssignmentLearningItemInstanceStatus =
  | 'COMPLETED'
  | 'COMPLETED_PASSED'
  | 'PASSED'
  | 'CANCELED'
  | 'IN_PROGRESS'
  | 'NOT_STARTED';

export const LearningAssignmentDateSchema = z
  .union([
    z.object({
      type: z.literal('fixed'),
      value: z.string().nullable(),
    }),
    z.object({
      type: z.literal('relative'),
      value: TimeDeltaSchema.nullable(),
    }),
  ])
  .nullable();

export type LearningAssignmentRuleStarDate = z.infer<
  typeof LearningAssignmentDateSchema
>;

const LearningAssignmentRuleConditionsEquationSchema = z.object({
  value: z.union([
    z.string(),
    z.lazy(() => z.array(LearningAssignmentRuleConditionsEquationSchema)),
  ]),
  combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
});

export type LearningAssignmentRuleConditionsEquation = z.infer<
  typeof LearningAssignmentRuleConditionsEquationSchema
>;

export const LearningAssignmentRuleSettingsSchema = z.object({
  // triggerNTimes: z.union([z.number(), z.boolean()]).nullish(),
  triggerNTimes: z.number().nullish(),
  // cancel planned instance when calling resolve
  learningAssignmentInstancesLimit: z.number().nullish(),
});

export type LearningAssignmentRuleSettings = z.infer<
  typeof LearningAssignmentRuleSettingsSchema
>;

export const LearningAssignmentRuleSchema = z.object({
  id: z.string(),
  name: z.string(),
  learningAssignmentId: z.string(),
  startDate: LearningAssignmentDateSchema.nullable(),
  conditionsEquation: LearningAssignmentRuleConditionsEquationSchema,
  active: z.boolean(),
  settings: LearningAssignmentRuleSettingsSchema.nullable(), // mark as failed/completed
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignmentRule = z.infer<
  typeof LearningAssignmentRuleSchema
>;

export const LearningAssignmentRuleMatchSchema = FieldConditionSchema;

export type LearningAssignmentRuleMatch = z.infer<
  typeof LearningAssignmentRuleMatchSchema
>;

export const LearningAssignmentRuleConditionSchema = z.object({
  id: z.string(),
  learningAssignmentRuleId: z.string(),
  eventName: z.string(),
  match: LearningAssignmentRuleMatchSchema.nullable(),
  referenceId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignmentRuleCondition = z.infer<
  typeof LearningAssignmentRuleConditionSchema
>;

export const LearningAssignmentNotificationSchema = z.discriminatedUnion(
  'name',
  [
    z.object({
      name: z.literal('notification'),
      enabled: z.boolean(),
      payload: z.object({
        delta: TimeDeltaSchema.nullish(),
        template: NotificationTemplateSchema.omit({
          id: true,
          updatedAt: true,
          createdAt: true,
        }),
      }),
    }),
    z.object({
      name: z.literal('reminder'),
      enabled: z.boolean(),
      payload: z.object({
        delta: TimeDeltaSchema.nullish(),
        template: NotificationTemplateSchema.omit({
          id: true,
          updatedAt: true,
          createdAt: true,
        }),
      }),
    }),
  ]
);

export type LearningAssignmentNotification = z.infer<
  typeof LearningAssignmentNotificationSchema
>;

export const LearningAssignmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  learnerGroupIds: z.array(z.string()),
  learnerIds: z.array(z.string()),
  dueDate: z.date().nullable(),
  dueDateDelta: TimeDeltaSchema.nullable(),
  mandatory: z.boolean(),
  learningProgramId: z.string().nullable(),
  assessmentFormId: z.string().nullable(),
  learningPathId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  courseId: z.string().nullable(),
  surveyFormId: z.string().nullable(),
  checklistId: z.string().nullable(),
  userId: z.string().nullable(),
  showAfterDueDate: z.boolean(),
  notifications: z.array(LearningAssignmentNotificationSchema),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignment = z.infer<typeof LearningAssignmentSchema>;

export const LearningAssignmentInstanceSchema = z.object({
  id: z.string(),
  learningAssignmentId: z.string(),
  learnerId: z.string(),
  learningProgramInstanceId: z.string().nullable(),
  learningAssignmentPlannedInstanceId: z.string().nullable(),
  dueDate: z.date().nullable(),
  mandatory: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignmentInstance = z.infer<
  typeof LearningAssignmentInstanceSchema
>;

export const LearningAssignmentPlannedInstanceSchema = z.object({
  id: z.string(),
  learningAssignmentId: z.string(),
  learningAssignmentRuleId: z.string().nullable(),
  learningProgramInstanceId: z.string().nullable(),
  rule: LearningAssignmentRuleSchema.extend({
    conditions: z.array(LearningAssignmentRuleConditionSchema),
    learningAssignment: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }).nullable(),
  triggersEquation: LearningAssignmentRuleConditionsEquationSchema.nullable(),
  learnerId: z.string(),
  resolved: z.boolean().nullable(),
  created: z.boolean(),
  scheduledOn: z.date().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignmentPlannedInstance = z.infer<
  typeof LearningAssignmentPlannedInstanceSchema
>;

export const LearningAssignmentPlannedInstanceTriggerSchema = z.object({
  id: z.string(),
  learningAssignmentPlannedInstanceId: z.string(),
  learnerId: z.string(),
  referenceId: z.string(),
  reference: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullish(),
  eventName: z.string(),
  triggered: z.boolean(),
  triggeredOn: z.date().nullable(),
  match: LearningAssignmentRuleMatchSchema.nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningAssignmentPlannedInstanceTrigger = z.infer<
  typeof LearningAssignmentPlannedInstanceTriggerSchema
>;

const LearningAssignmentContent = z.object({
  id: z.string(),
  name: z.string(),
});

export enum LearningAssignmentLearningContentType {
  'elearning',
  'assessment',
  'live-learning',
  'survey',
  'checklist',
  'learning-path',
}

export const ListLearningAssignmentSchema = LearningAssignmentSchema.extend({
  elearningCourse: LearningAssignmentContent.nullable(),
  assessmentForm: LearningAssignmentContent.nullable(),
  learningPath: LearningAssignmentContent.nullable(),
  liveCourse: LearningAssignmentContent.nullable(),
  surveyForm: LearningAssignmentContent.nullable(),
  checklist: LearningAssignmentContent.nullable(),
  learningProgram: LearningAssignmentContent.nullable(),
  content: z.object({
    type: z.nativeEnum(LearningAssignmentLearningContentType),
    id: z.string(),
    name: z.string(),
  }),
});

export type ListLearningAssignment = z.infer<
  typeof ListLearningAssignmentSchema
>;

export const ListLearningAssignmentRuleSchema =
  LearningAssignmentRuleSchema.extend({
    conditions: z.array(LearningAssignmentRuleConditionSchema),
    learningAssignment: z.object({
      id: z.string(),
      name: z.string(),
    }),
  });

export enum LearningAssignmentInstanceStatus {
  'COMPLETED',
  'COMPLETED_PASSED',
  'COMPLETED_FAILED',
  'SCORING',
  'FAILED',
  'PASSED',
  'CANCELED',
  'IN_PROGRESS',
  'NOT_STARTED',
  'NOT_INVITED',
  'INVITED',
  'REGISTERED',
  'DECLINED',
  'ATTENDED',
  'ABSENT',
  'GO_SHOW',
  'APPROVAL',
  'APPROVAL_DECLINED',
  'WAITING_LIST',
  'CREATING',
}

export enum LearningAssignmentPlannedInstanceStatus {
  'PLANNED',
  'SCHEDULED',
  'CREATED',
}
