import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Link.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type Props = TidComponent<{
  className?: string;
  to?: string;
  raw?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  target?: '_blank' | '_self';
}>;

export const Link = ({
  to,
  children,
  raw,
  onClick,
  target,
  ...props
}: Props) => {
  const classNameProps = useClassNameProps('link', props);
  const commonProps = useTestProps(props);
  return onClick ? (
    <span {...classNameProps} {...commonProps} onClick={onClick}>
      {children}
    </span>
  ) : raw ? (
    <a href={to} target={target || '_blank'} {...commonProps}>
      {children}
    </a>
  ) : (
    <RouterLink
      {...classNameProps}
      {...commonProps}
// @ts-ignore
      to={to}
      target={target || '_self'}
    >
      {children}
    </RouterLink>
  );
};
