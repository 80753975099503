import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { LearningProgramRouterContract } from './learning-program.router-contract.sdk';

export const getLearningProgramClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'learningProgramStore';

  const tsRest = buildTsRestInstance<typeof LearningProgramRouterContract>(
    apiInstance,
    LearningProgramRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listLearningPrograms: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningProgramRouterContract.listLearningPrograms.query
          >
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.listLearningPrograms.responses)['200']
      >
    >;
    searchLearningContent: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningProgramRouterContract.searchLearningContent.query
          >
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.searchLearningContent.responses)['200']
      >
    >;
    createLearningProgram: RtkMutation<
      RtkFullParams<
        z.infer<typeof LearningProgramRouterContract.createLearningProgram.body>
      >,
      z.infer<
        (typeof LearningProgramRouterContract.createLearningProgram.responses)['200']
      >
    >;
    updateLearningProgram: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.updateLearningProgram.body
        >,
        z.infer<
          typeof LearningProgramRouterContract.updateLearningProgram.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.updateLearningProgram.responses)['200']
      >
    >;
    addLearningProgramAssignments: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramAssignments.body
        >,
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramAssignments.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.addLearningProgramAssignments.responses)['200']
      >
    >;
    removeLearningPrograms: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.removeLearningPrograms.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.removeLearningPrograms.responses)['200']
      >
    >;
    removeLearningProgramAssignments: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.removeLearningProgramAssignments.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.removeLearningProgramAssignments.responses)['200']
      >
    >;
    getLearningProgram: RtkQuery<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.getLearningProgram.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.getLearningProgram.responses)['200']
      >
    >;
    getLearningProgramInstance: RtkQuery<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.getLearningProgramInstance.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.getLearningProgramInstance.responses)['200']
      >
    >;
    listLearningProgramRules: RtkQuery<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.listLearningProgramRules.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.listLearningProgramRules.responses)['200']
      >
    >;
    addLearningProgramRules: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramRules.body
        >,
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramRules.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.addLearningProgramRules.responses)['200']
      >
    >;
    addLearningProgramInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramInstances.body
        >,
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramInstances.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.addLearningProgramInstances.responses)['200']
      >
    >;
    listLearningProgramInstances: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningProgramRouterContract.listLearningProgramInstances.query
          >
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.listLearningProgramInstances.responses)['200']
      >
    >;
    listLearningProgramInstancesForALearner: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<
            typeof LearningProgramRouterContract.listLearningProgramInstancesForALearner.query
          >
        >,
        z.infer<
          typeof LearningProgramRouterContract.listLearningProgramInstancesForALearner.pathParams
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.listLearningProgramInstancesForALearner.responses)['200']
      >
    >;
    resolveLearningProgramInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.resolveLearningProgramInstances.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.resolveLearningProgramInstances.responses)['200']
      >
    >;
    removeLearningProgramInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.removeLearningProgramInstances.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.removeLearningProgramInstances.responses)['200']
      >
    >;
    upsertAutoEnrollRule: RtkMutation<
      RtkFullParams<
        z.infer<typeof LearningProgramRouterContract.upsertAutoEnrollRule.body>
      >,
      z.infer<
        (typeof LearningProgramRouterContract.upsertAutoEnrollRule.responses)['200']
      >
    >;
    addLearningProgramAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.addLearningProgramAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.addLearningProgramAssignmentInstances.responses)['200']
      >
    >;
    scheduleLearningProgramAssignmentInstances: RtkMutation<
      RtkFullParams<
        z.infer<
          typeof LearningProgramRouterContract.scheduleLearningProgramAssignmentInstances.body
        >
      >,
      z.infer<
        (typeof LearningProgramRouterContract.scheduleLearningProgramAssignmentInstances.responses)['200']
      >
    >;
  }>(tsRest, LearningProgramRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
