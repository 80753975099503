export const LearningProgramRouterPrefix = '/learning-program';

export const LearningProgramScope = {
  viewLearningPrograms: { resource: 'learning_program', action: 'view' },
  createLearningPrograms: {
    resource: 'learning_program',
    action: 'create',
  },
  deleteLearningPrograms: {
    resource: 'learning_program',
    action: 'delete',
  },
  updateLearningPrograms: {
    resource: 'learning_program',
    action: 'update',
  },
  viewLearningProgramInstances: {
    resource: 'learning_program_instance',
    action: 'view',
  },
  deleteLearningProgramInstances: {
    resource: 'learning_program_instance',
    action: 'delete',
  },
  createLearningProgramInstances: {
    resource: 'learning_program_instance',
    action: 'create',
  },
  updateLearningProgramInstances: {
    resource: 'learning_program_instance',
    action: 'update',
  },
  portal: {
    viewLearningProgramInstance: {
      resource: 'portal_learning_program_instance',
      action: 'view',
    },
  },
} as const;

export const LearningProgramDomainEventNames = {
  LearnerJoinedAudience: 'learning-program.learner-joined-audience',
  LearningProgramInstanceCompleted: 'learning-program-instance.completed',
  LearningProgramInstanceStarted: 'learning-program-instance.started',
  LearningProgramInstanceCreated: 'learning-program-instance.created',
  LearningProgramInstanceRemoved: 'learning-program-instance.removed',
  LearningProgramInstanceFailed: 'learning-program-instance.failed',
  LearningProgramInstanceBlocked: 'learning-program-instance.blocked',
} as const;

export const LearningProgramInstanceNotificationAudienceName =
  'learning-program-instance-audience';

export const LearningProgramAutomation = {
  entityName: 'learning-program',
  autoEnrollRuleName: 'auto-enroll',
  actions: {
    markInstanceAsCompleted: 'mark-instance-as-completed',
    markInstanceAsFailed: 'mark-instance-as-failed',
    createInstance: 'create-instance',
  },
};

// TODO: This should be moved in a general way of handling different notification audiences and properties for notifications
export const LearningProgramInitialNotificationTemplateName =
  'LearningProgramInitialNotificationTemplate';
export const LearningProgramNotificationMentionOptions = [
  {
    id: '{{learner.firstName}}',
    label: '{{learner.firstName}}',
  },
  { id: '{{learner.lastName}}', label: '{{learner.lastName}}' },
  { id: '{{learner.email}}', label: '{{learner.email}}' },
  {
    id: '{{learningProgram.name}}',
    label: '{{learningProgram.name}}',
  },
  {
    id: '{{portalUrl}}',
    label: '{{portalUrl}}',
  },
];

// TODO: This is a temp solution
// We use it in the learner app in order to limit what a learner can see/do
// It will be removed once we get rid of the learning path functionality
export const LearningPathScope = {
  portal: {
    viewLearningPath: {
      resource: 'portal_learning_path',
      action: 'view',
    },
    viewLearningPathInstance: {
      resource: 'portal_learning_path_instance',
      action: 'view',
    },
  },
} as const;
