import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Tag } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  Input,
  SideModal,
  Typography,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';

type Props = {
  tag?: Partial<Tag>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  scope: yup.string().nullable(),
  adminOnly: yup.boolean(),
});

export const AdminTagEditFormSideModal = ({ tag = {} }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...tag,
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateTagMutation',
    createHookName: 'useCreateTagMutation',
    entityId: tag.id,
    parseEntity: (entity) => ({ ...entity }),
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {tag.id ? 'Edit Tag' : 'Create New Tag'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Tag Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Title"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="Scope" errorMessage={errors?.scope?.message}>
              <Input
                {...register('scope')}
                hasError={!!errors?.scope?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Admin Only"
              errorMessage={errors?.adminOnly?.message}
            >
              <Controller
                name="adminOnly"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    name="adminOnly"
                    label="Admin Only"
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={tag.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
