import {
  AppQuery,
  constants as C,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import { _ } from '@nl-lms/vendor';

import { ApiService, snakelize } from '../lib';
import { IApiService, Learner as ILearner } from './types';

export class Learner extends ApiService implements IApiService<'learner'> {
  public paths: any;
  public serviceBase: string = '/resource/learner';

  constructor(props) {
    super(props);
    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => `${this.serviceBase}/list`,
      listForManager: () => `${this.serviceBase}/for_manager`,
      create: () => `${this.serviceBase}`,
      update: (id) => `${this.serviceBase}/${id}`,
      importLearners: () => `${this.serviceBase}/import`,
      bulkDelete: () => `${this.serviceBase}`,
      remove: (id) => `${this.serviceBase}/${id}`,
      listForSelect: () => `${this.serviceBase}/all`,
      sync: () => `${this.serviceBase}/sync`,
      listByIds: () => `${this.serviceBase}/by_ids`,

      searchMultipleLearners: () => `${this.serviceBase}/search_multiple`,
      listGroups: () => `${this.serviceBase}/group`,
      exportGroups: () => `${this.serviceBase}/group/export`,
      listGroupsForSelect: () => `/list/learner/groups`,
      createGroup: () => `${this.serviceBase}/group`,
      updateGroup: (id) => `${this.serviceBase}/group/${id}`,
      getGroup: (id) => `${this.serviceBase}/group/${id}`,
      removeGroup: () => `${this.serviceBase}/group`,
      importLearnerGroups: () => `${this.serviceBase}/group/import`,
      listLearningActivity: (learnerId) =>
        `${this.serviceBase}/${learnerId}/learning_activity`,
    };
  }

  get = (id) =>
    this.api.get(this.paths.get(id)).then((entity: any) =>
      Promise.resolve({
        ...entity,
        managerId: entity?.hierarchicManagers[0]?.id,
        managerEmail: entity?.hierarchicManagers[0]?.email,
        managerName: entity.hierarchicManagers[0]
          ? `${entity.hierarchicManagers[0].firstName} ${entity.hierarchicManagers[0].lastName}`
          : null,
      })
    );

  list = (query: AppQuery) =>
    this.api.post<null, { rows: ILearner[]; count: number }>(
      this.paths.list(),
      {
        query,
      }
    );

  listForManager = (query: AppQuery) =>
    this.api.get(this.paths.listForManager(), { params: { query } });

  create = (entity) =>
    this.api.post<null, ILearner>(
      this.paths.create(),
      snakelize(parseEntity(entity))
    );

  update = (entity) =>
    this.api.patch(
      this.paths.update(entity.id),
      snakelize(parseEntity(entity))
    );

  importLearners = (file) => {
    const data = new FormData();
    data.append('file', file, file.name);
    return this.api.post(this.paths.importLearners(), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };

  listForSelect = (query: AppQuery) =>
    this.api.get<null, ILearner[]>(this.paths.listForSelect(), {
      params: { query },
    });

  listByIds = (ids) => {
    const query: AppQuery = {
      pagination: { disabled: true },
      filters: {
        id: 'ids',
        value: {
          field: { field: 'id', label: 'learnerId' },
          operator: {
            value: QueryOperator.Includes,
            type: QueryFilterOperatorType.Binary,
          },
          value: { value: ids, label: 'ids' },
        },
      },
    };
    return this.list(query);
  };

  searchMultipleLearners = (learnersSearchInputValues) =>
    this.api.post(this.paths.searchMultipleLearners(), {
      learners_search_input_values: learnersSearchInputValues,
    });

  sync = () => {
    return this.api.patch(this.paths.sync());
  };

  listLearningActivity = ({ learnerId, query }) =>
    this.api.get(this.paths.listLearningActivity(learnerId), {
      params: { query },
    });
}

const parseEntity = (entity) => {
  let { LearnerRelationships } = entity;
  let managerRelationship = null;
  if (entity.managerId) {
    // @ts-ignore
    managerRelationship = {
      relatedLearnerId: entity.managerId,
      type: C.LEARNER.RELATIONSHIP_TYPES.MANAGER,
    };
    if (LearnerRelationships && LearnerRelationships.length > 0) {
      // @ts-ignore
      managerRelationship.id = LearnerRelationships[0].relationshipId;
      // @ts-ignore
      managerRelationship.learnerId = LearnerRelationships[0].learnerId;
    }
  }
  const relationships = managerRelationship ? [managerRelationship] : [];
  return {
    ...entity,
    LearnerRelationships: relationships,
    customerInternalId: entity.customerInternalId
      ? entity.customerInternalId
      : entity.email,
  };
};
