import * as Popover from '@radix-ui/react-popover';
import React from 'react';

import './FloatingCard.scss';

const FloatingCardRoot = ({
  children,
  ...rest
}: React.ComponentProps<typeof Popover.Root>) => {
  return <Popover.Root {...rest}>{children}</Popover.Root>;
};

FloatingCardRoot.displayName = 'FloatingCard';

const FloatingCardTrigger = ({
  children,
  asChild = false,
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  return <Popover.Trigger asChild={asChild}>{children}</Popover.Trigger>;
};

const FloatingCardContent = ({
  children,
  className = '',
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof Popover.Content>) => {
  return (
    <Popover.Portal>
      <Popover.Content className={`floating-card ${className}`} {...rest}>
        {children}
      </Popover.Content>
    </Popover.Portal>
  );
};

const FloatingCardArrow = (
  props: React.ComponentProps<typeof Popover.Arrow>
) => <Popover.Arrow className="floating-card__arrow" {...props} />;

export const FloatingCard = Object.assign(FloatingCardRoot, {
  Trigger: FloatingCardTrigger,
  Close: Popover.Close,
  Arrow: FloatingCardArrow,
  Anchor: Popover.Anchor,
  Content: FloatingCardContent,
});
