import React, { CSSProperties, useContext, useMemo, useRef } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import { Box, Button, FlowGraph, Icon } from '@nl-lms/ui/components';

import { LearningProgramAddAssignmentsButtonTrigger } from '../learning-program/LearningProgramAddAssignmentsSideModal';
import { LearningProgramGraphRuleEditModal } from './LearningProgramGraphRuleEditModal';
import './LearningProgramRulesGraph.scss';
import { LearningProgramRulesGraphContext } from './LearningProgramRulesGraphContext';
import { LearningProgramRulesGraphLegend } from './LearningProgramRulesGraphLegend';
import { LearningProgramRulesGraphSidebar } from './LearningProgramRulesGraphSidebar';

type LearningProgramRulesGraphProps = {
  id: string;
  containerStyle?: CSSProperties;
};

export const LearningProgramRulesGraph = ({
  id,
  containerStyle,
}: LearningProgramRulesGraphProps) => {
  const {
    assignments,
    onUpsertEdge,
    isConnectable,
    learningProgramId,
    showLegend,
    setShowLegend,
    showConnectModal,
    onDragNode,
    unconnectedEntities,
  } = useContext(LearningProgramRulesGraphContext);

  const reactFlowWrapper = useRef(null);

  const uniqueLearningItemIds = useMemo(
    () =>
      assignments
        ?.map(
          (assignment) =>
            assignment?.assessmentFormId ||
            assignment?.elearningCourseId ||
            assignment?.checklistId ||
            assignment?.courseId ||
            assignment?.learningPathId ||
            assignment?.surveyFormId
        )
        ?.filter((learningId) => !!learningId) || [],
    [assignments]
  );

  return (
    <ReactFlowProvider>
      <div
        className={`learning-program-graph ${
          !isConnectable ? 'learning-program-graph--noedit' : ''
        }`}
      >
        <FlowGraph
          id={id}
          context={LearningProgramRulesGraphContext}
          ref={reactFlowWrapper}
          style={containerStyle}
          minZoom={0.4}
          maxZoom={1.5}
          fitViewOptions={{ padding: 20, minZoom: 0.49 }}
        >
          <FlowGraph.Background />
          <FlowGraph.Map />
          <FlowGraph.Controls>
            {isConnectable && (
              <>
                <Box flex={{ flexDirection: 'row' }}>
                  <LearningProgramAddAssignmentsButtonTrigger
                    learningProgramId={learningProgramId as string}
                    // @ts-ignore
                    learningItemIds={uniqueLearningItemIds}
                  >
                    <div className="learning-program-graph__node-add-button">
                      <Button
                        label="add content"
                        icon={<Icon.AddIcon />}
                        iconPosition={'left'}
                        small
                        regular
                      />
                    </div>
                  </LearningProgramAddAssignmentsButtonTrigger>
                  {!showLegend ? (
                    <div className="learning-program-graph__legend-button">
                      <Button
                        label={`view legend`}
                        icon={<Icon.HelpIcon />}
                        iconPosition={'left'}
                        onClick={() => setShowLegend(!showLegend)}
                        small
                        regular
                      />
                    </div>
                  ) : null}
                </Box>
                {showConnectModal ? (
                  <LearningProgramGraphRuleEditModal
                    onSubmit={onUpsertEdge as any}
                  />
                ) : null}
              </>
            )}
          </FlowGraph.Controls>
        </FlowGraph>
        {isConnectable ? (
          <>
            {unconnectedEntities?.length ? (
              <LearningProgramRulesGraphSidebar
                layout="TB"
                onDragNode={onDragNode}
              />
            ) : null}
            <LearningProgramRulesGraphLegend layout="TB" />
          </>
        ) : null}
      </div>
    </ReactFlowProvider>
  );
};
