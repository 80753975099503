import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Rating, StatusTag } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { translateDuration } from '@nl-lms/ui/utils';

import {
  CardIconCaption,
  LearnerLearningCard,
} from '../../../../_common/components';
import './LearnerLearningOpportunityCard.scss';

type Props = {
  name: string;
  description: string;
  type: number;
  duration: any;
  thumbnail: string;
  actions: ReactElement[];
  status?: string | number;
  competencies?: any[];
  courseType?: number | string;
  rating?: number | null;
  ratingCount?: number | null;
};

export const LearnerLearningOpportunityCard = ({
  name,
  description,
  type,
  courseType,
  duration,
  thumbnail,
  actions,
  competencies,
  status,
  rating,
  ratingCount,
}: Props) => {
  let durationCaption;
  let typeCaption;
  let TypeIcon;
  let thumbnailType;
  const { t } = useTranslation('learner');
  let statusDetail;
  switch (type) {
    case C.I_LEARNER_LEARNING_TYPES.ASSESSMENT:
      typeCaption = t('common.learningunit.assessment');
      TypeIcon = Icon.BorderedCheckIconSmall;
      durationCaption = `${duration} ${
        duration === 1 ? t('common.time.minute') : t('common.time.minutes')
      }`;
      thumbnailType = LearnerLearningCard.ThumbnailType.Assessment;
      break;
    case C.I_LEARNER_LEARNING_TYPES.ELEARNING:
      typeCaption = t('common.learningunit.elearning');
      if (
        courseType !== C.I_CONTENT_FILE_VERSIONS.SCORM2004 &&
        courseType !== C.I_CONTENT_FILE_VERSIONS.SCORM12 &&
        // @ts-ignore
        C.CONTENT_FILE_VERSIONS[courseType]
      ) {
        // @ts-ignore
        typeCaption = C.CONTENT_FILE_VERSIONS[courseType];
      }
      TypeIcon = Icon.PlayerIconSmall;
      thumbnailType = LearnerLearningCard.ThumbnailType.Elearning;
      statusDetail = status ? C.ELEARNING_SESSION_STATUSES[status] : null;
      break;
    case C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH:
      typeCaption = t('common.learningunit.learningpath');
      TypeIcon = Icon.GitIconSmall;
      durationCaption = `${duration} ${
        duration === 1 ? t('common.time.hour') : t('common.time.hours')
      }`;
      thumbnailType = LearnerLearningCard.ThumbnailType.LearningPath;
      statusDetail = status ? C.LEARNING_PATH_INSTANCE_STATUSES[status] : null;
      break;
    default:
      const isInClass = (
        [C.I_LEARNING_TYPES.IN_CLASS, C.I_LEARNING_TYPES.VIRTUAL_CLASS] as any[]
      ).includes(courseType);
      typeCaption = isInClass
        ? t('common.learningunit.inclass')
        : t('common.learningunit.conference');
      TypeIcon = Icon.ClipboardIconSmall;
      durationCaption = translateDuration(duration);
      thumbnailType = LearnerLearningCard.ThumbnailType.InClass;
      statusDetail = status ? C.LEARNER_STATUS[status] : null;
  }

  return (
    <LearnerLearningCard className="learner-learning-activity-card">
      <LearnerLearningCard.Thumbnail
        imageUrl={thumbnail}
        type={thumbnailType}
      />

      <LearnerLearningCard.Title>{name}</LearnerLearningCard.Title>

      <CardIconCaption icon={<TypeIcon />} caption={typeCaption} />

      {duration && durationCaption ? (
        <CardIconCaption
          icon={<Icon.ClockIconSmall />}
          caption={durationCaption}
        />
      ) : null}

      <LearnerLearningCard.Description description={description} />

      {status && statusDetail ? (
        <LearnerLearningCard.Detail>
          <StatusTag status={statusDetail} />
        </LearnerLearningCard.Detail>
      ) : null}

      {!!rating && !!ratingCount && (
        <LearnerLearningCard.Rating>
          <Rating total={5} rating={rating || 0} reviewCount={ratingCount} />
        </LearnerLearningCard.Rating>
      )}

      {actions.map((action, index) => (
        <LearnerLearningCard.Action key={index}>
          {action}
        </LearnerLearningCard.Action>
      ))}

      {!!(competencies && competencies.length) && (
        <LearnerLearningCard.Tags>
          {competencies.map((tag) => tag.title)}
        </LearnerLearningCard.Tags>
      )}
    </LearnerLearningCard>
  );
};
