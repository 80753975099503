import { z } from 'zod';

import { TimeDeltaSchema } from '@nl-lms/common/shared';

export const CertificationTemplateSchema = z.object({
  html: z.string(),
  options: z
    .object({
      printBackground: z.boolean().optional(),
      preferCSSPageSize: z.boolean().optional(),
    })
    .optional(),
});
export type CertificationTemplate = z.infer<typeof CertificationTemplateSchema>;

export const CertificationSchema = z.object({
  id: z.string(),

  name: z.string(),
  template: CertificationTemplateSchema,
  validityPeriod: TimeDeltaSchema.nullable(),

  createdAt: z.date(),
  updatedAt: z.date(),
});
export type Certification = z.infer<typeof CertificationSchema>;

export const CertificationInstanceSchema = z.object({
  id: z.string(),

  learnerId: z.string(),
  learnerHistoryId: z.string(),
  certificationId: z.string(),

  issuedAt: z.date(),
  expiresAt: z.date().nullable(),
  template: CertificationTemplateSchema,
  learnerFullName: z.string(),
  learningUnitName: z.string(),
  certificationName: z.string(),

  notifiedAt: z.date().nullable(),
  path: z.string(),

  trainingSessionLearnerId: z.string().nullable(),
  elearningSessionId: z.string().nullable(),
  assessmentFormInstanceId: z.string().nullable(),
  learningProgramInstanceId: z.string().nullable(),

  courseId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  assessmentFormId: z.string().nullable(),
  learningProgramId: z.string().nullable(),

  createdAt: z.date(),
  updatedAt: z.date(),
});
export type CertificationInstance = z.infer<typeof CertificationInstanceSchema>;

export enum CertificateResultType {
  FirstResult = 1,
  LastResult = 2,
}

export const CertificationLearningUnitSchema = z.object({
  id: z.string(),

  certificationId: z.string(),
  elearningCourseId: z.string().nullable(),
  courseId: z.string().nullable(),
  assessmentFormId: z.string().nullable(),

  resultType: z.nativeEnum(CertificateResultType),

  createdAt: z.date(),
  updatedAt: z.date(),
});

export type CertificationLearningUnit = z.infer<
  typeof CertificationLearningUnitSchema
>;
