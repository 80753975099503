import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Trainer } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Typography,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { LocationSelect } from '../../_common/LocationSelect';
import { AdminLiveCourseMultiSelect } from '../AdminLiveCourse/AdminLiveCourseSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';

type Props = {
  trainer?: Partial<Trainer>;
};

export const Schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phoneNo: yup.string(),
  vendorId: yup.string().required(),
  location: yup.object().nullable(),
  canDeliverAnyCourse: yup.boolean(),
  courses: yup.array().when('canDeliverAnyCourse', {
    is: false,
    then: (schema) => schema.required().min(1),
  }),
});

const parseEntity = (entity) => {
  return {
    ...entity,
    deliverAnywhere: true,
    courses: entity?.courses
      ? entity?.courses?.map((courseId) => ({
          course_id: courseId,
          deleted: false,
        }))
      : [],
    ...entity?.location,
    locations: [],
  };
};

export const AdminTrainerEditFormSideModal = ({ trainer = {} }: Props) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...trainer,
      courses: trainer?.courses?.map((c) => c.courseId),
      location: trainer.city
        ? {
            city: trainer.city,
            region: trainer.region,
            country: trainer.country,
          }
        : null,
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateTrainerMutation',
    updateHookName: 'useUpdateTrainerMutation',
    entityId: trainer.id,
    parseEntity,
  });
  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {trainer.id ? 'Edit Trainer' : 'Create New Trainer'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Trainer Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="First Name"
              required
              errorMessage={errors?.firstName?.message}
            >
              <Sensitive>
                <Input
                  required
                  {...register('firstName')}
                  hasError={!!errors?.firstName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Last Name"
              errorMessage={errors?.lastName?.message}
              required
            >
              <Sensitive>
                <Input
                  {...register('lastName')}
                  hasError={!!errors?.lastName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Email"
              errorMessage={errors?.email?.message}
            >
              <Sensitive>
                <Input
                  type="email"
                  {...register('email')}
                  required
                  hasError={!!errors?.email?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField label="Phone Number">
              <Sensitive>
                <Input {...register('phoneNo')} />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Vendor"
              required
              errorMessage={errors?.vendorId?.message}
            >
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    initialSelectedItem={{
                      // @ts-ignore
                      label: trainer.vendorName || trainer.vendor?.name,
                      value: trainer.vendorId || trainer.vendor?.id,
                    }}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField>
              <Checkbox
                {...register('canDeliverAnyCourse')}
                label="Can Deliver Any Course"
              />
            </FormField>

            {!watch('canDeliverAnyCourse') && (
              <FormField
                label="Courses"
                required
                // @ts-ignore
                errorMessage={errors?.courses?.message}
              >
                <Controller
                  name="courses"
                  control={control}
                  render={({ field }) => (
                    <AdminLiveCourseMultiSelect
                      {...field}
                      initialSelectedItems={trainer?.courses?.map((c) => ({
                        value: c.courseId,
                        label: c.name,
                      }))}
                      returnEntireItemOnChange={false}
                      // @ts-ignore
                      hasError={!!errors?.courses?.message}
                    />
                  )}
                />
              </FormField>
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Location">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Sensitive>
                    <LocationSelect
                      {...field}
                      // @ts-ignore
                      initialLocation={
                        trainer.country
                          ? {
                              country: trainer.country,
                              city: trainer.city,
                              region: trainer.region,
                            }
                          : null
                      }
                    />
                  </Sensitive>
                )}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={trainer.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
