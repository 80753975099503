import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isBrowser } from 'browser-or-node';
import FileSaver from 'file-saver';
import LZString from 'lz-string';

import { transformQuery } from '@nl-lms/common/shared';
import { _ } from '@nl-lms/vendor';

export const errorHandler = (options) => (err) => {
  let e: any = null;

  if (err.response) {
    if (err.response?.data?.error) {
      e = err.response.data.error;
    } else {
      e = {
        message:
          'An unknown error occurred. Please contact your administrator.',
        name: 'UNKNOWN_ERROR',
      };
    }
  } else {
    e = err;
  }

  if (options && options.errorHandler) {
    options.errorHandler(e);
  }

  return Promise.reject(e);
};

export const successHandler = (options) => (response: AxiosResponse) => {
  if (response.data.warnings && response.data.warnings.length) {
    options.warningHandler && options.warningHandler(response.data.warnings);

    if (isBrowser) {
      document.dispatchEvent(
        new CustomEvent('onWarning', { detail: response.data.warnings })
      );
    }
  }

  let data = response.data;
  if (response.data.data || response.data.data === 0) data = response.data.data;

  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition && data instanceof Blob) {
    const [, filename] = contentDisposition.split('filename=');
    FileSaver.saveAs(data, filename);
  }
  return data;
};

export const camelizeTransform = (data: AxiosResponse) => {
  if (typeof window !== 'undefined' && data instanceof Blob) return data;
  // else should see what response we get when the sdk runs on another nodejs service

  // When mocking api calls an object is returned
  try {
    // @ts-ignore
    return _.camelize(JSON.parse(data), true);
  } catch (e) {
    // @ts-ignore
    return _.camelize(data, true);
  }
};

export const setAuthTokenHandler =
  (tokenGetter) => (config: AxiosRequestConfig) => {
    let originalRequest = config;

    let accessToken = tokenGetter();
    if (!accessToken) {
      return originalRequest;
    }

    // @ts-ignore
    originalRequest.headers.Authorization = `Bearer ${accessToken}`;

    return originalRequest;
  };

export const setSessionSpanIdHandler = (config: AxiosRequestConfig) => {
  let originalRequest = config;
  if (
    typeof window === 'undefined' ||
    !sessionStorage ||
    !sessionStorage.getItem ||
    !sessionStorage.getItem('spanId')
  ) {
    return originalRequest;
  }

  let spanId = sessionStorage.getItem('spanId');
  if (!spanId) return originalRequest;

  // @ts-ignore
  originalRequest.headers['x-span-id'] = spanId;

  return originalRequest;
};

export const queryTransform = (config: AxiosRequestConfig) => {
  if (config.params && config.params.query) {
    config.params.query = JSON.stringify({
      ...config.params.query,
      ...transformQuery(config.params.query),
    });
  }

  if (
    config.data &&
    config.data.query &&
    Object.keys(config.data || {}).length === 1
  ) {
    config.data.query = {
      ...config.data.query,
      ...transformQuery(config.data.query),
    };
  }

  return config;
};

export const compressQuery = (config: AxiosRequestConfig) => {
  if (config.params && config.params.query) {
    config.params.query = LZString.compressToEncodedURIComponent(
      config.params.query
    );
  }
  return config;
};
