import { C } from '../constants';
import { prettyNumber } from './prettyNumber';
import { roundToTwo } from './roundToTwo';

export const prettyPrice = (
  num,
  notNice = false,
  withCurrency = true,
  currency = false
) => {
  let price = 0;
  if (num) price = num;

  const nicePrice = notNice
    ? roundToTwo(Math.abs(price))
    : prettyNumber(Math.abs(price), 2);
  const currencySign = C.CURRENCIES[currency || C.CURRENCY];

  if (!withCurrency) return `${price >= 0 ? '' : '-'} ${nicePrice}`;
  return `${price >= 0 ? '' : '-'} ${nicePrice} ${currencySign}`;
};
