import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef } from 'react';

import {
  Box,
  FloatingMenu,
  Icon,
  IconButton,
  Modal,
  SideModal,
} from '@nl-lms/ui/components';

import {
  NotificationMessageContext,
  NotificationMessageEditorProvider,
} from '../NotificationMessageContext';
import { MessageInputProps } from '../types';
import {
  EmptyBlockInput,
  NotificationEmailMessageBlock,
} from './NotificationEmailMessageBlockInput';
import './NotificationEmailMessageInput.scss';

export const NotificationEmailMessageInput = ({
  message,
  onChange,
  mentionOptions,
}: MessageInputProps) => {
  return (
    <NotificationMessageEditorProvider
      message={message}
      mentionOptions={mentionOptions}
      onChange={onChange}
    >
      <EmailMessageInputContainer>
        <EmailMessageInputToolbar />
        <div className="notification-email-message-input__content">
          {message.map((messageBlock, index) => {
            if (messageBlock.type === 'title') return null;
            return (
              <NotificationEmailMessageBlock
                block={messageBlock}
                index={index}
              />
            );
          })}
          {/* By default the mjml renderer adds an empty block at the end of the message for spacing */}
          {/* If we want to remove this and include it as theme component we will have to update all the email templates */}
          {message.length ? <EmptyBlockInput /> : null}
        </div>
      </EmailMessageInputContainer>
    </NotificationMessageEditorProvider>
  );
};

const EmailMessageInputToolbar = () => {
  return (
    <div className="notification-email-message-input__toolbar">
      <div
        id="notification-email-message-input__active-section-toolbar"
        className="notification-email-message-input__active-section-toolbar"
      />
      <Box margin={{ left: 'auto' }}>
        <AddBlockButton />
      </Box>
      <UndoButton />
      <RedoButton />
      <ExpandInputToggleButton />
    </div>
  );
};

const EmailMessageInputContainer = ({ children }) => {
  const { isExpanded, onMinimize, onExpand } = useContext(
    NotificationMessageContext
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const onOpenChange = useCallback(() => {
    if (isExpanded) {
      onMinimize();
    } else {
      onExpand();
    }
  }, [isExpanded]);

  if (isExpanded) {
    return (
      <Modal.Provider onOpenChange={onOpenChange} defaultOpened>
        <SideModal.Content fullScreen>
          <div
            className={clsx(
              'notification-email-message-input',
              isExpanded && 'notification-email-message-input--expanded'
            )}
            ref={containerRef}
          >
            {children}
          </div>
        </SideModal.Content>
      </Modal.Provider>
    );
  }

  return (
    <div className="notification-email-message-input" ref={containerRef}>
      {children}
    </div>
  );
};

const ExpandInputToggleButton = () => {
  const { isExpanded, onExpand, onMinimize } = useContext(
    NotificationMessageContext
  );
  return (
    <IconButton
      className="notification-email-message-input__toolbar-item"
      label={isExpanded ? 'Minimize' : 'Expand'}
      onClick={isExpanded ? onMinimize : onExpand}
    >
      {isExpanded ? <Icon.MinimizeIcon /> : <Icon.MaximizeIcon />}
    </IconButton>
  );
};

const UndoButton = () => {
  const { onUndo } = useContext(NotificationMessageContext);
  return (
    <IconButton
      className="notification-email-message-input__toolbar-item"
      label="Undo"
      onClick={onUndo}
    >
      <Icon.UndoIcon />
    </IconButton>
  );
};

const RedoButton = () => {
  const { onRedo } = useContext(NotificationMessageContext);
  return (
    <IconButton
      className="notification-email-message-input__toolbar-item"
      label="Redo"
      onClick={onRedo}
    >
      <Icon.RedoIcon />
    </IconButton>
  );
};

const AddBlockButton = () => {
  const { onAddBlock } = useContext(NotificationMessageContext);

  return (
    <FloatingMenu
      items={[
        {
          name: 'Header',
          handler: () =>
            onAddBlock({
              type: 'header',
              attributes: {},
            }),
        },
        {
          name: 'Text',
          handler: () =>
            onAddBlock({
              type: 'text',
              attributes: { translations: {} },
              value: 'Simple message',
            }),
        },
        {
          name: 'Separator',
          handler: () =>
            onAddBlock({
              type: 'separator',
              attributes: {},
            }),
        },
        {
          name: 'Buttons',
          handler: () =>
            onAddBlock({
              type: 'buttons',
              attributes: {},
              value: [
                {
                  label: 'Button',
                  translations: {},
                  href: `{{url}}`,
                },
              ],
            }),
        },
      ]}
      usePortal={false}
    >
      <IconButton
        className="notification-email-message-input__toolbar-item"
        label="Add Section"
        onClick={() => ({})}
      >
        <Icon.AddIcon />
      </IconButton>
    </FloatingMenu>
  );
};

const useLoadFonts = () => {
  const font = {
    name: 'Muli',
    // name: 'Ubuntu',
    // src: 'https://fonts.googleapis.com/css?family=Ubuntu&display=swap',
    src: 'https://fonts.gstatic.com/s/muli/v28/7Auwp_0qiz-afTLGLQjUwkQ.woff2',
  };

  const loadFont = useCallback(async () => {
    const fontFile = new FontFace(font.name, `url(${font.src})`);
    try {
      await fontFile.load();
      // @ts-ignore
      document.fonts.add(fontFile);
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    loadFont();
  }, []);
};
