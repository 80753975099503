import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanFieldMetadata, QueryOperator } from '@nl-lms/common/shared';

import {
  BooleanValueOption,
  FilterBarOptionsComponentProps,
} from '../FilterBar.types';
import { filterOptions, getOptionClassName, getOptionId } from '../filterUtils';

export const FilterBarBooleanFieldSuggestOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<BooleanFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const valueOptions: BooleanValueOption[] = [
    {
      id: getOptionId(
        `${QueryOperator.IsTrue}-true`,
        field.name,
        'BooleanValueOption'
      ),
      type: 'BooleanValueOption',
      field,
      operator: QueryOperator.IsTrue,
      value: true,
      label: `${t('common.filterbar.is')} ${field.label}`,
    },
    {
      id: getOptionId(
        `${QueryOperator.IsFalse}-false`,
        field.name,
        'BooleanValueOption'
      ),
      type: 'BooleanValueOption',
      field,
      operator: QueryOperator.IsFalse,
      value: false,
      label: `${t('common.filterbar.is not')} ${field.label}`,
    },
  ];

  const filteredOptions = filterOptions(inputValue, valueOptions);
  if (!filteredOptions.length) return null;

  return (
    <>
      <span className="filter-bar__option-separator">{field.label}</span>
      {filteredOptions.map((option) => (
        <li
          className={getOptionClassName(option.id === activeOptionId)}
          {...getOptionProps(option, option.id)}
          key={option.id}
        >
          {option.label}
        </li>
      ))}
    </>
  );
};

export const FilterBarBooleanFieldOptions = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
}: FilterBarOptionsComponentProps<BooleanFieldMetadata>) => {
  const { t } = useTranslation('learner');

  const operatorOptions = useMemo<BooleanValueOption[]>(() => {
    return [
      {
        id: getOptionId(
          `${QueryOperator.IsTrue}-true`,
          field.name,
          'BooleanValueOption'
        ),
        type: 'BooleanValueOption',
        field,
        operator: QueryOperator.IsTrue,
        value: true,
        label: `${t('common.filterbar.is')} ${field.label}`,
      },
      {
        id: getOptionId(
          `${QueryOperator.IsFalse}-false`,
          field.name,
          'BooleanValueOption'
        ),
        type: 'BooleanValueOption',
        field,
        operator: QueryOperator.IsFalse,
        value: false,
        label: `${t('common.filterbar.is not')} ${field.label}`,
      },
    ];
  }, [inputValue, field]);

  return (
    <>
      <span className="filter-bar__option-separator">
        {t('common.filterbar.values')}
      </span>
      {operatorOptions.map((option) => (
        <li
          className={getOptionClassName(option.id === activeOptionId)}
          {...getOptionProps(option, option.id)}
          key={option.id}
        >
          {option.label}
        </li>
      ))}
    </>
  );
};
