export const NotificationsRouterPrefix = '/notifications';

export const NotificationsScope = {
  list: { resource: 'notification', action: 'list' },
  update: { resource: 'notification', action: 'update' },
  delete: { resource: 'notification', action: 'delete' },
  send: { resource: 'notification', action: 'send' },
  preview: { resource: 'notification', action: 'preview' },
  sample: { resource: 'notification', action: 'sample' },
  listTemplate: { resource: 'notification-template', action: 'list' },
  createTemplate: { resource: 'notification-template', action: 'create' },
  updateTemplate: { resource: 'notification-template', action: 'update' },
  deleteTemplate: { resource: 'notification-template', action: 'delete' },
} as const;
