import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AppQueryFilter, FieldType } from '@nl-lms/common/shared';
import {
  PaginatedReportQuery,
  Report,
  ReportDataColumn,
  ReportQuery,
  ReportTemplate,
  ReportTemplateName,
  ReportsScope,
} from '@nl-lms/feature/reports/sdk';
import {
  Box,
  Button,
  Checkbox,
  CheckboxField,
  DateTime,
  ErrorPlaceholder,
  FormField,
  FormWizard,
  Icon,
  Input,
  Modal,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  PrettyDate,
  Sensitive,
  SideModal,
  SingleSelect,
  Table,
  Textarea,
  Tooltip,
  Typography,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  getMessageFromError,
  getRecurrenceFromValue,
  getRecurrenceOptions,
} from '@nl-lms/ui/utils';
import { timeSuggest } from '@nl-lms/vendor';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { Can } from '../../../../Can';
import { authStore } from '../../../../_common/modules/Auth/auth';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { reportsApi } from '../../../../_common/services/api';
import { AdminLearnerMultiSelect } from '../../AdminLearner/AdminLearnerSelect';
import { AdminAnalyticsReportColumnsInput } from './AdminAnalyticsReportColumnsInput';
import { AdminAnalyticsReportFormFilterBar } from './AdminAnalyticsReportFormFilterBar';
import { AdminAnalyticsReportTemplateSelect } from './AdminAnalyticsReportTemplatesSelect';

const {
  useGenerateReportMutation,
  useScheduleReportMutation,
  useUpdateReportMutation,
  useGetReportDataQuery,
} = reportsApi;

type CreateReportWithReferenceEntitySideModalProps = {
  initialFilters: AppQueryFilter;
};
type EditReportSideModalProps = {
  report: Report;
};
type RegenerateReportSideModalProps = {
  report: Omit<Report, 'id'>;
};
type AdminAnalyticsUpsertReportSideModalFormProps = {
  report?: Omit<Report, 'id'> & { id?: string };
  initialFilters?: AppQueryFilter;
};

const RecurrenceOptions = getRecurrenceOptions();

const Schema = yup.object().shape({
  reportTemplateId: yup.string(),
  name: yup.string().required(),
  label: yup.string().required(),
  description: yup.string().required(),
  columns: yup.array().required(),
  filters: yup.object().nullable(),
  withHistoricalLearnerData: yup.boolean().required(),
  recurrenceDelta: yup.object().nullable().notRequired(),
  scheduleDate: yup.date().nullable(),
  recurrenceUntilDate: yup.date().nullable(),
  recipientIds: yup.array().of(yup.string()),
  emailTitle: yup.string().required(),
  emailSubject: yup.string().required(),
  emailMessage: yup.string().required(),
});

export const AdminAnalyticsUpsertReportSideModal = (
  props: AdminAnalyticsUpsertReportSideModalFormProps
) => {
  const { hide } = useModal();
  // @ts-ignore
  const [reportTemplate, setReportTemplate] = useState<ReportTemplate>(null);
  const initialFilters =
    'initialFilters' in props ? props.initialFilters : null;

  const isEditing = useMemo(() => {
    return !!props?.report?.id;
  }, [props]);
  const isRegenerating = useMemo(() => {
    return !!props?.report && !props.report?.id;
  }, [props]);
  const isScheduledReport = useMemo(() => {
    return (
      !!props?.report &&
      !!props.report?.id &&
      props.report.status === 'SCHEDULED'
    );
  }, []);

  const baseReport = useMemo(() => {
    return {
      id: props?.report?.id || null,
      reportTemplateId: undefined,
      name: props?.report?.name || '',
      label: props?.report?.label || '',
      filters:
        props?.report?.query?.query?.filters || props?.initialFilters || null,
      description: props?.report?.query?.description || '',
      columns: props?.report?.query?.columns || [],
      withHistoricalLearnerData:
        typeof props?.report?.query?.withHistoricalLearnerData === 'undefined'
          ? !!C.FEATURES.defaultReportIncludeLearnerHistory
          : props?.report?.query?.withHistoricalLearnerData,
      recurrenceDelta: props?.report?.query?.recurrenceDelta || null,
      scheduleDate: props?.report?.query?.scheduleDate || null,
      recurrenceUntilDate: props?.report?.query?.recurrenceUntilDate || null,
      recipientIds:
        props?.report?.query?.recipientIds ||
        (authStore.learnerId ? [authStore.learnerId] : []),
      emailTitle: props?.report?.query?.notification?.title || '',
      emailSubject: props?.report?.query?.notification?.subject || '',
      emailMessage: props?.report?.query?.notification?.message || '',
    };
  }, [props, initialFilters]);

  const [createReport, { isLoading: isCreateLoading, error: createError }] =
    useGenerateReportMutation();
  const createReportAction = useAction(createReport);

  const [
    scheduleReport,
    { isLoading: isScheduleLoading, error: scheduleError },
  ] = useScheduleReportMutation();
  const scheduleReportAction = useAction(scheduleReport);

  const [updateReport, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdateReportMutation();
  const updateReportAction = useAction(updateReport);

  const onSubmit = useCallback(async (entity) => {
    let result;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedEntity = {
      ...entity,
      query: entity.filters ? { filters: entity.filters } : null,
      timezone,
      notification: {
        title: entity.emailTitle,
        subject: entity.emailSubject,
        message: entity.emailMessage,
      },
    };

    if (baseReport.id) {
      result = await updateReportAction({
        reportId: baseReport.id,
        ...parsedEntity,
      });
    } else if (entity.scheduleDate) {
      result = await scheduleReportAction(parsedEntity);
    } else {
      result = await createReportAction(parsedEntity);
    }

    if ('data' in result) hide();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    // @ts-ignore
    defaultValues: baseReport,
  });

  const onChangeReportTemplate = useCallback((selectedOption) => {
    if (!selectedOption?.report) return;
    const selectedReportTemplate: ReportTemplate = selectedOption.report;

    setValue('name', selectedReportTemplate.name);
    setValue('label', selectedReportTemplate.label);
    setValue('columns', selectedReportTemplate.columns);
    // @ts-ignore
    setValue('filters', selectedReportTemplate.query.filters);
    setValue('description', selectedReportTemplate.description);
    setValue(
      'withHistoricalLearnerData',
      selectedReportTemplate.withHistoricalLearnerData
    );
    setValue('emailTitle', selectedReportTemplate.notification.title);
    setValue('emailSubject', selectedReportTemplate.notification.subject);
    setValue('emailMessage', selectedReportTemplate.notification.message);

    setReportTemplate(selectedReportTemplate);
  }, []);

  const scheduleDate = watch('scheduleDate');
  const reportName = watch('name');
  const columns = watch('columns') || [];
  const filters = watch('filters');
  const withHistoricalLearnerData = watch('withHistoricalLearnerData');
  let formTitle = 'Create a new report';
  if (isEditing && isScheduledReport) formTitle = 'Edit Report';
  if (isEditing && !isScheduledReport) formTitle = 'View Report';
  if (isRegenerating) formTitle = 'Recreate Report';
  const availableColumnsForEdit = columns.filter(
    (col) => !(col.options && 'alwaysHide' in col.options)
  );

  return (
    <SideModal.Content>
      <FormWizard.Provider
        steps={['general', 'data-structure', 'schedule', 'notification']}
        withContainer={false}
      >
        <SideModal.Header>
          <SideModal.Title>{formTitle}</SideModal.Title>
        </SideModal.Header>
        <SideModal.Body>
          {!isEditing && !isRegenerating ? (
            <Box margin={{ bottom: 'm' }}>
              <Typography.p type="muted">
                Start by selecting a report template that you want to use then
                customize the available fields.
              </Typography.p>
            </Box>
          ) : null}

          {!isEditing && !isRegenerating ? (
            <FormField label="Report Template">
              <Controller
                name="name"
                control={control}
                render={() => (
                  <AdminAnalyticsReportTemplateSelect
                    onChange={onChangeReportTemplate}
                    isClearable
                  />
                )}
              />
            </FormField>
          ) : null}
          <FormWizard.Tabs />
          <FormWizard.Step name="general">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Use these fields to make it easier to identify your report in
                the future
              </Typography.p>
            </Box>
            <FormField errorMessage={errors.label?.message} label="Name">
              <Input
                hasError={!!errors.label?.message}
                // @ts-ignore
                name="label"
                {...register('label')}
              />
            </FormField>
            <FormField
              label="Description"
              errorMessage={errors.description?.message}
            >
              <Textarea
                // @ts-ignore
                name="description"
                hasError={!!errors.description?.message}
                {...register('description')}
              />
            </FormField>
          </FormWizard.Step>
          <FormWizard.Step name="data-structure">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Set which columns will be visible and specify if the properties
                of a learner should be relative to the point in time at which
                the learning event took place
              </Typography.p>
            </Box>
            <FormField label="Filters">
              <Controller
                name="filters"
                control={control}
                render={({ field }) => (
                  <AdminAnalyticsReportFormFilterBar
                    onChange={field.onChange}
                    key={`filter-bar-${reportTemplate?.name}`}
                    value={field.value}
                    columns={columns}
                  />
                )}
              />
            </FormField>
            <CheckboxField
              helpText="Use this field to build your report with historical learner values"
              label="With Historical Learner Data"
            >
              <Checkbox
                // @ts-ignore
                name="withHistoricalLearnerData"
                {...register('withHistoricalLearnerData')}
              />
            </CheckboxField>
            <FormField label="Columns">
              <Controller
                name="columns"
                control={control}
                render={({ field }) => (
                  <AdminAnalyticsReportColumnsInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormField>
          </FormWizard.Step>
          <FormWizard.Step name="schedule">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Schedule when should the report be generated and if this should
                be a recurring action
              </Typography.p>
            </Box>
            <FormField label="Generate On">
              <Controller
                name="scheduleDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
            <Box flex={{ flexDirection: 'row', gap: 'm' }}>
              <FormField label="Recurrence Interval">
                <Controller
                  name="recurrenceDelta"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      placeholder="Select a specific time interval"
                      name="recurrenceDelta"
                      // @ts-ignore
                      selectedItem={
                        field.value
                          ? {
                              value: field.value,
                              label: getRecurrenceFromValue(field.value),
                            }
                          : null
                      }
                      options={RecurrenceOptions}
                      onChange={field.onChange}
                      isClearable
                    />
                  )}
                />
              </FormField>

              <FormField label="Recurrence End">
                <Controller
                  name="recurrenceUntilDate"
                  control={control}
                  render={({ field }) => (
                    <DateTime
                      date={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </FormField>
            </Box>
          </FormWizard.Step>
          <FormWizard.Step name="notification">
            <Box margin={{ top: 'm', bottom: 'm' }}>
              <Typography.p>
                Customize who will receive the report and how the notification
                email will look like
              </Typography.p>
            </Box>
            <FormField label="Recipients">
              <Controller
                name="recipientIds"
                control={control}
                render={({ field }) => (
                  <AdminLearnerMultiSelect
                    {...field}
                    returnEntireItemOnChange={false}
                    initialSelectedItems={field.value}
                  />
                )}
              />
            </FormField>
            <Box flex={{ flexDirection: 'row', gap: 'm' }}>
              <FormField
                label="Subject"
                errorMessage={errors.emailSubject?.message}
              >
                <Input
                  hasError={!!errors.emailSubject?.message}
                  // @ts-ignore
                  name="emailSubject"
                  {...register('emailSubject')}
                />
              </FormField>
              <FormField
                label="Title"
                errorMessage={errors.emailTitle?.message}
              >
                <Input
                  hasError={!!errors.emailTitle?.message}
                  // @ts-ignore
                  name="emailTitle"
                  {...register('emailTitle')}
                />
              </FormField>
            </Box>
            <FormField
              label="Message"
              errorMessage={errors.emailMessage?.message}
            >
              <Controller
                name="emailMessage"
                control={control}
                render={({ field }) => (
                  <Sensitive>
                    <TextEditorWithFileUpload
                      onChange={field.onChange}
                      value={field.value}
                      key={`editor-${reportName}`}
                    />
                  </Sensitive>
                )}
              />
            </FormField>
          </FormWizard.Step>
        </SideModal.Body>
        <SideModal.Actions>
          <SideModal.Error>
            {getMessageFromError(updateError)}
            {getMessageFromError(createError)}
            {getMessageFromError(scheduleError)}
          </SideModal.Error>
          <Tooltip
            offsetToChildren={10}
            title="See how your report will look like in a table format"
          >
            <AdminAnalyticsReportPreviewSideModalTrigger
              name={reportName as ReportTemplateName}
              columns={columns}
              filters={filters as AppQueryFilter}
              withHistoricalLearnerData={withHistoricalLearnerData}
            >
              <Button
                disabled={
                  !reportName ||
                  !availableColumnsForEdit?.length ||
                  (isEditing && !isScheduledReport)
                }
                label="Preview"
                icon={<Icon.TableIcon />}
                regular
              />
            </AdminAnalyticsReportPreviewSideModalTrigger>
          </Tooltip>

          {!isEditing ? (
            <Button
              onClick={handleSubmit(onSubmit)}
              label={!!scheduleDate ? 'Schedule' : 'Create'}
              isLoading={isScheduleLoading || isCreateLoading}
              icon={!!scheduleDate ? <Icon.CalendarIcon /> : <Icon.FileIcon />}
            />
          ) : null}
          {isEditing && isScheduledReport ? (
            <Button
              label="Update"
              onClick={handleSubmit(onSubmit)}
              isLoading={isUpdateLoading}
              icon={<Icon.FileIcon />}
            />
          ) : null}
          {isEditing && !isScheduledReport ? (
            <Tooltip title="You can only edit scheduled reports">
              <Button
                label="Update"
                disabled
                onClick={handleSubmit(onSubmit)}
                isLoading={isUpdateLoading}
                icon={<Icon.FileIcon />}
              />
            </Tooltip>
          ) : null}
        </SideModal.Actions>
      </FormWizard.Provider>
    </SideModal.Content>
  );
};

export const AdminAnalyticsUpsertReportSideModalTrigger = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const showModal = useShowModal(AdminAnalyticsUpsertReportSideModal);
  return (
    <Can do={ReportsScope.export.action} on={ReportsScope.export.resource}>
      {React.cloneElement(children, {
        onClick: showModal,
      })}
    </Can>
  );
};

export const useShowUpsertReportSideModal = () => {
  const showModal = useShowModal(AdminAnalyticsUpsertReportSideModal);

  return useCallback(
    (
      props:
        | EditReportSideModalProps
        | RegenerateReportSideModalProps
        | CreateReportWithReferenceEntitySideModalProps
    ) => {
      return showModal(props);
    },
    []
  );
};

export const AdminAnalyticsReportPreviewSideModalTrigger = ({
  children,
  filters,
  name,
  columns,
  withHistoricalLearnerData,
}: {
  children: React.ReactNode;
  name: ReportTemplateName;
  columns: ReportDataColumn[];
  filters: AppQueryFilter;
  withHistoricalLearnerData: boolean;
}) => {
  const reportQuery = useMemo<ReportQuery>(() => {
    const query = {
      filters: filters || null,
      pagination: { offset: 0, limit: 10 },
    };
    return {
      query,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      description: '',
      label: name,
      recipientIds: [],
      notification: { title: '', message: '', subject: '' },
      name,
      columns,
      scheduleDate: null,
      recurrenceDelta: null,
      recurrenceUntilDate: null,
      withHistoricalLearnerData,
    };
  }, [filters, name, columns, withHistoricalLearnerData]);
  return (
    <Modal.Provider>
      <Modal.Trigger>{children}</Modal.Trigger>
      <SideModal.Content fullScreen>
        <AdminAnalyticsReportPreviewTable reportQuery={reportQuery} />
      </SideModal.Content>
    </Modal.Provider>
  );
};

const AdminAnalyticsReportPreviewTable = ({
  reportQuery,
}: {
  reportQuery: ReportQuery;
}) => {
  const { data, error, refetch, isLoading } = useGetReportDataQuery(
    reportQuery as unknown as PaginatedReportQuery
  );
  useQueryErrorHandler({ error });

  const rows = data?.rows || [];
  const count = data?.count || 0;

  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Report Preview</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Table
          data={rows.map((r, index) => ({ ...r, id: index }))}
          columns={reportQuery.columns
            .filter((col) => col.isVisible)
            .map((col) => ({
              Header: col.label,
              accessor: col.label,
              Cell: ({ row }) => {
                const value = row.original[col.label];

                if (col.type === FieldType.date && value) {
                  return <PrettyDate value={value} />;
                }

                if (col.type === FieldType.datetime && value) {
                  return <PrettyDate value={value} withTime />;
                }

                if (typeof value === 'boolean') {
                  return value ? 'True' : 'False';
                }

                if (Array.isArray(value))
                  return <Sensitive>{value.join(', ')}</Sensitive>;
                return <Sensitive>{value}</Sensitive>;
              },
            }))}
          isLoading={isLoading}
        >
          {error ? (
            <ErrorPlaceholder>
              <ErrorPlaceholder.Image />
              <ErrorPlaceholder.Title />
              <ErrorPlaceholder.Description>
                Something went wrong during the fetching process. Try to refresh
                the table data or if the problem persists please get in contact
                with us using the app help channel
              </ErrorPlaceholder.Description>
              <Button label="Reload" onClick={refetch} />
            </ErrorPlaceholder>
          ) : (
            <NoDataPlaceholder
              iconName={'TableIcon'}
              color={NoDataPlaceholderColor.warning}
              title="Your configured report does not return any data"
              subtitle="You can try adjusting your filgers"
            />
          )}
        </Table>
      </SideModal.Body>
      <SideModal.Actions>
        {!!data ? (
          <Typography.p>
            Only showing the first 10 rows out of {count}. Generate the report
            for the entire dataset.
          </Typography.p>
        ) : null}
      </SideModal.Actions>
    </>
  );
};

const suggest = timeSuggest.create({
  // eslint-disable-next-line @typescript-eslint/camelcase
  allow_someday: false,
  // eslint-disable-next-line @typescript-eslint/camelcase
  allow_past_dates: false,
  interpreters: ['relative'],
  // eslint-disable-next-line @typescript-eslint/camelcase
  results_count: 5,
});

export const AdminAnalyticsRecurrenceIntervalSelect = ({
  name,
  disabled = false,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const options = suggest(inputValue).map((suggestion) => ({
    value: suggestion.delta,
    label: suggestion.formatted,
  }));

  const wrappedOnChange = useCallback(
    (selected) => {
      if (selected) {
        setInputValue(selected.label);
        onChange(selected.value);
      } else {
        setInputValue('');
        onChange(null);
      }
    },
    [setInputValue, options]
  );

  return (
    <SingleSelect
      name={name}
      onChange={wrappedOnChange}
      disableFiltering
      placeholder="Search for a specific time interval"
      onChangeInputValue={(val) => {
        setInputValue(val);
      }}
      returnEntireItemOnChange
      options={options}
      isClearable
      disabled={disabled}
      initialHighlightedIndex={0}
    />
  );
};
