import _ from 'lodash';

import { TimeDelta } from '@nl-lms/common/shared';
import { dateFns } from '@nl-lms/vendor';

export const formatTimeDelta = (delta: TimeDelta, prefix = 'in ') => {
  const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });

  const relativeDateFormatter = new Intl.RelativeTimeFormat('en');

  if (!delta || _.isEmpty(delta)) {
    return 'right away';
  }

  const expression: string[] = [];
  if (delta.years)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.years, 'year')
      )
    );
  if (delta.minutes)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.minutes, 'minute')
      )
    );
  if (delta.hours)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.hours, 'hour')
      )
    );
  if (delta.days)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.days, 'days')
      )
    );
  if (delta.months)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.months, 'months')
      )
    );
  if (delta.weeks)
    expression.push(
      removePrefixFromParts(
        relativeDateFormatter.formatToParts(delta.weeks, 'week')
      )
    );

  if (!expression?.length && !delta.settings) {
    return 'right away';
  }

  const settings: string[] = [];

  if (delta?.settings?.month !== undefined) {
    const referenceDate = new Date();
    referenceDate.setMonth(delta.settings.month);
    const localPrefix = expression.length ? 'in' : 'next';
    settings.push(`${localPrefix} ${dateFns.format(referenceDate, 'MMMM')}`);
  }

  if (delta.settings?.dayOfWeek !== undefined) {
    const referenceDate = new Date();
    const currentDayOfWeek = referenceDate.getDay();
    const daysToAdd = (delta.settings.dayOfWeek - currentDayOfWeek + 7) % 7;
    referenceDate.setDate(referenceDate.getDate() + daysToAdd);
    const localPrefix = expression.length ? 'on ' : 'next';
    settings.push(`${localPrefix} ${dateFns.format(referenceDate, 'EEEE')}`);
  }

  if (delta?.settings?.dayOfMonth !== undefined) {
    settings.push(`on the ${formatOrdinals(delta.settings.dayOfMonth)}`);
  }

  if (delta?.settings?.hour !== undefined) {
    const referenceDate = new Date();
    referenceDate.setHours(delta.settings.hour);
    referenceDate.setMinutes(delta?.settings?.minute || 0);
    settings.push(`at ${dateFns.format(referenceDate, 'HH:mm')}`);
  }

  if (delta?.settings?.skipWeekends) {
    settings.push('skip weekends');
  }

  if (!expression.length) {
    return `${settings.join(', ')}`;
  }
  return `${prefix}${listFormatter.format(expression)}${
    settings.length ? ', ' : ''
  }${settings.join(', ')}`;
};

function removePrefixFromParts(
  parts: ReturnType<Intl.RelativeTimeFormat['formatToParts']>
) {
  if (parts.length === 1) return parts[0].value;
  return parts
    .slice(1)
    .map((part) => part.value)
    .join('');
}

function formatOrdinals(value: number) {
  const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  const rule = enOrdinalRules.select(value);
  const suffix = suffixes.get(rule);
  return `${value}${suffix}`;
}
