import { useCombobox, useMultipleSelection } from 'downshift';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTestProps } from '../../hooks';
import { BadgeTypes, LightBadge } from '../Badge/Badge';
import { BadgeGroup } from '../BadgeGroup/BadgeGroup';
import { ArrowDownIcon, ArrowUpIcon } from '../Icon';
import { TidComponent } from '../index.types';
import './DownshiftSelect.scss';
import { BaseSelectProps, SelectOption } from './Select.types';
import { filterMultiselectItems, parseInitialSelectedItems } from './lib';

export type MultiSelectProps = TidComponent<
  BaseSelectProps & {
    initialSelectedItems?: string[] | number[];
    onChange: (items: Array<any>, name: string) => void;
    options: SelectOption[];
    returnEntireItemOnChange?: boolean;
    selectedItems?: SelectOption[];
    shouldClearInput?: boolean;
  }
>;

export const MultiSelect = (props: MultiSelectProps) => {
  const {
    options,
    onChange,
    onCreateOption,
    name,
    hasError = false,
    placeholder = 'Select or type value to search',
    isCreatable = false,
    isLoading = false,
    returnEntireItemOnChange = false,
    initialSelectedItems = [],
    initialHighlightedIndex = 0,
    selectedItems: _selectedItems = undefined,
    disabled = false,
    shouldClearInput = false,
  } = props;
  const commonProps = useTestProps(props);

  const [inputValue, setInputValue] = useState('');
  const parsedInitialSelectedItems = useMemo(
    () => parseInitialSelectedItems(initialSelectedItems, options),
    []
  );

  const onSelectedItemsChange = useCallback(
    ({ selectedItems }) => {
      if (returnEntireItemOnChange) {
        onChange(selectedItems, name);
      } else {
        onChange(
          selectedItems.map((item) => item.value),
          name
        );
      }
    },
    [onChange, returnEntireItemOnChange]
  );

  const itemToString = useCallback(
    (option: SelectOption | string | number) => {
      if (!option) return null;
      if (typeof option === 'string' || typeof option === 'number') {
        const optionObject = options.find((o) => o.value === option);
        return optionObject?.label;
      }
      return option.label;
    },
    [options]
  );

  const multipleSelectionProps = {
    initialSelectedItems: parsedInitialSelectedItems,
    onSelectedItemsChange,
    itemToString,
    selectedItems: _selectedItems || parsedInitialSelectedItems || [],
  };

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection(multipleSelectionProps as any);
  const filteredItems = filterMultiselectItems(
    inputValue,
    options,
    selectedItems,
    isCreatable
  );

  const createOptionHandler = useCallback(async () => {
    // @ts-ignore
    const result = await onCreateOption(inputValue);
    if (result) {
      addSelectedItem(result);
      setInputValue('');
    }
  }, [inputValue, onCreateOption, addSelectedItem]);

  useEffect(() => {
    if (shouldClearInput) {
      setInputValue('');
      selectedItems.forEach((item) => {
        removeSelectedItem(item);
      });
    }
  }, [shouldClearInput]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    toggleMenu,
    openMenu,
    closeMenu,
  } = useCombobox({
    inputValue,
    items: filteredItems,
    initialHighlightedIndex,
    defaultHighlightedIndex: 0,
    onStateChange: ({
      inputValue: downshiftStateInputValue,
      type,
      selectedItem,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          // @ts-ignore
          setInputValue(downshiftStateInputValue);
          if (!isOpen) openMenu();
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue('');
          if (selectedItem as SelectOption) {
            // @ts-ignore
            if (selectedItem?.value === 'create') {
              createOptionHandler();
            } else {
              addSelectedItem(selectedItem as SelectOption);
            }
            selectItem(null);
          }
          closeMenu();
          break;
        default:
          break;
      }
    },
  });

  // If we pass the ref the element will automatically get focused by downshift (don't know why)
  // This is a issue when you have a form that overflows and
  // when you open it the page will automatically scroll to your select
  // The actual solution here is to revamp our select components and get rid of downshift
  const { ref, ...inputProps } = getInputProps({
    placeholder,
    ...getDropdownProps({ preventKeyAction: isOpen }),
    onClick: toggleMenu,
    disabled,
  });
  return (
    <div className="downshift-select" {...commonProps}>
      <div
        className={`downshift-select__input-wrapper ${
          hasError ? 'downshift-select__input-wrapper--has-error' : ''
        } ${disabled ? 'downshift-select__input-wrapper--disabled' : ''}`}
        {...getComboboxProps({ disabled })}
      >
        <div className="downshift-select__input-and-selected-items">
          <div className="downshift-select__selected-items">
            <BadgeGroup
              name="multiSelectItems"
              type={BadgeTypes.LIGHT}
              reorderTags={false}
            >
              {/* @ts-ignore */}
              {selectedItems.map((item: SelectOption, index) => (
                <LightBadge
                  label={item.label}
                  key={`selected-item-${index}`}
                  onClose={() => removeSelectedItem(item)}
                  {...getSelectedItemProps({ selectedItem: item, index })}
                />
              ))}
            </BadgeGroup>
          </div>
          <input
            {...inputProps}
            name={name}
            className={`downshift-select__input ${
              disabled ? 'downshift-select__input--disabled' : ''
            }`}
          />
        </div>
        <div className="downshift-select__actions">
          <div className="downshift-select__input-icons">
            {isLoading ? <div className="downshift-select__spinner" /> : null}
            <div
              className={`downshift-select__input-icon ${
                disabled ? 'downshift-select__input-icon--disabled' : ''
              }`}
              {...getToggleButtonProps({ disabled })}
              aria-label="toggle menu"
            >
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        </div>
      </div>
      <div
        {...getMenuProps()}
        className="downshift-select__options-menu-wrapper"
      >
        {isOpen ? (
          <ul className="downshift-select__options-menu">
            {filteredItems.map((item, index) => (
              <li
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                className={`downshift-select__option ${
                  highlightedIndex === index
                    ? 'downshift-select__option--active'
                    : ''
                }`}
              >
                {item.Component ? (
                  <item.Component label={item.label} entity={item.entity} />
                ) : (
                  item.label
                )}
              </li>
            ))}

            {filteredItems.length === 0 ? (
              <li
                key="no-options"
                className="downshift-select__option downshift-select__option--none-available"
              >
                No options available
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
