import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CostType } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { AdminCostResourceTypeSelect } from './AdminCostResourceTypeSelect';

type Props = {
  costType?: Partial<CostType>;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  unit: yup.string().required(),
  resourceType: yup.string().required(),
});

export const AdminCostTypeEditFormSideModal = ({ costType = {} }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      name: costType?.name || '',
      unit: costType?.unit || '',
      resourceType: costType?.resourceType || '',
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateCostTypeMutation',
    updateHookName: 'useUpdateCostTypeMutation',
    entityId: costType.id,
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {costType.id ? 'Edit Cost Type' : 'Create New Cost Type'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Cost Type Details</Typography.h2>
        </Box>
        <Box>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Input
              required
              {...register('name')}
              hasError={!!errors?.name?.message}
            />
          </FormField>
        </Box>
        <Box>
          <FormField label="Unit" errorMessage={errors?.unit?.message} required>
            <Input
              required
              {...register('unit')}
              hasError={!!errors?.unit?.message}
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            required
            label="Resource Type"
            errorMessage={errors?.resourceType?.message}
          >
            <Controller
              control={control}
              name="resourceType"
              render={({ field }) => (
                <AdminCostResourceTypeSelect
                  {...field}
                  initialValue={costType.resourceType}
                  hasError={!!errors?.resourceType?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={costType.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
