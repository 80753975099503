import React, { Component, createContext } from 'react';

import {
  AppQueryFilter,
  FieldMetadata,
  FieldType,
  QueryFilterCombinator,
  QueryOperator,
  isGeneralSelectFieldMetadata,
} from '@nl-lms/common/shared';
import { FloatingMenuProps } from '@nl-lms/ui/components';

import { TidComponent } from '../../../components/index.types';
import {
  BooleanValueOption,
  DateValueOption,
  DatetimeValueOption,
  FieldOption,
  FieldTypeToFilterBarActiveFilterType,
  FilterBarActiveFilter,
  FilterBarField,
  FilterBarFilteringState,
  FilterBarOption,
  FilterBarOptionsComponentProps,
  NumberValueOption,
  OperatorOption,
  SelectValueOption,
  SettingsOption,
  StringValueOption,
  ValueOption,
} from '../FilterBar.types';
import {
  FilterBarFilter,
  FilterBarFilterComponent,
} from '../FilterBarFilters/FilterBarFiltersStore';
import {
  FilterBarBooleanFieldOptions,
  FilterBarBooleanFieldSuggestOptions,
  FilterBarDateFieldOptions,
  FilterBarDateFieldSuggestOptions,
  FilterBarNumberFieldOptions,
  FilterBarNumberFieldSuggestOptions,
  FilterBarSelectFieldOptions,
  FilterBarSelectFieldSuggestOptions,
  FilterBarStringFieldOptions,
  FilterBarStringFieldSuggestOptions,
} from '../FilterBarOptions';
import {
  FilterBarDatetimeFieldOptions,
  FilterBarDatetimeFieldSuggestOptions,
} from '../FilterBarOptions/FilterBarDatetimeFieldOptions';
import { FilterBarStore } from './FilterBarStore';

export interface FilterBarContextState {
  activeOptionId: string;
  optionsMenuRef: React.MutableRefObject<HTMLUListElement>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  getOptionProps: (
    o: FieldOption | OperatorOption | ValueOption,
    id: string
  ) => any;
  onInputKeyDown: (e: any) => void;
  isMenuOpen: boolean;
  isLoading: boolean;
  disabled: boolean;
  inputValue: string;
  filters: FilterBarFilter[];
  fields: FieldMetadata[];
  filteringState: FilterBarFilteringState;
  defaultCombinator: QueryFilterCombinator;
  activeFilter: FilterBarActiveFilter;
  settingsMenuOptions: FloatingMenuProps['items'];
  hiddenFields: string[];
  pasteFromListFields: string[];
  onPasteFromList: (
    values: string[],
    field: FieldMetadata,
    operator: QueryOperator
  ) => Promise<FilterBarActiveFilter[]>;
  onChangeDefaultCombinator: (c: QueryFilterCombinator) => void;
  onFocusInput: () => void;
  onBlurInput: () => void;
  onClickFilter: (filter: FilterBarFilterComponent) => void;
  onChangeInputValue: (inputValue: string) => void;
  onAddFilters: (
    filters: FilterBarActiveFilter[],
    combinator: QueryFilterCombinator
  ) => void;
  onSelectOption: (option: FilterBarOption) => void;
  onResetFilters: () => void;
  onToggleCombinator: (index: number) => void;
  onChangeMenuVisibility: (isVisible: boolean) => void;
  onSelectOptionCheckbox: (option: FilterBarOption) => void;
  onRemoveFilter: (filterIndex: number) => void;
  onSetActiveOptionId: (id: string) => void;
}

// @ts-ignore
export const FilterBarContext = createContext<FilterBarContextState>(null);

export type FilterBarProviderProps = TidComponent<{
  id: string;
  onChangeFilters: (filters: AppQueryFilter) => void;
  fields: FilterBarField[];
  initialFilters?: AppQueryFilter;
  isLoading?: boolean;
  disabled?: boolean;
  pasteFromListFields?: string[];
  hiddenFields?: string[];
  onPasteFromList?: (
    values: string[],
    field: FieldMetadata,
    operator: QueryOperator
  ) => Promise<FilterBarActiveFilter[] | void>;
  settingsMenuOptions?: FloatingMenuProps['items'];
  children: React.ReactNode;
  optionsMenuRef: React.MutableRefObject<HTMLUListElement>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
}>;

type FilterBarProviderState = {
  store: FilterBarStore;
};

export const FieldTypeToOptionsComponentMap: Record<
  FieldMetadata['type'],
  React.FunctionComponent<FilterBarOptionsComponentProps<FieldMetadata>>
> = {
  // @ts-ignore
  [FieldType.number]: FilterBarNumberFieldOptions,
  // @ts-ignore
  [FieldType.string]: FilterBarStringFieldOptions,
  // @ts-ignore
  [FieldType.boolean]: FilterBarBooleanFieldOptions,
  // @ts-ignore
  [FieldType.select]: FilterBarSelectFieldOptions,
  // @ts-ignore
  [FieldType.jsonArraySelect]: FilterBarSelectFieldOptions,
  // @ts-ignore
  [FieldType.arraySelect]: FilterBarSelectFieldOptions,
  // @ts-ignore
  [FieldType.date]: FilterBarDateFieldOptions,
  // @ts-ignore
  [FieldType.datetime]: FilterBarDatetimeFieldOptions,
};

export const FieldTypeToOptionsSuggestComponentMap: Record<
  FieldMetadata['type'],
  React.FunctionComponent<FilterBarOptionsComponentProps<FieldMetadata>>
> = {
  // @ts-ignore
  [FieldType.number]: FilterBarNumberFieldSuggestOptions,
  // @ts-ignore
  [FieldType.string]: FilterBarStringFieldSuggestOptions,
  // @ts-ignore
  [FieldType.boolean]: FilterBarBooleanFieldSuggestOptions,
  // @ts-ignore
  [FieldType.select]: FilterBarSelectFieldSuggestOptions,
  // @ts-ignore
  [FieldType.jsonArraySelect]: FilterBarSelectFieldSuggestOptions,
  // @ts-ignore
  [FieldType.arraySelect]: FilterBarSelectFieldSuggestOptions,
  // @ts-ignore
  [FieldType.date]: FilterBarDateFieldSuggestOptions,
  // @ts-ignore
  [FieldType.datetime]: FilterBarDatetimeFieldSuggestOptions,
};

export class FilterBarProvider extends Component<
  FilterBarProviderProps,
  FilterBarProviderState
> {
  constructor(props: FilterBarProviderProps) {
    super(props);
    this.state = {
      store: FilterBarProvider.getFilterBarStore(props),
    };
  }

  static getFilterBarStore(props) {
    const actualFields = props.fields.map((f) => {
      return f;
    });
    return new FilterBarStore({
      initialFilters: props.initialFilters,
      fields: actualFields,
      onChangeFilters: props.onChangeFilters,
    });
  }

  get inputValue(): string {
    return this.state.store.inputValue;
  }

  get fields(): FieldMetadata[] {
    return this.state.store.fields;
  }

  get filteringState(): FilterBarFilteringState {
    return this.state.store.filteringState;
  }

  get activeFilter(): FilterBarActiveFilter {
    return this.state.store.activeFilter;
  }

  get filters() {
    return this.state.store.filtersStore.filters;
  }

  get isMenuOpen() {
    return this.state.store.isMenuOpen;
  }

  get defaultCombinator() {
    return this.state.store.filtersStore.combinator;
  }

  get optionsMenuRef() {
    return this.props.optionsMenuRef;
  }

  get inputRef() {
    return this.props.inputRef;
  }

  get activeOptionId() {
    return this.state.store.activeOptionId;
  }

  get pasteFromListFields() {
    const fields = this.state.store.fields;
    return (
      this.props.pasteFromListFields ||
      fields
        .filter((field) => field.type === FieldType.string)
        .map((f) => f.name)
    );
  }

  onChangeFields(fields: FilterBarField[]) {
    const actualFields = fields.map((f) => {
      return f;
    });
    this.setState({
      ...this.state,
      store: this.state.store.updateFields(actualFields),
    });
  }

  componentDidUpdate(prevProps) {
    const currentFields = this.props.fields.map((f) => {
      return f;
    });
    const prevFields = prevProps.fields;
    if (prevProps.fields.length !== this.props.fields.length)
      this.onChangeFields(currentFields);

    const isDifferent = currentFields.some((f) => {
      const prevField = prevFields.find((pf) => pf.name === f.name);
      if (!prevField) return true;
      return false;
    });
    if (isDifferent) this.onChangeFields(currentFields);
    if (prevProps.onChangeFilters !== this.props.onChangeFilters) {
      const store = this.state.store.updateOnChange(this.props.onChangeFilters);
      this.setState({
        ...this.state,
        store,
      });
    }
  }

  onChangeMenuVisibility = (isVisible: boolean) => {
    this.setState({
      ...this.state,
      store: this.state.store.toggleMenu(isVisible),
    });
  };

  onChangeInputValue = (inputValue: string) => {
    if (inputValue === ' ') return;
    this.setState({
      ...this.state,
      store: this.state.store.changeInputValue(inputValue),
    });
  };

  onShowOptionsMenu = () => {
    this.setState({ ...this.state, store: this.state.store.showMenu() });
  };

  onHideOptionsMenu = () => {
    this.setState({ ...this.state, store: this.state.store.hideMenu() });
  };

  onRemoveFilter = (filterIndex: number) => {
    this.setState({
      ...this.state,
      store: this.state.store.removeFilter(filterIndex),
    });
  };

  onResetFilters = () => {
    this.setState({
      ...this.state,
      store: this.state.store.reset(),
    });
  };

  onSelectFieldOption = (option: FieldOption) => {
    const { field } = option;
    if (!field) {
      throw new Error(`Invalid field from option ${option}`);
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectField(field),
    });
  };

  onSelectOperatorOption = (option: OperatorOption) => {
    if (!option.value) {
      throw new Error(`Invalid operator option ${option}`);
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectOperator(option.value),
    });
  };

  onSelectDateValueOption = (option: DateValueOption) => {
    if (!option.value) {
      console.error(`Invalid value for option ${option}`);
      return;
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectValue(option),
    });
  };

  onSelectDatetimeValueOption = (option: DatetimeValueOption) => {
    if (!option.value) {
      console.error(`Invalid value for option ${option}`);
      return;
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectValue(option),
    });
  };

  onSelectNumberValueOption = (option: NumberValueOption) => {
    if (
      typeof option.value === 'undefined' ||
      option.value === null ||
      !option.operator
    ) {
      console.error(`Invalid option ${option}`);
      return;
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectValue(option),
    });
  };

  onSelectBooleanValueOption = (option: BooleanValueOption) => {
    if (!option.operator) {
      console.error(`Invalid option ${option}`);
      return;
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectValue(option),
    });
  };

  onSelectStringValueOption = (option: StringValueOption) => {
    if (!option.value || !option.operator) {
      console.error(`Invalid option ${option}`);
      return;
    }
    this.setState({
      ...this.state,
      store: this.state.store.selectValue(option),
    });
  };

  onSelectSelectValueOption = (
    option: SelectValueOption,
    preventFilterSubmit = false
  ) => {
    if (option.value === undefined) {
      console.error(`Invalid option ${option}`);
      return;
    }
    if (
      this.filteringState === FilterBarFilteringState.EditingFilterValue &&
      (this.activeFilter.type !== 'SelectField' ||
        !isGeneralSelectFieldMetadata(this.activeFilter.field))
    ) {
      console.error(`Invalid active filter field - ${this.activeFilter.field}`);
      return;
    }

    this.setState({
      store: this.state.store.selectSelectValueOption(
        option,
        preventFilterSubmit
      ),
    });
  };

  onSelectSettingsOption(option: SettingsOption) {
    if (!option.value) {
      console.error(`Invalid option ${option}`);
      return;
    }
    let newStore = this.state.store;
    switch (option.value) {
      case 'EditValue':
        newStore = newStore.selectEditValue();
        break;
      case 'EditOperator':
        newStore = newStore.selectEditOperator();
        break;
      case 'RemoveFilter':
        newStore = newStore.removeFilter(newStore.activeFilter.index);
        break;
      default:
        console.error(`Invalid option value for ${option}`);
        return;
    }

    this.setState({ ...this.state, store: newStore });
  }

  onSelectOption = (option: FilterBarOption, preventFilterSubmit = false) => {
    if (!option) {
      console.error('Select option was called with an empty value');
      return;
    }
    switch (option.type) {
      case 'FieldOption':
        return this.onSelectFieldOption(option);
      case 'OperatorOption':
        return this.onSelectOperatorOption(option);
      case 'DateValueOption':
        return this.onSelectDateValueOption(option);
      case 'DatetimeValueOption':
        return this.onSelectDatetimeValueOption(option);
      case 'BooleanValueOption':
        return this.onSelectBooleanValueOption(option);
      case 'StringValueOption':
        return this.onSelectStringValueOption(option);
      case 'NumberValueOption':
        return this.onSelectNumberValueOption(option);
      case 'SelectValueOption':
        return this.onSelectSelectValueOption(option, preventFilterSubmit);
      case 'SettingsOptions':
        return this.onSelectSettingsOption(option);
      default:
        console.error(`Invalid option type for ${option}`);
        return;
    }
  };

  onSelectOptionCheckbox = (option: FilterBarOption) => {
    this.onSelectOption(option, true);
  };

  onToggleCombinator = (index: number) => {
    this.setState({
      ...this.state,
      store: this.state.store.toggleCombinator(index),
    });
  };

  onChangeDefaultCombinator = (combinator: QueryFilterCombinator) => {
    this.setState({
      ...this.state,
      store: this.state.store.changeDefaultCombinator(combinator),
    });
  };

  onClickFilter = (filter: FilterBarFilterComponent) => {
    this.setState({
      ...this.state,
      store: this.state.store.selectFilter(filter),
    });
  };

  onAddFilters = (
    filters: FilterBarActiveFilter[],
    combinator: QueryFilterCombinator
  ) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      store: this.state.store.addFilters(filters, combinator),
    });
  };

  onPasteFromList = async (
    values: string[],
    field: FieldMetadata,
    operator: QueryOperator = QueryOperator.Like
  ) => {
    const baseResult = values.map((value) => ({
      type: FieldTypeToFilterBarActiveFilterType[field.type],
      field: {
        name: field.name,
        type: field.type,
        label: field.label,
      },
      operator,
      value,
    })) as unknown as FilterBarActiveFilter[];

    if (this.props.onPasteFromList) {
      const processedResult = await this.props.onPasteFromList(
        values,
        field,
        operator
      );
      return processedResult || baseResult;
    }

    return baseResult;
  };

  onSetActiveOptionId = (id) => {
    this.setState({
      ...this.state,
      store: this.state.store.setActiveOptionId(id),
    });
  };

  onInputKeyDown = (e) => {
    let options = [];
    if (this.optionsMenuRef && this.optionsMenuRef.current) {
      options = Array.from(
        this.optionsMenuRef.current.querySelectorAll(
          '[data-list-item-type="option"]'
        )
      );
    }
    const activeOptionIndex = options.findIndex(
      // @ts-ignore
      (o) => o.dataset.listItemId === this.activeOptionId
    );
    const nextActiveOptionIndex =
      options.length !== activeOptionIndex + 1 ? activeOptionIndex + 1 : 0;
    const prevActiveOptionIndex =
      activeOptionIndex !== 0 ? activeOptionIndex - 1 : options.length - 1;
    if (this.isMenuOpen) {
      switch (e.keyCode) {
        // Escape
        case 27:
          this.onChangeMenuVisibility(false);
          break;
        // Down
        case 38:
          const prevActiveOptionItem = options[prevActiveOptionIndex];
          // @ts-ignore
          this.onSetActiveOptionId(prevActiveOptionItem?.dataset?.listItemId);
          break;
        // Up
        case 40:
          const nextActiveOptionItem = options[nextActiveOptionIndex];
          // @ts-ignore
          this.onSetActiveOptionId(nextActiveOptionItem?.dataset?.listItemId);
          break;
        // Enter
        case 13:
          const activeOption = options[activeOptionIndex];
          // @ts-ignore
          activeOption?.click();
          break;
        default:
          break;
      }
    } else {
      this.onChangeMenuVisibility(true);
    }
  };

  onGetOptionProps = (
    option: FieldOption | OperatorOption | ValueOption,
    id: string
  ): {
    onMouseEnter: (e: any) => void;
    onClick: (e: any) => void;
    'data-list-item-id': string;
    'data-list-item-type': string;
  } => {
    return {
      onMouseEnter: (e) =>
        this.onSetActiveOptionId(e.target.dataset.listItemId),
      'data-list-item-id': id,
      'data-list-item-type': 'option',
      onClick: (event, isCheckbox = false) => {
        this.inputRef?.current?.focus();
        this.onSelectOption(option, isCheckbox);
      },
    };
  };

  get contextState(): FilterBarContextState {
    // Like this bc the build crashes when you use arrow functions
    // the fix involves updating nx to the latest version
    return {
      inputValue: this.inputValue,
      // @ts-ignore
      isLoading: this.props.isLoading,
      // @ts-ignore
      disabled: this.props.disabled,
      filteringState: this.filteringState,
      activeFilter: this.activeFilter,
      fields: this.state.store.fields,
      isMenuOpen: this.isMenuOpen,
      filters: this.filters,
      onPasteFromList: this.onPasteFromList,
      defaultCombinator: this.defaultCombinator,
      hiddenFields: this.props.hiddenFields || [],
      settingsMenuOptions: this.props.settingsMenuOptions || [],
      onChangeDefaultCombinator: this.onChangeDefaultCombinator,
      onSelectOption: this.onSelectOption,
      onBlurInput: this.onHideOptionsMenu,
      onFocusInput: this.onShowOptionsMenu,
      onAddFilters: this.onAddFilters,
      pasteFromListFields: this.pasteFromListFields,
      onResetFilters: this.onResetFilters,
      onRemoveFilter: this.onRemoveFilter,
      onClickFilter: this.onClickFilter,
      onChangeMenuVisibility: this.onChangeMenuVisibility,
      onToggleCombinator: this.onToggleCombinator,
      onChangeInputValue: this.onChangeInputValue,
      onSelectOptionCheckbox: this.onSelectOptionCheckbox,
      optionsMenuRef: this.optionsMenuRef,
      inputRef: this.inputRef,
      activeOptionId: this.activeOptionId,
      getOptionProps: this.onGetOptionProps,
      onInputKeyDown: this.onInputKeyDown,
      onSetActiveOptionId: this.onSetActiveOptionId,
    };
  }

  render() {
    const { children } = this.props;
    return (
      <FilterBarContext.Provider value={this.contextState}>
        {children}
      </FilterBarContext.Provider>
    );
  }
}
