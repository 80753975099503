import { z } from 'zod';

import { AppQueryFiltersSchema } from '@nl-lms/common/shared';

import { LearnerSchema } from '../learner/learner.types';

export const LearnerGroupSchema = z.object({
  id: z.string(),
  title: z.string(),
  filters: z.union([AppQueryFiltersSchema, z.object({})]),
  learnersCount: z.number(),
  isDynamic: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const LearnerGroupLearnerSchema = z.object({
  id: z.string(),
  learnerId: z.string(),
  learnerGroupId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const CreateLearnerGroupSchema = z
  .object({
    title: z.string(),
    isDynamic: z.boolean(),
    learnerIds: z.array(z.string()).optional(),
    filters: AppQueryFiltersSchema.optional(),
  })
  .refine((schema) => (schema.isDynamic ? !!schema.filters : true), {
    message: 'Dynamic learner groups should include filters',
  })
  .refine(
    (schema) => (!schema.isDynamic ? !!schema.learnerIds?.length : true),
    { message: 'Static learner groups should include a list of learner ids' }
  );

export const UpdateLearnerGroupSchema = z.object({
  title: z.string(),
  learnerIds: z.array(z.string()).optional(),
  filters: AppQueryFiltersSchema.optional(),
});

export type UpdateLearnerGroupPayload = z.infer<
  typeof UpdateLearnerGroupSchema
>;
export type CreateLearnerGroupPayload = z.infer<
  typeof CreateLearnerGroupSchema
>;
export type LearnerGroup = z.infer<typeof LearnerGroupSchema>;
export type LearnerGroupLearner = z.infer<typeof LearnerGroupLearnerSchema>;
