import React, { useEffect } from 'react';

import { NotificationsContainer, useTracker } from '@nl-lms/ui/modules';

import './AssessmentLayout.scss';

export const AssessmentLayout = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  const { children, className = '' } = props;

  const tracker = useTracker();

  useEffect(() => {
    tracker.stopRecording();
  }, []);

  return (
    <div className={`assessment-layout ${className}`}>
      {children}{' '}
      <div className="notifications-container">
        <NotificationsContainer />
      </div>
    </div>
  );
};

const AssessmentLayoutHeader = (props: {
  light?: boolean;
  children: React.ReactNode;
}) => {
  const { light = false, children } = props;
  return (
    <div
      className={`assessment-layout__header ${
        light ? 'assessment-layout__header--light' : ''
      }`}
    >
      {children}
    </div>
  );
};

const AssessmentLayoutLogo = ({ children }: { children: React.ReactNode }) => (
  <div className="assessment-layout__header-logo">{children}</div>
);

const AssessmentLayoutHeaderDetails = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="assessment-layout__header-details">{children}</div>;

const AssessmentLayoutContent = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="assessment-layout__content">{children}</div>;

AssessmentLayout.Header = AssessmentLayoutHeader;
AssessmentLayout.HeaderLogo = AssessmentLayoutLogo;
AssessmentLayout.HeaderDetails = AssessmentLayoutHeaderDetails;
AssessmentLayout.Content = AssessmentLayoutContent;

export default AssessmentLayout;
