import i18n from 'i18next';

export const getMessageFromError = (err) => {
  let errMessage = '';
  if (!err) return '';
  if (typeof err === 'string') errMessage = err;
  // TODO try and get it from locale first when it's implemented
  else if (err.name) {
    const isTranslationAvailable = i18n.exists(
      `learner:assets.api.errors.${err.name}`
    );
    const errTranslation = i18n.t(`learner:assets.api.errors.${err.name}`);
    errMessage = isTranslationAvailable ? errTranslation : err.message;
  } else if (err.message) errMessage = err.message;

  return errMessage;
};

export const getCodeFromError = (err) => {
  let code = 0;
  if (typeof err === 'string') code = -1;
  else if (err.code) {
    code = err.code;
  }

  return code;
};
