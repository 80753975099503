import { useContext } from 'react';

import { TrackingContext } from './TrackingProvider';
import { Tracker } from './tracker';

export const useTracker = (): Tracker => {
  const ctx = useContext(TrackingContext);
  if (!ctx) {
    console.warn('useTracker must be used within a TrackingProvider');

    return new Tracker({
      enabled: false,
      key: '',
      name: '',
      getUser: () => ({ id: '', learnerId: '' }),
    });
  }

  return ctx.tracker;
};
