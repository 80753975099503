import { C } from '@nl-lms/ui/constants';
import { _ } from '@nl-lms/vendor';
import i18n from 'i18next';

export const updateLanguage = (activeApp, user) => {
  const userAdminLanguage = _.get(user, 'settings.i18nAdmin') || C.I18N;
  const userPortalLanguage = _.get(user, 'settings.i18nPortal') || C.I18N;

  let appLanguage;
  if (activeApp === 'admin') {
    appLanguage = userAdminLanguage;
    if (window.location.pathname.includes('external')) appLanguage = C.I18N;
  } else {
    appLanguage = userPortalLanguage;
  }

  i18n.changeLanguage(appLanguage).then(console.log).catch(console.error);
};
