import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  IApiMethods,
  IApiService,
  Vendor as IVendor,
  ListResponse,
} from './types';

export class Vendor
  extends ApiService
  implements IApiService<'vendor'>, IApiMethods<'vendor'>
{
  static serviceBase = '/resource/vendor';
  public paths = {
    get: (id) => `${Vendor.serviceBase}/${id}`,
    list: () => `${Vendor.serviceBase}`,
    create: () => `${Vendor.serviceBase}`,
    bulkDelete: () => `${Vendor.serviceBase}`,
    update: (id) => `${Vendor.serviceBase}/${id}`,
    remove: (id) => `${Vendor.serviceBase}/${id}`,
    listForSelect: () => `/list/vendor`,
  };

  get = (id) => this.api.get<null, IVendor>(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get<null, ListResponse<IVendor>>(this.paths.list(), {
      params: { query },
    });

  create = (entity) =>
    this.api.post<null, boolean>(this.paths.create(), snakelize(entity));

  bulkDelete = (ids) =>
    this.api.delete<null, boolean>(this.paths.bulkDelete(), {
      data: { list: ids },
    });

  update = (entity) =>
    this.api.patch<null, boolean>(
      this.paths.update(entity.id),
      snakelize(entity)
    );

  remove = (entity) => {
    let id = typeof entity === 'string' ? entity : entity.id;
    return this.api.delete(this.paths.remove(id), entity);
  };

  listForSelect = (input = '') =>
    this.api.get<null, IVendor[]>(this.paths.listForSelect(), {
      params: { query: { search: input } },
    });
}
