import React from 'react';

import './RadioButton.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type RadioButtonProps = TidComponent<{
  label?: string;
  className?: string;
  disabled?: boolean;
  checked: boolean;
  defaultChecked?: boolean;
  hasError?: boolean;
  readOnly?: boolean;
  onClick: any;
  name: string;
  value;
}>;

export const RadioButton: React.FunctionComponent<RadioButtonProps> = (
  props
) => {
  const {
    label = '',
    className = '',
    disabled = false,
    checked,
    defaultChecked = false,
    hasError = false,
    readOnly = false,
    onClick,
    name,
    value,
  } = props;
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'radio-button',
    props,
    disabled ? 'radio-button--disabled' : null,
    readOnly ? 'radio-button--readonly' : null
  );
  const checkmarkClassNameProps = useClassNameProps(
    'radio-button__checkmark',
    defaultChecked || checked ? 'radio-button__checkmark--checked' : null,
    hasError ? 'radio-button__checkmark--has-error' : null
  );

  return (
    <label {...classNameProps} {...commonProps}>
      {label && <span className={'radio-button__label'}>{label}</span>}
      <input
        type="radio"
        className="radio-button__input"
        disabled={disabled}
        defaultChecked={defaultChecked || checked}
        readOnly={readOnly}
        onClick={onClick}
        value={value}
        name={name}
      />
      <span {...checkmarkClassNameProps} />
    </label>
  );
};
