import isEqual from 'lodash/isEqual';
import DropdownTreeSelect, {
  Mode,
  NodeAction,
  TreeNode,
} from 'nl-react-dropdown-tree-select';
import React from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './MultiLevelSelect.scss';

type MultiLevelSelectProps = TidComponent<{
  name?: string;
  options: TreeNode[];
  onChange: (option?: TreeNode) => void;
  onNodeToggle?: (currentNode: TreeNode) => void;
  onAction?: (currentNode: TreeNode, currentAction: NodeAction) => void;
  placeholder?: string;
  noResultsText?: string;
  mode?: Mode;
  disabled?: boolean;
  shouldClearInput?: boolean;
  selectedItem?: TreeNode | string;
}>;

const MultiLevelSelectComponent = ({
  name = 'multi-level-select',
  options,
  onChange,
  onNodeToggle,
  onAction,
  placeholder = 'Select or type value to search',
  noResultsText = 'No results found',
  mode = 'radioSelect',
  disabled = false,
  shouldClearInput,
  selectedItem,
  ...props
}: MultiLevelSelectProps) => {
  const commonProps = useTestProps(props);
  // todo: Add support for data-tid property - at the moment it's not possible
  return (
    <DropdownTreeSelect
      id={name}
      data={options}
      onChange={onChange}
      onAction={onAction}
      onNodeToggle={onNodeToggle}
      texts={{
        placeholder,
        noMatches: noResultsText,
      }}
      keepTreeOnSearch
      keepChildrenOnSearch
      clearSearchOnChange
      mode={mode}
      disabled={disabled}
      selectedItem={selectedItem}
      {...commonProps}
    />
  );
};

// used to keep the input state when parent re-renders
export const MultiLevelSelect = React.memo(
  MultiLevelSelectComponent,
  (prevProps, nextProps) =>
    isEqual(prevProps.options, nextProps.options) &&
    isEqual(prevProps.shouldClearInput, nextProps.shouldClearInput)
);
