import React, { useCallback } from 'react';

import { AsyncSingleSelect } from '@nl-lms/ui/components';

import { useApi } from '../../../_common/services/api';

export const AdminElearningCourseSingleSelect = (props) => {
  const {
    onChange,
    name,
    disabled = false,
    initialSelectedItem = null,
    isClearable = true,
    returnEntireItemOnChange = false,
    hasError = false,
  } = props;
  const api = useApi();

  const onLoadCourses = useCallback(async (input) => {
    const res = await api.elearningCourse.listForSelect(input);
    // @ts-ignore
    return res.map((course) => ({
      label: course.name,
      value: course.id,
      course: course,
      item: course,
    }));
  }, []);

  return (
    <AsyncSingleSelect
      name={name}
      onChange={onChange}
      disabled={disabled}
      placeholder="Search by course name"
      loadOptions={onLoadCourses}
      initialSelectedItem={initialSelectedItem}
      isClearable={isClearable}
      returnEntireItemOnChange={returnEntireItemOnChange}
      hasError={hasError}
    />
  );
};
