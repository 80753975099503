import React from 'react';

export const DotsIcon = () => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="2" fill="currentColor" />
    <rect x="6" width="2" height="2" fill="currentColor" />
    <rect x="12" width="2" height="2" fill="currentColor" />
  </svg>
);
