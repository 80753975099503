import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { OperatorToLabelRecord } from '@nl-lms/common/shared';

import { ArrowDownIcon } from '../../../components';
import { Box, Tooltip } from '../../../components';
import { useClassNameProps } from '../../../hooks';
import { FilterBarContext } from '../FilterBarContext/FilterBarContext';
import {
  getFilterFieldValueLabel,
  parseFilterFieldsToOptions,
} from '../filterUtils';
import { FilterBarBadge, FilterBarBadgeComponent } from './FilterBarBadge';
import {
  FilterBarFilter,
  FilterBarFilterComponent,
} from './FilterBarFiltersStore';

export const FilterBarFiltersList = ({ children }) => {
  const wrapperRef = useRef(null);
  const badgesRef = useRef([]);
  const { filters, onRemoveFilter, onToggleCombinator, onClickFilter } =
    useContext(FilterBarContext);
  const [isToggled, setIsToggled] = useState(false);
  const [hasScrollableContent, setHasScrollableContent] = useState(false);
  const [parsedFilters, setParsedFilters] = useState([]);

  const classNameProps = useClassNameProps(
    'filter-bar__selected-filters',
    isToggled ? 'filter-bar__selected-filters--expanded' : null
  );

  useEffect(() => {
    if (wrapperRef.current) {
      setHasScrollableContent(
        // @ts-ignore
        wrapperRef.current.scrollHeight > wrapperRef.current.offsetHeight
      );
    }
  }, [children, isToggled]);

  useEffect(() => {
    parseFilterFieldsToOptions(filters).then((res) => {
      // @ts-ignore
      setParsedFilters(res);
    });
  }, [filters]);

  const filtersCount = useMemo(
    () =>
      filters.reduce((counter, filter) => {
        if (
          filter.type !== 'CombinatorComponent' &&
          filter.type !== 'BracketComponent'
        ) {
          return counter + 1;
        }
        return counter;
      }, 0),
    [filters]
  );

  const toggleIsExpanded = useCallback(() => {
    setIsToggled(!isToggled);
  }, [isToggled]);

  return (
    <div {...classNameProps} ref={wrapperRef}>
      <Box flex={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
        {parsedFilters?.map((filter, index) => {
          // Skip initial brackets
          // We use those to keep a consistent data structure in the filters store
          // but the user doesn't need to see that
          if (index === 0 || index === filters.length - 1) return null;
          return (
            <FilterBarFiltersListBadge
              filter={filter}
              // @ts-ignore
              ref={(el) => (badgesRef.current[index - 1] = el)}
              index={index}
              key={`filter-bar-filter-badge-${index}`}
              onRemoveFilter={onRemoveFilter}
              onToggleCombinator={onToggleCombinator}
              onClickFilter={onClickFilter}
            />
          );
        })}
      </Box>

      <Box
        className="filter-bar__selected-filters-actions"
        flex={{ flexDirection: 'row' }}
      >
        {hasScrollableContent || isToggled ? (
          <Tooltip
            className={`filter-bar__selected-filters-expander ${
              isToggled ? 'filter-bar__selected-filters-expander--expanded' : ''
            }`}
            onClick={toggleIsExpanded}
            offsetToChildren={-20}
            title={isToggled ? 'Hide' : `View all ${filtersCount} filters`}
          >
            <ArrowDownIcon />
          </Tooltip>
        ) : null}
      </Box>
    </div>
  );
};

type FilterBarFiltersListBadgeProps = {
  filter: FilterBarFilter;
  index: number;
  onRemoveFilter: (index: number) => void;
  onToggleCombinator: (index: number) => void;
  onClickFilter: (filter: FilterBarFilterComponent) => void;
};

export const FilterBarFiltersListBadge = React.forwardRef<
  HTMLDivElement,
  FilterBarFiltersListBadgeProps
>(
  (
    { filter, index, onRemoveFilter, onToggleCombinator, onClickFilter },
    ref
  ) => {
    const { t } = useTranslation('learner');

    if (filter.type === 'BracketComponent') {
      return (
        <FilterBarBadge ref={ref} type="bracket">
          <FilterBarBadgeComponent title="Bracket">
            {filter.value}
          </FilterBarBadgeComponent>
        </FilterBarBadge>
      );
    }

    if (filter.type === 'CombinatorComponent') {
      return (
        <FilterBarBadge ref={ref} type="combinator">
          <FilterBarBadgeComponent
            title="Toggle Combinator"
            onClick={() => onToggleCombinator(index)}
          >
            {filter.value}
          </FilterBarBadgeComponent>
        </FilterBarBadge>
      );
    }

    const valueLabel = getFilterFieldValueLabel(filter);
    const operatorLabel = t(
      // @ts-ignore
      `common.filterbar.${OperatorToLabelRecord[filter.operator]}`
    ) as string;

    return (
      <FilterBarBadge
        type="filter"
        ref={ref}
        onRemove={() => onRemoveFilter(index)}
        onClick={() => onClickFilter(filter)}
      >
        <FilterBarBadgeComponent title="Field">
          {filter.field.label}
        </FilterBarBadgeComponent>
        <FilterBarBadgeComponent title="Edit Operator">
          {operatorLabel}
        </FilterBarBadgeComponent>
        <FilterBarBadgeComponent title="Edit value">
          {filter.field.ValueComponent ? (
            <filter.field.ValueComponent filter={filter}>
              {valueLabel}
            </filter.field.ValueComponent>
          ) : (
            valueLabel
          )}
        </FilterBarBadgeComponent>
      </FilterBarBadge>
    );
  }
);
