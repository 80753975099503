import React from 'react';

export const MinusIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="5" width="12" height="2" fill="currentColor" />
  </svg>
);
