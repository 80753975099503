import React from 'react';

import { Typography } from '@nl-lms/ui/components';
import { Emoji } from '../Emoji/Emoji';
import { Illustration } from '../Illustration/Illustration';

import './ErrorPlaceholder.scss';
import { useTestProps } from '@nl-lms/ui/hooks';

export const ErrorPlaceholder = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  return (
    <div className="error-placeholder__container" {...commonProps}>
      {children}
    </div>
  );
};

const ErrorPlaceholderImage = (props) => {
  const commonProps = useTestProps(props, { passThrough: true });
  return (
    <Illustration
      name="broken-mug"
      className="error-placeholder__image"
      {...commonProps}
    />
  );
};
const ErrorPlaceholderTitle = ({ children = null, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });

  return (
    <Typography.h2 {...commonProps}>
      {children ? (
        children
      ) : (
        <>
          Ooops... something broke <Emoji symbol="😔" />
        </>
      )}
    </Typography.h2>
  );
};
const ErrorPlaceholderDescription = ({ children, ...props }) => {
  const commonProps = useTestProps(props, { passThrough: true });

  return (
    <Typography.p className="error-placeholder__description" {...commonProps}>
      {children}
    </Typography.p>
  );
};

ErrorPlaceholder.Image = ErrorPlaceholderImage;
ErrorPlaceholder.Title = ErrorPlaceholderTitle;
ErrorPlaceholder.Description = ErrorPlaceholderDescription;
