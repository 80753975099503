import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { AssessmentQuestion as AssessmentQuestionT } from './types';
import { IApiService } from './types';

export class AssessmentQuestion
  extends ApiService
  implements IApiService<'assessmentQuestion'>
{
  public paths: any;
  public serviceBase: string = '/assessment/question';

  constructor(props) {
    super(props);

    this.paths = {
      get: (id) => `${this.serviceBase}/${id}`,
      list: () => `${this.serviceBase}`,
      create: () => `${this.serviceBase}`,
      update: (id) => `${this.serviceBase}/${id}`,
      remove: (id) => `${this.serviceBase}/${id}`,
      import: () => `${this.serviceBase}/import`,
      analyzeImport: () => `${this.serviceBase}/import/analyze`,
      export: () => `${this.serviceBase}/export`,
      downloadImportTemplate: () => `${this.serviceBase}/import/template`,
      listForSelect: () => `/list/assessment/questions`,
      removeQuestions: () => `${this.serviceBase}`,
    };
  }

  get = (id) =>
    this.api.get(this.paths.get(id)) as Promise<AssessmentQuestionT>;

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), { params: { query } }) as Promise<
      AssessmentQuestionT[]
    >;

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  remove = ({ id }) => this.api.delete(this.paths.remove(id));

  listForSelect = (search: string) =>
    this.api.get<null, AssessmentQuestionT[]>(this.paths.listForSelect(), {
      params: {
        query: { search },
      },
    });

  import = ({ file, questionType }) => {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('question_type', questionType);
    return this.api.post(this.paths.import(), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  analyzeImport = ({ file, questionType }) => {
    const data = new FormData();
    data.set('question_type', questionType);
    data.append('file', file, file.name);
    return this.api.post<
      null,
      {
        existentQuestions: string[];
        duplicateQuestions: string[];
      }
    >(this.paths.analyzeImport(), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  export = (query: AppQuery) =>
    this.api.get(this.paths.export(), {
      params: { query },
      responseType: 'blob',
    }) as Promise<AssessmentQuestionT[]>;

  removeQuestions = (ids) => {
    return this.api.delete(this.paths.removeQuestions(), {
      data: { list: ids },
    });
  };
  downloadImportTemplate = () =>
    this.api.get(this.paths.downloadImportTemplate());
}
