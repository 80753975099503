import { z } from 'zod';

import {
  ActionSchema,
  ActionTriggerSchema,
  AudienceSchema,
  RuleConditionSchema,
  RuleSchema,
  RuleSettingsSchema,
  ScheduleDateSchema,
} from '@nl-lms/common/feature/types';
import {
  FieldConditionSchema,
  TransformedDateSchema,
} from '@nl-lms/common/shared';

const ConditionSchema = z.object({
  eventName: z.string(),
  match: FieldConditionSchema.nullable(),
  referenceEntityId: z.string().nullish(),
});

export const AddRuleConditionSchema = z.object({
  value: z.union([
    ConditionSchema,
    z.lazy(() => z.array(AddRuleConditionSchema)),
  ]),
  combinator: z.union([z.literal('and'), z.literal('or')]).nullish(),
});

export const AddRulesPayloadSchema = z.array(
  z.object({
    name: z.string(),
    entityName: z.string(),
    entityId: z.string(),
    actionName: z.string(),
    scheduleDate: ScheduleDateSchema.nullable(),
    settings: RuleSettingsSchema,
    conditionsEquation: AddRuleConditionSchema,
    payload: z.record(z.string(), z.unknown()).nullish(),
  })
);

export const AddAutomationFlowPayloadSchema = z.object({
  name: z.string(),
  entityId: z.string(),
  entityName: z.string(),
  description: z.string().nullable(),
  rules: AddRulesPayloadSchema,
});

export const ListActionsSchema = ActionSchema.extend({
  triggers: z.array(ActionTriggerSchema),
});

export const ListRuleSchema = RuleSchema.extend({
  conditions: z.array(RuleConditionSchema),
});

export const ScheduleActionsPayloadSchema = z.object({
  audience: AudienceSchema,
  name: z.string(),
  payload: z.record(z.string(), z.unknown()).nullable(),
  entityName: z.string(),
  entityId: z.string(),
  scheduleOn: TransformedDateSchema,
});
