import store from 'store';

import { getLearningAssignmentClients } from '@nl-lms/feature/learning-assignments/sdk';

import { apiBaseUrl } from './api';

const { raw: learningAssignmentsApiClient, rtk: learningAssignmentsRtkClient } =
  getLearningAssignmentClients({
    baseUrl: `${apiBaseUrl('v2')}`,
    tokenGetter: () => store.get('accessToken'),
  });

export { learningAssignmentsApiClient };

export const learningAssignmentsApi = learningAssignmentsRtkClient;
learningAssignmentsApi.enhanceEndpoints<
  | 'LearningAssignment'
  | 'LearningAssignmentInstance'
  | 'LearningAssignmentPlannedInstance'
  | 'LearningAssignmentInstanceAndPlannedInstance'
>({
  endpoints: {
    listLearningAssignments: {
      providesTags: [{ type: 'LearningAssignment', id: 'LIST' }],
    },
    listLearningAssignmentRules: {
      providesTags: [{ type: 'LearningAssignment', id: 'LIST' }],
    },
    getLearningAssignment: {
      providesTags: (result, error, arg) => [
        { type: 'LearningAssignment', id: arg.id },
      ],
    },
    createLearningAssignment: {
      invalidatesTags: [{ type: 'LearningAssignment', id: 'LIST' }],
    },
    updateLearningAssignment: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    updateLearningAssignments: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    addLearningAssignmentAudience: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    removeLearningAssignmentAudience: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    addLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
          { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
        ];
      },
    },
    addLearningAssignmentRules: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: 'LIST' },
          { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
        ];
      },
    },
    removeLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    removeLearningAssignmentRules: {
      invalidatesTags: [{ type: 'LearningAssignment', id: 'LIST' }],
    },
    enableLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    disableLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    applyLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    updateLearningAssignmentRule: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    removeLearningAssignments: {
      invalidatesTags: [
        { type: 'LearningAssignment', id: 'LIST' },
        { type: 'LearningAssignmentInstance', id: 'LIST' },
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
    listLearningAssignmentInstances: {
      providesTags: [{ type: 'LearningAssignmentInstance', id: 'LIST' }],
    },
    addLearningAssignmentInstances: {
      invalidatesTags: [{ type: 'LearningAssignmentInstance', id: 'LIST' }],
    },
    updateLearningAssignmentInstances: {
      invalidatesTags: [{ type: 'LearningAssignmentInstance', id: 'LIST' }],
    },
    removeLearningAssignmentInstances: {
      invalidatesTags: [
        { type: 'LearningAssignmentInstance', id: 'LIST' },
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
    updateLearningAssignmentNotifications: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'LearningAssignment', id: arg.id },
          { type: 'LearningAssignment', id: 'LIST' },
        ];
      },
    },
    resetLearningAssignmentInstances: {
      invalidatesTags: [{ type: 'LearningAssignmentInstance', id: 'LIST' }],
    },
    cancelLearningAssignmentInstances: {
      invalidatesTags: [{ type: 'LearningAssignmentInstance', id: 'LIST' }],
    },
    listLearningAssignmentPlannedInstances: {
      providesTags: [{ type: 'LearningAssignmentPlannedInstance', id: 'LIST' }],
    },
    scheduleLearningAssignmentInstances: {
      invalidatesTags: [
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
    removeLearningAssignmentPlannedInstances: {
      invalidatesTags: [
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
    rescheduleLearningAssignmentPlannedInstances: {
      invalidatesTags: [
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
    forceResolveLearningAssignmentPlannedInstances: {
      invalidatesTags: [
        { type: 'LearningAssignmentInstance', id: 'LIST' },
        { type: 'LearningAssignmentPlannedInstance', id: 'LIST' },
      ],
    },
  },
});

type FilterEndingWith<
  Set,
  Suffix extends string
> = Set extends `${infer _X}${Suffix}` ? Set : never;
export type LearningAssignmentApiMutationNames = FilterEndingWith<
  keyof typeof learningAssignmentsApi,
  'Mutation'
>;
