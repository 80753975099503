import store from 'store';
import { ListViewState } from './ListView.types';

const STORE_KEY = 'LIST_VIEW_STATE';

export class ListViewStore {
  private viewName: string;
  // in minutes
  private cacheDuration: number;
  constructor(viewName: string, cacheDuration = 60) {
    this.viewName = viewName;
    this.cacheDuration = cacheDuration;
  }

  get viewStatesRecord(): Record<
    string,
    {
      viewState: ListViewState;
      updateTimestamp: string;
    }
  > {
    const stringViewStates = store.get(STORE_KEY);
    if (!stringViewStates) {
      return {};
    } else {
      try {
        return JSON.parse(stringViewStates);
      } catch (e) {
        console.error(e);
        return {};
      }
    }
  }

  get viewState(): ListViewState | null {
    const storeState = this.viewStatesRecord[this.viewName];
    if (!storeState) return null;
    if (storeState.updateTimestamp && storeState.viewState) {
      try {
        const currentTimestamp = new Date();
        const updateTimestamp = new Date(storeState.updateTimestamp);
        const diffInMs = currentTimestamp.getTime() - updateTimestamp.getTime();
        const diffInMinutes = Math.round(diffInMs / 60000);
        if (diffInMinutes > this.cacheDuration) return null;
        return storeState.viewState;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  }

  set viewState(viewState) {
    const updateTimestamp = new Date().toISOString();
    const viewStatesRecord = this.viewStatesRecord;
    viewStatesRecord[this.viewName] = {
      updateTimestamp,
// @ts-ignore
      viewState,
    };
    store.set(STORE_KEY, JSON.stringify(viewStatesRecord));
  }
}
