import {
  AppQueryFilter,
  FilterOperator,
  QueryFilterCombinator,
} from '@nl-lms/common/shared';
import { C } from '@nl-lms/ui/constants';

// TODO: WIP on fixes for QueryFilter
// export const getAssignedFilter = () => {
//   const isAssignmentFilter = new QueryFilter();
//   isAssignmentFilter.add({
//     field: 'learning_assignment_instance_id',
//     operator: QueryOperator.IsNotNull,
//     operatorType: QueryFilterOperatorType.Unary,
//     value: undefined,
//   });
//
//   const hasNotPassedDueDateFilter = new QueryFilter();
//   hasNotPassedDueDateFilter.add({
//     operator: QueryOperator.Equals,
//     field: 'show_after_due_date',
//     value: false,
//   });
//   hasNotPassedDueDateFilter.and({
//     operator: QueryOperator.GreaterThan,
//     field: 'due_date',
//     value: new Date().toISOString(),
//   });
//   const canBeShownAfterDueDateFilter = new QueryFilter();
//   canBeShownAfterDueDateFilter.add({
//     field: 'show_after_due_date',
//     operator: QueryOperator.Equals,
//     value: true,
//   });
//   const isVisibleBasedOnDueDateFilter = new QueryFilter();
//   isVisibleBasedOnDueDateFilter.add([
//     hasNotPassedDueDateFilter,
//     'or',
//     canBeShownAfterDueDateFilter,
//   ]);
//
//   const isVisibleBasedOnStartDateFilter = new QueryFilter();
//   isVisibleBasedOnStartDateFilter.add({
//     operator: QueryOperator.LowerThan,
//     value: new Date().toISOString(),
//     field: 'start_date',
//   });
//
//   const isElearningFilter = new QueryFilter();
//   isElearningFilter.add({
//     field: 'type',
//     value: C.I_LEARNER_LEARNING_TYPES.ELEARNING,
//     operator: QueryOperator.Equals,
//   });
//   isElearningFilter.and({
//     field: 'status',
//     operator: QueryOperator.Excludes,
//     value: [
//       C.I_ELEARNING_SESSION_STATUSES.COMPLETED,
//       C.I_ELEARNING_SESSION_STATUSES.CANCELED,
//       C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED,
//     ],
//   });
//   const isAssessmentFilter = new QueryFilter();
//   isAssessmentFilter.add([
//     {
//       field: 'type',
//       value: C.I_LEARNER_LEARNING_TYPES.ASSESSMENT,
//       operator: QueryOperator.Equals,
//     },
//     'and',
//     [
//       {
//         field: 'status',
//         value: C.I_ASSESSMENT_INSTANCE_STATUSES.IN_PROGRESS,
//         operator: QueryOperator.Equals,
//       },
//       'or',
//       [
//         {
//           field: 'status',
//           value: C.I_ASSESSMENT_INSTANCE_STATUSES.PASSED,
//           operator: QueryOperator.NEqual,
//         },
//         'and',
//         {
//           field: 'max_attempts_count_reached',
//           value: false,
//           operator: QueryOperator.Equals,
//         },
//       ],
//     ],
//   ]);
//   const isLearningPathFilter = new QueryFilter();
//   isLearningPathFilter.add({
//     field: 'type',
//     value: C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH,
//     operator: QueryOperator.Equals,
//   });
//   isLearningPathFilter.and({
//     field: 'status',
//     value: C.I_LEARNING_PATH_INSTANCE_STATUSES.COMPLETED,
//     operator: QueryOperator.NEqual,
//   });
//
//   const assignedLearningFilter = new QueryFilter();
//   assignedLearningFilter.add([
//     isAssignmentFilter,
//     'and',
//     [
//       isVisibleBasedOnDueDateFilter,
//       'and',
//       isVisibleBasedOnStartDateFilter,
//       'and',
//       [isElearningFilter, 'or', isAssessmentFilter, 'or', isLearningPathFilter],
//     ],
//   ]);
//
//   return assignedLearningFilter;
// };
//
// export const getOptionalFilter = () => {
//   const isNotAssignmentFilter = new QueryFilter();
//   isNotAssignmentFilter.add({
//     value: undefined,
//     field: 'learning_assignment_instance_id',
//     operator: QueryOperator.IsNull,
//     operatorType: QueryFilterOperatorType.Unary,
//   });
//   const isElearningFilter = new QueryFilter();
//   isElearningFilter.add({
//     field: 'type',
//     value: C.I_LEARNER_LEARNING_TYPES.ELEARNING,
//     operator: QueryOperator.Equals,
//   });
//   isElearningFilter.and({
//     field: 'status',
//     operator: QueryOperator.Includes,
//     value: [
//       C.I_ELEARNING_SESSION_STATUSES.NOT_STARTED,
//       C.I_ELEARNING_SESSION_STATUSES.IN_PROGRESS,
//     ],
//   });
//   const isAssessmentFilter = new QueryFilter();
//   isAssessmentFilter.add({
//     field: 'type',
//     value: C.I_LEARNER_LEARNING_TYPES.ASSESSMENT,
//     operator: QueryOperator.Equals,
//   });
//   isAssessmentFilter.and({
//     field: 'status',
//     value: [
//       C.I_ASSESSMENT_INSTANCE_STATUSES.IN_PROGRESS,
//       C.I_ASSESSMENT_INSTANCE_STATUSES.NOT_STARTED,
//       C.I_ASSESSMENT_INSTANCE_STATUSES.SCORING,
//     ],
//     operator: QueryOperator.Includes,
//   });
//   const isLearningPathFilter = new QueryFilter();
//   isLearningPathFilter.add({
//     field: 'type',
//     value: C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH,
//     operator: QueryOperator.Equals,
//   });
//   isLearningPathFilter.and({
//     field: 'status',
//     value: [
//       C.I_LEARNING_PATH_INSTANCE_STATUSES.NOT_STARTED,
//       C.I_LEARNING_PATH_INSTANCE_STATUSES.IN_PROGRESS,
//     ],
//     operator: QueryOperator.Includes,
//   });
//   const optionalLearningFilter = new QueryFilter();
//   optionalLearningFilter.add([
//     isNotAssignmentFilter,
//     'and',
//     [isElearningFilter, 'or', isAssessmentFilter, 'or', isLearningPathFilter],
//   ]);
//
//   return optionalLearningFilter;
// };
//
// export const getAllFilter = () => {
//   const allFilter = new QueryFilter();
//   allFilter.add([getAssignedFilter(), 'or', getOptionalFilter()]);
//   return allFilter;
// };

export const assignedFilter: AppQueryFilter = {
  id: '',
  value: [
    {
      id: '',
      // @ts-ignore
      value: {
        operator: FilterOperator.IsNotNull,
        value: { label: '', value: undefined },
        field: { label: '', field: 'learning_assignment_instance_id' },
      },
    },
    {
      id: '',
      value: [
        {
          id: '',
          value: {
            operator: FilterOperator.DoesNotContain,
            value: { label: '', value: 'Curs istoric' },
            field: { label: '', field: 'description' },
          },
        },
        {
          id: '',
          // @ts-ignore
          value: {
            operator: FilterOperator.IsNull,
            field: { label: '', field: 'description' },
            value: {
              label: '',
              value: undefined,
            },
          },
          combinator: QueryFilterCombinator.Or,
        },
      ],
      combinator: QueryFilterCombinator.And,
    },
    {
      id: '',
      value: [
        {
          id: 'sho_after_duedate',
          value: [
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'show_after_due_date' },
                    value: {
                      label: '',
                      value: false,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.GreaterThan,
                    field: { label: '', field: 'due_date' },
                    value: {
                      label: '',
                      value: '{{current_time}}',
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
              ],
            },
            {
              id: '',
              value: {
                operator: FilterOperator.Equals,
                field: { label: '', field: 'show_after_due_date' },
                value: {
                  label: '',
                  value: true,
                },
              },
              combinator: QueryFilterCombinator.Or,
            },
            {
              id: '',
              // @ts-ignore
              value: {
                operator: FilterOperator.IsNull,
                field: { label: '', field: 'due_date' },
                value: {
                  label: '',
                  value: undefined,
                },
              },
              combinator: QueryFilterCombinator.Or,
            },
          ],
        },
        {
          id: '',
          value: [
            {
              id: '',
              value: {
                operator: FilterOperator.LowerThan,
                field: { label: '', field: 'start_date' },
                value: {
                  label: '',
                  value: '{{current_time}}',
                },
              },
            },
            {
              id: '',
              // @ts-ignore
              value: {
                operator: FilterOperator.IsNull,
                field: { label: '', field: 'start_date' },
                value: {
                  label: '',
                  value: undefined,
                },
              },
              combinator: QueryFilterCombinator.Or,
            },
          ],
          combinator: QueryFilterCombinator.And,
        },
        {
          id: '',
          value: [
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.ELEARNING,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: [
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_ELEARNING_SESSION_STATUSES.COMPLETED,
                        },
                      },
                    },
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value:
                            C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED,
                        },
                      },
                      combinator: QueryFilterCombinator.And,
                    },
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_ELEARNING_SESSION_STATUSES.CANCELED,
                        },
                      },
                      combinator: QueryFilterCombinator.And,
                    },
                  ],
                  combinator: QueryFilterCombinator.And,
                },
              ],
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.SURVEY,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: [
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_SURVEY_STATUS.COMPLETED,
                        },
                      },
                    },
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_SURVEY_STATUS.CANCELED,
                        },
                      },
                      combinator: QueryFilterCombinator.And,
                    },
                  ],
                  combinator: QueryFilterCombinator.And,
                },
              ],
              combinator: QueryFilterCombinator.Or,
            },

            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.CHECKLIST,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: [
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_CHECKLIST_INSTANCE_STATUSES.COMPLETED,
                        },
                      },
                    },

                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_CHECKLIST_INSTANCE_STATUSES.CANCELED,
                        },
                      },
                      combinator: QueryFilterCombinator.And,
                    },
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.NEqual,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: C.I_CHECKLIST_INSTANCE_STATUSES.FAILED,
                        },
                      },
                      combinator: QueryFilterCombinator.And,
                    },
                  ],
                  combinator: QueryFilterCombinator.And,
                },
              ],
              combinator: QueryFilterCombinator.Or,
            },

            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: [
                    {
                      id: '',
                      value: {
                        operator: FilterOperator.Excludes,
                        field: { label: '', field: 'status' },
                        value: {
                          label: '',
                          value: [
                            C.I_TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES
                              .ATTENDED,
                            C.I_TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES
                              .ABSENT,
                            C.I_TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES
                              .GO_SHOW,
                            C.I_TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES
                              .CANCELLED,
                          ],
                        },
                      },
                    },
                  ],
                  combinator: QueryFilterCombinator.And,
                },
              ],
              combinator: QueryFilterCombinator.Or,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.ASSESSMENT,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: [
                    {
                      id: '',
                      value: [
                        {
                          id: '',
                          value: {
                            operator: FilterOperator.NEqual,
                            field: { label: '', field: 'status' },
                            value: {
                              label: '',
                              value: C.I_ASSESSMENT_INSTANCE_STATUSES.PASSED,
                            },
                          },
                        },
                        {
                          id: '',
                          value: {
                            operator: FilterOperator.Equals,
                            field: {
                              label: '',
                              field: 'max_attempts_count_reached',
                            },
                            value: {
                              label: '',
                              value: false,
                            },
                          },
                          combinator: QueryFilterCombinator.And,
                        },
                      ],
                    },
                    {
                      id: '',
                      value: [
                        {
                          id: '',
                          value: {
                            operator: FilterOperator.Equals,
                            field: { label: '', field: 'status' },
                            value: {
                              label: '',
                              value:
                                C.I_ASSESSMENT_INSTANCE_STATUSES.IN_PROGRESS,
                            },
                          },
                        },
                      ],
                      combinator: QueryFilterCombinator.Or,
                    },
                  ],
                  combinator: QueryFilterCombinator.And,
                },
              ],
              combinator: QueryFilterCombinator.Or,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'type' },
                    value: {
                      label: '',
                      value: C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.NEqual,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_LEARNING_PATH_INSTANCE_STATUSES.COMPLETED,
                    },
                  },
                  combinator: QueryFilterCombinator.And,
                },
              ],
              combinator: QueryFilterCombinator.Or,
            },
          ],
          combinator: QueryFilterCombinator.And,
        },
      ],
      combinator: QueryFilterCombinator.And,
    },
  ],
};

export const optionalFilter: AppQueryFilter = {
  id: '',
  value: [
    {
      id: '',
      // @ts-ignore
      value: {
        operator: FilterOperator.IsNull,
        value: { label: '', value: undefined },
        field: { label: '', field: 'learning_assignment_instance_id' },
      },
    },
    {
      id: '',
      value: {
        // srry :( this is hack
        // TODO move this filter to config and have a standard way of filtering historic data
        operator: FilterOperator.DoesNotContain,
        value: { label: '', value: 'Curs istoric' },
        field: { label: '', field: 'description' },
      },
      combinator: QueryFilterCombinator.And,
    },
    {
      id: '',
      value: [
        {
          id: '',
          value: [
            {
              id: '',
              value: {
                operator: FilterOperator.Equals,
                field: { label: '', field: 'type' },
                value: {
                  label: '',
                  value: C.I_LEARNER_LEARNING_TYPES.ELEARNING,
                },
              },
              combinator: QueryFilterCombinator.And,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_ELEARNING_SESSION_STATUSES.NOT_STARTED,
                    },
                  },
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_ELEARNING_SESSION_STATUSES.IN_PROGRESS,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
              ],
              combinator: QueryFilterCombinator.And,
            },
          ],
        },
        {
          id: '',
          value: [
            {
              id: '',
              value: {
                operator: FilterOperator.Equals,
                field: { label: '', field: 'type' },
                value: {
                  label: '',
                  value: C.I_LEARNER_LEARNING_TYPES.SURVEY,
                },
              },
              combinator: QueryFilterCombinator.And,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_SURVEY_STATUS.IN_PROGRESS,
                    },
                  },
                },
              ],
              combinator: QueryFilterCombinator.And,
            },
          ],
          combinator: QueryFilterCombinator.Or,
        },
        {
          id: '',
          value: [
            {
              id: '',
              value: {
                operator: FilterOperator.Equals,
                field: { label: '', field: 'type' },
                value: {
                  label: '',
                  value: C.I_LEARNER_LEARNING_TYPES.ASSESSMENT,
                },
              },
              combinator: QueryFilterCombinator.And,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_ASSESSMENT_INSTANCE_STATUSES.NOT_STARTED,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_ASSESSMENT_INSTANCE_STATUSES.IN_PROGRESS,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_ASSESSMENT_INSTANCE_STATUSES.SCORING,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
              ],
              combinator: QueryFilterCombinator.And,
            },
          ],
          combinator: QueryFilterCombinator.Or,
        },
        {
          id: '',
          value: [
            {
              id: '',
              value: {
                operator: FilterOperator.Equals,
                field: { label: '', field: 'type' },
                value: {
                  label: '',
                  value: C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH,
                },
              },
              combinator: QueryFilterCombinator.And,
            },
            {
              id: '',
              value: [
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_LEARNING_PATH_INSTANCE_STATUSES.NOT_STARTED,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
                {
                  id: '',
                  value: {
                    operator: FilterOperator.Equals,
                    field: { label: '', field: 'status' },
                    value: {
                      label: '',
                      value: C.I_LEARNING_PATH_INSTANCE_STATUSES.IN_PROGRESS,
                    },
                  },
                  combinator: QueryFilterCombinator.Or,
                },
              ],
              combinator: QueryFilterCombinator.And,
            },
          ],
          combinator: QueryFilterCombinator.Or,
        },
      ],
      combinator: QueryFilterCombinator.And,
    },
  ],
};

export const allFilter: AppQueryFilter = {
  id: '',
  value: [
    optionalFilter,
    {
      ...assignedFilter,
      combinator: QueryFilterCombinator.Or,
    },
  ],
  combinator: QueryFilterCombinator.And,
};
