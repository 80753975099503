import { createDraft, finishDraft, immerable, produce } from 'immer';

import {
  AppQueryFilter,
  AppQueryFilterField,
  AppQueryFilterModel,
  AppQueryFilterValue,
  ArraySelectFieldMetadata,
  BooleanFieldMetadata,
  DateFieldMetadata,
  DatetimeFieldMetadata,
  FieldMetadata,
  FieldType,
  JsonArraySelectFieldMetadata,
  NumberFieldMetadata,
  QueryFilterCombinator,
  QueryFilterOperator,
  QueryFilterOperatorType,
  QueryOperator,
  SelectFieldMetadata,
  StringFieldMetadata,
  isGeneralSelectFieldMetadata,
} from '@nl-lms/common/shared';
import { _ } from '@nl-lms/vendor';

import { FilterBarActiveFilter } from '../FilterBar.types';

export type NumberFilterComponent = {
  type: 'NumberFilterComponent';
  index: number;
  field: NumberFieldMetadata;
  operator: QueryOperator;
  value: number;
};

export type StringFilterComponent = {
  type: 'StringFilterComponent';
  index: number;
  field: StringFieldMetadata;
  operator: QueryOperator;
  value: string;
};

export type DateFilterComponent = {
  type: 'DateFilterComponent';
  index: number;
  field: DateFieldMetadata;
  operator: QueryOperator;
  value: string;
};

export type DatetimeFilterComponent = {
  type: 'DatetimeFilterComponent';
  index: number;
  field: DatetimeFieldMetadata;
  operator: QueryOperator;
  value: string;
};

export type BooleanFilterComponent = {
  type: 'BooleanFilterComponent';
  index: number;
  field: BooleanFieldMetadata;
  operator: QueryOperator;
  value: boolean;
};

export type SelectFilterComponent = {
  type: 'SelectFilterComponent';
  index: number;
  field:
    | SelectFieldMetadata
    | JsonArraySelectFieldMetadata
    | ArraySelectFieldMetadata;
  operator: QueryOperator;
  value: string[] | number[];
};

export type FilterBarFilterComponent =
  | NumberFilterComponent
  | StringFilterComponent
  | DatetimeFilterComponent
  | DateFilterComponent
  | BooleanFilterComponent
  | SelectFilterComponent;

export type FilterBarFilterComponentType = FilterBarFilterComponent['type'];

export type FilterBarCombinatorComponent = {
  index: number;
  type: 'CombinatorComponent';
  value: QueryFilterCombinator;
};

export type FilterBarBracketComponent = {
  index: number;
  type: 'BracketComponent';
  value: '(' | ')';
};

export type FilterBarFilter =
  | FilterBarFilterComponent
  | FilterBarCombinatorComponent
  | FilterBarBracketComponent;

export const FieldTypeToFilterComponentType: Record<
  FieldType,
  FilterBarFilterComponentType
> = {
  [FieldType.boolean]: 'BooleanFilterComponent',
  [FieldType.number]: 'NumberFilterComponent',
  [FieldType.string]: 'StringFilterComponent',
  [FieldType.date]: 'DateFilterComponent',
  [FieldType.datetime]: 'DatetimeFilterComponent',
  [FieldType.select]: 'SelectFilterComponent',
  [FieldType.jsonArraySelect]: 'SelectFilterComponent',
  [FieldType.arraySelect]: 'SelectFilterComponent',
};
const activeFilterToFilterComponent = (
  activeFilter: FilterBarActiveFilter
): FilterBarFilterComponent => {
  return {
    index: activeFilter.index,
    type: FieldTypeToFilterComponentType[activeFilter.field.type],
    value: activeFilter.value,
    operator: activeFilter.operator,
    field: activeFilter.field,
  } as FilterBarFilterComponent;
};

/**
 * The purpose of this class is to:
 * - Keep track of the current "submitted filters" in a linear way
 *   Examples of that state might look like:
 *   * "(" "name contains test" "AND" "status includes [confirmed]" ")"
 *   * "(" "startDate gte 10.10.2020" "OR" "(" "name equals new" "AND" "disabled is false" ")" ")"
 *   We use this representation to have an easier life when we process it
 *
 * The main responsibilities here are
 * - performing operations on the aforementioned filter components
 *   * Add Filter, Edit Filter, Group Filters, Update Combinators, Remove Filters
 * - parse the linear filter representation to and from the AppQueryFilter representation
 */
export class FilterBarFiltersStore {
  [immerable] = true;
  readonly filters: FilterBarFilter[];
  readonly fields: FieldMetadata[];
  readonly combinator: QueryFilterCombinator;

  constructor(props: {
    initialFilters?: AppQueryFilter;
    fields: FieldMetadata[];
  }) {
    const { initialFilters, fields } = props;
    // this.fields = this.parseFieldOptions(fields);
    this.fields = fields;
    this.combinator = QueryFilterCombinator.And;
    if (!initialFilters) {
      this.filters = [
        {
          type: 'BracketComponent',
          value: '(',
          index: 0,
        },
        {
          type: 'BracketComponent',
          value: ')',
          index: 1,
        },
      ];
    } else {
      if (!Array.isArray(initialFilters.value)) {
        this.filters = this.fromAppQueryFilter({
          id: 'initial',
          value: [initialFilters],
        });
      } else {
        this.filters = this.fromAppQueryFilter(initialFilters);
      }
    }
  }

  fromAppQueryFilter(
    appQueryFilter: AppQueryFilter,
    filterIndex?: number
  ): FilterBarFilter[] {
    let filters: FilterBarFilter[] = [];
    if (filterIndex && filterIndex !== 0 && appQueryFilter.combinator) {
      filters.push({
        index: 0,
        type: 'CombinatorComponent',
        value: appQueryFilter.combinator,
      });
    }
    if (Array.isArray(appQueryFilter.value)) {
      filters.push({
        index: 1,
        type: 'BracketComponent',
        value: '(',
      });
      const nested_filters = appQueryFilter.value
        .map((v, index) => this.fromAppQueryFilter(v, index))
        .flat();
      filters.push(...nested_filters);
      filters.push({
        index: 0,
        type: 'BracketComponent',
        value: ')',
      });
    } else {
      try {
        filters.push(
          // @ts-ignore
          this.appQueryFilterModelToFilterComponent(appQueryFilter.value)
        );
      } catch (e) {
        console.error(e);
      }
    }

    filters.forEach((filter, index) => {
      filter.index = index;
    });
    return filters;
  }

  appQueryFilterModelToFilterComponent(
    appQueryFilterModel: AppQueryFilterModel
  ): FilterBarFilterComponent {
    const fieldName = appQueryFilterModel.field.field;
    const operator = appQueryFilterModel.operator.value;
    // @ts-ignore
    const value = appQueryFilterModel.value.value;
    let field = this.fields.find(
      // this ze problem
      // (f) => _.snakeCase(f.name) === _.snakeCase(fieldName)
      (f) => f.name === fieldName
    );
    if (!field) {
      field = this.fields.find(
        (f) => _.snakeCase(f.name) === _.snakeCase(fieldName)
      );
    }
    if (!field) {
      throw new Error('Unable to find field ' + fieldName);
    }
    let filterComponent = {
      index: 0,
      type: '',
      field,
      operator,
      value,
    };
    switch (true) {
      case isGeneralSelectFieldMetadata(field):
        return {
          ...filterComponent,
          type: 'SelectFilterComponent',
        } as SelectFilterComponent;
      case field.type === FieldType.date:
        return {
          ...filterComponent,
          type: 'DateFilterComponent',
        } as DateFilterComponent;
      case field.type === FieldType.datetime:
        return {
          ...filterComponent,
          type: 'DatetimeFilterComponent',
        } as DatetimeFilterComponent;
      case field.type === FieldType.string:
        return {
          ...filterComponent,
          type: 'StringFilterComponent',
        } as StringFilterComponent;
      case field.type === FieldType.boolean:
        return {
          ...filterComponent,
          type: 'BooleanFilterComponent',
        } as BooleanFilterComponent;
      default:
        return {
          ...filterComponent,
          type: 'NumberFilterComponent',
        } as NumberFilterComponent;
    }
  }

  toAppQueryFilter(
    filters: FilterBarFilter[],
    options: {
      combinator?: QueryFilterCombinator;
      startIndex?: number;
      endIndex?: number;
    } = {}
  ): AppQueryFilter {
    const appQueryFiltersArray: AppQueryFilter[] = [];
    const {
      combinator = null,
      startIndex = 0,
      endIndex = filters.length,
    } = options;
    // @ts-ignore
    let activeCombinator: QueryFilterCombinator = null;
    let indexCounter = startIndex;
    while (indexCounter < endIndex) {
      const component = filters[indexCounter];
      const activeIndex = indexCounter;
      indexCounter += 1;
      if (
        component.type === 'BracketComponent' &&
        activeIndex !== 0 &&
        component.value === '('
      ) {
        const endIndex = this.getMatchingClosingBracketIndex(activeIndex);
        appQueryFiltersArray.push(
          this.toAppQueryFilter(this.filters, {
            combinator: activeCombinator,
            startIndex: indexCounter,
            endIndex,
          })
        );
        indexCounter = endIndex + 1;
      } else if (component.type === 'CombinatorComponent') {
        activeCombinator = component.value;
      } else if (
        [
          'StringFilterComponent',
          'SelectFilterComponent',
          'BooleanFilterComponent',
          'NumberFilterComponent',
          'DateFilterComponent',
          'DatetimeFilterComponent',
        ].includes(component.type)
      ) {
        appQueryFiltersArray.push(
          this.filterComponentToAppQueryFilter(
            component as FilterBarFilterComponent,
            activeCombinator
          )
        );
      }
    }
    return {
      id: '0',
      // @ts-ignore
      combinator,
      value: appQueryFiltersArray,
    };
  }

  filterComponentToAppQueryFilter(
    filter: FilterBarFilterComponent,
    combinator?: QueryFilterCombinator
  ): AppQueryFilter {
    const field: AppQueryFilterField = {
      field: filter.field.name,
      label: filter.field.label,
    };
    const operator: QueryFilterOperator = {
      value: filter.operator,
      type:
        filter.field.type === FieldType.boolean
          ? QueryFilterOperatorType.Unary
          : QueryFilterOperatorType.Binary,
    };
    const value = {
      label: `${filter.index}`,
      value: filter.value,
    } as AppQueryFilterValue;
    return {
      id: `${filter.index}`,
      combinator,
      value: {
        field,
        operator,
        value,
      },
    };
  }

  get appQueryFilter(): AppQueryFilter {
    return this.toAppQueryFilter(this.filters);
  }

  updateFields(fields: FieldMetadata[]): FilterBarFiltersStore {
    return produce(this, (draft) => {
      draft.fields = fields;
    });
  }

  updateFilterComponentIndexes(): FilterBarFiltersStore {
    return produce(this, (draft) => {
      draft.filters.forEach((filter, index) => {
        filter.index = index;
      });
    });
  }

  addComponentToFiltersArray(
    component: FilterBarFilter,
    index: number
  ): FilterBarFiltersStore {
    return produce(this, (draft) => {
      draft.filters.splice(index, 0, component);
    });
  }

  addFilter(
    activeFilter: FilterBarActiveFilter,
    combinator?: QueryFilterCombinator
  ): FilterBarFiltersStore {
    const filterComponent = activeFilterToFilterComponent(activeFilter);

    const newFilterIndex = filterComponent.index || this.filters.length - 1;
    const filters = this.addComponentToFiltersArray(
      filterComponent,
      newFilterIndex
    );
    const filtersWithNewIndexes = filters.updateFilterComponentIndexes();

    if (newFilterIndex > 1) {
      const combinatorComponent: FilterBarCombinatorComponent = {
        index: newFilterIndex,
        type: 'CombinatorComponent',
        value: combinator || this.combinator,
      };
      const filtersWithCombinator =
        filtersWithNewIndexes.addComponentToFiltersArray(
          combinatorComponent,
          newFilterIndex
        );
      return filtersWithCombinator.updateFilterComponentIndexes();
    }
    return filtersWithNewIndexes;
  }

  updateFilter(activeFilter: FilterBarActiveFilter): FilterBarFiltersStore {
    const filterComponent = activeFilterToFilterComponent(activeFilter);
    const currentFilter = this.filters[filterComponent.index];
    if (!currentFilter) {
      throw new Error('Invalid index value');
    }
    if (
      currentFilter.type === 'BracketComponent' ||
      currentFilter.type === 'CombinatorComponent'
    ) {
      throw new Error('Invalid filter type');
    }
    return produce(this, (draft) => {
      draft.filters[filterComponent.index] = filterComponent;
    });
  }

  removeFilter(filterIndex: number) {
    const filterToBeRemoved = this.filters[filterIndex];
    if (
      !filterToBeRemoved ||
      filterToBeRemoved.type === 'CombinatorComponent'
    ) {
      return this;
    }

    if (filterToBeRemoved.type === 'BracketComponent') {
      const filters = this.removeBracket(filterIndex);
      return filters.updateFilterComponentIndexes();
    }
    const indexesToRemove = [filterIndex];
    if (this.filters[filterIndex - 1].type === 'CombinatorComponent') {
      indexesToRemove.push(filterIndex - 1);
    } else if (this.filters[filterIndex + 1].type === 'CombinatorComponent') {
      indexesToRemove.push(filterIndex + 1);
    }
    if (
      filterIndex > 1 &&
      this.filters[filterIndex - 1].type === 'BracketComponent' &&
      this.filters[filterIndex - 1].value === '(' &&
      filterIndex - 1 !== 0
    ) {
      const closingBracketIndex = this.getMatchingClosingBracketIndex(
        filterIndex - 1
      );
      // This means there are only two filters in this group and we need to remove the brackets
      if (closingBracketIndex - 3 === filterIndex) {
        indexesToRemove.push(filterIndex - 1);
        indexesToRemove.push(closingBracketIndex);
      }
    }

    if (
      filterIndex + 1 < this.filters.length &&
      this.filters[filterIndex + 1].type === 'BracketComponent' &&
      this.filters[filterIndex + 1].value === ')' &&
      filterIndex + 1 !== this.filters.length - 1
    ) {
      const openBracketIndex = this.getMatchingOpenBracketIndex(
        filterIndex + 1
      );
      if (openBracketIndex + 3 === filterIndex) {
        indexesToRemove.push(filterIndex + 1);
        indexesToRemove.push(openBracketIndex);
      }
    }

    const newFilters = produce<FilterBarFiltersStore>(this, (draft) => {
      draft.filters = draft.filters.filter((component, index) => {
        return !indexesToRemove.includes(index);
      });
    });
    return newFilters.updateFilterComponentIndexes();
  }

  reset(): FilterBarFiltersStore {
    const openBracket: FilterBarBracketComponent = {
      type: 'BracketComponent',
      value: '(',
      index: 0,
    };
    const closedBracket: FilterBarBracketComponent = {
      type: 'BracketComponent',
      value: ')',
      index: 1,
    };
    const newFilters = produce(this, (draft) => {
      draft.filters = [openBracket, closedBracket];
    });
    return newFilters.updateFilterComponentIndexes();
  }

  toggleCombinator(combinatorIndex: number): FilterBarFiltersStore {
    const combinator = this.filters[combinatorIndex];
    if (!combinator || combinator.type !== 'CombinatorComponent') {
      throw new Error('Invalid combinator');
    }

    const draft = createDraft<FilterBarFiltersStore>(this);
    draft.filters[combinatorIndex].value =
      combinator.value === QueryFilterCombinator.And
        ? QueryFilterCombinator.Or
        : QueryFilterCombinator.And;
    return finishDraft(draft);
  }

  groupFilters(startIndex: number, endIndex: number): FilterBarFiltersStore {
    if (startIndex >= endIndex) {
      throw new Error('Invalid index values');
    }

    const startBracket: FilterBarBracketComponent = {
      value: '(',
      type: 'BracketComponent',
      index: startIndex,
    };
    const withStartBracket = this.addComponentToFiltersArray(
      startBracket,
      startBracket.index
    );
    const withStartBracketAndNewIndexes =
      withStartBracket.updateFilterComponentIndexes();
    const endBracket: FilterBarBracketComponent = {
      value: ')',
      type: 'BracketComponent',
      index: endIndex + 1,
    };
    const withEndBracket =
      withStartBracketAndNewIndexes.addComponentToFiltersArray(
        endBracket,
        endBracket.index
      );

    return withEndBracket.updateFilterComponentIndexes();
  }

  removeBracket(bracketIndex: number): FilterBarFiltersStore {
    const bracket = this.filters[bracketIndex];
    if (!bracket || bracket.type !== 'BracketComponent') {
      throw new Error('Invalid bracket');
    }
    let startIndex = bracketIndex;
    let endIndex = bracketIndex;
    if (bracket.value === ')') {
      startIndex = this.getMatchingOpenBracketIndex(bracketIndex);
    } else {
      endIndex = this.getMatchingClosingBracketIndex(bracketIndex);
    }
    return produce(this, (draft) => {
      draft.filters.splice(startIndex, 1);
      draft.filters.splice(endIndex, 1);
    });
  }

  changeCombinator(combinator: QueryFilterCombinator) {
    const draft = createDraft<FilterBarFiltersStore>(this);
    draft.combinator = combinator;
    return finishDraft(draft);
  }

  getMatchingOpenBracketIndex(bracketIndex: number) {
    const filtersWithoutValuesAfterBracket = this.filters.slice(
      0,
      bracketIndex
    );
    let openBracketIndex;
    let bracketCounter = 1;
    filtersWithoutValuesAfterBracket.reverse().some((component) => {
      if (component.type === 'BracketComponent') {
        if (component.value === ')') {
          bracketCounter += 1;
        } else {
          bracketCounter -= 1;
        }
        if (bracketCounter === 0) {
          openBracketIndex = component.index;
          return true;
        }
        return false;
      }
    });

    if (!openBracketIndex) throw new Error('Unable to find open bracket index');
    return openBracketIndex;
  }

  getMatchingClosingBracketIndex(bracketIndex: number) {
    const filtersWithoutValuesBeforeBracket = this.filters.slice(
      bracketIndex + 1
    );
    let closingBracketIndex;
    let bracketCounter = 1;
    this.filters.slice(bracketIndex);
    filtersWithoutValuesBeforeBracket.some((component) => {
      if (component.type === 'BracketComponent') {
        if (component.value === '(') {
          bracketCounter += 1;
        } else {
          bracketCounter -= 1;
        }
        if (bracketCounter === 0) {
          closingBracketIndex = component.index;
          return true;
        }
        return false;
      }
    });

    if (!closingBracketIndex)
      throw new Error('Unable to find closed bracket index');
    return closingBracketIndex;
  }
}
