import React, { useCallback, useMemo } from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { Draggable } from '../Draggable';
import { ErrorPlaceholder } from '../ErrorPlaceholder/ErrorPlaceholder';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import { NoDataPlaceholder } from '../NoDataPlaceholder/NoDataPlaceholder';
import { Pagination } from '../Pagination/Pagination';
import './Table.scss';
import { TableCellType, TableProps } from './Table.types';
import {
  DataBodyCell,
  DataHeaderCell,
  ExpanderBodyCell,
  ExpanderHeaderCell,
  RowActionsBodyCell,
  RowActionsHeaderCell,
  SelectionBodyCell,
  SelectionHeaderCell,
} from './TableCell';
import { TableProvider, useTableContext } from './TableContext';

export function Table<Entity extends Record<string, any>>(
  props: TableProps<Entity>,
) {
  const { children, ...rest } = props;
  const commonProps = useTestProps(props, { passThrough: true });

  return (
    <TableProvider {...rest}>
      <TableContainer {...commonProps} style={rest.style}>
        <TableHeader />
        {/*// @ts-ignore */}
        <TableBody>
          {/*// @ts-ignore */}
          <TableRows>{children}</TableRows>
        </TableBody>
      </TableContainer>
      <TableRowSelectionMenu />
      <TablePaginationRow />
    </TableProvider>
  );
}

export function TableWithoutRowSelectionMenu<
  Entity extends Record<string, any>,
>(props: TableProps<Entity>) {
  const { children, ...rest } = props;
  return (
    <TableProvider {...rest}>
      <TableContainer>
        <TableHeader />
        <TableBody>
          {/*// @ts-ignore */}
          <TableRows>{children}</TableRows>
        </TableBody>
      </TableContainer>
      <TablePaginationRow />
    </TableProvider>
  );
}

export function TableWithFullPagination<Entity extends Record<string, any>>(
  props: TableProps<Entity>,
) {
  const { children, ...rest } = props;
  return (
    <TableProvider {...rest}>
      <TableContainer>
        <TableHeader />
        <TableBody>
          {/*// @ts-ignore */}
          <TableRows>{children}</TableRows>
        </TableBody>
      </TableContainer>
      <TableRowSelectionMenu />
      <TablePaginationRow selectLimit={true} />
    </TableProvider>
  );
}

export const TableHeader = () => {
  const { headerGroups, getTableProps } = useTableContext();

  return (
    <div className="table__header-wrapper" {...getTableProps()}>
      <div className="table__head">
        {headerGroups.map((headerGroup, index) => (
          <div
            // TODO: This might cause an issue when we have multiple tables on the same page
            // @ts-ignore
            key={`header-group-${index}`}
            {..._.omit(headerGroup.getHeaderGroupProps(), 'key')}
            className="table__header-row"
          >
            {headerGroup.headers.map((column, index) => (
              <TableHeaderCell
                column={column}
                key={`${column.id}-${index}`}
                //@ts-ignore
                tid={column.tid}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const CellTypeToHeaderComponentRecord: Record<
  TableCellType,
  React.FunctionComponent<{ column: any }>
> = {
  RowActions: RowActionsHeaderCell,
  Expansion: ExpanderHeaderCell,
  Selection: SelectionHeaderCell,
  Data: DataHeaderCell,
};

const TableHeaderCell = ({ column, ...props }) => {
  const Component = CellTypeToHeaderComponentRecord[column.type];
  if (!Component) return null;
  const commonProps = useTestProps(props, { passThrough: true });
  return <Component column={column} {...commonProps} />;
};

export const TableBody = ({ children, ...props }) => {
  const { getTableBodyProps } = useTableContext();
  const commonProps = useTestProps(props);
  return (
    <div {...getTableBodyProps()} className="table__body" {...commonProps}>
      {children}
    </div>
  );
};

export const TableRows = ({ children }: React.PropsWithChildren<{}>) => {
  const { rows, error, isLoading } = useTableContext();

  if (isLoading) {
    return (
      <>
        {rows.map((row) => (
          <TableBodyRow key={`table-row-${row.id}`} row={row} />
        ))}
      </>
    );
  }

  if (error) {
    return (
      <div className="table__no-data-wrapper">
        {children ? children : <TableErrorPlaceholder />}
      </div>
    );
  }
  if (!rows.length) {
    return (
      <div className="table__no-data-wrapper">
        {children ? children : <TableNoDataPlaceholder />}
      </div>
    );
  }
  return (
    <>
      {rows.map((row) => (
        <TableBodyRow key={`table-row-${row.id}`} row={row} />
      ))}
    </>
  );
};

export const TableContainer = ({ children, ...props }) => {
  const commonProps = useTestProps(props);
  const { getTableProps, isSelectable, data } = useTableContext();

  const isFullHeight = data.length === 0;

  const classNameProps = useClassNameProps(
    'table',
    isFullHeight ? 'table--full-height' : null,
  );

  const tableProps = getTableProps();
  tableProps.style = {
    ...(props.style || {}),
    ...(tableProps.style || {}),
  };

  return (
    <div {...tableProps} {...classNameProps} {...commonProps}>
      <div
        className={`table__content ${
          isSelectable ? 'table__content--selectable' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

const TableBodyRow = ({ row }) => {
  const {
    prepareRow,
    isLoading,
    rowSelection,
    rowIdAccessor,
    onDrop,
    isDraggable,
    allRowsAreSelected,
    rowExclusion,
    ExpanderComponent,
    expandedRows,
  } = useTableContext();
  prepareRow(row);
  const isExpanded = !!expandedRows[row.original[rowIdAccessor]];
  const isSelected = allRowsAreSelected
    ? !rowExclusion[row.original[rowIdAccessor]]
    : !!rowSelection[row.original[rowIdAccessor]];

  const classNameProps = useClassNameProps(
    'table__row',
    isExpanded ? 'table__row--expanded' : null,
    isSelected ? 'table__row--selected' : null,
    isLoading ? 'table__row--loading' : null,
  );

  if (isDraggable && onDrop) {
    return (
      <>
        <Draggable
          asChild
          type="report-column"
          index={row.index}
          onDrop={onDrop}
        >
          <div
            key={row.original[rowIdAccessor]}
            {...row.getRowProps()}
            {...classNameProps}
          >
            {row.cells.map((cell, index) => (
              <TableBodyCell
                key={`${cell.row.id}-${cell.column.id}-${cell.value}`}
                index={index}
                column={cell.column}
                cell={cell}
                row={row}
                tid={cell.column.tid}
              />
            ))}
          </div>
        </Draggable>
        {isExpanded ? (
          <div className="table__row-expanded-content">
            {' '}
            <ExpanderComponent row={row.original} />{' '}
          </div>
        ) : null}
      </>
    );
  }
  return (
    <>
      <div
        key={row.original[rowIdAccessor]}
        {..._.omit(row.getRowProps(), 'key')}
        {...classNameProps}
      >
        {row.cells.map((cell, index) => (
          <TableBodyCell
            key={`${cell.row.id}-${cell.column.id}-${cell.value}`}
            index={index}
            column={cell.column}
            cell={cell}
            row={row}
            tid={cell.column.tid}
          />
        ))}
      </div>
      {isExpanded ? (
        <div className="table__row-expanded-content">
          {' '}
          <ExpanderComponent row={row.original} />{' '}
        </div>
      ) : null}
    </>
  );
};

const CellTypeToBodyComponentRecord: Record<
  TableCellType,
  React.FunctionComponent<{ cell: any; row: any; index: number }>
> = {
  RowActions: RowActionsBodyCell,
  Expansion: ExpanderBodyCell,
  Selection: SelectionBodyCell,
  Data: DataBodyCell,
};

const TableBodyCell = ({ cell, row, column, index, ...props }) => {
  const Component = CellTypeToBodyComponentRecord[column.type];
  if (!Component) return null;
  const commonProps = useTestProps(props, { passThrough: true });
  return <Component cell={cell} row={row} index={index} {...commonProps} />;
};

export const TablePaginationRow = ({
  selectLimit = false,
  sticky = true,
  className = '',
}) => {
  const { pagination, isPaginated, onChangePagination } = useTableContext();
  if (!isPaginated) {
    return null;
  }

  return (
    <div
      className={`table__pagination ${
        sticky ? 'table__pagination--sticky' : ''
      } ${className}`}
    >
      <Pagination
        pagination={pagination}
        variant="white"
        // @ts-ignore
        onChangePage={onChangePagination}
        selectLimit={selectLimit}
      />
    </div>
  );
};

export const TableRowSelectionMenu = ({ className = '' }) => {
  const {
    tableActions,
    pagination,
    allRowsAreSelected,
    excludedRows,
    data,
    selectionMode,
    selectedRows,
    onSelectRow,
  } = useTableContext();
  const selectedRowsCount = useMemo(() => {
    if (allRowsAreSelected && pagination) {
      return pagination.rowCount - excludedRows.length;
    }
    return selectedRows.length;
  }, [pagination, selectedRows, allRowsAreSelected, excludedRows]);
  const maxRowsCount = useMemo(() => {
    if (pagination) {
      return pagination.rowCount;
    }
    return data.length;
  }, [data, selectedRows]);

  const deselectAllRows = useCallback(() => {
    onSelectRow(false);
  }, []);
  const selectAllRows = useCallback(async () => {
    onSelectRow(true);
    return Promise.resolve(false);
  }, []);
  const selectVisibleRows = useCallback(async () => {
    onSelectRow(data);
    return Promise.resolve(false);
  }, [data]);
  const tableActionsAndCancelAction = useMemo(() => {
    const baseActions = [...tableActions];

    if (allRowsAreSelected) {
      baseActions.push({
        name: 'Select Visible Rows',
        handler: selectVisibleRows,
      });
    } else if (
      selectedRows.length === data.length &&
      selectedRows.length !== maxRowsCount &&
      selectionMode !==
        'checkbox-without-the-ability-to-select-rows-that-are-not-visible'
    ) {
      baseActions.push({
        name: 'Select All Rows',
        handler: selectAllRows,
      });
    }
    return [
      ...baseActions,
      {
        name: 'Deselect',
        handler: deselectAllRows,
      },
    ];
  }, [
    tableActions,
    selectedRows,
    maxRowsCount,
    allRowsAreSelected,
    selectVisibleRows,
    data,
  ]);
  if (!selectedRows.length) return null;

  return (
    <div className={`table__actions ${className}`}>
      <div className="table__info">
        {selectedRowsCount}/{maxRowsCount} Selected
      </div>
      <div className="table__buttons">
        {tableActionsAndCancelAction.map((action, index) => {
          if ('handler' in action) {
            return (
              <ButtonTableAction
                name={action.name}
                handler={action.handler}
                key={`${action.name}-${index}`}
              />
            );
          }

          return (
            <FloatingMenuTableAction
              name={action.name}
              options={action.options}
              key={`${action.name}-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

const ButtonTableAction = ({ name, handler }) => {
  const {
    allRowsAreSelected,
    pagination,
    excludedRows,
    selectedRows,
    onSelectRow,
  } = useTableContext();

  const selectedRowsCount = useMemo(() => {
    if (allRowsAreSelected && pagination) {
      return pagination.rowCount - excludedRows.length;
    }
    return selectedRows.length;
  }, [pagination, selectedRows, allRowsAreSelected, excludedRows]);
  const deselectAllRows = useCallback(() => {
    onSelectRow(false);
  }, []);

  return (
    <div
      className="table__action"
      onClick={async () => {
        const clearSelection = await handler(
          selectedRows,
          allRowsAreSelected,
          excludedRows,
          selectedRowsCount,
        );
        if (clearSelection) {
          deselectAllRows();
        }
      }}
    >
      {name}
    </div>
  );
};

const FloatingMenuTableAction = ({ name, options }) => {
  const {
    allRowsAreSelected,
    pagination,
    excludedRows,
    selectedRows,
    onSelectRow,
  } = useTableContext();
  const selectedRowsCount = useMemo(() => {
    if (allRowsAreSelected && pagination) {
      return pagination.rowCount - excludedRows.length;
    }
    return selectedRows.length;
  }, [pagination, selectedRows, allRowsAreSelected, excludedRows]);
  const deselectAllRows = useCallback(() => {
    onSelectRow(false);
  }, []);
  return (
    <FloatingMenu
      className="table__action--floating"
      items={options.map((o) => ({
        name: o.name,
        handler: async () => {
          const clearSelection = await o.handler(
            selectedRows,
            allRowsAreSelected,
            excludedRows,
            selectedRowsCount,
          );
          if (clearSelection) {
            deselectAllRows();
          }
        },
      }))}
    >
      <div className="table__action">{name}</div>
    </FloatingMenu>
  );
};

export const TableErrorPlaceholder = () => {
  return (
    <ErrorPlaceholder>
      <ErrorPlaceholder.Image />
      <ErrorPlaceholder.Title />
      <ErrorPlaceholder.Description>
        Something went wrong during the fetching process. Try to refresh the
        table data or if the problem persists please get in contact with us
        using the app help channel
      </ErrorPlaceholder.Description>
    </ErrorPlaceholder>
  );
};

export const TableNoDataPlaceholder = () => (
  <NoDataPlaceholder
    title="There's no data available"
    subtitle="You can populate the table by creating new records"
  />
);

Table.Provider = TableProvider;
Table.Body = TableBody;
Table.PaginationRow = TablePaginationRow;
Table.Container = TableContainer;
Table.Rows = TableRows;
Table.Header = TableHeader;
