import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ErrorPlaceholder,
  Icon,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  Pagination,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useListViewContext, useListViewTableData } from '@nl-lms/ui/modules';

import { LearnerLearningCard } from '../../../_common/components';
import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import {
  LearnerAppLayoutCardGrid,
  LearnerAppLayoutCardGridItem,
  LearnerAppLayoutCardGridNoData,
} from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import { LearnerLearningOpportunityCard } from '../../_common/components/LearnerLearningOpportunityCard/LearnerLearningOpportunityCard';
import './LearnerLearningPathListCards.scss';

const { useListLearningPathsQuery } = learnerApi;

const LearnerLearningPathListCards = () => {
  const { query, filters } = useListViewContext();
  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
      ],
    }),
    [query]
  );
  const {
    isLoading,
    isSuccess,
    data = { count: 0, rows: [] },
    isError,
    isFetching,
    // @ts-ignore
  } = useListLearningPathsQuery(fetchArg);
  const [learningPaths] = useListViewTableData(data);

  const { t } = useTranslation('learner');

  if (isLoading || isFetching) {
    return (
      <>
        <LearnerAppLayoutCardGrid>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="learning-paths-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="learning-paths-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="learning-paths-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
        </LearnerAppLayoutCardGrid>
      </>
    );
  }

  if (isSuccess && !learningPaths.length) {
    return (
      <>
        <LearnerAppLayoutCardGridNoData>
          <NoDataPlaceholder
            iconName="GitIconRegular"
            title={
              filters
                ? t('opportunities.learningpathfilterplaceholder.title')
                : t('opportunities.learningpathplaceholder.title')
            }
            subtitle={
              filters
                ? t('opportunities.learningpathfilterplaceholder.subtitle')
                : t('opportunities.learningpathplaceholder.subtitle')
            }
            color={
              filters
                ? NoDataPlaceholderColor.warning
                : NoDataPlaceholderColor.default
            }
          />
        </LearnerAppLayoutCardGridNoData>
      </>
    );
  }

  if (isError) {
    return (
      <LearnerAppLayoutCardGridNoData>
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          {/*// @ts-ignore */}
          <ErrorPlaceholder.Title>
            {/*// @ts-ignore */}
            {t('dashboard.error.title')}
          </ErrorPlaceholder.Title>
          <ErrorPlaceholder.Description>
            {t('dashboard.error.description')}
          </ErrorPlaceholder.Description>
        </ErrorPlaceholder>
      </LearnerAppLayoutCardGridNoData>
    );
  }

  return (
    <>
      <LearnerAppLayoutCardGrid>
        {learningPaths.map((lp) => (
          <LearnerAppLayoutCardGridItem key={lp.id}>
            <LearnerLearningPathCard id={lp.id} />
          </LearnerAppLayoutCardGridItem>
        ))}
      </LearnerAppLayoutCardGrid>
    </>
  );
};

const LearnerLearningPathCard = ({ id }) => {
  const { query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
      ],
    }),
    [query]
  );
  // @ts-ignore
  const { learningPath } = useListLearningPathsQuery(fetchArg, {
    selectFromResult: ({ data }) => ({
      learningPath: data?.rows?.find((a) => a.id === id),
    }),
  });

  const goToLearningPathPage = useRoutingAction({
    route: routes.portal.learningPath.item.path.full,
  });

  const { t } = useTranslation('learner');

  if (!learningPath || !learningPath.id) return null;

  const { name, description, thumbnail, duration, competencies, bestResult } =
    learningPath;
  return (
    <LearnerLearningOpportunityCard
      name={name}
      description={description}
      type={C.I_LEARNER_LEARNING_TYPES.LEARNING_PATH}
      duration={duration}
      thumbnail={thumbnail}
      status={bestResult?.status}
      actions={[
        <Button
          regular
          onClick={() => goToLearningPathPage(id)}
          label={t('common.learningactivitycard.viewmore')}
        />,
      ]}
      competencies={competencies}
    />
  );
};

const LearningPathCardsListPagination = ({ className = '' }) => {
  const { pagination, onChangePagination, query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
      ],
    }),
    [query]
  );

  // @ts-ignore
  const { data } = useListLearningPathsQuery(fetchArg);
  const rowCount = data?.count || 0;
  return (
    <Pagination
      className={`learning-paths-cards-list__pagination ${className}`}
      pagination={{ ...pagination, rowCount }}
      onChangePage={onChangePagination}
    />
  );
};

LearnerLearningPathListCards.Pagination = LearningPathCardsListPagination;

export default LearnerLearningPathListCards;
