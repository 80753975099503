const illegalRe = /[\/\?<>\\:\*\|"]/g;
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
const reservedRe = /^\.+$/;
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
const windowsTrailingRe = /[\. ]+$/;
const spacesRe = /\s+/g;

const sanitizeString = (input: string, replacement: string) => {
  return input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)
    .replace(spacesRe, '-');
};

export const sanitizeFileName = (
  input: string,
  options: { replacement: string } = { replacement: '' }
) => {
  const { replacement } = options;
  const output = sanitizeString(input, replacement);
  if (replacement === '') return output;
  return sanitizeString(output, '');
};
