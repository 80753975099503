import {
  AppQueryFilter,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';

export const learnerLibraryByCompetenciesFilter = (competencyIds: string[]) => {
  let competencyFilter: AppQueryFilter = {
    id: 'learnerLibraryCompetencyFilter',
    value: [],
  };

  competencyIds.map((comp) => {
    competencyFilter = {
      ...competencyFilter,
      value: [
        ...(competencyFilter.value as AppQueryFilter[]),
        {
          id: comp,
          value: {
            field: { field: 'competency_ids', label: 'competencyIds' },
            operator: {
              type: QueryFilterOperatorType.Binary,
              value: QueryOperator.Contains,
            },
            value: {
              value: [comp],
              label: 'competencyId',
            },
          },
          combinator: QueryFilterCombinator.Or,
        },
      ],
    };
  });

  return competencyFilter;
};
