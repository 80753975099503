import { isEqual, keyBy, reduce } from 'lodash';

export * from './custom';

export {
  isArray,
  kebabCase,
  omit,
  replace,
  clone,
  debounce,
  chain,
  throttle,
  snakeCase,
  capitalize,
  isEmpty,
  startCase,
  lowerCase,
  difference,
  differenceBy,
  map,
  isObject,
  transform,
  merge,
  mapKeys,
  reduce,
  values,
  once,
  uniqueId,
  keyBy,
  each,
  toLower,
  get,
  camelCase,
  range,
  groupBy,
  set,
  trim,
  pick,
  isEqual,
  uniqBy,
  uniq,
  chunk,
  sortBy,
  orderBy,
  cloneDeep,
  intersection,
  flow,
} from 'lodash';

export const indexBy = keyBy;
export const diff = (a, b) =>
  reduce(
    a,
    function (result, value, key) {
      // @ts-ignore
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
