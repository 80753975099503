import React from 'react';

import { Card, Typography } from '@nl-lms/ui/components';
import { prettyPrice } from '@nl-lms/ui/utils';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';

import { adminApi } from '../../../_common/services/api';

export const AdminAnalyticsCostPerLearnerCard = ({ filters }) => {
  const { useGetCostPerLearnerQuery } = adminApi;
  const { isLoading, data, error } = useGetCostPerLearnerQuery(filters);
  useQueryErrorHandler({ error });

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Card.HeaderTitleSkeleton />
        </Card.Header>
        <Card.Content>
          <Card.Body>
            <Card.BodySkeleton />
          </Card.Body>
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Cost/Learner</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <Card.Body>
          {/*// @ts-ignore */}
          {data.avgParticipantPrice ? (
            <Typography.h1>
              {/*// @ts-ignore */}
              {prettyPrice(data.avgParticipantPrice)}
            </Typography.h1>
          ) : (
            <Typography.h2>No data available</Typography.h2>
          )}
        </Card.Body>
      </Card.Content>
    </Card>
  );
};
