import React from 'react';

import { WidgetPosition } from '@nl-lms/feature/dashboard/sdk';

import { AdminActivityLogGroupedCard } from '../AdminActivityLog/AdminActivityLogGroupedCard';
import { AdminAnalyticsCancelledSessionsCard } from '../AdminAnalytics/AdminAnalyticsCancelledSessionsCard';
import { AdminAnalyticsCostPerLearnerCard } from '../AdminAnalytics/AdminAnalyticsCostPerLearnerCard';
import { AdminAnalyticsFillRateCard } from '../AdminAnalytics/AdminAnalyticsFillRateCard';
import { AdminAnalyticsLearnedLearnersCard } from '../AdminAnalytics/AdminAnalyticsLearnedLearnersCard';
import { AdminAnalyticsTrainingProgressCard } from '../AdminAnalytics/AdminAnalyticsTrainingProgressCard';
import { AdminLiveSessionDayTimeline } from '../AdminLiveSession/AdminLiveSessionDayTimeline/AdminLiveSessionDayTimeline';
import { AdminEvaluatorAssessmentProgressListTable } from './AdminAssessmentProgress/AdminAssessmentProgressListTable';
import { AdminDashboardReportWidget } from './AdminDashboardReportWidget';
import { AdminLearningAssignmentCard } from './AdminLearningAssignmentCard/AdminLearningAssignmentCard';
import { AdminLearningApprovalListCard } from './AdminLiveSessionLearnerApprovalListCard/AdminLearningApprovalListCard';
import { AdminReportsCard } from './AdminReportsCard/AdminReportsCard';
import { AdminManagerTeamCard } from './AdminTeamCard/AdminTeamCard';

export interface WidgetConfig {
  label: string;
  description: string;
  Component: React.FunctionComponent;
  type: WidgetType;
  payload: Record<string, unknown>;
  position: Omit<WidgetPosition, 'x' | 'y'>;
}

type WidgetType =
  | 'liveSessionTimeline'
  | 'learningProgress'
  | 'learnerLearners'
  | 'fillRate'
  | 'cancelledSessions'
  | 'costPerLearner'
  | 'activityLog'
  | 'learningApprovals'
  | 'addReports'
  | 'myTeam'
  | 'assessmentProgress'
  | 'learningAssignments'
  | 'report';
export const WidgetsMap: Record<WidgetType, WidgetConfig> = {
  liveSessionTimeline: {
    label: 'Timeline',
    description: '',
    type: 'liveSessionTimeline',
    Component: AdminLiveSessionDayTimeline,
    payload: {},
    position: { w: 12, h: 10 },
  },
  learningProgress: {
    label: 'Learning Progress',
    type: 'learningProgress',
    description: '',
    Component: AdminAnalyticsTrainingProgressCard,
    payload: {},
    position: { w: 4, h: 6 },
  },
  learnerLearners: {
    label: 'Learned Learners',
    type: 'learnerLearners',
    description: '',
    Component: AdminAnalyticsLearnedLearnersCard,
    payload: {},
    position: { w: 4, h: 3 },
  },
  fillRate: {
    label: 'Fill Rate',
    type: 'fillRate',
    description: '',
    Component: AdminAnalyticsFillRateCard,
    payload: {},
    position: { w: 4, h: 3 },
  },
  cancelledSessions: {
    label: 'Cancelled Sessions',
    type: 'cancelledSessions',
    description: '',
    Component: AdminAnalyticsCancelledSessionsCard,
    payload: {},
    position: { w: 4, h: 3 },
  },
  costPerLearner: {
    label: 'Cost Per Learner',
    type: 'costPerLearner',
    description: '',
    Component: AdminAnalyticsCostPerLearnerCard,
    payload: { name: 'costPerLearner' },
    position: { w: 4, h: 3 },
  },
  activityLog: {
    label: 'Activity Log',
    type: 'activityLog',
    description: '',
    Component: AdminActivityLogGroupedCard,
    payload: {},
    position: { w: 6, h: 8 },
  },
  learningApprovals: {
    label: 'Learning Approvals',
    type: 'learningApprovals',
    description: '',
    Component: AdminLearningApprovalListCard,
    payload: {},
    position: { w: 12, h: 6 },
  },
  addReports: {
    label: 'Add Reports Card',
    type: 'addReports',
    description: '',
    Component: AdminReportsCard,
    payload: {},
    position: { w: 6, h: 4 },
  },
  myTeam: {
    label: 'My Team',
    type: 'myTeam',
    description: '',
    Component: AdminManagerTeamCard,
    payload: {},
    position: { w: 12, h: 4 },
  },
  assessmentProgress: {
    label: 'Assessment Progress',
    type: 'assessmentProgress',
    description: '',
    Component: AdminEvaluatorAssessmentProgressListTable,
    payload: {},
    position: { w: 12, h: 6 },
  },
  learningAssignments: {
    label: 'Learning Assignment',
    type: 'learningAssignments',
    description: '',
    Component: AdminLearningAssignmentCard,
    payload: {},
    position: { w: 6, h: 8 },
  },
  report: {
    label: 'Reports',
    type: 'report',
    description: '',
    Component: AdminDashboardReportWidget,
    payload: {},
    position: { w: 6, h: 4 },
  },
};
