import { InternalAxiosRequestConfig } from 'axios';

import { ApiClientOptions } from '../types';

export const setSpanIdInterceptor =
  (options: ApiClientOptions) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const originalRequest = config;
    if (
      typeof window === 'undefined' ||
      !sessionStorage ||
      !sessionStorage.getItem ||
      !sessionStorage.getItem('spanId')
    ) {
      return originalRequest;
    }

    const spanId = sessionStorage.getItem('spanId');
    if (!spanId) return originalRequest;

    // @ts-ignore
    originalRequest.headers['x-span-id'] = spanId;

    return originalRequest;
  };
