import { Slot } from '@radix-ui/react-slot';
import React from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { TidComponent } from '../index.types';
import './Box.scss';
import { FlexProp, MarginProp, PaddingProp } from './Box.types';

type BoxProps = TidComponent<{
  margin?: MarginProp;
  padding?: PaddingProp;
  asChild?: boolean;
  flex?: FlexProp;
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}>;

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      margin = undefined,
      asChild = false,
      children,
      flex = undefined,
      padding = undefined,
      ...props
    }: BoxProps,
    ref
  ) => {
    const classNameProps = useClassNameProps(
      'box',
      props,
      getPaddingClassNames(padding),
      getMarginClassNames(margin),
      getFlexClassName(flex)
    );
    const commonProps = useTestProps(props);

    const Component = asChild ? Slot : 'div';

    return (
      <Component {...classNameProps} {...commonProps} ref={ref}>
        {children}
      </Component>
    );
  }
);

Box.displayName = 'Box';

const getPaddingClassNames = (padding?: PaddingProp) => {
  let className = '';
  if (!padding) return className;
  for (const direction in padding) {
    // @ts-ignore
    className = `${className} padding-${direction}-${padding[direction]}`;
  }
  return className;
};

const getMarginClassNames = (margin?: MarginProp) => {
  let className = '';
  if (!margin) return className;
  for (const direction in margin) {
    // @ts-ignore
    className = `${className} margin-${direction}-${margin[direction]}`;
  }
  return className;
};

const getFlexClassName = (flex?: FlexProp) => {
  if (!flex) return '';
  let className = 'flex';
  for (const direction in flex) {
    // @ts-ignore
    className = `${className} ${_.kebabCase(direction)}-${flex[direction]}`;
  }
  return className;
};
