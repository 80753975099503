import {
  OperatorToLabelRecord,
  QueryFilterOperator,
  QueryFilterOperatorType,
  QueryOperator,
} from './types';

export const FilterOperator: Record<QueryOperator, QueryFilterOperator> = {
  Equals: {
    label: OperatorToLabelRecord[QueryOperator.Equals],
    value: QueryOperator.Equals,
    type: QueryFilterOperatorType.Binary,
  },
  NEqual: {
    label: OperatorToLabelRecord[QueryOperator.NEqual],
    value: QueryOperator.NEqual,
    type: QueryFilterOperatorType.Binary,
  },
  IEquals: {
    label: OperatorToLabelRecord[QueryOperator.IEquals],
    value: QueryOperator.IEquals,
    type: QueryFilterOperatorType.Binary,
  },
  INEqual: {
    label: OperatorToLabelRecord[QueryOperator.INEqual],
    value: QueryOperator.INEqual,
    type: QueryFilterOperatorType.Binary,
  },
  GreaterThan: {
    label: OperatorToLabelRecord[QueryOperator.GreaterThan],
    value: QueryOperator.GreaterThan,
    type: QueryFilterOperatorType.Binary,
  },
  LowerThan: {
    label: OperatorToLabelRecord[QueryOperator.LowerThan],
    value: QueryOperator.LowerThan,
    type: QueryFilterOperatorType.Binary,
  },
  GreaterThanEqual: {
    label: OperatorToLabelRecord[QueryOperator.GreaterThanEqual],
    value: QueryOperator.GreaterThanEqual,
    type: QueryFilterOperatorType.Binary,
  },
  LowerThanEqual: {
    label: OperatorToLabelRecord[QueryOperator.LowerThanEqual],
    value: QueryOperator.LowerThanEqual,
    type: QueryFilterOperatorType.Binary,
  },
  Contains: {
    label: OperatorToLabelRecord[QueryOperator.Contains],
    value: QueryOperator.Contains,
    type: QueryFilterOperatorType.Binary,
  },
  // Used in cases where you don't want more precision (you want doe@gmail.com but not john.doe@gmail.com)
  Like: {
    label: OperatorToLabelRecord[QueryOperator.Like],
    value: QueryOperator.Like,
    type: QueryFilterOperatorType.Binary,
  },
  DoesNotContain: {
    label: OperatorToLabelRecord[QueryOperator.DoesNotContain],
    value: QueryOperator.DoesNotContain,
    type: QueryFilterOperatorType.Binary,
  },
  IsNotNull: {
    label: OperatorToLabelRecord[QueryOperator.IsNotNull],
    value: QueryOperator.IsNotNull,
    type: QueryFilterOperatorType.Unary,
  },
  IsNull: {
    label: OperatorToLabelRecord[QueryOperator.IsNull],
    value: QueryOperator.IsNull,
    type: QueryFilterOperatorType.Unary,
  },
  IsTrue: {
    label: OperatorToLabelRecord[QueryOperator.IsTrue],
    value: QueryOperator.IsTrue,
    type: QueryFilterOperatorType.Unary,
  },
  IsFalse: {
    label: OperatorToLabelRecord[QueryOperator.IsFalse],
    value: QueryOperator.IsFalse,
    type: QueryFilterOperatorType.Unary,
  },
  Includes: {
    label: OperatorToLabelRecord[QueryOperator.Includes],
    value: QueryOperator.Includes,
    type: QueryFilterOperatorType.Binary,
  },
  Excludes: {
    label: OperatorToLabelRecord[QueryOperator.Excludes],
    value: QueryOperator.Excludes,
    type: QueryFilterOperatorType.Binary,
  },
  Overlaps: {
    label: OperatorToLabelRecord[QueryOperator.Overlaps],
    value: QueryOperator.Overlaps,
    type: QueryFilterOperatorType.Binary,
  },
  DoesNotOverlap: {
    label: OperatorToLabelRecord[QueryOperator.DoesNotOverlap],
    value: QueryOperator.DoesNotOverlap,
    type: QueryFilterOperatorType.Binary,
  },
  JsonArrayIncludes: {
    label: OperatorToLabelRecord[QueryOperator.JsonArrayIncludes],
    value: QueryOperator.JsonArrayIncludes,
    type: QueryFilterOperatorType.Binary,
  },
  JsonArrayDoesNotInclude: {
    label: OperatorToLabelRecord[QueryOperator.JsonArrayDoesNotInclude],
    value: QueryOperator.JsonArrayDoesNotInclude,
    type: QueryFilterOperatorType.Binary,
  },
};
