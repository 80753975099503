import React from 'react';

export const MonitorIconSmall = () => (
  <svg 
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    xmlns="http://www.w3.org/2000/svg"
    fill="none" 
    stroke="currentColor" 
    strokeWidth="1" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className="feather feather-monitor"
  >
    <path 
      d="M10.5 8H1.5V2H10.5V8ZM10.5 1H1.5C0.945 1 0.5 1.445 0.5 2V8C0.5 8.55 0.95 9 1.5 9H5V10H4V11H8V10H7V9H10.5C11.05 9 11.5 8.55 11.5 8V2C11.5 1.445 11.05 1 10.5 1Z" 
      fill="black"
    />
  </svg>
);
