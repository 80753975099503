import _ from 'lodash';
import React from 'react';

import { LearningAssignmentRule } from '@nl-lms/common/feature/types';
import { Box, Button, Icon, Typography } from '@nl-lms/ui/components';

import './LearningAssignmentRulesComponent.scss';
import { RuleComponent } from './RuleComponent';
import { AssignationRule } from './utils/types';

export const LearningAssignmentRulesList = ({
  rules,
  onDeleteRule,
}: {
  rules: AssignationRule[] | LearningAssignmentRule[];
  onDeleteRule?: (
    ruleIndicator: string | number,
    assignmentId?: string
  ) => void;
}) => (
  <Box flex={{ flexDirection: 'column', gap: 'xs' }} padding={{ top: 's' }}>
    {rules?.map((rule, ruleIndex) => (
      <Box flex={{ flexDirection: 'column' }}>
        <Typography.h4 style={{ color: '#7a7f82' }}>{rule?.name}</Typography.h4>
        <Box
          flex={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            gap: 'm',
          }}
        >
          <RuleComponent rule={rule} />
          {onDeleteRule ? (
            <Button
              label=""
              icon={<Icon.CloseIcon />}
              ghost
              onClick={() =>
                // @ts-ignore
                rule?.id
                  ? onDeleteRule(rule?.id, rule?.learningAssignmentId)
                  : onDeleteRule(ruleIndex)
              }
            />
          ) : null}
        </Box>
      </Box>
    ))}
  </Box>
);
