import React, { useCallback, useMemo, useRef, useState } from 'react';

import { FieldType } from '@nl-lms/common/shared';
import { ReportDataColumn } from '@nl-lms/feature/reports/sdk';
import {
  Box,
  Button,
  Draggable,
  FormField,
  Icon,
  IconButton,
  Input,
  Modal,
  Separator,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { AdminAssessmentQuestionSingleSelect } from '../../AdminAssessment/AdminAssessmentQuestionSelect';
import { AdminCompetencySelect } from '../../AdminCompetency/AdminCompetencySelect';
import { AdminLiveSessionCostTypeSelect } from '../../AdminLiveSession/AdminLiveSessionCost/AdminLiveSessionCostAddForm/AdminLiveSessionCostTypeSelect';
import { AdminTagSelect } from '../../AdminTag/AdminTagSelect';

export const AdminAnalyticsReportColumnsInput = ({
  value,
  onChange,
}: {
  value: ReportDataColumn[];
  onChange: (columns: ReportDataColumn[]) => void;
}) => {
  const columns = value;
  const onChangeColumnLabel = useCallback(
    (e) => {
      const columnIndex = columns.findIndex((c) => c.name === e.target.name);
      if (columnIndex === -1) return;
      const newColumns = [...columns];
      newColumns[columnIndex].label = e.target.value;
      onChange(newColumns);
    },
    [columns, onChange]
  );
  const onToggleColumnVisibility = useCallback(
    (index) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        isVisible: !newColumns[index].isVisible,
      };

      onChange(newColumns);
    },
    [columns, onChange]
  );
  const onMoveColumn = useCallback(
    (from: number, to: number) => {
      const copiedColumns = [...columns];
      const [movedColumn] = copiedColumns.splice(from, 1);
      copiedColumns.splice(to, 0, movedColumn);
      onChange(copiedColumns);
    },
    [columns, onChange]
  );
  if (!columns || !columns.length) {
    return (
      <Typography.p>
        You cannot customize columns for this type of report
      </Typography.p>
    );
  }
  const availableColumnsForEdit = columns.filter(
    (col) => !(col.options && 'alwaysHide' in col.options)
  );
  return (
    <Box flex={{ flexDirection: 'column' }}>
      {columns.map((col, index) => (
        <ColumnInput
          key={`column-input-${col.name}`}
          column={col}
          index={index}
          onMoveColumn={onMoveColumn}
          onChangeColumnLabel={onChangeColumnLabel}
          onToggleColumnVisibility={onToggleColumnVisibility}
        />
      ))}
      {!availableColumnsForEdit.length ? null : (
        <AdminAnalyticsAddNewColumnModal
          onSubmit={onChange}
          existentColumns={columns}
        >
          <Button label="Add Column" regular icon={<Icon.AddIcon />} />
        </AdminAnalyticsAddNewColumnModal>
      )}
    </Box>
  );
};

const ColumnInput = ({
  column,
  index,
  onChangeColumnLabel,
  onMoveColumn,
  onToggleColumnVisibility,
}: {
  column: ReportDataColumn;
  index: number;
  onChangeColumnLabel: (e: any) => void;
  onMoveColumn: (from: number, to: number) => void;
  onToggleColumnVisibility: (index: number) => void;
}) => {
  if (column.options && 'alwaysHide' in column.options) return null;
  return (
    <Draggable type="report-column" index={index} onDrop={onMoveColumn}>
      <Box
        flex={{
          flexDirection: 'row',
          gap: 's',
          alignItems: 'center',
        }}
        padding={{ top: 's', bottom: 's' }}
      >
        <Input
          name={column.name}
          defaultValue={column.label}
          disabled={!column.isVisible}
          onChange={onChangeColumnLabel}
        />
        <Draggable.HoverActions>
          <Draggable.DragButton />
          <IconButton
            label={column.isVisible ? 'Hide' : 'Show'}
            onClick={() => onToggleColumnVisibility(index)}
          >
            <Icon.EyeIcon />
          </IconButton>
        </Draggable.HoverActions>
      </Box>
    </Draggable>
  );
};

type ReportDataNewColumnType =
  | 'learner'
  | 'tag'
  | 'competency'
  | 'survey-answer'
  | 'cost-type';
const AdminAnalyticsAddNewColumnModal = ({
  existentColumns,
  onSubmit,
  children,
}: {
  existentColumns: ReportDataColumn[];
  children: React.ReactNode;
  onSubmit: (columns: ReportDataColumn[]) => void;
}) => {
  const availableColumnTypes = useMemo(() => {
    const columnNames = existentColumns.map((col) => col.name);
    const availableColumnTypes: ReportDataNewColumnType[] = [];
    if (columnNames.includes('tags')) {
      availableColumnTypes.push('tag');
    }
    if (columnNames.includes('competencies')) {
      availableColumnTypes.push('competency');
    }
    if (
      (columnNames.includes('learner_id') ||
        columnNames.includes('customer_internal_id')) &&
      C.LEARNER_PROPERTIES.some(
        (field) => !columnNames.includes(`learner_${_.snakeCase(field.name)}`)
      )
    ) {
      availableColumnTypes.push('learner');
    }
    if (columnNames.includes('survey_answers')) {
      availableColumnTypes.push('survey-answer');
    }
    if (columnNames.includes('cost_types')) {
      availableColumnTypes.push('cost-type');
    }
    return availableColumnTypes;
  }, [existentColumns]);

  return (
    <Modal.Provider>
      {availableColumnTypes.length ? (
        <Modal.Trigger>{children}</Modal.Trigger>
      ) : null}
      <SideModal.Content>
        <AdminAnalyticsAddNewColumnModalContent
          availableColumnTypes={availableColumnTypes}
          onSubmit={onSubmit}
          existentColumns={existentColumns}
        />
      </SideModal.Content>
    </Modal.Provider>
  );
};

const AdminAnalyticsAddNewColumnModalContent = ({
  existentColumns,
  onSubmit: _onSubmit,
  availableColumnTypes,
}: {
  existentColumns: ReportDataColumn[];
  availableColumnTypes: ReportDataNewColumnType[];
  onSubmit: (columns: ReportDataColumn[]) => void;
}) => {
  const { hide } = useModal();
  // @ts-ignore
  const [columnType, setColumnType] = useState<ReportDataNewColumnType>(null);
  const [columns, setColumns] = useState<ReportDataColumn[]>([]);
  const onSubmit = useCallback(() => {
    _onSubmit([...existentColumns, ...columns]);
    hide();
  }, [columns, existentColumns]);
  const onSelectColumnValue = useCallback(
    (value) => {
      const column: ReportDataColumn = {
        label: Array.isArray(value) ? value[0].label : value.label,
        name: Array.isArray(value) ? value[0].value : value.value,
        isFilterable: columnType === 'learner',
        isVisible: true,
        type: FieldType.string,
        options:
          columnType !== 'learner'
            ? {
                parseAs: columnType,
              }
            : undefined,
      };
      if (columns.find((c) => c.name === column.name)) return;
      setColumns([...columns, column]);
    },
    [columnType, columns]
  );
  const onChangeColumnLabel = useCallback(
    (e) => {
      const columnIndex = columns.findIndex((c) => c.name === e.target.name);
      if (columnIndex === -1) return;
      const newColumns = [...columns];
      newColumns[columnIndex].label = e.target.value;
      setColumns(newColumns);
    },
    [columns]
  );
  const onToggleColumnVisibility = useCallback(
    (index) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        isVisible: !newColumns[index].isVisible,
      };

      setColumns(newColumns);
    },
    [columns]
  );
  const onMoveColumn = useCallback(
    (from: number, to: number) => {
      const copiedColumns = [...columns];
      const [movedColumn] = copiedColumns.splice(from, 1);
      copiedColumns.splice(to, 0, movedColumn);
      setColumns(copiedColumns);
    },
    [columns]
  );
  const ColumnValueSelect = useMemo(() => {
    if (columnType === 'tag') {
      return (props) => <AdminTagSelect {...props} returnEntireItemOnChange />;
    } else if (columnType === 'competency') {
      return (props) => (
        <AdminCompetencySelect {...props} returnEntireItemOnChange />
      );
    } else if (columnType === 'cost-type') {
      return (props) => (
        <AdminLiveSessionCostTypeSelect {...props} returnEntireItemOnChange />
      );
    } else if (columnType === 'survey-answer') {
      return (props) => (
        <AdminAssessmentQuestionSingleSelect
          {...props}
          returnEntireItemOnChange
        />
      );
    } else if (columnType === 'learner') {
      const columnNames = existentColumns.map((col) => col.name);
      const options = C.LEARNER_PROPERTIES.filter(
        (field) => !columnNames.includes(`learner_${_.snakeCase(field.name)}`)
      ).map((field) => ({
        value: `learner_${_.snakeCase(field.name)}`,
        label: `Learner ${field.fieldName}`,
      }));
      return ({ onChange }) => (
        <SingleSelect
          name="learner-fields"
          onChange={onChange}
          returnEntireItemOnChange
          options={options}
        />
      );
    }
    return ({ onChange }) => (
      <SingleSelect
        name="learner-fields"
        onChange={onChange}
        options={[]}
        disabled
      />
    );
  }, [columnType, existentColumns]);
  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Add New Columns</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box flex={{ flexDirection: 'column', gap: 's' }}>
          <FormField label="Entity">
            <SingleSelect
              name="entity"
              onChange={setColumnType}
              options={availableColumnTypes.map((type) => ({
                label: formatConstantString(type),
                value: type,
              }))}
            />
          </FormField>
          <FormField label="Value">
            <ColumnValueSelect
              key={`col-value-select-${columns.length}`}
              onChange={onSelectColumnValue}
            />
          </FormField>
        </Box>
        {/*// @ts-ignore */}
        <Separator marginTop={0} />
        <Box flex={{ flexDirection: 'column', gap: 'm' }}>
          {columns.map((col, index) => (
            <ColumnInput
              key={`column-input-${col.name}`}
              column={col}
              index={index}
              onChangeColumnLabel={onChangeColumnLabel}
              onMoveColumn={onMoveColumn}
              onToggleColumnVisibility={onToggleColumnVisibility}
            />
          ))}
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <Button disabled={!columns.length} label="Submit" onClick={onSubmit} />
      </SideModal.Actions>
    </>
  );
};
