import React from 'react';

import { _ } from '@nl-lms/vendor';
import { C } from '@nl-lms/ui/constants';
import { SingleSelect } from '@nl-lms/ui/components';
import { formatConstantString } from '@nl-lms/ui/utils';

const AdminLiveCourseLearningTypeSelect = ({
  onChange,
  name = 'learning-type-select',
  initialValue = null,
  isClearable = false,
  value = null,
  hasError = false,
}) => (
  <SingleSelect
    onChange={onChange}
    options={_.map(C.LEARNING_TYPES, (label, value) => ({
      value: parseInt(value),
      label: formatConstantString(label),
    }))}
    placeholder="Select Type"
    isClearable={isClearable}
    name={name}
// @ts-ignore
    selectedItem={value}
// @ts-ignore
    initialSelectedItem={initialValue}
    hasError={hasError}
  />
);

export default AdminLiveCourseLearningTypeSelect;
