export const IndividualLearningScope = {
  portal: {
    viewIndividualLearning: {
      resource: 'portal_individual_learning',
      action: 'view',
    },
    viewIndividualLearningInstance: {
      resource: 'portal_individual_learning_instance',
      action: 'view',
    },
  },
} as const;
