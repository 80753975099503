import { z } from 'zod';

export enum LearningPathInstanceStatus {
  'IN_PROGRESS' = 1,
  'COMPLETED',
  'FAILED',
  'NOT_STARTED',
}
export enum LearningPathItemInstanceStatus {
  'IN_PROGRESS' = 1,
  'COMPLETED',
  'FAILED',
  'CANCELED',
  'NOT_STARTED',
}

export enum LearningPathItemValidationType {
  'LATEST_SCORE' = 1,
  'HIGHEST_SCORE',
}
export const LearningPathSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string(),
  duration: z.number().nullable(),
  targetLearnerGroupIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningPath = z.infer<typeof LearningPathSchema>;

export const LearningPathInstanceSchema = z.object({
  id: z.string(),
  learningPathId: z.string().nullable(),
  learnerId: z.string().nullable(),
  learningAssignmentInstanceId: z.string().nullable(),
  status: z.nativeEnum(LearningPathInstanceStatus),
  startedAt: z.date().nullable(),
  endedAt: z.date().nullable(),
  learnerHistoryId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningPathInstance = z.infer<typeof LearningPathInstanceSchema>;

export const LearningPathItemSchema = z.object({
  id: z.string(),
  learningPathId: z.string().nullable(),
  order: z.number(),
  mandatory: z.boolean(),
  sequential: z.boolean(),
  courseId: z.string().nullable(),
  elearningCourseId: z.string().nullable(),
  assessmentFormId: z.string().nullable(),
  validationType: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningPathItem = z.infer<typeof LearningPathItemSchema>;

export const LearningPathItemInstanceSchema = z.object({
  id: z.string(),
  learningPathId: z.string(),
  learningPathInstanceId: z.string(),
  learningPathItemId: z.string(),
  learnerId: z.string(),
  trainingSessionId: z.string().nullable(),
  elearningSessionId: z.string().nullable(),
  assessmentFormInstanceId: z.string().nullable(),
  status: z.number(),
  startedAt: z.date().nullable(),
  endedAt: z.date().nullable(),
  learnerHistoryId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LearningPathItemInstance = z.infer<
  typeof LearningPathItemInstanceSchema
>;
