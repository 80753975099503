import React, { useContext } from 'react';
import { Icon, Tooltip } from '@nl-lms/ui/components';
import { FilterBarContext } from '../FilterBarContext/FilterBarContext';

export const FilterBarInputResetButton = () => {
  const { onResetFilters } = useContext(FilterBarContext);
  const { filters, activeFilter } = useContext(FilterBarContext);

  if (!(filters.length > 2 || !!activeFilter?.field)) return null;
  return (
    <Tooltip
      title="Reset Filters"
      className="filter-bar__input-icon"
      onClick={onResetFilters}
      data-testid="filter-bar-reset-button"
    >
      <Icon.FilterClearIcon />
    </Tooltip>
  );
};
