import React from 'react';

import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

import { TidComponent } from '../index.types';
import './Typography.scss';

const variantToComponentTypeMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  p: 'p',
};

export type TypographyProps = TidComponent<{
  children: React.ReactNode;
  variant: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
  type?:
    | 'default'
    | 'muted'
    | 'highlight'
    | 'alert'
    | 'warning'
    | 'success'
    | 'info'
    | 'brand';
  style?: React.CSSProperties;
  component?: React.ElementType;
  editable?: boolean;
  loading?: boolean;
}>;

export const Typography = ({
  children,
  variant,
  // @ts-ignore
  component = null,
  type = 'default',
  editable = false,
  loading = false,
  style = {},
  ...props
}: TypographyProps) => {
  if (!variantToComponentTypeMapping[variant]) return null;
  const commonProps = useTestProps(props);
  const mappedVariant = variantToComponentTypeMapping[variant];
  const Component = component || mappedVariant;

  const classNameProps = useClassNameProps(
    `typography-${variant}`,
    props,
    `typography-${variant}--${type}`,
    loading && 'typography--loading'
  );

  return (
    <Component
      style={style}
      {...classNameProps}
      {...commonProps}
      contentEditable={editable}
    >
      {children}
    </Component>
  );
};

Typography.p = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="p" />
);
Typography.h1 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="h1" />
);
Typography.h2 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="h2" />
);
Typography.h3 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="h3" />
);
Typography.h4 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="h4" />
);
Typography.h5 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant="h5" />
);
