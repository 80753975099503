import { EndpointDefinitions, createApi } from '@reduxjs/toolkit/query/react';

import { ApiClientOptions, SdkEndpointType } from '../../types';
import { axiosTsRestBaseQuery, parseRtkQueryArgs } from './lib';

export function build<T extends EndpointDefinitions>(
  instance,
  router,
  options: ApiClientOptions & { reducerPath: string }
) {
  return createApi({
    reducerPath: options.reducerPath,
    baseQuery: axiosTsRestBaseQuery(),
    endpoints: (builder): T =>
      Object.keys(router).reduce((acc, key) => {
        return {
          ...acc,
          [key]: (() => {
            let method: typeof builder.query | typeof builder.mutation =
              builder.query;
            if (
              router[key].method === 'GET' ||
              router[key].description === SdkEndpointType.Query
            ) {
              method = builder.query;
            } else if (
              router[key].method !== 'GET' ||
              router[key].description === SdkEndpointType.Mutation
            ) {
              method = builder.mutation;
            }

            return method({
              query: (args) => async () => {
                const res = await instance[key](
                  parseRtkQueryArgs(args, router[key])
                );
                return res;
              },
            });
          })(),
        };
      }, {}) as unknown as T,
  });
}
