import { ReactNode, useContext } from 'react';

import { Box, Icon, ToggleInput, Typography } from '@nl-lms/ui/components';

import './LearningProgramRulesGraph.scss';
import { LearningProgramRulesGraphContext } from './LearningProgramRulesGraphContext';

const LearningProgramGraphLegendGroup = ({
  title,
  description,
  items = null,
}: {
  title: string;
  description: string;
  items?: ReactNode | null;
}) => (
  <Box
    margin={{ bottom: 's' }}
    flex={{ flexDirection: 'column', gap: 'xs' }}
    className="learning-program-graph__legend__group"
  >
    <Box
      flex={{
        flexDirection: 'row',
        gap: 'xs',
        alignItems: 'center',
      }}
    >
      <Typography.h4>
        {title} {items ? '-' : ''}{' '}
      </Typography.h4>
      {items}
    </Box>
    <Box>
      <Typography.p style={{ color: '#7a7f82' }}>{description}</Typography.p>
    </Box>
  </Box>
);

export const LearningProgramRulesGraphLegend = ({ layout }) => {
  const { showLegend, setShowLegend } = useContext(
    LearningProgramRulesGraphContext
  );
  return (
    <aside
      className={`learning-program-graph__legend ${
        !showLegend ? 'learning-program-graph__legend--hidden' : ''
      }`}
    >
      <button
        title="Close"
        className="learning-program-graph__legend__close"
        // @ts-ignore
        onClick={() => setShowLegend(false)}
      >
        <Icon.CloseIcon />
      </button>
      <LearningProgramGraphLegendGroup
        title="Elements Sidebar"
        description="You can drag unconnected elements from the left sidebar into the graph canvas."
      />
      <LearningProgramGraphLegendGroup
        title="Connection Points"
        description="You can drag connections from the blue points into the grey ones."
        items={
          <>
            <span
              className="learning-program-graph__legend__node-handle"
              style={{
                backgroundColor: '#2774a6',
              }}
            />
            <span
              className="learning-program-graph__legend__node-handle"
              style={{
                backgroundColor: '#cccccc',
              }}
            />
          </>
        }
      />
      <LearningProgramGraphLegendGroup
        title="Connections"
        description="Color-coded depending on rule type: Advanced Rule/Auto-assign/Completion/Started/Created/Failed"
        items={
          <>
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#7171DD',
              }}
            />
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#1578B2',
              }}
            />
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#20A775',
              }}
            />
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#B8DC57',
              }}
            ></span>
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#F5A236',
              }}
            />
            <span
              className="learning-program-graph__legend__edge"
              style={{
                backgroundColor: '#D7263D',
              }}
            />
          </>
        }
      />
      <LearningProgramGraphLegendGroup
        title="Rule & Assignment Graph Elements"
        description="Click to preview and right-click to edit, remove, or connect to endpoints. Operators like AND/OR are for advanced rules."
        items={<Box>Assignment X, Assignment Completed</Box>}
      />
      <LearningProgramGraphLegendGroup
        title="Completion Flow Elements"
        description="Connect them to assignments to build completion flows. One program can have more start/completion/failure points."
        items={<Box>START, PROGRAM COMPLETED / FAILED</Box>}
      />
      <LearningProgramGraphLegendGroup
        title="Layout Toggle"
        description="You can switch between top-bottom or right-left graph arrangement."
        items={<ToggleInput name="toggle" checked></ToggleInput>}
      />
    </aside>
  );
};
