import Axios from 'axios';
import React, { useCallback, useMemo, useReducer, useState } from 'react';

import { ContentFile } from '@nl-lms/feature/elearning/sdk';
import {
  Box,
  Button,
  FileInput,
  FormField,
  Input,
  Modal,
  ProgressBar,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useNotifications } from '@nl-lms/ui/modules';
import { _ } from '@nl-lms/vendor';

import { elearningApi, useApi } from '../../../_common/services/api';

export type AdminContentFileCreateModalProps = {
  currentFileNames?: string[];
  type?: number;
};

const axios = Axios.create();
delete axios.defaults.headers.put['Content-Type'];

const MAX_FILE_SIZE_IN_MB = 2560;

const { useCreateContentFileMutation } = elearningApi;

export type AdminContentFileCreateModalReturnType = {
  success: boolean;
  payload: ContentFile;
};

export const AdminContentFileCreateModal = (
  props: AdminContentFileCreateModalProps
) => {
  const { hide } = useModal<AdminContentFileCreateModalReturnType>();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [type, setType] = useState<number | null>(props.type || null);
  const [file, setFile] = useState<File>();
  const [path, setPath] = useState<string>();
  const api = useApi();
  const { uploadMethod: uploadFileWithSignedUrls, uploadPercentage } =
    useUploadFileWithSignedUrls(setInputValue);
  const { addAlertNotification } = useNotifications();
  const typeOptions = useMemo(() => {
    return _.map(C.CONTENT_FILE_TYPES, (label, value) => ({
      value: parseInt(value, 10),
      label,
    }));
  }, []);

  const onTypeChange = useCallback(
    (e) => {
      setType(e);
    },
    [setType]
  );

  const onUrlChange = useCallback(
    (e) => {
      setPath(e.target.value);
    },
    [setType]
  );

  const [createContentFile] = useCreateContentFileMutation();
  const onSaveFile = useCallback(async () => {
    if (type === C.I_CONTENT_FILE_TYPES.EMBED) {
      if (!path) {
        setHasError(true);
        return;
      }

      let createdFile;
      try {
        createdFile = await createContentFile({
          path,
          type,
        });
      } catch (e) {
        console.log(createdFile);
        setInputValue('Invalid operation');
        setHasError(true);
      }

      if (createdFile.error) {
        const message =
          createdFile?.error?.message || 'The content could not be saved';
        addAlertNotification(message);
        setInputValue(message);
        setHasError(true);
      } else {
        setInputValue(createdFile.name);
        setHasError(false);
        hide({ success: true, payload: createdFile });
      }

      return true;
    }

    if (!file) {
      setInputValue(`Invalid content file`);
      setHasError(true);
      return false;
    }
    const fileSizeInMb = file.size / (1024 * 1024);
    if (fileSizeInMb > MAX_FILE_SIZE_IN_MB) {
      setInputValue(
        `Invalid file size. Max zip size is ${MAX_FILE_SIZE_IN_MB} MB`
      );
      setHasError(true);
      return false;
    }
    setIsLoading(true);
    setHasError(false);
    setInputValue('Validating File Name');

    if (type === null) return;
    try {
      setInputValue('Uploading');
      const { relativePath } =
        C.UPLOAD_STRATEGY === 'direct'
          ? await uploadFileWithSignedUrls(file, 'content')
          : await api.common.uploadFile('content', file);
      setInputValue('Validating File');
      const createdFileResponse = await createContentFile({
        path: relativePath,
        type,
      });
      setIsLoading(false);

      if (createdFileResponse.error) {
        const message =
          createdFileResponse?.error?.message ||
          'The content could not be saved';
        addAlertNotification(message);
        setInputValue(message);
        setHasError(true);
      } else {
        setInputValue(file.name);
        setHasError(false);
        hide({ success: true, payload: createdFileResponse.data });
      }
    } catch (error) {
      console.error(error);
      setInputValue('Invalid Operation');
      setHasError(true);
      setIsLoading(false);
    }
  }, [file, setIsLoading, setInputValue, hasError, type]);

  const onChangeFile = useCallback(
    async (e) => {
      const [_file] = e.target.files;
      setFile(_file);
      setInputValue(_file.name);
      setHasError(false);
    },
    [setFile, setInputValue]
  );

  const acceptedFileTypes = useMemo(() => {
    let accept = [];
    if (type === C.I_CONTENT_FILE_TYPES.SCORM) {
      // @ts-ignore
      accept = ['.zip'];
    } else if (type === C.I_CONTENT_FILE_TYPES.DOCUMENT) {
      // @ts-ignore
      accept = ['.doc', '.docx', '.ppt', '.pptx', '.pdf'];
    } else if (type === C.I_CONTENT_FILE_TYPES.MEDIA) {
      // @ts-ignore
      accept = ['.mp4', '.webm', '.ogg'];
    }

    return accept.join(',');
  }, [type]);

  return (
    <Modal.Content lock={true}>
      <Modal.Header>
        <Modal.Title>Content File</Modal.Title>
      </Modal.Header>
      <Box>
        <Typography.p>
          You can upload content files up to {MAX_FILE_SIZE_IN_MB / 1024}GB.
          Please select a content type from below.
          <br />
          <br />
        </Typography.p>
        <FormField>
          <SingleSelect
            name="contentType"
            placeholder="Content type"
            onChange={onTypeChange}
            options={typeOptions}
            // @ts-expect-error
            initialSelectedItem={type}
          />
        </FormField>
        {!!type && (
          <FormField>
            {type === C.I_CONTENT_FILE_TYPES.EMBED && (
              <Input
                name="path"
                required
                placeholder="Content URL"
                hasError={hasError}
                onChange={onUrlChange}
                value={path}
                disabled={!type}
              />
            )}
            {(type === C.I_CONTENT_FILE_TYPES.SCORM ||
              type === C.I_CONTENT_FILE_TYPES.DOCUMENT ||
              type === C.I_CONTENT_FILE_TYPES.MEDIA) && (
              <>
                <FileInput
                  value={inputValue}
                  isLoading={isLoading}
                  placeholder="Content File"
                  hasError={hasError}
                  onChange={onChangeFile}
                  accept={acceptedFileTypes}
                  disabled={!type}
                />
                {C.UPLOAD_STRATEGY === 'direct' && isLoading ? (
                  <Box margin={{ top: 's' }}>
                    <ProgressBar value={uploadPercentage} />
                  </Box>
                ) : null}
              </>
            )}
          </FormField>
        )}
        <Typography.p>
          For <b>media</b> files the accepted extensions are: <b>.mp4</b>,{' '}
          <b>.webm</b> and <b>.ogg</b>.<br />
          For <b>SCORM</b> files, the accepted extensions are: <b>.zip</b>.
          <br />
          For <b>document</b> files, the accepted extensions are: <b>.pdf</b>.
          <br />
          For <b>embed</b> content, the accepted links are <b>YouTube</b>,{' '}
          <b>Vimeo</b> and any <b>web page URL</b> that allows embedding (not
          all pages support this).
          <br />
          <br />
        </Typography.p>
      </Box>
      <Modal.Actions>
        <Button label="Save" onClick={onSaveFile} />
      </Modal.Actions>
    </Modal.Content>
  );
};

const useUploadFileWithSignedUrls = (setInputValue) => {
  const [uploadedPartsCount, increaseUploadedPartsCount] = useReducer(
    (state) => state + 1,
    0
  );
  const [totalPartsCount, setTotalPartsCount] = useState(0);
  const api = useApi();
  // const BYTES_PER_PART = 5242880; // 5 MB
  const upload = useCallback(async (file, type) => {
    // const BYTES_PER_PART = file.size > 1000000000 ? 10485760 * 3 : 10485760; // 10 MB
    const BYTES_PER_PART = 1024 * 1024 * 10;
    const partsCount = Math.ceil(file.size / BYTES_PER_PART);
    setTotalPartsCount(partsCount);
    setInputValue('Starting Upload');
    const { uploadId, signedUrls, relativePath } =
      await api.common.getSignedPutUrls(file.name, type, partsCount);
    const partsIndexes = Object.keys(signedUrls);

    const chunkSize = 50;
    const groupedParts = splitArrayIntoChunks(partsIndexes, chunkSize);
    setInputValue('Uploading File');

    setInputValue('Uploading File');
    const res = await Promise.all(
      partsIndexes.map(async (partIndex) => {
        // const index = partIndexBase + parseInt(partIndex, 10) - 1;
        const index = parseInt(partIndex, 10) - 1;
        const start = index * BYTES_PER_PART;
        const end = (index + 1) * BYTES_PER_PART;
        const blob =
          index < partsIndexes.length
            ? file.slice(start, end)
            : file.slice(start);
        const res = await axios.put(signedUrls[partIndex], blob);
        increaseUploadedPartsCount();
        return res;
      })
    );
    const uploadedParts = res.map((part, index) => ({
      ETag: part.headers.etag,
      PartNumber: index + 1,
    }));
    setInputValue('Uploading File');
    await api.common.completeMultipartUpload(
      relativePath,
      uploadedParts,
      uploadId
    );

    setInputValue('Validating File');

    return { relativePath };
  }, []);
  const uploadPercentage = totalPartsCount
    ? (100 * uploadedPartsCount) / totalPartsCount
    : 0;
  return { uploadMethod: upload, uploadPercentage };
};

function splitArrayIntoChunks<T>(arr: T[], chunkSize: number) {
  const chunks: T[][] = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}
