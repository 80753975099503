import { z } from 'zod';

export type NotificationMessageBlockTranslations = Record<string, string>;

export const NotificationMessageBlockSchema = z.object({
  type: z.string(),
  attributes: z.record(z.string(), z.unknown()).optional(),
  value: z.unknown().optional(),
  if: z.string().optional(),
  loop: z.string().optional(),
});

export type NotificationMessageBlock = z.infer<
  typeof NotificationMessageBlockSchema
>;
export const NotificationMessageSchema = z.array(
  NotificationMessageBlockSchema
);
export type NotificationMessage = z.infer<typeof NotificationMessageSchema>;

export const NotificationTemplateSchema = z.object({
  id: z.string(),
  name: z.string(),
  channel: z.string(),
  content: NotificationMessageSchema,
  options: z.record(z.string(), z.unknown()),
  createdAt: z.date(),
  updatedAt: z.date(),
});
export type NotificationTemplate = z.infer<typeof NotificationTemplateSchema>;
