import React, { useEffect } from 'react';

import { SingleSelect } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

const options = _.reduce(
  C.APPROVAL_TYPES,
  (acc, label, value) => {
    return [
      ...acc,
      { label: formatConstantString(label), value: parseInt(value, 10) },
    ];
  },
  []
);

export const AdminLiveSessionApprovalTypeSelect = ({
  onChange,
  name = 'learning-type-select',
  isClearable = false,
  initialValue = null,
  value = null,
  hasError = false,
}) => {
  return (
    <SingleSelect
      onChange={onChange}
      options={options}
      placeholder="Select Approval Type"
      isClearable={isClearable}
      // @ts-ignore
      initialSelectedItem={initialValue}
      name={name}
      // @ts-ignore
      selectedItem={parseSelectedItem(value)}
      hasError={hasError}
    />
  );
};

const parseSelectedItem = (value) => {
  if (value) return parseInt(value);
  return null;
};
