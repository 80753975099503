import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  RtkWrapQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { OrganizationRouterContract } from './organization.router-contract.sdk';

export const getOrganizationClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'organizationStore';

  const tsRest = buildTsRestInstance<typeof OrganizationRouterContract>(
    apiInstance,
    OrganizationRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listLearners: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof OrganizationRouterContract.listLearners.query>
        >
      >,
      z.infer<(typeof OrganizationRouterContract.listLearners.responses)['200']>
    >;
    getLearner: RtkQuery<
      RtkFullParams<
        z.infer<typeof OrganizationRouterContract.getLearner.pathParams>
      >,
      z.infer<(typeof OrganizationRouterContract.getLearner.responses)['200']>
    >;

    listLearnerGroups: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof OrganizationRouterContract.listLearnerGroups.query>
        >
      >,
      z.infer<
        (typeof OrganizationRouterContract.listLearnerGroups.responses)['200']
      >
    >;
    createLearnerGroup: RtkMutation<
      RtkFullParams<
        z.infer<typeof OrganizationRouterContract.createLearnerGroup.body>
      >,
      z.infer<
        (typeof OrganizationRouterContract.createLearnerGroup.responses)['200']
      >
    >;
    importLearnerGroups: RtkMutation<
      RtkFullParams<typeof OrganizationRouterContract.importLearnerGroups.body>,
      z.infer<
        (typeof OrganizationRouterContract.importLearnerGroups.responses)['200']
      >
    >;
    getLearnerGroup: RtkQuery<
      RtkFullParams<
        z.infer<typeof OrganizationRouterContract.getLearnerGroup.pathParams>
      >,
      z.infer<
        (typeof OrganizationRouterContract.getLearnerGroup.responses)['200']
      >
    >;
    deleteLearnerGroups: RtkMutation<
      RtkFullParams<
        z.infer<typeof OrganizationRouterContract.deleteLearnerGroups.body>
      >,
      z.infer<
        (typeof OrganizationRouterContract.deleteLearnerGroups.responses)['200']
      >
    >;
    updateLearnerGroup: RtkMutation<
      RtkFullParams<
        z.infer<typeof OrganizationRouterContract.updateLearnerGroup.body>,
        z.infer<typeof OrganizationRouterContract.updateLearnerGroup.pathParams>
      >,
      z.infer<
        (typeof OrganizationRouterContract.updateLearnerGroup.responses)['200']
      >
    >;
    exportLearnerGroups: RtkQuery<
      RtkFullParams<
        RtkWrapQuery<
          z.infer<typeof OrganizationRouterContract.exportLearnerGroups.query>
        >
      >,
      z.infer<
        (typeof OrganizationRouterContract.exportLearnerGroups.responses)['200']
      >
    >;
  }>(tsRest, OrganizationRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
