import React from 'react';

import './Textarea.scss';
import { TidComponent } from '../index.types';
import { useClassNameProps, useTestProps } from '@nl-lms/ui/hooks';

type Props = TidComponent<{
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  rows?: any;
  autofocus?: boolean;
  cols?: any;
}>;

type Ref = HTMLTextAreaElement;

export const Textarea = React.forwardRef<Ref, Props>((props, ref) => {
  const {
    className = '',
    hasError = false,
    name,
    autofocus = false,
    ...rest
  } = props;
  const commonProps = useTestProps(props);
  const classNameProps = useClassNameProps(
    'textarea',
    props,
    hasError ? 'textarea--has-error' : null
  );

  return (
    <textarea
      ref={ref}
      autoFocus={autofocus}
      name={name}
      {...classNameProps}
      {...commonProps}
      {...rest}
    />
  );
});
