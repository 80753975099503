import React from 'react';

import { ContentLibraryItemType } from '@nl-lms/feature/content-library/sdk';
import { SingleSelect } from '@nl-lms/ui/components';

const mapEnumsForSelectOptions = (enumConstant) => {
  const enumKeys = Object.keys(enumConstant).filter((i: string | number) =>
    isNaN(i as number)
  );
  return enumKeys.map((key) => ({
    label: key,
    value: enumConstant[key],
  }));
};

const AdminContentLibraryTypeSelect = ({
  onChange,
  name = 'content-library-type-select',
  initialValue = null,
  isClearable = false,
  value = null,
  hasError = false,
}) => (
  <SingleSelect
    onChange={onChange}
    options={mapEnumsForSelectOptions(ContentLibraryItemType)}
    placeholder="Select Type"
    isClearable={isClearable}
    name={name}
    // @ts-ignore
    selectedItem={value}
    // @ts-ignore
    initialSelectedItem={initialValue}
    hasError={hasError}
  />
);

export default AdminContentLibraryTypeSelect;
