import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AppQuery, FieldType } from '@nl-lms/common/shared';
import { LearnerGroup } from '@nl-lms/feature/organization/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Table,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { FilterBar } from '@nl-lms/ui/modules';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import {
  adminApi,
  organizationApi,
  useApi,
} from '../../../_common/services/api';
import { fetchAndMapLearners } from '../../_common/utils/fetchEntitiesForSelectMethods';
import { getLearnerPropertiesFilterFields } from '../../_common/utils/getLearnerPropertiesFilterFields';

const { useUpdateLearnerGroupMutation, useCreateLearnerGroupMutation } =
  organizationApi;
const { useListLearnersQuery } = adminApi;

type Props = {
  learnerGroup?: Partial<LearnerGroup>;
};

const Schema = yup.object().shape({
  title: yup.string().required(),
  isDynamic: yup.boolean(),
  filters: yup
    .object()
    .shape({
      id: yup.string().required(),
      value: yup.array().min(1, 'Must contain at least one filter').required(),
    })
    .required('Must contain at least one filter'),
});

export const AdminLearnerGroupDynamicEditFormSideModal = ({
  learnerGroup = {},
}: Props) => {
  const api = useApi();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      title: learnerGroup.title,
      isDynamic: true,
      filters: learnerGroup.filters || undefined,
    },
  });
  const { hide } = useModal();
  const [
    updateLearnerGroup,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateLearnerGroupMutation();
  const updateLearnerGroupAction = useAction(updateLearnerGroup, {
    successDetails: 'The learner group has been updated successfully',
  });

  const [
    createLearnerGroup,
    { isLoading: isCreateLoading, error: createError },
  ] = useCreateLearnerGroupMutation();
  const createLearnerGroupAction = useAction(createLearnerGroup, {
    successDetails: 'The learner group has been created successfully',
  });

  const [learnersPagination, setLearnersPagination] = useState({
    limit: 10,
    offset: 0,
    rowCount: 0,
  });
  const filters = watch('filters');
  const query = useMemo<AppQuery>(() => {
    if (filters && 'id' in filters) {
      return { filters, pagination: learnersPagination };
    }
    return { pagination: learnersPagination };
  }, [filters, learnersPagination]);

  const { data, isLoading: isFetchLearnersLoading } =
    useListLearnersQuery(query);

  const learners = useMemo(() => {
    if (!data) return { rows: [], count: 0 };
    return data;
  }, [data]);

  const onChangeLearnersPagination = useCallback(
    (pagination) => {
      setLearnersPagination(pagination);
    },
    [setLearnersPagination]
  );

  const onSubmit = useCallback(
    async (params) => {
      let res;
      if (learnerGroup.id) {
        res = await updateLearnerGroupAction({
          ...params,
          id: learnerGroup.id,
        });
      } else {
        res = await createLearnerGroupAction(params);
      }

      if (res?.data) hide();
    },
    [learnerGroup]
  );

  useEffect(() => {
    register('title');
    register('filters');
    register('isDynamic');
  }, []);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {learnerGroup.id ? 'Edit Learner Group' : 'Create New Learner Group'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box>
          <Box>
            <FormField
              label="Name"
              required
              errorMessage={errors?.title?.message}
            >
              <Input
                required
                {...register('title')}
                hasError={!!errors?.title?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Learners"
              errorMessage={
                (errors?.filters as any)?.value?.message ||
                errors?.filters?.message
              }
            >
              <Controller
                name="filters"
                control={control}
                render={({ field }) => (
                  <FilterBar
                    id={''}
                    // todo check this
                    // @ts-ignore
                    onChangeFilters={field.onChange}
                    fields={[
                      {
                        name: 'id',
                        label: 'Learner',
                        type: FieldType.select,
                        loadOptions: fetchAndMapLearners,
                        ValueComponent: ({ children }) => {
                          return <Sensitive>{children}</Sensitive>;
                        },
                      },
                      {
                        name: 'first_name',
                        label: 'First Name',
                        type: FieldType.string,
                        ValueComponent: ({ children }) => {
                          return <Sensitive>{children}</Sensitive>;
                        },
                      },
                      {
                        name: 'last_name',
                        label: 'Last Name',
                        type: FieldType.string,
                        ValueComponent: ({ children }) => {
                          return <Sensitive>{children}</Sensitive>;
                        },
                      },
                      {
                        name: 'email',
                        label: 'Email',
                        type: FieldType.string,
                        ValueComponent: ({ children }) => {
                          return <Sensitive>{children}</Sensitive>;
                        },
                      },
                      {
                        name: 'is_active',
                        label: 'Active',
                        type: FieldType.boolean,
                      },
                      {
                        name: 'manager_id',
                        label: 'Learner Manager',
                        type: FieldType.select,
                        loadOptions: fetchAndMapLearners,
                        ValueComponent: ({ children }) => {
                          return <Sensitive>{children}</Sensitive>;
                        },
                      },
                      {
                        name: 'is_manager',
                        label: 'Is Manager',
                        type: FieldType.boolean,
                      },
                      ...getLearnerPropertiesFilterFields(),
                    ]}
                    initialFilters={
                      learnerGroup.filters && 'id' in learnerGroup.filters
                        ? learnerGroup.filters
                        : undefined
                    }
                  />
                )}
              />
            </FormField>
            <Separator marginTop={10} />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography.h3>{learners.count} learner(s) selected</Typography.h3>
          </Box>
        </Box>
        <Separator marginTop={10} backgroundColor="transparent" />
        <Box>
          <Box>
            <Table
              columns={[
                {
                  Header: 'First Name',
                  accessor: 'firstName',
                  sortField: 'firstName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.firstName}</Sensitive>
                  ),
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastName',
                  sortField: 'lastName',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.lastName}</Sensitive>
                  ),
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  sortField: 'email',
                  Cell: ({ row }) => (
                    <Sensitive>{row.original.email}</Sensitive>
                  ),
                },
              ]}
              pagination={{
                ...learnersPagination,
                rowCount: learners?.count || 0,
              }}
              isLoading={isFetchLearnersLoading}
              data={learners.rows}
              onChangePagination={onChangeLearnersPagination}
            />
            <Separator marginTop={10} backgroundColor="transparent" />
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>
          {getMessageFromError(createError || updateError)}
        </SideModal.Error>
        <Button
          onClick={handleSubmit(onSubmit)}
          label={learnerGroup.id ? 'Save' : 'Create'}
          isLoading={isCreateLoading || isUpdateLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
