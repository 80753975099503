import dagre from 'dagre';
import { useEffect, useState } from 'react';
import { Edge, MarkerType, Node, Position } from 'reactflow';

import { LearningAssignment } from '@nl-lms/common/feature/types';

import { FlowGraphConnection, FlowGraphEntity } from './FlowGraph';

export enum LayoutDirection {
  LeftToRight = 'LR',
  RightToLeft = 'RL',
  TopToBottom = 'TB',
  BottomToTop = 'BT',
}

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: LayoutDirection
) => {
  const isHorizontal = direction === 'RL' || direction === 'LR';

  const NODE_WIDTH = 600;
  const NODE_HEIGHT = 300;

  const dagreGraph = new dagre.graphlib.Graph();

  const isConnected = (nodeId: string) =>
    edges?.some((edge) => edge?.source === nodeId || edge?.target === nodeId);

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  // const hasEdges = !!edges?.length;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node, index) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    if (!node?.position || isConnected(node?.id)) {
      node.position = nodeWithPosition;
    }

    return node;
  });

  return { nodes, edges };
};

export const useGraphElements = ({
  entities,
  unconnectedEntities,
  draggedNodes,
  connections,
  isConnectable,
  onDeleteEdge,
  onClickEdge,
  onDeleteNode,
  onClickNode,
  onConnect,
  onUpsertEdge,
  layout,
}: {
  entities: FlowGraphEntity[];
  unconnectedEntities: FlowGraphEntity[];
  connections: FlowGraphConnection[];
  layout: LayoutDirection;
  isConnectable?: boolean;
  onDeleteEdge?: ({
    ruleIndex,
    conditionIndex,
  }: {
    ruleIndex: number;
    conditionIndex?: number;
  }) => void;
  onClickEdge: (index: number, edgeData: any) => void;
  onDeleteNode?: ((id: string, type: string) => void) | null;
  onConnect?: ((data: any) => void) | null;
  onUpsertEdge?: ((data: any) => void) | null;
  onClickNode?: ((data: LearningAssignment, type: string) => void) | null;
  draggedNodes?: FlowGraphEntity[] | null;
}) => {
  const [nodes, setNodes] = useState<Partial<Node>[]>([]);
  const [edges, setEdges] = useState<Partial<Edge>[]>([]);

  useEffect(() => {
    setNodes(
      // @ts-ignore
      entities?.map((entity) => ({
        id: entity?.id,
        type: entity?.type,
        hidden: unconnectedEntities?.includes(entity),
        isConnectable,
        sourcePosition:
          entity?.sourcePosition || layout === LayoutDirection.TopToBottom
            ? Position.Bottom
            : Position.Right,
        targetPosition:
          entity?.targetPosition || layout === LayoutDirection.TopToBottom
            ? Position.Top
            : Position.Left,
        position:
          draggedNodes?.find((dn) => dn.id === entity.id)?.position || null,
        data: {
          ...entity?.nodeData,
          onClickNode,
          layout,
          onDeleteEdge: isConnectable ? onDeleteEdge : null,
          onClickEdge: isConnectable ? onClickEdge : null,
          onDeleteNode: isConnectable ? onDeleteNode : null,
          onConnect: isConnectable ? onConnect : null,
          onUpsertEdge: isConnectable ? onUpsertEdge : null,
        },
      }))
    );

    setEdges(
      connections?.map((connection) => ({
        id: connection?.id,
        type: connection?.type,
        source: connection?.source,
        target: connection?.target,
        sourceHandle: connection?.sourceHandle || null,
        targetHandle: connection?.targetHandle || null,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 24,
          height: 24,
          strokeWidth: 2,
        },
        data: {
          ...connection?.edgeData,
          layout,
          onDeleteEdge: isConnectable ? onDeleteEdge : null,
          onClickEdge: isConnectable ? onClickEdge : null,
          onDeleteNode: isConnectable ? onDeleteNode : null,
        },
      }))
    );
  }, [
    entities,
    connections,
    isConnectable,
    onDeleteEdge,
    onClickEdge,
    onDeleteNode,
    onClickNode,
    onConnect,
    layout,
    onUpsertEdge,
    unconnectedEntities,
    draggedNodes,
  ]);

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    nodes as Node[],
    edges as Edge[],
    layout
  );

  return {
    layoutedEdges,
    layoutedNodes,
  };
};
