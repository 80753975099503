import { z } from 'zod';

import {
  ActionSchema,
  ActionTriggerSchema,
  CompetencySchema,
  LearnerGroupSchema,
  LearnerSchema,
  LearningAssignmentInstanceSchema,
  LearningAssignmentInstanceStatus,
  LearningAssignmentLearningContentType,
  LearningAssignmentSchema,
  LearningProgramInstanceSchema,
  LearningProgramSchema,
  LearningProgramSettingsSchema,
  RuleConditionSchema,
  RuleSchema,
  ScheduleDateSchema,
  TagSchema,
} from '@nl-lms/common/feature/types';
import {
  AddRuleConditionSchema,
  AddRulesPayloadSchema,
} from '@nl-lms/feature/automation/sdk';

export const LearningProgramAudienceSchema = z
  .object({
    learnerGroupIds: z.array(z.string()),
    learnerIds: z.array(z.string()),
  })
  .refine(
    (audience) => {
      const { learnerIds, learnerGroupIds } = audience;
      return learnerIds.length || learnerGroupIds.length;
    },
    { message: 'No audience members specified' }
  );

export const CreateLearningProgramPayloadSchema = z.object({
  name: z.string(),
  description: z.string(),
  thumbnail: z.string(),
});

export const LaunchLearningProgramSchema = z.object({
  createLearningProgramInstances: z.boolean(),
  audience: LearningProgramAudienceSchema,
});

export const UpdateLearningProgramPayloadSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    thumbnail: z.string(),
    settings: LearningProgramSettingsSchema,
  })
  .partial();

export const AddLearningAssignmentPayloadSchema = z.array(
  z
    .object({
      name: z.string(),
      assessmentFormId: z.string().nullable(),
      learningPathId: z.string().nullable(),
      surveyFormId: z.string().nullable(),
      checklistId: z.string().nullable(),
      elearningCourseId: z.string().nullable(),
      courseId: z.string().nullable(),
    })
    .refine(
      (payload) => {
        const {
          assessmentFormId,
          learningPathId,
          surveyFormId,
          checklistId,
          elearningCourseId,
          courseId,
        } = payload;
        return (
          assessmentFormId ||
          learningPathId ||
          surveyFormId ||
          checklistId ||
          elearningCourseId ||
          courseId
        );
      },
      { message: 'At least one learning content type must be specified' }
    )
);

export const ListLearningProgramInstanceSchema =
  LearningProgramInstanceSchema.extend({
    learner: LearnerSchema,
    learningProgram: LearningProgramSchema,
  });

export const ListLearningProgramInstanceForALearnerSchema =
  LearningProgramInstanceSchema.extend({
    learningProgram: LearningProgramSchema,
  });

export enum LearningProgramStatus {
  initial,
  draft,
  released,
}

export const GetLearningProgramSchema = LearningProgramSchema.extend({
  learners: z.array(LearnerSchema),
  learnerGroups: z.array(LearnerGroupSchema),
  autoEnrollRule: RuleSchema.extend({
    conditions: z.array(RuleConditionSchema),
  }).nullable(),
  tags: z.array(TagSchema),
  competencies: z.array(CompetencySchema),
  status: z.nativeEnum(LearningProgramStatus),
});

export const GetLearningProgramForALearnerSchema = LearningProgramSchema.extend(
  {
    content: z.array(LearningAssignmentSchema),
    activeInstance: LearningProgramInstanceSchema.nullable(),
    tags: z.array(TagSchema),
    competencies: z.array(CompetencySchema),
  }
);

export const UpsertProgramRulesPayloadSchema = z.object({
  rules: AddRulesPayloadSchema,
  settings: LearningProgramSettingsSchema,
});

export const ListProgramRulesPayloadSchema = RuleSchema.extend({
  conditions: z.array(RuleConditionSchema),
});

export const GetLearningProgramInstanceSchema =
  LearningProgramInstanceSchema.extend({
    learner: LearnerSchema,
    learningAssignments: z.array(LearningAssignmentSchema),
    learningAssignmentInstances: z.array(
      LearningAssignmentInstanceSchema.extend({
        status: z.nativeEnum(LearningAssignmentInstanceStatus),
        type: z.nativeEnum(LearningAssignmentLearningContentType),
        learningSessionId: z.string(),
      })
    ),
    actions: z.array(
      ActionSchema.extend({
        triggers: z.array(ActionTriggerSchema),
      })
    ),
  });

export const UpsertAutoEnrollSchema = z.object({
  scheduleDate: ScheduleDateSchema.nullable(),
  learnerGroupIds: z.array(z.string()),
});

export const LearningProgramLearningContentSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(LearningAssignmentLearningContentType),
});
