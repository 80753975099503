import React from 'react';

import { LiveCourseType } from '@nl-lms/common/feature/types';
import { Icon } from '@nl-lms/ui/components';

export const LiveCourseTypeIcon = ({ type }: { type: LiveCourseType }) => {
  if (type === LiveCourseType.IN_CLASS) {
    return <Icon.ClipboardIconSmall />;
  } else if (type === LiveCourseType.CONFERENCE) {
    return <Icon.MicIconSmall />;
  }

  return <Icon.MonitorIconSmall />;
};
