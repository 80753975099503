import { z } from 'zod';

export type WidgetPosition = {
  x: number;
  y: number;
  w: number;
  h: number;
  minH?: number;
  minW?: number;
  maxW?: number;
  maxH?: number;
};

export type Widget<Payload = Record<string, unknown>> = {
  label: string;
  position: WidgetPosition;
  type: string;
  payload: Payload;
};

export const DashboardWidgetPositionSchema = z.object({
  x: z.number(),
  y: z.number(),
  w: z.number(),
  h: z.number(),
  minH: z.number().optional(),
  minW: z.number().optional(),
  maxW: z.number().optional(),
  maxH: z.number().optional(),
});

export const DashboardWidgetSchema = z.object({
  label: z.string(),
  position: DashboardWidgetPositionSchema,
});

export const DashboardSchema = z.object({
  id: z.string(),
  label: z.string(),
  widgets: z.array(DashboardWidgetSchema),
  targetLearnerGroupIds: z.array(z.string()),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Dashboard = z.infer<typeof DashboardSchema>;
