import { ApiService, snakelize } from '../lib';
import { AuthAccess, IApiService } from './types';

export class Auth extends ApiService implements IApiService<'auth'> {
  public paths: any;
  public serviceBase: string = '/auth/basic';

  constructor(props) {
    super(props);

    this.paths = {
      access: () => `${this.serviceBase}/access`,
      refresh: () => `${this.serviceBase}/refresh`,
      reject: () => `${this.serviceBase}/reject`,
      external: () => `${this.serviceBase}/external`,
      register: () => `${this.serviceBase}/register`,
      registerConfirm: () => `${this.serviceBase}/register/confirm`,
      impersonateLearner: () => `${this.serviceBase}/impersonate-learner`,
    };
  }

  access = (email, password, code = undefined, tk = undefined) =>
    this.api.post<string, AuthAccess>(this.paths.access(), {
      email,
      password,
      code,
      tk,
    });

  refresh = () => this.api.post(this.paths.refresh());

  impersonateLearner = (learnerId) =>
    this.api.post(this.paths.impersonateLearner(), snakelize({ learnerId }));

  reject = () => this.api.post(this.paths.reject());

  register = (data) => this.api.post(this.paths.register(), snakelize(data));

  registerConfirm = (token, signal) =>
    this.api.post(
      this.paths.registerConfirm(),
      {},
      { params: { tk: token }, signal }
    );
}
