import axios from 'axios';

import {
  camelizeTransformInterceptor,
  errorHandlerInterceptor,
  queryTransformerInterceptor,
  setAuthTokenInterceptor,
  setSpanIdInterceptor,
  successHandlerInterceptor,
} from './interceptors';
import { ApiClientOptions } from './types';

export const instance = (apiBaseUrl: string, options: ApiClientOptions) => {
  const apiInstance = axios.create({
    baseURL: apiBaseUrl,
  });

  apiInstance.defaults.headers.common['Content-Type'] = 'application/json';

  apiInstance.interceptors.request.use(
    setSpanIdInterceptor(options),
    errorHandlerInterceptor(options)
  );
  apiInstance.interceptors.request.use(
    setAuthTokenInterceptor(options),
    errorHandlerInterceptor(options)
  );
  apiInstance.interceptors.request.use(
    queryTransformerInterceptor(options),
    errorHandlerInterceptor(options)
  );

  apiInstance.interceptors.response.use(
    successHandlerInterceptor(options),
    errorHandlerInterceptor(options)
  );
  apiInstance.interceptors.response.use(
    camelizeTransformInterceptor(options),
    errorHandlerInterceptor(options)
  );

  return apiInstance;
};
