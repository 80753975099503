export type SelectOption<T = any> = {
  label: string;
  value: any;
  entity?: T;
  type?: string;
  Component?: any;
};

export type BaseSelectProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  disableFiltering?: boolean;
  hasError?: boolean;
  isCreatable?: boolean;
  onChangeInputValue?: (inputValue: string) => void;
  onCreateOption?: (inputValue: string) => Promise<SelectOption | false>;
  initialHighlightedIndex?: number;
  onBlur?: () => void;
};

export type BaseSingleSelectProps = BaseSelectProps & {
  returnEntireItemOnChange?: boolean;
  isClearable?: boolean;
  onChange: (item: any, name: string) => void;
};

export const CreateOptionName = 'CREATE';
