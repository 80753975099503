import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import {
  IApiMethods,
  IApiService,
  IndividualLearning as IIndividualLearning,
  Survey as ISurvey,
} from './types';

export class IndividualLearning
  extends ApiService
  implements
    IApiService<'individualLearning'>,
    IApiMethods<'individualLearning'>
{
  static serviceBase = '/individual';
  public paths = {
    get: (id) => `${IndividualLearning.serviceBase}/${id}`,
    update: (id) => `${IndividualLearning.serviceBase}/${id}`,
    list: () => IndividualLearning.serviceBase,
    listInstances: () => `${IndividualLearning.serviceBase}/instance`,
    create: () => IndividualLearning.serviceBase,
    bulkDelete: () => IndividualLearning.serviceBase,
    bulkDeleteInstances: () => `${IndividualLearning.serviceBase}/instance`,
    updateInstance: (id) => `${IndividualLearning.serviceBase}/instance/${id}`,
    createWithInstances: () => `${IndividualLearning.serviceBase}/instance`,
    getInstance: (id) => `${IndividualLearning.serviceBase}/instance/${id}`,
    listForSelect: () => `/list/individual`,
    listForLearnerSelect: () => `/list/individual/learner`,
  };

  get = (id) => this.api.get(this.paths.get(id));

  getInstance = (id) => this.api.get(this.paths.getInstance(id));

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), {
      params: { query },
    });

  listInstances = (query: AppQuery) =>
    this.api.get(this.paths.listInstances(), {
      params: { query },
    });

  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = ({ id, ...data }) =>
    this.api.patch(this.paths.update(id), snakelize(data));

  updateInstance = ({ id, ...data }) =>
    this.api.patch(this.paths.updateInstance(id), snakelize(data));

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), {
      data: { list: ids },
    });

  bulkDeleteInstances = (ids) =>
    this.api.delete(`${this.paths.bulkDeleteInstances()}`, {
      data: { list: ids },
    });

  createWithInstances = (data) =>
    this.api.post(`${this.paths.createWithInstances()}`, snakelize(data));

  listForSelect = ({ query = '', isAdmin }) =>
    this.api.get<null, Pick<IIndividualLearning, 'id' | 'name' | 'type'>[]>(
      isAdmin ? this.paths.listForSelect() : this.paths.listForLearnerSelect(),
      {
        params: {
          query: { query },
        },
      }
    );
}
