import { ApiService, snakelize } from '../lib';
import { IApiMethods, IApiService, Learner } from './types';

export class User
  extends ApiService
  implements IApiService<'user'>, IApiMethods<'user'>
{
  static serviceBase = '/user';
  public paths = {
    edit: () => `${User.serviceBase}/edit`,
    get: () => `${User.serviceBase}/`,
    create: () => '/register/basic',
    list: () => `${User.serviceBase}/list`,
    listForSelect: () => `list/user`,
    resetPassword: () => `${User.serviceBase}/password/reset`,
    changePassword: () => `${User.serviceBase}/password/change`,
  };

  edit = ({ password, ...userData }) =>
    this.api.patch(
      this.paths.edit(),
      snakelize({ ...userData, currentPassword: password })
    );

  create = (props) => this.api.post(this.paths.create(), props);

  list = () => this.api.get(this.paths.list());
  listForSelect = (search = '') =>
    this.api.get<null, Learner[]>(this.paths.listForSelect(), {
      params: {
        query: { search },
      },
    });

  get = (): any => this.api.get(this.paths.get());

  resetPassword = (email) =>
    this.api.post(this.paths.resetPassword(), { email });

  changePassword = (password, token) =>
    this.api.post(this.paths.changePassword(), { password, token });
}
