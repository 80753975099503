import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { Badge, BadgeProps, BadgeTypes } from '../Badge/Badge';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import { TidComponent } from '../index.types';
import './BadgeGroup.scss';

type BadgeGroupProps = TidComponent<{
  children: React.ReactElement<BadgeProps, typeof Badge>[];
  name?: string;
  type?: BadgeTypes;
  reorderTags?: boolean;
}>;

export const BadgeGroup = (props: BadgeGroupProps) => {
  const {
    name = 'badgeGroup',
    type = BadgeTypes.DEFAULT,
    reorderTags = true,
    children,
  } = props;
  const commonProps = useTestProps(props);

  const [hiddenItemsCount, setHiddenItemsCount] = useState(0);
  const [resize, setResize] = useState(false);
  const [resetHiddenItems, setResetHiddenItems] = useState(false);
  const [currentChildren, setCurrentChildren] = useState([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const badgeRef = useRef<HTMLDivElement>(null);

  // when inside modal, react portal doesn`t allow interaction with the menu
  const isElementInsideModal = () =>
    !!document.querySelector("div[class='new-modal']") ||
    !!document.querySelector("div[role='dialog']");

  const getChildrenProps = (childrenElements) =>
    childrenElements?.map((child) => child.props);

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setHiddenItemsCount(0);
      setResize(true);
    }, 300);
    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (
      JSON.stringify(getChildrenProps(children)) !==
      JSON.stringify(getChildrenProps(currentChildren))
    ) {
      setResetHiddenItems(true);
      // @ts-ignore
      setCurrentChildren(children);
    }
  }, [children]);

  useEffect(() => {
    if (resetHiddenItems) {
      setHiddenItemsCount(0);
      setResetHiddenItems(false);
    } else if (
      containerRef.current &&
      badgeRef.current &&
      containerRef.current.getBoundingClientRect().width <
        badgeRef.current.getBoundingClientRect().width &&
      hiddenItemsCount < children.length
    ) {
      setHiddenItemsCount(hiddenItemsCount + 1);
    }
    setResize(false);
  }, [
    children,
    resetHiddenItems,
    hiddenItemsCount,
    resize,
    containerRef,
    badgeRef,
  ]);

  const sortedChildren = useMemo(() => {
    if (children) {
      return reorderTags
        ? [...children]?.sort((a, b) =>
            a.props.label > b.props.label ? -1 : 1
          )
        : [...children]?.sort((a, b) =>
            a.props.label.length > b.props.label.length ? 1 : -1
          );
    }
    return children;
  }, [reorderTags, children]);

  const visibleChildren = sortedChildren?.slice(
    0,
    sortedChildren.length - hiddenItemsCount
  );

  const hiddenChildren = sortedChildren?.slice(
    sortedChildren.length - hiddenItemsCount,
    sortedChildren.length
  );

  const menuItems = hiddenChildren?.map((childElement) => ({
    name: childElement,
    handler: () => {},
  }));

  return (
    <div className="badge-group" ref={containerRef} {...commonProps}>
      <div className="badge-group__items" ref={badgeRef}>
        {visibleChildren?.map((visibleChildElement, index) => {
          return (
            <div
              className="badge-group__item"
              key={`${visibleChildElement.props.label}${name}${index}`}
            >
              {visibleChildElement}
            </div>
          );
        })}
        {menuItems?.length > 0 && (
          <FloatingMenu
            items={menuItems}
            align="right"
            usePortal={!isElementInsideModal()}
          >
            <div className="badge-group__button">
              <Badge type={type} label={`+${hiddenItemsCount}`} />
            </div>
          </FloatingMenu>
        )}
      </div>
    </div>
  );
};
