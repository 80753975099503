import { useCallback } from 'react';

import { SimplifiedReportQuery } from '@nl-lms/feature/reports/sdk';
import { useNotifications } from '@nl-lms/ui/modules';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';

import { reportsApiClient, useApi } from '../../../_common/services/api';

export const useDownloadReport = () => {
  const api = useApi();
  const { addInfoNotification, addAlertNotification, removeNotification } =
    useNotifications();

  const downloadReport = useAction(reportsApiClient.downloadReport);
  const generateAndDownloadReport = useAction(
    reportsApiClient.generateAndDownloadReport
  );

  return useCallback(async (arg: string | SimplifiedReportQuery) => {
    const reportId = typeof arg === 'string' ? arg : null;
    const reportQuery = typeof arg === 'string' ? null : arg;
    let signedUrl: string | null = null;
    try {
      if (reportId) {
        const res = await downloadReport({
          params: { reportId },
        });
        if (res?.status != 200) return;

        signedUrl = res.body.signedUrl;
      } else if (reportQuery) {
        const notificationId = addInfoNotification({
          message: 'Downloading report',
        });
        const res = await generateAndDownloadReport({
          body: reportQuery,
        });
        removeNotification(notificationId);

        if (res?.status != 200) return;

        signedUrl = res.body.signedUrl;
      }
    } catch (e) {
      console.log(e);
    }

    if (!signedUrl) {
      addAlertNotification('Report could not be downloaded');
      return false;
    }

    window.location.href = signedUrl;
    return signedUrl;
  }, []);
};
