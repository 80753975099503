export const getStatusColor = (
  status: string,
): 'default' | 'alert' | 'warning' | 'success' | 'info' => {
  switch (status?.toLowerCase()) {
    case 'draft':
    case 'none':
    case 'pending':
    case 'not_attempted':
    case 'waiting_list_waiting':
    case 'approval_pending':
    case 'not_started':
    case 'emailunconfirmed':
      return 'default';
    case 'declined':
    case 'canceled':
    case 'cancelled':
    case 'not_invited':
    case 'failed':
    case 'completed_failed':
    case 'waiting_list_declined':
    case 'approval_declined':
    case 'absent':
    case 'no':
    case 'blocked':
    case 'mandatory_learning':
      return 'alert';
    case 'waiting':
    case 'processing':
    case 'invited':
    case 'creating':
    case 'sending':
    case 'waiting_list_invited':
    case 'waiting_list':
    case 'needs_approval':
    case 'waitingaction':
      return 'warning';
    case 'confirmed':
    case 'completed':
    case 'attended':
    case 'created':
    case 'go_show':
    case 'passed':
    case 'performed':
    case 'resolved':
    case 'yes':
    case 'sent':
    case 'completed_passed':
    case 'waiting_list_moved':
    case 'approval_confirmed':
      return 'success';
    case 'ready':
    case 'in_progress':
    case 'registered':
    case 'on_track':
    case 'planned':
    case 'scheduled':
    case 'no_learning_items':
    case 'inreview':
      return 'info';
    default:
      return 'default';
  }
};
