import _, { toLower } from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ArraySelectFieldMetadata,
  BooleanFieldMetadata,
  DateFieldMetadata,
  DatetimeFieldMetadata,
  FieldType,
  FieldTypeToFilterComponentType as FieldTypeToFilterComponent,
  FieldTypeToFilterBarActiveFilterType as FieldTypeToFilterType,
  FilterComponentTypeToActiveFilterType as FilterComponentToFilterType,
  JsonArraySelectFieldMetadata,
  NumberFieldMetadata,
  OperatorToLabelRecord,
  QueryFilterCombinator,
  QueryOperator,
  SelectFieldMetadata,
  StringFieldMetadata,
  isGeneralSelectFieldMetadata,
} from '@nl-lms/common/shared';
import {
  ArrowDownIcon,
  Box,
  Icon,
  Link,
  Tooltip,
  Typography,
} from '@nl-lms/ui/components';
import { useClassNameProps } from '@nl-lms/ui/hooks';
import {
  filterOptions,
  getFilterFieldValueLabel,
  getOptionClassName,
  getOptionId,
} from '@nl-lms/ui/modules';
import {
  FieldTypeToOptionsComponentMap,
  FieldTypeToOptionsSuggestComponentMap,
} from '@nl-lms/ui/modules';

import './LearningAssignmentRulesConditionsFilterBar.scss';
import {
  AssignationRuleEventEntityTypeToLabel,
  AssignationRuleEventPayload,
  AssignationRuleEventPayloadToOptions,
  AssignmentRuleMatchOperatorToPayloadOperator,
  DefaultReferenceEntities,
} from './utils/constants';
import {
  AssignationRuleFilterbarReferencesMap,
  GenericFieldOption,
} from './utils/types';
import {
  AssignationRuleEvent,
  AssignationRuleFilterFieldComponent,
  AssignationRuleFilterMatch,
} from './utils/types';

export enum ConditionsFilterbarState {
  Default = 'Default',
  EditingReferenceEntity = 'EditingReferenceEntity',
  EditingMatchField = 'EditingMatchField',
  EditingMatchOperator = 'EditingMatchOperator',
  EditingMatchValue = 'EditingMatchValue',
  EditingFilter = 'EditingFilter',
}

type LearningAssignmentRulesConditionsFilterbarProps = {
  onChange: (conditions) => void;
  isModuleRule?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  conditions?: any[];
};

const activeFilterToFilterComponent = (
  activeFilter: AssignationRuleFilterFieldComponent
): ConditionsFilterBarFilterComponent => {
  return {
    index: activeFilter.index,
    type: FieldTypeToFilterComponent[activeFilter.field.type],
    value: activeFilter.value,
    operator: activeFilter.operator,
    field: activeFilter.field,
  } as ConditionsFilterBarFilterComponent;
};

export type ConditionsFilterbarCombinatorComponent = {
  index: number;
  type: 'CombinatorComponent';
  value: 'and' | 'or';
};

export type ConditionsFilterBarFilterComponent = {
  type:
    | 'NumberFilterComponent'
    | 'StringFilterComponent'
    | 'DateFilterComponent'
    | 'DatetimeFilterComponent'
    | 'BooleanFilterComponent'
    | 'SelectFilterComponent';
  index: number;
  operator: QueryOperator;
  value: string | number | string[] | number[] | boolean;
  field:
    | NumberFieldMetadata
    | StringFieldMetadata
    | BooleanFieldMetadata
    | DateFieldMetadata
    | DatetimeFieldMetadata
    | SelectFieldMetadata
    | JsonArraySelectFieldMetadata
    | ArraySelectFieldMetadata;
};

type LearningAssignmentRulesCondition = {
  event: AssignationRuleEvent;
  conditionIndex: number;
  references: AssignationRuleFilterbarReferencesMap;
  match?:
    | ConditionsFilterBarFilterComponent
    | ConditionsFilterbarCombinatorComponent;
};

export const LearningAssignmentRulesConditionsFilterbar = memo(
  (props: LearningAssignmentRulesConditionsFilterbarProps) => {
    const [activeFilter, setActiveFilter] =
      useState<AssignationRuleFilterMatch | null>();
    const [activeEvent, setActiveEvent] =
      useState<AssignationRuleEvent | null>();
    const [activeReferences, setActiveReferences] =
      useState<AssignationRuleFilterbarReferencesMap>();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [activeState, setActiveState] = useState<ConditionsFilterbarState>(
      ConditionsFilterbarState.Default
    );
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<any>('');
    const [activeOptionId, setActiveOptionId] = useState<string | null>(null);
    const [conditions, setConditions] = useState<
      LearningAssignmentRulesCondition[]
    >([]);
    const defaultCombinator = QueryFilterCombinator.And?.toLowerCase();

    const isFilterBarActiveFilterValid = useMemo(() => {
      if (_.isEmpty(activeEvent)) {
        return false;
      }

      if (activeEvent?.payload?.length) {
        if (!activeEvent?.filterFields) {
          return (
            Object.keys(activeReferences ?? {})?.length ===
            activeEvent?.payload?.length
          );
        } else {
          return (
            Object.keys(activeReferences ?? {})?.length ===
              activeEvent?.payload?.length && !_.isEmpty(activeFilter)
          );
        }
      } else {
        return true;
      }
    }, [activeEvent, activeReferences, activeFilter]);

    const getActiveReferenceType = useCallback(() => {
      if (activeEvent && !_.isEmpty(activeReferences)) {
        let firstEmptyReferenceIndex = activeEvent?.payload?.findIndex(
          (payload) =>
            activeReferences[payload?.name] &&
            activeReferences[payload?.name]?.type &&
            !activeReferences[payload?.name]?.id &&
            !activeReferences[payload?.name]?.name
        );
        if (firstEmptyReferenceIndex > -1) {
          return (
            activeEvent?.payload?.[
              firstEmptyReferenceIndex
            ] as AssignationRuleEvent
          )?.name;
        }
        return null;
      }
      return null;
    }, [activeReferences, activeEvent]);

    const toggleMenu = (isVisible: boolean) => {
      if (!isVisible && isFilterBarActiveFilterValid) {
        return;
      }
      setIsMenuOpen(isVisible);
    };

    const onChangeMenuVisibility = (isVisible: boolean) => {
      toggleMenu(isVisible);
    };

    const onSetActiveOptionId = (id: string) => {
      setActiveOptionId(id);
    };

    const optionsMenuRef = useRef<HTMLUListElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const onShowOptionsMenu = () => {
      setIsMenuOpen(true);
    };

    const reset = () => {
      setInputValue('');
      setIsMenuOpen(false);
      setActiveFilter(null);
      setActiveReferences({} as AssignationRuleFilterbarReferencesMap);
      setActiveEvent({} as AssignationRuleEvent);
      setActiveState(ConditionsFilterbarState.Default);
      setActiveIndex(null);
    };

    const addFilter = (
      activeEvent: AssignationRuleEvent,
      activeFilter: AssignationRuleFilterMatch,
      activeReferences: AssignationRuleFilterbarReferencesMap,
      conditions: LearningAssignmentRulesCondition[],
      combinator?: QueryFilterCombinator
    ) => {
      const newConditionIndex = conditions?.length;

      const matchComponent = (
        activeFilter as AssignationRuleFilterFieldComponent
      )?.field?.type
        ? {
            ...activeFilter,
            index: newConditionIndex,
            type: FieldTypeToFilterComponent[
              (activeFilter as AssignationRuleFilterFieldComponent)?.field?.type
            ],
          }
        : {};
      let currConditionsCopy = [
        ...conditions,
        {
          match: matchComponent as AssignationRuleFilterMatch,
          conditionIndex: newConditionIndex,
          references: activeReferences as AssignationRuleFilterbarReferencesMap,
          event: activeEvent as AssignationRuleEvent,
        },
      ];
      if (newConditionIndex > 0) {
        currConditionsCopy?.splice(newConditionIndex, 0, {
          match: {
            index: newConditionIndex,
            type: 'CombinatorComponent',
            value: combinator || defaultCombinator,
          } as AssignationRuleFilterMatch,
          conditionIndex: newConditionIndex,
          references: {} as AssignationRuleFilterbarReferencesMap,
          event: {} as AssignationRuleEvent,
        });
      }
      setConditions(currConditionsCopy as LearningAssignmentRulesCondition[]);
    };

    const updateFilter = (
      activeEvent: AssignationRuleEvent,
      activeFilter: AssignationRuleFilterMatch,
      activeReferences: AssignationRuleFilterbarReferencesMap,
      conditions: LearningAssignmentRulesCondition[],
      activeIndex: number
    ) => {
      let newCondition = {
        match: {} as ConditionsFilterBarFilterComponent,
        conditionIndex: activeIndex,
        event: activeEvent,
        references: activeReferences,
      };
      if (activeFilter && !_.isEmpty(activeFilter)) {
        const filterComponent = activeFilterToFilterComponent(
          activeFilter as AssignationRuleFilterFieldComponent
        );
        const currentFilter = conditions[activeIndex]?.match;
        if (!currentFilter) {
          throw new Error('Invalid index value');
        }
        // @ts-ignore
        if (currentFilter.type === 'CombinatorComponent') {
          throw new Error('Invalid filter type');
        }
        newCondition.match = filterComponent;
      }

      const currConditionsCopy = [...conditions];
      if (activeIndex > 0) {
        currConditionsCopy.splice(activeIndex + 1, 1, newCondition);
      } else {
        currConditionsCopy.splice(activeIndex, 1, newCondition);
      }
      setConditions(currConditionsCopy);
    };

    const upsertFilter = (
      activeEvent: AssignationRuleEvent,
      activeFilter: AssignationRuleFilterFieldComponent,
      activeReferences: AssignationRuleFilterbarReferencesMap,
      activeIndex: number,
      conditions: LearningAssignmentRulesCondition[]
    ) => {
      if (!!activeIndex || activeIndex === 0) {
        updateFilter(
          activeEvent,
          activeFilter,
          activeReferences,
          conditions,
          activeIndex
        );
      } else {
        addFilter(activeEvent, activeFilter, activeReferences, conditions);
      }
    };

    const completeFilteringFlow = useCallback(
      (activeEvent, activeReferences, activeFilter, activeIndex) => {
        if (!activeEvent?.payload?.length) {
          upsertFilter(
            activeEvent as AssignationRuleEvent,
            {} as AssignationRuleFilterFieldComponent,
            {} as AssignationRuleFilterbarReferencesMap,
            activeIndex,
            conditions
          );
        } else {
          if (
            !activeEvent?.filterFields &&
            activeReferences &&
            Object.keys(activeReferences)?.length ===
              activeEvent?.payload?.length
          ) {
            upsertFilter(
              activeEvent as AssignationRuleEvent,
              {} as AssignationRuleFilterFieldComponent,
              activeReferences as AssignationRuleFilterbarReferencesMap,
              activeIndex,
              conditions
            );
          } else if (activeEvent?.filterFields) {
            upsertFilter(
              activeEvent as AssignationRuleEvent,
              activeFilter as AssignationRuleFilterFieldComponent,
              activeReferences as AssignationRuleFilterbarReferencesMap,
              activeIndex,
              conditions
            );
          }
        }

        setInputValue('');
        setIsMenuOpen(false);
        setActiveState(ConditionsFilterbarState.Default);
        setActiveFilter(null);
        setActiveReferences({} as AssignationRuleFilterbarReferencesMap);
        setActiveIndex(null);
      },
      [activeFilter, conditions, activeEvent, activeReferences, activeIndex]
    );

    const onToggleCombinator = useCallback(
      (conditionsIndex: number) => {
        const combinator = conditions?.[conditionsIndex]?.match;
        if (!combinator || combinator.type !== 'CombinatorComponent') {
          throw new Error('Invalid combinator');
        }

        setConditions((currConditions) => {
          let currConditionsCopy = [...currConditions];
          currConditionsCopy[conditionsIndex] = {
            ...currConditionsCopy[conditionsIndex],
            match: {
              ...combinator,
              value:
                combinator?.value === QueryFilterCombinator.And.toLowerCase()
                  ? QueryFilterCombinator.Or.toLowerCase()
                  : QueryFilterCombinator.And.toLowerCase(),
            } as ConditionsFilterbarCombinatorComponent,
          };

          return currConditionsCopy;
        });
      },
      [conditions]
    );

    const onRemoveCondition = useCallback(
      (index: number) => {
        if (index >= conditions?.length) {
          throw new Error('Invalid condition index');
        }

        const conditionToBeRemoved = conditions[index];
        if (
          !conditionToBeRemoved ||
          conditionToBeRemoved?.match?.type === 'CombinatorComponent'
        ) {
          return;
        }

        let indexesToRemove = [index];
        if (conditions?.[index - 1]?.match?.type === 'CombinatorComponent') {
          indexesToRemove.push(index - 1);
        } else if (
          conditions?.[index + 1]?.match?.type === 'CombinatorComponent'
        ) {
          indexesToRemove.push(index + 1);
        }

        const newConditions = conditions.filter((component, fIndex) => {
          return !indexesToRemove.includes(fIndex);
        });

        setConditions(newConditions);
        setIsMenuOpen(false);
        setInputValue('');
        setActiveEvent({} as AssignationRuleEvent);
      },
      [conditions]
    );

    const onEditCondition = useCallback(
      (selectedCondition: LearningAssignmentRulesCondition) => {
        const selectedEvent = selectedCondition?.event;
        setActiveEvent(selectedEvent as AssignationRuleEvent);
        const selectedReferences = selectedCondition?.references;
        setActiveReferences(selectedReferences);
        setActiveIndex(selectedCondition?.conditionIndex);

        let activeMatch = {} as AssignationRuleFilterMatch;
        if (selectedCondition?.match && !_.isEmpty(selectedCondition?.match)) {
          activeMatch = {
            index: selectedCondition.match.index,
            value: selectedCondition.match.value,
            field: (
              selectedCondition?.match as AssignationRuleFilterFieldComponent
            )?.field,
            operator: (
              selectedCondition?.match as AssignationRuleFilterFieldComponent
            )?.operator,
            type: FilterComponentToFilterType?.[selectedCondition?.match?.type],
          } as AssignationRuleFilterMatch;
        }

        setActiveFilter(activeMatch);
        setInputValue(
          (activeMatch as AssignationRuleFilterFieldComponent)?.field?.type ===
            FieldType.string ||
            (activeMatch as AssignationRuleFilterFieldComponent)?.field
              ?.type === FieldType.number
            ? (activeMatch as AssignationRuleFilterFieldComponent)?.value
            : ''
        );

        setActiveState(ConditionsFilterbarState.EditingFilter);
        setIsMenuOpen(true);
      },
      [conditions]
    );

    const assignationRulesConditionsFilterBarInputProps = {
      disabled: props.disabled,
      isLoading: props.isLoading,
      onResetFilters: reset,
      activeFilter,
      activeState,
      onChangeMenuVisibility,
      isMenuOpen,
      inputValue,
      inputRef,
      optionsMenuRef,
      onSetActiveOptionId,
      setInputValue,
      onShowOptionsMenu,
      conditions,
      activeOptionId,
      setActiveFilter,
      setActiveState,
      activeReferences,
      setActiveReferences,
      getActiveReferenceType,
      activeEvent,
      setActiveEvent,
      activeIndex,
      setActiveIndex,
      completeFilterRule: completeFilteringFlow,
    };

    const assignationRulesConditionsFilterBarFiltersProps = {
      conditions,
      onRemoveCondition,
      onToggleCombinator,
      onClickCondition: onEditCondition,
    };

    useEffect(() => {
      if (!props?.conditions?.length) {
        setConditions(props?.conditions ?? []);
      }
    }, [props.conditions]);

    useEffect(() => {
      if (!conditions?.length) {
        if (!props?.conditions?.length) {
          return;
        }
        props?.onChange?.([]);
      } else {
        const filteredConditions = conditions?.filter(
          (cond) => cond?.match?.type !== 'CombinatorComponent'
        );
        const filteredCombinators = conditions?.filter(
          (cond) => cond?.match?.type === 'CombinatorComponent'
        );
        props?.onChange?.(
          filteredConditions?.map((cond: any, condIndex) => {
            const eventName = cond?.event?.name;
            const referenceEntity = cond?.references?.assignmentId || {};
            const referenceEntityId =
              cond?.references?.assignmentId?.id || null;

            let match =
              cond?.match && !_.isEmpty(cond?.match)
                ? {
                    ...cond?.match,
                    field: cond?.match?.field?.name,
                    fieldLabel: cond?.match?.field?.label,
                    fieldType: cond?.match?.field?.type,
                    operator:
                      AssignmentRuleMatchOperatorToPayloadOperator[
                        cond?.match?.operator
                      ],
                  }
                : null;
            if (cond?.match?.field?.loadOptions) {
              match = {
                ...match,
                fieldLoadOptions: cond?.match?.field?.loadOptions,
              };
            }
            if (cond?.match?.field?.valueOptions) {
              match = {
                ...match,
                fieldValueOptions: cond?.match?.field?.valueOptions,
              };
            }

            let combinator;
            if (condIndex > 0) {
              combinator = filteredCombinators?.[condIndex - 1];
            }

            return {
              value: {
                eventName,
                referenceEntity,
                referenceEntityId,
                match,
              },
              combinator: combinator?.match?.value || null,
            };
          })
        );
      }
    }, [conditions]);

    return (
      <div className="assignation-rules-conditions-filter-bar">
        <AssignationRulesConditionsFilterBarInput
          {...assignationRulesConditionsFilterBarInputProps}
        />
        <AssignationRulesConditionsFilterBarConditions
          {...assignationRulesConditionsFilterBarFiltersProps}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps?.conditions?.length === nextProps?.conditions?.length &&
    prevProps?.conditions?.length === 0
);

const parseEntityLabelName = (entityName) => {
  if (entityName.includes('·')) {
    const entityNameSplit = entityName?.split('·');
    return entityNameSplit[0];
  }
  return entityName;
};

const AssignationRulesConditionsFilterBarInput = ({
  disabled,
  isLoading,
  onResetFilters,
  activeFilter,
  activeState,
  onChangeMenuVisibility,
  isMenuOpen,
  inputValue,
  inputRef,
  optionsMenuRef,
  onSetActiveOptionId,
  setInputValue,
  onShowOptionsMenu,
  conditions,
  activeOptionId,
  setActiveFilter,
  setActiveState,
  activeReferences,
  setActiveReferences,
  getActiveReferenceType,
  activeEvent,
  setActiveEvent,
  completeFilterRule,
  activeIndex,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const classNameProps = useClassNameProps(
    'assignation-rules-conditions-filter-bar__select',
    disabled
      ? 'assignation-rules-conditions-filter-bar__select--disabled'
      : null
  );

  const isSelectingDateValueOption =
    activeFilter &&
    (activeFilter.type === 'DateField' ||
      activeFilter.type === 'DatetimeField') &&
    activeState === ConditionsFilterbarState.EditingMatchValue;

  const onClickOutside = useCallback((e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      onChangeMenuVisibility(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, []);

  useEffect(() => {
    if (isMenuOpen && inputRef) {
      inputRef.current.focus();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen && optionsMenuRef.current) {
      const options = Array.from(
        optionsMenuRef.current.querySelectorAll(
          '[data-list-item-type="option"]'
        )
      );
      // @ts-ignore
      onSetActiveOptionId(options[0]?.dataset.listItemId);
    }
  }, [isMenuOpen, inputValue, activeState]);

  const activeConditionLabel = useMemo(() => {
    if (!activeEvent || _.isEmpty(activeEvent)) return null;

    let conditionLabel = '';

    if (activeReferences && !_.isEmpty(activeReferences)) {
      Object.keys(activeReferences).map((key) => {
        if (activeReferences[key]?.id && activeReferences[key]?.label) {
          conditionLabel = `${conditionLabel} ${parseEntityLabelName(
            activeReferences[key]?.label
          )}`;
        }
      });
    }

    if (activeFilter && !_.isEmpty(activeFilter)) {
      const operatorLabel = activeFilter?.operator
        ? ` ${OperatorToLabelRecord[activeFilter?.operator]}`
        : '';
      conditionLabel = `${conditionLabel} ${toLower(
        activeFilter?.field?.label || ''
      )} ${operatorLabel}:`;
    }

    return conditionLabel;
  }, [activeFilter, activeEvent, activeReferences]);

  const inputPlaceholder = useMemo(() => {
    if (activeState === ConditionsFilterbarState.Default) {
      return 'Select condition event';
    }
    if (activeState === ConditionsFilterbarState.EditingReferenceEntity) {
      return 'Select related assignment';
    }
    if (activeState === ConditionsFilterbarState.EditingMatchField) {
      return 'Type the search term and then select an option';
    }

    if (activeState === ConditionsFilterbarState.EditingMatchOperator) {
      return 'Select an operator';
    }

    if (activeState === ConditionsFilterbarState.EditingMatchValue) {
      if (
        activeFilter.field.type === FieldType.string ||
        activeFilter.field.type === FieldType.number
      ) {
        return 'Type in a value and then select one of the options';
      }

      if (activeFilter.field.type === FieldType.date) {
        return 'Select a date';
      }
      if (activeFilter.field.type === FieldType.datetime) {
        return 'Select a date';
      }
      return 'Select one of the options';
    }

    return 'Select related entity type';
  }, [activeState, activeFilter]);

  const onInputKeyDown = (e) => {
    let options = [];
    if (optionsMenuRef && optionsMenuRef.current) {
      options = Array.from(
        optionsMenuRef.current.querySelectorAll(
          '[data-list-item-type="option"]'
        )
      );
    }
    const activeOptionIndex = options.findIndex(
      // @ts-ignore
      (o) => o.dataset.listItemId === activeOptionId
    );
    const nextActiveOptionIndex =
      options.length !== activeOptionIndex + 1 ? activeOptionIndex + 1 : 0;
    const prevActiveOptionIndex =
      activeOptionIndex !== 0 ? activeOptionIndex - 1 : options.length - 1;
    if (isMenuOpen) {
      switch (e.keyCode) {
        // Escape
        case 27:
          onChangeMenuVisibility(false);
          break;
        // Down
        case 38:
          const prevActiveOptionItem = options[prevActiveOptionIndex];
          // @ts-ignore
          onSetActiveOptionId(prevActiveOptionItem?.dataset?.listItemId);
          break;
        // Up
        case 40:
          const nextActiveOptionItem = options[nextActiveOptionIndex];
          // @ts-ignore
          onSetActiveOptionId(nextActiveOptionItem?.dataset?.listItemId);
          break;
        // Enter
        case 13:
          const activeOption = options[activeOptionIndex];
          // @ts-ignore
          activeOption?.click();
          break;
        default:
          break;
      }
    } else {
      onChangeMenuVisibility(true);
    }
  };

  const onChange = useCallback((e) => setInputValue(e.target.value), []);
  const showResetButton = useMemo(() => {
    return (
      (!!activeEvent && !_.isEmpty(activeEvent)) ||
      (!!activeReferences && !_.isEmpty(activeReferences)) ||
      !!activeFilter?.field ||
      conditions.length > 0
    );
  }, [conditions, activeFilter, activeEvent, activeReferences]);

  const optionsMenuClassNameProps = useClassNameProps(
    'assignation-rules-conditions-filter-bar__options-menu',
    isSelectingDateValueOption
      ? 'assignation-rules-conditions-filter-bar__options-menu--date-picker-menu'
      : ''
  );

  const onSelectReferenceEventOption = useCallback(
    (option: GenericFieldOption) => {
      const { value, label, field } = option;
      if (!value || !field || _.isEmpty(field)) {
        throw new Error(`Invalid value or field for event option ${option}`);
      }

      setActiveEvent({
        name: value,
        label,
        payload: (field as AssignationRuleEvent)?.payload ?? [],
        filterFields: (field as AssignationRuleEvent)?.filterFields ?? null,
        mandatoryFilterFields:
          (field as AssignationRuleEvent)?.mandatoryFilterFields ?? false,
      });
      if ((field as AssignationRuleEvent)?.payload?.length) {
        let newActiveRefs = {};
        (field as AssignationRuleEvent)?.payload?.map((fieldPayload) => {
          newActiveRefs[fieldPayload?.name] = { type: fieldPayload?.name };
        });
        setActiveReferences(newActiveRefs);
      }
      setActiveState(ConditionsFilterbarState.EditingReferenceEntity);
    },
    [activeEvent]
  );

  const onSelectReferenceEntityOption = useCallback(
    (option: GenericFieldOption) => {
      const { value, label, field } = option;
      if (!value || !field?.name) {
        throw new Error(`Invalid value from option ${option}`);
      }
      const referenceType = field?.name;
      setActiveReferences((currentActiveRef) => ({
        ...currentActiveRef,
        [referenceType]: {
          type: referenceType,
          id: value,
          label,
        },
      }));
    },
    [activeReferences]
  );

  const onSelectFieldOption = useCallback(
    (option: GenericFieldOption) => {
      const { field } = option;
      if (!field) {
        throw new Error(`Invalid field from option ${option}`);
      }
      const newActiveFilter = {
        field,
        type: FieldTypeToFilterType[field.type],
        operator: null,
        value: null,
      };
      setActiveState(ConditionsFilterbarState.EditingMatchValue);
      setInputValue('');
      setActiveFilter(newActiveFilter);
      if (isGeneralSelectFieldMetadata(field as any)) {
        setActiveFilter({
          ...newActiveFilter,
          operator: QueryOperator.Includes,
        });
      }
      if (field.type === FieldType.date || field.type === FieldType.datetime) {
        setActiveFilter({
          ...newActiveFilter,
          operator: QueryOperator.GreaterThanEqual,
        });
      }
    },
    [activeFilter]
  );

  const onSelectOperatorOption = useCallback(
    (option: GenericFieldOption) => {
      if (!option.value) {
        throw new Error(`Invalid operator option ${option}`);
      }

      setActiveFilter({
        ...activeFilter,
        operator: option?.value,
      });

      if (!!activeFilter?.index || activeFilter?.index === 0) {
        setInputValue('');
        setActiveState(ConditionsFilterbarState.Default);
        onChangeMenuVisibility(false);
        completeFilterRule(
          activeEvent,
          activeReferences,
          {
            ...activeFilter,
            operator: option?.value,
          },
          activeIndex
        );
        return;
      }

      setActiveState(ConditionsFilterbarState.EditingMatchValue);
      setInputValue('');
    },
    [activeFilter, activeEvent, activeReferences, activeIndex]
  );

  const onSelectValueOption = useCallback(
    (option, preventFilterSubmit) => {
      if (
        option.type === 'DateValueOption' ||
        option.type === 'DatetimeValueOption'
      ) {
        if (!option.value) {
          console.error(`Invalid value for option ${option}`);
          return;
        }
      }

      if (option.type === 'NumberValueOption') {
        if (
          typeof option.value === 'undefined' ||
          option.value === null ||
          !option.operator
        ) {
          console.error(`Invalid option ${option}`);
          return;
        }
      }

      if (option.type === 'BooleanValueOption') {
        if (!option.operator) {
          console.error(`Invalid option ${option}`);
          return;
        }
      }

      if (option.type === 'StringValueOption') {
        if (!option.value || !option.operator) {
          console.error(`Invalid option ${option}`);
          return;
        }
      }

      if (option.type === 'SelectValueOption') {
        if (!option.value) {
          console.error(`Invalid option ${option}`);
          return;
        }

        if (
          activeState === ConditionsFilterbarState.EditingMatchValue &&
          (activeFilter.type !== 'SelectField' ||
            !isGeneralSelectFieldMetadata(activeFilter.field))
        ) {
          console.error(`Invalid active filter field - ${activeFilter.field}`);
          return;
        }
      }

      const { operator, field, value } = option;
      if (option.type !== 'SelectValueOption') {
        setActiveFilter({
          ...activeFilter,
          operator,
          type: FieldTypeToFilterType[field.type],
          value,
          field,
        });

        setInputValue('');
        onChangeMenuVisibility(false);
        setActiveState(ConditionsFilterbarState.Default);
        completeFilterRule(
          activeEvent,
          activeReferences,
          {
            index: activeFilter?.index,
            operator,
            type: FieldTypeToFilterType[field.type],
            value,
            field,
          },
          activeIndex
        );
      } else {
        const currentValue = (activeFilter?.value || []) as string[] | number[];
        // @ts-ignore
        const isDeselect = currentValue.includes(value);
        const newValue = isDeselect
          ? // @ts-ignore
            currentValue.filter((v) => v !== value)
          : [...currentValue, value];

        setActiveFilter((currentActiveFilter) => ({
          ...currentActiveFilter,
          operator,
          type: FieldTypeToFilterType[field.type],
          value: newValue,
          field,
        }));

        if (!preventFilterSubmit) {
          setInputValue('');
          onChangeMenuVisibility(false);
          setActiveState(ConditionsFilterbarState.Default);
          completeFilterRule(
            activeEvent,
            activeReferences,
            {
              index: activeFilter?.index,
              operator,
              type: FieldTypeToFilterType[field.type],
              value: newValue,
              field,
            },
            activeIndex
          );
          return;
        }

        setActiveFilter((currentActiveFilter) => ({
          ...currentActiveFilter,
          index: currentActiveFilter?.index,
        }));
        setActiveState(ConditionsFilterbarState.EditingMatchValue);
        if (newValue.length) {
          completeFilterRule(
            activeEvent,
            activeReferences,
            {
              index: activeFilter?.index,
              operator,
              type: FieldTypeToFilterType[field.type],
              value: newValue,
              field,
            },
            activeIndex
          );
        }
      }
    },
    [activeFilter, activeEvent, activeReferences, activeIndex]
  );

  const onSelectOption = (
    option: GenericFieldOption,
    persistFilterState = false
  ) => {
    if (!option) {
      console.error('Select option was called with an empty value');
      return;
    }
    setInputValue('');
    switch (option.type) {
      case 'ReferenceEventOption':
        return onSelectReferenceEventOption(option);
      case 'ReferenceEntityOption':
        return onSelectReferenceEntityOption(option);
      case 'FieldOption':
        return onSelectFieldOption(option);
      case 'OperatorOption':
        return onSelectOperatorOption(option);
      case 'DateValueOption':
      case 'DatetimeValueOption':
      case 'BooleanValueOption':
      case 'StringValueOption':
      case 'NumberValueOption':
        return onSelectValueOption(option, persistFilterState ?? false);
      case 'SelectValueOption':
        return onSelectValueOption(option, persistFilterState ?? true);
      default:
        console.error(`Invalid option type for ${option}`);
        return;
    }
  };

  const getOptionProps = (
    option: GenericFieldOption,
    id: string
  ): {
    onMouseEnter: (e: any) => void;
    onClick: (e: any) => void;
    'data-list-item-id': string;
    'data-list-item-type': string;
  } => {
    return {
      onMouseEnter: (e) => onSetActiveOptionId(e.target.dataset.listItemId),
      'data-list-item-id': id,
      'data-list-item-type': 'option',
      onClick: (event, isCheckbox = false) => {
        inputRef?.current?.focus();
        onSelectOption(option, isCheckbox);
      },
    };
  };

  const onSkipFilterStep = useCallback(() => {
    if (activeState === ConditionsFilterbarState.EditingReferenceEntity) {
      const newActiveRefs = {
        ...activeReferences,
        [getActiveReferenceType()]: {
          type: getActiveReferenceType(),
          id: DefaultReferenceEntities[getActiveReferenceType()]?.value,
          label: DefaultReferenceEntities[getActiveReferenceType()]?.label,
        },
      };

      setActiveReferences(newActiveRefs);

      if (activeEvent?.filterFields) {
        setActiveState(ConditionsFilterbarState.EditingMatchField);
      } else {
        completeFilterRule(activeEvent, newActiveRefs, {}, activeIndex);
      }
    } else if (activeState === ConditionsFilterbarState.EditingMatchField) {
      completeFilterRule(activeEvent, activeReferences, {}, activeIndex);
    }
  }, [activeState, activeEvent, activeReferences, activeIndex]);

  const renderMenuOptions = useCallback(() => {
    if (activeState === ConditionsFilterbarState.Default) {
      return AssignationRuleEventPayload?.map((field, index) => (
        <ReferenceEventMenuOption
          key={`field-event-menu-option-${field.name}-${index}`}
          activeOptionId={activeOptionId}
          field={field}
          getOptionProps={getOptionProps}
          option={{
            id: getOptionId(
              field.name,
              field.fieldLabel,
              'ReferenceEventOption'
            ),
            type: 'ReferenceEventOption',
            value: field.name,
            label: field.fieldLabel,
            field,
          }}
        />
      ));
    }

    if (
      activeState === ConditionsFilterbarState.EditingReferenceEntity &&
      activeEvent?.payload?.length > 0
    ) {
      if (!getActiveReferenceType() && activeEvent?.filterFields) {
        setActiveState(ConditionsFilterbarState.EditingMatchField);
        return;
      } else if (!getActiveReferenceType()) {
        setInputValue('');
        onChangeMenuVisibility(false);
        setActiveState(ConditionsFilterbarState.Default);
        completeFilterRule(activeEvent, activeReferences, {}, activeIndex);
        return;
      }
      const activeRefField =
        AssignationRuleEventPayloadToOptions[getActiveReferenceType() || ''];
      return (
        <ReferenceEntityMenuOptionComponent
          key={`field-input-menu-options-${activeRefField.name}-${0}`}
          inputValue={inputValue}
          activeOptionId={activeOptionId}
          activeState={activeState}
          activeFilter={activeFilter}
          activeEvent={activeEvent}
          field={activeRefField}
          getOptionProps={getOptionProps}
          onSkipFilterStep={onSkipFilterStep}
        />
      );
    }

    if (
      activeState === ConditionsFilterbarState.EditingMatchField ||
      activeState === ConditionsFilterbarState.EditingMatchOperator ||
      activeState === ConditionsFilterbarState.EditingMatchValue ||
      activeState === ConditionsFilterbarState.EditingFilter
    ) {
      const activeReferenceFilterFields = activeEvent?.filterFields || [];
      const showSkipOption =
        activeState === ConditionsFilterbarState.EditingMatchField &&
        !activeEvent?.mandatoryFilterFields;

      let MenuOptionsComponent = (
        <>
          {showSkipOption ? (
            <Box
              flex={{ flexDirection: 'row' }}
              padding={{ top: 'xs', bottom: 'xs', left: 'm' }}
            >
              <Typography.h5>
                <Link onClick={onSkipFilterStep}>skip additional filters</Link>
              </Typography.h5>
            </Box>
          ) : null}
          {activeReferenceFilterFields?.map((field, index) => {
            let OptionsComponent = FieldTypeToOptionsComponentMap[field.type];
            const key = `field-input-menu-options-${field.name}-${index}`;
            if (
              activeState === ConditionsFilterbarState.EditingMatchField &&
              !inputValue
            ) {
              OptionsComponent = FieldMenuOption;
            } else if (
              activeState === ConditionsFilterbarState.EditingMatchField &&
              inputValue
            ) {
              OptionsComponent =
                FieldTypeToOptionsSuggestComponentMap[field.type];
            } else if (activeFilter?.field?.name !== field.name) {
              return null;
            }

            return (
              <OptionsComponent
                key={key}
                inputValue={inputValue}
                activeOptionId={activeOptionId}
                activeFilter={activeFilter}
                field={field}
                onSelectOption={onSelectOption}
                getOptionProps={getOptionProps}
                activeState={activeState}
                filteringState={activeState}
              />
            );
          })}
        </>
      );

      return MenuOptionsComponent;
    }
  }, [activeEvent, activeState, activeReferences, inputValue, activeIndex]);

  return (
    <div {...classNameProps} ref={wrapperRef}>
      <div className="assignation-rules-conditions-filter-bar__input-wrapper">
        <div className="assignation-rules-conditions-filter-bar__search-icon">
          <Icon.FilterIcon />
        </div>
        {activeConditionLabel ? (
          <div className="assignation-rules-conditions-filter-bar__active-filter">
            {activeConditionLabel}
          </div>
        ) : null}
        <input
          className="assignation-rules-conditions-filter-bar__input"
          value={inputValue}
          ref={inputRef}
          placeholder={inputPlaceholder}
          disabled={isLoading}
          onKeyDown={onInputKeyDown}
          onChange={onChange}
          onFocus={onShowOptionsMenu}
          onClick={onShowOptionsMenu}
        />
        {isLoading ? (
          <div className="assignation-rules-conditions-filter-bar__spinner" />
        ) : null}
        {showResetButton ? (
          <Tooltip
            title="Reset Filters"
            className="assignation-rules-conditions-filter-bar__input-icon"
            onClick={onResetFilters}
            data-testid="assignation-rules-conditions-filter-bar-reset-button"
          >
            <Icon.FilterClearIcon />
          </Tooltip>
        ) : null}
      </div>
      {isMenuOpen ? (
        <div className="assignation-rules-conditions-filter-bar__options-menu-wrapper">
          <ul {...optionsMenuClassNameProps} ref={optionsMenuRef}>
            {activeState === ConditionsFilterbarState.Default && !inputValue ? (
              <span className="assignation-rules-conditions-filter-bar__option-separator">
                Event type
              </span>
            ) : null}
            {renderMenuOptions()}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export const ReferenceEventMenuOption = ({
  field,
  activeOptionId,
  getOptionProps,
  option,
}) => {
  return (
    <li
      className={getOptionClassName(option.id === activeOptionId, false)}
      {...getOptionProps(option, option.id)}
    >
      {field.fieldLabel}
    </li>
  );
};

export const ReferenceTypeMenuOption = ({
  field,
  activeOptionId,
  getOptionProps,
  option,
}) => {
  return (
    <li
      className={getOptionClassName(option.id === activeOptionId, false)}
      {...getOptionProps(option, option.id)}
    >
      {field.label}
    </li>
  );
};

export const ReferenceEntityMenuOptionComponent = ({
  inputValue,
  field,
  activeOptionId,
  getOptionProps,
  activeFilter,
  activeState,
  activeEvent,
  onSkipFilterStep,
}) => {
  const [asyncOptions, setAsyncOptions] = useState<GenericFieldOption[]>([]);
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const fetchOptions = useCallback(async (searchTerm) => {
    setIsFetchLoading(true);
    try {
      // @ts-ignore
      const res = await field.loadOptions(searchTerm);
      // @ts-ignore
      setAsyncOptions(res);
    } catch (e) {
    } finally {
      setIsFetchLoading(false);
    }
  }, []);

  const debouncedFetchOptions = useMemo(
    () => _.debounce(fetchOptions, 300),
    []
  );

  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  let options = useMemo(() => {
    let operator = QueryOperator.Includes;

    let _options = asyncOptions.map((v) => ({
      id: getOptionId(
        `${operator}-${v?.value}`,
        field.name,
        'ReferenceEntityOption'
      ),
      type: 'ReferenceEntityOption',
      field,
      label: v?.label,
      operator: operator,
      value: v?.value,
      isChecked: false,
    }));
    return filterOptions(inputValue, _options);
  }, [asyncOptions, field, activeFilter, inputValue]);

  const separatorLabel = useCallback(() => {
    if (
      (activeState === ConditionsFilterbarState.Default && inputValue) ||
      activeState === ConditionsFilterbarState.EditingReferenceEntity ||
      activeState === ConditionsFilterbarState.EditingFilter
    ) {
      let showSkipOption = false;
      if (activeState === ConditionsFilterbarState.EditingReferenceEntity) {
        const eventPayloadEntityIndex = activeEvent?.payload?.findIndex(
          (evP) => evP?.name === field?.name
        );
        if (!activeEvent?.payload?.[eventPayloadEntityIndex]?.mandatory) {
          showSkipOption = true;
        }
      }
      return (
        <>
          <Typography.h5>{field.label}</Typography.h5>
          {showSkipOption ? (
            <Link onClick={onSkipFilterStep}>
              apply for current {field.label}
            </Link>
          ) : null}
        </>
      );
    }

    return 'Values';
  }, [activeState, inputValue]);

  return (
    <>
      <Box className="assignation-rules-conditions-filter-bar__option-separator">
        {separatorLabel()}
      </Box>

      {isFetchLoading && !options.length ? (
        <li className={getOptionClassName(false, false)}>
          Fetching Options...
        </li>
      ) : (
        <>
          {options.length ? (
            options.map((option) => {
              const optionProps = getOptionProps(option, option.id);
              return (
                <li
                  className={getOptionClassName(
                    option.id === activeOptionId,
                    option.isChecked
                  )}
                  key={option.id}
                  name={`${option.label}-${option.value}`}
                  {...optionProps}
                  onClick={(e) => optionProps.onClick(e, false)}
                >
                  {option.label}
                </li>
              );
            })
          ) : (
            <li className={getOptionClassName(false, false)}>
              No data available
            </li>
          )}
        </>
      )}
    </>
  );
};

export const FieldMenuOption = ({ field, activeOptionId, getOptionProps }) => {
  const option: GenericFieldOption = {
    id: getOptionId(field.name, field.name, 'FieldOption'),
    type: 'FieldOption',
    value: field.name,
    label: field.label,
    field,
  };

  return (
    <li
      className={getOptionClassName(option.id === activeOptionId, false)}
      {...getOptionProps(option, option.id)}
    >
      {field.label}
    </li>
  );
};
export const parseConditionMatchFieldsToOptions = (
  conditions: LearningAssignmentRulesCondition[]
) =>
  Promise.all(
    conditions.map(async (cond: LearningAssignmentRulesCondition) => {
      if (
        cond?.match?.type !== 'CombinatorComponent' &&
        // @ts-ignore
        cond?.match?.field?.loadOptions
      ) {
        // @ts-ignore
        const valueOptions = await cond?.match?.field?.loadOptions('');
        return {
          ...cond,
          match: {
            ...cond?.match,
            field: {
              ...cond?.match?.field,
              valueOptions,
            },
          },
        };
      }
      return cond;
    })
  );

const AssignationRulesConditionsFilterBarConditions = ({
  conditions,
  onRemoveCondition,
  onToggleCombinator,
  onClickCondition,
}) => {
  const wrapperRef = useRef(null);
  const badgesRef = useRef([]);
  const [isToggled, setIsToggled] = useState(false);
  const [hasScrollableContent, setHasScrollableContent] = useState(false);
  const [parsedConditions, setParsedConditions] = useState([]);

  const classNameProps = useClassNameProps(
    'assignation-rules-conditions-filter-bar__selected-conditions',
    isToggled
      ? 'assignation-rules-conditions-filter-bar__selected-conditions--expanded'
      : null
  );

  useEffect(() => {
    if (wrapperRef.current) {
      setHasScrollableContent(
        // @ts-ignore
        wrapperRef.current.scrollHeight > wrapperRef.current.offsetHeight
      );
    }
  }, [isToggled]);

  useEffect(() => {
    if (conditions && conditions?.length) {
      parseConditionMatchFieldsToOptions(conditions).then((res) => {
        // @ts-ignore
        setParsedConditions(res);
      });
    }
  }, [conditions]);

  const conditionsCount = useMemo(
    () =>
      conditions?.reduce((counter, cond) => {
        if (cond?.match?.type !== 'CombinatorComponent') {
          return counter + 1;
        }
        return counter;
      }, 0),
    [conditions]
  );

  const toggleIsExpanded = useCallback(() => {
    setIsToggled(!isToggled);
  }, [isToggled]);

  if (!conditions || !conditions?.length) return null;

  return (
    <div {...classNameProps} ref={wrapperRef}>
      <Box flex={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
        {parsedConditions?.map((condition, index) => {
          return (
            <AssignationRulesConditionsFilterBarBadgeList
              condition={condition}
              // @ts-ignore
              ref={(el) => (badgesRef.current[index - 1] = el)}
              index={index}
              key={`filter-bar-filter-badge-${index}`}
              onRemoveCondition={onRemoveCondition}
              onToggleCombinator={onToggleCombinator}
              onClickCondition={onClickCondition}
            />
          );
        })}
      </Box>

      <Box
        className="assignation-rules-conditions-filter-bar__selected-conditions-actions"
        flex={{ flexDirection: 'row' }}
      >
        {hasScrollableContent || isToggled ? (
          <Tooltip
            className={`assignation-rules-conditions-filter-bar__selected-conditions-expander ${
              isToggled
                ? 'assignation-rules-conditions-filter-bar__selected-conditions-expander--expanded'
                : ''
            }`}
            onClick={toggleIsExpanded}
            offsetToChildren={-20}
            title={
              isToggled ? 'Hide' : `View all ${conditionsCount} conditions`
            }
          >
            <ArrowDownIcon />
          </Tooltip>
        ) : null}
      </Box>
    </div>
  );
};

type AssignationRulesConditionsFilterBarBadgeListProps = {
  condition: LearningAssignmentRulesCondition;
  index: number;
  onRemoveCondition: (index: number) => void;
  onToggleCombinator: (index: number) => void;
  onClickCondition: (condition: LearningAssignmentRulesCondition) => void;
};
export const AssignationRulesConditionsFilterBarBadgeList = forwardRef<
  HTMLDivElement,
  AssignationRulesConditionsFilterBarBadgeListProps
>(
  (
    {
      condition,
      index,
      onRemoveCondition,
      onToggleCombinator,
      onClickCondition,
    },
    ref
  ) => {
    if (condition?.match?.type === 'CombinatorComponent') {
      return (
        <AssignationRulesConditionsFilterBarBadge ref={ref} type="combinator">
          <span
            title="Toggle Combinator"
            onClick={() => onToggleCombinator(index)}
            className="assignation-rules-conditions-filter-bar__badge-component assignation-rules-conditions-filter-bar__badge-component--active"
          >
            {condition?.match?.value}
          </span>
        </AssignationRulesConditionsFilterBarBadge>
      );
    }

    const valueLabel = getFilterFieldValueLabel(condition?.match as any);
    const operatorLabel =
      OperatorToLabelRecord?.[
        (condition?.match as ConditionsFilterBarFilterComponent)?.operator
      ];
    const eventLabel = condition?.event?.label;

    return (
      <AssignationRulesConditionsFilterBarBadge
        type="filter"
        ref={ref}
        onRemove={() => onRemoveCondition(index)}
        onClick={() => onClickCondition(condition)}
      >
        <span
          title="Event Label"
          className="assignation-rules-conditions-filter-bar__badge-component"
        >
          {eventLabel}
        </span>
        {Object.keys(condition?.references).map((refKey) =>
          condition?.references[refKey]?.id &&
          condition?.references[refKey]?.label ? (
            <span
              title="Reference"
              className="assignation-rules-conditions-filter-bar__badge-component"
            >
              {`${
                AssignationRuleEventEntityTypeToLabel[
                  condition?.references[refKey]?.type
                ]
              } - ${condition?.references[refKey]?.label}`}
            </span>
          ) : null
        )}
        {condition?.match && !_.isEmpty(condition?.match) ? (
          <>
            <span
              title="Field"
              className="assignation-rules-conditions-filter-bar__badge-component"
            >
              {
                (condition?.match as ConditionsFilterBarFilterComponent)?.field
                  ?.label
              }
            </span>
            <span
              title="Edit Operator"
              className="assignation-rules-conditions-filter-bar__badge-component"
            >
              {operatorLabel}
            </span>
            <span
              title="Edit Value"
              className="assignation-rules-conditions-filter-bar__badge-component"
            >
              {valueLabel}
            </span>
          </>
        ) : null}
      </AssignationRulesConditionsFilterBarBadge>
    );
  }
);

export const AssignationRulesConditionsFilterBarBadge = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onRemove?: () => void;
    onClick?: () => void;
    type: string;
  }
>(({ children, type, onRemove = null, onClick }, ref) => {
  const componentsClassNameProps = useClassNameProps(
    'assignation-rules-conditions-filter-bar__badge-components',
    onClick
      ? 'assignation-rules-conditions-filter-bar__badge-components--active'
      : null
  );
  return (
    <div
      className="assignation-rules-conditions-filter-bar__badge"
      ref={ref}
      data-badge-type={type}
    >
      <div className="assignation-rules-conditions-filter-bar__badge-content">
        <span {...componentsClassNameProps} onClick={onClick}>
          {children}
        </span>
        {onRemove ? (
          <span
            title="Remove"
            onClick={onRemove}
            className="assignation-rules-conditions-filter-bar__badge-component assignation-rules-conditions-filter-bar__badge-component--active"
          >
            <Icon.CloseIcon strokeWidth="1" />
          </span>
        ) : null}
      </div>
    </div>
  );
});
