import React, { useMemo, useState } from 'react';

import { SingleSelect } from '@nl-lms/ui/components';
import { adminApi } from '../../../_common/services/api';

type Props = {
  onChange: (questionIds: string[]) => void;
  name: string;
  isClearable?: boolean;
  hasError?: boolean;
  returnEntireItemOnChange?: boolean;
  clearSelection?: boolean;
};

const { useListAssessmentQuestionsForSelectQuery } = adminApi;

export const AdminAssessmentQuestionSingleSelect = (props: Props) => {
  const {
    onChange,
    name,
    hasError = false,
    isClearable = false,
    returnEntireItemOnChange = false,
    clearSelection = false,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const { data, isLoading } =
    useListAssessmentQuestionsForSelectQuery(inputValue);
  const options = useMemo(() => {
    if (!data) return [];
    return data.map((question) => ({
      label: question.title,
      value: question.id,
      question,
    }));
  }, [data]);
  return (
    <SingleSelect
      name={name}
      onChangeInputValue={setInputValue}
      isLoading={isLoading}
      placeholder="Search by question name"
      onChange={onChange}
// @ts-ignore
      options={options}
      isClearable={isClearable}
      returnEntireItemOnChange={returnEntireItemOnChange}
      hasError={hasError}
      shouldClearSelection={clearSelection}
    />
  );
};
