import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  ScheduleDate,
  ScheduleDateSchema,
} from '@nl-lms/feature/automation/sdk';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  Input,
  ScheduleDateInput,
  SideModal,
  useModal,
} from '@nl-lms/ui/components';

import { learningAssignmentsApi } from '../../../_common/services/api';
import './LearningAssignmentCreateSideModal.scss';

const { useUpdateLearningAssignmentMutation } = learningAssignmentsApi;

const Schema = z.object({
  name: z.string().min(1, { message: 'Field is required' }),
  mandatory: z.boolean(),
  showAfterDueDate: z.boolean(),
  dueDate: ScheduleDateSchema.nullish(),
});

export const LearningAssignmentEditSideModal = ({
  assignment,
  showMandatory = true,
}) => {
  const { hide } = useModal();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      name: assignment.name,
      mandatory: assignment.mandatory,
      showAfterDueDate: assignment.showAfterDueDate,
      dueDate: assignment.dueDate
        ? ({ value: assignment.dueDate, type: 'fixed' } as ScheduleDate)
        : ({
            value: assignment.dueDateDelta,
            type: 'relative',
          } as ScheduleDate),
    },
  });
  const [updateLearningAssignment, { error, isLoading }] =
    useUpdateLearningAssignmentMutation();
  const onSubmit = useCallback(async (entity: z.infer<typeof Schema>) => {
    const parsedEntity = {
      id: assignment.id,
      name: entity.name,
      mandatory: entity.mandatory,
      showAfterDueDate: entity.showAfterDueDate,
      dueDate: entity.dueDate || null,
    };
    const result = await updateLearningAssignment(parsedEntity);
    if ('data' in result) {
      hide();
    }
  }, []);

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Edit Assignment</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box flex={{ flexDirection: 'column' }} margin={{ top: 'm' }}>
          <Box flex={{ flexDirection: 'row' }}>
            <FormField
              label="Name"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                placeholder="Name"
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
          <Box flex={{ flexDirection: 'row', gap: 'm' }}>
            <div style={{ flexBasis: '100%' }}>
              <FormField
                label="Due Date"
                errorMessage={errors.dueDate?.message}
                helpText="The due date will be updated only on the main learning assignment. Learning sessions that have been already created will not be changed."
              >
                <Controller
                  name="dueDate"
                  control={control}
                  render={({ field }) => (
                    <ScheduleDateInput
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </FormField>
            </div>
            <div style={{ flexBasis: '100%' }}>
              <FormField label="Content Settings">
                <>
                  {showMandatory ? (
                    <Checkbox
                      {...register('mandatory')}
                      className="admin-learning-assignment-modal__edit-checkbox"
                      label="Mandatory"
                    />
                  ) : null}{' '}
                  <Checkbox
                    className="admin-learning-assignment-modal__edit-checkbox"
                    {...register('showAfterDueDate')}
                    label="After the due date passes the assignment can still be accessed by learners"
                  />
                </>
              </FormField>
            </div>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        {/*// @ts-ignore */}
        <SideModal.Error>{error}</SideModal.Error>
        <Button
          label="Save"
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
