import React, { useCallback, useRef } from 'react';

import { useTestProps } from '@nl-lms/ui/hooks';

import { Button } from '../Button/Button';
import { TidComponent } from '../index.types';
import './FileInput.scss';

type FileInputProps = TidComponent<{
  name?: string;
  placeholder?: string;
  isLoading?: boolean;
  value?: string;
  hasError?: boolean;
  onChange: React.ChangeEventHandler;
  accept: string;
  multiple?: boolean;
  disabled?: boolean;
}>;

export const FileInput = (props: FileInputProps) => {
  const {
    name = '',
    placeholder = 'Select File',
    hasError = false,
    value = '',
    isLoading = false,
    disabled = false,
    multiple = false,
    accept,
    onChange,
  } = props;
  const commonProps = useTestProps(props);
  const fileInput = useRef<HTMLInputElement>();
  const onClickFileInputButton = useCallback(() => {
    // @ts-ignore
    if (fileInput.current) fileInput.current.click();
  }, [fileInput]);

  return (
    <div className="file_input" {...commonProps}>
      <input
        className={`file_input__text ${
          hasError ? `file_input__text--error` : ''
        }`}
        placeholder={placeholder}
        value={value}
        type="text"
        disabled
      />
      <input
        className="file_input__file"
        type="file"
        // @ts-ignore
        ref={fileInput}
        onChange={onChange}
        accept={accept}
        multiple={multiple}
        disabled={disabled || isLoading}
        name={name}
      />
      <Button
        small
        className="file_input__button"
        isLoading={isLoading}
        onClick={onClickFileInputButton}
        disabled={disabled || isLoading}
        label="Select File"
      />
    </div>
  );
};
