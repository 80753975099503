import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import { Modal } from './Modal';

type Modal<T = unknown, K = Record<string, unknown>> = {
  Component: React.FC<K>;
  props: K;
  resolve: (value: T) => void;
};

type ModalManagerContextType = {
  addModal: (modal: Modal) => void;
  removeModal: () => void;
};
export const ModalManagerContext = createContext<ModalManagerContextType>(
  {} as ModalManagerContextType
);

type ReducerState = Modal[];
type ReducerAction = { type: 'add'; payload: Modal } | { type: 'remove' };

export const ModalManagerProvider = ({ children }) => {
  const [modals, dispatch] = useReducer(
    (state: ReducerState, action: ReducerAction) => {
      switch (action.type) {
        case 'add':
          return [...state, action.payload];
        case 'remove':
          return state.slice(0, -1);
      }
    },
    []
  );

  const addModal = useCallback((modal: Modal) => {
    dispatch({ type: 'add', payload: modal });
  }, []);
  const removeModal = useCallback(() => {
    dispatch({ type: 'remove' });
  }, []);

  return (
    <ModalManagerContext.Provider
      value={{
        addModal,
        removeModal,
      }}
    >
      {children}
      {modals.map(({ Component, props, resolve }, index) => {
        return (
          <Modal.Provider
            defaultOpened={true}
            onClose={(val) => {
              resolve(val);
              removeModal();
            }}
            key={`modal-manager-modal-${index}`}
          >
            <Component {...props} />
          </Modal.Provider>
        );
      })}
    </ModalManagerContext.Provider>
  );
};

export const useModalManagerContext = () => useContext(ModalManagerContext);

export function useShowModal<T = unknown, K = Record<string, unknown>>(
  Component: React.FC<K>,
  initialProps?: K
) {
  const { addModal } = useContext(ModalManagerContext);
  return useCallback(
    (arg?: K) => {
      return new Promise<T | undefined>((resolve) => {
        let props = {} as K;
        if (initialProps) props = initialProps;
        if (!(arg instanceof Event || arg instanceof MouseEvent) && arg)
          props = arg;
        const modal: Modal<T, K> = {
          Component,
          props,
          resolve,
        };
        // @ts-expect-error
        addModal(modal);
      });
    },
    [initialProps]
  );
}
