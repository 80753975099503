import { z } from 'zod';

import {
  RtkFullParams,
  RtkMutation,
  RtkQuery,
  buildRtkInstance,
  buildTsRestInstance,
  instance,
} from '@nl-lms/common/sdk';

import { DashboardRouterContract } from './dashboard.router-contract.sdk';

export const getClients = ({ baseUrl, tokenGetter }) => {
  const apiOptions = {
    tokenGetter,
    baseUrl,
  };
  const apiInstance = instance(baseUrl, apiOptions);

  const reducerPath = 'dashboardStore';

  const tsRest = buildTsRestInstance<typeof DashboardRouterContract>(
    apiInstance,
    DashboardRouterContract,
    apiOptions
  );

  const rtk = buildRtkInstance<{
    listDashboards: RtkQuery<
      void,
      z.infer<typeof DashboardRouterContract.listDashboards.responses['200']>
    >;
    createDashboard: RtkMutation<
      RtkFullParams<
        z.infer<typeof DashboardRouterContract.createDashboard.body>
      >,
      z.infer<typeof DashboardRouterContract.createDashboard.responses['200']>
    >;
    updateDashboard: RtkMutation<
      RtkFullParams<
        z.infer<typeof DashboardRouterContract.updateDashboard.body>,
        z.infer<typeof DashboardRouterContract.updateDashboard.pathParams>
      >,
      z.infer<typeof DashboardRouterContract.updateDashboard.responses['200']>
    >;
    deleteDashboard: RtkMutation<
      RtkFullParams<
        z.infer<typeof DashboardRouterContract.deleteDashboard.body>,
        z.infer<typeof DashboardRouterContract.deleteDashboard.pathParams>
      >,
      z.infer<typeof DashboardRouterContract.deleteDashboard.responses['200']>
    >;
  }>(tsRest, DashboardRouterContract, {
    ...apiOptions,
    reducerPath,
  });

  return { raw: tsRest, rtk };
};
