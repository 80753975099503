import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ApiQueryFilter, QueryOperator } from '@nl-lms/common/shared';
import {
  NotificationMessageBlock,
  NotificationReceiver,
  NotificationsScope,
  RenderNotificationPayload,
  RenderNotificationResult,
  SampleReceiversQuery,
  SendNotificationPayload,
  SendNotificationReceiver,
} from '@nl-lms/feature/notifications/sdk';
import {
  Badge,
  BadgeTypes,
  Box,
  Button,
  DateTime,
  EditIcon,
  FormField,
  Icon,
  Modal,
  Separator,
  SideModal,
  SingleSelect,
  TextEditor,
  Typography,
  useConfirmationModal,
  useModal,
  useShowModal,
} from '@nl-lms/ui/components';
import { useNotifications } from '@nl-lms/ui/modules';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { Can } from '../../../Can';
import { authStore } from '../../../_common/modules/Auth/auth';
import { notificationsApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import { NotificationEmailMessageInput } from './NotificationEmailMessageInput/NotificationEmailMessageInput';
import { parseProsemirrorMentions } from './utils';

type ModalPropsWithReceiver = {
  receivers?: SendNotificationPayload['to'];
  message?: NotificationMessageBlock[];
  mentionOptions?: { id: string; label: string }[];
};
type SendNotificationSideModalProps = ModalPropsWithReceiver;

const {
  useSampleReceiversQuery,
  useRenderNotificationMutation,
  useScheduleNotificationsMutation,
  useSendNotificationsMutation,
} = notificationsApi;

export const useTriggerNotificationSendSideModal = () => {
  const triggerModal = useShowModal(AdminNotificationSend);
  return useCallback(
    (
      receivers: SendNotificationPayload['to'],
      message: NotificationMessageBlock[],
      mentionOptions: { id: string; label: string }[]
    ) => {
      return triggerModal({
        receivers,
        message,
        mentionOptions,
      });
    },
    []
  );
};

export const AdminSendNotificationSideModalTrigger = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const showModal = useShowModal(AdminNotificationSend);

  return (
    <Can
      do={NotificationsScope.send.action}
      on={NotificationsScope.send.resource}
    >
      {React.cloneElement(children, {
        onClick: showModal,
      })}
    </Can>
  );
};

const DefaultMessage = [
  { type: 'header', attributes: {} },
  {
    type: 'text',
    value: 'this is a title',
    attributes: { type: 'title', translations: {} },
  },
  {
    type: 'text',
    attributes: { translations: {} },
    value: 'this is a message',
  },
  { type: 'separator', attributes: {} },
  {
    type: 'buttons',
    attributes: {},
    value: [
      {
        label: 'send assignment',
        translations: {},
        href: '{{portalUrl}}',
      },
    ],
  },
];

const DefaultSubject = {
  type: 'title',
  value: '',
  attributes: { translations: {} },
};

export const AdminNotificationSend = (
  props: SendNotificationSideModalProps
) => {
  const { hide } = useModal();
  const [subject, setSubject] = useState<NotificationMessageBlock>(() => {
    const block = props.message
      ? (props.message.find(
          (b) => b.type === 'title'
        ) as unknown as NotificationMessageBlock)
      : DefaultSubject;
    return {
      ...block,
      value: parseProsemirrorMentions(block.value as string),
    };
  });
  const [message, setMessage] = useState<NotificationMessageBlock[]>(() =>
    props.message
      ? props.message.filter((b) => b.type !== 'title')
      : DefaultMessage.filter((b) => b.type !== 'title')
  );
  const [to, setTo] = useState<SendNotificationPayload['to']>(() =>
    props.receivers ? props.receivers : []
  );
  const mentionOptions = props.mentionOptions || [];

  const { addSuccessNotification } = useNotifications();

  const [sendNotifications, { isLoading: isSendLoading, error: sendError }] =
    useSendNotificationsMutation();

  const sampleReceiversQuery = useMemo<SampleReceiversQuery>(() => {
    if (Array.isArray(to)) return { name: 'sample', filters: [''] };
    return to;
  }, [to]);

  const { data: sampleReceiversData, isLoading: isSampleReceiversLoading } =
    // @ts-expect-error
    useSampleReceiversQuery({
      query: sampleReceiversQuery,
    });

  const receivers = sampleReceiversData?.receivers || [];
  const showConfirmation = useConfirmationModal({
    message: 'You are about to send a notification.',
  });

  const onClickSend = useCallback(async () => {
    const confirmation = await showConfirmation();
    if (!confirmation.confirmed) return;
    const response = await sendNotifications({
      to,
      message: {
        email: {
          content: [...message, subject],
          options: {},
        },
      },
    });
    if ('data' in response) {
      const searchParams = new URLSearchParams();
      const userIdFilter: ApiQueryFilter = {
        value: {
          field: 'userId',
          operator: QueryOperator.Includes,
          value: [authStore.user.id],
        },
      };
      searchParams.set('query', JSON.stringify({ filters: userIdFilter }));
      const successLink = `${routes.admin.analytics.notifications.path.full()}?${searchParams.toString()}`;
      addSuccessNotification({
        message:
          'Your notifications are now being sent. Click on the text to follow the delivery status',
        link: successLink,
      });
      hide();
    }
  }, [subject, message, to]);

  const onChangeSubject = useCallback(
    (htmlValue, markdownValue) => {
      console.log(markdownValue);
      setSubject({ ...subject, value: markdownValue });
    },
    [setSubject, subject]
  );

  return (
    <SideModal.Content>
      <SideModal.Header>
        <Box flex={{ flexDirection: 'row', gap: 's', alignItems: 'center' }}>
          <EditIcon />
          <Typography.h2>Send Notification</Typography.h2>
        </Box>
      </SideModal.Header>
      <SideModal.Body scrollable={false}>
        <FormField label="To">
          <div className="notification-to-input">
            {sampleReceiversData ? (
              <Badge
                type={BadgeTypes.LIGHT}
                label={`${sampleReceiversData.total} learner${
                  sampleReceiversData.total > 1 ? 's' : ''
                }`}
              />
            ) : (
              <Badge type={BadgeTypes.LIGHT} label="Learners" />
            )}
          </div>
        </FormField>
        <FormField label="Subject">
          <TextEditor.Provider
            mentionOptions={mentionOptions}
            onChange={onChangeSubject}
            value={subject.value as string}
          >
            <TextEditor.Input className="notification-subject-input" />
            <TextEditor.MentionsMenu />
          </TextEditor.Provider>
        </FormField>
        <FormField label="Message">
          <NotificationEmailMessageInput
            message={message}
            mentionOptions={mentionOptions}
            onChange={setMessage}
          />
        </FormField>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(sendError)}</SideModal.Error>
        <NotificationPreviewSideModalTrigger
          message={[...message, subject]}
          receivers={receivers}
        >
          <Button
            disabled={isSampleReceiversLoading}
            regular
            label="Preview"
            icon={<Icon.ImageIcon />}
          />
        </NotificationPreviewSideModalTrigger>
        {/* <Button regular label="Send to yourself" /> */}
        {/* <ScheduleNotificationsModalTrigger */}
        {/*   to={to} */}
        {/*   message={message} */}
        {/*   subject={subject} */}
        {/* > */}
        {/*   <Button regular label="Schedule" icon={<Icon.CalendarIcon />} /> */}
        {/* </ScheduleNotificationsModalTrigger> */}
        <Button
          label="Send"
          onClick={onClickSend}
          isLoading={isSendLoading}
          icon={<Icon.SendMessageIcon />}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

const NotificationPreviewSideModalTrigger = ({
  children,
  receivers,
  message,
}) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>
        <div>{children}</div>
      </Modal.Trigger>
      <SideModal.Content>
        <NotificationPreviewSideModalContent
          receivers={receivers}
          message={message}
        />
      </SideModal.Content>
    </Modal.Provider>
  );
};

const NotificationPreviewSideModalContent = ({ receivers, message }) => {
  const [selectedReceiver, setSelectedReceiver] =
    useState<NotificationReceiver | null>(() => receivers[0] || null);

  const [renderResult, setRenderResult] =
    useState<RenderNotificationResult | null>(null);
  const [renderNotification, { isLoading, error }] =
    useRenderNotificationMutation();

  const renderQuery = useMemo<RenderNotificationPayload>(
    () => ({
      receiver: selectedReceiver as unknown as SendNotificationReceiver,
      message,
      channel: 'email',
    }),
    [selectedReceiver, message]
  );

  const onChangeReceiver = useCallback((option) => {
    if (!option || !option?.entity) return;
    setSelectedReceiver(option.entity);
  }, []);

  const onRenderNotification = useCallback(async () => {
    const result = await renderNotification(renderQuery);

    if ('data' in result) {
      //@ts-ignore
      setRenderResult(result.data);
    }
  }, [renderQuery]);
  useEffect(() => {
    if (!selectedReceiver) return;
    onRenderNotification();
  }, [renderQuery]);

  return (
    <>
      <SideModal.Header>
        <SideModal.Title>Preview Notification</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <FormField label="Receiver">
          <SingleSelect
            name="receiver"
            options={receivers.map((receiver) => ({
              value: receiver.data.id,
              label: receiver.name,
              entity: receiver,
            }))}
            returnEntireItemOnChange={true}
            initialSelectedItem={receivers[0]?.data?.id}
            onChange={onChangeReceiver}
          />
        </FormField>
        <Separator marginTop={-10} marginBottom={10} />
        {!renderResult ? (
          <Typography.h2 loading={isLoading}>
            Select one a receiver so that you can see how the notification will
            look like
          </Typography.h2>
        ) : null}
        {error ? (
          <Typography.p type="alert">
            An error occurred while trying to render the notification
          </Typography.p>
        ) : null}
        {renderResult ? (
          <>
            <Box
              flex={{ flexDirection: 'row', gap: 's' }}
              margin={{ bottom: 's' }}
            >
              <Typography.h2 type="muted">Subject: </Typography.h2>
              <Typography.h2>{renderResult.title}</Typography.h2>
            </Box>
            <Box margin={{ bottom: 'm' }} flex={{ flex: 1 }}>
              <iframe
                srcDoc={renderResult.message}
                allowFullScreen
                style={{ height: '100%', width: '100%' }}
                title="Preview"
              />
            </Box>
          </>
        ) : null}
      </SideModal.Body>
    </>
  );
};

const ScheduleNotificationsModalTrigger = ({
  message,
  to,
  subject,
  children,
}) => {
  return (
    <Modal.Provider>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.Content>
        <ScheduleNotificationsModal
          to={to}
          subject={subject}
          message={message}
        />
      </Modal.Content>
    </Modal.Provider>
  );
};

const ScheduleNotificationsModal = ({ message, to, subject }) => {
  const [scheduleDate, setScheduleDate] = useState<Date>(() => new Date());

  const { hide: hideModal } = useModal();
  const [
    scheduleNotifications,
    { isLoading: isScheduleLoading, error: scheduleError },
  ] = useScheduleNotificationsMutation();

  const { addSuccessNotification } = useNotifications();
  const onSubmit = useCallback(async () => {
    if (!scheduleDate) return;
    const response = await scheduleNotifications({
      to,
      message: {
        email: {
          content: [...message, subject],
          options: {},
        },
      },
      scheduleDate,
    });
    if ('data' in response) {
      const searchParams = new URLSearchParams();
      const userIdFilter: ApiQueryFilter = {
        value: {
          field: 'userId',
          operator: QueryOperator.Includes,
          value: [authStore.user.id],
        },
      };
      searchParams.set('query', JSON.stringify({ filters: userIdFilter }));
      const successLink = `${routes.admin.analytics.notifications.path.full()}?${searchParams.toString()}`;
      addSuccessNotification({
        message:
          'Your notifications have been scheduled. Click on the text to get a detailed overview',
        link: successLink,
      });
      hideModal();
      hideModal();
    }
  }, []);

  return (
    <>
      <Modal.Header>
        <Modal.Title>Schedule Notifications</Modal.Title>
      </Modal.Header>
      <FormField noPadding label="Schedule Date">
        <DateTime
          minDate={new Date()}
          value={scheduleDate}
          onChange={(e) => setScheduleDate(e.target.value)}
        />
      </FormField>
      <Modal.Actions>
        <Modal.Error>{getMessageFromError(scheduleError)}</Modal.Error>
        <Button
          disabled={!scheduleDate}
          isLoading={isScheduleLoading}
          label="Submit"
          onClick={onSubmit}
        />
      </Modal.Actions>
    </>
  );
};
