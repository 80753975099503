import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ContentFileSchema,
  ContentFileType,
} from '@nl-lms/common/feature/types';
import {
  BooleanResponseSchema,
  ContractPaginatedResponse,
  ContractStandardResponse,
} from '@nl-lms/common/sdk';
import {
  ApiQuerySchema,
  DeleteResourcesBodySchema,
} from '@nl-lms/common/shared';

import { ElearningRouterPrefix } from './elearning.constants.sdk';
import {
  CreateElearningCourseSchema,
  GetElearningCourseSchema,
  ListElearningCourseSchema,
  UpdateElearningCourseSchema,
} from './elearning.schema.sdk';

const contract = initContract();

export const ElearningRouterContractSdk = contract.router({
  listElearningCourses: {
    method: 'GET',
    path: `${ElearningRouterPrefix}/course`,
    responses: ContractPaginatedResponse(ListElearningCourseSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createElearningCourse: {
    method: 'POST',
    path: `${ElearningRouterPrefix}/course`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: CreateElearningCourseSchema,
  },
  removeElearningCourses: {
    method: 'DELETE',
    path: `${ElearningRouterPrefix}/course`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  getElearningCourse: {
    method: 'GET',
    path: `${ElearningRouterPrefix}/course/:elearningCourseId`,
    responses: ContractStandardResponse(GetElearningCourseSchema),
    pathParams: z.object({ elearningCourseId: z.string() }),
  },
  updateElearningCourse: {
    method: 'POST',
    path: `${ElearningRouterPrefix}/course/:id`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: UpdateElearningCourseSchema,
    pathParams: z.object({ id: z.string() }), // has to id because of useSubmitUpsertEntityFromSideModal
  },

  listContentFiles: {
    method: 'GET',
    path: `${ElearningRouterPrefix}/content-file`,
    responses: ContractPaginatedResponse(ContentFileSchema),
    query: z.object({ query: ApiQuerySchema }),
  },
  createContentFile: {
    method: 'POST',
    path: `${ElearningRouterPrefix}/content-file`,
    responses: ContractStandardResponse(ContentFileSchema),
    body: z.object({ type: z.nativeEnum(ContentFileType), path: z.string() }),
  },
  removeContentFiles: {
    method: 'DELETE',
    path: `${ElearningRouterPrefix}/content-file`,
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: DeleteResourcesBodySchema,
  },
  validateContentFile: {
    method: 'POST',
    path: `${ElearningRouterPrefix}/content-file/:contentFileId/validate`,
    pathParams: z.object({ contentFileId: z.string() }),
    responses: ContractStandardResponse(BooleanResponseSchema),
    body: z.object({}),
  },
});
