import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useNotifications } from '@nl-lms/ui/modules';

import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';

const { useCreateSurveyInstanceMutation } = learnerApi;

export const LearnerSurveyButton = ({ instance, surveyForm }) => {
  const { addAlertNotification } = useNotifications();

  const goToPlayer = useCallback(() => {
    window.open(routes.portal.survey.item.path.full(instance.id));
  }, [instance]);

  const [createSurveyInstance, { isLoading: isCreateLoading }] =
    useCreateSurveyInstanceMutation();

  const createInstance = useCallback(async () => {
    if (instance) return;
    if (isCreateLoading) return;

    try {
      const res = await createSurveyInstance({
        learnerId: authStore.learnerId as string,
        surveyFormId: surveyForm.id,
      });

      if ('data' in res) {
        window.open(routes.portal.survey.item.path.full(res.data?.id));
      } else if ('error' in res) {
        addAlertNotification({
          // @ts-ignore
          message: t(`assets.api.errors.${mutateRes.error.name}`),
        });
      }
    } catch (e) {
      console.log(e);
      addAlertNotification({
        message: t(`assets.api.errors.GENERAL_ERROR`),
      });
    }
  }, [surveyForm, instance, isCreateLoading]);

  const isAfterDeadline = useMemo(() => {
    if (!instance || !instance.dueDate || instance.showAfterDueDate)
      return false;
    return new Date().getTime() > new Date(instance.dueDate).getTime();
  }, [instance]);

  const { t } = useTranslation('learner');

  return (
    <>
      {!instance && surveyForm.canStartWithoutAssignment && (
        <Button
          label={t('common.learningactivitycard.start')}
          regular
          icon={<Icon.PlayIcon />}
          onClick={createInstance}
        />
      )}

      {((!instance && !surveyForm.canStartWithoutAssignment) ||
        instance?.status === C.I_SURVEY_STATUS.CANCELED) && (
        <Button
          label={t('common.learningactivitycard.start')}
          regular
          icon={<Icon.PlayIcon />}
          disabled
        />
      )}

      {instance?.status === C.I_SURVEY_STATUS.NOT_STARTED && (
        <Button
          label={t('common.learningactivitycard.start')}
          regular={!isAfterDeadline}
          alert={isAfterDeadline}
          icon={<Icon.PlayIcon />}
          // @ts-ignore
          onClick={goToPlayer}
          disabled={isAfterDeadline}
        />
      )}

      {instance?.status === C.I_SURVEY_STATUS.IN_PROGRESS && (
        <Button
          label={t('common.learningactivitycard.continue')}
          regular={!isAfterDeadline}
          alert={isAfterDeadline}
          icon={<Icon.PlayIcon />}
          // @ts-ignore
          onClick={goToPlayer}
          disabled={isAfterDeadline}
        />
      )}

      {instance?.status === C.I_SURVEY_STATUS.COMPLETED && (
        <Button
          label={t('common.learningactivitycard.completed')}
          success
          icon={<Icon.CheckIcon />}
        />
      )}
    </>
  );
};
