import { AppQuery } from '@nl-lms/common/shared';

import { ApiService, snakelize } from '../lib';
import { IApiMethods, IApiService, Survey as ISurvey } from './types';

export class Survey
  extends ApiService
  implements IApiService<'survey'>, IApiMethods<'survey'>
{
  static serviceBase = '/survey';
  public paths = {
    get: (id) => `${Survey.serviceBase}/${id}`,
    list: () => `${Survey.serviceBase}`,
    listByConditionedQuestion: (questionId) =>
      `${Survey.serviceBase}/question/${questionId}`,
    listInstances: () => `${Survey.serviceBase}/instance`,
    listInstanceQuestions: () => `${Survey.serviceBase}/instance/question`,
    updateInstanceQuestion: (id) =>
      `${Survey.serviceBase}/instance/question/${id}`,
    create: () => `${Survey.serviceBase}`,
    update: (id) => `${Survey.serviceBase}/${id}`,
    remove: (id) => `${Survey.serviceBase}/${id}`,
    bulkDelete: () => `${Survey.serviceBase}`,
    bulkDeleteInstance: () => `${Survey.serviceBase}/instance`,
    listForSelect: () => `/list/survey`,
  };

  get = (id) => this.api.get(this.paths.get(id));

  list = (query: AppQuery) =>
    this.api.get(this.paths.list(), {
      params: { query },
    });
  listByConditionedQuestion = (questionId: string) =>
    this.api.get<null, ISurvey[]>(
      this.paths.listByConditionedQuestion(questionId)
    );
  listInstances = (query: AppQuery) =>
    this.api.get(this.paths.listInstances(), {
      params: { query },
    });

  bulkDeleteInstance = (ids: string[]) =>
    this.api.delete(this.paths.bulkDeleteInstance(), { data: { list: ids } });

  listInstanceQuestions = (query: AppQuery) =>
    this.api.get<null, { rows: any[]; count: number }>(
      this.paths.listInstanceQuestions(),
      {
        params: { query },
      }
    );
  create = (entity) => this.api.post(this.paths.create(), snakelize(entity));

  update = (entity) =>
    this.api.patch(this.paths.update(entity.id), snakelize(entity));

  updateInstanceQuestion = (entity) =>
    this.api.patch(
      this.paths.updateInstanceQuestion(entity.id),
      snakelize(entity)
    );

  remove = ({ id }) => this.api.delete(this.paths.remove(id));

  listForSelect = (search = '') =>
    this.api.get<null, ISurvey[]>(this.paths.listForSelect(), {
      params: {
        query: { search },
      },
    });

  bulkDelete = (ids) =>
    this.api.delete(this.paths.bulkDelete(), { data: { list: ids } });
}
