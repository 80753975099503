export const BaseDeltaTimeObject = {
  minutes: 0,
  hours: 0,
  days: 0,
  weeks: 0,
  months: 0,
  years: 0,
};

export const getRecurrenceOptions = () => {
  const baseOptions = [
    { value: { ...BaseDeltaTimeObject, days: 1 }, label: 'Every day' },
    { value: { ...BaseDeltaTimeObject, weeks: 1 }, label: 'Every week' },
    { value: { ...BaseDeltaTimeObject, months: 1 }, label: 'Every month' },
    { value: { ...BaseDeltaTimeObject, years: 1 }, label: 'Every year' },
  ];
  const generatedOptions = [...Array(31)].map((value, index) => {
    const dayOption = {
      label: `Every ${index + 2} days`,
      value: { ...BaseDeltaTimeObject, days: index + 2 },
    };
    const weekOption = {
      label: `Every ${index + 2} weeks`,
      value: { ...BaseDeltaTimeObject, weeks: index + 2 },
    };
    const monthOption = {
      label: `Every ${index + 2} months`,
      value: { ...BaseDeltaTimeObject, months: index + 2 },
    };
    const yearOption = {
      label: `Every ${index + 2} years`,
      value: { ...BaseDeltaTimeObject, years: index + 2 },
    };
    return [dayOption, weekOption, monthOption, yearOption];
  });
  return [...baseOptions, ...generatedOptions.flat()];
};

export const orderValue = (value) => ({
  minutes: value?.minutes,
  hours: value?.hours,
  days: value?.days,
  weeks: value?.weeks,
  months: value?.months,
  years: value?.years,
});

export const getRecurrenceFromValue = (value) => {
  const orderedValue = orderValue(value);
  const foundValue = getRecurrenceOptions().find(
    (ro) => JSON.stringify(ro.value) === JSON.stringify(orderedValue)
  );
  return foundValue?.label || '';
};
