import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AssessmentQuestion,
  Banner,
  Button,
  Card,
  Separator,
  Typography,
} from '@nl-lms/ui/components';
import { roundToTwo } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { authStore } from '../../../../_common/modules/Auth/auth';
import { useApi } from '../../../../_common/services/api';
import { useEndAttempt } from '../learnerAssessmentPlayerHooks';
import { actions, selectors } from '../learnerAssessmentPlayerSlice';
import './LearnerAssessmentPlayerAttemptForm.scss';

export const LearnerAssessmentPlayerAttemptForm = () => {
  const [error, setError] = useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  const { t } = useTranslation('learner');

  const [trackedTime, setTrackedTime] = useState({
    previousVisibilityEventStartTime: new Date(),
    focusTotalTime: 0,
    blurTotalTime: 0,
  });

  const assessmentInstanceId = useSelector(selectors.getAssessmentId);
  const questions = useSelector(selectors.getQuestions);
  const answers = useSelector(selectors.getUserAnswers);
  const isReadyForSubmit = useSelector(selectors.getIsAttemptReadyForSubmit);
  const answerCount = useSelector(selectors.getAttemptCompletedAnswersCount);
  const timeSpent =
    (useSelector(selectors.getAttemptTimeSpent) as number) * 1000;

  const computeTotalTime = (initialTime, currentTime) =>
    (initialTime || 0) +
    currentTime.focusTotalTime +
    (new Date().getTime() -
      currentTime.previousVisibilityEventStartTime.getTime()) +
    currentTime?.blurTotalTime;

  const { onEndAttempt, isLoading } = useEndAttempt();

  const onPersistAssessmentData = useCallback(
    ({ computedAnswers = null, computedTimeSpent }) => {
      dispatch(actions.userUpdateTimeSpent({ timeSpent: computedTimeSpent }));
      const parsedAnswers = computedAnswers
        ? Object.keys(computedAnswers).map((questionId) => ({
            questionId,
            answer: computedAnswers?.[questionId]
              ? (computedAnswers?.[questionId] as string[])?.map((a) => ` ${a}`)
              : [],
          }))
        : null;

      try {
        api.learnerApp.persistAssessmentAttemptData({
          assessmentInstanceId,
          learnerId: authStore.learnerId,
          answers: parsedAnswers || null,
          timeSpent: computedTimeSpent,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [answers, assessmentInstanceId, trackedTime]
  );

  const onChangeAnswer = useCallback(
    (e) => {
      setError(false);
      const answer =
        typeof e.target.value === 'string' ? [e.target.value] : e.target.value;
      const questionId = e.target.name;
      dispatch(actions.userUpdatedAnswer({ questionId, answer }));
      const computedAnswers = { ...answers, [questionId]: answer };
      onPersistAssessmentData({
        // @ts-ignore
        computedAnswers,
        computedTimeSpent: computeTotalTime(timeSpent, trackedTime),
      });
    },
    [dispatch, answers, trackedTime, timeSpent, assessmentInstanceId]
  );

  const handlePageUnload = useCallback(() => {
    dispatch(
      actions.userUpdateTimeSpent({
        timeSpent: computeTotalTime(timeSpent, trackedTime),
      })
    );
    onPersistAssessmentData({
      computedTimeSpent: computeTotalTime(timeSpent, trackedTime),
    });
  }, [timeSpent, trackedTime]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      const blurTime =
        trackedTime.blurTotalTime +
        (new Date().getTime() -
          trackedTime.previousVisibilityEventStartTime.getTime());
      setTrackedTime({
        ...trackedTime,
        previousVisibilityEventStartTime: new Date(),
        blurTotalTime: blurTime,
      });
    } else {
      const focusTime =
        trackedTime.focusTotalTime +
        (new Date().getTime() -
          trackedTime.previousVisibilityEventStartTime.getTime());
      setTrackedTime({
        ...trackedTime,
        previousVisibilityEventStartTime: new Date(),
        focusTotalTime: focusTime,
      });
    }
  }, [trackedTime]);

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageUnload, true);
    return () =>
      window.removeEventListener('beforeunload', handlePageUnload, true);
  }, [handlePageUnload]);

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange, true);
    return () => {
      window.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        true
      );
    };
  }, [handleVisibilityChange]);

  const orderedQuestions = useMemo(
    () => _.sortBy(questions, (q) => q.order),
    [questions]
  );

  const onSubmitClick = useCallback(() => {
    if (isReadyForSubmit) {
      onEndAttempt({
        timeSpent: computeTotalTime(timeSpent, trackedTime),
      });
    } else {
      setError(true);
    }
  }, [onEndAttempt, isReadyForSubmit, trackedTime, timeSpent]);

  const progress = useMemo(() => {
    const questionCount = questions.length;
    const percentage = roundToTwo(Math.abs(100 * answerCount) / questionCount);
    return {
      percentage: `${percentage}%`,
      questions: questionCount,
      answers: answerCount,
    };
  }, [answerCount, questions]);

  return (
    <Card className="learner-assessment-player-form" paddingType="medium">
      {orderedQuestions.map((question, index) => (
        <div key={question.id}>
          <AssessmentQuestion
            type={question.type}
            description={question.description}
            question={question.title}
            inputProps={{
              onChange: onChangeAnswer,
              options: question.options.map((q) => ({
                value: q.title,
                label: q.title,
              })),
              name: question.id,
              controlled: true,
            }}
            value={answers[question.id]?.map((answer) => answer?.trimStart())}
            index={index}
            required={true}
          />
          <Separator />
        </div>
      ))}
      {error && (
        <Banner
          className="learner-assessment-player-form__error-banner"
          text={t('learningunit.assessmentsubmiterror')}
          alert
        />
      )}
      <div className="learner-assessment-player-form__submit-container">
        <div className="learner-assessment-player-form__submit-stats">
          <Typography.h5>
            {t('learningunit.assessmentprogress', progress)}
          </Typography.h5>
        </div>
        <Button
          onClick={onSubmitClick}
          label={t('learningunit.assessmentsubmit')}
          isLoading={isLoading}
        />
      </div>
    </Card>
  );
};
